import styled, { css } from 'styled-components'

export const Image = styled.img`
  margin-bottom: 0.625rem;
`

export const Title = styled.h4`
  ${({ theme }) => css`
    font-weight: ${theme.font.semiBold};
    font-size: ${theme.font.sizes.medium};
    line-height: 1;
  `}
`

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.gray};
    font-weight: ${theme.font.regular};
    line-height: 2;
  `}
`

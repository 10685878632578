import * as DialogPrimitive from '@radix-ui/react-dialog'
import { Close } from '@styled-icons/material-outlined'
import { useEffect } from 'react'

import Button, { ButtonProps } from '@/components/Button'

import * as S from './styles'

export type ModalContentProps = DialogPrimitive.DialogContentProps & {
  title?: string
  hideCloseButton?: boolean
  noHeader?: boolean
  noBackground?: boolean
  simpleHeader?: boolean
  onClose?: () => void
  width?: string
  height?: string
  xsWidth?: string
  xsHeight?: string
  externalCloseButton?: boolean
  style?: React.CSSProperties
  isToCloseOnOverlay?: boolean
}

export const Content = ({
  children,
  title = '',
  hideCloseButton = false,
  noHeader = false,
  simpleHeader = false,
  noBackground = false,
  externalCloseButton = false,
  width,
  height,
  xsWidth,
  xsHeight,
  onClose,
  isToCloseOnOverlay = true,
  ...props
}: ModalContentProps) => {
  useEffect(() => {
    history.pushState(null, '', location.href)
    window.onpopstate = function () {
      onClose && onClose()
      history.go(1)
    }
  }, [onClose])

  return (
    <DialogPrimitive.Portal>
      {externalCloseButton && (
        <S.ExternalCloseButton
          onClick={() => {
            onClose && onClose()
          }}
        >
          <Close width={28} />
        </S.ExternalCloseButton>
      )}

      <S.Overlay onClick={() => onClose && isToCloseOnOverlay && onClose()} />

      <S.Wrapper
        onPointerDownOutside={(e) => e.preventDefault()}
        width={width || ''}
        height={height || ''}
        xsWidth={xsWidth || ''}
        xsHeight={xsHeight || ''}
        noBackground={noBackground ? 1 : 0}
        {...props}
      >
        {!noHeader && (
          <S.Header simple={simpleHeader ? 1 : 0}>
            <S.Title>{title}</S.Title>

            {!hideCloseButton && <S.CloseButton>✕</S.CloseButton>}
          </S.Header>
        )}

        {children}
      </S.Wrapper>
    </DialogPrimitive.Portal>
  )
}

export const ModalButtonClose = ({ children, ...props }: ButtonProps) => (
  <Button as={DialogPrimitive.Close} {...props}>
    {children}
  </Button>
)

export const Modal = DialogPrimitive.Root
export const ModalTrigger = DialogPrimitive.Trigger
export const ModalContent = Content
export const ModalOverflow = S.ModalOverflow
export const ModalHeader = S.Header
export const ModalHeaderTitle = S.Title
export const ModalHeaderClose = S.CloseButton
export const ModalFooter = S.ModalFooter

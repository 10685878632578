import { useCallback, useEffect, useState } from 'react'
import { titleCase } from 'title-case'

import settingsService from '@/api/settings.service'
import zordonWifiIcon from '@/assets/img/icons-zordon-wifi.svg'
import Switch from '@/components/Switch'
import useAuth from '@/hooks/useAuth'
import { MerchantGeneralSettingsType } from '@/types/settings.types'
import { formatDistanceToNow } from '@/utils/formats/date'

import { FormGeneralSettingsLoading } from './loader'
import * as S from './styles'

export function FormGeneralSettings() {
  const [merchantSettings, setMerchantSettings] =
    useState<MerchantGeneralSettingsType>()
  const [driverArrivingSound, setDriverArrivingSound] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [saveLoading, setSaveLoading] = useState(false)
  const { user, merchantSelected } = useAuth()

  const getMerchantGeneralSettings = async (
    merchantId: string,
    userId: string,
  ) => {
    setIsLoading(true)
    try {
      const { data } = await settingsService.getMerchantGeneralSettings(
        merchantId,
        userId,
      )
      setMerchantSettings(data)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSave = useCallback(
    async (driverArrivingSound: boolean) => {
      if (user && merchantSelected) {
        setSaveLoading(true)
        try {
          const dataToSave = { driverArrivingSound }

          const { data } = await settingsService.saveMerchantGeneralSettings(
            dataToSave,
            merchantSelected.id,
            user.uuid,
          )
          setMerchantSettings(data)
        } finally {
          setSaveLoading(false)
        }
      }
    },
    [merchantSelected, user],
  )

  useEffect(() => {
    setDriverArrivingSound(merchantSettings?.driverArrivingSound || false)
  }, [merchantSettings])

  useEffect(() => {
    if (user && merchantSelected) {
      getMerchantGeneralSettings(merchantSelected.id, user.uuid)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantSelected])

  return isLoading ? (
    <FormGeneralSettingsLoading />
  ) : (
    <S.Wrapper>
      <S.FormList>
        <S.FormListItem>
          <S.FormListItemTitle>Tablet connection</S.FormListItemTitle>
          <S.ItemLabel style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={zordonWifiIcon}
              width={20}
              height={20}
              alt="Zordon wifi icon"
            />{' '}
            {formatDistanceToNow(
              new Date(merchantSettings?.lastUpdate || ''),
              false,
            )}
          </S.ItemLabel>
        </S.FormListItem>
        <S.FormListItem>
          <S.FormListItemTitle>Tablet status</S.FormListItemTitle>
          <S.ItemLabel>{titleCase(merchantSettings?.status || '')}</S.ItemLabel>
        </S.FormListItem>
        {!!merchantSettings?.battery && (
          <S.FormListItem>
            <S.FormListItemTitle>Battery level</S.FormListItemTitle>
            <S.ItemLabel>{`${merchantSettings?.battery}%`}</S.ItemLabel>
          </S.FormListItem>
        )}
        <S.FormListItem>
          <S.FormListItemTitle>Driver arriving sound</S.FormListItemTitle>
          <Switch
            checked={driverArrivingSound}
            id="driverArrivingSound"
            onChange={(checked) => handleSave(checked)}
            disabled={saveLoading}
          />
        </S.FormListItem>
      </S.FormList>
    </S.Wrapper>
  )
}

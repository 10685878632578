import styled, { css } from 'styled-components'

export const Section = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.medium};
    border-radius: 24px;
    background-color: ${theme.colors.white};
  `}
`

export const Icon = styled.div<{ addition?: number }>`
  ${({ theme, addition = 1 }) => css`
    color: ${addition === 1 ? theme.colors.green : theme.colors.red};
  `}
`

export const Value = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.font.bold};
  `}
`

export const Date = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
  `}
`

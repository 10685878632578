import { useCallback, useState } from 'react'

import menuMakerService from '@/api/menu-maker.service'
import { Box } from '@/components/Box'
import Button from '@/components/Button'
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverflow,
} from '@/components/Modal'
import SelectButton from '@/components/SelectButton'
import TextField from '@/components/TextField'
import useModal from '@/hooks/useModal'
import ConfirmModal from '@/modals/ConfirmModal'
import { CategoryType, MenusType } from '@/types/menu-maker.types'

import * as S from './styles'

export type EditCategoryModalProps = {
  editCategoryData: CategoryType | null
  merchant: string
  menus: MenusType[]
  closeEditCategory: (refresh: boolean) => void
}

type FormErrorsType = {
  name: string
  menus: string
}

type MenusSelectedType = {
  name: string
  value: string
  selected: boolean
}

const categoryDefault: CategoryType = {
  menus: [],
  menusName: [],
  merchant: '',
  name: '',
  note: '',
  position: 0,
}

const EditCategoryModal = ({
  editCategoryData,
  closeEditCategory,
  merchant,
  menus,
}: EditCategoryModalProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const { open: openConfirmModal, toggle: setOpenConfirmModal } = useModal()
  const [name, setName] = useState(editCategoryData?.name || '')
  const [note, setNote] = useState(editCategoryData?.note || '')
  const [menusSelected, setMenusSelected] = useState<MenusSelectedType[]>(
    menus.map((menu) => {
      return {
        name: menu.name,
        value: menu.menuId!,
        selected: editCategoryData?.menus
          ? editCategoryData.menus.some((ms) => ms === menu.menuId!)
          : false,
      }
    }),
  )
  const [formErrors, setFormErrors] = useState<FormErrorsType>({
    name: '',
    menus: '',
  })

  const handleSelectMenu = useCallback(
    (menu: MenusSelectedType, index: number) => {
      const newMenusSelected = [...menusSelected]
      newMenusSelected[index] = { ...menu, selected: !menu.selected }
      setMenusSelected(newMenusSelected)
    },
    [menusSelected],
  )

  const saveCategory = useCallback(async () => {
    setIsLoading(true)
    const menus = menusSelected.filter((m) => m.selected).map((ms) => ms.value)
    const data: CategoryType = editCategoryData
      ? {
          ...editCategoryData,
          name,
          note,
          menus,
        }
      : {
          ...categoryDefault,
          name,
          note,
          menus,
          merchant,
        }

    if (!data.name) {
      setFormErrors((old) => ({ ...old, name: 'Name is required' }))
      setIsLoading(false)
      return
    }

    setFormErrors((old) => ({ ...old, name: '' }))

    if (!data.menus.length) {
      setFormErrors((old) => ({ ...old, menus: 'Select at least one menu' }))
      setIsLoading(false)
      return
    }

    setFormErrors((old) => ({ ...old, menus: '' }))

    try {
      await menuMakerService.saveCategory(data)
      closeEditCategory(true)
    } finally {
      setIsLoading(false)
    }
  }, [menusSelected, editCategoryData, name, note, merchant, closeEditCategory])

  const deleteCategory = useCallback(async () => {
    setIsDeleting(true)
    const menus = menusSelected.filter((m) => m.selected).map((ms) => ms.value)
    const data: CategoryType = {
      ...editCategoryData!,
      name,
      note,
      menus,
    }

    try {
      await menuMakerService.deleteCategory(data)
      closeEditCategory(true)
    } finally {
      setIsDeleting(false)
    }
  }, [closeEditCategory, editCategoryData, menusSelected, name, note])

  const renderConfirmModal = () => (
    <Modal open={openConfirmModal} onOpenChange={setOpenConfirmModal} modal>
      <ModalContent
        title="Delete Category"
        onClose={() => setOpenConfirmModal(false)}
        hideCloseButton
        width="auto"
        xsWidth="90vw"
        xsHeight="auto"
      >
        <ConfirmModal
          message={
            <>
              Are you sure you want to delete category{' '}
              <strong>{editCategoryData?.name}</strong>?
            </>
          }
          closeConfirm={(confirm) => {
            if (confirm) deleteCategory()
            setOpenConfirmModal(false)
          }}
        />
      </ModalContent>
    </Modal>
  )

  return (
    <>
      <ModalOverflow>
        <S.ContentInputs>
          <S.FormControl>
            <TextField
              value={name}
              label="Category's Name"
              labelFor="name"
              id="name"
              onChange={(e) => setName(e.target.value)}
              error={formErrors.name}
              disabled={isLoading}
              variant="material"
            />
          </S.FormControl>

          <S.FormControl>
            <TextField
              value={note}
              label="Note"
              labelFor="note"
              id="note"
              onChange={(e) => setNote(e.target.value)}
              disabled={isLoading}
              variant="material"
            />
          </S.FormControl>
        </S.ContentInputs>

        <S.Content>
          <S.MenuForm>
            <Box flex flexDirection="column">
              <S.LabelForm>Menus for this category</S.LabelForm>
              <S.Menus>
                {menusSelected.map((menu, index) => (
                  <SelectButton
                    key={menu.value}
                    selected={menu.selected}
                    onClick={() => handleSelectMenu(menu, index)}
                  >
                    {menu.name}
                  </SelectButton>
                ))}
              </S.Menus>
              {formErrors.menus && (
                <S.ErrorMenus>{formErrors.menus}</S.ErrorMenus>
              )}
            </Box>
          </S.MenuForm>
        </S.Content>
      </ModalOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
          style={{ marginTop: '1rem' }}
        >
          {editCategoryData && (
            <Button
              color="white"
              size="large"
              disabled={isDeleting}
              loading={isDeleting}
              onClick={() => setOpenConfirmModal(true)}
              shadow
            >
              Delete
            </Button>
          )}

          <Button
            size="large"
            disabled={isLoading}
            onClick={saveCategory}
            loading={isLoading}
            style={{ marginLeft: 'auto' }}
          >
            Save
          </Button>
        </Box>
      </ModalFooter>
      {openConfirmModal && renderConfirmModal()}
    </>
  )
}

export default EditCategoryModal

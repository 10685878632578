import { useCallback } from 'react'
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'

import { VisitedItemsType } from '@/types/dashboard.types'

import * as S from './styles'

const COLORS = [
  '#ff4c4c',
  '#fba240',
  '#17bebb',
  '#433d3d',
  '#8e8b8b',
  '#ff5ca2',
  '#43cb8f',
  '#3798ff',
  '#f7c02c',
  '#17bebb',
  '#ff4c4c',
  '#fba240',
  '#17bebb',
  '#433d3d',
  '#8e8b8b',
  '#ff5ca2',
  '#43cb8f',
  '#3798ff',
  '#f7c02c',
  '#17bebb',
  '#ff4c4c',
  '#fba240',
  '#17bebb',
  '#433d3d',
  '#8e8b8b',
  '#ff5ca2',
  '#43cb8f',
  '#3798ff',
  '#f7c02c',
  '#17bebb',
  '#ff4c4c',
  '#fba240',
  '#17bebb',
  '#433d3d',
  '#8e8b8b',
  '#ff5ca2',
  '#43cb8f',
  '#3798ff',
  '#f7c02c',
  '#17bebb',
  '#ff4c4c',
  '#fba240',
  '#17bebb',
  '#433d3d',
  '#8e8b8b',
  '#ff5ca2',
  '#43cb8f',
  '#3798ff',
  '#f7c02c',
  '#17bebb',
]

type RenderCustomizedLabelProps = {
  cx: number
  cy: number
  midAngle: number
  innerRadius: number
  outerRadius: number
  value: number
}

type CustomLegendPayloadProps = {
  color: string
  value: string
}

type CustomLegendProps = {
  payload: CustomLegendPayloadProps[]
}

const CustomLegend = ({ payload }: CustomLegendProps) => (
  <S.List>
    {payload.map((entry: CustomLegendPayloadProps) => (
      <S.ListItem key={`item-${entry.value}`}>
        <S.ListItemBullet color={entry.color} />
        <S.ListItemText>{entry.value}</S.ListItemText>
      </S.ListItem>
    ))}
  </S.List>
)

const RADIAN = Math.PI / 180

type CustomTooltipProps = {
  active: boolean
  payload: Array<{ name: string; value: string }>
}

const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <S.Tooltip>
        <h4>{payload[0].name}</h4>
        <p>Visits: {payload[0].value}</p>
      </S.Tooltip>
    )
  }

  return null
}

export type TopViewsPieChartProps = {
  data: VisitedItemsType[]
  height?: string
}

const TopViewsPieChart = ({ data, height }: TopViewsPieChartProps) => {
  const renderCustomizedLabel = useCallback(
    ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      value,
    }: RenderCustomizedLabelProps) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.4
      const x = cx + radius * Math.cos(-midAngle * RADIAN)
      const y = cy + radius * Math.sin(-midAngle * RADIAN)

      return (
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
          fontSize="0.875rem"
          fontFamily="Quicksand"
          fontWeight="bold"
        >
          {value}
        </text>
      )
    },
    [],
  )

  return (
    <S.Wrapper height={height}>
      <S.Title>Top Visited</S.Title>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Legend content={<CustomLegend {...({} as CustomLegendProps)} />} />
          <Tooltip
            cursor={{ stroke: 'none' }}
            content={<CustomTooltip {...({} as CustomTooltipProps)} />}
          />
          <Pie
            data={data}
            nameKey="item"
            dataKey="views"
            cx="50%"
            cy="50%"
            labelLine={false}
            innerRadius={95}
            outerRadius={155}
            strokeWidth="0"
            label={renderCustomizedLabel}
          >
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </S.Wrapper>
  )
}

export default TopViewsPieChart

import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@styled-icons/material-outlined'

import Select from '@/components/Select'
import { DOTS, usePagination } from '@/hooks/usePagination'

import * as S from './styles'

const PAGINATION_OPTIONS = [
  {
    value: 10,
    label: '10',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 50,
    label: '50',
  },
]

export type PaginationProps = {
  currentPage: number
  pageSize: number
  totalCount: number
  onPageChange: (page: string | number) => void
  onPageSizeChange: (pageSize: number) => void
  siblingCount?: number
  style?: React.CSSProperties
}

const Pagination = ({
  currentPage,
  pageSize,
  totalCount,
  onPageChange,
  onPageSizeChange,
  siblingCount = 1,
  style,
}: PaginationProps) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  if (!paginationRange) {
    return null
  }

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  const lastPage = paginationRange[paginationRange.length - 1]
  return (
    <S.Wrapper style={style}>
      <S.FormControl>
        <S.Label>Rows per page:</S.Label>
        <Select
          options={PAGINATION_OPTIONS}
          formatOptionLabel={(option) => option.label}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => String(option.value)}
          onChange={(option) => option && onPageSizeChange(option.value)}
          isSearchable={false}
          value={{
            value: pageSize,
            label: String(pageSize),
          }}
        />
      </S.FormControl>

      <S.ListItem>
        <S.Item disabled={currentPage === 1} onClick={onPrevious}>
          <KeyboardArrowLeft />
        </S.Item>

        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <S.Item key={`dot-${index}`} dots>
                &#8230;
              </S.Item>
            )
          }

          return (
            <S.Item
              key={pageNumber}
              selected={pageNumber === currentPage}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </S.Item>
          )
        })}

        <S.Item disabled={currentPage === lastPage} onClick={onNext}>
          <KeyboardArrowRight />
        </S.Item>
      </S.ListItem>
    </S.Wrapper>
  )
}

export default Pagination

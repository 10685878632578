import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 263px;
  gap: 32px;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  padding: 32px 0;
`

export const Container = styled.div`
  width: 263px;
  height: 263px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-weight: ${theme.font.semiBold};
    font-size: ${theme.font.sizes.xlarge};
    text-align: center;
    line-height: 1;
    margin-bottom: 4px;
  `}
`

export const Description = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.body};
    font-weight: ${theme.font.semiBold};
    text-align: center;
  `}
`

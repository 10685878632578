import { format } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import Empty from '@/components/Empty'
import List from '@/components/List'
import Skeleton from '@/components/Skeleton'
import useAuth from '@/hooks/useAuth'
import { api } from '@/lib/axios'
import { MagicVoucher } from '@/types/super-partner.types'

import * as S from './styles'

type MagicVouchersListProps = {
  selectedVouchers?: MagicVoucher[]
  hoverable?: boolean
  onSetVoucher?: (voucher: MagicVoucher) => void
}

export const MagicVouchersList = ({
  hoverable = false,
  selectedVouchers = [],
  onSetVoucher,
}: MagicVouchersListProps) => {
  const [vouchers, setVouchers] = useState<MagicVoucher[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const { merchantSelected } = useAuth()

  const alreadyAddedVoucher = useCallback(
    (id: string) => {
      return selectedVouchers.some((voucher) => voucher.voucherId === id)
    },
    [selectedVouchers],
  )

  const handleSetVoucher = (voucher: MagicVoucher) => {
    if (!alreadyAddedVoucher(voucher.voucherId)) {
      onSetVoucher && onSetVoucher(voucher)
    }
  }

  const fetchVoucherList = async () => {
    try {
      const { data } = await api.get(
        `/merchants/${merchantSelected?.id}/vouchers`,
      )
      setVouchers(data)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (merchantSelected?.id) {
      fetchVoucherList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <S.Wrapper>
      {isLoading ? (
        <Skeleton count={3} />
      ) : vouchers.length === 0 ? (
        <Empty title="There is nothing here, yet" />
      ) : (
        <List<MagicVoucher>
          data={vouchers}
          onItemClick={(voucher) => handleSetVoucher(voucher)}
          hoverable={hoverable}
          style={{ userSelect: 'auto' }}
        >
          {(voucher) => (
            <>
              <S.WrapperButton
                cursor={
                  alreadyAddedVoucher(voucher.voucherId) ? 'default' : 'pointer'
                }
                added={alreadyAddedVoucher(voucher.voucherId) ? 1 : 0}
                data-tip={alreadyAddedVoucher(voucher.voucherId) ? 'Added' : ''}
              >
                <S.VoucherItemImage bg={voucher.image} />

                <div>
                  <S.VoucherItemTitle>{voucher.title}</S.VoucherItemTitle>
                  <S.VoucherItemInfo>
                    Magic Voucher: {voucher.voucherId}
                  </S.VoucherItemInfo>
                  <S.VoucherItemExpireDate>
                    Expires on{' '}
                    {format(new Date(voucher.expiryDate), 'MMM do, yyyy')}
                  </S.VoucherItemExpireDate>
                </div>
              </S.WrapperButton>

              <ReactTooltip
                effect="solid"
                className="tooltip"
                backgroundColor="#433d3d"
                borderColor="#433d3d"
              />
            </>
          )}
        </List>
      )}
    </S.Wrapper>
  )
}

import { darken } from 'polished'
import styled, { css } from 'styled-components'

import Button from '@/components/Button'

export const ButtonIcon = styled(Button)`
  padding: 0.5rem;
`

export const TotalText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.gray};

    span {
      color: ${theme.colors.black};
    }
  `}
`

export const CustomCloseButton = styled(Button)`
  ${({ theme }) => css`
    padding: 0.281rem 0.5rem;
    all: unset;
    background: none;
    color: ${theme.colors.primary};
    font-size: 1.75rem;
    border: 0;
    border-radius: 99999px;
    position: absolute;
    outline: none;
    cursor: pointer;

    &:hover {
      background: none;
      color: ${darken(0.2, theme.colors.primary)};
    }
  `}
`

export const CampaignAsBentoText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.gray};
    font-size: ${theme.font.sizes.small};
  `}
`

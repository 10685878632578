import { lighten } from 'polished'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    // main wrapper
    --main-bg: ${theme.colors.white};
    --main-font-family: ${theme.font.quicksand};

    // top bar
    --top-bg: ${theme.colors.white};
    --top-colon-color: ${theme.colors.secondary};
    --top-text-color: ${theme.colors.secondary};
    --top-selected-color: ${theme.colors.primary};
    --top-meridiem-color: ${theme.colors.secondary};

    // time dropdown
    --dropdown-border: none;
    --dropdown-text-color: ${theme.colors.secondary};
    --dropdown-hover-bg: ${theme.colors.mainBg};

    // clock wrapper
    --clock-wrapper-bg: ${theme.colors.mainBg};
    --clock-bg: ${theme.colors.white};

    // clock hand
    --hand-line-color: ${theme.colors.primary};
    --hand-circle-center: ${theme.colors.primary};
    --hand-circle-outer: ${lighten(0.28, theme.colors.primary)};
    --hand-minute-circle: ${theme.colors.primary};

    // numbers
    --numbers-text-color: ${theme.colors.secondary};

    // meridiem buttons
    --meridiem-bg-color: ${theme.colors.white};
    --meridiem-text-color: ${theme.colors.secondary};
    --meridiem-selected-bg-color: ${lighten(0.28, theme.colors.primary)};
    --meridiem-selected-text-color: ${theme.colors.secondary};

    // done button
    --done-bg-color: ${theme.colors.white};
    --done-text-color: ${theme.colors.primary};
    --done-border-top: none;
    --done-font-size: ${theme.font.sizes.medium};
    --done-font-weight: ${theme.font.semiBold};

    .react-timekeeper {
      border-radius: ${theme.border.radius.medium};

      .react-timekeeper__top-bar {
        border-top-left-radius: ${theme.border.radius.medium};
        border-top-right-radius: ${theme.border.radius.medium};
      }

      .react-timekeeper__done-button {
        border-bottom-left-radius: ${theme.border.radius.medium};
        border-bottom-right-radius: ${theme.border.radius.medium};
      }
    }
  `}
`

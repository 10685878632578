import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { TakeoutDeliveryType } from '@/types/dashboard.types'

import * as S from './styles'

type CustomLegendPayloadProps = {
  color: string
  value: string
}

type CustomLegendProps = {
  payload: CustomLegendPayloadProps[]
}

type SVGLegendBulletProps = {
  fill: string
}

const SVGLegendBullet = ({ fill }: SVGLegendBulletProps) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="barTakeout" x1="0" y1="0" x2="0" y2="1">
        <stop offset="1%" stopColor="#fba240" stopOpacity={1} />
        <stop offset="99%" stopColor="#d6852d" stopOpacity={1} />
      </linearGradient>
      <linearGradient id="barDelivery" x1="0" y1="0" x2="0" y2="1">
        <stop offset="1%" stopColor="#fba140" stopOpacity={1} />
        <stop offset="99%" stopColor="#ff4c4c" stopOpacity={1} />
      </linearGradient>
    </defs>

    <rect fill={fill} />
  </svg>
)

const CustomLegend = ({ payload }: CustomLegendProps) => (
  <S.List>
    {payload.map((entry: CustomLegendPayloadProps) => (
      <S.ListItem key={`item-${entry.value}`}>
        <S.ListItemBullet>
          <SVGLegendBullet fill={entry.color} />
        </S.ListItemBullet>
        {entry.value}
      </S.ListItem>
    ))}
  </S.List>
)

type CustomTooltipProps = {
  active: boolean
  payload: Array<{ name: string; value: string }>
  label: string
}

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <S.Tooltip>
        <h4>{label}</h4>
        <p>
          {payload[0].name}: {payload[0].value}
        </p>
        <p>
          {payload[1].name}: {payload[1].value}
        </p>
      </S.Tooltip>
    )
  }

  return null
}

export type TakeoutDeliveryChartProps = {
  data: TakeoutDeliveryType[]
  moreThanFifteenDays: boolean
  width?: string
  height?: string
}

const TakeoutDeliveryChart = ({
  data,
  height,
  moreThanFifteenDays,
}: TakeoutDeliveryChartProps) => (
  <S.Wrapper height={height} moreThanFifteenDays={moreThanFifteenDays}>
    <S.Title>{'Takeout & Delivery'}</S.Title>
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data} margin={{ right: 20, left: -20 }} barGap={0}>
        <defs>
          <linearGradient id="takeoutColor" x1="0" y1="1" x2="0" y2="0">
            <stop offset="5%" stopColor="#D6852D" stopOpacity={1} />
            <stop offset="95%" stopColor="#FBA240" stopOpacity={1} />
          </linearGradient>
          <linearGradient id="deliveryColor" x1="0" y1="1" x2="0" y2="0">
            <stop offset="5%" stopColor="#ff4c4c" stopOpacity={1} />
            <stop offset="95%" stopColor="#fba140" stopOpacity={1} />
          </linearGradient>
        </defs>
        <CartesianGrid vertical={false} />
        <XAxis dataKey="date" strokeWidth="0" fontSize="0.75rem" />
        <YAxis strokeWidth="0" fontSize="0.75rem" />
        <Tooltip
          cursor={{ stroke: 'none' }}
          content={<CustomTooltip {...({} as CustomTooltipProps)} />}
        />
        <Legend content={<CustomLegend {...({} as CustomLegendProps)} />} />

        <Bar
          dataKey="takeout"
          name="Takeout"
          fill="url(#takeoutColor)"
          radius={[6, 6, 0, 0]}
        />
        <Bar
          dataKey="delivery"
          name="Delivery"
          fill="url(#deliveryColor)"
          radius={[6, 6, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  </S.Wrapper>
)

export default TakeoutDeliveryChart

import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { ModalFooter, ModalOverflow } from '@/components/Modal'
import TextField from '@/components/TextField'
import { VoucherFormData } from '@/modals/CreatingNewCampaignModal/components/VoucherSteps/VoucherForm'
import {
  formatCurrencyWithCode,
  formatValueNumber,
} from '@/utils/formats/number'

import * as S from './styles'

export type CustomVoucherModalProps = {
  vouchers?: VoucherFormData['vouchers']
  selectedCount?: number
  close: (data: VoucherFormData['vouchers'] | null) => void
}

const customVoucherFormSchema = z
  .object({
    selectedCount: z.coerce.number().optional(),
    voucherShift: z
      .string({
        required_error: 'Voucher shift is required',
      })
      .min(1, 'Voucher shift is required'),
    voucherQuantity: z
      .string({
        required_error: 'Voucher quantity is required',
      })
      .min(1, 'Voucher quantity is required')
      .refine((data) => data !== '0', {
        message: 'Voucher quantity must be greater than 0',
      }),
    totalSpent: z
      .string({ required_error: 'Total spent is required' })
      .min(1, 'Total spent is required'),
  })
  .refine(
    (data) => {
      return (
        data.selectedCount === undefined ||
        data.selectedCount >= Number(data.voucherQuantity)
      )
    },
    ({ selectedCount }) => ({
      path: ['voucherQuantity'],
      message: `Voucher quantity must be less than or equal to ${selectedCount} audiences selected`,
    }),
  )

export type CustomVoucherFormData = z.infer<typeof customVoucherFormSchema>

export const CustomVoucherModal = ({
  close,
  vouchers,
  selectedCount,
}: CustomVoucherModalProps) => {
  const { handleSubmit, watch, setValue, control } =
    useForm<CustomVoucherFormData>({
      resolver: zodResolver(customVoucherFormSchema),
      defaultValues: {
        selectedCount,
        voucherShift: vouchers ? String(vouchers?.voucherShift) : undefined,
        voucherQuantity: vouchers
          ? String(vouchers?.voucherQuantity)
          : undefined,
        totalSpent: vouchers ? String(vouchers?.totalSpent) : undefined,
      },
    })

  const onSubmit = (values: CustomVoucherFormData) => {
    const { totalSpent, voucherQuantity, voucherShift } = values

    const data = {
      totalSpent: formatValueNumber(totalSpent),
      voucherQuantity: Number(voucherQuantity.replace(/\D/g, '')),
      voucherShift: formatValueNumber(voucherShift),
    }

    close(data)
  }

  const voucherShift = watch('voucherShift')

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (!value.voucherQuantity) return

      const quantity = Number(value.voucherQuantity.replace(/\D/g, ''))
      if (
        (type === 'change' && name === 'voucherShift') ||
        name === 'voucherQuantity'
      ) {
        if (value.voucherShift) {
          const valueNumber = formatValueNumber(value.voucherShift)
          const totalSpent = quantity * valueNumber
          setValue('totalSpent', String(totalSpent))
        }
      }

      if (type === 'change' && name === 'totalSpent') {
        if (value.totalSpent) {
          const valueNumber = formatValueNumber(value.totalSpent)
          const voucherShift = valueNumber / quantity
          setValue('voucherShift', String(voucherShift))
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [setValue, watch])

  return (
    <S.Form>
      <ModalOverflow>
        <S.WrapperContent>
          <S.Header>
            <S.Info>
              <h4>Price per voucher:</h4>
              <p>
                {formatCurrencyWithCode(
                  formatValueNumber(voucherShift || '0') / 100,
                )}
              </p>
            </S.Info>
          </S.Header>

          <S.FieldWrapper>
            <S.FieldHeader>
              <S.FieldHeaderTitle>Set a voucher</S.FieldHeaderTitle>
            </S.FieldHeader>

            <S.FieldContent>
              <Controller
                name="voucherShift"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    variant="material"
                    label="Amount per voucher"
                    labelFor="voucherShift"
                    id="voucherShift"
                    placeholder="$"
                    inputMode="numeric"
                    mask="money"
                    error={fieldState.error?.message}
                    {...field}
                  />
                )}
              />

              <Controller
                name="voucherQuantity"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    type="number"
                    variant="material"
                    label="How many vouchers?"
                    labelFor="voucherQuantity"
                    id="voucherQuantity"
                    placeholder="0"
                    inputMode="numeric"
                    mask="numberClean"
                    maxLength={3}
                    error={fieldState.error?.message}
                    {...field}
                  />
                )}
              />

              <Controller
                name="totalSpent"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    variant="material"
                    label="Total spent"
                    labelFor="totalSpent"
                    id="totalSpent"
                    placeholder="$"
                    mask="money"
                    inputMode="numeric"
                    error={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
            </S.FieldContent>
          </S.FieldWrapper>
        </S.WrapperContent>
      </ModalOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
        >
          <Button size="large" color="white" onClick={() => close(null)} shadow>
            Cancel
          </Button>
          <Button size="large" onClick={handleSubmit(onSubmit)}>
            Continue
          </Button>
        </Box>
      </ModalFooter>
    </S.Form>
  )
}

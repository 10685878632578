import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal, ModalContent } from '@/components/Modal'
import Radio from '@/components/Radio'
import useModal from '@/hooks/useModal'
import {
  FieldContent,
  FieldError,
  FieldHeader,
  FieldHeaderTitle,
  FieldWrapper,
} from '@/modals/CreatingNewCampaignModal/styles'
import { CustomCampaignLoyaltyModal } from '@/modals/CustomCampaignLoyaltyModal'
import { CampaignLoyaltyType } from '@/types/super-partner.types'

import * as S from './styles'

export type CampaignFieldProps = {
  selectedCount?: number
  campaign?: CampaignLoyaltyType
  error?: string
  before?: boolean
  isInitialDataCustom?: boolean
  onChange: (value?: CampaignLoyaltyType) => void
}

export const CampaignField = ({
  error,
  onChange,
  campaign,
  before,
  isInitialDataCustom = false,
}: CampaignFieldProps) => {
  const [isCustomCampaign, setIsCustomCampaign] = useState(isInitialDataCustom)
  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal()
  const { t } = useTranslation()

  const renderCustomCampaignModal = () => (
    <Modal open={modalIsOpen} onOpenChange={setModalIsOpen} modal>
      <ModalContent
        title="Custom Campaign"
        onClose={() => setModalIsOpen(false)}
        onOpenAutoFocus={(event) => event.preventDefault()}
        width="auto"
        hideCloseButton
      >
        <CustomCampaignLoyaltyModal
          campaign={campaign}
          close={(data) => {
            if (data) {
              onChange(data)
              setIsCustomCampaign(true)
            } else {
              setIsCustomCampaign(false)
            }
            setModalIsOpen(false)
          }}
        />
      </ModalContent>
    </Modal>
  )

  useEffect(() => {
    if (before) {
      const element = document.getElementById('campaign')
      if (element) {
        element.scrollIntoView({ block: 'center', behavior: 'smooth' })
      }
    }
  }, [before])

  return (
    <FieldWrapper before={before ? 1 : 0} id="campaign">
      <FieldHeader>
        <FieldHeaderTitle>Campaign</FieldHeaderTitle>
      </FieldHeader>

      <FieldContent>
        <S.OptionsList>
          <S.OptionItem>
            <Radio
              label="$5 reward after 5 orders"
              labelFor="campaign5"
              id="campaign5"
              onChange={() => {
                setIsCustomCampaign(false)
                onChange({
                  reward: 500,
                  orders: 5,
                })
              }}
              checked={campaign?.reward === 500 && !isCustomCampaign}
            />
          </S.OptionItem>
          <S.OptionItem>
            <Radio
              label="$10 reward after 10 orders"
              labelFor="campaign10"
              id="campaign10"
              onChange={() => {
                setIsCustomCampaign(false)
                onChange({
                  reward: 1000,
                  orders: 10,
                })
              }}
              checked={campaign?.reward === 1000 && !isCustomCampaign}
            />
          </S.OptionItem>
          <S.OptionItem>
            <Radio
              label="$15 reward after 20 orders"
              labelFor="campaign15"
              id="campaign15"
              onChange={() => {
                setIsCustomCampaign(false)
                onChange({
                  reward: 1500,
                  orders: 20,
                })
              }}
              checked={campaign?.reward === 1500 && !isCustomCampaign}
            />
          </S.OptionItem>
          <S.OptionItem>
            <Radio
              label={`Custom Budget ${
                isCustomCampaign && campaign
                  ? `($${campaign?.reward / 100} reward after ${
                      campaign?.orders
                    } ${t(
                      'modals.creating-new-campaign.campaignField.custom.label.orders',
                      {
                        count: campaign?.orders,
                      },
                    )})`
                  : ''
              }`}
              labelFor="campaignCustom"
              id="campaignCustom"
              labelColor="red"
              onClick={() => setModalIsOpen(true)}
              onChange={() => {
                onChange(undefined)
              }}
              checked={isCustomCampaign && campaign !== undefined}
            />
          </S.OptionItem>

          {error && <FieldError>{error}</FieldError>}
        </S.OptionsList>
      </FieldContent>

      {modalIsOpen && renderCustomCampaignModal()}
    </FieldWrapper>
  )
}

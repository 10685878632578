import { Outlet } from 'react-router-dom'

import logo from '@/assets/img/logo.svg'

export function AuthLayout() {
  return (
    <main className="flex h-screen items-center justify-center">
      <div className="flex w-full max-w-[350px] flex-col">
        <div className="mb-8 self-center">
          <img src={logo} alt="Logo Bento" width={90} height={48} />
        </div>

        <Outlet />
      </div>
    </main>
  )
}

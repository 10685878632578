import { OptionTypeBase } from 'react-select'
import styled, { css, DefaultTheme } from 'styled-components'

import { SelectProps } from '.'

type WrapperProps = Pick<SelectProps<OptionTypeBase>, 'variant'>

const wrapperModifiers = {
  font: (theme: DefaultTheme) => css`
    font-family: ${theme.font.quicksand};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.medium};
    color: ${theme.colors.black};
  `,
  material: (theme: DefaultTheme) => css`
    margin: 0 ${theme.spacings.small};

    ${Label} {
      font-weight: ${theme.font.medium};
      font-size: ${theme.font.sizes.xxsmall};
      color: ${theme.colors.black};
    }

    .react-select__control {
      ${wrapperModifiers.font(theme)}

      border-radius: 0;
      border-bottom: 1px solid ${theme.colors.borderColor};
      padding: 0;
      background-color: transparent;

      .react-select__value-container {
        color: ${theme.colors.black};
        font-size: ${theme.font.sizes.medium};
        padding: 0;
        background: transparent;
        border: 0;
        outline: none;
        width: 100%;
      }

      &:hover {
        border: 2px solid transparent;
        border-bottom: 1px solid #979797;
      }

      &:focus,
      &:active,
      &--is-focused {
        border: 2px solid transparent;
        border-bottom: 1px solid ${theme.colors.lightGray};

        &:hover {
          border: 2px solid transparent;
          border-bottom: 1px solid ${theme.colors.lightGray};
        }
      }
    }

    .react-select__menu {
      ${wrapperModifiers.font(theme)}

      background: ${theme.colors.white};
      border-radius: 4px;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);

      .react-select__menu-list {
        padding-bottom: 0;
        padding-top: 0;

        .react-select__option {
          border-bottom: 1px solid ${theme.colors.borderColor};
          padding: 1rem;

          &:hover,
          &--is-focused,
          &--is-selected {
            background: ${theme.colors.primary};
            color: ${theme.colors.borderColor};
          }

          &:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }

          &:last-child {
            border-bottom: none;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      }
    }
  `,
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, variant }) => css`
    .react-select__control {
      ${wrapperModifiers.font(theme)}

      background: ${theme.colors.white};
      border-radius: ${theme.border.radius.xsmall};
      padding: 0 ${theme.spacings.xxsmall};
      border: 2px solid transparent;
      transition: border ${theme.transition.default};

      .react-select__value-container {
        color: ${theme.colors.black};
        font-size: ${theme.font.sizes.xsmall};
        background: transparent;
        border: 0;
        outline: none;
        width: 100%;
      }

      .react-select__value-container .react-select__single-value {
        color: ${theme.colors.black};
      }

      &:hover {
        border-color: transparent;
      }

      &:focus,
      &:active,
      &--is-focused {
        border-color: ${theme.colors.lightGray};
        box-shadow: none;

        &:hover {
          border-color: ${theme.colors.lightGray};
        }
      }

      .react-select__indicators {
        color: ${theme.colors.black};
      }
    }

    .react-select__menu {
      ${wrapperModifiers.font(theme)}

      background: ${theme.colors.white};
      border-radius: ${theme.border.radius.xsmall};
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
      z-index: 2;

      .react-select__menu-list {
        padding-bottom: 0;
        padding-top: 0;

        .react-select__option {
          border-bottom: 1px solid ${theme.colors.borderColor};
          padding: 1rem;

          &:hover,
          &--is-focused {
            background: ${theme.colors.mainBg};
            color: ${theme.colors.body} !important;
          }

          &--is-selected {
            background: ${theme.colors.primary};
            color: ${theme.colors.body} !important;

            &:hover,
            &--is-focused {
              background: ${theme.colors.primary};
              color: ${theme.colors.body} !important;
            }
          }

          &:first-child {
            border-top-left-radius: ${theme.border.radius.xsmall};
            border-top-right-radius: ${theme.border.radius.xsmall};
          }

          &:last-child {
            border-bottom: none;
            border-bottom-left-radius: ${theme.border.radius.xsmall};
            border-bottom-right-radius: ${theme.border.radius.xsmall};
          }
        }
      }
    }

    ${variant === 'material' && wrapperModifiers.material(theme)}
  `}
`

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.black};
    cursor: pointer;
  `}
`

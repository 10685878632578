import {
  addHours,
  addMinutes,
  addYears,
  differenceInDays,
  format,
  isSameDay,
  isTomorrow,
} from 'date-fns'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'

import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { ModalFooter, ModalOverflow } from '@/components/Modal'
import Radio from '@/components/Radio'
import { Permission } from '@/enums/permission.enum'
import useAuth from '@/hooks/useAuth'

import * as S from './styles'

export type EditSettingsAvailabilityModalProps = {
  closeAvailability: (againAt?: string) => void
}

type FormErrorsType = {
  availability: string
}

const EditSettingsAvailabilityModal = ({
  closeAvailability,
}: EditSettingsAvailabilityModalProps) => {
  const { merchantSelected, user } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [availability, setAvailability] = useState(30)
  const [formErrors, setFormErrors] = useState<FormErrorsType>({
    availability: '',
  })
  const isSuperAdmin = _.includes(user?.permissions, Permission.SUPER_ADMIN)

  const dateNow = useMemo(() => {
    return new Date()
  }, [])

  const saveAvailabilityOff = useCallback(async () => {
    setIsLoading(true)

    if (!availability) {
      setFormErrors((old) => ({
        ...old,
        availability: 'Turn OFF for is required',
      }))
      setIsLoading(false)
      return
    }

    setFormErrors((old) => ({ ...old, availability: '' }))

    let dataToSave: string

    if (availability === 30) {
      dataToSave = addMinutes(dateNow, 30).toISOString()
    } else if (availability === 60) {
      dataToSave = addHours(dateNow, 1).toISOString()
    } else if (availability === 1) {
      dataToSave = merchantSelected?.nextShift
        ? new Date(merchantSelected.nextShift).toISOString()
        : ''
    } else {
      dataToSave = addYears(dateNow, 100).toISOString()
    }

    closeAvailability(dataToSave)
  }, [availability, closeAvailability, dateNow, merchantSelected])

  const getMessageDate = useCallback(() => {
    if (merchantSelected?.nextShift) {
      const date = new Date(merchantSelected.nextShift)
      const diffDays = differenceInDays(date, dateNow)
      const isToday = isSameDay(date, dateNow)
      const isTmw = isTomorrow(date)

      if (diffDays === 0) {
        if (isToday) {
          return 'Today'
        } else if (isTmw) {
          return 'Tomorrow'
        }
      } else {
        return format(date, 'EEEE')
      }
    }
    return ''
  }, [dateNow, merchantSelected])

  return (
    <>
      <ModalOverflow>
        <S.FormControl onClick={() => setAvailability(30)}>
          <Radio
            label="30 Minutes"
            labelFor="30minutes"
            id="30minutes"
            name="availability"
            value={30}
            checked={availability === 30}
            onCheck={(v) => setAvailability(Number(v))}
          />
          <span>
            Available at {format(addMinutes(dateNow, 30), 'hh:mmaaa')}
          </span>
        </S.FormControl>
        <S.FormControl onClick={() => setAvailability(60)}>
          <Radio
            label="1 Hour"
            labelFor="1hour"
            id="1hour"
            name="availability"
            value={60}
            checked={availability === 60}
            onCheck={(v) => setAvailability(Number(v))}
          />
          <span>Available at {format(addHours(dateNow, 1), 'hh:mmaaa')}</span>
        </S.FormControl>
        <S.FormControl onClick={() => setAvailability(1)}>
          <Radio
            label="Until Next Shift"
            labelFor="next_shift"
            id="next_shift"
            name="availability"
            value={1}
            checked={availability === 1}
            onCheck={(v) => setAvailability(Number(v))}
          />
          <span>
            Next Shift {getMessageDate()}{' '}
            {merchantSelected?.nextShift
              ? format(new Date(merchantSelected.nextShift), 'hh:mmaaa')
              : '0:00'}
          </span>
        </S.FormControl>
        {isSuperAdmin && (
          <S.FormControl
            onClick={() => setAvailability(2)}
            style={{ height: '4.188rem' }}
          >
            <Radio
              label="Undefined"
              labelFor="undefined"
              id="undefined"
              name="availability"
              value={2}
              checked={availability === 2}
              onCheck={(v) => setAvailability(Number(v))}
            />
          </S.FormControl>
        )}

        {formErrors.availability && (
          <S.FormControl>
            <S.Error>{formErrors.availability}</S.Error>
          </S.FormControl>
        )}
      </ModalOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
          style={{ marginTop: '1rem' }}
        >
          <Button
            color="white"
            size="large"
            onClick={() => closeAvailability()}
            shadow
          >
            Cancel
          </Button>

          <Button
            size="large"
            disabled={isLoading}
            onClick={saveAvailabilityOff}
            loading={isLoading}
            style={{ marginLeft: 'auto' }}
          >
            Save
          </Button>
        </Box>
      </ModalFooter>
    </>
  )
}

export default EditSettingsAvailabilityModal

import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { Box } from '@/components/Box'
import Button from '@/components/Button'
import Fab from '@/components/Fab'
import { Wrapper as SelectWrapper } from '@/components/Select/styles'
import SelectButton from '@/components/SelectButton'

export const Content = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.spacings.xlarge};
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-x: auto;

    .tooltip {
      border-radius: 8px;
      font-family: ${theme.font.quicksand};
      font-size: ${theme.font.sizes.xsmall};
    }

    ${media.lessThan('medium')`
      > ${Box} {
        gap: 1rem;
        align-items: start;
      }

      ${ExpandButton} {
        align-self: end;
      }
    `}

    ${media.lessThan('small')`
      > ${Box} {
        flex-direction: column;
      }
    `}
  `}
`

export const Header = styled.header`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: ${theme.spacings.medium};

    h1 {
      font-size: ${theme.font.sizes.xlarge};
      color: ${theme.colors.primary};
      font-family: ${theme.font.nunito};
      font-weight: ${theme.font.extraBold};
    }
  `}
`

export const FormBox = styled(Box)`
  gap: 1.25rem;
  align-items: flex-end;

  ${media.lessThan('medium')`
    gap: 0.625rem;
  `}
`

export const FormControl = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 0.625rem;

    ${SelectWrapper} {
      width: 9.375rem;

      .react-select__control {
        border: 2px solid ${theme.colors.mainBg};

        &:focus,
        &:active,
        &--is-focused {
          border-color: ${theme.colors.lightGray};
          box-shadow: none;

          &:hover {
            border-color: ${theme.colors.lightGray};
          }
        }
      }
    }

    ${media.lessThan('medium')`
      flex-direction: column;
      align-items: flex-start;

      ${SelectWrapper} {
        width: 6.25rem;

        .react-select__control {
          padding-left: 0.125rem;
        }
      }
    `}
  `}
`

export const Label = styled.label`
  ${({ theme }) => css`
    font-family: ${theme.font.nunito};
    font-weight: ${theme.font.semiBold};
    font-size: ${theme.font.sizes.small};
    line-height: 1.188rem;
    color: ${theme.colors.black};
  `}
`

export const SelectButtonTime = styled(SelectButton)`
  ${({ theme }) => css`
    margin: 0;
    padding: 0.438rem;
    min-width: auto;
    font-size: ${theme.font.sizes.xsmall};
  `}
`

export const FabButtonSave = styled(Fab)`
  ${({ theme }) => css`
    border-radius: ${theme.border.radius.large};
    height: 3.125rem;
    font-size: ${theme.font.sizes.medium};
    padding: ${theme.spacings.xxsmall} ${theme.spacings.xlarge};
  `}
`

export const ExpandButton = styled(Button)`
  ${({ theme }) => css`
    padding: 0;

    svg {
      width: ${theme.spacings.medium};
      max-height: ${theme.spacings.medium};
    }
  `}
`

import styled, { css } from 'styled-components'

export const OptionsList = styled.ul`
  list-style: none;
`

export const OptionItem = styled.li`
  ${({ theme }) => css`
    padding: ${theme.spacings.xsmall} 0;

    & + & {
      border-top: 1px solid #dcdcdc;
    }
  `}
`

import styled, { css } from 'styled-components'

type MenuListProps = {
  width?: string
}

export const MenuList = styled.ul<MenuListProps>`
  ${({ theme, width = '12.5rem' }) => css`
    list-style: none;
    font-size: ${theme.font.sizes.small};
    width: ${width};
  `}
`

type MenuItemProps = {
  disabled?: boolean
}

export const MenuItem = styled.li<MenuItemProps>`
  ${({ theme, disabled = false }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.body};
    background-color: ${theme.colors.mainBg};
    text-decoration: none;
    padding: ${theme.spacings.xxsmall};
    cursor: pointer;
    min-width: 9.375rem;

    ${disabled &&
    css`
      cursor: not-allowed;
      background-color: ${theme.colors.mainBg};
      color: ${theme.colors.lightGray};
    `}

    &:first-child {
      border-top-left-radius: ${theme.border.radius.xsmall};
      border-top-right-radius: ${theme.border.radius.xsmall};
    }

    &:last-child {
      border-bottom-left-radius: ${theme.border.radius.xsmall};
      border-bottom-right-radius: ${theme.border.radius.xsmall};
    }

    &:hover {
      background-color: ${theme.colors.mainBg};
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${theme.colors.mainBg};
    }
  `}
`

export const MenuText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const MenuIcon = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 2rem;
    max-height: 1.25rem;
    color: ${theme.colors.gray};
    padding-right: ${theme.spacings.xxsmall};
  `}
`

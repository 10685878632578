import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    h1 {
      font-family: ${theme.font.nunito};
      font-weight: ${theme.font.semiBold};
      font-size: ${theme.font.sizes.large};
    }

    h6 {
      font-weight: ${theme.font.regular};
      color: ${theme.colors.superLight};
      font-size: ${theme.font.sizes.xsmall};
      margin-top: 0.25rem;
    }
  `}
`

export const Append = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.quicksand};
    font-weight: ${theme.font.regular};
    padding-left: ${theme.spacings.xxsmall};
  `}
`

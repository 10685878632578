import {
  HighlightOff as CloseIcon,
  Search as SearchIcon,
} from '@styled-icons/material-outlined'
import _ from 'lodash'
import { KeyboardEvent, memo, useCallback, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import Button from '@/components/Button'
import TextField from '@/components/TextField'

import * as S from './styles'

type TableSearchProps<T> = {
  data: T[]
  filterCb: (search: string, data: T[]) => T[]
  onFilter: (data: T[] | null) => void
  disabled?: boolean
}

const TableSearch = <T,>({
  data,
  filterCb,
  onFilter,
  disabled,
}: TableSearchProps<T>) => {
  const [filterText, setFilterText] = useState('')

  const handleFilter = useCallback(
    (search: string, data: T[]) => {
      if (search) {
        let filtered: T[] = []
        filtered = filterCb(search, data)
        onFilter(filtered)
      } else {
        onFilter(data)
      }
    },
    [filterCb, onFilter],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(_.debounce(handleFilter, 300), [])

  const handleClear = () => {
    setFilterText('')
    debouncedOnChange('', data)
  }

  useEffect(() => {
    if (data.length > 0) {
      debouncedOnChange(filterText, data)
    } else {
      onFilter(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, data])

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      e.currentTarget.blur()
    }
  }

  return (
    <S.Actions>
      <S.TextFieldWrapper>
        <TextField
          type="text"
          name="search_menu"
          placeholder="Search by name"
          disabled={disabled}
          value={filterText}
          onKeyPress={handleKeyPress}
          icon={
            filterText ? (
              <>
                <Button
                  onClick={handleClear}
                  icon={<CloseIcon />}
                  style={{ padding: 0 }}
                  data-tip="Clear search"
                  minimal
                />
                <ReactTooltip
                  effect="solid"
                  className="tooltip"
                  backgroundColor="#433d3d"
                  borderColor="#433d3d"
                />
              </>
            ) : (
              <SearchIcon />
            )
          }
          iconPosition="right"
          onChange={(event) => setFilterText(event.target.value)}
        />
      </S.TextFieldWrapper>
    </S.Actions>
  )
}

export default memo(TableSearch) as typeof TableSearch

import { zodResolver } from '@hookform/resolvers/zod'
import { add } from 'date-fns'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { StepForm, StepOverflow } from '@/components/FormStep'
import { useFormStepData } from '@/components/FormStep/Hook'
import {
  ModalFooter,
  ModalHeader,
  ModalHeaderClose,
  ModalHeaderTitle,
} from '@/components/Modal'
import {
  Header,
  ImageWrapper,
  Info,
  WrapperContent,
} from '@/modals/CreatingNewCampaignModal/styles'
import { Campaign, CampaignList } from '@/types/super-partner.types'

import { AudienceField } from '../../AudienceField'
import { DatesRangeField } from '../../DatesRangeField'
import { ItemsField } from '../../ItemsField'
import { MinimumOrderField } from '../../MinimumOrderField'
import { Total } from '../../Total'
import { VouchersField } from '../../VouchersField'
import { BeforeType } from '..'

export type CampaignListInitialData = {
  vouchers: {
    voucherShift: number
    voucherQuantity: number
    totalSpent: number
  }
  minimumOrderValue: number
  removeAudiencesComponent?: boolean
  autoSelectAudience?: string
  removeAudiences?: boolean
}

export type VoucherFormProps = {
  close: (isSuccess?: boolean) => void
  campaignItem: CampaignList<CampaignListInitialData> | null
  campaign: Campaign
  active: boolean
  before: BeforeType
  onNextStep: () => void
}

const voucherFormSchema = z.object({
  audiences: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        count: z.number(),
        selected: z.boolean(),
      }),
    )
    .max(5, 'Max 5 audiences')
    .optional(),
  itemIds: z.array(z.string()).optional(),
  usersPercentage: z.number().optional(),
  total: z.number().optional(),
  selectedCount: z.number().optional(),
  vouchers: z.object(
    {
      voucherShift: z.coerce.number(),
      voucherQuantity: z.coerce.number(),
      totalSpent: z.coerce.number(),
    },
    { required_error: 'Vouchers is required' },
  ),
  minimumOrderValue: z.coerce.number({
    invalid_type_error: 'Minimum order amount is required',
  }),
  period: z.object(
    { startAt: z.date(), endAt: z.date() },
    { required_error: 'Period is required' },
  ),
})

export type VoucherFormData = z.infer<typeof voucherFormSchema>

export const VoucherForm = ({
  campaignItem,
  campaign,
  onNextStep,
  active,
  before,
  close,
}: VoucherFormProps) => {
  const { dataForm, setStepFormValues } = useFormStepData<VoucherFormData>()

  const initialValues = campaignItem?.initialData
  const isMinimumOrderValueCustom = (minimumOrderValue?: number) => {
    if (minimumOrderValue) {
      const min = minimumOrderValue
      return min !== 1000 && min !== 2000 && min !== 3000
    }
    return false
  }

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    setError,
    formState: { isValid, isSubmitting },
  } = useForm<VoucherFormData>({
    resolver: zodResolver(voucherFormSchema),
    mode: 'onChange',
    defaultValues: {
      audiences: dataForm?.audiences || undefined,
      itemIds: dataForm?.itemIds || undefined,
      vouchers: dataForm?.vouchers || initialValues?.vouchers || undefined,
      minimumOrderValue:
        dataForm?.minimumOrderValue ||
        initialValues?.minimumOrderValue ||
        undefined,
      period: dataForm?.period || {
        startAt: new Date(),
        endAt: add(new Date(), {
          days: 10,
        }),
      },
      total: dataForm?.total || 0,
      selectedCount: dataForm?.selectedCount || 999,
    },
  })

  const onSubmit = (values: VoucherFormData) => {
    setStepFormValues(values)
    onNextStep()
  }

  const vouchers = watch('vouchers')
  const audiences = watch('audiences')
  const total = watch('total')
  const selectedCount = watch('selectedCount')
  const usersPercentage = watch('usersPercentage')

  const calculateAudienceCount = useMemo(() => {
    if (!usersPercentage || !selectedCount) return 0
    return Number(((usersPercentage / 100) * selectedCount).toFixed(0))
  }, [usersPercentage, selectedCount])

  useEffect(() => {
    if (
      selectedCount &&
      selectedCount > 0 &&
      vouchers?.voucherQuantity > selectedCount
    ) {
      setError('vouchers', {
        type: 'manual',
        message: `Voucher quantity must be less than or equal to ${selectedCount} audiences selected`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vouchers?.voucherQuantity, setError])

  return (
    <>
      <StepForm onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          <ModalHeaderTitle>{campaign?.title}</ModalHeaderTitle>

          <ModalHeaderClose
            onClick={(event) => {
              event.preventDefault()
              close()
            }}
          >
            ✕
          </ModalHeaderClose>
        </ModalHeader>

        <StepOverflow active={active ? 1 : 0} before={before.active ? 1 : 0}>
          <WrapperContent>
            <Header>
              <Info>
                <p>{campaign?.description}</p>
              </Info>

              <ImageWrapper>
                <img src={campaign?.image} alt={campaign?.title} />
              </ImageWrapper>
            </Header>

            <Controller
              name="vouchers"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <VouchersField
                  selectedCount={selectedCount}
                  vouchers={vouchers}
                  onChange={field.onChange}
                  error={error?.message}
                  before={before.active && before.field === 'vouchers'}
                  isInitialDataCustom={
                    initialValues?.vouchers &&
                    initialValues.vouchers.voucherShift > 5000
                  }
                />
              )}
            />

            <Controller
              name="minimumOrderValue"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <MinimumOrderField
                  value={field.value}
                  onChange={field.onChange}
                  error={error?.message}
                  isInitialDataCustom={isMinimumOrderValueCustom(
                    initialValues?.minimumOrderValue,
                  )}
                />
              )}
            />

            <Controller
              name="itemIds"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <ItemsField
                  value={field.value}
                  onChange={field.onChange}
                  error={error?.message}
                  campaignType={campaign?.type}
                />
              )}
            />

            {!initialValues?.removeAudiences && (
              <Controller
                name="audiences"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <AudienceField
                    value={field.value}
                    onChange={field.onChange}
                    error={error?.message}
                    onSelectedCount={(value) => {
                      if (value === 0 && audiences && audiences?.length > 0) {
                        setError('audiences', {
                          type: 'manual',
                          message: '',
                        })
                      }
                      setValue('selectedCount', value)
                    }}
                    onSelectPercentage={(value) =>
                      setValue('usersPercentage', value)
                    }
                    onAudiencePrice={(value) => setValue('total', value)}
                    before={before.active && before.field === 'audiences'}
                    autoSelectAudience={initialValues?.autoSelectAudience}
                  />
                )}
              />
            )}

            <Controller
              name="period"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatesRangeField
                  vouchers={vouchers}
                  value={field.value}
                  onChange={field.onChange}
                  error={error?.message}
                />
              )}
            />
          </WrapperContent>
        </StepOverflow>

        <ModalFooter>
          <Total
            audiences={audiences || []}
            total={total || 0}
            selectedCount={calculateAudienceCount || 999}
          />

          <Box
            flex
            alignItems="center"
            JustifyContent="space-between"
            width="100%"
          >
            <Button size="large" color="white" onClick={() => close()} shadow>
              Cancel
            </Button>
            <Button
              type="submit"
              size="large"
              disabled={!isValid || isSubmitting}
            >
              Continue
            </Button>
          </Box>
        </ModalFooter>
      </StepForm>
    </>
  )
}

import { ModeEdit } from '@styled-icons/material-outlined/ModeEdit'
import { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import pinBag from '@/assets/img/pin-bag.svg'
import Empty from '@/components/Empty'
import { OrderAddressType } from '@/types/orders.types'

import * as S from './styles'

type AddressListProps = {
  items: OrderAddressType[]
  onEditAddress: (address: OrderAddressType | null) => void
  loading?: boolean
}

const AddressList = ({ items, onEditAddress, loading }: AddressListProps) => {
  const [addresses, setAddresses] = useState<OrderAddressType[]>([])

  useEffect(() => {
    if (items.length > 0) {
      setAddresses(items)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <S.Addresses>
      {addresses.length === 0 ? (
        <S.Box>
          <Empty
            title=""
            description="You don’t have any registered address yet."
          />
        </S.Box>
      ) : (
        addresses.map((address, index) => (
          <S.Address key={`${address.streetName}-${index}`}>
            <S.WrapperButton>
              <S.AddressImage>
                <img src={pinBag} alt="Pin bag" />{' '}
              </S.AddressImage>

              <S.AddressDescription>
                <h3>
                  {address.description}
                  {address.isDefault && <span>default</span>}
                </h3>
                <p>
                  {address.streetNumber} {address.streetName}
                </p>
                <p>{address.city}</p>
                <p>Extra Info: {address.extraInfo}</p>
              </S.AddressDescription>
            </S.WrapperButton>

            <S.WrapperActions>
              <S.EditButton
                onClick={() => {
                  onEditAddress(address)
                }}
                disabled={loading}
                data-tip="Edit"
              >
                <ModeEdit size={18} />
              </S.EditButton>
              <ReactTooltip
                effect="solid"
                className="tooltip"
                backgroundColor="#433d3d"
                borderColor="#433d3d"
              />
            </S.WrapperActions>
          </S.Address>
        ))
      )}
    </S.Addresses>
  )
}

export default AddressList

import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'
import styled, { css, keyframes } from 'styled-components'

const open = keyframes`
  from { height: 0 }
  to { height: 'var(--radix-collapsible-content-height)' }
`
const close = keyframes`
  from { height: 'var(--radix-collapsible-content-height)' }
  to { height: 0 }
`

export const Icon = styled.div`
  ${({ theme }) => css`
    width: 2rem;
    color: ${theme.colors.primary};
  `}
`

export const Trigger = styled(CollapsiblePrimitive.Trigger)`
  border: none;
  padding: 0;
  background-color: inherit;
  cursor: pointer;
`

export const Content = styled(CollapsiblePrimitive.Content)`
  overflow: hidden;

  &[data-state='open'] {
    animation: ${open} 300ms ease-out;
  }
  &[data-state='closed'] {
    animation: ${close} 300ms ease-out;
  }
`

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, DialogContent } from '@iguanads/react'
import {
  HighlightOff as CloseIcon,
  Search as SearchIcon,
} from '@styled-icons/material-outlined'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import { useTheme } from 'styled-components'

import configsToStorageService from '@/api/configs-to-storage.service'
import dashboardService from '@/api/dashboard.service'
import inputOrderIcon from '@/assets/img/icons-input-order.svg'
import performanceDelivery from '@/assets/img/icons-performance-delivery.svg'
import performanceDeliveryLight from '@/assets/img/icons-performance-delivery-light.svg'
import performanceTakeout from '@/assets/img/icons-performance-takeout.svg'
import performanceTakeoutLight from '@/assets/img/icons-performance-takeout-light.svg'
import { Box } from '@/components/Box'
import Button from '@/components/Button'
import Empty from '@/components/Empty'
import MessagingContainer from '@/components/Messaging'
import { Modal, ModalContent } from '@/components/Modal'
import Skeleton from '@/components/Skeleton'
import Table, {
  getColorModifier,
  statusModifiers,
  TypeEnum,
} from '@/components/Table'
import * as T from '@/components/Table/styles'
import TextField from '@/components/TextField'
import { OrderDetailDialog } from '@/dialogs/order-detail.dialog'
import { useDialog } from '@/hooks/use-dialog'
import useAudio from '@/hooks/useAudio'
import useAuth from '@/hooks/useAuth'
import useLiveOrders from '@/hooks/useLiveOrders'
import useModal from '@/hooks/useModal'
import ConfirmModal from '@/modals/ConfirmModal'
import { OrderType } from '@/types/orders.types'
import { formatDistanceToNow } from '@/utils/formats/date'
import { formatCurrencyWithCode } from '@/utils/formats/number'

import packageJson from '../../../../package.json'
import * as S from './styles'

export function LiveOrders() {
  const [orderDetailData, setOrderDetailData] = useState<OrderType>()
  const [filteredOrders, setFilteredOrders] = useState<OrderType[]>([])
  const { orders, ongoing, isLoading, setIsLoading } = useLiveOrders()
  const [filterText, setFilterText] = useState('')
  const { open: modalIsOpen, toggle: setModalIsOpen } = useDialog()
  const { open: openDialogConfirmModal, toggle: setOpenDialogConfirmModal } =
    useModal()
  const { merchantsSelected, isDialogShowed, setIsDialogShowed, timezone } =
    useAuth()
  const { playing, play, pause, canPlay } = useAudio()
  const theme = useTheme()
  const { t } = useTranslation()

  const handleFilterChange = (search: string, orders: OrderType[]) => {
    if (search) {
      const users = orders.filter(
        ({ user }) =>
          user.name && user.name.toLowerCase().includes(search.toLowerCase()),
      )
      const ids = orders.filter(
        ({ shortId }) =>
          shortId && shortId.toLowerCase().includes(search.toLowerCase()),
      )

      setFilteredOrders([...users, ...ids])
    } else {
      setFilteredOrders(orders)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    setFilteredOrders(orders)
  }, [orders])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(_.debounce(handleFilterChange, 300), [])

  function openOrderDetail(orderDetail: OrderType) {
    setOrderDetailData(orderDetail)
    pause()
    setModalIsOpen(true)
  }

  const renderOpenDetailModal = () => {
    if (!orderDetailData) return null
    return (
      <Dialog open={modalIsOpen} onOpenChange={setModalIsOpen}>
        <DialogContent hideCloseButton onClose={() => setModalIsOpen(false)}>
          <OrderDetailDialog
            order={orderDetailData}
            onClose={() => setModalIsOpen(false)}
            setOrder={setOrderDetailData}
          />
        </DialogContent>
      </Dialog>
    )
  }

  const renderDialogConfirmModal = () => (
    <Modal
      open={openDialogConfirmModal}
      onOpenChange={setOpenDialogConfirmModal}
    >
      <ModalContent
        title="Live Orders"
        onClose={() => setOpenDialogConfirmModal(false)}
        width="auto"
        xsWidth="90vw"
        xsHeight="auto"
        hideCloseButton
      >
        <ConfirmModal
          confirmLabel="Okay"
          removeCancelButton
          message={
            <>
              Use the <strong>Live</strong> tab to receive orders in real-time
            </>
          }
          closeConfirm={() => {
            setOpenDialogConfirmModal(false)
            setIsDialogShowed(true)

            if (orders && orders.length > 0) {
              const hasNewOrders = orders?.some(
                (order) => order.lastStatus === 'created',
              )

              if (!hasNewOrders && playing) {
                pause()
              }

              if (hasNewOrders && canPlay) {
                play()
              }
            }
          }}
        />
      </ModalContent>
    </Modal>
  )

  const setOrderRead = useCallback(async (readOrders: OrderType[]) => {
    if (readOrders.length > 0) {
      const calls: Promise<any>[] = []
      readOrders.forEach(({ orderId }) => {
        if (orderId) {
          calls.push(dashboardService.setOrderRead(orderId))
        }
      })
      if (calls.length > 0) {
        await Promise.all(calls)
      }
    }
  }, [])

  const saveTabletStatus = useCallback(async () => {
    if (merchantsSelected.length > 0) {
      let battery: number | undefined
      const newNavigator: any = navigator
      const version = packageJson.version

      if ('getBattery' in newNavigator) {
        const batteryPromise = await newNavigator.getBattery()
        battery = Number((batteryPromise.level * 100).toFixed(0))
      }

      if (battery) {
        try {
          const merchants = merchantsSelected.map((item) => item.id)
          await dashboardService.multipleMerchantsSaveTabletStatus(
            merchants,
            battery,
            version,
            configsToStorageService.getMessagingToken(),
          )
        } catch (error) {}
      }
    }
  }, [merchantsSelected])

  const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setFilterText(value)
  }

  const handleClear = () => {
    setFilterText('')
    debouncedOnChange('', orders || [])
  }

  const columns = useMemo(
    () => [
      {
        name: 'Mode',
        selector: (row: OrderType) => row.type,
        sortable: true,
        center: true,
        width: '60px',
        cell: (row: OrderType) => {
          if (theme.light)
            return (
              <img
                data-tag="allowRowEvents"
                src={
                  row.type === TypeEnum.DELIVERY
                    ? performanceDelivery
                    : performanceTakeout
                }
                width={32}
                height={32}
                alt={row.type}
              />
            )
          else {
            return (
              <img
                data-tag="allowRowEvents"
                src={
                  row.type === TypeEnum.DELIVERY
                    ? performanceDeliveryLight
                    : performanceTakeoutLight
                }
                width={32}
                height={32}
                alt={row.type}
              />
            )
          }
        },
      },
      {
        name: 'Customer',
        selector: (row: OrderType) => row.user.name,
        sortable: true,
        center: true,
        minWidth: '146px',
        cell: (row: OrderType) => (
          <T.Client>
            <T.ClientName data-tag="allowRowEvents">
              {row.user.name}
            </T.ClientName>
            <T.ClientShortId data-tag="allowRowEvents">
              {row.shortId}
            </T.ClientShortId>
          </T.Client>
        ),
      },
      {
        name: 'Status',
        selector: (row: OrderType) => row.lastStatus,
        sortable: true,
        center: true,
        minWidth: '85px',
        cell: (row: OrderType) => {
          const isCollected =
            row.driver && row.driver.tripStatus === 'collected'
          if (row.trip && isCollected) {
            return (
              <T.StatusWrapper
                data-tag="allowRowEvents"
                bgColor={getColorModifier('collected', theme)}
                style={{ color: theme.colors.secondary }}
              >
                {statusModifiers.collected}
              </T.StatusWrapper>
            )
          }

          return (
            <T.StatusWrapper
              data-tag="allowRowEvents"
              bgColor={getColorModifier(row.lastStatus, theme)}
            >
              {statusModifiers[row.lastStatus]}
            </T.StatusWrapper>
          )
        },
      },
      {
        name: 'Value',
        selector: (row: OrderType) => row.total,
        sortable: true,
        center: true,
        minWidth: '110px',
        cell: (row: OrderType) => {
          return (
            <T.CurrencyWrapper data-tag="allowRowEvents">
              {formatCurrencyWithCode(row.total)}
            </T.CurrencyWrapper>
          )
        },
      },
      {
        name: 'Date',
        selector: (row: OrderType) => row.createdAt,
        sortable: true,
        center: true,
        id: 'createdAt',
        minWidth: '118px',
        cell: (row: OrderType) => (
          <T.DateWrapper data-tag="allowRowEvents">
            {formatDistanceToNow(new Date(row.createdAt), true, timezone)}
          </T.DateWrapper>
        ),
      },
    ],
    [theme, timezone],
  )

  useEffect(() => {
    if (
      orders &&
      orders.length > 0 &&
      !modalIsOpen &&
      !openDialogConfirmModal
    ) {
      const hasNewOrders = orders.some(
        (order) => order.lastStatus === 'created',
      )

      if (!hasNewOrders && playing) {
        pause()
      }

      if (hasNewOrders && canPlay) {
        play()
      }
    }

    return () => pause()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, canPlay, modalIsOpen, openDialogConfirmModal])

  useEffect(() => {
    if (orders && orders.length > 0) {
      const readOrders = orders.filter((order) => !order.isRead)

      if (readOrders.length > 0) {
        setOrderRead(readOrders)
      }
    }
  }, [orders, setOrderRead])

  useEffect(() => {
    if (orders) {
      if (orders.length > 0) {
        debouncedOnChange(filterText, orders)
      } else {
        setFilteredOrders([])
        setIsLoading(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, orders])

  useEffect(() => {
    saveTabletStatus()
    const handle = setInterval(
      () => {
        saveTabletStatus()
      },
      10 * 60 * 1000,
    )

    return () => clearInterval(handle)
  }, [saveTabletStatus])

  useEffect(() => {
    if (
      orders &&
      orders.length > 0 &&
      !modalIsOpen &&
      !openDialogConfirmModal
    ) {
      const hasNewOrders = orders.some(
        (order) => order.lastStatus === 'created',
      )

      if (hasNewOrders && !playing && canPlay) {
        play()
      }
    }

    return () => pause()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, canPlay, playing, modalIsOpen, openDialogConfirmModal])

  useEffect(() => {
    if (!isDialogShowed) {
      setOpenDialogConfirmModal(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const createdAtInterval = setInterval(() => {
      setFilteredOrders((old) => [
        ...old.map((order) => ({
          ...order,
          createdAt: order.createdAt,
        })),
      ])
    }, 60000)

    return () => clearInterval(createdAtInterval)
  }, [])

  return (
    <>
      <Helmet
        title={`${ongoing?.length > 0 ? `(${ongoing.length})` : ''} ${t('pages.live-orders.title')}`}
      />
      <S.Wrapper>
        <S.Header>
          <Box
            flex
            alignItems="center"
            JustifyContent="space-between"
            width="100%"
          >
            <h1>
              {t('pages.live-orders.title')}{' '}
              {ongoing?.length > 0 && `(${ongoing.length})`}
            </h1>

            <Box flex alignItems="center" gap="1.25rem">
              <S.TextFieldWrapper>
                <TextField
                  type="text"
                  name="search_order"
                  placeholder="Search by name or order"
                  disabled={isLoading || orders?.length === 0}
                  value={filterText}
                  icon={
                    filterText ? (
                      <>
                        <Button
                          onClick={handleClear}
                          icon={<CloseIcon />}
                          style={{ padding: 0 }}
                          data-tip="Clear search"
                          minimal
                        />
                        <ReactTooltip
                          effect="solid"
                          className="tooltip"
                          backgroundColor="#433d3d"
                          borderColor="#433d3d"
                        />
                      </>
                    ) : (
                      <SearchIcon />
                    )
                  }
                  iconPosition="right"
                  onChange={handleChangeFilter}
                />
              </S.TextFieldWrapper>

              <S.Link to="/orders" title="History">
                see history{' '}
                <img
                  src={inputOrderIcon}
                  width={24}
                  height={24}
                  alt="Input order icon"
                />
              </S.Link>
            </Box>
          </Box>
        </S.Header>

        <S.ContentTable>
          {isLoading ? (
            <>
              <Box
                flex
                alignItems="center"
                JustifyContent="space-between"
                width="100%"
                style={{ marginBottom: '1.75rem' }}
              >
                <Skeleton
                  width={4}
                  height={1.4}
                  count={5}
                  radius={0.4}
                  direction="row"
                />
              </Box>

              <Skeleton height={4} count={7} radius={0.4} margin={4} />
            </>
          ) : filteredOrders?.length === 0 ? (
            <Box
              flex
              JustifyContent="center"
              alignItems="center"
              width="100%"
              height="31.25rem"
            >
              <Empty
                title="There is nothing here, yet"
                description="Make sure you are available to receive orders"
              />
            </Box>
          ) : (
            <>
              <Table<OrderType>
                defaultSortFieldId="createdAt"
                defaultSortAsc={false}
                columns={columns as any}
                data={filteredOrders?.slice(0, 30)}
                onRowClicked={openOrderDetail}
                conditionalRowStyles={[
                  {
                    when: (row) => row.lastStatus === 'created',
                    classNames: ['new_order'],
                  },
                ]}
                highlightOnHover={true}
                pointerOnHover={true}
              />
              <ReactTooltip
                effect="solid"
                className="tooltip"
                backgroundColor="#433d3d"
                borderColor="#433d3d"
              />
            </>
          )}
        </S.ContentTable>

        {renderOpenDetailModal()}
        {openDialogConfirmModal && renderDialogConfirmModal()}
        <MessagingContainer />
      </S.Wrapper>
    </>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { KeyboardArrowDown as ArrowDownIcon } from '@styled-icons/material-rounded/KeyboardArrowDown'
import {
  // eslint-disable-next-line import/no-named-default
  default as ReactSelect,
  OptionTypeBase,
  Props as ReactSelectProps,
} from 'react-select'

import * as S from './styles'

export type SelectProps<T extends OptionTypeBase> = {
  label?: string
  labelFor?: string
  variant?: 'default' | 'material'
} & ReactSelectProps<T>

const CustomIndicator = ({ innerProps }: any) => (
  <div {...innerProps}>
    <ArrowDownIcon size={28} />
  </div>
)

const CustomIndicatorSeparator = ({ innerProps }: any) => (
  <div {...innerProps} />
)

const Select = <T extends OptionTypeBase>({
  label,
  labelFor = '',
  variant = 'default',
  isSearchable = false,
  components,
  ...props
}: SelectProps<T>) => (
  <S.Wrapper variant={variant}>
    {!!label && <S.Label htmlFor={labelFor}>{label}</S.Label>}
    <ReactSelect
      classNamePrefix="react-select"
      {...props}
      isSearchable={isSearchable}
      components={{
        DropdownIndicator: CustomIndicator,
        IndicatorSeparator: CustomIndicatorSeparator,
        ...components,
      }}
    />
  </S.Wrapper>
)

export default Select

import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'

import menuMakerService from '@/api/menu-maker.service'
import { Box } from '@/components/Box'
import Button from '@/components/Button'
import Empty from '@/components/Empty'
import List from '@/components/List'
import {
  ModalButtonClose,
  ModalFooter,
  ModalOverflow,
} from '@/components/Modal'
import Switch from '@/components/Switch'
import TextField from '@/components/TextField'
import { ItemType } from '@/types/menu-maker.types'

import * as S from './styles'

export type ChangeAvailabilityItemsModalProps = {
  items: ItemType[]
  closeChangeAvailabilityItems: (refresh?: boolean) => void
}

const ChangeAvailabilityItemsModal = ({
  items,
  closeChangeAvailabilityItems,
}: ChangeAvailabilityItemsModalProps) => {
  const [internalItems, setInternalItems] = useState<ItemType[]>(items)
  const [itemsToSave, setItemsToSave] = useState<ItemType[]>([])
  const [filteredInternalItems, setFilteredInternalItems] =
    useState<ItemType[]>(internalItems)
  const [filterText, setFilterText] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleFilterChange = (search: string, internalItems: ItemType[]) => {
    if (search) {
      let internalItemsFiltered = []
      if (search.startsWith('#')) {
        internalItemsFiltered = internalItems.filter(({ categoriesName }) => {
          const catName = categoriesName.filter((cat) =>
            cat.toLowerCase().includes(search.replace('#', '').toLowerCase()),
          )
          return catName.length > 0
        })
      } else {
        internalItemsFiltered = internalItems.filter(
          ({ name }) =>
            name && name.toLowerCase().includes(search.toLowerCase()),
        )
      }
      setFilteredInternalItems([...internalItemsFiltered])
    } else {
      setFilteredInternalItems(internalItems)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(_.debounce(handleFilterChange, 300), [])

  const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setFilterText(value)
  }

  const handleAvailabilityApplyToSave = useCallback(
    (item: ItemType) => {
      const index = itemsToSave.findIndex((i) => i.itemId === item.itemId)

      if (index === -1) {
        setItemsToSave((old) => [...old, item])
        return
      }

      const newItems = [...itemsToSave]
      newItems.splice(index, 1)
      setItemsToSave(newItems)
    },
    [itemsToSave],
  )

  const toCents = (price: number) => {
    const str = price.toString()
    const [int] = str.split('.')

    return Number(
      price
        .toFixed(2)
        .replace('.', '')
        .padEnd(int.length === 1 ? 3 : 4, '0'),
    )
  }

  const handleAvailabilityApply = useCallback(
    (item: ItemType) => {
      const index = internalItems.findIndex((i) => i.itemId === item.itemId)
      const newItems = [...internalItems]

      newItems[index] = {
        ...item,
        price: toCents(item.price),
        prices:
          item.prices?.map((p) => ({ ...p, price: toCents(p.price) })) ?? [],
        available: !item.available,
      }
      handleAvailabilityApplyToSave(newItems[index])
      setInternalItems(newItems)
    },
    [handleAvailabilityApplyToSave, internalItems],
  )

  const handleSave = useCallback(async () => {
    try {
      if (itemsToSave.length === 0) {
        return
      }

      setIsLoading(true)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const saveItemsCall: any[] = []

      itemsToSave.forEach((item) => {
        const itemToSave = {
          ...item,
          price: parseInt(item.price.toString().replace('.', ''), 10),
          prices: item.prices?.map((price) => ({
            ...price,
            price: parseInt(price.price.toString().replace('.', ''), 10),
          })),
          modifiers: item.modifiers?.map((modifier) => ({
            ...modifier,
            options: modifier.options?.map((option) => ({
              ...option,
              price: parseInt(option.price.toString().replace('.', ''), 10),
            })),
          })),
        }
        saveItemsCall.push(menuMakerService.saveItems(itemToSave))
      })

      await Promise.all(saveItemsCall)
      closeChangeAvailabilityItems(true)
    } catch (error) {
      setIsLoading(false)
    }
  }, [closeChangeAvailabilityItems, itemsToSave])

  useEffect(() => {
    if (internalItems.length > 0) {
      debouncedOnChange(filterText, internalItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, internalItems])

  return (
    <>
      <ModalOverflow>
        <S.SearchWrapper>
          <TextField
            type="text"
            name="search_menu"
            placeholder="Search by name"
            disabled={isLoading || internalItems.length === 0}
            value={filterText}
            iconPosition="right"
            onChange={handleChangeFilter}
            variant="material"
          />
        </S.SearchWrapper>

        <S.Content>
          {filteredInternalItems.length === 0 ? (
            <Box
              flex
              JustifyContent="center"
              alignItems="center"
              width="100%"
              height="12.5rem"
            >
              <Empty title="There is nothing here, yet" />
            </Box>
          ) : (
            <List<ItemType> data={filteredInternalItems}>
              {(item, index) => (
                <>
                  <Box flex alignItems="center" gap="1rem" width="95%">
                    <S.ImageWrapper>
                      {item.featuredPicture ? (
                        <img
                          src={item.featuredPicture}
                          data-tag="allowRowEvents"
                          width={60}
                          height={60}
                          alt={item.name}
                        />
                      ) : (
                        '-'
                      )}
                    </S.ImageWrapper>
                    <S.Name>{item.name}</S.Name>
                  </Box>

                  <Switch
                    checked={!!item.available}
                    id={`availability_${index}`}
                    onChange={() => handleAvailabilityApply(item)}
                    disabled={isLoading}
                  />
                </>
              )}
            </List>
          )}
        </S.Content>
      </ModalOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
          style={{ marginTop: '1rem' }}
        >
          <ModalButtonClose
            color="white"
            size="large"
            onClick={() => closeChangeAvailabilityItems()}
            shadow
          >
            Close
          </ModalButtonClose>
          <span />
          <Button
            size="large"
            onClick={handleSave}
            disabled={isLoading || itemsToSave.length === 0}
            loading={isLoading}
          >
            Save
          </Button>
        </Box>
      </ModalFooter>
    </>
  )
}

export default ChangeAvailabilityItemsModal

import React from 'react'

import * as S from './styles'

export type SkeletonProps = {
  width?: string | number
  height?: string | number
  circle?: boolean
  radius?: string | number
  count?: number
  direction?: 'row' | 'column'
  margin?: string | number
}

const Skeleton = ({ count, ...rest }: SkeletonProps) => {
  const renderWrapper = () => <S.Wrapper {...rest} />

  if (count) {
    return (
      <>
        {Array.from(Array(count).keys()).map((item) => (
          <React.Fragment key={item}>{renderWrapper()}</React.Fragment>
        ))}
      </>
    )
  }
  return renderWrapper()
}

export default Skeleton

import { useCallback, useEffect, useState } from 'react'

import Alert from '@/components/Alert'
import { Box } from '@/components/Box'
import Checkbox from '@/components/Checkbox'
import Pill from '@/components/Pill'
import Select from '@/components/Select'
import Slider from '@/components/Slider'
import useAuth from '@/hooks/useAuth'
import { api } from '@/lib/axios'
import {
  Amount,
  FieldContent,
  FieldError,
  FieldHeader,
  FieldHeaderActions,
  FieldHeaderTitle,
  FieldWrapper,
} from '@/modals/CreatingNewCampaignModal/styles'
import { Audience } from '@/types/super-partner.types'
import { formatCurrency, formatExtNumber } from '@/utils/formats/number'

import * as S from './styles'

export type AudienceFieldProps = {
  value?: Audience[]
  error?: string
  before?: boolean
  onChange: (value: Audience[]) => void
  onSelectedCount?: (value: number) => void
  onSelectPercentage?: (value: number) => void
  onAudiencePrice?: (value: number) => void
  autoSelectAudience?: string
  campaignType?: string
}

export const AudienceField = ({
  error,
  onChange,
  value,
  onSelectedCount,
  onSelectPercentage,
  onAudiencePrice,
  before,
  autoSelectAudience,
  campaignType,
}: AudienceFieldProps) => {
  const [audiences, setAudiences] = useState<Audience[]>([])
  const [audiencePrice, setAudiencePrice] = useState(0)
  const [selectedCount, setSelectedCount] = useState(0)
  const [peopleCount, setPeopleCount] = useState(0)
  const [selectedPercent, setSelectedPercent] = useState(50)
  const [isLoading, setIsLoading] = useState(true)
  const [isCountLoading, setIsCountLoading] = useState(false)
  const { merchantSelected } = useAuth()

  const selectsAudiences: Audience[] = audiences.filter((item) => item.selected)

  const handleSelectAudience = useCallback(
    (audience: Audience | null) => {
      if (!audience) return

      const newAudiences = audiences.map((item) => {
        if (item.id === audience.id) {
          return { ...item, selected: !item.selected }
        }
        return item
      })

      setAudiences(newAudiences)
      onChange(newAudiences.filter((item) => item.selected))
    },
    [audiences, onChange],
  )

  async function getAudiences(merchantId: string) {
    try {
      const { data } = await api.get(`/superpartner/${merchantId}/audience`)

      setAudiences(
        data.map((audience: Audience) => ({
          ...audience,
          selected: audience.id === autoSelectAudience,
        })),
      )
    } finally {
      setIsLoading(false)
    }
  }

  const getAudienceCount = useCallback(
    async (selectedAudiences: Audience[]) => {
      if (merchantSelected) {
        try {
          setIsCountLoading(true)

          const audiencesIds = selectedAudiences.map((item) => item.id)
          const { data } = await api.get(
            `/superpartner/${merchantSelected.id}/audience/count`,
            {
              params: {
                audiences: audiencesIds.join(','),
              },
            },
          )

          const { count } = data

          setSelectedCount(count)
          onSelectedCount && onSelectedCount(count)
        } finally {
          setIsCountLoading(false)
        }
      }
    },
    [merchantSelected, onSelectedCount],
  )

  const getAudiencePercent = useCallback(
    async (selectedAudiences: Audience[]) => {
      if (merchantSelected && selectedPercent) {
        try {
          const audiencesIds = selectedAudiences.map((item) => item.id)
          const { data } = await api.post(
            `/superpartner/${merchantSelected.id}/campaigns/${
              campaignType === 'LOYALTY' ? 'loyalty' : 'voucher'
            }/simulation`,
            {
              audiences: audiencesIds,
              usersPercentage: selectedPercent,
            },
          )

          setAudiencePrice(data.price)
          // setSelectedCount(data.quantity)
          onSelectPercentage && onSelectPercentage(selectedPercent)
          // onSelectedCount && onSelectedCount(data.quantity)
          // eslint-disable-next-line no-empty
        } finally {
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [merchantSelected, onSelectPercentage, selectedPercent],
  )

  const calculateAudienceCount = useCallback(() => {
    return Number(((selectedPercent / 100) * selectedCount).toFixed(0))
  }, [selectedPercent, selectedCount])

  useEffect(() => {
    if (merchantSelected) {
      getAudiences(merchantSelected.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const selectedAudiences = audiences.filter((item) => item.selected)
    if (selectedAudiences.length > 0) {
      onSelectedCount && onSelectedCount(999)
      getAudienceCount(selectedAudiences)
    } else {
      setSelectedCount(0)
      setAudiencePrice(0)
      setSelectedPercent(50)
      setPeopleCount(0)
      onSelectedCount && onSelectedCount(999)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audiences])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let time: NodeJS.Timeout = null as any
    const selectedAudiences = audiences.filter((item) => item.selected)
    if (selectedAudiences.length > 0 && selectedPercent > 0) {
      time = setTimeout(() => getAudiencePercent(selectedAudiences), 300)
      setPeopleCount(calculateAudienceCount)
    } else {
      setAudiencePrice(0)
      setSelectedPercent(50)
      setPeopleCount(0)
    }

    return () => clearTimeout(time)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audiences, selectedPercent, calculateAudienceCount])

  useEffect(() => {
    if (audiences.length > 0 && autoSelectAudience) {
      onChange(audiences.filter((item) => item.selected))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audiences, onChange])

  useEffect(() => {
    onAudiencePrice && onAudiencePrice(audiencePrice)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audiencePrice])

  useEffect(() => {
    if (before) {
      const element = document.getElementById('audiences')
      if (element) {
        element.scrollIntoView({ block: 'center', behavior: 'smooth' })
      }
    }
  }, [before])

  return (
    <FieldWrapper before={before ? 1 : 0} id="audiences">
      <FieldHeader>
        <FieldHeaderTitle>
          Audiences <span>(Up to 5 audiences)</span>
        </FieldHeaderTitle>
        <FieldHeaderActions>
          {isCountLoading ? (
            <S.LoadingSpinner />
          ) : (
            <>
              {/* <span>~{formatExtNumber(Number(selectedCount), 0)} people</span> */}
              <span>${formatCurrency(audiencePrice / 100)}</span>
            </>
          )}
        </FieldHeaderActions>
      </FieldHeader>

      <FieldContent>
        <S.AudienceContent>
          <S.FieldContentSelect>
            <Select<Audience>
              options={audiences}
              variant="material"
              formatOptionLabel={(option) => (
                <Box flex alignItems="center" JustifyContent="space-between">
                  <Box flex alignItems="center" gap="0.5rem">
                    <Checkbox checked={option.selected} />
                    <span>{option.name}</span>
                  </Box>

                  <div>~{formatExtNumber(option.count, 0)}</div>
                </Box>
              )}
              placeholder="Select audiences..."
              controlShouldRenderValue={false}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              captureMenuScroll={false}
              value={value}
              onChange={(value) => handleSelectAudience(value)}
              isLoading={isLoading}
              components={{
                LoadingIndicator: () => <S.LoadingSpinner />,
              }}
              loadingMessage={() => 'Loading...'}
            />
            {error && <FieldError>{error}</FieldError>}

            {selectsAudiences.length > 0 && (
              <Box
                flex
                gap="0.25rem"
                flexWrap="wrap"
                style={{ margin: '1.5rem 0 0.625rem' }}
              >
                {selectsAudiences.map((audience) => (
                  <Pill
                    key={audience.id}
                    avatar={
                      <Amount>~{formatExtNumber(audience.count, 0)}</Amount>
                    }
                    onClose={() => handleSelectAudience(audience)}
                  >
                    {audience.name}
                  </Pill>
                ))}
              </Box>
            )}

            {selectsAudiences.length > 0 && (
              <>
                {peopleCount === 0 && !isCountLoading && (
                  <Alert variant="error" style={{ marginTop: '2rem' }}>
                    This audience combination does not reach anyone, make sure
                    you select audiences with more customers.
                  </Alert>
                )}

                <FieldHeaderTitle
                  style={{
                    margin: `${peopleCount === 0 ? '0' : '2rem 0 3.25rem'}`,
                  }}
                >
                  Reaches ~{formatExtNumber(Number(peopleCount), 0)} people{' '}
                  {peopleCount > 0 && <span>(Slide to set)</span>}
                </FieldHeaderTitle>

                {peopleCount > 0 && (
                  <Box width="18.75rem">
                    <Slider
                      value={selectedPercent}
                      min={10}
                      max={100}
                      step={10}
                      aria-labelledby="Number of audience"
                      thumbClassName="slider-thumb"
                      trackClassName="slider-track"
                      renderThumb={(props, state) => (
                        <div {...props}>{state.valueNow}%</div>
                      )}
                      onChange={(value) => setSelectedPercent(value)}
                    />
                    <Box flex JustifyContent="space-between">
                      <span>10%</span>
                      <span>100%</span>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </S.FieldContentSelect>

          <S.AudienceInfo>
            <ul>
              <li>
                Audiences have a{' '}
                <strong>better potential for conversion</strong> in revenue
              </li>
              <li>
                Our algorithm will display your campaign{' '}
                <strong>only when it&apos;s appropriate</strong>
              </li>
              <li>
                We will use{' '}
                <strong>push notifications, banners, promotions</strong> and
                more to highlight your campaign.
              </li>
            </ul>
          </S.AudienceInfo>
        </S.AudienceContent>
      </FieldContent>
    </FieldWrapper>
  )
}

import styled, { css } from 'styled-components'

import { DropdownProps } from '.'

export const Title = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    color: ${theme.colors.body};
    position: relative;
    display: flex;
    align-items: center;
    padding-right: ${theme.spacings.xsmall};
    z-index: ${theme.layers.base};
  `}
`

const contentModifiers = {
  left: () => css`
    left: 0;

    &::before {
      left: 1.5rem;
    }
  `,
  right: () => css`
    right: 0;

    &::before {
      right: 1.5rem;
    }
  `,
  center: () => css`
    left: -20%;

    &::before {
      left: 50%;
    }
  `,
}

export const Content = styled.div<Pick<DropdownProps, 'position'>>`
  ${({ theme, position = 'right' }) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.colors.white};
    color: ${theme.colors.secondary};
    margin-top: ${theme.spacings.xxsmall};
    border-radius: ${theme.border.radius.xsmall};
    box-shadow: 0 0.125rem 0.188rem rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: ${theme.layers.base + 5};

    &::before {
      content: '';
      position: absolute;
      border-right: 0.5rem solid transparent;
      border-left: 0.5rem solid transparent;
      border-bottom: 0.5rem solid ${theme.colors.mainBg};
      top: -0.5rem;
    }

    ${contentModifiers[position]}
  `}
`

export const Overlay = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${theme.layers.base};
  `}
`

type WrapperProps = {
  isOpen?: boolean
}

const wrapperModifiers = {
  open: () => css`
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  `,
  close: () => css`
    opacity: 0;
    pointer-events: none;
    transform: translateY(-1.25rem);
  `,
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, isOpen }) => css`
    position: relative;
    width: max-content;

    ${Content},
    ${Overlay} {
      transition:
        transform 0.2s ease-in,
        opacity ${theme.transition.default};
      ${isOpen && wrapperModifiers.open()}
      ${!isOpen && wrapperModifiers.close()}
    }
  `}
`

import axios from 'axios'

import { env } from '@/env'
import { api } from '@/lib/axios'
import { OverviewCategoryType, OverviewType } from '@/types/overview.types'

const getMerchantMenus = (id: string, date: string) => {
  return axios.get<OverviewType>(
    `${env.VITE_APP_API_URL}/merchants/${id}/menu?date=${date}`,
  )
}

const saveMerchantOverview = (
  id: string,
  data: { categories: OverviewCategoryType[] },
) => {
  return api.post(`/merchants/${id}/overview/position`, data)
}

export default {
  getMerchantMenus,
  saveMerchantOverview,
}

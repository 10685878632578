import styled, { css } from 'styled-components'

export const Client = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ClientName = styled.div`
  ${({ theme }) => css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: ${theme.font.regular};
    max-width: 10.625rem;
    user-select: none;
  `}
`

export const ClientShortId = styled.div`
  ${({ theme }) => css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: ${theme.font.regular};
    color: ${theme.colors.lightGray};
    margin-top: 0.25rem;
    user-select: none;
  `}
`

export const StatusWrapper = styled.div<{ bgColor: string }>`
  ${({ theme, bgColor }) => css`
    user-select: none;
    background-color: ${bgColor};
    font-family: ${theme.font.quicksand};
    font-weight: ${theme.font.semiBold};
    color: ${theme.colors.white};
    font-size: 0.75rem;
    padding: 0.375rem 0.375rem;
    border-radius: 8px;
    width: 5rem;
    text-align: center;
  `}
`

export const DateWrapper = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.font.regular};
    color: ${theme.colors.lightGray};
    max-width: 6.25rem;
    text-align: center;
    user-select: none;
  `}
`

export const CurrencyWrapper = styled.div<{ refund?: boolean }>`
  ${({ theme, refund }) => css`
    font-weight: ${theme.font.semiBold};
    max-width: 8.75rem;
    width: 100%;
    text-align: center;
    user-select: none;

    ${refund &&
    css`
      color: ${theme.colors.red};
    `}
  `}
`

export const RefundInfoWrapper = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.font.regular};
    user-select: none;
  `}
`

export const ImageWrapper = styled.div`
  ${({ theme }) => css`
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 8px;
    background-color: ${theme.colors.mainBg};
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    svg {
      fill: ${theme.colors.lightGray};
    }
  `}
`

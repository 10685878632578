import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { Box } from '@/components/Box'

export const WrapperContent = styled.div`
  ${({ theme }) => css`
    .tooltip {
      border-radius: 0.5rem;
      font-family: ${theme.font.quicksand} !important;
      font-size: ${theme.font.sizes.xsmall} !important;
    }

    ${media.lessThan('medium')`
      > ${Box}:first-child {
        flex-direction: column;
      }
    `}
  `}
`

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacings.large};
  `}
`

export const Info = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 0.5rem;

    h4,
    p {
      font-size: 1.125rem;
      font-weight: ${theme.font.semiBold};
      color: ${theme.colors.supportDarker};
    }

    p {
      color: ${theme.colors.supportGray};
    }
  `}
`

export const FieldWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: ${theme.spacings.xsmall} ${theme.spacings.small};
    border-radius: ${theme.border.radius.medium};
    margin-bottom: ${theme.spacings.xsmall};
  `}
`

export const FieldContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spacings.small};

    ${media.lessThan('small')`
      flex-direction: column;
    `}
  `}
`

export const FieldHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacings.medium};
  `}
`

export const FieldHeaderTitle = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.font.semiBold};
    font-size: ${theme.font.sizes.medium};
  `}
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`

import { AxiosResponse } from 'axios'

import { api } from '@/lib/axios'
import {
  ApplyModifierItemType,
  CategoryType,
  ItemType,
  MenusType,
  ModifierType,
  SavePictureResponseType,
} from '@/types/menu-maker.types'

const getMenus = (uuid: string): Promise<AxiosResponse<MenusType[]>> => {
  return api.get<MenusType[]>(`/menu-maker/${uuid}/menus`)
}

const saveMenu = (data: MenusType): Promise<AxiosResponse<MenusType>> => {
  if (data.menuId) {
    return api.put<MenusType>(`/menu-maker/${data.merchant}/menu`, data)
  }
  return api.post<MenusType>(`/menu-maker/${data.merchant}/menu`, data)
}

const deleteMenu = (data: MenusType): Promise<AxiosResponse<void>> => {
  return api.delete(`/menu-maker/${data.merchant}/menu`, { data })
}

const getCategories = (
  uuid: string,
): Promise<AxiosResponse<CategoryType[]>> => {
  return api.get<CategoryType[]>(`/menu-maker/${uuid}/categories`)
}

const saveCategory = (
  data: CategoryType,
): Promise<AxiosResponse<CategoryType>> => {
  if (data.categoryId) {
    return api.put<CategoryType>(`/menu-maker/${data.merchant}/category`, data)
  }
  return api.post<CategoryType>(`/menu-maker/${data.merchant}/category`, data)
}

const deleteCategory = (data: CategoryType): Promise<AxiosResponse<void>> => {
  return api.delete(`/menu-maker/${data.merchant}/category`, { data })
}

const getItems = (uuid: string): Promise<AxiosResponse<ItemType[]>> => {
  return api.get<ItemType[]>(`/menu-maker/${uuid}/items`)
}

const saveItems = (data: ItemType): Promise<AxiosResponse<ItemType>> => {
  if (data.itemId) {
    return api.put<ItemType>(`/menu-maker/${data.merchant}/item`, data)
  }
  return api.post<ItemType>(`/menu-maker/${data.merchant}/item`, data)
}

const deleteItem = (data: ItemType): Promise<AxiosResponse<void>> => {
  return api.delete(`/menu-maker/${data.merchant}/item`, { data })
}

const savePicture = (
  merchant: string,
  itemId: string,
  picture: File,
): Promise<AxiosResponse<SavePictureResponseType[]>> => {
  const formData = new FormData()
  formData.append('file', picture)
  return api.post<SavePictureResponseType[]>(
    `/menu-maker/${merchant}/items/${itemId}/upload`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
}

const getModifiers = (uuid: string): Promise<AxiosResponse<ModifierType[]>> => {
  return api.get<ModifierType[]>(`/menu-maker/${uuid}/modifier`)
}

const saveModifier = (
  data: ModifierType,
): Promise<AxiosResponse<ModifierType>> => {
  if (data.modifierId) {
    return api.put<ModifierType>(`/menu-maker/${data.merchant}/modifier`, data)
  }
  return api.post<ModifierType>(`/menu-maker/${data.merchant}/modifier`, data)
}

const deleteModifier = (data: ModifierType): Promise<AxiosResponse<void>> => {
  return api.delete(`/menu-maker/${data.merchant}/modifier`, { data })
}

const saveApplyModifierItems = (
  data: ApplyModifierItemType,
): Promise<AxiosResponse<ApplyModifierItemType>> => {
  return api.post<ApplyModifierItemType>(
    `/menu-maker/${data.modifier.merchant}/modifier/bulk/items`,
    data,
  )
}

export default {
  getMenus,
  saveMenu,
  deleteMenu,
  getCategories,
  saveCategory,
  deleteCategory,
  getItems,
  saveItems,
  deleteItem,
  savePicture,
  getModifiers,
  saveModifier,
  deleteModifier,
  saveApplyModifierItems,
}

import { Area } from 'react-easy-crop'

import { ImageUploadType } from '@/modals/EditItemModal'

import { createImage } from './create'

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180
}

function limitSize(
  size: { width: number; height: number },
  maximumPixels: number,
) {
  const { width, height } = size

  const requiredPixels = width * height
  if (requiredPixels <= maximumPixels) return { width, height }

  const scalar = Math.sqrt(maximumPixels) / Math.sqrt(requiredPixels)
  return {
    width: Math.floor(width * scalar),
    height: Math.floor(height * scalar),
  }
}

export default async function getCroppedImg(
  imageSrc: string,
  pixelCrop: Area,
  rotation = 0,
  file: File,
  contrast: number,
  brightness: number,
  saturate: number,
  shadows: number,
): Promise<ImageUploadType> {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d')

  const maxSize = Math.max(image.width, image.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  const limitSafeSize = limitSize(
    { width: safeArea, height: safeArea },
    16777216,
  )

  canvas.width = limitSafeSize.width
  canvas.height = limitSafeSize.height

  ctx!.translate(limitSafeSize.width / 2, limitSafeSize.height / 2)
  ctx!.rotate(getRadianAngle(rotation))
  ctx!.translate(-limitSafeSize.width / 2, -limitSafeSize.height / 2)
  ctx!.filter = ` contrast(${contrast + 100}%)
                  brightness(${brightness + 100}%)
                  saturate(${saturate + 100}%)
                  grayscale(${shadows}%)`
  ctx!.fillStyle = '#ffffff'

  ctx!.fillRect(0, 0, canvas.width, canvas.height)

  ctx!.drawImage(
    image,
    limitSafeSize.width / 2 - image.width * 0.5,
    limitSafeSize.height / 2 - image.height * 0.5,
  )
  const data = ctx!.getImageData(
    0,
    0,
    limitSafeSize.width,
    limitSafeSize.height,
  )

  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  ctx!.putImageData(
    data,
    Math.round(0 - limitSafeSize.width / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - limitSafeSize.height / 2 + image.height * 0.5 - pixelCrop.y),
  )

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((fileBlob) => {
      resolve({
        file: new File([fileBlob as Blob], file?.name, {
          lastModified: new Date().getTime(),
          type: file?.type,
        }),
        imagePreview: URL.createObjectURL(fileBlob as Blob),
      })
    }, file?.type)
  })
}

import { lighten } from 'polished'
import styled, { css } from 'styled-components'

export const MonthList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`

type MonthItemProps = {
  active: boolean
  disabled: boolean
}

export const MonthItem = styled.li<MonthItemProps>`
  ${({ theme, active, disabled }) => css`
    padding: ${theme.spacings.xsmall} 0;
    flex: 1 0 21%;
    font-family: ${theme.font.quicksand};
    color: ${theme.colors.secondary};
    border: 1px solid ${theme.colors.lightGray};
    border-radius: ${theme.border.radius.medium};
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap 4px;
    justify-items: center;

    &:hover {
      background-color: ${theme.colors.mainBg};
    }

    ${
      !!active &&
      css`
        border: 1px solid rgb(255, 76, 76, 20%);
        background-color: rgb(255, 76, 76, 20%);
        color: ${theme.colors.primary};

        &:hover {
          background-color: ${lighten(0.25, theme.colors.primary)};
          color: ${theme.colors.primary};
          border-color: ${lighten(0.3, theme.colors.primary)};
        }
      `
    }

    ${
      !!disabled &&
      css`
        opacity: 0.3;
        cursor: not-allowed;
      `
    }
  `}
`

export const Period = styled.div`
  font-weigth: 800;
`

export const Price = styled.div`
  ${({ theme }) => css`
    font-weigth: bold;
    font-family: ${theme.font.nunito};
    color: ${theme.colors.primary};
    font-size: ${theme.font.sizes.xsmall};
  `}
`

import { ModeEditOutline as EditIcon } from '@styled-icons/material/ModeEditOutline'
import { Add as AddIcon } from '@styled-icons/material-outlined'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import menuMakerService from '@/api/menu-maker.service'
import { Box } from '@/components/Box'
import Empty from '@/components/Empty'
import Fab from '@/components/Fab'
import { Modal, ModalContent } from '@/components/Modal'
import Pagination from '@/components/Pagination'
import Skeleton from '@/components/Skeleton'
import TableData from '@/components/TableData'
import TableSearch from '@/components/TableSearch'
import useAuth from '@/hooks/useAuth'
import useModal from '@/hooks/useModal'
import EditMenuModal from '@/modals/EditMenuModal'
import { MenusType, ShiftType } from '@/types/menu-maker.types'
import { getHoursToList, getWeekDays } from '@/utils/formats/date'

import * as S from './styles'

const PAGE_SIZE = 50

const getStatus = (shifts: ShiftType[]) => {
  if (shifts.length) {
    const hasWeeks =
      shifts.length > 1
        ? shifts.some((item) => item.weekDays.length)
        : !!shifts[0].weekDays.length

    return hasWeeks ? 'Active' : 'Inactive'
  }
  return 'Inactive'
}

export function Menus() {
  const [menus, setMenus] = useState<MenusType[]>([])
  const [filteredMenus, setFilteredMenus] = useState<MenusType[] | null>([])
  const [editMenu, setEditMenu] = useState<MenusType | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal()
  const { merchantSelected } = useAuth()

  const modalTitle = `${editMenu ? 'Edit' : 'Create'} Menu`

  const handleFilterChange = (search: string, menus: MenusType[]) => {
    return menus.filter(
      ({ name }) => name && name.toLowerCase().includes(search.toLowerCase()),
    )
  }

  const getMenus = useCallback(async (id: string) => {
    setIsLoading(true)
    setHasError(false)

    try {
      const [resMenus, resCategories] = await Promise.all([
        menuMakerService.getMenus(id),
        menuMakerService.getCategories(id),
      ])
      const { data: dataMenus } = resMenus
      const { data: dataCat } = resCategories

      const menus = dataMenus.map((menu) => {
        return {
          ...menu,
          status: getStatus(menu?.shifts),
          itemsLength: menu.items?.length || 0,
          categoriesLength: menu.categories?.length || 0,
          categories: dataCat.filter((c) =>
            (menu.categories as string[])?.includes(c.categoryId!),
          ),
        }
      })

      if (menus.length === 0) {
        setIsLoading(false)
      }

      setMenus(menus)
    } catch (error) {
      setHasError(true)
      setMenus([])
      setFilteredMenus([])
      setIsLoading(false)
    }
  }, [])

  const openEditMenu = useCallback(
    (menu?: MenusType) => {
      setEditMenu(menu || null)
      setModalIsOpen(true)
    },
    [setModalIsOpen],
  )

  const closeEditMenu = (refresh?: boolean) => {
    if (refresh) {
      getMenus(merchantSelected!.id)
    }
    setModalIsOpen(false)
  }

  const renderOpenEditMenuModal = () => {
    return (
      <Modal open={modalIsOpen} onOpenChange={setModalIsOpen} modal>
        <ModalContent title={modalTitle} onClose={() => setModalIsOpen(false)}>
          <EditMenuModal
            closeEditMenu={closeEditMenu}
            editMenuData={editMenu}
            merchant={merchantSelected!.id}
          />
        </ModalContent>
      </Modal>
    )
  }

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: (row: MenusType) => row.name,
        sortable: true,
        width: '200px',
      },
      {
        name: 'Opening Hours',
        selector: (row: MenusType) => row.shifts,
        minWidth: '380px',
        cell: (row: MenusType) => (
          <div>
            {row.shifts.length > 0 &&
              row.shifts.map((item, index) => (
                <Fragment key={index}>
                  <div data-tag="allowRowEvents">
                    {item.weekDays.length > 0
                      ? getWeekDays(item.weekDays)
                      : 'Inactive'}{' '}
                    {getHoursToList(item.hours)}
                  </div>
                </Fragment>
              ))}
          </div>
        ),
      },
      {
        name: 'Categories',
        selector: (row: MenusType) => row.categoriesLength,
        sortable: true,
        center: true,
        minWidth: '120px',
        cell: (row: MenusType) => (
          <div data-tag="allowRowEvents">{row.categoriesLength}</div>
        ),
      },
      {
        name: 'Items',
        selector: (row: MenusType) => row.itemsLength,
        sortable: true,
        center: true,
        minWidth: '90px',
        cell: (row: MenusType) => (
          <div data-tag="allowRowEvents">{row.itemsLength}</div>
        ),
      },
      {
        name: 'Status',
        selector: (row: MenusType) => row.status,
        sortable: true,
        center: true,
        minWidth: '100px',
      },
      {
        name: '',
        center: true,
        minWidth: '40px',
        cell: (row: MenusType) => (
          <S.ButtonEdit
            icon={<EditIcon size={44} />}
            size="small"
            minimal
            onClick={() => openEditMenu(row)}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    if (filteredMenus && filteredMenus.length === 0) {
      setFilteredMenus(null)
    }
  }, [filteredMenus])

  useEffect(() => {
    if (merchantSelected) {
      getMenus(merchantSelected.id)
    }
  }, [getMenus, merchantSelected])

  return (
    <>
      <Helmet title="Menus" />
      <S.Content>
        <S.Header>
          <Box
            flex
            alignItems="center"
            JustifyContent="space-between"
            width="100%"
          >
            <h1>Menus</h1>

            <TableSearch<MenusType>
              data={menus}
              filterCb={handleFilterChange}
              onFilter={(data) => {
                setFilteredMenus(data)
                setIsLoading(false)
              }}
              disabled={isLoading || hasError || menus.length === 0}
            />
          </Box>
        </S.Header>

        {isLoading ? (
          <>
            <Box
              flex
              alignItems="center"
              JustifyContent="space-between"
              width="100%"
              style={{ marginBottom: '1.75rem' }}
            >
              <Skeleton
                width="2.5rem"
                height="0.875rem"
                count={5}
                radius="4px"
                direction="row"
              />
            </Box>

            <Skeleton height="2.5rem" count={7} radius="4px" margin="2.5rem" />
          </>
        ) : hasError ? (
          <Box
            flex
            JustifyContent="center"
            alignItems="center"
            width="100%"
            height="31.25rem"
          >
            <Empty
              title="Something Happened"
              description="Reload the page and try again"
            />
          </Box>
        ) : filteredMenus === null ? (
          <Box
            flex
            JustifyContent="center"
            alignItems="center"
            width="100%"
            height="31.25rem"
          >
            <Empty title="There is nothing here, yet" />
          </Box>
        ) : (
          <TableData<MenusType>
            data={filteredMenus || []}
            columns={columns}
            onEdit={openEditMenu}
            pagination
            paginationPerPage={PAGE_SIZE}
            paginationComponent={({
              currentPage,
              onChangePage,
              onChangeRowsPerPage,
              rowsPerPage,
            }) => (
              <Pagination
                currentPage={Number(currentPage)}
                totalCount={filteredMenus.length}
                pageSize={rowsPerPage}
                onPageChange={(page) =>
                  onChangePage(Number(page), filteredMenus.length)
                }
                onPageSizeChange={(size) =>
                  onChangeRowsPerPage(size, Number(currentPage))
                }
              />
            )}
          />
        )}

        <Fab size="large" onClick={() => openEditMenu()}>
          <AddIcon size={24} />
        </Fab>

        {!isLoading && modalIsOpen && renderOpenEditMenuModal()}
      </S.Content>
    </>
  )
}

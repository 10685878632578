import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { Box } from '@/components/Box'

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacings.medium} ${theme.spacings.small};
    margin-bottom: ${theme.spacings.small};

    .tooltip {
      border-radius: 8px;
      font-family: ${theme.font.quicksand} !important;
      font-size: ${theme.font.sizes.xsmall} !important;
    }
  `}
`

export const MenuForm = styled.div`
  textarea {
    width: 100%;
    min-height: 150px;
  }

  label {
    margin-bottom: 16px;
    display: block;
  }

  ${media.lessThan('medium')`


    ${Box} {
      width: 100%;
    }
  `}
`

import styled, { css } from 'styled-components'

import { CampaignViewsChartProps } from '.'

type WrapperProps = Pick<CampaignViewsChartProps, 'height'>

export const Wrapper = styled.div<WrapperProps>`
  ${({ height = '26.875rem' }) => css`
    width: 100%;
    height: ${height};
    margin: 0 auto;
  `}
`

export const Title = styled.h3`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    font-weight: ${theme.font.semiBold};
    color: ${theme.dark ? theme.colors.black : theme.colors.secondary};
  `}
`

export const Subtitle = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.nunito};
    font-size: 2.625rem;
    font-weight: ${theme.font.bold};
    line-height: 3rem;
    color: ${theme.colors.primary};
  `}
`

export const Tooltip = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.secondary};
    padding: 0.313rem ${theme.spacings.xxsmall};
    color: ${theme.colors.primaryText};
    border-radius: 0.5rem;
  `}
`

import { useCallback, useState } from 'react'

import ordersService from '@/api/orders.service'
import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { ModalFooter, ModalOverflow } from '@/components/Modal'
import TextArea from '@/components/TextArea'
import TextField from '@/components/TextField'
import Toast from '@/components/Toast'
import { OrderType } from '@/types/orders.types'

import * as S from './styles'

export type AddChargeModalProps = {
  orderId?: string
  closeAddCharge: (order?: OrderType) => void
}

type FormErrorsType = {
  reason: string
  amount: string
}

const AddChargeModal = ({ orderId, closeAddCharge }: AddChargeModalProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [reason, setReason] = useState('')
  const [amount, setAmount] = useState('')
  const [formErrors, setFormErrors] = useState<FormErrorsType>({
    reason: '',
    amount: '',
  })

  const canBeSave = reason === '' || amount === '' || amount === '0.00'

  const saveAddCharge = useCallback(async () => {
    setIsLoading(true)

    const data = {
      amount: parseInt(amount.toString().replace('.', ''), 10),
      comment: reason,
    }

    if (!data.comment) {
      setFormErrors((old) => ({ ...old, reason: 'Reason is required' }))
      setIsLoading(false)
      return
    }

    setFormErrors((old) => ({ ...old, reason: '' }))

    if (!data.amount) {
      setFormErrors((old) => ({ ...old, amount: 'Amount is required' }))
      setIsLoading(false)
      return
    }

    setFormErrors((old) => ({ ...old, amount: '' }))

    try {
      const { data: order } = await ordersService.saveAddCharge(data, orderId)
      Toast({
        type: 'success',
        title: 'Extra Charge',
        message: 'Extra charge successfully added.',
      })
      closeAddCharge(order)
    } finally {
      setIsLoading(false)
    }
  }, [amount, closeAddCharge, reason, orderId])

  return (
    <>
      <ModalOverflow>
        <S.TextNotice>
          <strong>Attention Partner:</strong> Ensure you contact customers
          before adding extra charges to orders.
        </S.TextNotice>

        <S.FormControl>
          <TextArea
            value={reason}
            onChange={(event) => setReason(event.currentTarget.value)}
            error={formErrors.reason}
            label="Reason"
            labelFor="reason"
            id="reason"
            disabled={isLoading}
            variant="material"
            style={{ width: '18.75rem' }}
          />
        </S.FormControl>

        <S.FormControl>
          <TextField
            value={amount}
            onChange={(event) => setAmount(event.currentTarget.value)}
            error={formErrors.amount}
            label="Amount"
            labelFor="amount"
            id="amount"
            mask="money"
            disabled={isLoading}
            variant="material"
          />
        </S.FormControl>
      </ModalOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
          style={{ marginTop: '1rem' }}
        >
          <Button
            color="white"
            size="large"
            onClick={() => closeAddCharge()}
            shadow
          >
            Cancel
          </Button>

          <Button
            size="large"
            disabled={isLoading || canBeSave}
            onClick={saveAddCharge}
            loading={isLoading}
            style={{ marginLeft: 'auto' }}
          >
            Save
          </Button>
        </Box>
      </ModalFooter>
    </>
  )
}

export default AddChargeModal

import { darken } from 'polished'
import styled, { css } from 'styled-components'

import Button from '@/components/Button'

export const CustomCloseButton = styled(Button)`
  ${({ theme }) => css`
    padding: 0.281rem 0.5rem;
    all: unset;
    background: none;
    color: ${theme.colors.primary};
    font-size: 1.75rem;
    border: 0;
    border-radius: 99999px;
    position: absolute;
    outline: none;
    cursor: pointer;

    &:hover {
      background: none;
      color: ${darken(0.2, theme.colors.primary)};
    }
  `}
`

export const ConditionsList = styled.ul`
  ${({ theme }) => css`
    padding-left: ${theme.spacings.xsmall};
  `}
`

export const TextToCopy = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.xxsmall} ${theme.spacings.xsmall};
    background-color: ${theme.colors.mainBg};
    border-radius: ${theme.border.radius.small};
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.xxsmall};

    h3 {
      font-size: ${theme.font.sizes.large};
      font-family: ${theme.font.nunito};
      color: ${theme.colors.primary};
    }

    p {
      font-size: ${theme.font.sizes.small};
    }

    a {
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.primary};
      word-wrap: break-word;
    }
  `}
`

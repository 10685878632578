import { darken, lighten } from 'polished'
import styled, { css } from 'styled-components'

export const MonthList = styled.div`
  ${({ theme }) => css`
    .react-datepicker {
      width: 100%;
      border: none;
      font-family: ${theme.font.quicksand};
      color: ${theme.colors.black};
      font-size: ${theme.font.sizes.medium};
      background-color: ${theme.colors.white};
      border: 1px solid ${theme.colors.mainBg};
      border-radius: ${theme.border.radius.medium};

      .react-datepicker__navigation {
        &--previous {
          left: ${theme.spacings.small};
        }
        &--next {
          right: ${theme.spacings.small};
        }

        .react-datepicker__navigation-icon {
          &::before {
            border-color: ${theme.colors.primary};
            border-width: 2px 2px 0 0;
            top: 8px;
          }
        }

        &:hover *::before {
          border-color: ${darken(0.1, theme.colors.primary)};
        }
      }

      .react-datepicker__month {
        padding: 0.625rem 0;
      }

      .react-datepicker__month-container {
        width: 100%;

        .react-datepicker__header {
          background-color: ${theme.colors.mainBg};
          color: ${theme.colors.black};
          font-size: ${theme.font.sizes.medium};
          border-bottom: 0;
          border-top-left-radius: ${theme.border.radius.medium};
          border-top-right-radius: ${theme.border.radius.medium};
        }

        .react-datepicker__month-wrapper {
          width: 100%;

          .react-datepicker__month-text {
            width: 24%;
            gap: 0.625rem;
            color: ${theme.colors.gray};
            border: 1px solid
              ${theme.dark ? theme.colors.mainBg : theme.colors.gray};
            border-radius: ${theme.border.radius.medium};
            padding: 0.813rem 0.625rem;

            &.react-datepicker__month-text--disabled {
              color: ${theme.dark
                ? lighten(0.155, theme.colors.secondary)
                : theme.colors.supportSuperLight} !important;
              background-color: ${theme.dark
                ? lighten(0.155, theme.colors.gray)
                : theme.colors.mainBg} !important;
              border: 1px solid ${theme.colors.mainBg};
              cursor: not-allowed;

              &:hover {
                background-color: transparent;
              }
            }

            &.react-datepicker__month-text--in-selecting-range,
            &.react-datepicker__month-text--in-selecting-range-start,
            &.react-datepicker__month-text--in-selecting-range-end {
              background-color: ${lighten(
                0.3,
                theme.colors.primary,
              )} !important;
              color: ${theme.colors.primary} !important;
              border: 1px solid ${theme.colors.primary} !important;

              &:hover {
                color: ${theme.colors.primary};
              }
            }

            &.react-datepicker__month-text--keyboard-selected {
              background-color: ${theme.colors.white};
              color: ${theme.colors.gray};
              border: 1px solid ${theme.colors.gray};
              border-radius: ${theme.border.radius.medium};
            }

            &.react-datepicker__month-text--selected,
            &.react-datepicker__month-text--in-range,
            &.react-datepicker__month-text--range-start,
            &.react-datepicker__month-text--range-end {
              background-color: ${theme.colors.primary};
              color: ${theme.dark ? theme.colors.body : theme.colors.white};
              border: 1px solid ${theme.colors.primary};
              transition: background-color 0.2s ease-in-out;

              &:hover {
                background-color: ${darken(0.2, theme.colors.primary)};
                color: ${theme.dark ? theme.colors.body : theme.colors.white};
              }
            }

            &:hover {
              background-color: ${theme.dark
                ? theme.colors.secondary
                : theme.colors.mainBg};
              color: ${theme.dark
                ? theme.colors.supportSuperLight
                : theme.colors.gray};
            }
          }
        }
      }
    }
  `}
`

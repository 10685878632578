import Lottie from 'react-lottie'

import bennyMagicImage from '@/assets/img/benny-magic.svg'
import animationData from '@/lib/magic_stars.json'

import * as S from './styles'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

export const VoucherAnimation = () => {
  return (
    <S.MagicVoucherPanel>
      <S.MagicVoucherContainer>
        <Lottie options={defaultOptions} height={400} width={400} />
        <img src={bennyMagicImage} alt="Benny Magic" />
        <span>Working my Magic!</span>
      </S.MagicVoucherContainer>
    </S.MagicVoucherPanel>
  )
}

import { darken } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { Wrapper as SelectWrapper } from '@/components/Select/styles'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    gap: 1rem;
    margin: ${theme.spacings.medium} 0 0;

    ${media.lessThan('medium')`
      flex-direction: column;
    `}
  `}
`

export const ListItem = styled.ul`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    gap: 0.5rem;
    font-family: ${theme.font.nunito};
  `}
`

type ItemProps = {
  selected?: boolean
  disabled?: boolean
  dots?: boolean
}

export const Item = styled.li<ItemProps>`
  ${({ theme, selected, dots, disabled }) => css`
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    padding: 0.313rem;
    background-color: ${selected ? theme.colors.primary : theme.colors.mainBg};
    color: ${selected ? theme.colors.primaryText : theme.colors.body};
    transition: background 0.2s ease-in-out;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    ${disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.3;
    `}

    ${dots &&
    css`
      cursor: default;
    `}

    &:hover {
      background-color: ${!selected &&
      !disabled &&
      !dots &&
      darken(0.05, theme.colors.mainBg)};
    }
  `}
`

export const Label = styled.label`
  ${({ theme }) => css`
    font-family: ${theme.font.nunito};
    font-weight: ${theme.font.semiBold};
    font-size: ${theme.font.sizes.small};
    line-height: 1.188rem;
    color: ${theme.colors.black};
  `}
`

export const FormControl = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    ${SelectWrapper} {
      width: 6.25rem;

      .react-select__control {
        border: 2px solid ${theme.colors.mainBg};

        &:focus,
        &:active,
        &--is-focused {
          border-color: ${theme.colors.lightGray};
          box-shadow: none;

          &:hover {
            border-color: ${theme.colors.lightGray};
          }
        }
      }
    }

    ${media.lessThan('medium')`
      flex-direction: column;
      align-items: flex-start;

      ${SelectWrapper} {
        width: 6.25rem;

        .react-select__control {
          padding-left: 0.125rem;
        }
      }
    `}
  `}
`

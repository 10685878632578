import campaignSearchPosition from '@/assets/img/icon-campaign-search-position.svg'
import campaignFeaturedIcon from '@/assets/img/icons-campaign-featured.svg'
import campaignVoucherGiveawayIcon from '@/assets/img/icons-campaign-giveaway.svg'
import campaignIncreaseIcon from '@/assets/img/icons-campaign-increase.svg'
import campaignPriceIcon from '@/assets/img/icons-campaign-price.svg'
import campaignTakeoutIcon from '@/assets/img/icons-campaign-takeout.svg'
import attractNewCustomersIllustration from '@/assets/img/illustrations/attract-new-customers.svg'
import improveYourAverageTicketIllustration from '@/assets/img/illustrations/improve-your-average-ticket.svg'
import increaseOrderVolumeIllustration from '@/assets/img/illustrations/increase-order-volume.svg'
import loyaltyProgramsIllustration from '@/assets/img/illustrations/loyalty-programs.svg'
import searchPagePositionIllustration from '@/assets/img/illustrations/search-page-position.svg'
import { Box } from '@/components/Box'
import { Campaign } from '@/types/super-partner.types'

export const campaignList: Campaign[] = [
  {
    id: '1',
    type: 'VOUCHER',
    icon: campaignIncreaseIcon,
    image: increaseOrderVolumeIllustration,
    title: 'Increase order volume',
    brief: 'Boost Your Orders',
    description:
      'This campaign allows you to encourage customers to purchase more from you. You can now inspire your customers to make more orders with attractive discounts.\nStart now to see your order volume increase.',
    items: [
      {
        id: '1',
        title: (
          <>
            <strong>Spend over $20</strong>, save $3
          </>
        ),
        initialData: {
          vouchers: {
            voucherShift: 300,
            voucherQuantity: 50,
            totalSpent: 300 * 50,
          },
          minimumOrderValue: 2000,
        },
      },
      {
        id: '2',
        title: (
          <>
            <strong>Spend over $15</strong>, save $5
          </>
        ),
        initialData: {
          vouchers: {
            voucherShift: 500,
            voucherQuantity: 50,
            totalSpent: 500 * 50,
          },
          minimumOrderValue: 1500,
        },
      },
    ],
  },
  {
    id: '2',
    type: 'LOYALTY',
    icon: campaignFeaturedIcon,
    image: loyaltyProgramsIllustration,
    title: 'Loyalty Programs',
    brief:
      'Now you can show appreciation to your loyal customers by offering them a delightful reward.',
    description: `A loyalty program boosts repeat business by rewarding customers with discounts and freebies.\nIt strengthens customer loyalty, increases satisfaction, and provides valuable insights into preferences.\nIt's a win-win for your establishment and customers alike.`,
    items: [
      {
        id: '1',
        title: (
          <>
            <strong>$5 reward</strong> after 5 orders
          </>
        ),
        initialData: {
          campaign: {
            reward: 500,
            orders: 5,
          },
        },
      },
      {
        id: '2',
        title: (
          <>
            <strong>$10 reward</strong> after 10 orders
          </>
        ),
        initialData: {
          campaign: {
            reward: 1000,
            orders: 10,
          },
        },
      },
      {
        id: '3',
        title: (
          <>
            <strong>$15 reward</strong> after 20 orders
          </>
        ),
        initialData: {
          campaign: {
            reward: 1500,
            orders: 20,
          },
        },
      },
    ],
  },
  {
    id: '3',
    type: 'VOUCHER',
    icon: campaignPriceIcon,
    image: improveYourAverageTicketIllustration,
    title: 'Improve your average ticket',
    brief:
      'Drive more sales with this special campaign! Encourage your customers to spend just a bit more and watch your numbers grow.',
    description:
      'Implementing this campaign will help you increase your sales and revenue, creating a win-win scenario for both you and your customers.',
    items: [
      {
        id: '1',
        title: (
          <>
            <strong>Spend over $30</strong>, save $5
          </>
        ),
        initialData: {
          vouchers: {
            voucherShift: 500,
            voucherQuantity: 50,
            totalSpent: 500 * 50,
          },
          minimumOrderValue: 3000,
        },
      },
      {
        id: '2',
        title: (
          <>
            <strong>Spend over $50</strong>, save $5
          </>
        ),
        initialData: {
          vouchers: {
            voucherShift: 500,
            voucherQuantity: 50,
            totalSpent: 500 * 50,
          },
          minimumOrderValue: 5000,
        },
      },
      {
        id: '3',
        title: (
          <>
            <strong>Spend over $80</strong>, save $10
          </>
        ),
        initialData: {
          vouchers: {
            voucherShift: 1000,
            voucherQuantity: 50,
            totalSpent: 1000 * 50,
          },
          minimumOrderValue: 8000,
        },
      },
    ],
  },
  {
    id: '4',
    type: 'VOUCHER',
    icon: campaignTakeoutIcon,
    image: attractNewCustomersIllustration,
    title: 'Attract new customers',
    brief:
      'Offer Exclusive Deals and Incredible Discounts for First-Time Customers!',
    description:
      'Now, you have the opportunity to attract fresh customers by offering a generous discount to first-time customers, giving them an irresistible reason to choose you.',
    items: [
      {
        id: '1',
        title: (
          <>
            New customers get <strong>$5 off</strong>
            <br />
            if they <strong>spend over $20</strong>
          </>
        ),
        initialData: {
          autoSelectAudience: 'NEW_CUSTOMERS',
          vouchers: {
            voucherShift: 500,
            voucherQuantity: 50,
            totalSpent: 500 * 50,
          },
          minimumOrderValue: 2000,
        },
      },
      {
        id: '2',
        title: (
          <>
            New customers get <strong>$10 off</strong>
            <br />
            if they <strong>spend over $40</strong>
          </>
        ),
        initialData: {
          autoSelectAudience: 'NEW_CUSTOMERS',
          vouchers: {
            voucherShift: 1000,
            voucherQuantity: 50,
            totalSpent: 1000 * 50,
          },
          minimumOrderValue: 4000,
        },
      },
      {
        id: '3',
        title: (
          <>
            New customers get <strong>$10 off</strong>
            <br />
            if they <strong>spend over $60</strong>
          </>
        ),
        initialData: {
          autoSelectAudience: 'NEW_CUSTOMERS',
          vouchers: {
            voucherShift: 1000,
            voucherQuantity: 50,
            totalSpent: 1000 * 50,
          },
          minimumOrderValue: 6000,
        },
      },
    ],
  },
  {
    id: '5',
    type: 'POSITION',
    icon: campaignSearchPosition,
    image: searchPagePositionIllustration,
    title: 'Search Page Position',
    brief: 'Stand Out from the Crowd! Secure Your Prime Spot Today!',
    description:
      "It's time to shine and seize the opportunity to take your business to new heights. Introducing our exclusive Premium App Positions, where you get to be at the TOP 5 partners. Secure Your Prime Spot Today and Dominate the Market!",
    items: [
      {
        id: '1',
        title: (
          <Box flex width="100%" JustifyContent="space-between">
            <div>
              <strong>1st</strong> position
            </div>
            <strong>$300</strong>
          </Box>
        ),
      },
      {
        id: '2',
        title: (
          <Box flex width="100%" JustifyContent="space-between">
            <div>
              <strong>2nd</strong> position
            </div>
            <strong>$260</strong>
          </Box>
        ),
      },
      {
        id: '3',
        title: (
          <Box flex width="100%" JustifyContent="space-between">
            <div>
              <strong>3rd</strong> position
            </div>
            <strong>$220</strong>
          </Box>
        ),
      },
      {
        id: '4',
        title: (
          <Box flex width="100%" JustifyContent="space-between">
            <div>
              <strong>4th</strong> position
            </div>
            <strong>$200</strong>
          </Box>
        ),
      },
      {
        id: '5',
        title: (
          <Box flex width="100%" JustifyContent="space-between">
            <div>
              <strong>5th</strong> position
            </div>
            <strong>$180</strong>
          </Box>
        ),
      },
    ],
  },
  {
    id: '6',
    type: 'VOUCHER',
    icon: campaignVoucherGiveawayIcon,
    image: increaseOrderVolumeIllustration,
    title: 'Giveaway Voucher',
    brief: 'Gratify Your Customers with Exclusive Giveaways!',
    description:
      'Get ready to spread the love and excitement with our incredible Voucher Giveaway Campaign! With this exciting opportunity, you can shower your valued customers with exclusive vouchers, adding an extra dose of joy to their ordering experience.',
    items: [
      {
        id: '1',
        title: 'Give away vouchers',
        initialData: {
          removeAudiences: true,
        },
      },
    ],
  },
]

import styled, { css } from 'styled-components'

import { InputWrapper } from '@/components/TextField/styles'

export const Actions = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;

    > div + div {
      margin-left: ${theme.spacings.small};
    }
  `}
`

export const TextFieldWrapper = styled.div`
  ${({ theme }) => css`
    width: 12.375rem;
    max-width: 13rem;

    ${InputWrapper} {
      border-color: ${theme.colors.mainBg};

      &:focus-within {
        border-color: ${theme.colors.lightGray};
      }
    }
  `}
`

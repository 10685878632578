import {
  differenceInHours,
  differenceInMinutes,
  format,
  getDay,
  getHours,
  getMinutes,
} from 'date-fns'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Skeleton from '@/components/Skeleton'
import useAuth from '@/hooks/useAuth'
import { db } from '@/lib/firebase'
import { MerchantType } from '@/types/merchant.types'

import MerchantInfoDropdown from './MerchantInfoDropdown'
import * as S from './styles'

const getMerchantsOpenStatus = (merchant: MerchantType) => {
  if (!merchant?.isOpen) return 'unavailable'
  return !merchant.isOnShift || merchant.servicesAvailable === null
}

const getNextShift = (merchant: MerchantType) => {
  const { isOnShift, nextShift } = merchant
  if (isOnShift) {
    return ''
  }

  const nextShiftDate = new Date(nextShift)
  const today = new Date()
  const ONE_HOUR = 60
  const TWO_HOURS = 120
  const hoursDiff = differenceInHours(nextShiftDate, today)

  const minsDiff = differenceInMinutes(nextShiftDate, today)
  if (minsDiff < 45) {
    return `Opens in ${minsDiff} minutes`
  }

  const isOneHourDifference = minsDiff <= ONE_HOUR
  if (isOneHourDifference) {
    return `Opens in 1 hour`
  }

  const isTwoHoursDifference = minsDiff <= TWO_HOURS
  if (isTwoHoursDifference) {
    return `Opens in ${hoursDiff} hours`
  }

  const minDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const hours = getHours(nextShiftDate)
  const minutes = getMinutes(nextShiftDate)
  const minDay = minDays[getDay(nextShiftDate)]
  const amPmHour = getAMPMHour(`${hours}:${minutes}`)

  return `Opens ${minDay} ${amPmHour}`
}

const getAMPMHour = (time: string) => {
  const [hour, min] = time.split(':')
  if (hour === 'NaN' || min === 'NaN') return ''
  return format(new Date().setHours(Number(hour), Number(min)), 'hh:mm a')
}

type MerchantInfoProps = {
  loading?: boolean
}

const MerchantInfo = ({ loading }: MerchantInfoProps) => {
  const { merchantSelected, onSetMerchantSelected } = useAuth()
  const { pathname } = useLocation()

  const today = getDay(new Date())
  const isClosed = getMerchantsOpenStatus(merchantSelected!)

  const hasToday = merchantSelected?.shifts?.some((shift) => {
    return shift.weekDays.some((day) => day === today)
  })

  const getLiveMerchant = useCallback(
    (id: string) => {
      const q = query(collection(db, 'merchants'), where('id', '==', id))

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const merchantData = querySnapshot?.docs[0]?.data() as MerchantType

        if (merchantData) {
          onSetMerchantSelected(merchantData.id)
        }
      })

      return unsubscribe
    },
    [onSetMerchantSelected],
  )

  useEffect(() => {
    let unsubscribe: () => void
    const isAdminRoute =
      pathname === '/settings' || pathname === '/admin-settings'

    if (merchantSelected?.id && isAdminRoute) {
      unsubscribe = getLiveMerchant(merchantSelected.id)
    }

    const timeToOpen =
      new Date(merchantSelected?.nextShift as string).getTime() -
      new Date().getTime()

    console.log(timeToOpen)
    setTimeout(() => {
      console.log('open')
      if (merchantSelected?.id) getLiveMerchant(merchantSelected?.id)
    }, timeToOpen)

    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderCookingTime = () => {
    return (
      <>
        {merchantSelected?.cookingTime} -{' '}
        {(merchantSelected?.cookingTime || 30) + 10} min
      </>
    )
  }

  const renderStatus = () => {
    return (
      <>
        {!isClosed && 'Available'}
        {isClosed &&
          isClosed !== 'unavailable' &&
          getNextShift(merchantSelected!)}
        {isClosed === 'unavailable' && 'Temporarily Unavailable'}
      </>
    )
  }

  const renderShifts = () => {
    return (
      <div>
        {merchantSelected?.shifts?.map((shift) => {
          const hasDay = shift.weekDays.some((day) => day === today)
          return (
            hasDay &&
            shift.hours.map((hour, index) => (
              <div key={index}>
                {getAMPMHour(hour.start)} - {getAMPMHour(hour.end)}
              </div>
            ))
          )
        })}
      </div>
    )
  }

  return (
    <S.Wrapper isClosed={!!isClosed}>
      {loading ? (
        <Skeleton width="130px" count={1} />
      ) : (
        <>
          <MerchantInfoDropdown
            status={renderStatus()}
            cookingTime={renderCookingTime()}
            shifts={renderShifts()}
            hasToday={hasToday}
            merchantSelected={merchantSelected}
            onSetMerchant={() => {
              getLiveMerchant(merchantSelected?.id || '')
            }}
          />
        </>
      )}
    </S.Wrapper>
  )
}

export default MerchantInfo

import styled, { css } from 'styled-components'

import { TopViewsPieChartProps } from '.'

type WrapperProps = Pick<TopViewsPieChartProps, 'height'>

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, height = '34.375rem' }) => css`
    width: 100%;
    min-width: 22.5rem;
    height: ${height};
    margin: 0 auto;

    .recharts-legend-item {
      span {
        color: ${theme.colors.secondary} !important;
      }
    }
  `}
`
export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.nunito};
    font-size: ${theme.font.sizes.xlarge};
    font-weight: ${theme.font.extraBold};
    color: ${theme.colors.primary};
    text-align: center;
  `}
`

export const Tooltip = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.secondary};
    padding: 0.313rem ${theme.spacings.xxsmall};
    color: ${theme.colors.primaryText};
    border-radius: 0.5rem;
  `}
`

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
`

export const ListItemBullet = styled.div<{ color: string }>`
  ${({ color }) => css`
    width: 1.25rem;
    height: 1.25rem;
    background-color: ${color};
    border-radius: 0.375rem;
    margin-right: 0.5rem;
  `}
`

export const ListItemText = styled.div`
  width: 7.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ListItem = styled.li`
  ${({ theme }) => css`
    display: flex;
    padding-right: ${theme.spacings.xsmall};
    color: ${theme.colors.body};
    height: ${theme.font.sizes.xxlarge};
  `}
`

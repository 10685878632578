import { ModeEditOutline as EditIcon } from '@styled-icons/material/ModeEditOutline'
import { Add as AddIcon } from '@styled-icons/material-outlined'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import menuMakerService from '@/api/menu-maker.service'
import checkIcon from '@/assets/img/check-icon.svg'
import errorIcon from '@/assets/img/error-icon.svg'
import { Box } from '@/components/Box'
import Empty from '@/components/Empty'
import Fab from '@/components/Fab'
import { Modal, ModalContent } from '@/components/Modal'
import Pagination from '@/components/Pagination'
import Skeleton from '@/components/Skeleton'
import TableData from '@/components/TableData'
import TableSearch from '@/components/TableSearch'
import useAuth from '@/hooks/useAuth'
import useModal from '@/hooks/useModal'
import ChangeAvailabilityItemsModal from '@/modals/ChangeAvailabilityItemsModal'
import EditItemModal from '@/modals/EditItemModal'
import {
  CategoryType,
  ItemType,
  MenusType,
  ModifierType,
} from '@/types/menu-maker.types'
import { formatCurrency } from '@/utils/formats/number'
import { SortAlphabetically } from '@/utils/formats/sort'

import * as S from './styles'

type AvailableType = 'available' | 'notAvailable' | 'noPicture'

const getAvailableModalTitle = {
  available: 'Available Items',
  notAvailable: 'Not Available Items',
  noPicture: 'No Picture Items',
}

const PAGE_SIZE = 50

const isItemAvailable = (item: ItemType) => item.available !== false

export function Items() {
  const [items, setItems] = useState<ItemType[]>([])
  const [filteredItems, setFilteredItems] = useState<ItemType[] | null>([])
  const [editItem, setEditItem] = useState<ItemType | null>(null)
  const [categories, setCategories] = useState<CategoryType[]>([])
  const [menus, setMenus] = useState<MenusType[]>([])
  const [modifiers, setModifiers] = useState<ModifierType[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [availableType, setAvailableType] = useState<AvailableType>('available')
  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal()
  const {
    open: openChangeAvailabilityItemsModal,
    toggle: setOpenChangeAvailabilityItemsModal,
  } = useModal()
  const { merchantSelected } = useAuth()

  const modalTitle = `${editItem ? 'Edit' : 'Create'} Item`
  const availableModalTitle = getAvailableModalTitle[availableType]

  const { itemsWithoutPicture, availableItems, notAvailableItems } =
    useMemo(() => {
      const itemsWithoutPicture = items.filter(
        (item) => !item.featuredPicture && item.isSellable,
      )

      const availableItems = items.filter(
        (item) => isItemAvailable(item) && item.isSellable,
      )

      const notAvailableItems = items.filter(
        (item) => !isItemAvailable(item) && item.isSellable,
      )

      return {
        itemsWithoutPicture,
        availableItems,
        notAvailableItems,
      }
    }, [items])

  const handleFilterChange = (search: string, items: ItemType[]) => {
    if (search.startsWith('#')) {
      return items.filter(({ categoriesName }) => {
        const catName = categoriesName.filter((cat) =>
          cat.toLowerCase().includes(search.replace('#', '').toLowerCase()),
        )
        return catName.length > 0
      })
    } else {
      return items.filter(
        ({ name }) => name && name.toLowerCase().includes(search.toLowerCase()),
      )
    }
  }

  const getItems = useCallback(async (id: string) => {
    setIsLoading(true)
    setHasError(false)

    try {
      const [resItems, resCategories, resMenus, resModifiers] =
        await Promise.all([
          menuMakerService.getItems(id),
          menuMakerService.getCategories(id),
          menuMakerService.getMenus(id),
          menuMakerService.getModifiers(id),
        ])
      const { data: dataItems } = resItems
      const { data: dataCat } = resCategories
      const { data: dataMenus } = resMenus
      const { data: dataModifiers } = resModifiers

      const newItems = dataItems.map((item) => {
        return {
          ...item,
          price: item.price / 100,
          prices: item?.prices
            ? item.prices.map((p) => ({ ...p, price: p.price / 100 }))
            : [],
          categoriesName: dataCat
            .filter((c) => item.categories?.includes(c.categoryId!))
            .map((cat) => cat.name),
          menusLength: item.menus?.length || 0,
          modifiersLength: item.modifiers?.length || 0,
        }
      })

      if (newItems.length === 0) {
        setIsLoading(false)
      }

      setItems(newItems)
      setCategories(dataCat.sort(SortAlphabetically<CategoryType>('name')))
      setMenus(dataMenus)
      setModifiers(dataModifiers)
    } catch (error) {
      setHasError(true)
      setItems([])
      setFilteredItems([])
      setIsLoading(false)
    }
  }, [])

  const openEditItem = useCallback(
    (item?: ItemType) => {
      setEditItem(item || null)
      setModalIsOpen(true)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const closeEditItem = useCallback(
    (refresh?: boolean) => {
      if (refresh) {
        getItems(merchantSelected!.id)
      }
      setModalIsOpen(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getItems, merchantSelected],
  )

  const renderOpenEditItemModal = () => (
    <Modal open={modalIsOpen} onOpenChange={setModalIsOpen} modal>
      <ModalContent title={modalTitle} onClose={() => setModalIsOpen(false)}>
        <EditItemModal
          closeEditItem={closeEditItem}
          editItemData={editItem}
          merchant={merchantSelected!.id}
          categories={categories}
          menus={menus}
          modifiers={modifiers}
          items={items}
        />
      </ModalContent>
    </Modal>
  )

  const handleOpenChangeAvailabilityItems = useCallback(
    (availableChange: AvailableType) => {
      setAvailableType(availableChange)
      setOpenChangeAvailabilityItemsModal(true)
    },
    [setOpenChangeAvailabilityItemsModal],
  )

  const renderChangeAvailabilityItemsModal = () => {
    let itemsToChangeAvailability: ItemType[] = []

    if (availableType === 'noPicture') {
      itemsToChangeAvailability = itemsWithoutPicture
    } else if (availableType === 'available') {
      itemsToChangeAvailability = availableItems
    } else {
      itemsToChangeAvailability = notAvailableItems
    }

    return (
      <Modal
        open={openChangeAvailabilityItemsModal}
        onOpenChange={setOpenChangeAvailabilityItemsModal}
        modal
      >
        <ModalContent
          title={availableModalTitle}
          onClose={() => setOpenChangeAvailabilityItemsModal(false)}
        >
          <ChangeAvailabilityItemsModal
            items={itemsToChangeAvailability}
            closeChangeAvailabilityItems={(refresh) => {
              if (refresh) getItems(merchantSelected!.id)
              setOpenChangeAvailabilityItemsModal(false)
            }}
          />
        </ModalContent>
      </Modal>
    )
  }

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: (row: ItemType) => row.name,
        sortable: true,
        width: '350px',
      },
      {
        name: 'Price',
        selector: (row: ItemType) => row.price,
        center: true,
        sortable: true,
        minWidth: '70px',
        cell: (row: ItemType) => (
          <div data-tag="allowRowEvents">
            {row.price ? formatCurrency(row.price) : '-'}
          </div>
        ),
      },
      {
        name: 'Picture',
        selector: (row: ItemType) => row.featuredPicture,
        center: true,
        sortable: true,
        sortFunction: (rowA: ItemType) => (rowA.featuredPicture ? 1 : -1),
        minWidth: '70px',
        cell: (row: ItemType) => (
          <div data-tag="allowRowEvents">
            {row.featuredPicture ? (
              <img
                src={row.featuredPicture}
                data-tag="allowRowEvents"
                width={60}
                height={60}
                alt={row.name}
              />
            ) : (
              '-'
            )}
          </div>
        ),
      },
      {
        name: 'Menus',
        selector: (row: ItemType) => row.menusLength,
        sortable: true,
        center: true,
        minWidth: '70px',
        cell: (row: ItemType) => (
          <div data-tag="allowRowEvents">{row.menusLength}</div>
        ),
      },
      {
        name: 'Categories',
        selector: (row: ItemType) => row.categoriesName,
        sortable: true,
        minWidth: '180px',
        cell: (row: ItemType) => (
          <div>
            <span data-tag="allowRowEvents">
              {row.categories?.length || 0}
              {row.categoriesName.length > 0 ? ' - ' : ''}
            </span>
            {row.categoriesName.map((c, index) => (
              <span key={c} data-tag="allowRowEvents">
                {index + 1 === row.categoriesName?.length ? `${c}` : `${c}, `}
              </span>
            ))}
          </div>
        ),
      },
      {
        name: 'Available',
        selector: (row: ItemType) => row.available,
        center: true,
        sortable: true,
        sortFunction: (item: ItemType) => (isItemAvailable(item) ? 1 : -1),
        minWidth: '80px',
        cell: (row: ItemType) => (
          <div data-tag="allowRowEvents">
            {row.available !== false ? (
              <img
                data-tag="allowRowEvents"
                src={checkIcon}
                height={32}
                width={32}
                alt="Check icon"
              />
            ) : (
              <img
                data-tag="allowRowEvents"
                src={errorIcon}
                height={32}
                width={32}
                alt="Error icon"
              />
            )}
          </div>
        ),
      },
      {
        name: 'Modifiers',
        selector: (row: ItemType) => row.modifiersLength,
        center: true,
        sortable: true,
        minWidth: '80px',
        cell: (row: ItemType) => (
          <div data-tag="allowRowEvents">{row.modifiersLength}</div>
        ),
      },
      {
        name: '',
        center: true,
        minWidth: '40px',
        cell: (row: ItemType) => (
          <S.ButtonEdit
            icon={<EditIcon size={44} />}
            size="small"
            minimal
            onClick={() => openEditItem(row)}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    if (filteredItems && filteredItems.length === 0) {
      setFilteredItems(null)
    }
  }, [filteredItems])

  useEffect(() => {
    if (merchantSelected) {
      getItems(merchantSelected.id)
    }
  }, [getItems, merchantSelected])

  return (
    <>
      <Helmet title="Items" />
      <S.Content>
        <S.Header>
          <Box
            flex
            alignItems="center"
            JustifyContent="space-between"
            width="100%"
          >
            <h1>Items {items.length ? `(${items.length})` : ''}</h1>

            <TableSearch<ItemType>
              data={items}
              filterCb={handleFilterChange}
              onFilter={(data) => {
                setFilteredItems(data)
                setIsLoading(false)
              }}
              disabled={isLoading || hasError || items.length === 0}
            />
          </Box>
        </S.Header>

        <S.TagsContainer>
          {isLoading ? (
            <Box flex gap="1.25rem" style={{ marginBottom: '1.25rem' }}>
              <Skeleton
                width="11.25rem"
                height="1.875rem"
                count={3}
                radius="4px"
                direction="row"
              />
            </Box>
          ) : (
            <>
              <S.TagButton
                color="#00bb53"
                onClick={() => handleOpenChangeAvailabilityItems('available')}
                active
              >
                Available: {availableItems.length}
              </S.TagButton>
              <S.TagButton
                color="#b64e40"
                onClick={() =>
                  handleOpenChangeAvailabilityItems('notAvailable')
                }
                active
              >
                Not Available: {notAvailableItems.length}
              </S.TagButton>
              <S.TagButton
                color="#666766"
                onClick={() => handleOpenChangeAvailabilityItems('noPicture')}
                active
              >
                No Picture: {itemsWithoutPicture.length}
              </S.TagButton>
            </>
          )}
        </S.TagsContainer>

        {isLoading ? (
          <>
            <Box
              flex
              alignItems="center"
              JustifyContent="space-between"
              width="100%"
              style={{ marginBottom: '1.75rem' }}
            >
              <Skeleton
                width="2.5rem"
                height="0.875rem"
                count={5}
                radius="4px"
                direction="row"
              />
            </Box>

            <Skeleton height="2.5rem" count={7} radius="4px" margin="2.5rem" />
          </>
        ) : hasError ? (
          <Box
            flex
            JustifyContent="center"
            alignItems="center"
            width="100%"
            height="31.25rem"
          >
            <Empty
              title="Something Happened"
              description="Reload the page and try again"
            />
          </Box>
        ) : filteredItems === null ? (
          <Box
            flex
            JustifyContent="center"
            alignItems="center"
            width="100%"
            height="31.25rem"
          >
            <Empty title="There is nothing here, yet" />
          </Box>
        ) : (
          <>
            <TableData<ItemType>
              data={filteredItems || []}
              columns={columns}
              onEdit={openEditItem}
              pagination
              paginationPerPage={PAGE_SIZE}
              paginationComponent={({
                currentPage,
                onChangePage,
                onChangeRowsPerPage,
                rowsPerPage,
              }) => (
                <Pagination
                  currentPage={Number(currentPage)}
                  totalCount={filteredItems.length}
                  pageSize={rowsPerPage}
                  onPageChange={(page) =>
                    onChangePage(Number(page), filteredItems.length)
                  }
                  onPageSizeChange={(size) =>
                    onChangeRowsPerPage(size, Number(currentPage))
                  }
                />
              )}
            />
          </>
        )}

        <Fab size="large" onClick={() => openEditItem()}>
          <AddIcon size={24} />
        </Fab>

        {modalIsOpen && renderOpenEditItemModal()}
        {openChangeAvailabilityItemsModal &&
          renderChangeAvailabilityItemsModal()}
      </S.Content>
    </>
  )
}

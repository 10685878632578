import { format } from 'date-fns'

import { DateCountType, TakeoutDeliveryType } from '@/types/dashboard.types'

export default function deliveryTakeoutFormat(
  takeout: DateCountType[],
  delivery: DateCountType[],
) {
  const newArr: TakeoutDeliveryType[] = []

  takeout.forEach((item) => {
    newArr.push({
      date: format(new Date(`${item.date}T23:59:59.000Z`), 'MMM, dd E'),
      takeout: item.count,
      delivery: 0,
    })
  })

  delivery.forEach((item, index) => {
    newArr[index] = { ...newArr[index], delivery: item.count }
  })

  return newArr
}

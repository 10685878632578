import Skeleton from '@/components/Skeleton'

import * as S from './styles'

export const FormGeneralSettingsLoading = () => (
  <S.Wrapper>
    <S.FormList>
      <S.FormListItem>
        <S.FormListItemTitle>
          <Skeleton width="6.25rem" />
        </S.FormListItemTitle>
        <Skeleton width="12.5rem" />
      </S.FormListItem>
      <S.FormListItem>
        <S.FormListItemTitle>
          <Skeleton width="6.25rem" />
        </S.FormListItemTitle>
        <Skeleton width="12.5rem" />
      </S.FormListItem>
      <S.FormListItem>
        <S.FormListItemTitle>
          <Skeleton width="6.25rem" />
        </S.FormListItemTitle>
        <Skeleton width="12.5rem" />
      </S.FormListItem>
      <S.FormListItem>
        <S.FormListItemTitle>
          <Skeleton width="6.25rem" />
        </S.FormListItemTitle>
        <Skeleton width="12.5rem" />
      </S.FormListItem>
    </S.FormList>
  </S.Wrapper>
)

import axios from 'axios'

import { env } from '@/env'
import { api } from '@/lib/axios'
import { TokenizationCardType } from '@/types/payment.types'

interface CreateTokenizeCardBody {
  data: TokenizationCardType
  merchantId: string
}

const createTokenizeCard = async ({
  data,
  merchantId,
}: CreateTokenizeCardBody) => {
  return await axios.post<void>(
    `${env.VITE_SERVICES_API_URL}/tokenization/${merchantId}/merchants`,
    { ...data },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: api.defaults.headers.Authorization,
      },
    },
  )
}

export default {
  createTokenizeCard,
}

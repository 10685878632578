import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { SoldItemsType } from '@/types/dashboard.types'

import * as S from './styles'

type CustomLegendPayloadProps = {
  color: string
  value: string
}

type CustomLegendProps = {
  payload: CustomLegendPayloadProps[]
}

const SVGLegendBullet = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="topSalesColor" x1="0" y1="0" x2="1" y2="0">
        <stop offset="5%" stopColor="#ff4c4c" stopOpacity={1} />
        <stop offset="95%" stopColor="#fba140" stopOpacity={1} />
      </linearGradient>
    </defs>

    <rect fill="url(#topSalesColor)" />
  </svg>
)

const CustomLegend = ({ payload }: CustomLegendProps) => (
  <S.List>
    {payload.map((entry: CustomLegendPayloadProps) => (
      <S.ListItem key={`item-${entry.value}`}>
        <S.ListItemBullet>
          <SVGLegendBullet />
        </S.ListItemBullet>
        {entry.value}
      </S.ListItem>
    ))}
  </S.List>
)

type CustomTooltipProps = {
  active: boolean
  payload: Array<{ name: string; value: string }>
  label: string
}

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <S.Tooltip>
        <h4>{label}</h4>
        <p>Sales: {payload[0].value}</p>
      </S.Tooltip>
    )
  }
  return null
}

export type TopSalesChartProps = {
  data: SoldItemsType[]
  height?: string
}

const TopSalesChart = ({ data, height }: TopSalesChartProps) => (
  <S.Wrapper height={height}>
    <S.Title>Top Sales</S.Title>
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        layout="vertical"
        data={data}
        margin={{ right: 20, left: 20 }}
      >
        <defs>
          <linearGradient id="topSalesColor" x1="0" y1="0" x2="1" y2="0">
            <stop offset="5%" stopColor="#ff4c4c" stopOpacity={1} />
            <stop offset="95%" stopColor="#fba140" stopOpacity={1} />
          </linearGradient>
        </defs>
        <CartesianGrid
          stroke="#c5c0c0"
          horizontal={false}
          strokeDasharray="5"
        />
        <XAxis type="number" fontSize="0.75rem" stroke="#c5c0c0" />
        <YAxis
          dataKey="item"
          type="category"
          scale="band"
          strokeWidth="0"
          fontSize="0.75rem"
        />
        <Tooltip
          cursor={{ stroke: 'none' }}
          content={<CustomTooltip {...({} as CustomTooltipProps)} />}
        />
        <Legend content={<CustomLegend {...({} as CustomLegendProps)} />} />
        <Bar
          dataKey="qty"
          name="Top Sold Items"
          barSize={20}
          fill="url(#topSalesColor)"
          radius={8}
        />
      </ComposedChart>
    </ResponsiveContainer>
  </S.Wrapper>
)

export default TopSalesChart

import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'

import { Box } from '@/components/Box'
import Empty from '@/components/Empty'
import List from '@/components/List'
import {
  ModalButtonClose,
  ModalFooter,
  ModalOverflow,
} from '@/components/Modal'
import TextField from '@/components/TextField'
import { ItemType } from '@/types/menu-maker.types'

import * as S from './styles'

export type ItemsAssignedModalProps = {
  items: ItemType[]
  assigned: string[]
  closeItemsAssigned?: () => void
}

type ItemsAssigned = {
  name: string
  description: string
  itemId: string
}

const selectItemsAssignedDeleted = (items: ItemType[], assigned: string[]) => {
  if (assigned) {
    return assigned.map((assign) => {
      const selectedFound = items.find((item) => item.itemId === assign)
      return {
        name: selectedFound?.name || '',
        itemId: selectedFound?.itemId || '',
        description: selectedFound?.description || '',
      }
    })
  }
  return []
}

const ItemsAssignedModal = ({
  items,
  assigned,
  closeItemsAssigned,
}: ItemsAssignedModalProps) => {
  const [itemsAssigned] = useState<ItemsAssigned[]>(
    selectItemsAssignedDeleted(items, assigned),
  )
  const [filteredItemsAssigned, setFilteredItemsAssigned] =
    useState<ItemsAssigned[]>(itemsAssigned)
  const [filterText, setFilterText] = useState('')

  const handleFilterChange = (
    search: string,
    itemsAssigned: ItemsAssigned[],
  ) => {
    if (search) {
      const itemsAssignedFiltered = itemsAssigned.filter(
        ({ name }) => name && name.toLowerCase().includes(search.toLowerCase()),
      )
      setFilteredItemsAssigned([...itemsAssignedFiltered])
    } else {
      setFilteredItemsAssigned(itemsAssigned)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(_.debounce(handleFilterChange, 300), [])

  const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setFilterText(value)
  }

  useEffect(() => {
    if (itemsAssigned.length > 0) {
      debouncedOnChange(filterText, itemsAssigned)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, itemsAssigned])

  return (
    <>
      <ModalOverflow>
        <S.SearchWrapper>
          <TextField
            type="text"
            name="search_menu"
            placeholder="Search by name"
            disabled={itemsAssigned.length === 0}
            value={filterText}
            iconPosition="right"
            onChange={handleChangeFilter}
            variant="material"
          />
        </S.SearchWrapper>

        <S.Content>
          {filteredItemsAssigned.length === 0 ? (
            <Box
              flex
              JustifyContent="center"
              alignItems="center"
              width="100%"
              height="12.5rem"
            >
              <Empty title="There is nothing here, yet" />
            </Box>
          ) : (
            <List<ItemsAssigned> data={filteredItemsAssigned}>
              {(item) => (
                <Box flex flexDirection="column">
                  <S.Name>{item.name}</S.Name>
                  <S.Description>{item.description}</S.Description>
                </Box>
              )}
            </List>
          )}
        </S.Content>
      </ModalOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
          style={{ marginTop: '1rem' }}
        >
          <ModalButtonClose
            color="white"
            size="large"
            onClick={closeItemsAssigned}
            shadow
          >
            Cancel
          </ModalButtonClose>
          <span />
          <ModalButtonClose size="large" onClick={closeItemsAssigned}>
            Save
          </ModalButtonClose>
        </Box>
      </ModalFooter>
    </>
  )
}

export default ItemsAssignedModal

import { ReactNode, useState } from 'react'

import { FormStep } from '@/components/FormStep'
import FormStepProvider from '@/components/FormStep/Context'
import { CardType } from '@/types/payment.types'
import { Campaign, CampaignList } from '@/types/super-partner.types'

import { CheckoutForm } from './CheckoutForm'
import { CampaignListInitialData, LoyaltyForm } from './LoyaltyForm'
import { ShareLoyalty } from './ShareLoyalty'

export type Steps = {
  [stepName: string]: ReactNode
}

export type LoyaltyStepsProps = {
  close: (isSuccess?: boolean) => void
  campaignItem: CampaignList | null
  campaign: Campaign
  paymentMethod: CardType | null
}

export type BeforeType = {
  active: boolean
  field: string
}

export const LoyaltySteps = ({
  campaignItem,
  campaign,
  close,
  paymentMethod,
}: LoyaltyStepsProps) => {
  const [activeStep, setActiveStep] = useState(0)
  const [before, setBefore] = useState<BeforeType>({
    active: false,
    field: '',
  })

  const campaignItemData = campaignItem as CampaignList<CampaignListInitialData>

  const nextFormStep = () => {
    setBefore({ active: false, field: '' })
    setActiveStep((currentStep) => currentStep + 1)
  }

  const beforeFormStep = ({ active, field }: BeforeType) => {
    setBefore({ active, field })
    setActiveStep((currentStep) => currentStep - 1)
  }

  const steps: Steps[] = [
    {
      loyalty: (
        <LoyaltyForm
          key="loyalty-form"
          campaignItem={campaignItemData}
          campaign={campaign}
          close={close}
          onNextStep={nextFormStep}
          before={before}
          active={activeStep === 0}
        />
      ),
    },
    {
      checkout: (
        <CheckoutForm
          key="checkout-form"
          campaignItem={campaignItemData}
          close={close}
          active={activeStep === 1}
          before={before}
          onBeforeStep={beforeFormStep}
          paymentMethod={paymentMethod}
          onNextStep={nextFormStep}
        />
      ),
    },
    {
      share: (
        <ShareLoyalty
          key="share-loyalty"
          active={activeStep === 2}
          close={close}
        />
      ),
    },
  ]

  return (
    <FormStepProvider>
      <FormStep
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    </FormStepProvider>
  )
}

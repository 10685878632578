import styled, { css } from 'styled-components'

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacings.xxsmall} ${theme.spacings.small};
    margin-bottom: ${theme.spacings.small};
  `}
`

export const Name = styled.h4`
  ${({ theme }) => css`
    font-weight: ${theme.font.medium};
    line-height: 1.375rem;
  `}
`

export const ImageWrapper = styled.div`
  width: 3.75rem;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SearchWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.small};
  `}
`

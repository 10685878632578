import { ModeEditOutline as EditIcon } from '@styled-icons/material/ModeEditOutline'
import { Add as AddIcon } from '@styled-icons/material-outlined'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import menuMakerService from '@/api/menu-maker.service'
import { Box } from '@/components/Box'
import Empty from '@/components/Empty'
import Fab from '@/components/Fab'
import { Modal, ModalContent } from '@/components/Modal'
import Pagination from '@/components/Pagination'
import Skeleton from '@/components/Skeleton'
import TableData from '@/components/TableData'
import TableSearch from '@/components/TableSearch'
import useAuth from '@/hooks/useAuth'
import useModal from '@/hooks/useModal'
import EditCategoryModal from '@/modals/EditCategoryModal'
import { CategoryType, MenusType } from '@/types/menu-maker.types'

import * as S from './styles'

const PAGE_SIZE = 50

export function Categories() {
  const [categories, setCategories] = useState<CategoryType[]>([])
  const [filteredCategories, setFilteredCategories] = useState<
    CategoryType[] | null
  >([])
  const [editCategory, setEditCategory] = useState<CategoryType | null>(null)
  const [menus, setMenus] = useState<MenusType[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal()
  const { merchantSelected } = useAuth()

  const modalTitle = `${editCategory ? 'Edit' : 'Create'} Category`

  const handleFilterChange = (search: string, categories: CategoryType[]) => {
    return categories.filter(
      ({ name }) => name && name.toLowerCase().includes(search.toLowerCase()),
    )
  }

  const getCategories = useCallback(async (id: string) => {
    setIsLoading(true)
    setHasError(false)

    try {
      const [resCategories, resMenus] = await Promise.all([
        menuMakerService.getCategories(id),
        menuMakerService.getMenus(id),
      ])

      const { data: dataCat } = resCategories
      const { data: dataMenus } = resMenus

      const categories = dataCat.map((category) => {
        return {
          ...category,
          menusName: dataMenus
            .filter((m) => category.menus.includes(m.menuId!))
            .map((mn) => mn.name),
          itemsLength: category.items?.length || 0,
        }
      })

      if (categories.length === 0) {
        setIsLoading(false)
      }

      setCategories(categories)
      setMenus(dataMenus)
    } catch (error) {
      setHasError(true)
      setCategories([])
      setFilteredCategories([])
      setIsLoading(false)
    }
  }, [])

  const openEditCategory = useCallback(
    (category?: CategoryType) => {
      setEditCategory(category || null)
      setModalIsOpen(true)
    },
    [setModalIsOpen],
  )

  const closeEditCategory = (refresh?: boolean) => {
    if (refresh) {
      getCategories(merchantSelected!.id)
    }
    setModalIsOpen(false)
  }

  const renderOpenEditCategoryModal = () => {
    return (
      <Modal open={modalIsOpen} onOpenChange={setModalIsOpen} modal>
        <ModalContent title={modalTitle} onClose={() => setModalIsOpen(false)}>
          <EditCategoryModal
            closeEditCategory={closeEditCategory}
            editCategoryData={editCategory}
            merchant={merchantSelected!.id}
            menus={menus}
          />
        </ModalContent>
      </Modal>
    )
  }

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: (row: CategoryType) => row.name,
        sortable: true,
        width: '350px',
      },
      {
        name: 'Note',
        selector: (row: CategoryType) => row.note,
        center: true,
        minWidth: '100px',
        cell: (row: CategoryType) => (
          <div data-tag="allowRowEvents">{row.note || '-'}</div>
        ),
      },
      {
        name: 'Menus',
        selector: (row: CategoryType) => row.menus,
        sortable: true,
        minWidth: '180px',
        cell: (row: CategoryType) => (
          <div>
            {row.menusName.map((m) => (
              <>
                <span key={m} data-tag="allowRowEvents">
                  {m}
                </span>
                <br />
              </>
            ))}
          </div>
        ),
      },
      {
        name: 'Items',
        selector: (row: CategoryType) => row.itemsLength,
        sortable: true,
        center: true,
        minWidth: '90px',
        cell: (row: CategoryType) => (
          <div data-tag="allowRowEvents">{row.itemsLength}</div>
        ),
      },
      {
        name: '',
        center: true,
        minWidth: '40px',
        cell: (row: CategoryType) => (
          <S.ButtonEdit
            icon={<EditIcon size={44} />}
            size="small"
            minimal
            onClick={() => openEditCategory(row)}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    if (filteredCategories && filteredCategories.length === 0) {
      setFilteredCategories(null)
    }
  }, [filteredCategories])

  useEffect(() => {
    if (merchantSelected) {
      getCategories(merchantSelected.id)
    }
  }, [getCategories, merchantSelected])

  return (
    <>
      <Helmet title="Categories" />
      <S.Content>
        <S.Header>
          <Box
            flex
            alignItems="center"
            JustifyContent="space-between"
            width="100%"
          >
            <h1>Categories</h1>

            <TableSearch<CategoryType>
              data={categories}
              filterCb={handleFilterChange}
              onFilter={(data) => {
                setFilteredCategories(data)
                setIsLoading(false)
              }}
              disabled={isLoading || hasError || categories.length === 0}
            />
          </Box>
        </S.Header>

        {isLoading ? (
          <>
            <Box
              flex
              alignItems="center"
              JustifyContent="space-between"
              width="100%"
              style={{ marginBottom: '1.75rem' }}
            >
              <Skeleton
                width="2.5rem"
                height="0.875rem"
                count={5}
                radius="4px"
                direction="row"
              />
            </Box>

            <Skeleton height="2.5rem" count={7} radius="4px" margin="2.4rem" />
          </>
        ) : hasError ? (
          <Box
            flex
            JustifyContent="center"
            alignItems="center"
            width="100%"
            height="31.25rem"
          >
            <Empty
              title="Something Happened"
              description="Reload the page and try again"
            />
          </Box>
        ) : filteredCategories === null ? (
          <Box
            flex
            JustifyContent="center"
            alignItems="center"
            width="100%"
            height="31.25rem"
          >
            <Empty title="There is nothing here, yet" />
          </Box>
        ) : (
          <>
            <TableData<CategoryType>
              data={filteredCategories || []}
              columns={columns}
              onEdit={openEditCategory}
              pagination
              paginationPerPage={PAGE_SIZE}
              paginationComponent={({
                currentPage,
                onChangePage,
                onChangeRowsPerPage,
                rowsPerPage,
              }) => (
                <Pagination
                  currentPage={Number(currentPage)}
                  totalCount={filteredCategories.length}
                  pageSize={rowsPerPage}
                  onPageChange={(page) =>
                    onChangePage(Number(page), filteredCategories.length)
                  }
                  onPageSizeChange={(size) =>
                    onChangeRowsPerPage(size, Number(currentPage))
                  }
                />
              )}
            />
          </>
        )}

        <Fab size="large" onClick={() => openEditCategory()}>
          <AddIcon size={24} />
        </Fab>

        {modalIsOpen && renderOpenEditCategoryModal()}
      </S.Content>
    </>
  )
}

import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { getCode } from 'country-list'
import creditCardType from 'credit-card-type'
import { CreditCardTypeCardBrandId } from 'credit-card-type/dist/types'
import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import paymentService from '@/api/payment.service'
import cardIcon from '@/assets//img/icons-card.svg'
import Button from '@/components/Button'
import TextField from '@/components/TextField'
import Toast from '@/components/Toast'
import useAuth from '@/hooks/useAuth'
import { CardInfoType } from '@/types/payment.types'

import * as S from './styles'

const tokenizeCardFormSchema = z.object({
  card: z.object({
    number: z.string().min(1, 'Card number is required'),
    expiration: z.string().min(7, 'Input a valid date [MM/YYYY] format'),
    cvv: z.string().min(1, 'CVV is required'),
  }),
  billing: z.object({
    name: z.string().min(1, 'Name is required'),
    address: z.string(),
    city: z.string(),
    state: z.string(),
    country: z.string(),
    postalCode: z.string(),
  }),
})

type TokenizeCardFormData = z.infer<typeof tokenizeCardFormSchema>

export type PaymentFormProps = {
  onLoading?: (isLoading: boolean) => void
  onSavePayment: (fetchList: boolean) => void
}

export const PaymentForm = ({ onSavePayment }: PaymentFormProps) => {
  const [hasBillingError, setHasBillingError] = useState(false)
  const [cardInfo, setCardInfo] = useState<CardInfoType | null>(null)
  const { merchantSelected } = useAuth()

  const isCardAlter =
    cardInfo?.type === 'american-express' || cardInfo?.type === 'diners-club'
  const isTypeInRange = (type: CreditCardTypeCardBrandId) =>
    type === 'visa' ||
    type === 'mastercard' ||
    type === 'discover' ||
    type === 'american-express' ||
    type === 'diners-club'

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { isValid },
  } = useForm<TokenizeCardFormData>({
    resolver: zodResolver(tokenizeCardFormSchema),
    defaultValues: {
      card: {
        number: '',
        expiration: '',
        cvv: '',
      },
      billing: {
        name: '',
        address: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
      },
    },
  })

  const { mutateAsync: tokenizeCard, isPending: isLoading } = useMutation({
    mutationFn: paymentService.createTokenizeCard,
  })

  const getCardData = useCallback(
    (value: string) => {
      const infos = creditCardType(value)
      const info = (infos[0] as CardInfoType) || null
      setCardInfo(info)
      setValue('card.cvv', '')
    },
    [setValue],
  )

  const handleExpiration = useCallback((value: string) => {
    if (value.length === 1) {
      value = Number(value) > 1 ? '0' : value
    }
    if (value.length === 2) {
      value = Number(value) > 12 || value === '00' ? '12' : value
    }
    return value
  }, [])

  const handleTokenizeCard = async (data: TokenizeCardFormData) => {
    if (!merchantSelected) return
    try {
      const [expMonth, expYear] = data.card.expiration.split('/')

      const dataToSave = {
        card: {
          ...data.card,
          number: data.card.number.replaceAll(' ', ''),
          expiration: `${expMonth}-${expYear}`,
        },
        billing: {
          name: data.billing.name,
          address: data.billing.address || 'George Town, Cayman Islands',
          city: data.billing.city || 'Grand Cayman',
          state: data.billing.state || 'Cayman Islands',
          postalCode: data.billing.postalCode || '00000',
          country: getCode(data.billing.country) || 'KY',
        },
        defaultMethod: true,
      }

      await tokenizeCard({
        data: dataToSave,
        merchantId: merchantSelected?.id,
      })

      reset()
      onSavePayment(true)
    } catch (error) {
      const { response } = error as AxiosError

      const message =
        (response?.data as Record<string, string>)?.message ||
        'Ops, something went wrong.'

      Toast({
        title: 'Problems saving card',
        message: !hasBillingError
          ? 'Some card Issuers might ask for billing address'
          : message,
      })
      setHasBillingError(true)
    }
  }

  function getImageURL(cardType: CreditCardTypeCardBrandId) {
    const imageUrl = new URL(
      `/src/assets/img/ic_card_${cardType}.webp`,
      import.meta.url,
    )
    return imageUrl
  }

  return (
    <S.Wrapper>
      <form onSubmit={handleSubmit(handleTokenizeCard)}>
        <S.FormRow>
          <Controller
            name="card.number"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                type="text"
                variant="material"
                label="Card number"
                labelFor="cardNumber"
                id="cardNumber"
                inputMode="numeric"
                placeholder="0000 0000 0000 0000"
                mask={isCardAlter ? 'creditCardAlter' : 'creditCard'}
                maxLength={isCardAlter ? 17 : 19}
                icon={
                  <img
                    src={
                      cardInfo?.type && isTypeInRange(cardInfo.type)
                        ? getImageURL(cardInfo.type).href
                        : cardIcon
                    }
                    alt={`${cardInfo?.type || 'credit card'} icon`}
                    className="absolute right-[0] h-6 !w-8"
                  />
                }
                iconPosition="right"
                error={error?.message}
                value={field.value}
                onChange={(e) => {
                  getCardData(e.currentTarget.value)
                  field.onChange(e)
                }}
              />
            )}
          />
        </S.FormRow>

        <S.FormRow>
          <S.FormGrid>
            <Controller
              name="card.expiration"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  variant="material"
                  type="text"
                  name="expirationDate"
                  label="Expiration date"
                  labelFor="expirationDate"
                  id="card_exp"
                  inputMode="numeric"
                  placeholder="MM/YYYY"
                  mask="dateMonthYear"
                  disabled={isLoading}
                  error={error?.message}
                  value={field.value}
                  onChange={(e) => {
                    const value = handleExpiration(e.currentTarget.value)
                    field.onChange(value)
                  }}
                />
              )}
            />

            <Controller
              name="card.cvv"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  variant="material"
                  type="text"
                  name="cvv"
                  label="CVV"
                  labelFor="card_cvv"
                  id="card_cvv"
                  inputMode="numeric"
                  placeholder={
                    cardInfo?.type === 'american-express' ? '0000' : '000'
                  }
                  disabled={isLoading}
                  maxLength={cardInfo?.code.size || 3}
                  mask="numberClean"
                  error={error?.message}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </S.FormGrid>
        </S.FormRow>

        <S.FormRow>
          <Controller
            name="billing.name"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                variant="material"
                type="text"
                name="name"
                label="Name"
                labelFor="name"
                id="name"
                disabled={isLoading}
                error={error?.message}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </S.FormRow>

        {hasBillingError && (
          <>
            <S.PaymentTitle>Billing Address</S.PaymentTitle>

            <S.FormRow>
              <Controller
                name="billing.address"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    variant="material"
                    type="text"
                    name="streetName"
                    label="Street Name"
                    labelFor="streetName"
                    id="streetName"
                    placeholder="Sarah Lan Av., 832"
                    disabled={isLoading}
                    error={error?.message}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </S.FormRow>

            <S.FormRow>
              <S.FormGrid>
                <Controller
                  name="billing.city"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      variant="material"
                      type="text"
                      name="city"
                      label="City"
                      labelFor="city"
                      id="city"
                      disabled={isLoading}
                      error={error?.message}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />

                <Controller
                  name="billing.state"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      variant="material"
                      name="state"
                      type="text"
                      label="State"
                      labelFor="state"
                      id="state"
                      disabled={isLoading}
                      error={error?.message}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </S.FormGrid>
            </S.FormRow>

            <S.FormRow>
              <S.FormGrid>
                <Controller
                  name="billing.postalCode"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      variant="material"
                      type="text"
                      name="zip"
                      label="Zip"
                      labelFor="zip"
                      id="zip"
                      placeholder="9322-002"
                      disabled={isLoading}
                      error={error?.message}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />

                <Controller
                  name="billing.country"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      variant="material"
                      type="text"
                      name="country"
                      label="Country"
                      labelFor="country"
                      id="country"
                      disabled={isLoading}
                      error={error?.message}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </S.FormGrid>
            </S.FormRow>
          </>
        )}

        <S.ButtonWrapper>
          <Button
            type="submit"
            disabled={isLoading || !isValid}
            loading={isLoading}
            size="large"
          >
            Save
          </Button>
        </S.ButtonWrapper>
      </form>
    </S.Wrapper>
  )
}

import { AccessTime as AccessTimeIcon } from '@styled-icons/material-outlined/AccessTime'
import { KeyboardArrowDown } from '@styled-icons/material-rounded/KeyboardArrowDown'
import { RoomService as RoomServiceIcon } from '@styled-icons/material-rounded/RoomService'
import { useState } from 'react'

import settingsService from '@/api/settings.service'
import Dropdown from '@/components/Dropdown'
import { Modal, ModalContent } from '@/components/Modal'
import Switch from '@/components/Switch'
import useAuth from '@/hooks/useAuth'
import useModal from '@/hooks/useModal'
import EditSettingsAvailabilityModal from '@/modals/EditSettingsAvailabilityModal'
import { MerchantType } from '@/types/merchant.types'

import * as S from './styles'

interface MerchantInfoProps {
  status: JSX.Element
  shifts: JSX.Element
  cookingTime: JSX.Element
  hasToday?: boolean
  merchantSelected: MerchantType | undefined
  onSetMerchant: () => void
}

const MerchantInfoDropdown = ({
  status,
  cookingTime,
  shifts,
  merchantSelected,
  hasToday = false,
  onSetMerchant,
}: MerchantInfoProps) => {
  const [available, setAvailable] = useState(merchantSelected?.isOpen || false)
  const { user } = useAuth()
  const { open: openAvailabilityModal, toggle: setOpenAvailabilityModal } =
    useModal()

  const handleAvailableChange = async (value: boolean) => {
    if (!value) {
      setOpenAvailabilityModal(true)
      return
    }

    if (merchantSelected && user) {
      const newMerchant = {
        ...merchantSelected,
        picture: merchantSelected.picture
          ? new URL(merchantSelected.picture).toString()
          : '',
        logo: merchantSelected.logo
          ? new URL(merchantSelected.logo).toString()
          : '',
        isOpen: true,
      }
      delete newMerchant?.availableAgainAt

      await settingsService.saveMerchantGeneralInfo(
        newMerchant,
        merchantSelected.id,
        user.uuid,
      )
    }

    setAvailable(true)
    onSetMerchant()
  }

  const closeEditAvailability = async (againAt?: string) => {
    if (againAt) {
      setAvailable(false)

      if (merchantSelected && user) {
        await settingsService.saveMerchantGeneralInfo(
          {
            ...merchantSelected,
            picture: merchantSelected.picture
              ? new URL(merchantSelected.picture).toString()
              : '',
            logo: merchantSelected.logo
              ? new URL(merchantSelected.logo).toString()
              : '',
            isOpen: false,
            availableAgainAt: againAt,
          },
          merchantSelected.id,
          user.uuid,
        )
      }

      onSetMerchant()
    } else {
      setAvailable(true)
      onSetMerchant()
    }
    setOpenAvailabilityModal(false)
  }

  const renderOpenEditSettingsAvailabilityModal = () => {
    return (
      <Modal
        open={openAvailabilityModal}
        onOpenChange={setOpenAvailabilityModal}
        modal
      >
        <ModalContent
          title="Turn OFF for…"
          style={{ minWidth: '25rem' }}
          onClose={() => setOpenAvailabilityModal(false)}
          width="auto"
        >
          <EditSettingsAvailabilityModal
            closeAvailability={closeEditAvailability}
          />
        </ModalContent>
      </Modal>
    )
  }

  return (
    <>
      <S.Wrapper>
        <Dropdown
          title={
            <>
              <S.Title>
                <S.StatusContainer>
                  <S.AvailableIcon />
                  <S.Status>{status}</S.Status>
                </S.StatusContainer>
                <KeyboardArrowDown size={16} />
              </S.Title>

              <S.ItemInfo>
                {merchantSelected?.shifts && (
                  <>
                    {hasToday && (
                      <S.CookingTime>
                        <AccessTimeIcon size={14} />
                        {shifts}
                      </S.CookingTime>
                    )}
                    <S.CookingTime>
                      <RoomServiceIcon size={14} />
                      <div>{cookingTime}</div>
                    </S.CookingTime>
                  </>
                )}

                <S.ItemStatus>{status} </S.ItemStatus>
              </S.ItemInfo>
            </>
          }
        >
          {() => (
            <S.Content>
              {hasToday && (
                <S.Info>
                  <AccessTimeIcon size={14} />
                  <>{shifts}</>
                </S.Info>
              )}
              <S.Info>
                <RoomServiceIcon size={14} />
                <>{cookingTime}</>
              </S.Info>
              <S.ItemAvailabilitySwitch
                onClick={() => {
                  handleAvailableChange(!available)
                }}
              >
                <>Availability</>
                <Switch onChange={handleAvailableChange} checked={available} />
              </S.ItemAvailabilitySwitch>
            </S.Content>
          )}
        </Dropdown>
      </S.Wrapper>

      {openAvailabilityModal && renderOpenEditSettingsAvailabilityModal()}
    </>
  )
}

export default MerchantInfoDropdown

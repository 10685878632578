import './global.css'
import './i18n'

import * as Sentry from '@sentry/react'
// import '@iguanads/react/dist/index.css'
import React from 'react'
import ReactDOM from 'react-dom/client'

import { App } from './app.tsx'
import { env } from './env.ts'

const isProd = env.VITE_ENV === 'production'
const SENTRY_DSN = isProd ? env.VITE_SENTRY_DSN : env.VITE_STAGE_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://a82875c43eb34d5d9da76fb3f334e009@o1353386.ingest.sentry.io/4504680836562944',
  tracesSampleRate: 1.0,
  enabled: isProd,
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

import { Back, CategoryList, Edit } from '@iguanads/icons/one-color'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  formatCurrencyWithCode,
  Heading,
  Loading,
  ScrollArea,
  ScrollBar,
  Skeleton,
  Text,
} from '@iguanads/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'
import { titleCase } from 'title-case'

import ordersService from '@/api/orders.service'
import { Modal, ModalContent } from '@/components/Modal'
import { OrderPrintButton } from '@/components/order-print-button'
import { OrderStatusEnum } from '@/enums/order.enum'
import { useDialog } from '@/hooks/use-dialog'
import useAuth from '@/hooks/useAuth'
import useModal from '@/hooks/useModal'
import AddChargeModal from '@/modals/AddChargeModal'
import CreateMagicVoucherModal from '@/modals/CreateMagicVoucherModal'
import { ProfileModal } from '@/modals/ProfileModal'
import type { OrderType } from '@/types/orders.types'
import { formatDistanceToNow } from '@/utils/formats/date'

import { ChangeStatusDialog } from './change-status.dialog'

type OrderStatusButton = {
  label: string
  status: string
  renderCondition: (order: OrderType) => boolean
}

const orderNegativeStatusMapper = {
  [OrderStatusEnum.REJECTED]: {
    label: 'Reject',
    status: OrderStatusEnum.REJECTED,
    renderCondition: () => true,
  },
  [OrderStatusEnum.CANCELLED]: {
    label: 'Cancel',
    status: OrderStatusEnum.CANCELLED,
    renderCondition: () => true,
  },
}

const orderPositiveStatusMapper = (
  isDelivery: boolean,
  isDeliveryHandlerBento: boolean,
  isSuperAdmin: boolean,
) => ({
  [OrderStatusEnum.CREATED]: {
    label: 'Accept',
    status: OrderStatusEnum.IN_PROGRESS,
    renderCondition: () => true,
  },
  [OrderStatusEnum.IN_PROGRESS]: {
    label: 'Ready',
    status: OrderStatusEnum.READY,
    renderCondition: () => true,
  },
  [OrderStatusEnum.READY]: {
    label: 'Picked-up',
    status: isDelivery ? OrderStatusEnum.IN_TRANSIT : OrderStatusEnum.PICKED,
    renderCondition: (order: OrderType) =>
      !isDelivery || (isDeliveryHandlerBento && !!order.driver),
  },
  [OrderStatusEnum.IN_TRANSIT]: {
    label: 'Delivered',
    status: OrderStatusEnum.DELIVERED,
    renderCondition: () => isDeliveryHandlerBento && isSuperAdmin,
  },
})

interface Props {
  order: OrderType
  onClose: (isChanged?: boolean) => void
  setOrder: (order: OrderType) => void
}

export function OrderDetailDialog({ order, onClose, setOrder }: Props) {
  const { open: openChangeStatusDialog, toggle: setOpenChangeStatusDialog } =
    useDialog()
  const { open: openConfirmDialog, toggle: setOpenConfirmDialog } = useDialog()
  const { open: addChargeIsOpen, toggle: setAddChargeIsOpen } = useModal()
  const {
    open: createMagicVoucherIsOpen,
    toggle: setCreateMagicVoucherIsOpen,
  } = useModal()
  const { open: profileIsOpen, toggle: setProfileIsOpen } = useModal()
  const { isSuperAdmin, timezone } = useAuth()
  const isDelivery = order.type === 'delivery'
  const isDeliveryHandlerBento = order.deliveryHandler === 'bento'

  const isOrderCompleted = (status: string) =>
    status === OrderStatusEnum.DELIVERED ||
    status === OrderStatusEnum.COMPLETED ||
    status === OrderStatusEnum.PICKED ||
    status === OrderStatusEnum.CANCELLED ||
    status === OrderStatusEnum.REJECTED
  const isOrderBentoInTransit = (status: string) =>
    status === OrderStatusEnum.IN_TRANSIT &&
    isDeliveryHandlerBento &&
    !isSuperAdmin

  const getNegative = (status: string): OrderStatusButton => {
    if (status === OrderStatusEnum.CREATED) {
      return orderNegativeStatusMapper[OrderStatusEnum.REJECTED]
    }
    return orderNegativeStatusMapper[OrderStatusEnum.CANCELLED]
  }

  const getPositive = (
    status: string,
    isDelivery: boolean,
    isSuperAdmin: boolean,
  ): OrderStatusButton => {
    return (
      orderPositiveStatusMapper(
        isDelivery,
        isDeliveryHandlerBento,
        isSuperAdmin,
      )[status] || {
        label: 'Completed',
        status: OrderStatusEnum.COMPLETED,
        renderCondition: () => true,
      }
    )
  }

  const { label: negativeLabel, status: negativeStatus } = getNegative(
    order.lastStatus,
  )

  const { label, status, renderCondition } = getPositive(
    order.lastStatus,
    isDelivery,
    isSuperAdmin,
  )

  const { mutateAsync: updateOrderStatus, isPending: isLoading } = useMutation({
    mutationFn: ordersService.updateOrderStatus,
  })

  async function handleOrderStatusChange(status: string, orderId: string) {
    try {
      await updateOrderStatus({ status, orderId })
      onClose(true)
    } finally {
      setOpenChangeStatusDialog(false)
    }
  }

  const renderConfirmModal = () => (
    <AlertDialog open={openConfirmDialog} onOpenChange={setOpenConfirmDialog}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{negativeLabel} Order</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want {negativeLabel.toLowerCase()} order for{' '}
            <strong>{order.user.name}</strong>?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isLoading}>No</AlertDialogCancel>
          <AlertDialogAction
            onClick={() =>
              handleOrderStatusChange(negativeStatus, order.orderId)
            }
            disabled={isLoading}
          >
            {isLoading && <Loading size={2} color="white" className="mr-1" />}
            Yes, {negativeLabel.toLowerCase()}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )

  const closeAddCharge = useCallback(
    (order?: OrderType) => {
      setAddChargeIsOpen(false)

      if (order) {
        setOrder({
          ...order,
          subTotal: order.subTotal / 100,
          total: order.total / 100,
          deliveryFee: order.deliveryFee ? order.deliveryFee / 100 : null,
        })
      }
    },
    [setAddChargeIsOpen, setOrder],
  )

  const renderAddChargeModal = () => {
    return (
      <Modal
        open={addChargeIsOpen}
        onOpenChange={(open) => {
          if (!open) {
            closeAddCharge()
            return
          }
          setAddChargeIsOpen(open)
        }}
      >
        <ModalContent title="Add Charge" onClose={closeAddCharge} width="auto">
          <AddChargeModal
            orderId={order?.orderId}
            closeAddCharge={closeAddCharge}
          />
        </ModalContent>
      </Modal>
    )
  }

  const renderCreateMagicVoucherModal = () => {
    return (
      <Modal
        open={createMagicVoucherIsOpen}
        onOpenChange={setCreateMagicVoucherIsOpen}
      >
        <ModalContent
          title="Create Magic Voucher"
          onClose={() => setCreateMagicVoucherIsOpen(false)}
          width="auto"
        >
          <CreateMagicVoucherModal
            orderId={order.orderId}
            orderShortId={order.shortId}
            close={() => setCreateMagicVoucherIsOpen(false)}
          />
        </ModalContent>
      </Modal>
    )
  }

  const renderProfileModal = () => {
    if (!order) return null
    return (
      <Modal open={profileIsOpen} onOpenChange={setProfileIsOpen} modal>
        <ModalContent
          title="Update Profile"
          onClose={() => setProfileIsOpen(false)}
        >
          <ProfileModal
            user={order.user}
            address={order.address}
            onCloseProfile={(data) => {
              setOrder({
                ...order,
                user: data.user,
                address: data.address,
              })
              setProfileIsOpen(false)
            }}
          />
        </ModalContent>
      </Modal>
    )
  }

  const { data: vouchersCompensation, isLoading: compensationIsLoading } =
    useQuery({
      enabled: !!order.magicVoucherCompensation,
      queryKey: ['order', order.magicVoucherCompensation],
      queryFn: () => ordersService.getCompensationVouchers(order.orderId),
    })

  return (
    <>
      <DialogHeader className="flex-row items-center justify-between space-y-0">
        <DialogClose onClick={() => onClose(false)}>
          <Back className="text-pink-base" />
        </DialogClose>

        <DialogTitle className="text-primary">Order Detail</DialogTitle>

        <DropdownMenu>
          <DropdownMenuTrigger>
            <CategoryList className="rotate-90 text-pink-base" />
          </DropdownMenuTrigger>
          {/* // TODO: remove class 'z-[101]' when change loyalty campaign detail to iguana dialog component */}
          <DropdownMenuContent className="z-[101]">
            {(isSuperAdmin || !isOrderCompleted(order.lastStatus)) && (
              <DropdownMenuItem onClick={() => setAddChargeIsOpen(true)}>
                Add charge
              </DropdownMenuItem>
            )}
            {!isOrderBentoInTransit(order.lastStatus) && (
              <DropdownMenuItem onClick={() => setOpenChangeStatusDialog(true)}>
                Change status
              </DropdownMenuItem>
            )}
            <DropdownMenuItem onClick={() => setCreateMagicVoucherIsOpen(true)}>
              Create magic voucher
            </DropdownMenuItem>
            <OrderPrintButton
              order={order}
              close={close}
              onOrderStatusChange={(status) =>
                handleOrderStatusChange(status, order.orderId)
              }
            />
          </DropdownMenuContent>
        </DropdownMenu>

        <Dialog
          open={openChangeStatusDialog}
          onOpenChange={setOpenChangeStatusDialog}
        >
          {/* // TODO: remove class 'z-[101]' when change loyalty campaign detail to iguana dialog component */}
          <DialogContent
            className="z-[101] md:!w-fit md:min-w-[300px]"
            onClose={() => setOpenChangeStatusDialog(false)}
            aria-describedby="Order Detail"
          >
            <ChangeStatusDialog
              order={order}
              closeChangeStatus={(status) =>
                handleOrderStatusChange(status, order.orderId)
              }
            />
          </DialogContent>
        </Dialog>
      </DialogHeader>

      <ScrollArea className="max-h-screen">
        <div className="grid gap-4 p-6 md:grid-cols-2">
          <div className="flex flex-col gap-4">
            {order?.driver && (
              <Card>
                <CardContent className="flex items-center gap-3 pt-4">
                  <Avatar>
                    <AvatarImage src={order?.driver.picture} />
                    <AvatarFallback>{order?.driver.name[0]}</AvatarFallback>
                  </Avatar>
                  <div>
                    <Heading size="h3">Driver: {order?.driver.name}</Heading>
                    <Text>Phone: {order?.driver.phone}</Text>
                  </div>
                </CardContent>
              </Card>
            )}

            <Card>
              <CardHeader className="flex flex-row items-center justify-between">
                <Text className="text-pink-base">
                  Nº {order.shortId} •{' '}
                  {formatDistanceToNow(
                    new Date(order.createdAt || ''),
                    true,
                    timezone,
                  )}
                </Text>

                <Text className="uppercase text-pink-base">{order.type}</Text>
              </CardHeader>
              <CardContent className="flex w-full gap-4">
                <div>
                  <Heading size="h3" className="mb-2 flex items-center gap-1">
                    Customer{' '}
                    {isSuperAdmin && (
                      <Button
                        size="icon"
                        className="size-auto shrink-0 border-none p-1"
                        disabled={isLoading}
                        onClick={() => setProfileIsOpen(true)}
                      >
                        <Edit className="size-4" />
                      </Button>
                    )}
                  </Heading>{' '}
                  <Text className="text-grayscale-gray">
                    {titleCase(order?.user?.name || '')}
                  </Text>
                  <Text className="text-grayscale-gray">
                    {order?.user.phoneNumber}
                  </Text>
                </div>
                {order?.type === 'delivery' && (
                  <div className="flex flex-col">
                    <Heading size="h3" className="mb-2 flex items-center gap-1">
                      Address{' '}
                      {isSuperAdmin && (
                        <Button
                          size="icon"
                          className="size-auto shrink-0 border-none p-1"
                          disabled={isLoading}
                          onClick={() => setProfileIsOpen(true)}
                        >
                          <Edit className="size-4" />
                        </Button>
                      )}
                    </Heading>{' '}
                    <ScrollArea className="table w-full table-fixed truncate pb-2">
                      <Text className="text-grayscale-gray">
                        {order?.address.streetNumber}{' '}
                        {order?.address.streetName} {order?.address.city}{' '}
                        {order?.address.description}
                      </Text>
                      <Text className="text-grayscale-gray">
                        <b>Extra Info: </b>
                        {order?.address.extraInfo}
                      </Text>
                      <Text className="text-grayscale-gray">
                        <b>Instructions: </b> {order?.address.instructions}
                      </Text>
                      <ScrollBar orientation="horizontal" />
                    </ScrollArea>
                  </div>
                )}
              </CardContent>
            </Card>

            {!!order.magicVoucherCompensation && (
              <Card>
                <CardContent className="flex flex-col gap-2 pt-4">
                  <Heading size="h4">Compensation Vouchers</Heading>

                  <div>
                    {compensationIsLoading ? (
                      <div className="flex justify-between">
                        <Skeleton className="h-5 w-32" />
                        <Skeleton className="h-5 w-32" />
                      </div>
                    ) : (
                      vouchersCompensation &&
                      order.note === 'merchant_voucher' && (
                        <div className="flex items-center justify-between">
                          <Text className="text-grayscale-gray">
                            {vouchersCompensation.voucherId}
                          </Text>
                          <Heading size="h6" className="font-bold">
                            {formatCurrencyWithCode(
                              vouchersCompensation.balance / 100,
                            )}
                          </Heading>
                        </div>
                      )
                    )}
                  </div>
                </CardContent>
              </Card>
            )}
          </div>

          <Card className="overflow-hidden">
            <CardHeader>
              <Text className="text-pink-base">Order</Text>
            </CardHeader>
            <CardContent className="h-full pr-2">
              <ScrollArea className="h-full md:!max-h-[350px]">
                <Text
                  dangerouslySetInnerHTML={{
                    __html:
                      order?.orderTextVersion?.replace(/\t/g, '&nbsp;&nbsp;') ||
                      '',
                  }}
                  className="whitespace-pre-wrap text-grayscale-gray [tab-size:2]"
                />
              </ScrollArea>
            </CardContent>
          </Card>
        </div>
      </ScrollArea>

      <DialogFooter className="w-full !flex-col gap-4 !space-x-0">
        <div className="flex w-full flex-col gap-1">
          <div className="flex items-center justify-between text-grayscale-gray">
            <Text>Sub total:</Text>{' '}
            <Text>{formatCurrencyWithCode(order?.subTotal || 0)}</Text>
          </div>
          {!isDeliveryHandlerBento && (
            <div className="flex items-center justify-between text-grayscale-gray">
              <Text>Delivery Fee:</Text>
              <Text>{formatCurrencyWithCode(order?.deliveryFee || 0)}</Text>
            </div>
          )}

          {((!isDelivery && (order?.tip?.amount ?? 0) > 0) ||
            (isDelivery &&
              (order?.tip?.amount ?? 0) > 0 &&
              !isDeliveryHandlerBento)) && (
            <div className="flex items-center justify-between text-grayscale-gray">
              <Text>Tip amount:</Text>
              <Text>
                ❤️ {'  '}
                {formatCurrencyWithCode(order?.tip?.amount || 0)}
              </Text>
            </div>
          )}
          {order.magicVouchers &&
            order.magicVouchers.length > 0 &&
            order.magicVouchers?.map((voucher) => {
              if (voucher.author !== 'bento' && voucher.author === 'merchant') {
                return (
                  <div
                    key={voucher.id}
                    className="flex items-center justify-between text-grayscale-gray"
                  >
                    <Text>Magic Voucher ({voucher.voucherId})</Text>
                    <Text className="text-brands-pets">
                      -{formatCurrencyWithCode(voucher.balance || 0)}
                    </Text>
                  </div>
                )
              }
              return null
            })}
          {order.refundAmount && (
            <div className="flex items-center justify-between text-grayscale-gray">
              <Text>Refund: {order.refundComment}</Text>
              <Text className="text-brands-package">
                -{formatCurrencyWithCode(order.refundAmount)}
              </Text>
            </div>
          )}
          <div className="flex items-center justify-between">
            <Heading size="h3">Total:</Heading>
            <Heading size="h3">
              {formatCurrencyWithCode(order.total || 0)}
            </Heading>
          </div>
        </div>

        {!isOrderCompleted(order.lastStatus) &&
          !isOrderBentoInTransit(order.lastStatus) && (
            <div className="flex w-full items-center justify-between">
              <Button
                type="button"
                variant="secondary"
                onClick={() => setOpenConfirmDialog(true)}
                disabled={isLoading}
              >
                {negativeLabel}
              </Button>
              {renderCondition(order) && (
                <Button
                  type="button"
                  disabled={isLoading}
                  onClick={() => handleOrderStatusChange(status, order.orderId)}
                >
                  {isLoading && (
                    <Loading size={2} color="white" className="mr-1" />
                  )}
                  {label}
                </Button>
              )}
            </div>
          )}
      </DialogFooter>

      {addChargeIsOpen &&
        (isSuperAdmin || !isOrderCompleted(order.lastStatus)) &&
        renderAddChargeModal()}
      {createMagicVoucherIsOpen && renderCreateMagicVoucherModal()}
      {profileIsOpen && isSuperAdmin && renderProfileModal()}
      {renderConfirmModal()}
    </>
  )
}

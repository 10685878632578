import { useState } from 'react'

import { OrderAddressType } from '@/types/orders.types'

import AddressList from './components/AddressList'
import { FormAddress, SaveAddressType } from './components/FormAddress'
import * as S from './styles'

type AddressProps = {
  address: OrderAddressType
  loading?: boolean
  onSaveAddress: (addresses: SaveAddressType) => void
}

export const Address = ({ address, onSaveAddress, loading }: AddressProps) => {
  const [editAddress, setEditAddress] = useState<OrderAddressType | null>(null)
  const [isEditing, setIsEditing] = useState(false)

  return (
    <div>
      <S.HeaderTitle>Address</S.HeaderTitle>

      {isEditing ? (
        <FormAddress
          oldAddress={editAddress}
          onSaveAddress={(addresses) => {
            setIsEditing(false)
            onSaveAddress(addresses)
          }}
        />
      ) : (
        <>
          <AddressList
            items={[address]}
            onEditAddress={(address) => {
              setIsEditing(true)
              setEditAddress(address)
            }}
            loading={loading}
          />
        </>
      )}
    </div>
  )
}

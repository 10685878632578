import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.section`
  ${({ theme }) => css`
    min-width: 22.5rem;
    width: 100%;

    .slick-dots {
      list-style: none;
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-top: ${theme.spacings.small};

      li {
        background: ${theme.colors.gray};
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0.5rem;
        cursor: pointer;

        &.slick-active {
          background: ${theme.colors.primary};
        }
      }

      button {
        opacity: 0;
        width: 0.75rem;
        height: 0.75rem;
        cursor: pointer;
      }
    }

    .slick-list {
      min-height: 37.5rem;
    }

    .slick-next,
    .slick-prev {
      width: 3.125rem;
      height: 3.125rem;
      z-index: ${theme.layers.base};
      color: ${theme.colors.gray};

      &:hover {
        color: ${theme.colors.lightGray};
      }
    }

    ${media.lessThan('medium')`
      margin-bottom: ${theme.spacings.small};

      .slick-arrow {
        display: none !important;
      }
    `}
  `}
`

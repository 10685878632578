import styled, { css, keyframes } from 'styled-components'
import media from 'styled-media-query'

import { ModalOverflow } from '@/components/Modal'

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
`

const slideOut = keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
`

const slideLeft = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
`

const slideRight = keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
`

export const Wrapper = styled.div``

export const StepNavigation = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: 2.213rem;
    right: 0;
    left: 0;
    width: 30%;
    transform: translateX(calc(100% + 1.5rem));
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    z-index: 2;
    color: ${theme.colors.primary};
  `}
`

export const StepNavigationItem = styled.div<{
  active?: number
  completed?: number
}>`
  ${({ theme, active = 0, completed = 0 }) => css`
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 99999px;
    background-color: ${theme.colors.lightGray};

    ${active === 1 &&
    css`
      background-color: ${theme.colors.primary};
    `}

    ${completed === 1 &&
    css`
      background-color: ${theme.colors.green};
    `}
  `}
`

export const Steps = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`

export const Step = styled.div<{ active?: number }>`
  ${({ active = 0 }) => css`
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: ${active === 0 ? 0 : 1};
    z-index: ${active === 0 ? 0 : 1};
    transition: opacity 0.3s ease-in-out;

    ${media.greaterThan('medium')`
      height: 90vh;
    `}
  `}
`

export const StepOverflow = styled(ModalOverflow)<{
  active?: number
  before?: number
}>`
  ${({ active = 0, before = 0 }) => css`
    ${before === 1
      ? css`
          animation: ${active === 0 ? slideRight : slideLeft} 0.3s ease-in-out
            forwards;
        `
      : css`
          animation: ${active === 0 ? slideOut : slideIn} 0.3s ease-in-out
            forwards;
        `}
  `}
`

export const StepForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  height: inherit;
`

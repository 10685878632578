import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  padding: 1.5rem;

  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Flex1 = styled.div`
  flex: 1;
`

export const Title = styled.div`
  ${({ theme }) => css`
    font-family: 'Quicksand';
    font-size: 1.875rem;
    font-weight: 700;
    color: ${theme.colors.primary};

    width: 100%;
    text-align: center;
  `}
`

export const Description = styled.div`
  ${({ theme }) => css`
    font-family: 'Quicksand';
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.22;
    color: ${theme.colors.gray};
    margin-top: 1rem;
  `}
`

export const Section = styled.div`
  margin-top: 1.75rem;
  padding: 1.5rem;
  border-radius: 25px;
  background-color: #ffffff;

  display: flex;
  gap: 1.25rem;
`

export const ButtonWrapper = styled.div`
  margin-top: 1.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SelectDialCode = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 10.625rem;
  border-bottom: 1px solid #979797;
  font-size: 1rem;
  color: #8e8b8b;
  padding-bottom: 0.5rem;
  font-weight: 500;
  cursor: pointer;
`

export const FlagEmoji = styled.div`
  font-size: 1.5rem;
  line-height: 0.75rem;
`

export const Icon = styled.img`
  margin-left: auto;
  margin-right: 0.125rem;
`

import { Dialog, DialogContent } from '@iguanads/react'
import {
  HighlightOff as CloseIcon,
  Search as SearchIcon,
} from '@styled-icons/material-outlined'
import { ChangeEventHandler, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { useDebounce } from 'react-use'
import { useTheme } from 'styled-components'

import OrdersService from '@/api/orders.service'
import performanceDelivery from '@/assets/img/icons-performance-delivery.svg'
import performanceDeliveryLight from '@/assets/img/icons-performance-delivery-light.svg'
import performanceTakeout from '@/assets/img/icons-performance-takeout.svg'
import performanceTakeoutLight from '@/assets/img/icons-performance-takeout-light.svg'
import { Box } from '@/components/Box'
import Button from '@/components/Button'
import Skeleton from '@/components/Skeleton'
import { getColorModifier, statusModifiers, TypeEnum } from '@/components/Table'
import * as T from '@/components/Table/styles'
import TextField from '@/components/TextField'
import { OrderDetailDialog } from '@/dialogs/order-detail.dialog'
import { useDialog } from '@/hooks/use-dialog'
import useAuth from '@/hooks/useAuth'
import { OrderType } from '@/types/orders.types'
import { formatDistanceToNow } from '@/utils/formats/date'
import { formatCurrencyWithCode } from '@/utils/formats/number'

import * as S from './styles'

const MyLoader = () => (
  <S.LoaderContainer>
    <Box
      flex
      alignItems="center"
      JustifyContent="space-between"
      gap="0.25rem"
      width="100%"
    >
      <Box flex width="70%">
        <Skeleton width="2.5rem" height="2.5rem" radius="4px" />
      </Box>

      <Box flex flexDirection="column" width="100%">
        <Skeleton />
        <Skeleton />
      </Box>

      <Box flex JustifyContent="center" width="100%">
        <Skeleton width="5rem" height="1.875rem" />
      </Box>

      <Box flex flexDirection="column" width="100%">
        <Skeleton />
        <Skeleton />
      </Box>
    </Box>
  </S.LoaderContainer>
)

const SearchInput = () => {
  const [term, setTerm] = useState('')
  const [searchData, setSearchData] = useState<OrderType[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [openPreview, setOpenPreview] = useState(false)
  const [currentItem, setCurrentItem] = useState<OrderType | null>(null)
  const { open: modalIsOpen, toggle: setModalIsOpen } = useDialog()
  const theme = useTheme()
  const { timezone } = useAuth()

  const openOrderDetail = (item: OrderType) => {
    setOpenPreview(false)
    setCurrentItem(item)
    setModalIsOpen(true)
    setIsLoading(false)
    setTerm('')
  }

  const renderOrderDetail = () => {
    if (!currentItem) return null
    return (
      <Dialog open={modalIsOpen} onOpenChange={setModalIsOpen}>
        <DialogContent hideCloseButton onClose={() => setModalIsOpen(false)}>
          <OrderDetailDialog
            order={currentItem}
            onClose={() => setModalIsOpen(false)}
            setOrder={setCurrentItem}
          />
        </DialogContent>
      </Dialog>
    )
  }

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setTerm(event.target.value)
  }

  useEffect(() => {}, [term])

  const [, cancel] = useDebounce(
    () => {
      if (term.length >= 3) {
        getSearch()
        setOpenPreview(true)
      } else {
        setOpenPreview(false)
      }
    },
    400,
    [term],
  )

  const getSearch = async () => {
    setIsLoading(true)
    cancel()

    try {
      const { data } = await OrdersService.getOrdersByShortId(term)

      setSearchData(
        data.map((item: OrderType) => {
          return {
            ...item,
            subTotal: item.subTotal / 100,
            total: item.total / 100,
            deliveryFee: item.deliveryFee ? item.deliveryFee / 100 : null,
            tip: item.tip
              ? {
                  orderId: item.shortId,
                  amount: item.tip.amount / 100,
                }
              : null,
            magicVouchers: item.magicVouchers
              ? item.magicVouchers.map((voucher) => ({
                  ...voucher,
                  balance: voucher?.balance / 100,
                }))
              : [],
            refundAmount: item.refundAmount ? item.refundAmount / 100 : null,
            items: item.items?.map((child) => ({
              ...child,
              price: child.price / 100,
              modifiers: child.modifiers?.map((modifier) => ({
                ...modifier,
                options: modifier.options?.map((option) => ({
                  ...option,
                  price: option.price / 100,
                })),
              })),
            })),
          }
        }),
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <S.SearchInputContainer>
      <TextField
        onChange={onChange}
        value={term}
        placeholder="Search"
        icon={
          term ? (
            <>
              <Button
                onClick={() => setTerm('')}
                icon={<CloseIcon />}
                style={{ padding: 0, height: '2.375rem' }}
                data-tip="Clear search"
                minimal
              />
              <ReactTooltip
                effect="solid"
                className="tooltip"
                backgroundColor="#433d3d"
                borderColor="#433d3d"
              />
            </>
          ) : (
            <SearchIcon />
          )
        }
        iconPosition="right"
      />

      {openPreview && (
        <S.SearchPreviewContainer>
          <S.SearchPreviewWrapper>
            {!isLoading && searchData.length === 0 && (
              <S.Empty>No items found</S.Empty>
            )}

            {isLoading && <MyLoader />}

            {!isLoading &&
              searchData.map((order: OrderType) => {
                const isCollected =
                  order.driver && order.driver.tripStatus === 'collected'

                return (
                  <S.SearchPreviewItem
                    onClick={() => openOrderDetail(order)}
                    key={order.orderId}
                  >
                    <S.SearchPreviewItemData>
                      {theme.dark ? (
                        <img
                          src={
                            order.type === TypeEnum.DELIVERY
                              ? performanceDeliveryLight
                              : performanceTakeoutLight
                          }
                          width={32}
                          height={32}
                          alt={order.type}
                        />
                      ) : (
                        <img
                          src={
                            order.type === TypeEnum.DELIVERY
                              ? performanceDelivery
                              : performanceTakeout
                          }
                          width={32}
                          height={32}
                          alt={order.type}
                        />
                      )}
                    </S.SearchPreviewItemData>
                    <S.SearchPreviewItemData>
                      <T.Client>
                        <T.ClientName>{order.user.name}</T.ClientName>
                        <T.ClientShortId>{order.shortId}</T.ClientShortId>
                      </T.Client>
                    </S.SearchPreviewItemData>
                    <S.SearchPreviewItemData>
                      {order.trip && isCollected ? (
                        <T.StatusWrapper
                          bgColor={getColorModifier('collected', theme)}
                          style={{ color: theme.colors.secondary }}
                        >
                          {statusModifiers.collected}
                        </T.StatusWrapper>
                      ) : (
                        <T.StatusWrapper
                          bgColor={getColorModifier(order.lastStatus, theme)}
                        >
                          {statusModifiers[order.lastStatus]}
                        </T.StatusWrapper>
                      )}
                      <ReactTooltip
                        effect="solid"
                        className="tooltip"
                        backgroundColor="#433d3d"
                        borderColor="#433d3d"
                      />
                    </S.SearchPreviewItemData>
                    <S.SearchPreviewItemData>
                      <T.CurrencyWrapper>
                        {formatCurrencyWithCode(order.total)}
                      </T.CurrencyWrapper>
                      <T.DateWrapper>
                        {formatDistanceToNow(
                          new Date(order.createdAt),
                          true,
                          timezone,
                        )}
                      </T.DateWrapper>
                    </S.SearchPreviewItemData>
                  </S.SearchPreviewItem>
                )
              })}
          </S.SearchPreviewWrapper>
        </S.SearchPreviewContainer>
      )}
      {renderOrderDetail()}
    </S.SearchInputContainer>
  )
}

export default SearchInput

import { Dialog, DialogContent } from '@iguanads/react'
import { useMemo, useState } from 'react'
import { useTheme } from 'styled-components'

import performanceDelivery from '@/assets/img/icons-performance-delivery.svg'
import performanceDeliveryLight from '@/assets/img/icons-performance-delivery-light.svg'
import performanceTakeout from '@/assets/img/icons-performance-takeout.svg'
import performanceTakeoutLight from '@/assets/img/icons-performance-takeout-light.svg'
import { Box } from '@/components/Box'
import Empty from '@/components/Empty'
import {
  ModalHeader,
  ModalHeaderTitle,
  ModalOverflow,
} from '@/components/Modal'
import Table, {
  getColorModifier,
  statusModifiers,
  TypeEnum,
} from '@/components/Table'
import * as T from '@/components/Table/styles'
import { OrderDetailDialog } from '@/dialogs/order-detail.dialog'
import { useDialog } from '@/hooks/use-dialog'
import useAuth from '@/hooks/useAuth'
import { OrderType } from '@/types/orders.types'
import { formatDistanceToNow } from '@/utils/formats/date'
import { formatCurrencyWithCode } from '@/utils/formats/number'

import * as S from './styles'

export type OrdersModalProps = {
  orders: OrderType[]
}

export const OrdersModal = ({ orders }: OrdersModalProps) => {
  const [orderDetailData, setOrderDetailData] = useState<OrderType>()
  const { open: modalIsOpen, toggle: setModalIsOpen } = useDialog()
  const theme = useTheme()
  const { timezone } = useAuth()

  const openOrderDetail = (orderDetail: OrderType) => {
    setOrderDetailData(orderDetail)
    setModalIsOpen(true)
  }

  const renderOpenDetailModal = () => {
    if (!orderDetailData) return null
    return (
      <Dialog open={modalIsOpen} onOpenChange={setModalIsOpen}>
        {/* // TODO: remove class 'z-[101]' when change loyalty campaign detail to iguana dialog component */}
        <DialogContent
          className="z-[101]"
          hideCloseButton
          onClose={() => setModalIsOpen(false)}
        >
          <OrderDetailDialog
            order={
              {
                ...orderDetailData,
                tip: orderDetailData?.tip
                  ? {
                      orderId: orderDetailData.shortId,
                      amount: orderDetailData.tip.amount / 100,
                    }
                  : null,
                magicVouchers: orderDetailData?.magicVouchers?.map(
                  (voucher) => {
                    return {
                      ...voucher,
                      balance: voucher.balance / 100,
                    }
                  },
                ),
                refundAmount: orderDetailData?.refundAmount
                  ? orderDetailData.refundAmount / 100
                  : null,
                deliveryFee: (orderDetailData?.deliveryFee || 0) / 100,
                subTotal: (orderDetailData?.subTotal || 0) / 100,
                total: (orderDetailData?.total || 0) / 100,
                items: orderDetailData?.items?.map((itemChild) => ({
                  ...itemChild,
                  price: itemChild.price / 100,
                  modifiers: itemChild.modifiers?.map((modifier) => ({
                    ...modifier,
                    options: modifier.options?.map((option) => ({
                      ...option,
                      price: option.price / 100,
                    })),
                  })),
                })),
              } as OrderType
            }
            onClose={() => setModalIsOpen(false)}
            setOrder={setOrderDetailData}
          />
        </DialogContent>
      </Dialog>
    )
  }

  const columns = useMemo(
    () => [
      {
        name: 'Mode',
        selector: (row: OrderType) => row.type,
        sortable: true,
        center: true,
        width: '60px',
        cell: (row: OrderType) => {
          if (theme.light)
            return (
              <img
                data-tag="allowRowEvents"
                src={
                  row.type === TypeEnum.DELIVERY
                    ? performanceDelivery
                    : performanceTakeout
                }
                width={32}
                height={32}
                alt={row.type}
              />
            )
          else {
            return (
              <img
                data-tag="allowRowEvents"
                src={
                  row.type === TypeEnum.DELIVERY
                    ? performanceDeliveryLight
                    : performanceTakeoutLight
                }
                width={32}
                height={32}
                alt={row.type}
              />
            )
          }
        },
      },
      {
        name: 'Client',
        selector: (row: OrderType) => row.user.name,
        sortable: true,
        center: true,
        minWidth: '146px',
        cell: (row: OrderType) => (
          <T.Client>
            <T.ClientName data-tag="allowRowEvents">
              {row.user.name}
            </T.ClientName>
            <T.ClientShortId data-tag="allowRowEvents">
              {row.shortId}
            </T.ClientShortId>
          </T.Client>
        ),
      },
      {
        name: 'Status',
        selector: (row: OrderType) => row.lastStatus,
        sortable: true,
        center: true,
        minWidth: '85px',
        cell: (row: OrderType) => {
          const isCollected =
            row.driver && row.driver.tripStatus === 'collected'
          if (row.trip && isCollected) {
            return (
              <T.StatusWrapper
                data-tag="allowRowEvents"
                bgColor={getColorModifier('collected', theme)}
                style={{ color: theme.colors.secondary }}
              >
                {statusModifiers.collected}
              </T.StatusWrapper>
            )
          }

          return (
            <T.StatusWrapper
              data-tag="allowRowEvents"
              bgColor={getColorModifier(row.lastStatus, theme)}
            >
              {statusModifiers[row.lastStatus]}
            </T.StatusWrapper>
          )
        },
      },
      {
        name: 'Date',
        selector: (row: OrderType) => row.createdAt,
        sortable: true,
        center: true,
        id: 'createdAt',
        minWidth: '118px',
        cell: (row: OrderType) => (
          <T.DateWrapper data-tag="allowRowEvents">
            {formatDistanceToNow(new Date(row.createdAt), true, timezone)}
          </T.DateWrapper>
        ),
      },
      {
        name: 'Value',
        selector: (row: OrderType) => row.total,
        sortable: true,
        center: true,
        minWidth: '110px',
        cell: (row: OrderType) => {
          return (
            <T.CurrencyWrapper data-tag="allowRowEvents">
              {formatCurrencyWithCode(row.total / 100)}
            </T.CurrencyWrapper>
          )
        },
      },
    ],
    [theme, timezone],
  )

  return (
    <>
      <ModalHeader simple={1}>
        <ModalHeaderTitle>Orders</ModalHeaderTitle>
      </ModalHeader>
      <ModalOverflow>
        <S.CloseButton>✕</S.CloseButton>
        <S.Content>
          {orders.length === 0 ? (
            <Box
              flex
              JustifyContent="center"
              alignItems="center"
              width="100%"
              height="12.5rem"
            >
              <Empty title="There is nothing here, yet" />
            </Box>
          ) : (
            <Table<OrderType>
              defaultSortFieldId="createdAt"
              defaultSortAsc={false}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              columns={columns as any}
              data={orders}
              onRowClicked={openOrderDetail}
              highlightOnHover={true}
              pointerOnHover={true}
            />
          )}
        </S.Content>

        {renderOpenDetailModal()}
      </ModalOverflow>
    </>
  )
}

import {
  Add as AddIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  FlipCameraIos as FlipCameraIcon,
  Info as InfoIcon,
  PhotoCamera as PhotoCameraIcon,
} from '@styled-icons/material-outlined'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { ValueType } from 'react-select'
import ReactTooltip from 'react-tooltip'
import slugify from 'slugify'

import menuMakerService from '@/api/menu-maker.service'
import othersPriceIcon from '@/assets/img/icons-others-price.svg'
import { Box } from '@/components/Box'
import Button from '@/components/Button'
import Checkbox from '@/components/Checkbox'
import Empty from '@/components/Empty'
import List from '@/components/List'
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverflow,
} from '@/components/Modal'
import Radio from '@/components/Radio'
import Select from '@/components/Select'
import SelectButton from '@/components/SelectButton'
import Switch from '@/components/Switch'
import TextArea from '@/components/TextArea'
import TextField from '@/components/TextField'
import useModal from '@/hooks/useModal'
import ConfirmModal from '@/modals/ConfirmModal'
import CropPictureModal from '@/modals/CropPictureModal'
import EditModifierModal from '@/modals/EditModifierModal'
import {
  CategoryType,
  ItemType,
  MenuAvailability,
  MenusType,
  ModifierType,
} from '@/types/menu-maker.types'
import { formatCurrency } from '@/utils/formats/number'

import * as S from './styles'

export type EditItemModalProps = {
  editItemData: ItemType | null
  merchant: string
  categories: CategoryType[]
  menus: MenusType[]
  modifiers: ModifierType[]
  items: ItemType[]
  closeEditItem: (refresh: boolean, item?: ItemType) => void
}

type FormErrorsType = {
  name: string
  categories: string
  hasModifier: string
}

type CategorySelectedType = {
  name: string
  value: string
  selected: boolean
  menus: string[]
}

type MenuSelectedType = {
  name: string
  price: string | number
  menuId: string
  selected: boolean
}

export type ImageUploadType = {
  file: File | null
  imagePreview: string
}

const itemDefault: ItemType = {
  available: true,
  categories: [],
  description: '',
  categoriesName: [],
  dislikes: 0,
  featuredPicture: '',
  id: '',
  isFeatured: false,
  isSellable: true,
  itemId: '',
  likes: 0,
  menus: [],
  merchant: '',
  modifiers: [],
  name: '',
  perfectMatchViews: 0,
  price: 0,
  prices: [],
  random: 0,
  sponsoredItemsViews: 0,
  sponsoredViews: 0,
  weekday: 6,
}

const convertHeicToPng = async (file: File) => {
  const heic2any = (await import('heic2any')).default

  const blob = file.slice(0, file.size, file.type)
  const fileConverted = await heic2any({ blob, toType: 'image/png' })
  const name = file.name.toLowerCase().replace('.heic', '.png')
  const type = 'image/png'

  return { fileConverted, name, type }
}

const selectMenuSelectedByCategory = (
  menus: MenuSelectedType[],
  editItemData: ItemType | null,
) => {
  return menus.map((menu) => {
    if (editItemData?.prices) {
      const priceIndex = editItemData.prices.findIndex(
        (m) => m.menuId === menu.menuId!,
      )

      let selected = editItemData.menusAvailability?.some(
        (m) => m.menuId === menu.menuId! && m.available,
      )
      selected = selected || false

      const price =
        priceIndex !== -1 ? editItemData?.prices[priceIndex]?.price : null

      return {
        menuId: menu.menuId!,
        name: menu.name,
        price:
          price === null
            ? formatCurrency(editItemData?.price)
            : price
              ? formatCurrency(price)
              : '',
        selected,
      }
    }
    return {
      menuId: menu.menuId!,
      name: menu.name,
      price: '',
      selected: false,
    }
  })
}

const selectMenuByCategory = (
  menus: MenusType[],
  selectedMenus: MenuSelectedType[],
  categoriesSelected: CategorySelectedType[],
) => {
  const menusIds = categoriesSelected.map((cat) => cat.menus).flat()
  const categoryMenus = menusIds.filter(
    (item, index) => menusIds.indexOf(item) === index,
  )

  return menus
    .filter((menu) => categoryMenus.some((m) => m === menu.menuId))
    .map((menu) => {
      const selectedFound = selectedMenus.find(
        (item) => item.menuId === menu.menuId,
      )
      return {
        name: menu.name,
        menuId: menu.menuId!,
        price: selectedFound ? selectedFound.price : '',
        selected: selectedFound ? selectedFound.selected : false,
      }
    })
}

const EditItemModal = ({
  editItemData,
  closeEditItem,
  merchant,
  categories,
  menus,
  modifiers,
  items,
}: EditItemModalProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [name, setName] = useState(editItemData?.name || '')
  const [pictureUpload, setPictureUpload] = useState<ImageUploadType>({
    file: null,
    imagePreview: editItemData?.featuredPicture || '',
  })
  const { open: openCropModal, toggle: setOpenCropModal } = useModal()
  const { open: modifierModalIsOpen, toggle: setModifierModalIsOpen } =
    useModal()
  const { open: openDeleteConfirmModal, toggle: setOpenDeleteConfirmModal } =
    useModal()
  const { open: openPriceConfirmModal, toggle: setOpenPriceConfirmModal } =
    useModal()
  const [description, setDescription] = useState(
    editItemData?.description || '',
  )
  const [available, setAvailable] = useState(editItemData?.available !== false)
  const [price, setPrice] = useState(
    String(formatCurrency(editItemData?.price || 0)),
  )
  const [isSellable, setIsSellable] = useState<boolean>(
    editItemData?.isSellable !== false,
  )
  const [modifiersSelected, setModifiersSelected] = useState<ModifierType[]>(
    editItemData?.modifiers || [],
  )
  const [modifiersOptions, setModifiersOptions] = useState<ModifierType[]>(
    modifiers || [],
  )
  const [selected, setSelected] = useState<ModifierType | null>(null)
  const [categoriesSelected, setCategoriesSelected] = useState<
    CategorySelectedType[]
  >(
    categories.map((cat) => {
      return {
        name: cat.name,
        value: cat.categoryId!,
        menus: cat.menus,
        selected: editItemData?.categories
          ? editItemData.categories.includes(cat.categoryId!)
          : false,
      }
    }),
  )
  const [menusSelected, setMenusSelected] = useState<MenuSelectedType[]>([])
  const [formErrors, setFormErrors] = useState<FormErrorsType>({
    name: '',
    categories: '',
    hasModifier: '',
  })
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleSelectCategory = useCallback(
    (category: CategorySelectedType, index: number) => {
      setFormErrors((old) => ({ ...old, categories: '' }))
      const newCategoriesSelected = [...categoriesSelected]
      newCategoriesSelected[index] = {
        ...category,
        selected: !category.selected,
      }
      setCategoriesSelected(newCategoriesSelected)
    },
    [categoriesSelected],
  )

  const handleSelectMenu = useCallback(
    (menu: MenuSelectedType, index: number) => {
      const newMenusSelected = [...menusSelected]
      newMenusSelected[index] = {
        ...menu,
        price: !menu.selected ? price : '',
        selected: !menu.selected,
      }
      setMenusSelected(newMenusSelected)
    },
    [menusSelected, price],
  )

  const handleSetMenuPrice = useCallback(
    (value: string, menu: MenuSelectedType, index: number) => {
      const newMenusSelected = [...menusSelected]
      newMenusSelected[index] = {
        ...menu,
        price: value,
      }
      setMenusSelected(newMenusSelected)
    },
    [menusSelected],
  )

  const saveItem = useCallback(async () => {
    setIsLoading(true)
    const categories = categoriesSelected
      .filter((c) => c.selected)
      .map((cat) => cat.value)
    const prices = menusSelected
      .filter((m) => m.selected)
      .map(({ menuId, price }) => ({
        menuId,
        price: parseInt(price.toString().replace('.', ''), 10),
      }))
    const menusAvailability = menusSelected
      .filter((m) => m.selected)
      .map(
        ({ menuId, selected }) =>
          ({
            menuId,
            available: selected,
          }) as MenuAvailability,
      )

    const data: ItemType = editItemData
      ? {
          ...editItemData,
          name,
          description,
          price: parseInt(price.toString().replace('.', ''), 10),
          available,
          isSellable,
          categories,
          prices,
          menusAvailability,
          modifiers: modifiersSelected,
        }
      : ({
          ...itemDefault,
          name,
          description,
          price: parseInt(price.toString().replace('.', ''), 10),
          available,
          isSellable,
          categories,
          merchant,
          prices,
          menusAvailability,
          modifiers: modifiersSelected,
        } as ItemType)

    if (!data.name) {
      setFormErrors((old) => ({ ...old, name: 'Name is required' }))
      setIsLoading(false)
      return
    }

    setFormErrors((old) => ({ ...old, name: '' }))

    if (!data.categories.length && isSellable) {
      setFormErrors((old) => ({
        ...old,
        categories: 'Select at least one category',
      }))
      setIsLoading(false)
      return
    }

    setFormErrors((old) => ({ ...old, categories: '' }))

    delete data.featuredPicture

    try {
      const { data: itemSave } = !data.itemId
        ? await menuMakerService.saveItems(data)
        : { data }

      if (!pictureUpload.imagePreview) {
        itemSave.featuredPicture = ''
      }

      if (pictureUpload.file) {
        const { data: response } = await menuMakerService.savePicture(
          itemSave.merchant,
          itemSave.itemId,
          pictureUpload.file,
        )
        itemSave.featuredPicture = response[0].url
      }

      await menuMakerService.saveItems(itemSave)
      closeEditItem(true, itemSave)
    } finally {
      setIsLoading(false)
    }
  }, [
    categoriesSelected,
    menusSelected,
    editItemData,
    name,
    description,
    price,
    available,
    isSellable,
    modifiersSelected,
    merchant,
    pictureUpload,
    closeEditItem,
  ])

  const handleUploadButton = () => {
    fileInputRef.current && fileInputRef.current.click()
  }

  const handleUploadChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null
    const timestamp = new Date().getTime()
    if (file) {
      const blob = file.slice(0, file.size, file.type)
      let fileBlob: Blob | Blob[] = blob
      let name = file.name
      let type = file.type

      if (file.type === 'image/heic') {
        const filePng = await convertHeicToPng(file)
        fileBlob = filePng.fileConverted
        name = filePng.name
        type = filePng.type
      }

      const newFile = new File(
        [fileBlob as Blob],
        slugify(timestamp + name, {
          trim: true,
          lower: true,
        }),
        { type },
      )

      const imagePreview = URL.createObjectURL(newFile)
      setPictureUpload({ file: newFile, imagePreview })
      setOpenCropModal(true)
    }
  }

  const handleEditPhoto = async () => {
    const img = new Image()
    img.src = pictureUpload.imagePreview

    const response = await fetch(pictureUpload.imagePreview)

    const blob = await response.blob()
    const index = pictureUpload.imagePreview.lastIndexOf('/') + 1
    const lastPart = pictureUpload.imagePreview.substring(index)
    const imageName = lastPart.substring(lastPart.length - 33, lastPart.length)

    const file = new File([blob], imageName, { type: blob.type })

    const timestamp = new Date().getTime()
    if (file) {
      const blob = file.slice(0, file.size, file.type)
      const newFile = new File(
        [blob],
        slugify(timestamp + file.name, {
          trim: true,
          lower: true,
        }),
        {
          type: file?.type,
        },
      )

      const imagePreview = URL.createObjectURL(newFile)
      setPictureUpload({ file: newFile, imagePreview })
      setOpenCropModal(true)
    }
  }

  const handleModifierSelectChange = (
    option: ValueType<ModifierType, false>,
  ) => {
    if (option) {
      setFormErrors((old) => ({ ...old, hasModifier: '' }))
      const hasModifier = modifiersSelected.some(
        (item) => item.modifierId === option.modifierId,
      )
      if (hasModifier) {
        setFormErrors((old) => ({
          ...old,
          hasModifier: 'Modifier already added',
        }))
        return
      }
      setModifiersSelected((old) => [...old, option])
      setSelected(null)
    }
  }

  const handleReorder = (modifiers: ModifierType[]) => {
    setModifiersSelected(modifiers)
  }

  const handleRemoveModifier = (index: number) => {
    const newModifiers = [...modifiersSelected]
    newModifiers.splice(index, 1)
    setModifiersSelected(newModifiers)
  }

  const renderModalCrop = () => (
    <Modal
      open={openCropModal}
      onOpenChange={(open) => {
        if (!open) {
          setPictureUpload({
            ...pictureUpload,
            imagePreview: editItemData?.featuredPicture || '',
          })
        }
        setOpenCropModal(open)
      }}
      modal
    >
      <ModalContent
        title="Crop Picture"
        onClose={() => setOpenCropModal(false)}
      >
        <CropPictureModal
          picture={pictureUpload}
          closeCropped={(pictureCropped, cancel) => {
            if (cancel)
              setPictureUpload({
                ...pictureUpload,
                imagePreview: editItemData?.featuredPicture || '',
              })
            if (pictureCropped) setPictureUpload(pictureCropped)
            setOpenCropModal(false)
          }}
        />
      </ModalContent>
    </Modal>
  )

  const closeModalModifier = (refresh?: boolean, modifier?: ModifierType) => {
    if (refresh && modifier) {
      setModifiersOptions((old) => [...old, modifier])
    }
    setModifierModalIsOpen(false)
  }

  const renderOpenModifierModal = () => {
    return (
      <Modal
        open={modifierModalIsOpen}
        onOpenChange={setModifierModalIsOpen}
        modal
      >
        <ModalContent
          title="Create Modifier"
          onClose={() => setModifierModalIsOpen(false)}
        >
          <EditModifierModal
            closeEditModifier={closeModalModifier}
            editModifierData={null}
            merchant={merchant}
            items={items}
            categories={categories}
            menus={menus}
            modifiers={modifiers}
          />
        </ModalContent>
      </Modal>
    )
  }

  const deleteItem = useCallback(async () => {
    setIsDeleting(true)
    const categories = categoriesSelected
      .filter((c) => c.selected)
      .map((cat) => cat.value)
    const prices = menusSelected
      .filter((m) => m.selected)
      .map(({ menuId, price }) => ({
        menuId,
        price: parseInt(price.toString().replace('.', ''), 10),
      }))
    const data: ItemType = {
      ...editItemData!,
      name,
      description,
      price: parseInt(price.toString().replace('.', ''), 10),
      available,
      isSellable,
      categories,
      prices,
      modifiers: modifiersSelected,
    }

    try {
      await menuMakerService.deleteItem(data)
      closeEditItem(true)
    } finally {
      setIsDeleting(false)
    }
  }, [
    available,
    categoriesSelected,
    closeEditItem,
    description,
    editItemData,
    isSellable,
    menusSelected,
    modifiersSelected,
    name,
    price,
  ])

  const renderPriceConfirmModal = () => (
    <Modal
      open={openPriceConfirmModal}
      onOpenChange={setOpenPriceConfirmModal}
      modal
    >
      <ModalContent
        title="Item without a price"
        onClose={() => setOpenPriceConfirmModal(false)}
        width="auto"
        xsWidth="90vw"
        xsHeight="auto"
        hideCloseButton
      >
        <ConfirmModal
          message="Are you sure you want to save this item without a price?"
          closeConfirm={(confirm) => {
            if (confirm) saveItem()
            setOpenPriceConfirmModal(false)
          }}
          confirmLabel="Save"
        />
      </ModalContent>
    </Modal>
  )

  const renderDeleteConfirmModal = () => (
    <Modal
      open={openDeleteConfirmModal}
      onOpenChange={setOpenDeleteConfirmModal}
      modal
    >
      <ModalContent
        title="Delete Item"
        onClose={() => setOpenDeleteConfirmModal(false)}
        width="auto"
        xsWidth="90vw"
        xsHeight="auto"
        hideCloseButton
      >
        <ConfirmModal
          message={
            <>
              Are you sure you want to delete item{' '}
              <strong>{editItemData?.name}</strong>?
            </>
          }
          closeConfirm={(confirm) => {
            if (confirm) deleteItem()
            setOpenDeleteConfirmModal(false)
          }}
        />
      </ModalContent>
    </Modal>
  )

  const renderNewModifierOption = () =>
    (
      <Button
        color="white"
        style={{ fontSize: '0.875rem' }}
        onClick={() => setModifierModalIsOpen(true)}
      >
        Add Modifier <AddIcon size={20} />
      </Button>
    ) as unknown as string

  useEffect(() => {
    const categories = categoriesSelected.filter((c) => c.selected)
    setMenusSelected((old) => selectMenuByCategory(menus, old, categories))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesSelected])

  useEffect(() => {
    setMenusSelected((old) => selectMenuSelectedByCategory(old, editItemData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ModalOverflow>
        <S.WrapperContent>
          <Box flex style={{ marginBottom: '1rem' }}>
            <Box flex flexDirection="column" alignItems="center">
              <S.ImageWrapper picture={pictureUpload.imagePreview}>
                <S.InputFile
                  type="file"
                  ref={fileInputRef}
                  onChange={handleUploadChange}
                  accept="image/png, image/jpeg, image/heic"
                  disabled={isLoading}
                />
                <S.UploadButton onClick={handleUploadButton}>
                  {pictureUpload.imagePreview ? (
                    <FlipCameraIcon size="30" />
                  ) : (
                    <PhotoCameraIcon size="30" />
                  )}
                </S.UploadButton>
              </S.ImageWrapper>

              {pictureUpload.imagePreview && (
                <S.ActionContainer>
                  <S.Action
                    onClick={() =>
                      setPictureUpload({ file: null, imagePreview: '' })
                    }
                    style={{ marginTop: '0.5rem' }}
                  >
                    <S.ButtonIcon>
                      <CloseIcon size={14} />
                    </S.ButtonIcon>
                    Remove
                  </S.Action>
                  <S.Action
                    onClick={handleEditPhoto}
                    style={{ marginTop: '0.5rem' }}
                  >
                    <S.ButtonIcon>
                      <EditIcon size={14} />
                    </S.ButtonIcon>
                    Edit
                  </S.Action>
                </S.ActionContainer>
              )}
            </Box>

            <S.ContentInputs>
              <S.FormControl>
                <TextField
                  value={name}
                  label="Item’s Name"
                  labelFor="name"
                  id="name"
                  onChange={(event) => setName(event.currentTarget.value)}
                  error={formErrors.name}
                  disabled={isLoading}
                  variant="material"
                />
              </S.FormControl>

              <S.FormControl>
                <TextArea
                  value={description}
                  label="Description (optional)"
                  labelFor="description"
                  id="description"
                  onChange={(event) =>
                    setDescription(event.currentTarget.value)
                  }
                  disabled={isLoading}
                  variant="material"
                  rows={3}
                />
              </S.FormControl>

              {isSellable && (
                <S.FormControl>
                  <TextField
                    value={price}
                    label="Price (KYD)"
                    labelFor="price"
                    id="price"
                    onChange={(event) => setPrice(event.currentTarget.value)}
                    disabled={isLoading}
                    variant="material"
                    mask="money"
                  />
                </S.FormControl>
              )}

              <Box flex>
                <S.FormControl>
                  <S.Label htmlFor="availability">Change availability</S.Label>
                  <Switch
                    checked={available}
                    id="availability"
                    onChange={setAvailable}
                    disabled={isLoading}
                  />
                </S.FormControl>

                <S.FormControl>
                  <S.Label>Sell this item on its own?</S.Label>
                  <S.RadioInline>
                    <Radio
                      defaultChecked={isSellable}
                      label="Yes"
                      labelFor="yes"
                      id="yes"
                      value="yes"
                      name="is_sellable"
                      onCheck={() => setIsSellable(true)}
                      disabled={isLoading}
                    />
                    <Radio
                      defaultChecked={!isSellable}
                      label="No"
                      labelFor="no"
                      id="no"
                      value="no"
                      name="is_sellable"
                      onCheck={() => setIsSellable(false)}
                      disabled={isLoading}
                    />
                  </S.RadioInline>
                </S.FormControl>
              </Box>
            </S.ContentInputs>
          </Box>

          {isSellable && (
            <>
              <S.Content>
                <S.MenuForm>
                  <Box flex flexDirection="column">
                    <S.LabelForm style={{ marginBottom: '1rem' }}>
                      Categories{' '}
                      <InfoIcon
                        size={18}
                        data-tip="Categories where this item will be listed"
                        data-for="categories_info"
                      />
                      <ReactTooltip
                        effect="solid"
                        className="tooltip"
                        backgroundColor="#433d3d"
                        borderColor="#433d3d"
                        id="categories_info"
                      />
                    </S.LabelForm>
                    <S.Categories>
                      {categoriesSelected.map((category, index) => (
                        <SelectButton
                          key={category.value}
                          selected={category.selected}
                          onClick={() => handleSelectCategory(category, index)}
                        >
                          {category.name}
                        </SelectButton>
                      ))}
                    </S.Categories>
                    {formErrors.categories && (
                      <S.TextError>{formErrors.categories}</S.TextError>
                    )}
                  </Box>
                </S.MenuForm>
              </S.Content>

              {menusSelected.length > 1 && (
                <S.Content>
                  <S.MenuForm>
                    <Box flex flexDirection="column" width="100%">
                      <S.LabelForm>
                        Customize price for each menu{' '}
                        <InfoIcon
                          size={18}
                          data-tip="You can have multiple prices for each menu where this item will be listed. If you don't customize a price per menu, the default item price will be considered"
                          data-for="price_menu_info"
                        />
                        <ReactTooltip
                          effect="solid"
                          className="tooltip"
                          backgroundColor="#433d3d"
                          borderColor="#433d3d"
                          id="price_menu_info"
                        />
                      </S.LabelForm>

                      <List<MenuSelectedType> data={menusSelected}>
                        {(menu, index) => (
                          <>
                            <Checkbox
                              checked={menu.selected}
                              label={menu.name}
                              labelFor={`check_${index}`}
                              id={`check_${index}`}
                              onCheck={() => handleSelectMenu(menu, index)}
                            />

                            <S.PriceWrapper>
                              <TextField
                                value={
                                  menu.price
                                    ? Number(menu.price).toFixed(2)
                                    : menu.price
                                }
                                id={`price_${index}`}
                                onChange={(event) =>
                                  handleSetMenuPrice(
                                    event.currentTarget.value,
                                    menu,
                                    index,
                                  )
                                }
                                disabled={isLoading || !menu.selected}
                                variant="material"
                                placeholder="Price"
                                icon={
                                  <img
                                    src={othersPriceIcon}
                                    width={20}
                                    alt="Others price icon"
                                  />
                                }
                                mask="money"
                              />
                            </S.PriceWrapper>
                          </>
                        )}
                      </List>
                    </Box>
                  </S.MenuForm>
                </S.Content>
              )}

              <S.SelectModifiers>
                <Select<ModifierType>
                  options={modifiersOptions.map((item) => ({
                    ...item,
                    options: undefined,
                    itemsAssigned: undefined,
                  }))}
                  formatOptionLabel={(option) => (
                    <S.SelectOption>
                      <h5>{option.name}</h5>
                      <p>{option.note}</p>
                    </S.SelectOption>
                  )}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.modifierId}
                  placeholder="Search existing modifier"
                  variant="material"
                  onChange={handleModifierSelectChange}
                  value={selected}
                  noOptionsMessage={renderNewModifierOption}
                  isSearchable
                  isClearable
                />

                {formErrors.hasModifier && (
                  <S.TextError style={{ marginLeft: '1.5rem' }}>
                    {formErrors.hasModifier}
                  </S.TextError>
                )}
              </S.SelectModifiers>

              <S.Content>
                <S.MenuForm>
                  <Box flex flexDirection="column" width="100%">
                    <S.LabelForm>
                      List of modifiers{' '}
                      <InfoIcon
                        size={18}
                        data-tip="Modifiers can be add-ons, side options, sizes, meat temperatures, egg style and etc"
                        data-for="modifiers_info"
                      />
                      <ReactTooltip
                        effect="solid"
                        className="tooltip"
                        backgroundColor="#433d3d"
                        borderColor="#433d3d"
                        id="modifiers_info"
                      />
                    </S.LabelForm>

                    {modifiersSelected.length === 0 ? (
                      <Box
                        flex
                        JustifyContent="center"
                        alignItems="center"
                        width="100%"
                        height="6.25rem"
                      >
                        <Empty title="There is nothing here, yet" />
                      </Box>
                    ) : (
                      <List<ModifierType>
                        data={modifiersSelected}
                        onReorder={handleReorder}
                        dragAndDrop
                      >
                        {(modifier, index) => (
                          <>
                            <Box flex flexDirection="column" width="70%">
                              <S.Name>{modifier.name}</S.Name>
                              <S.Note>{modifier.note}</S.Note>
                            </Box>

                            <S.Action
                              onClick={() => handleRemoveModifier(index)}
                            >
                              <S.ButtonIcon>
                                <DeleteIcon size={14} />
                              </S.ButtonIcon>
                              Delete
                            </S.Action>
                          </>
                        )}
                      </List>
                    )}
                  </Box>
                </S.MenuForm>
              </S.Content>
            </>
          )}
        </S.WrapperContent>
      </ModalOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
        >
          {editItemData && (
            <Button
              color="white"
              size="large"
              disabled={isDeleting}
              loading={isDeleting}
              onClick={() => setOpenDeleteConfirmModal(true)}
              shadow
            >
              Delete
            </Button>
          )}

          <Button
            size="large"
            disabled={isLoading}
            onClick={() => {
              if (isSellable && price === '0.00') {
                setOpenPriceConfirmModal(true)
                return
              }
              saveItem()
            }}
            loading={isLoading}
            style={{ marginLeft: 'auto' }}
          >
            Save
          </Button>
        </Box>
      </ModalFooter>

      {openCropModal && renderModalCrop()}
      {modifierModalIsOpen && renderOpenModifierModal()}
      {openDeleteConfirmModal && renderDeleteConfirmModal()}
      {openPriceConfirmModal && renderPriceConfirmModal()}
    </>
  )
}

export default EditItemModal

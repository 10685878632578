import { darken } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

type WrapperProps = {
  isDraggingOver?: boolean
}

export const Wrapper = styled.ul<WrapperProps>`
  ${({ theme, isDraggingOver = false }) => css`
    background: ${isDraggingOver ? theme.colors.mainBg : 'transparent'};
    padding-bottom: ${isDraggingOver ? '4.375rem' : 0};
    border-radius: ${theme.border.radius.xsmall};
    border: ${isDraggingOver ? `1px dashed ${theme.colors.lightGray}` : 0};
  `}
`

type ListItemProps = {
  isDragging?: boolean
  hoverable?: boolean
}

export const ListItem = styled.li<ListItemProps>`
  ${({ theme, isDragging = false, hoverable = false }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    background: ${isDragging ? theme.colors.white : 'transparent'};
    border-bottom: ${isDragging
      ? 'none'
      : `1px solid ${
          theme.dark ? darken(0.07, theme.colors.borderColor) : '#dcdcdc'
        }`};
    padding: ${theme.spacings.xsmall} 0;
    border-radius: ${isDragging ? theme.border.radius.xsmall : 0};
    opacity: ${isDragging ? '0.8' : 1};

    &:last-child {
      border-bottom: ${isDragging
        ? `1px solid ${
            theme.dark ? darken(0.07, theme.colors.borderColor) : '#dcdcdc'
          }`
        : 'none'};
    }

    ${hoverable &&
    css`
      padding: ${theme.spacings.xsmall};
      border-radius: ${theme.border.radius.xsmall};
      cursor: pointer;

      &:hover {
        background: ${theme.colors.mainBg};
      }
    `}
  `}
`

export const DragIcon = styled.span`
  color: #dcdcdc;
  min-width: 4.375rem;
  display: flex;
  justify-content: center;

  ${media.lessThan('medium')`
    min-width: 3.125rem;
  `}
`

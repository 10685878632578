import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from 'firebase/messaging'
import { useCallback, useEffect } from 'react'

import configsToStorageService from '@/api/configs-to-storage.service'
import dashboardService from '@/api/dashboard.service'
import Toast from '@/components/Toast'
import { env } from '@/env'
import useAudio from '@/hooks/useAudio'
import useAuth from '@/hooks/useAuth'

import packageJson from '../../../package.json'

const MessagingContainer = () => {
  const { merchantsSelected } = useAuth()
  const { playOnce, playing, canPlay } = useAudio()

  const messaging = getMessaging()

  const getTokenAsync = useCallback(async () => {
    const isMessageSupported = await isSupported()

    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.')
      }
    })

    if (messaging && isMessageSupported)
      getToken(messaging, { vapidKey: env.VITE_VAPID_KEY })
        .then(async (currentToken) => {
          console.log('current token', currentToken)
          if (currentToken) {
            if (merchantsSelected.length > 0) {
              console.log('merchant selected', currentToken)
              configsToStorageService.saveMessagingToken(currentToken)
              let battery: number | undefined
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const newNavigator: any = navigator
              const version = packageJson.version

              if ('getBattery' in newNavigator) {
                const batteryPromise = await newNavigator.getBattery()
                battery = Number((batteryPromise.level * 100).toFixed(0))
              }

              if (battery) {
                try {
                  const merchants = merchantsSelected.map((item) => item.id)
                  await dashboardService.multipleMerchantsSaveTabletStatus(
                    merchants,
                    battery,
                    version,
                    currentToken,
                  )
                } catch (error) {}
              }
            }
          } else {
            console.log(
              'No registration token available. Request permission to generate one.',
            )
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err)
          // ...
        })

    if (messaging)
      onMessage(messaging, (payload) => {
        console.log('Message received. ', payload)

        canPlay && !playing && playOnce()

        Toast({
          type: 'info',
          title: payload?.notification?.title || '',
          message: payload?.notification?.body || '',
          autoClose: 60000,
        })
      })
  }, [merchantsSelected, playOnce, playing, canPlay])

  useEffect(() => {
    getTokenAsync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}

export default MessagingContainer

/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { GoogleMap, Libraries, useJsApiLoader } from '@react-google-maps/api'
import { useCallback, useEffect, useState } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'

import othersSearchIcon from '@/assets/img/icons-others-search.svg'
import pinBag from '@/assets/img/pin-bag.svg'
import pinMap from '@/assets/img/pin-map.svg'
import Radio from '@/components/Radio'
import Switch from '@/components/Switch'
import TextField from '@/components/TextField'
import { env } from '@/env'
import { OrderAddressType } from '@/types/orders.types'
import { AddressType, LatLngType } from '@/types/settings.types'

import AlertAddress from '../AlertAddress'
import * as S from './styles'

const stepMap = {
  // SEARCH: 1,
  MAP: 1,
  FORM: 2,
}

const options: google.maps.MapOptions = {
  disableDefaultUI: true,
  mapTypeId: 'hybrid',
}

const DropdownIndicator = (props: any) => {
  return (
    <div {...props}>
      <img src={othersSearchIcon} alt="Others search icon" />
    </div>
  )
}

enum TypeAddressEnum {
  APARTMENT = 'apartment',
  HOUSE = 'house',
}

export type SaveAddressType = {
  newAddress: OrderAddressType
  oldAddress?: OrderAddressType
}

type FormAddressProps = {
  oldAddress?: OrderAddressType | null
  onSaveAddress: (addresses: SaveAddressType) => void
}

const libraries: Libraries = ['places', 'visualization']

export const FormAddress = ({
  oldAddress = null,
  onSaveAddress,
}: FormAddressProps) => {
  const [streetNumber, setStreetNumber] = useState('')
  const [extraInfo, setExtraInfo] = useState('')
  const [instructions, setInstructions] = useState('')
  const [typeAddress, setTypeAddress] = useState('')
  const [step, setStep] = useState(stepMap.MAP)
  const [isLoading, setIsLoading] = useState(false)
  const [inQuarantine, setInQuarantine] = useState(false)
  const [isDefault, setIsDefault] = useState(oldAddress?.isDefault)
  const [address, setAddress] = useState<OrderAddressType | null>(null)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [mapCenter, setMapCenter] = useState<LatLngType>({
    lat: 19.287408635276158,
    lng: -81.36642566177018,
  })
  const [filterText, setFilterText] = useState('')

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: env.VITE_MAPS_API_KEY,
    libraries,
  })

  const clearForm = useCallback(() => {
    setStreetNumber('')
    setTypeAddress('')
    setExtraInfo('')
    setInstructions('')
    setInQuarantine(false)
  }, [])

  const handleSave = useCallback(async () => {
    setIsLoading(true)
    const quarantineText = ' • 😷 IN QUARANTINE 😷'
    const newAddress = {
      ...address,
      extraInfo: `${
        typeAddress === TypeAddressEnum.APARTMENT ? 'Apt • ' : 'House • '
      }${extraInfo}`,
      instructions: `${instructions}${inQuarantine ? quarantineText : ''}`,
      streetNumber,
      isDefault: true,
    } as AddressType

    const dataSave: SaveAddressType = {
      newAddress,
    }
    if (oldAddress) {
      dataSave.oldAddress = oldAddress
    }

    try {
      clearForm()
      onSaveAddress(dataSave)
    } finally {
      setIsLoading(false)
    }
  }, [
    address,
    typeAddress,
    extraInfo,
    instructions,
    inQuarantine,
    streetNumber,
    oldAddress,
    clearForm,
    onSaveAddress,
  ])

  const handlePlaceChange = useCallback(
    async (e: any) => {
      setFilterText(e)
      const { value } = e

      const request = {
        placeId: value.place_id,
        fields: ['name', 'address_component', 'formatted_address', 'geometry'],
      }

      const service = new google.maps.places.PlacesService(map!)
      service.getDetails(request, callback)

      function callback(
        place: google.maps.places.PlaceResult | null,
        status: google.maps.places.PlacesServiceStatus,
      ) {
        // eslint-disable-next-line eqeqeq
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          const { address_components, geometry } = place!

          const newAddress: AddressType = {} as AddressType

          if (address_components && address_components?.length > 0) {
            address_components.forEach((item) => {
              const { long_name, short_name, types } = item
              newAddress.streetName = types.includes('route')
                ? long_name
                : newAddress.streetName
              newAddress.streetNumber = types.includes('street_number')
                ? short_name
                : newAddress.streetNumber
              newAddress.city = types.includes('locality')
                ? long_name
                : newAddress.city
              newAddress.country = types.includes('country')
                ? long_name
                : newAddress.country
            })
          }

          const coordinates = {
            lat: geometry?.location?.lat() as number,
            lng: geometry?.location?.lng() as number,
          }

          setAddress({
            ...newAddress,
            description: place?.name || '',
            coordinates,
          })
          setStreetNumber(newAddress.streetNumber)

          setMapCenter(coordinates)
          setFilterText('')
          // setStep(stepMap.MAP)
        }
      }
    },
    [map],
  )

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map)
  }, [])

  const handleDragMap = useCallback(() => {
    const center = map?.getCenter()
    if (center) {
      setMapCenter({ lat: center.lat(), lng: center.lng() })
    }
  }, [map])

  const handleConfirmMap = useCallback(async () => {
    setAddress(
      (old) => ({ ...old, coordinatesAdjustment: mapCenter }) as AddressType,
    )
    setStep(stepMap.FORM)
  }, [mapCenter])

  useEffect(() => {
    clearForm()

    if (oldAddress) {
      setAddress(oldAddress)
      setMapCenter(
        oldAddress.coordinatesAdjustment.lat
          ? oldAddress.coordinatesAdjustment
          : oldAddress.coordinates,
      )
      setStreetNumber(oldAddress.streetNumber)
      setTypeAddress(
        oldAddress.extraInfo.indexOf('Apt • ') > -1
          ? TypeAddressEnum.APARTMENT
          : TypeAddressEnum.HOUSE,
      )
      setExtraInfo(
        oldAddress.extraInfo.replace('Apt • ', '').replace('House • ', ''),
      )
      setInQuarantine(
        oldAddress.instructions.indexOf(' • 😷 IN QUARANTINE 😷') > -1,
      )
      setInstructions(
        oldAddress.instructions.replace(' • 😷 IN QUARANTINE 😷', ''),
      )
    }
  }, [clearForm, oldAddress])

  const isAllowToSubmit = () => {
    return !(
      !isLoading &&
      typeAddress.length > 0 &&
      Boolean(streetNumber) &&
      Boolean(extraInfo) &&
      Boolean(instructions)
    )
  }

  return (
    <div style={{ width: '100%', height: '35rem', position: 'relative' }}>
      {isLoaded && (
        <GoogleMap
          options={options}
          mapContainerStyle={{
            width: '100%',
            height: '100%',
            display: step === stepMap.MAP ? 'block' : 'none',
          }}
          zoom={18}
          center={mapCenter}
          clickableIcons={false}
          onLoad={onLoad}
          onDragEnd={handleDragMap}
        >
          <S.FormRow
            style={{
              position: 'absolute',
              top: '0.5rem',
              padding: '0 1rem',
              width: '100%',
            }}
          >
            <GooglePlacesAutocomplete
              autocompletionRequest={{
                componentRestrictions: { country: ['ky'] },
              }}
              debounce={500}
              selectProps={{
                value: filterText as any,
                onChange: handlePlaceChange,
                placeholder: 'Search address',
                noOptionsMessage: () =>
                  (
                    <S.TextHelp>
                      <h4>Can’t find your address?</h4>
                      <p>
                        Try searching by a closer location and adjust the
                        coordinates on next step
                      </p>
                    </S.TextHelp>
                  ) as any,
                loadingMessage: () => 'Searching...',
                components: {
                  DropdownIndicator,
                  IndicatorSeparator: null,
                },
                openMenuOnFocus: true,
                theme: (theme: any) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#c5c0c0',
                  },
                }),
                styles: {
                  input: (provided: any) => ({
                    ...provided,
                    color: '#8e8b8b',
                    fontSize: '0.75rem',
                  }),
                  control: (provided: any) => ({
                    ...provided,
                    borderRadius: '13px',
                    border: '1px solid #c5c0c0',
                    paddingRight: '0.5rem',
                  }),
                  placeholder: (provided: any) => ({
                    ...provided,
                    color: '#8e8b8b',
                    fontSize: '0.75rem',
                  }),
                  singleValue: (provided: any) => ({
                    ...provided,
                    color: '#8e8b8b',
                    fontSize: '0.75rem',
                  }),
                },
              }}
            />
          </S.FormRow>

          <S.AlertWrapper>
            <AlertAddress
              title="Roof adjustment"
              description="Please adjust your location. You probably know how your roof looks"
              style={{ width: '100%' }}
            />
          </S.AlertWrapper>

          <S.Marker src={pinMap} alt="Pin map" />

          <S.ButtonWrapper
            style={{
              width: '100%',
              position: 'absolute',
              bottom: '1.5rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <S.ButtonConfirmMap onClick={handleConfirmMap}>
              Confirm
            </S.ButtonConfirmMap>
          </S.ButtonWrapper>
        </GoogleMap>
      )}

      {step !== stepMap.MAP && step === stepMap.FORM && (
        <>
          <S.Address>
            <S.AddressImage>
              <img src={pinBag} alt="Pin bag" />{' '}
            </S.AddressImage>

            <S.AddressDescription>
              <h3>{address?.description}</h3>
              <p>
                {address?.city ? `${address?.city}, ` : ''}
                {address?.country}
              </p>
            </S.AddressDescription>
          </S.Address>

          <S.FormAddress>
            <S.FormRow>
              <S.RadioInline>
                <Radio
                  label="Apartment"
                  labelFor="apartment"
                  id="apartment"
                  name="type_address"
                  value={TypeAddressEnum.APARTMENT}
                  onCheck={() => setTypeAddress(TypeAddressEnum.APARTMENT)}
                  defaultChecked={typeAddress === TypeAddressEnum.APARTMENT}
                  disabled={isLoading}
                />
                <Radio
                  label="House"
                  labelFor="house"
                  id="house"
                  name="type_address"
                  value={TypeAddressEnum.HOUSE}
                  onCheck={() => setTypeAddress(TypeAddressEnum.HOUSE)}
                  defaultChecked={typeAddress === TypeAddressEnum.HOUSE}
                  disabled={isLoading}
                />
              </S.RadioInline>
            </S.FormRow>
            <S.FormRow>
              <TextField
                isRequired
                variant="material"
                type="text"
                name="streetNumber"
                label="Street Number"
                labelFor="streetNumber"
                id="streetNumber"
                value={streetNumber}
                onChange={(e) => setStreetNumber(e.target.value)}
              />
            </S.FormRow>
            <S.FormRow>
              <TextField
                isRequired
                variant="material"
                type="text"
                name="extraInfo"
                label="Apt Number/Unit"
                labelFor="extraInfo"
                id="extraInfo"
                value={extraInfo}
                onChange={(e) => setExtraInfo(e.target.value)}
              />
            </S.FormRow>
            <S.FormRow>
              <TextField
                isRequired
                variant="material"
                type="text"
                name="instructions"
                label="Instructions"
                labelFor="instructions"
                id="instructions"
                placeholder="complex name, office, building color, etc"
                value={instructions}
                onChange={(e) => setInstructions(e.target.value)}
              />
            </S.FormRow>
            <S.FormRow>
              <S.Quarantine>
                <S.QuarantineDescription>
                  <h3>Are you in quarantine?</h3>
                  <p>We will provide contactless delivery if so</p>
                </S.QuarantineDescription>

                <Switch
                  checked={inQuarantine}
                  id="inQuarantine"
                  onChange={setInQuarantine}
                  disabled={isLoading}
                />
              </S.Quarantine>
            </S.FormRow>
            <S.FormRow>
              <S.Quarantine>
                <S.QuarantineDescription>
                  <h3>Set as default</h3>
                </S.QuarantineDescription>

                <Switch
                  checked={isDefault || false}
                  id="isDefault"
                  onChange={setIsDefault}
                  disabled={isLoading}
                />
              </S.Quarantine>
            </S.FormRow>
          </S.FormAddress>
        </>
      )}

      {step === stepMap.FORM && (
        <S.ButtonSave
          disabled={isAllowToSubmit()}
          onClick={handleSave}
          loading={isLoading}
        >
          Save
        </S.ButtonSave>
      )}
    </div>
  )
}

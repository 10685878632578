import styled, { css } from 'styled-components'

import { InputWrapper } from '@/components/TextField/styles'

export const Wrapper = styled.div`
  min-width: 3.75rem;
  padding: 1.5rem;

  display: flex;
  flex-direction: column;
  height: 100%;

  .tooltip {
    border-radius: 8px;
    font-family: 'Quicksand';
    font-size: 0.75rem;
  }
`

export const Flex1 = styled.div`
  flex: 1;
`

export const Section = styled.div`
  margin-top: 1.75rem;
  padding: 1.5rem;
  border-radius: 25px;
  background-color: #ffffff;
`

export const Search = styled.div`
  margin-bottom: 0.5rem;

  ${InputWrapper} {
    border: 2px solid #c5c0c0;
  }
`

export const List = styled.ul`
  overflow-y: auto;
  list-style: none;
  height: 100vh;
  max-height: 58vh;
`

export const ListItem = styled.li`
  display: flex;
  padding: 0.5rem;
  gap: 1rem;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`

export const Emoji = styled.div`
  font-size: 2.5rem;
  line-height: 2rem;
`

export const Box = styled.div``

export const Description = styled.h6`
  ${({ theme }) => css`
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: ${theme.colors.secondary};
  `}
`

export const DialCode = styled.p`
  ${({ theme }) => css`
    font-family: 'Quicksand';
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.75rem;
    color: ${theme.colors.gray};
  `}
`

export const NoResultsBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  max-height: 58vh;
`

export const LoadingBox = styled(NoResultsBox)`
  align-items: initial;
`

import styled, { css, keyframes } from 'styled-components'
import media from 'styled-media-query'

import { Box } from '@/components/Box'

const pulseSC = keyframes`
  0% {
		transform: scale(1);
	}

	30% {
		transform: scale(1.05);
	}

	50% {
		transform: scale(1);
	}

	70% {
		transform: scale(1.05);
	}

	100% {
		transform: scale(1);
	}
`

export const WrapperContent = styled.div`
  ${({ theme }) => css`
    .tooltip {
      border-radius: 8px;
      font-family: ${theme.font.quicksand} !important;
      font-size: ${theme.font.sizes.xsmall} !important;
    }

    ${media.lessThan('medium')`
      > ${Box}:first-child {
        flex-direction: column;
      }
    `}
  `}
`

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacings.large};
    gap: ${theme.spacings.xsmall};

    ${media.lessThan('medium')`
      flex-direction: column;
      gap: ${theme.spacings.medium};
    `}
  `}
`

export const Info = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h4,
    p {
      font-size: 1.125rem;
      font-weight: ${theme.font.semiBold};
      color: ${theme.colors.supportDarker};
      max-width: 320px;
      margin-right: 8px;
    }

    p {
      font-size: 1rem;
      color: ${theme.colors.supportGray};
      line-height: 1.25rem;
    }
  `}
`

export const ImageWrapper = styled.div`
  ${({ theme }) => css`
    width: 20.938rem;
    height: 16.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${theme.border.radius.medium};
  `}
`

export const FieldWrapper = styled.div<{ before?: number }>`
  ${({ theme, before = 0 }) => css`
    background-color: ${theme.colors.white};
    padding: ${theme.spacings.xsmall} ${theme.spacings.small};
    border-radius: ${theme.border.radius.medium};
    margin-bottom: ${theme.spacings.xsmall};

    ${before === 1 &&
    css`
      animation: ${pulseSC} 1.5s ease-in-out forwards;
    `}
  `}
`

export const FieldHeader = styled.div<{ checkout?: number; items?: number }>`
  ${({ checkout = 0, items = 0 }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;

    ${checkout === 0 &&
    media.lessThan('medium')`
      flex-direction: column;
      align-items: flex-start;
    `}

    ${items === 1 &&
    `
      flex-direction: column;
      align-items: flex-start;
    `}
  `}
`

export const FieldHeaderTitle = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.font.semiBold};
    font-size: 1.125rem;

    span {
      color: ${theme.colors.gray};
      font-size: ${theme.font.sizes.medium};
      font-weight: ${theme.font.medium};
    }
  `}
`

export const FieldHeaderDescription = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.quicksand};
    font-weight: ${theme.font.semiBold};
    font-size: 0.875rem;
    color: ${theme.colors.gray};
  `}
`

export const FieldHeaderActions = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.gray};
    font-weight: ${theme.font.medium};
    font-size: 1.125rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    span {
      color: ${theme.colors.primary};
      font-weight: ${theme.font.bold};
    }
  `}
`

export const FieldContent = styled.div`
  margin-top: 1.125rem;
`

export const FieldError = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.red};
    font-size: ${theme.font.sizes.xsmall};
    margin-top: ${theme.spacings.xxsmall};
  `}
`

export const Amount = styled.span`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    width: auto;
    height: 2rem;
    font-size: ${theme.font.sizes.small};
    padding: 0 0.5rem;
    font-weight: ${theme.font.bold};
    background-color: ${theme.colors.gray};
    color: ${theme.dark ? theme.colors.white : theme.colors.primaryText};
    border-radius: 16px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  `}
`

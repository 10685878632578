import { useState } from 'react'

import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { ModalFooter, ModalOverflow } from '@/components/Modal'
import { PaymentForm } from '@/components/PaymentForm'
import { PaymentList } from '@/components/PaymentList'
import { CardType } from '@/types/payment.types'

import * as S from './styles'

export type PaymentModalProps = {
  closePayment: (card: CardType | null) => void
}

const PaymentModal = ({ closePayment }: PaymentModalProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isAddNewCard, setIsAddNewCard] = useState(false)

  return (
    <>
      <ModalOverflow>
        <S.Wrapper>
          {isAddNewCard ? (
            <PaymentForm
              onLoading={setIsLoading}
              onSavePayment={() => setIsAddNewCard(false)}
            />
          ) : (
            <PaymentList
              onLoading={setIsLoading}
              onSetPayment={closePayment}
              hoverable
            />
          )}
        </S.Wrapper>
      </ModalOverflow>

      <ModalFooter simple={1}>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
          style={{ marginTop: '1rem' }}
        >
          <Button
            color="white"
            size="large"
            onClick={() =>
              isAddNewCard ? setIsAddNewCard(false) : closePayment(null)
            }
            disabled={isLoading}
            shadow
          >
            {isAddNewCard ? 'Back to list' : 'Cancel'}
          </Button>
          <span />
          {!isAddNewCard && (
            <Button
              size="large"
              onClick={() => setIsAddNewCard(true)}
              disabled={isLoading}
            >
              Add new card
            </Button>
          )}
        </Box>
      </ModalFooter>
    </>
  )
}

export default PaymentModal

import { ModeEdit as EditIcon } from '@styled-icons/material/ModeEdit'
import { Check } from '@styled-icons/material-outlined'
import { useState } from 'react'

import performanceTakeout from '@/assets/img/icons-performance-takeout.svg'
import { Box } from '@/components/Box'
import { Modal, ModalContent } from '@/components/Modal'
import TextField from '@/components/TextField'
import useModal from '@/hooks/useModal'
import { UserInfoType } from '@/types/orders.types'

import PhoneValidationModal from './components/PhoneValidationModal'
import * as S from './styles'

type UserInfoProps = {
  user: UserInfoType
  loading?: boolean
  onSaveUser: (name: string, phoneNumber: string) => void
}

const UserInfo = ({ user, onSaveUser, loading }: UserInfoProps) => {
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber)
  const [name, setName] = useState(user.name)
  const [isEditingName, setIsEditingName] = useState(false)
  const { open: openValidationModal, toggle: toggleValidationModal } =
    useModal()

  const renderPhoneValidationModal = () => {
    return (
      <Modal open={openValidationModal} onOpenChange={toggleValidationModal}>
        <ModalContent
          title=""
          noHeader
          width="auto"
          xsWidth="90vw"
          xsHeight="auto"
        >
          <PhoneValidationModal
            phone={user?.phoneNumber}
            onClose={(phoneNumber) => {
              if (phoneNumber) {
                setPhoneNumber(phoneNumber)
                onSaveUser(name, phoneNumber)
              }
              toggleValidationModal(false)
            }}
          />
        </ModalContent>
      </Modal>
    )
  }

  return (
    <S.UserWrapper>
      <S.Avatar image={performanceTakeout} />

      <S.UserInfo>
        {isEditingName ? (
          <Box flex>
            <TextField
              variant="material"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus
            />
            <S.EditPhoneButton
              onClick={() => {
                onSaveUser(name, phoneNumber)
                setIsEditingName(false)
              }}
              size="small"
              icon={<Check />}
              disabled={loading}
            />
          </Box>
        ) : (
          <S.Name>
            {name}{' '}
            <S.EditPhoneButton
              onClick={() => setIsEditingName(true)}
              size="small"
              icon={<EditIcon />}
              disabled={loading}
            />
          </S.Name>
        )}
        <S.Typography>{user.email}</S.Typography>

        <S.PhoneTypography>
          {phoneNumber}{' '}
          <S.EditPhoneButton
            onClick={() => toggleValidationModal(true)}
            size="small"
            icon={<EditIcon />}
            disabled={loading}
          />
        </S.PhoneTypography>
      </S.UserInfo>

      {renderPhoneValidationModal()}
    </S.UserWrapper>
  )
}

export default UserInfo

import styled, { css, DefaultTheme } from 'styled-components'
import media from 'styled-media-query'

import Button, { ButtonProps } from '@/components/Button'

type WrapperProps = { rounded: boolean } & Pick<ButtonProps, 'size'>

const wrapperModifiers = {
  small: (theme: DefaultTheme) => css`
    height: 2.5rem;
    line-height: 0.5;
    font-size: ${theme.font.sizes.xsmall};
    padding: 0 0.5rem;
  `,
  medium: (theme: DefaultTheme) => css`
    height: 3rem;
    line-height: 0.8;
    font-size: ${theme.font.sizes.small};
    padding: 0 ${theme.spacings.xxsmall};
  `,
  large: (theme: DefaultTheme) => css`
    height: 3.5rem;
    font-size: ${theme.font.sizes.medium};
    padding: 0 ${theme.spacings.xsmall};
  `,
}

export const Wrapper = styled(Button)<WrapperProps>`
  ${({ theme, size = 'medium', rounded }) => css`
    ${rounded &&
    css`
      border-radius: 99999px;
    `}
    position: fixed;
    bottom: ${theme.spacings.small};
    right: ${theme.spacings.small};
    box-shadow:
      rgb(0 0 0 / 20%) 0px 3px 5px -1px,
      rgb(0 0 0 / 14%) 0px 6px 10px 0px,
      rgb(0 0 0 / 12%) 0px 1px 18px 0px;

    svg {
      max-height: 1.5rem;
    }

    ${!!size && wrapperModifiers[size](theme)};

    ${media.lessThan('medium')`
      bottom: 5.5rem;
    `}
  `}
`

import { Menu as DragIcon } from '@styled-icons/material-outlined'
import { useEffect, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from 'react-beautiful-dnd'

import { reorder } from '@/utils/formats/list'

import * as S from './styles'

const renderItem = <T,>(
  item: T,
  index: number,
  dragProvided: DraggableProvided,
  dragSnapshot: DraggableStateSnapshot,
  children: (item: T, index: number) => React.ReactNode,
  style: React.CSSProperties | undefined,
  hoverable: boolean,
  onItemClick?: (item: T) => void,
) => (
  <S.ListItem
    ref={dragProvided.innerRef}
    isDragging={dragSnapshot.isDragging}
    onClick={() => onItemClick && onItemClick(item)}
    hoverable={hoverable}
    style={{ ...style, ...dragProvided.draggableProps.style }}
    {...dragProvided.draggableProps}
    {...dragProvided.dragHandleProps}
  >
    <S.DragIcon>
      <DragIcon size={24} />
    </S.DragIcon>

    {children(item, index)}
  </S.ListItem>
)

export type ListProps<T> = {
  data: T[]
  dragAndDrop?: boolean
  hoverable?: boolean
  id?: string
  sorterBy?: keyof T
  children: (item: T, index: number) => React.ReactNode
  onReorder?: (items: T[]) => void
  onItemClick?: (item: T) => void
  style?: React.CSSProperties
}

const List = <T,>({
  data,
  dragAndDrop = false,
  hoverable = false,
  id = 'droppable-id',
  sorterBy,
  children,
  onReorder,
  onItemClick,
  style,
}: ListProps<T>) => {
  const [items, setItems] = useState<T[]>([])

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const itemsReordered = reorder<T>(
      items,
      result.source.index,
      result.destination.index,
    ).map((item, index) => {
      if (sorterBy) {
        return {
          ...item,
          [sorterBy]: index,
        }
      }

      return item
    })

    setItems(itemsReordered)
    onReorder && onReorder(itemsReordered)
  }

  useEffect(() => {
    setItems(data)
  }, [data])

  return dragAndDrop ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={id}
        type={id}
        key={id}
        renderClone={(provided, snapshot, rubric) =>
          renderItem(
            items[rubric.source.index],
            rubric.source.index,
            provided,
            snapshot,
            children,
            style,
            hoverable,
            onItemClick,
          )
        }
      >
        {(
          dropProvided: DroppableProvided,
          dropSnapshot: DroppableStateSnapshot,
        ) => (
          <S.Wrapper
            ref={dropProvided.innerRef}
            isDraggingOver={dropSnapshot.isDraggingOver}
            {...dropProvided.droppableProps}
          >
            {items.map((item, index) => (
              <Draggable
                key={`item-${String(index)}`}
                draggableId={`item-${String(index)}`}
                index={index}
              >
                {(
                  dragProvided: DraggableProvided,
                  dragSnapshot: DraggableStateSnapshot,
                ) =>
                  renderItem(
                    item,
                    index,
                    dragProvided,
                    dragSnapshot,
                    children,
                    style,
                    hoverable,
                  )
                }
              </Draggable>
            ))}
          </S.Wrapper>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <S.Wrapper>
      {items.map((item, index) => (
        <S.ListItem
          key={String(index)}
          style={style}
          onClick={() => onItemClick && onItemClick(item)}
          hoverable={hoverable}
        >
          {children(item, index)}
        </S.ListItem>
      ))}
    </S.Wrapper>
  )
}

export default List

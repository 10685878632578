import { memo } from 'react'

import Table, { TableProps } from '@/components/Table'

type TableDataProps<T> = {
  data: T[]
  onEdit: (item: T) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: any
} & Omit<TableProps<T>, 'columns'>

const TableData = <T,>({
  data,
  onEdit,
  columns,
  ...props
}: TableDataProps<T>) => (
  <Table<T>
    columns={columns}
    data={data}
    onRowClicked={onEdit}
    highlightOnHover={true}
    pointerOnHover={true}
    {...props}
  />
)

export default memo(TableData) as typeof TableData

import axios, { AxiosError } from 'axios'

import Toast from '@/components/Toast'
import { env } from '@/env'

export const api = axios.create({
  baseURL: env.VITE_API_URL,
  timeout: 30000, // 30 seconds
})

export type ErrorResponseType = {
  statusCode: number
  message: string
  error: string
}

const onResponseError = (
  error: AxiosError,
): Promise<AxiosError<ErrorResponseType>> => {
  if (error?.response?.status === 401 || error?.response?.status === 403) {
    api.defaults.headers.Authorization = ''
    localStorage.clear()
    window.location.pathname = '/sign-in'
  }

  if (error?.response?.status === 500) {
    window.location.pathname = '/500'
  }

  if (error?.code === 'ECONNABORTED') {
    Toast({
      title: 'Error',
      message: 'Request timeout, please try again.',
    })
    return Promise.reject(error)
  }

  Toast({
    title: 'Error',
    message:
      (error?.response?.data as Record<string, string>)?.message ||
      (error?.response?.data as Record<string, string>)?.error ||
      'Ops, something went wrong.',
  })

  return Promise.reject(error?.response?.data || error)
}

api.interceptors.response.use((response) => response, onResponseError)

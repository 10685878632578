import { Link as RouterLink } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components'
import media from 'styled-media-query'

import { Box } from '@/components/Box'
import { InputWrapper } from '@/components/TextField/styles'

const flickerAnimation = keyframes`
  0%   { background-color: rgba(251, 162, 64, 0.5); }
  50%  { background-color: transparent; }
  100% { background-color: rgba(251, 162, 64, 0.5); }
`

export const Wrapper = styled.main`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacings.xxlarge} ${theme.spacings.small};

    .tooltip {
      border-radius: 0.5rem;
      font-family: ${theme.font.quicksand};
      font-size: ${theme.font.sizes.xsmall};
    }

    .rdt_TableRow {
      background-position: center;
      transition: background 0.8s;

      &:hover {
        background: ${theme.colors.mainBg}
          radial-gradient(circle, transparent 1%, ${theme.colors.mainBg} 1%)
          center/15000%;
      }

      &:active {
        background-color: ${theme.colors.lightGray};
        background-size: 100%;
        transition: background 0s;
      }
    }
  `}
`

export const Header = styled.header`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: ${theme.spacings.medium};

    h1 {
      font-size: ${theme.font.sizes.xlarge};
      color: ${theme.colors.primary};
      font-family: ${theme.font.nunito};
      font-weight: ${theme.font.extraBold};
    }

    ${media.lessThan('medium')`
      > ${Box} {
        flex-direction: column;
        align-items: start;

        > ${Box} {
          align-self: end;
        }
      }
    `}
  `}
`

export const Link = styled(RouterLink)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-family: ${theme.font.nunito};
    font-size: ${theme.font.sizes.small};
    font-weight: ${theme.font.semiBold};
    color: ${theme.colors.primary};
    text-decoration: none;

    img {
      margin-left: 0.25rem;
    }
  `}
`

export const TextFieldWrapper = styled.div`
  ${({ theme }) => css`
    width: 12.375rem;
    max-width: 13rem;

    ${InputWrapper} {
      border-color: ${theme.colors.mainBg};

      &:focus-within {
        border-color: ${theme.colors.lightGray};
      }
    }
  `}
`

export const DateWrapper = styled.div`
  width: 180px;

  ${media.greaterThan('large')`
    width: 300px;
  `}
`

export const ContentTable = styled.div`
  width: 100%;

  .new_order {
    animation: ${flickerAnimation} 2s infinite;
  }
`

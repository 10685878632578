import {
  ButtonHTMLAttributes,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react'

import * as S from './styles'

export type SelectButtonProps = {
  selected: boolean
  size?: string
  children: React.ReactNode
} & ButtonHTMLAttributes<HTMLButtonElement>

const SelectButton: ForwardRefRenderFunction<
  HTMLButtonElement,
  SelectButtonProps
> = ({ selected, size, children, ...props }, ref) => (
  <S.Wrapper selected={selected} size={size} ref={ref} {...props}>
    {children}
  </S.Wrapper>
)

export default forwardRef(SelectButton)

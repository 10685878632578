import { Share } from '@styled-icons/material-rounded'
import { format } from 'date-fns'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { StepForm, StepOverflow } from '@/components/FormStep'
import { useFormStepData } from '@/components/FormStep/Hook'
import {
  ModalFooter,
  ModalHeader,
  ModalHeaderClose,
  ModalHeaderTitle,
} from '@/components/Modal'
import Toast from '@/components/Toast'
import { env } from '@/env'
import useAuth from '@/hooks/useAuth'
import {
  FieldContent,
  FieldHeader,
  FieldHeaderActions,
  FieldHeaderTitle,
  FieldWrapper,
  WrapperContent,
} from '@/modals/CreatingNewCampaignModal/styles'
import { formatCurrencyWithCode } from '@/utils/formats/number'

import { PaymentFormData } from '../CheckoutForm'
import * as S from './styles'

export type ShareLoyaltyProps = {
  close: (isSuccess?: boolean) => void
  active: boolean
}

export const ShareLoyalty = ({ close, active }: ShareLoyaltyProps) => {
  const [isShareLoading, setIsShareLoading] = useState(false)
  const { dataForm } = useFormStepData<PaymentFormData>()
  const { merchantSelected } = useAuth()
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  const handleShare = useCallback(async () => {
    try {
      setIsShareLoading(true)
      const url =
        env.VITE_ENV === 'stage'
          ? `https://stage.bento.ky/merchants/${merchantSelected?.id}`
          : `https://bento.ky/merchants/${merchantSelected?.id}`
      if (isMobile && navigator.share) {
        await navigator.share({ url })
      } else {
        await navigator.clipboard.writeText(url)
        Toast({
          title: 'Loyalty campaign link copied',
          message: 'Share it with your customers.',
          type: 'success',
        })
      }
    } finally {
      setIsShareLoading(false)
    }
  }, [isMobile, merchantSelected?.id])

  return (
    <StepForm>
      <ModalHeader>
        <ModalHeaderTitle>Loyalty Campaign</ModalHeaderTitle>

        <ModalHeaderClose
          onClick={(event) => {
            event.preventDefault()
            close(true)
          }}
        >
          ✕
        </ModalHeaderClose>
      </ModalHeader>

      <StepOverflow active={active ? 1 : 0}>
        <WrapperContent>
          {!dataForm.audiences && (
            <FieldWrapper>
              <FieldHeader>
                <FieldHeaderTitle>Campaign link</FieldHeaderTitle>

                <FieldHeaderActions>
                  <Button
                    onClick={handleShare}
                    icon={<Share />}
                    loading={isShareLoading}
                    disabled={isShareLoading}
                  >
                    <span style={{ color: '#FFF' }}>Share</span>
                  </Button>
                </FieldHeaderActions>
              </FieldHeader>
            </FieldWrapper>
          )}

          <FieldWrapper>
            <FieldHeader>
              <FieldHeaderTitle>Conditions</FieldHeaderTitle>
            </FieldHeader>

            <FieldContent>
              <Box flex alignItems="center" gap="0.875rem">
                <S.ConditionsList>
                  <li>
                    {dataForm?.budget?.vouchers}{' '}
                    {t(
                      'modals.creating-new-campaign.checkoutStep.vouchers.voucherQuantity',
                      {
                        count: dataForm?.budget?.vouchers,
                      },
                    )}{' '}
                    available from{' '}
                    {dataForm.period?.startAt &&
                      format(dataForm.period.startAt, 'MMM d, yyyy')}{' '}
                    until{' '}
                    {dataForm.period?.endAt &&
                      format(dataForm.period.endAt, 'MMM d, yyyy')}
                    .
                  </li>
                  <li>1 voucher per ID</li>
                  <li>Vouchers only for new orders</li>
                  <li>
                    Minimum order{' '}
                    {formatCurrencyWithCode(
                      dataForm.minimumOrderValue / 100 || 0,
                    )}
                  </li>
                </S.ConditionsList>
              </Box>
            </FieldContent>
          </FieldWrapper>
        </WrapperContent>
      </StepOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
        >
          <span />
          <Button size="large" onClick={() => close(true)}>
            Done
          </Button>
        </Box>
      </ModalFooter>
    </StepForm>
  )
}

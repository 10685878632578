import { Add as AddIcon } from '@styled-icons/material-outlined'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import settingsService from '@/api/settings.service'
import circleCheckIcon from '@/assets/img/icon-circle-check.svg'
import deleteIcon from '@/assets/img/icon-delete.svg'
import editFilledIcon from '@/assets/img/icon-edit-filled.svg'
import { Box } from '@/components/Box'
import { Modal, ModalContent } from '@/components/Modal'
import TextField from '@/components/TextField'
import useAuth from '@/hooks/useAuth'
import useModal from '@/hooks/useModal'
import ConfirmModal from '@/modals/ConfirmModal'
import EditSettingsHoursModal from '@/modals/EditSettingsHoursModal'
import { ShiftType } from '@/types/menu-maker.types'
import { MerchantHoursType } from '@/types/settings.types'
import { getHoursToEdit, getWeekDays } from '@/utils/formats/date'

import { FormSettingsHoursLoading } from './loader'
import * as S from './styles'

type FormErrorsType = {
  cookingTime: string
}

type ShiftToEditType = ShiftType & {
  index: number
}

export function FormHours() {
  const [cookingTime, setCookingTime] = useState('')
  const { open: openModal, toggle: setOpenModal } = useModal()
  const { open: openConfirmModal, toggle: setOpenConfirmModal } = useModal()
  const [isLoading, setIsLoading] = useState(true)
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [isCookingTimeEdit, setIsCookingTimeEdit] = useState(false)
  const [shifts, setShifts] = useState<ShiftType[]>([])
  const [canSaveShift, setCanSaveShift] = useState(false)
  const [shiftToEdit, setShiftToEdit] = useState<ShiftToEditType | null>(null)
  const [formErrors, setFormErrors] = useState<FormErrorsType>({
    cookingTime: '',
  })
  const { user, merchantSelected } = useAuth()
  const { t } = useTranslation()

  const getMerchantHours = async (merchantId: string, userId: string) => {
    try {
      setIsLoading(true)
      const { data } = await settingsService.getMerchantHours(
        merchantId,
        userId,
      )
      setShifts(data.shifts || [])
      setCookingTime(String(data.cookingTime))
    } finally {
      setIsLoading(false)
    }
  }

  const handleSave = useCallback(async () => {
    if (user && merchantSelected?.id) {
      try {
        setIsSaveLoading(true)
        const dataToSave: MerchantHoursType = {
          cookingTime: Number(cookingTime),
          shifts,
        }

        if (!dataToSave.cookingTime) {
          setFormErrors({ cookingTime: 'Cooking time is required' })
          return
        }

        const { data } = await settingsService.saveMerchantHours(
          dataToSave,
          merchantSelected.id,
          user.uuid,
        )
        setShifts(data.shifts || [])
        setCookingTime(String(data.cookingTime))
      } finally {
        setCanSaveShift(false)
        setIsCookingTimeEdit(false)
        setIsSaveLoading(false)
      }
    }
  }, [cookingTime, merchantSelected, shifts, user])

  const handleEditShift = useCallback(
    (shiftEdit: ShiftType, index: number) => {
      setShiftToEdit({ ...shiftEdit, index })
      setOpenModal(true)
    },
    [setOpenModal],
  )

  const handleAddShift = useCallback(() => {
    setShiftToEdit(null)
    setOpenModal(true)
  }, [setOpenModal])

  const handleRemoveShift = useCallback(
    (index: number) => {
      const newShifts = [...shifts]
      newShifts.splice(index, 1)
      setShifts(newShifts)
      setCanSaveShift(true)
    },
    [shifts],
  )

  const closeEditSettingsHours = useCallback(
    (shift?: ShiftType) => {
      if (shift) {
        if (shiftToEdit) {
          const newShifts = [...shifts]
          newShifts[shiftToEdit.index] = shift
          setShifts(newShifts)
        } else {
          setShifts((old) => [...old, shift])
        }
        setCanSaveShift(true)
      }
      setShiftToEdit(null)
      setOpenModal(false)
    },
    [setOpenModal, shiftToEdit, shifts],
  )

  const renderOpenEditSettingsHoursModal = useCallback(() => {
    return (
      <Modal
        open={openModal}
        onOpenChange={(open) => {
          if (!open) closeEditSettingsHours()
        }}
        modal
      >
        <ModalContent
          title={shiftToEdit ? 'Edit' : 'Add more'}
          onClose={closeEditSettingsHours}
        >
          <EditSettingsHoursModal
            editItem={shiftToEdit}
            closeEditSettingsHours={closeEditSettingsHours}
          />
        </ModalContent>
      </Modal>
    )
  }, [closeEditSettingsHours, openModal, shiftToEdit])

  const renderConfirmModal = () => (
    <Modal open={openConfirmModal} onOpenChange={setOpenConfirmModal} modal>
      <ModalContent
        title="Delete all Business Hours"
        onClose={() => setOpenConfirmModal(false)}
        hideCloseButton
        width="auto"
        xsWidth="90vw"
        xsHeight="auto"
      >
        <ConfirmModal
          message="Are you sure you want to delete all Business Hours?"
          closeConfirm={(confirm) => {
            if (confirm) handleRemoveShift(0)
            setOpenConfirmModal(false)
          }}
        />
      </ModalContent>
    </Modal>
  )

  useEffect(() => {
    if (user && merchantSelected) {
      getMerchantHours(merchantSelected.id, user.uuid)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantSelected])

  useEffect(() => {
    if (canSaveShift) {
      handleSave()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSaveShift])

  return isLoading ? (
    <FormSettingsHoursLoading />
  ) : (
    <S.Wrapper>
      <S.Subtitle>Business Hours</S.Subtitle>
      {shifts.map(({ weekDays, hours }, index) => (
        <S.SavedItems key={index}>
          <S.SavedItemContainer>
            <S.SavedItem>
              <h5>Days</h5>
              <S.SavedDescription>
                {getWeekDays(weekDays, ' | ')}
              </S.SavedDescription>
            </S.SavedItem>
            <S.SavedItem>
              <h5>Hours</h5>
              <S.SavedDescription>{getHoursToEdit(hours)}</S.SavedDescription>
            </S.SavedItem>
          </S.SavedItemContainer>

          <S.Actions>
            <S.ButtonIcon
              minimal
              disabled={isLoading}
              onClick={() => handleEditShift({ weekDays, hours }, index)}
            >
              <img
                src={editFilledIcon}
                width={20}
                height={20}
                alt="Edit icon"
              />
            </S.ButtonIcon>
            <S.ButtonIcon
              minimal
              disabled={isLoading}
              onClick={() =>
                shifts.length === 1
                  ? setOpenConfirmModal(true)
                  : handleRemoveShift(index)
              }
            >
              <img src={deleteIcon} width={24} height={24} alt="Delete icon" />
            </S.ButtonIcon>
          </S.Actions>
        </S.SavedItems>
      ))}

      <Box flex alignItems="center" JustifyContent="center">
        <S.ButtonAdd
          icon={<AddIcon />}
          iconside="right"
          minimal
          disabled={isSaveLoading}
          onClick={handleAddShift}
        >
          add more
        </S.ButtonAdd>
      </Box>

      <S.Subtitle style={{ marginBottom: '0.375rem' }}>
        Preparation Time
      </S.Subtitle>
      <Box
        flex
        alignItems={isCookingTimeEdit ? 'end' : 'center'}
        width="8.125rem"
      >
        {isCookingTimeEdit ? (
          <>
            <TextField
              variant="material"
              label="Time (minutes)"
              value={cookingTime}
              mask="number"
              disabled={isSaveLoading}
              error={formErrors.cookingTime}
              onChange={(event) => setCookingTime(event.currentTarget.value)}
            />

            <S.ButtonIcon minimal onClick={handleSave} disabled={isSaveLoading}>
              <img
                src={circleCheckIcon}
                width={20}
                height={20}
                alt="Circle check icon"
              />
            </S.ButtonIcon>
          </>
        ) : (
          <>
            <S.SavedText>
              {t('common.minuteCount', { count: Number(cookingTime) })}
            </S.SavedText>
            <S.ButtonIcon
              minimal
              onClick={() => setIsCookingTimeEdit(true)}
              disabled={isSaveLoading}
            >
              <img
                src={editFilledIcon}
                width={20}
                height={20}
                alt="Edit icon"
              />
            </S.ButtonIcon>
          </>
        )}
      </Box>

      {openModal && renderOpenEditSettingsHoursModal()}
      {openConfirmModal && renderConfirmModal()}
    </S.Wrapper>
  )
}

import bennyAssist from '@/assets/img/benny-assist.svg'

import * as S from './styles'

type AlertAddressProps = {
  title: string
  description?: string
  style?: React.CSSProperties
}

const AlertAddress = ({ title, description, ...props }: AlertAddressProps) => {
  return (
    <S.Wrapper {...props}>
      <S.Image src={bennyAssist} alt="Benny assist" />

      <S.Description>
        <h3>{title}</h3>
        {description && <p>{description}</p>}
      </S.Description>
    </S.Wrapper>
  )
}

export default AlertAddress

import styled, { css, keyframes } from 'styled-components'

import { SkeletonProps } from '.'

type WrapperProps = Omit<SkeletonProps, 'count'>

const WrapperModifiers = {
  circle: css`
    border-radius: 50%;
  `,
}

export const Wrapper = styled.div<WrapperProps>`
  ${({
    theme,
    width = '100%',
    height = 1,
    direction = 'column',
    radius = 3,
    margin = 0.5,
    circle,
  }) => css`
    width: ${typeof width === 'number' ? `${width}rem` : width};
    height: ${typeof height === 'number' ? `${height}rem` : height};
    border-radius: ${typeof radius === 'number' ? `${radius}px` : radius};
    background-color: ${theme.colors.loadingGradient};
    position: relative;
    overflow: hidden;
    animation: ${loading} 1s ease-in-out infinite;

    ${circle && WrapperModifiers.circle}

    ${direction === 'column' &&
    css`
      + div {
        margin-top: ${typeof margin === 'number' ? `${margin}rem` : margin};
      }
    `}
  `}
`

const loading = keyframes`
  0%   { opacity: 1; }
  50%  { opacity: 0.3; }
  100% { opacity: 1; }
`

import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { Animation as LoadingAnimation } from '@/components/Loading/styles'
import { Wrapper as WrapperSelect } from '@/components/Select/styles'
import { FieldContent } from '@/modals/CreatingNewCampaignModal/styles'

export const FieldContentSelect = styled(FieldContent)`
  ${({ theme }) => css`
    padding-right: 2rem;
    border-right: 1px solid ${theme.colors.superLight};
    flex: 1;
    width: 100%;

    > ${WrapperSelect} {
      margin: 0;

      .react-select__menu {
        .react-select__menu-list {
          .react-select__option {
            &:hover,
            &--is-focused,
            &--is-selected {
              background: ${theme.colors.mainBg};
              color: ${theme.colors.secondary};
            }
          }
        }
      }
    }

    .slider-track {
      top: 0.25rem;
      height: 0.25rem;
      border-radius: 3px;

      &.slider-track-0 {
        background-color: ${theme.colors.primary};
        z-index: 1;
      }

      &.slider-track-1 {
        background-color: ${theme.colors.supportGray};
      }
    }

    .slider-thumb {
      top: -2.375rem;
      padding: 0.313rem;
      background: ${theme.colors.primary};
      color: ${theme.colors.primaryText};
      font-weight: ${theme.font.medium};
      cursor: pointer;
      border-radius: 4px;
      z-index: 0 !important;
      white-space: nowrap;
      transition: all 0.2s ease-in-out;

      &:before {
        content: '';
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        border: 8px solid transparent;
        border-top-color: ${theme.colors.primary};
        bottom: -14px;
        border-radius: 4px;
      }

      &:hover {
        transform: scale(1.1);
      }
    }

    ${media.lessThan('medium')`
      border-right: none;
      padding-right: 0;
      border-bottom: 1px solid ${theme.colors.superLight};
      padding-bottom: 2rem;
    `}
  `}
`

export const LoadingSpinner = styled(LoadingAnimation)``

export const AudienceContent = styled.div`
  display: flex;

  ${media.lessThan('medium')`
    flex-direction: column;
    align-items: flex-start;
  `}
`

export const AudienceInfo = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.xsmall};
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.supportGray};
    line-height: 1.438rem;
    max-width: 16.875rem;

    strong {
      font-weight: ${theme.font.bold};
    }

    ul {
      padding-left: 2.625rem;
    }

    ${media.lessThan('medium')`
      ul {
        padding-left: 1rem;
      }
    `}
  `}
`

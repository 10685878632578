// import useAudio from '@/hooks/useAudio'
import * as S from './styles'

export type SnackbarProps = {
  icon: React.ReactNode
  title: string
  message: string | React.ReactNode
  open?: boolean
  playSound?: boolean
}

const Snackbar = ({
  icon,
  title,
  message,
  open,
  // playSound = false,
}: SnackbarProps) => {
  // const { playOnce, canPlay, playing } = useAudio()

  // useEffect(() => {
  //   if (playSound && canPlay) {
  //     if (open && !playing) {
  //       playOnce()
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [playSound, open])

  return (
    <S.Wrapper open={open}>
      <S.IconWrapper>{icon}</S.IconWrapper>

      <S.Content>
        <S.Title>{title}</S.Title>
        <S.Message>{message}</S.Message>
      </S.Content>
    </S.Wrapper>
  )
}

export default Snackbar

import { Helmet } from 'react-helmet-async'

import Tabs from '@/components/Tabs'

import { Campaigns } from './components/campaigns'
import { NewCampaign } from './components/new-campaign'
import { PaymentMethods } from './components/payment-methods'
// import UpgradePlanButton from './components/upgrade-plan/upgrade-plan-button'
import { Vouchers } from './components/vouchers'
import * as S from './styles'

interface Props {
  activeTab?: number
}

export function SuperPartner({ activeTab = 0 }: Props) {
  return (
    <>
      <Helmet titleTemplate="%s - Super Partner | Partner Portal" />
      <S.Wrapper>
        {/* <UpgradePlanButton /> */}
        <Tabs
          activeTabProp={activeTab}
          tabs={[
            {
              id: '1',
              title: 'New Campaign',
              content: <NewCampaign />,
              route: '/super-partner/new-campaign',
            },
            {
              id: '2',
              title: 'Campaigns',
              content: <Campaigns />,
              route: '/super-partner/campaigns',
            },
            {
              id: '3',
              title: 'Vouchers',
              content: <Vouchers />,
              route: '/super-partner/vouchers',
            },
            {
              id: '4',
              title: 'Payments Methods',
              content: <PaymentMethods />,
              route: '/super-partner/payment',
            },
          ]}
        />
      </S.Wrapper>
    </>
  )
}

import { useCallback, useState } from 'react'

import ordersService from '@/api/orders.service'
import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { ModalFooter, ModalOverflow } from '@/components/Modal'
import TextArea from '@/components/TextArea'
import TextField from '@/components/TextField'
import Toast from '@/components/Toast'

import * as S from './styles'

export type CreateMagicVoucherModalProps = {
  orderId?: string
  orderShortId?: string
  close: () => void
}

type FormErrorsType = {
  reason: string
  amount: string
}

const CreateMagicVoucherModal = ({
  orderId,
  orderShortId,
  close,
}: CreateMagicVoucherModalProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [reason, setReason] = useState('')
  const [amount, setAmount] = useState('')
  const [formErrors, setFormErrors] = useState<FormErrorsType>({
    reason: '',
    amount: '',
  })

  const canBeSave = reason === '' || amount === '' || amount === '0.00'

  const saveMagicVoucher = useCallback(async () => {
    setIsLoading(true)

    const data = {
      amount: parseInt(amount.toString().replace('.', ''), 10),
      reason,
    }

    if (!data.reason) {
      setFormErrors((old) => ({ ...old, reason: 'Reason is required' }))
      setIsLoading(false)
      return
    }

    setFormErrors((old) => ({ ...old, reason: '' }))

    if (!data.amount) {
      setFormErrors((old) => ({ ...old, amount: 'Amount is required' }))
      setIsLoading(false)
      return
    }

    setFormErrors((old) => ({ ...old, amount: '' }))

    try {
      await ordersService.saveCreateMagicVoucher(data, orderId)
      Toast({
        type: 'success',
        title: 'Create Magic Voucher',
        message: `Magic Voucher created for order ${orderShortId}`,
      })
      close()
    } finally {
      setIsLoading(false)
    }
  }, [amount, reason, orderId, orderShortId, close])

  return (
    <>
      <ModalOverflow>
        <S.Description>
          Magic Vouchers are added to customers account directly, make sure you
          add the reason and the correct amount.
          <br />
          <br />
          These vouchers will expire automatically after 3 months.
        </S.Description>

        <S.FormControl>
          <TextArea
            value={reason}
            onChange={(event) => setReason(event.currentTarget.value)}
            error={formErrors.reason}
            label="Reason"
            labelFor="reason"
            id="reason"
            disabled={isLoading}
            variant="material"
            style={{ width: '18.75rem' }}
          />
        </S.FormControl>

        <S.FormControl>
          <TextField
            value={amount}
            onChange={(event) => setAmount(event.currentTarget.value)}
            error={formErrors.amount}
            label="Amount"
            labelFor="amount"
            id="amount"
            mask="money"
            disabled={isLoading}
            inputMode="numeric"
            variant="material"
          />
        </S.FormControl>
      </ModalOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
          style={{ marginTop: '1rem' }}
        >
          <Button color="white" size="large" onClick={close} shadow>
            Cancel
          </Button>

          <Button
            size="large"
            disabled={isLoading || canBeSave}
            onClick={saveMagicVoucher}
            loading={isLoading}
            style={{ marginLeft: 'auto' }}
          >
            Save
          </Button>
        </Box>
      </ModalFooter>
    </>
  )
}

export default CreateMagicVoucherModal

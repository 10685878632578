import format from 'date-fns/format'

import { statusModifiers } from '@/components/Table'
import { OrderType } from '@/types/orders.types'

export const convertArrayOfObjectsToCSV = (
  orders: OrderType[],
  columns: { key: string; name: string }[],
) => {
  let result: string

  const columnDelimiter = ','
  const lineDelimiter = '\n'
  const keys = columns.map((col) => col.key)
  const columnsNames = columns.map((col) => col.name)

  result = ''
  result += columnsNames.join(columnDelimiter)
  result += lineDelimiter

  orders.forEach((item) => {
    let ctr = 0
    item.refundComment = item.refundAmount ? item.refundComment : '-'

    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter
      if (key === 'user.name') {
        result += item.user.name
      } else if (key === 'lastStatus') {
        result += statusModifiers[item.lastStatus]
      } else if (key === 'createdAt') {
        result += format(new Date(item.createdAt), 'yyyy-MM-dd hh:mm a')
      } else if (
        key === 'deliveryFee' ||
        key === 'subTotal' ||
        key === 'total' ||
        key === 'netSale' ||
        key === 'refundAmount'
      ) {
        result += item[key as keyof OrderType]
          ? Number(item[key as keyof OrderType])
          : '-'
      } else if (key === 'tip') {
        let tip = item.tip ? item.tip?.amount ?? 0 : '-'
        tip =
          item.type === 'delivery' && item.deliveryHandler === 'bento'
            ? '-'
            : tip
        result += tip
      } else if (key === 'magicVouchers') {
        const sum = item.magicVouchers?.reduce((accumulator, currentValue) => {
          if (
            currentValue.author !== 'bento' &&
            currentValue.author === 'merchant'
          ) {
            return accumulator + currentValue.balance
          }
          return accumulator
        }, 0)
        result += sum ? sum * -1 : 0
      } else {
        result += item[key as keyof OrderType] || '-'
      }
      ctr++
    })
    result += lineDelimiter
  })

  return result
}

export const downloadCSV = (
  orders: OrderType[],
  columns: { key: string; name: string }[],
  [startDate, endDate]: Date[],
) => {
  const link = document.createElement('a')
  let csv = convertArrayOfObjectsToCSV(orders, columns)
  if (csv == null) return

  const filename = `orders_${format(startDate, 'dd-MM-yyyy')}-to-${format(
    endDate,
    'dd-MM-yyyy',
  )}.csv`

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`
  }

  link.setAttribute('href', encodeURI(csv))
  link.setAttribute('download', filename)
  link.click()
}

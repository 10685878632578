import { useTheme } from 'styled-components'

import EmptyStateLight from '@/assets/img/icons-message-empty-state.svg'
import EmptyState from '@/assets/img/icons-message-empty-state-dark.svg'

import * as S from './styles'

export type EmptyProps = {
  title: string
  description?: string
  image?: string
}

const Empty = ({ image, title, description }: EmptyProps) => {
  const theme = useTheme()
  const imageDefault = theme.dark ? EmptyState : EmptyStateLight

  return (
    <div className="flex flex-col items-center justify-center">
      <S.Image src={image || imageDefault} />
      <S.Title>{title}</S.Title>
      {!!description && <S.Description>{description}</S.Description>}
    </div>
  )
}

export default Empty

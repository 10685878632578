import { PhotoCamera as PhotoCameraIcon } from '@styled-icons/material-outlined'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import slugify from 'slugify'
import { titleCase } from 'title-case'

import settingsService from '@/api/settings.service'
import editFilledIcon from '@/assets/img/icon-edit-filled.svg'
import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { Modal, ModalContent } from '@/components/Modal'
import Radio from '@/components/Radio'
import Switch from '@/components/Switch'
import TextField from '@/components/TextField'
import useAuth from '@/hooks/useAuth'
import useModal from '@/hooks/useModal'
import CropPictureModal from '@/modals/CropPictureModal'
import { ImageUploadType } from '@/modals/EditItemModal'
import EditLocationInstructionsModal from '@/modals/EditLocationInstructionsModal'
import EditSettingsAvailabilityModal from '@/modals/EditSettingsAvailabilityModal'
import EditSettingsCategoryModal, {
  CategorySelectedType,
} from '@/modals/EditSettingsCategoryModal'
import EditSettingsServicesModal, {
  ServiceSelectedType,
} from '@/modals/EditSettingsServicesModal'
import EditSettingsTagModal, {
  TagSelectedType,
} from '@/modals/EditSettingsTagModal'
import { MerchantGeneralInfoType } from '@/types/settings.types'
import { phoneMask } from '@/utils/formats/masks'

import { FormSettingsLoading } from './loader'
import * as S from './styles'

export type Props = {
  admin: boolean
}

export function FormSettings({ admin }: Props) {
  const [merchantInfo, setMerchantInfo] = useState<MerchantGeneralInfoType>()
  const [logoUpload, setLogoUpload] = useState<ImageUploadType>(
    {} as ImageUploadType,
  )
  const [pictureUpload, setPictureUpload] = useState<ImageUploadType>(
    {} as ImageUploadType,
  )
  const [name, setName] = useState('')
  const [instructions, setInstructions] = useState('')
  const [initials, setInitials] = useState('')
  const [phone, setPhone] = useState('')
  const [delivery, setDelivery] = useState('')
  const [available, setAvailable] = useState(false)
  const [availableAgainAt, setAvailableAgainAt] = useState<string>()
  const [visible, setVisible] = useState(false)
  const [categoriesSelected, setCategoriesSelected] = useState<
    CategorySelectedType[]
  >([])
  const [servicesSelected, setServicesSelected] = useState<
    ServiceSelectedType[]
  >([])
  const [tagsSelected, setTagsSelected] = useState<TagSelectedType[]>([])
  const { open: openPictureCropModal, toggle: setOpenPictureCropModal } =
    useModal()
  const { open: openCategoryModal, toggle: setOpenCategoryModal } = useModal()
  const { open: openTagModal, toggle: setOpenTagModal } = useModal()
  const { open: openServiceModal, toggle: setOpenServiceModal } = useModal()
  const { open: openLocationModal, toggle: setOpenLocationModal } = useModal()
  const { open: openAvailabilityModal, toggle: setOpenAvailabilityModal } =
    useModal()
  const [isLoading, setIsLoading] = useState(true)
  const [saveLoading, setSaveLoading] = useState(false)
  const { user, merchantSelected } = useAuth()
  const fileLogoInputRef = useRef<HTMLInputElement | null>(null)
  const filePictureInputRef = useRef<HTMLInputElement | null>(null)

  const canEnableSave = useCallback(() => {
    if (merchantInfo) {
      const dataToCompare: MerchantGeneralInfoType = {
        category: categoriesSelected.map((cat) => cat.name).join(' • '),
        tags: tagsSelected.map((t) => t.name),
        hasBentoDelivery: delivery === 'bento',
        hasDelivery: servicesSelected.some(
          (service) => service.name === 'Delivery',
        ),
        hasTakeout: servicesSelected.some(
          (service) => service.name === 'Takeout',
        ),
        initials,
        isOpen: available,
        logo: logoUpload.imagePreview,
        name,
        phone,
        picture: pictureUpload.imagePreview,
        type: name,
        visible,
      }

      const merchantInfoArray = Object.keys(merchantInfo)

      return merchantInfoArray.every((key) => {
        return (
          merchantInfo[key as keyof MerchantGeneralInfoType] ===
          dataToCompare[key as keyof MerchantGeneralInfoType]
        )
      })
    }
  }, [
    available,
    categoriesSelected,
    tagsSelected,
    delivery,
    initials,
    logoUpload.imagePreview,
    merchantInfo,
    name,
    phone,
    pictureUpload.imagePreview,
    servicesSelected,
    visible,
  ])

  const handleUploadButton = (logo = false) => {
    if (logo) {
      fileLogoInputRef.current && fileLogoInputRef.current.click()
      return
    }

    filePictureInputRef.current && filePictureInputRef.current.click()
  }

  const handleUploadChange = (
    event: ChangeEvent<HTMLInputElement>,
    logo = false,
  ) => {
    const file = event.target.files ? event.target.files[0] : null
    const timestamp = new Date().getTime()

    if (file) {
      const blob = file.slice(0, file.size, file.type)
      const newFile = new File(
        [blob],
        slugify(timestamp + file.name.replace(/[^a-zA-Z0-9]/g, '')),
        {
          type: file?.type,
        },
      )

      const imagePreview = URL.createObjectURL(newFile)

      if (logo) {
        setLogoUpload({ file: newFile, imagePreview })
        return
      }

      setPictureUpload({ file: newFile, imagePreview })
      setOpenPictureCropModal(true)
    }
  }

  const renderPictureModalCrop = () => (
    <Modal
      open={openPictureCropModal}
      onOpenChange={setOpenPictureCropModal}
      modal
    >
      <ModalContent
        title="Crop Picture"
        onClose={() => setOpenPictureCropModal(false)}
      >
        <CropPictureModal
          picture={pictureUpload}
          closeCropped={(pictureCropped) => {
            if (pictureCropped) setPictureUpload(pictureCropped)
            setOpenPictureCropModal(false)
          }}
        />
      </ModalContent>
    </Modal>
  )

  const closeEditSettingsCategory = (selecteds?: CategorySelectedType[]) => {
    if (selecteds) {
      setCategoriesSelected(selecteds)
    }
    setOpenCategoryModal(false)
  }

  const renderOpenEditSettingsCategoryModal = () => {
    return (
      <Modal open={openCategoryModal} onOpenChange={setOpenCategoryModal} modal>
        <ModalContent
          title="Edit Categories"
          onClose={() => setOpenCategoryModal(false)}
        >
          <EditSettingsCategoryModal
            categories={categoriesSelected}
            closeEditSettingsCategory={closeEditSettingsCategory}
          />
        </ModalContent>
      </Modal>
    )
  }

  const closeEditSettingsTag = (selecteds?: TagSelectedType[]) => {
    if (selecteds) {
      setTagsSelected(selecteds)
    }
    setOpenTagModal(false)
  }

  const renderOpenEditSettingsTagModal = () => {
    return (
      <Modal open={openTagModal} onOpenChange={setOpenTagModal} modal>
        <ModalContent title="Edit Tags" onClose={() => setOpenTagModal(false)}>
          <EditSettingsTagModal
            tags={tagsSelected}
            closeEditSettingsTag={closeEditSettingsTag}
          />
        </ModalContent>
      </Modal>
    )
  }

  const closeEditSettingsServices = (selecteds?: ServiceSelectedType[]) => {
    if (selecteds) {
      setServicesSelected(selecteds)
    }
    setOpenServiceModal(false)
  }

  const closeLocationModal = (newInstructions: string) => {
    setInstructions(newInstructions)
    handleSave(newInstructions, () => setOpenLocationModal(false))
    document.body.style.overflow = 'auto'
  }

  const renderOpenEditSettingsServicesModal = () => {
    return (
      <Modal open={openServiceModal} onOpenChange={setOpenServiceModal} modal>
        <ModalContent
          title="Edit Services"
          onClose={() => setOpenServiceModal(false)}
          style={{ minWidth: '25rem' }}
          width="auto"
        >
          <EditSettingsServicesModal
            services={servicesSelected}
            closeEditSettingsServices={closeEditSettingsServices}
          />
        </ModalContent>
      </Modal>
    )
  }

  const renderOpenLocationModal = () => {
    return (
      <Modal open={openLocationModal} onOpenChange={setOpenLocationModal}>
        <ModalContent
          title="Edit Location Instructions"
          onClose={() => setOpenLocationModal(false)}
        >
          <EditLocationInstructionsModal
            instructions={instructions}
            closeEditLocalInstructions={closeLocationModal}
            loading={saveLoading}
          />
        </ModalContent>
      </Modal>
    )
  }

  const handleAvailableChange = (value: boolean) => {
    if (!value) {
      setOpenAvailabilityModal(true)
      return
    }

    setAvailable(true)
  }

  const closeEditAvailability = (againAt?: string) => {
    if (againAt) {
      setAvailableAgainAt(againAt)
      setAvailable(false)
    } else {
      setAvailable(true)
    }
    setOpenAvailabilityModal(false)
  }

  const renderOpenEditSettingsAvailabilityModal = () => {
    return (
      <Modal
        open={openAvailabilityModal}
        onOpenChange={setOpenAvailabilityModal}
        modal
      >
        <ModalContent
          title="Turn OFF for…"
          style={{ minWidth: '25rem' }}
          onClose={() => setOpenAvailabilityModal(false)}
          width="auto"
        >
          <EditSettingsAvailabilityModal
            closeAvailability={closeEditAvailability}
          />
        </ModalContent>
      </Modal>
    )
  }

  const getMerchantGeneralInfo = async (merchantId: string, userId: string) => {
    setIsLoading(true)
    try {
      const { data } = await settingsService.getMerchantGeneralInfo(
        merchantId,
        userId,
      )
      setMerchantInfo(data)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSave = useCallback(
    async (instructionsProp = instructions, onSave = () => {}) => {
      if (user && merchantSelected) {
        setSaveLoading(true)

        try {
          const dataToSave: MerchantGeneralInfoType = {
            name,
            initials,
            phone: phoneMask(phone),
            hasBentoDelivery: delivery === 'bento',
            isOpen: available,
            tags: tagsSelected.map((tag) => tag.name),
            category: categoriesSelected.map((cat) => cat.name).join(' • '),
            hasDelivery: servicesSelected.some(
              (service) => service.name === 'Delivery',
            ),
            hasTakeout: servicesSelected.some(
              (service) => service.name === 'Takeout',
            ),
            visible,
            locationInstructions: instructionsProp,
            availableAgainAt,
          }

          if (dataToSave.isOpen) {
            delete dataToSave.availableAgainAt
          }

          if (!logoUpload.imagePreview) {
            dataToSave.logo = ''
          }

          if (!pictureUpload.imagePreview) {
            dataToSave.picture = ''
          }

          if (logoUpload.file) {
            const { data: response } = await settingsService.merchantUpload(
              merchantSelected.id,
              logoUpload.file,
            )
            dataToSave.logo = response[0].url
          }

          if (pictureUpload.file) {
            const { data: response } = await settingsService.merchantUpload(
              merchantSelected.id,
              pictureUpload.file,
            )
            dataToSave.picture = response[0].url
          }

          const { data } = await settingsService.saveMerchantGeneralInfo(
            dataToSave,
            merchantSelected.id,
            user.uuid,
          )
          setMerchantInfo(data)
        } finally {
          if (onSave) onSave()
          setSaveLoading(false)
        }
      }
    },
    [
      available,
      tagsSelected,
      categoriesSelected,
      delivery,
      initials,
      logoUpload,
      merchantSelected,
      name,
      phone,
      pictureUpload,
      servicesSelected,
      user,
      visible,
      instructions,
      availableAgainAt,
    ],
  )

  useEffect(() => {
    setLogoUpload({ file: null, imagePreview: merchantInfo?.logo || '' })
    setPictureUpload({ file: null, imagePreview: merchantInfo?.picture || '' })
    setName(merchantInfo?.name || '')
    setInstructions(merchantInfo?.locationInstructions || '')
    setInitials(merchantInfo?.initials || '')
    setPhone(merchantInfo?.phone || '')
    const services: ServiceSelectedType[] = []
    if (merchantInfo?.hasDelivery) {
      services.push({ name: 'Delivery', value: 'delivery', selected: true })
    }
    if (merchantInfo?.hasTakeout) {
      services.push({ name: 'Takeout', value: 'takeout', selected: true })
    }
    setServicesSelected(services)
    setDelivery(merchantInfo?.hasBentoDelivery ? 'bento' : 'partner')
    setAvailable(merchantInfo?.isOpen || false)
    setVisible(merchantInfo?.visible || false)
    const merchantCategories =
      (merchantInfo?.category && merchantInfo?.category.split(' • ')) || []
    setCategoriesSelected(
      merchantCategories.map((item) => ({
        name: titleCase(item.toLowerCase()),
        value: item.toLowerCase(),
        selected: true,
      })),
    )
    const merchantTags = merchantInfo?.tags || []
    setTagsSelected(
      merchantTags.map((item) => ({
        name: titleCase(item.toLowerCase()),
        value: item.toLowerCase(),
        selected: true,
      })),
    )
  }, [merchantInfo])

  useEffect(() => {
    if (user && merchantSelected?.id) {
      getMerchantGeneralInfo(merchantSelected.id, user.uuid)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantSelected])

  useEffect(() => {
    if (merchantInfo && merchantInfo?.isOpen !== available) {
      handleSave()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [available])

  return isLoading ? (
    <FormSettingsLoading />
  ) : (
    <S.Wrapper>
      <Box flex JustifyContent="center" style={{ marginBottom: '1.5rem' }}>
        <S.ImageWrapper logo={logoUpload.imagePreview}>
          {admin && (
            <>
              <S.InputFile
                type="file"
                ref={fileLogoInputRef}
                onChange={(event) => handleUploadChange(event, true)}
                accept="image/png, image/jpeg"
                disabled={saveLoading}
              />
              <S.UploadButton onClick={() => handleUploadButton(true)}>
                <PhotoCameraIcon size="60" />
              </S.UploadButton>
            </>
          )}
        </S.ImageWrapper>
      </Box>

      <S.FormList>
        <S.FormListItem>
          <S.FormListItemTitle>Name</S.FormListItemTitle>
          {admin ? (
            <TextField
              variant="material"
              value={name}
              disabled={saveLoading}
              onChange={(event) => setName(event.currentTarget.value)}
            />
          ) : (
            <S.ItemLabel>{name}</S.ItemLabel>
          )}
        </S.FormListItem>
        <S.FormListItem>
          <S.FormListItemTitle>Merchant Initials</S.FormListItemTitle>
          {admin ? (
            <TextField
              variant="material"
              value={initials}
              disabled={saveLoading}
              onChange={(event) => setInitials(event.currentTarget.value)}
              maxLength={3}
            />
          ) : (
            <S.ItemLabel initials>{initials}</S.ItemLabel>
          )}
        </S.FormListItem>
        <S.FormListItem>
          <S.FormListItemTitle>Phone Number</S.FormListItemTitle>
          <TextField
            variant="material"
            value={phone}
            mask="phoneFormatted"
            disabled={saveLoading}
            onChange={(event) => setPhone(event.currentTarget.value)}
          />
        </S.FormListItem>
        <S.FormListItem onClick={() => admin && setOpenCategoryModal(true)}>
          <S.FormListItemTitle>Categories</S.FormListItemTitle>
          <Box flex gap="1rem">
            <S.ItemLabel>
              {categoriesSelected.map((cat) => cat.name).join(' | ')}
            </S.ItemLabel>
            {admin && (
              <S.Action
                onClick={() => setOpenCategoryModal(true)}
                disabled={saveLoading}
              >
                <S.ButtonIcon>
                  <img
                    src={editFilledIcon}
                    width={20}
                    height={20}
                    alt="Edit icon"
                  />
                </S.ButtonIcon>
              </S.Action>
            )}
          </Box>
        </S.FormListItem>
        {admin && (
          <S.FormListItem onClick={() => setOpenTagModal(true)}>
            <S.FormListItemTitle>Tags</S.FormListItemTitle>
            <Box flex gap="1rem">
              <S.ItemLabel>
                {tagsSelected.map((t) => t.name).join(' | ')}
              </S.ItemLabel>

              <S.Action
                onClick={() => setOpenTagModal(true)}
                disabled={saveLoading}
              >
                <S.ButtonIcon>
                  <img
                    src={editFilledIcon}
                    width={20}
                    height={20}
                    alt="Edit icon"
                  />
                </S.ButtonIcon>
              </S.Action>
            </Box>
          </S.FormListItem>
        )}
        <S.FormListItem onClick={() => admin && setOpenServiceModal(true)}>
          <S.FormListItemTitle>Services</S.FormListItemTitle>
          <Box flex gap="1rem">
            <S.ItemLabel>
              {servicesSelected.map((cat) => cat.name).join(' | ')}
            </S.ItemLabel>
            {admin && (
              <S.Action
                onClick={() => setOpenServiceModal(true)}
                disabled={saveLoading}
              >
                <S.ButtonIcon>
                  <img
                    src={editFilledIcon}
                    width={20}
                    height={20}
                    alt="Edit icon"
                  />
                </S.ButtonIcon>
              </S.Action>
            )}
          </Box>
        </S.FormListItem>
        <S.FormListItem onClick={() => handleAvailableChange(!available)}>
          <S.FormListItemTitle>Availability</S.FormListItemTitle>
          <Switch
            checked={available}
            id="availability"
            onChange={handleAvailableChange}
            disabled={saveLoading}
          />
        </S.FormListItem>
        <S.FormListItem onClick={() => admin && setVisible(!visible)}>
          <S.FormListItemTitle>Visibility</S.FormListItemTitle>
          <Switch
            checked={visible}
            id="visibility"
            onChange={setVisible}
            disabled={!admin || saveLoading}
          />
        </S.FormListItem>
        <S.FormListItem>
          <S.FormListItemTitle>Delivery</S.FormListItemTitle>
          {admin ? (
            <Box flex gap="1rem">
              <Radio
                label="Bento Delivery"
                labelFor="bento_delivery"
                id="bento_delivery"
                value="bento"
                name="delivery"
                checked={delivery === 'bento'}
                onCheck={() => setDelivery('bento')}
                disabled={saveLoading}
              />
              <Radio
                label="Partner Delivery"
                labelFor="partner_delivery"
                id="partner_delivery"
                value="partner"
                name="delivery"
                checked={delivery === 'partner'}
                onCheck={() => setDelivery('partner')}
                disabled={saveLoading}
              />
            </Box>
          ) : (
            <S.ItemLabel gray>
              {delivery === 'bento' ? 'Bento' : 'Partner'} Delivery
            </S.ItemLabel>
          )}
        </S.FormListItem>
        <S.FormListItem onClick={() => admin && setOpenLocationModal(true)}>
          <S.FormListItemTitle>Location instructions</S.FormListItemTitle>
          <Box flex gap="1rem">
            <S.ItemLabel>{instructions}</S.ItemLabel>
            {admin && (
              <S.Action
                onClick={() => setOpenLocationModal(true)}
                disabled={saveLoading}
              >
                <S.ButtonIcon>
                  <img
                    src={editFilledIcon}
                    width={20}
                    height={20}
                    alt="Edit icon"
                  />
                </S.ButtonIcon>
              </S.Action>
            )}
          </Box>
        </S.FormListItem>
      </S.FormList>

      <Box flex JustifyContent="center" style={{ marginBottom: '1.5rem' }}>
        <S.ImagePictureWrapper picture={pictureUpload.imagePreview}>
          {admin && (
            <>
              <S.InputFile
                type="file"
                ref={filePictureInputRef}
                onChange={(event) => handleUploadChange(event)}
                accept="image/png, image/jpeg"
                disabled={saveLoading}
              />
              <S.UploadPictureButton onClick={() => handleUploadButton()}>
                <PhotoCameraIcon size="60" />
                change hero
              </S.UploadPictureButton>
            </>
          )}
        </S.ImagePictureWrapper>
      </Box>

      <Box flex JustifyContent="end">
        <Button
          size="large"
          onClick={() => handleSave()}
          loading={saveLoading}
          disabled={saveLoading || canEnableSave()}
        >
          Save
        </Button>
      </Box>

      {openPictureCropModal && renderPictureModalCrop()}
      {openCategoryModal && renderOpenEditSettingsCategoryModal()}
      {openTagModal && renderOpenEditSettingsTagModal()}
      {openServiceModal && renderOpenEditSettingsServicesModal()}
      {openLocationModal && renderOpenLocationModal()}
      {openAvailabilityModal && renderOpenEditSettingsAvailabilityModal()}
    </S.Wrapper>
  )
}

import { GoogleMap, Libraries, useJsApiLoader } from '@react-google-maps/api'
import { ModeEditOutline as EditIcon } from '@styled-icons/material/ModeEditOutline'
import { useEffect, useState } from 'react'

import SettingsService from '@/api/settings.service'
import pinMap from '@/assets/img/pin-map.svg'
import { Modal, ModalContent } from '@/components/Modal'
import Skeleton from '@/components/Skeleton'
import { env } from '@/env'
import useAuth from '@/hooks/useAuth'
import useModal from '@/hooks/useModal'
import EditAddressModal from '@/modals/EditAddressModal'
import { AddressInfoType } from '@/types/settings.types'

import * as S from './styles'

export type Props = {
  admin: boolean
}

type LatLngType = {
  lat: number
  lng: number
}

const options: google.maps.MapOptions = {
  disableDefaultUI: true,
  mapTypeId: 'roadmap',
  draggable: false,
}

const libraries: Libraries = ['places', 'visualization']

export function Address({ admin }: Props) {
  const { user, merchantSelected } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal()
  const [addressInfo, setAddressInfo] = useState<AddressInfoType>()
  const [mapCenter, setMapCenter] = useState<LatLngType>({
    lat: 0,
    lng: 0,
  })

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: env.VITE_MAPS_API_KEY,
    libraries,
  })

  const getMerchantAddressInfo = async (merchantId: string, userId: string) => {
    setIsLoading(true)
    try {
      const { data } = await SettingsService.getMerchantAddressInfo(
        merchantId,
        userId,
      )
      setAddressInfo(data)
    } finally {
      setIsLoading(false)
    }
  }

  const openEditAddressModal = () => {
    if (admin) {
      setModalIsOpen(true)
      if (user && merchantSelected && modalIsOpen) {
        getMerchantAddressInfo(merchantSelected.id, user.uuid)
      }
    }
  }

  useEffect(() => {
    setMapCenter({
      lat: addressInfo?.address.coordinates.lat || 0,
      lng: addressInfo?.address.coordinates.lng || 0,
    })
  }, [addressInfo])

  useEffect(() => {
    if (user && merchantSelected) {
      getMerchantAddressInfo(merchantSelected.id, user.uuid)
    }
  }, [user, merchantSelected])

  const handleClose = () => {
    console.log('close')
    setTimeout(() => {
      setModalIsOpen(false)
    }, 50)
  }

  const renderEditAddressModal = () => {
    return (
      <Modal open={modalIsOpen} onOpenChange={setModalIsOpen} modal>
        <ModalContent
          title="Edit Address"
          onClose={handleClose}
          hideCloseButton
        >
          {addressInfo && (
            <EditAddressModal
              lat={mapCenter.lat}
              lng={mapCenter.lng}
              addressProp={addressInfo}
              close={(address) => {
                if (address) {
                  setMapCenter({
                    lat: address.coordinates.lat || 0,
                    lng: address.coordinates.lng || 0,
                  })
                }

                handleClose()
              }}
            />
          )}
        </ModalContent>
      </Modal>
    )
  }

  return (
    <S.ContentWrapper onClick={openEditAddressModal}>
      <S.AddressInfo>
        <S.Title>Address {admin && <EditIcon size={20} />}</S.Title>

        <S.AddressInfoText>
          {isLoading && <Skeleton width="6.25rem" />}
          {!isLoading && addressInfo?.address.formattedAddress}
        </S.AddressInfoText>
      </S.AddressInfo>

      <S.MapWrapper>
        {isLoading && <Skeleton width="100%" height="228px" />}
        {!isLoading && (
          <>
            <S.Marker src={pinMap} />
            {isLoaded && (
              <GoogleMap
                options={options}
                mapContainerStyle={{
                  width: '100%',
                  height: '228px',
                  display: 'block',
                }}
                zoom={18}
                center={mapCenter}
                clickableIcons={false}
              />
            )}
          </>
        )}
      </S.MapWrapper>

      {modalIsOpen && renderEditAddressModal()}
    </S.ContentWrapper>
  )
}

import { TextareaHTMLAttributes, useState } from 'react'

import * as S from './styles'

export type TextAreaProps = {
  onInput?: (value: string) => void
  label?: string
  labelFor?: string
  initialValue?: string
  disabled?: boolean
  resizable?: boolean
  error?: string
  variant?: 'default' | 'material'
} & TextareaHTMLAttributes<HTMLTextAreaElement>

const TextArea = ({
  label,
  labelFor = '',
  initialValue = '',
  error,
  disabled = false,
  resizable = false,
  onInput,
  variant = 'default',
  ...props
}: TextAreaProps) => {
  const [value, setValue] = useState(initialValue)

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget
    setValue(value)
    !!onInput && onInput(value)
  }

  return (
    <S.Wrapper disabled={disabled} error={!!error} variant={variant}>
      {!!label && <S.Label htmlFor={labelFor}>{label}</S.Label>}
      <S.TextAreaWrapper>
        <S.TextArea
          onChange={onChange}
          value={value}
          disabled={disabled}
          resizable={resizable}
          {...props}
        />
      </S.TextAreaWrapper>
      {!!error && <S.Error>{error}</S.Error>}
    </S.Wrapper>
  )
}

export default TextArea

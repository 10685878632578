import { useState } from 'react'

import { ModalOverflow } from '@/components/Modal'
import Toast from '@/components/Toast'
import { api } from '@/lib/axios'
import { OrderAddressType, UserInfoType } from '@/types/orders.types'

import { Address } from './components/Address'
import { SaveAddressType } from './components/Address/components/FormAddress'
import UserInfo from './components/UserInfo'
import * as S from './styles'

type CloseProfileType = {
  user: UserInfoType
  address: OrderAddressType
}

type ProfileModalProps = {
  user: UserInfoType
  address: OrderAddressType
  onCloseProfile: (data: CloseProfileType) => void
}

export const ProfileModal = ({
  user,
  address,
  onCloseProfile,
}: ProfileModalProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const onSaveProfile = async (
    user: UserInfoType,
    addresses: SaveAddressType,
  ) => {
    try {
      setIsLoading(true)

      await api.patch(`/users/${user.uuid}/profile`, {
        name: user.name,
        phoneNumber: user.phoneNumber,
      })

      Toast({
        title: 'Saved',
        message: 'The profile has been update.',
        type: 'success',
      })

      onCloseProfile({
        user,
        address: addresses.newAddress,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const onSaveAddress = async (
    user: UserInfoType,
    addresses: SaveAddressType,
  ) => {
    try {
      setIsLoading(true)

      await api.patch(`/users/${user.uuid}/address`, {
        ...addresses,
      })

      Toast({
        title: 'Saved',
        message: 'The address has been update.',
        type: 'success',
      })

      onCloseProfile({
        user,
        address: addresses.newAddress,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ModalOverflow>
      <S.Section>
        <S.HeaderTitle>Profile</S.HeaderTitle>
        <UserInfo
          user={user}
          onSaveUser={(name, phoneNumber) => {
            onSaveProfile(
              { ...user, name, phoneNumber },
              {
                newAddress: address,
                oldAddress: address,
              },
            )
          }}
          loading={isLoading}
        />
      </S.Section>

      <S.Section>
        <Address
          address={address}
          onSaveAddress={(addresses) => {
            onSaveAddress(user, addresses)
          }}
          loading={isLoading}
        />
      </S.Section>
    </ModalOverflow>
  )
}

import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import Button from '@/components/Button'
import { Wrapper as SelectButtonWrapper } from '@/components/SelectButton/styles'
import { InputWrapper } from '@/components/TextField/styles'

export const Wrapper = styled.main`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    grid-gap: ${theme.spacings.small};
    padding: ${theme.spacings.small};

    ${media.lessThan('large')`
      grid-template-columns: 1.2fr 40%;
    `}

    ${media.lessThan('medium')`
      grid-template-columns: 1fr;
    `}
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.large};
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.mainBg};
    border-radius: ${theme.border.radius.large};
  `}
`

export const MapContent = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.large};
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.mainBg};
    border-radius: ${theme.border.radius.large};
  `}
`

export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.nunito};
    font-weight: ${theme.font.extraBold};
    font-size: ${theme.font.sizes.xlarge};
    color: ${theme.colors.primary};
  `}
`

export const Image = styled.img`
  ${({ theme }) => css`
    margin: 0 ${theme.spacings.xxsmall};
  `}
`

export const Name = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.font.regular};
    flex: 1;
  `}
`

export const MenuForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  /* ${media.lessThan('medium')`
    flex-direction: column;
  `} */
`

export const Hr = styled.hr`
  margin: 1.375rem 0;
  border: none;
  border-bottom: 1px solid #dcdcdc;
`

export const Days = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${SelectButtonWrapper} {
    margin-right: 0.625rem;
    margin-bottom: 0.625rem;
  }
`

export const Hours = styled.div`
  display: flex;
  align-items: center;
`

export const Hour = styled.div<{ first?: boolean }>`
  ${({ theme, first = false }) => css`
    ${InputWrapper} {
      ${first &&
      css`
        margin-right: ${theme.spacings.xxsmall} !important;
      `}
    }
  `}
`

export const ButtonAdd = styled(Button)`
  ${({ theme }) => css`
      font-weight: ${theme.font.medium};
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.gray};
      border-bottom: 1px solid #979797;
      border-radius: 0;
      padding: 0 0.25rem;
      margin-top: ${theme.spacings.xsmall};
    }
  `}
`

export const SavedItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SavedItemContainer = styled.div`
  display: flex;

  ${media.lessThan('medium')`
      flex-direction: column;
  `}
`

export const SavedItem = styled.div`
  ${({ theme }) => css`
    width: 100%;

    h5 {
      font-weight: ${theme.font.bold};
      font-size: ${theme.font.sizes.medium};
      margin-bottom: 0.5rem;
    }

    /* ${media.greaterThan('medium')`
      min-width: 18.75rem;
    `} */

    ${media.lessThan('medium')`
      &:last-child {
        margin-top: 1.25rem;
      }
    `}
  `}
`

export const SavedDescription = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.font.medium};
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.gray};
  `}
`

export const Actions = styled.div`
  ${media.lessThan('medium')`
      min-width: 5rem;
  `}
`

export const ButtonIcon = styled(Button)`
  padding: 0.5rem;
`

import { darken } from 'polished'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    font-size: ${theme.font.sizes.medium};
    padding: 0.5rem;
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
    border-radius: ${theme.border.radius.medium};
    border: 1px solid ${theme.colors.gray};
  `}
`

export const Text = styled.span`
  padding: 0 0.25rem 0.125rem;
`

export const Close = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: ${theme.colors.gray};
    border: 0;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      color: ${darken(0.1, theme.colors.gray)};
    }
  `}
`

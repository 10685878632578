import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal, ModalContent } from '@/components/Modal'
import Radio from '@/components/Radio'
import useModal from '@/hooks/useModal'
import {
  FieldContent,
  FieldError,
  FieldHeader,
  FieldHeaderTitle,
  FieldWrapper,
} from '@/modals/CreatingNewCampaignModal/styles'
import { CustomVoucherModal } from '@/modals/CustomVoucherModal'
import { VoucherType } from '@/types/super-partner.types'

import * as S from './styles'

export type VouchersFieldProps = {
  selectedCount?: number
  vouchers?: VoucherType
  error?: string
  before?: boolean
  isInitialDataCustom?: boolean
  onChange: (value?: VoucherType) => void
}

export const VouchersField = ({
  error,
  onChange,
  vouchers,
  selectedCount,
  before,
  isInitialDataCustom = false,
}: VouchersFieldProps) => {
  const [isCustomVoucher, setIsCustomVoucher] = useState(isInitialDataCustom)
  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal()
  const { t } = useTranslation()

  const renderCustomVoucherModal = () => (
    <Modal open={modalIsOpen} onOpenChange={setModalIsOpen} modal>
      <ModalContent
        title="Custom Voucher"
        onClose={() => setModalIsOpen(false)}
        onOpenAutoFocus={(event) => event.preventDefault()}
        width="auto"
        hideCloseButton
      >
        <CustomVoucherModal
          vouchers={vouchers}
          selectedCount={selectedCount}
          close={(data) => {
            if (data) {
              onChange(data)
              setIsCustomVoucher(true)
            } else {
              setIsCustomVoucher(false)
            }
            setModalIsOpen(false)
          }}
        />
      </ModalContent>
    </Modal>
  )

  useEffect(() => {
    if (before) {
      const element = document.getElementById('vouchers')
      if (element) {
        element.scrollIntoView({ block: 'center', behavior: 'smooth' })
      }
    }
  }, [before])

  return (
    <FieldWrapper before={before ? 1 : 0} id="vouchers">
      <FieldHeader>
        <FieldHeaderTitle>Vouchers</FieldHeaderTitle>
      </FieldHeader>

      <FieldContent>
        <S.OptionsList>
          <S.OptionItem>
            <Radio
              label="$3 off per order - 50 vouchers"
              labelFor="vouchers3"
              id="vouchers3"
              onChange={() => {
                setIsCustomVoucher(false)
                onChange({
                  voucherShift: 300,
                  voucherQuantity: 50,
                  totalSpent: 200 * 50,
                })
              }}
              checked={vouchers?.voucherShift === 300 && !isCustomVoucher}
            />
          </S.OptionItem>
          <S.OptionItem>
            <Radio
              label="$5 off per order - 50 vouchers"
              labelFor="vouchers5"
              id="vouchers5"
              onChange={() => {
                setIsCustomVoucher(false)
                onChange({
                  voucherShift: 500,
                  voucherQuantity: 50,
                  totalSpent: 500 * 50,
                })
              }}
              checked={vouchers?.voucherShift === 500 && !isCustomVoucher}
            />
          </S.OptionItem>
          <S.OptionItem>
            <Radio
              label="$10 off per order - 50 vouchers"
              labelFor="vouchers10"
              id="vouchers10"
              onChange={() => {
                setIsCustomVoucher(false)
                onChange({
                  voucherShift: 1000,
                  voucherQuantity: 50,
                  totalSpent: 1000 * 50,
                })
              }}
              checked={vouchers?.voucherShift === 1000 && !isCustomVoucher}
            />
          </S.OptionItem>
          <S.OptionItem>
            <Radio
              label={`Custom Vouchers ${
                isCustomVoucher && vouchers
                  ? `($${vouchers?.voucherShift / 100} off per order - ${
                      vouchers?.voucherQuantity
                    } ${t(
                      'modals.creating-new-campaign.vouchersField.custom.label.voucherQuantity',
                      {
                        count: vouchers?.voucherQuantity,
                      },
                    )})`
                  : ''
              }`}
              labelFor="vouchersCustom"
              id="vouchersCustom"
              labelColor="red"
              onClick={() => setModalIsOpen(true)}
              onChange={() => {
                onChange(undefined)
              }}
              checked={isCustomVoucher && vouchers !== undefined}
            />
          </S.OptionItem>

          {error && <FieldError>{error}</FieldError>}
        </S.OptionsList>
      </FieldContent>

      {modalIsOpen && renderCustomVoucherModal()}
    </FieldWrapper>
  )
}

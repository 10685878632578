import styled, { css } from 'styled-components'

import {
  MenuItem,
  MenuText as DropdownMenuText,
} from '../../components/DropdownMenu'
import { InputWrapper } from '../../components/TextField/styles'

export const Wrapper = styled.div``

export const NoResult = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.quicksand};
    font-weight: ${theme.font.semiBold};
    color: ${theme.colors.lightGray};
    padding: ${theme.spacings.xsmall} ${theme.spacings.xxsmall}
      ${theme.spacings.xxsmall};
    text-align: center;
  `}
`

export const TextFieldWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0.5rem 0.5rem 0;
    margin-bottom: 0.25rem;

    ${InputWrapper} {
      border-color: ${theme.colors.mainBg};

      &:focus-within {
        border-color: ${theme.colors.lightGray};
      }
    }

    .tooltip {
      border-radius: 0.5rem;
      font-family: ${theme.font.quicksand} !important;
      font-size: ${theme.font.sizes.xsmall} !important;
    }
  `}
`

export const ListOverflow = styled.div`
  overflow-y: auto;
  max-height: 25rem;
`

export const MenuItemNew = styled(MenuItem)`
  ${({ theme }) => css`
    justify-content: center;
    color: ${theme.colors.primary};
  `}
`

export const MenuText = styled(DropdownMenuText)`
  display: flex;
  justify-content: space-between;

  span {
    display: block;
    width: 9.375rem;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Ellipsis = styled.div`
  ${({ theme }) => css`
    display: block;
    font-size: 2.5rem;
    line-height: 0;
    text-align: center;
    height: 10px;
    margin-top: -5px;
    color: ${theme.colors.body};
  `}
`

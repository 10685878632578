import {
  HighlightOff as CloseIcon,
  Logout as LogoutIcon,
  Search as SearchIcon,
} from '@styled-icons/material-outlined'
import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import Avatar from '@/components/Avatar'
import AvatarGroup from '@/components/AvatarGroup'
import Button from '@/components/Button'
import Checkbox from '@/components/Checkbox'
import Dropdown from '@/components/Dropdown'
import {
  MenuIcon,
  MenuItem,
  MenuList,
  MenuText,
} from '@/components/DropdownMenu'
import { Animation as LoadingAnimation } from '@/components/Loading/styles'
import { Modal, ModalContent } from '@/components/Modal'
import TextField from '@/components/TextField'
import useAuth from '@/hooks/useAuth'
import useModal from '@/hooks/useModal'
import CreateMerchantModal from '@/modals/CreateMerchantModal'
import { MerchantDetailsType, MerchantType } from '@/types/merchant.types'

import * as S from './styles'

export type MerchantDropdownProps = {
  loading?: boolean
}

const MerchantDropdown = ({ loading = false }: MerchantDropdownProps) => {
  const [loadingLogout, setLoadingLogout] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [filteredMerchants, setFilteredMerchants] = useState<
    MerchantDetailsType[]
  >([])
  const [filterText, setFilterText] = useState('')
  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal()
  const {
    Logout,
    merchants,
    merchantSelected,
    merchantsSelected,
    onSetMerchantSelected,
    onSetMerchantsSelected,
    isOneMerchantSelectedPages,
    isSuperAdmin,
  } = useAuth()

  const handleFilterChange = (
    search: string,
    merchants: MerchantDetailsType[],
  ) => {
    if (search) {
      const menusFiltered = merchants.filter(
        ({ name }) => name && name.toLowerCase().includes(search.toLowerCase()),
      )
      setFilteredMerchants([...menusFiltered])
    } else {
      setFilteredMerchants(merchants)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(_.debounce(handleFilterChange, 300), [])

  const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setFilterText(value)
  }

  const handleClear = () => {
    setFilterText('')
    debouncedOnChange('', merchants)
  }

  const handleSelectedMerchant = (merchantId: string, close: () => void) => {
    setIsLoading(true)
    !loading &&
      onSetMerchantSelected(merchantId, () => {
        setIsLoading(false)
      })
    close()
    handleClear()
  }

  const renderCreateMerchantModal = () => {
    return (
      <Modal open={modalIsOpen} onOpenChange={setModalIsOpen} modal>
        <ModalContent
          title="Create Merchant"
          onClose={() => setModalIsOpen(false)}
        >
          <CreateMerchantModal
            onClose={() => {
              close()
              handleClear()
              setModalIsOpen(false)
            }}
          />
        </ModalContent>
      </Modal>
    )
  }

  useEffect(() => {
    if (merchants.length > 0) {
      debouncedOnChange(filterText, merchants)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, merchants])

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  return (
    <S.Wrapper>
      <Dropdown
        title={
          <S.AvatarContainer>
            {merchantsSelected.length > 1 ? (
              <AvatarGroup<MerchantType>
                items={merchantsSelected}
                id="id"
                text="name"
                image="logo"
                loading={loading}
              />
            ) : (
              <Avatar
                text={merchantSelected?.name.slice(0, 1).toUpperCase()}
                image={merchantSelected?.logo}
                loading={loading || isLoading}
              />
            )}
            {merchants.length > 1 && <S.Ellipsis>...</S.Ellipsis>}
          </S.AvatarContainer>
        }
      >
        {(close) => (
          <MenuList width="15.625rem">
            {merchants.length > 5 && (
              <S.TextFieldWrapper>
                <TextField
                  type="text"
                  name="search_menu"
                  placeholder="Search by name"
                  disabled={loading || merchants.length === 0}
                  value={filterText}
                  icon={
                    filterText ? (
                      <>
                        <Button
                          onClick={handleClear}
                          icon={<CloseIcon />}
                          style={{ padding: 0 }}
                          data-tip="Clear search"
                          minimal
                        />
                        <ReactTooltip
                          effect="solid"
                          className="tooltip"
                          backgroundColor="#433d3d"
                          borderColor="#433d3d"
                        />
                      </>
                    ) : (
                      <SearchIcon />
                    )
                  }
                  iconPosition="right"
                  onChange={handleChangeFilter}
                />
              </S.TextFieldWrapper>
            )}

            <S.ListOverflow>
              {filteredMerchants.length === 0 ? (
                <>
                  <S.NoResult>No results found.</S.NoResult>

                  {isSuperAdmin && (
                    <S.MenuItemNew onClick={() => setModalIsOpen(true)}>
                      <MenuText>Create new</MenuText>
                    </S.MenuItemNew>
                  )}
                </>
              ) : (
                _.orderBy(filteredMerchants, 'selected', 'desc').map(
                  (merchant, index) => (
                    <MenuItem
                      key={`${merchant.id}-${index}`}
                      disabled={loading}
                    >
                      <MenuIcon
                        onClick={() =>
                          handleSelectedMerchant(merchant.id, close)
                        }
                      >
                        <Avatar
                          text={merchant.name.slice(0, 1).toUpperCase()}
                          image={merchant.logo}
                          size={1.25}
                        />
                      </MenuIcon>{' '}
                      <S.MenuText style={{ width: '100%' }}>
                        <span
                          title={merchant.name}
                          onClick={() =>
                            handleSelectedMerchant(merchant.id, close)
                          }
                        >
                          {merchant.name}
                        </span>
                        {!isOneMerchantSelectedPages && (
                          <Checkbox
                            checked={merchant.selected}
                            onCheck={() => onSetMerchantsSelected(merchant.id)}
                            disabled={loading}
                          />
                        )}
                      </S.MenuText>
                    </MenuItem>
                  ),
                )
              )}
            </S.ListOverflow>

            <MenuItem
              onClick={() => {
                !loading && setLoadingLogout(true)
                !loading && Logout()
              }}
              disabled={loading}
            >
              {loadingLogout ? (
                <>
                  <MenuIcon>
                    <LoadingAnimation />
                  </MenuIcon>{' '}
                  Logging out...
                </>
              ) : (
                <>
                  <MenuIcon>
                    <LogoutIcon width="32" />
                  </MenuIcon>{' '}
                  Logout
                </>
              )}
            </MenuItem>
          </MenuList>
        )}
      </Dropdown>

      {modalIsOpen && isSuperAdmin && renderCreateMerchantModal()}
    </S.Wrapper>
  )
}

export default MerchantDropdown

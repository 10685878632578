import { useState } from 'react'

import { Box } from '@/components/Box'
import Radio from '@/components/Radio'
import TextField from '@/components/TextField'
import {
  FieldContent,
  FieldError,
  FieldHeader,
  FieldHeaderTitle,
  FieldWrapper,
} from '@/modals/CreatingNewCampaignModal/styles'
import { formatValueNumber } from '@/utils/formats/number'

import * as S from './styles'

export type MinimumOrderFieldProps = {
  value?: number
  error?: string
  isInitialDataCustom?: boolean
  onChange: (value?: number) => void
}

export const MinimumOrderField = ({
  error,
  onChange,
  isInitialDataCustom = false,
  value,
}: MinimumOrderFieldProps) => {
  const [isCustomMinimumOrder, setIsCustomMinimumOrder] =
    useState(isInitialDataCustom)

  return (
    <FieldWrapper>
      <FieldHeader>
        <FieldHeaderTitle>Minimum order amount</FieldHeaderTitle>
      </FieldHeader>

      <FieldContent>
        <S.OptionsList>
          <S.OptionItem>
            <Radio
              label="KYD 10.00"
              labelFor="minimumOrder10"
              id="minimumOrder10"
              onChange={() => {
                setIsCustomMinimumOrder(false)
                onChange(1000)
              }}
              checked={value === 1000 && !isCustomMinimumOrder}
            />
          </S.OptionItem>
          <S.OptionItem>
            <Radio
              label="KYD 20.00"
              labelFor="minimumOrder20"
              id="minimumOrder20"
              onChange={() => {
                setIsCustomMinimumOrder(false)
                onChange(2000)
              }}
              checked={value === 2000 && !isCustomMinimumOrder}
            />
          </S.OptionItem>
          <S.OptionItem>
            <Radio
              label="KYD 30.00"
              labelFor="minimumOrder30"
              id="minimumOrder30"
              onChange={() => {
                setIsCustomMinimumOrder(false)
                onChange(3000)
              }}
              checked={value === 3000 && !isCustomMinimumOrder}
            />
          </S.OptionItem>
          <S.OptionItem>
            <Box flex alignItems="baseline" gap="1.5rem">
              <Radio
                label="Custom"
                labelFor="customMinimumOrder"
                id="customMinimumOrder"
                labelColor="red"
                onClick={() => setIsCustomMinimumOrder(true)}
                onChange={() => onChange(value)}
                checked={isCustomMinimumOrder}
              />

              {isCustomMinimumOrder && (
                <TextField
                  variant="material"
                  placeholder="Enter custom budget"
                  mask="money"
                  onChange={(e) => onChange(formatValueNumber(e.target.value))}
                  value={String(value || 0)}
                />
              )}
            </Box>
          </S.OptionItem>

          {error && <FieldError>{error}</FieldError>}
        </S.OptionsList>
      </FieldContent>
    </FieldWrapper>
  )
}

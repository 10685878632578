import { Image as ImageIcon } from '@styled-icons/material-outlined/Image'
import { useCallback, useEffect, useState } from 'react'

import deleteIconDark from '@/assets/img/icon-delete-dark.svg'
import { Box } from '@/components/Box'
import Checkbox from '@/components/Checkbox'
import Select from '@/components/Select'
import useAuth from '@/hooks/useAuth'
import { api } from '@/lib/axios'
import {
  FieldContent,
  FieldError,
  FieldHeader,
  FieldHeaderDescription,
  FieldHeaderTitle,
  FieldWrapper,
} from '@/modals/CreatingNewCampaignModal/styles'
import { ItemType } from '@/types/menu-maker.types'

import * as S from './styles'

export type ItemsFieldProps = {
  value?: string[]
  error?: string
  before?: boolean
  onChange: (value: string[]) => void
  onSelectedCount?: (value: number) => void
  onSelectPercentage?: (value: number) => void
  onAudiencePrice?: (value: number) => void
  autoSelectAudience?: string
  campaignType?: string
}

export type ItemSelectedType = ItemType & {
  selected: boolean
}

export const ItemsField = ({
  error,
  onChange,
  // value,
  before,
}: ItemsFieldProps) => {
  const [items, setItems] = useState<ItemSelectedType[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const { merchantSelected } = useAuth()

  const selectsItems: ItemSelectedType[] = items.filter((item) => item.selected)

  const handleSelectItem = useCallback(
    (itemSelected: ItemSelectedType | null) => {
      console.log(itemSelected)
      if (!itemSelected) return

      const newItems = items.map((item) => {
        if (item.itemId === itemSelected.itemId) {
          return { ...item, selected: !item.selected }
        }
        return item
      })

      const ids = newItems
        .filter((item) => {
          if (item.selected) return item
          return false
        })
        .map((item) => item.itemId)

      setItems(newItems)
      onChange(ids)
    },
    [items, onChange],
  )

  async function getItems(merchantId: string) {
    try {
      const { data } = await api.get(`/menu-maker/${merchantId}/items/`)

      const filtered = data.filter((item: ItemSelectedType) => {
        return item.isSellable
      })

      setItems(filtered)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (merchantSelected) {
      getItems(merchantSelected.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FieldWrapper before={before ? 1 : 0} id="audiences">
      <FieldHeader items={1}>
        <FieldHeaderTitle>Add items to voucher</FieldHeaderTitle>
        <FieldHeaderDescription>
          Choose the items required for the order to validade the voucher
        </FieldHeaderDescription>
      </FieldHeader>

      <FieldContent>
        <S.AudienceContent>
          <S.FieldContentSelect>
            <Select<ItemSelectedType>
              options={items}
              variant="material"
              formatOptionLabel={(option) => (
                <Box flex alignItems="center" width="100%">
                  <Box
                    flex
                    alignItems="center"
                    JustifyContent="space-between"
                    gap="0.8rem"
                    width="100%"
                  >
                    <Box flex alignItems="center" gap="1rem">
                      <Checkbox checked={option.selected} />
                      {option.featuredPicture ? (
                        <S.ItemImage
                          width={36}
                          height={36}
                          src={option.featuredPicture}
                        />
                      ) : (
                        <S.ItemNoImage>
                          <ImageIcon size={24} />
                        </S.ItemNoImage>
                      )}
                      <span>{option.name}</span>
                    </Box>
                    <S.ItemPrice>KYD {option.price / 100}</S.ItemPrice>
                  </Box>
                </Box>
              )}
              placeholder="Select items..."
              controlShouldRenderValue={false}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              captureMenuScroll={false}
              value={items}
              onChange={(value) => handleSelectItem(value)}
              isLoading={isLoading}
              components={{
                LoadingIndicator: () => <S.LoadingSpinner />,
              }}
              loadingMessage={() => 'Loading...'}
            />
            {error && <FieldError>{error}</FieldError>}

            {selectsItems.length > 0 && (
              <Box
                flex
                gap="1rem"
                flexWrap="wrap"
                style={{ margin: '2.4rem 0 1rem' }}
              >
                {selectsItems.map((item) => (
                  <S.ItemList
                    flex
                    alignItems="center"
                    JustifyContent="space-between"
                    gap="1rem"
                    width="100%"
                    key={item.itemId}
                  >
                    <Box flex alignItems="center" gap="1rem">
                      {item.featuredPicture ? (
                        <S.ItemImage
                          width={36}
                          height={36}
                          src={item.featuredPicture}
                        />
                      ) : (
                        <S.ItemNoImage>
                          <ImageIcon size={24} />
                        </S.ItemNoImage>
                      )}
                      <span>{item.name}</span>
                    </Box>
                    <S.DeleteButton
                      data-tip="Delete"
                      onClick={() => handleSelectItem(item)}
                    >
                      <img src={deleteIconDark} alt="Delete icon dark" />
                    </S.DeleteButton>
                  </S.ItemList>
                ))}
              </Box>
            )}
          </S.FieldContentSelect>
        </S.AudienceContent>
      </FieldContent>
    </FieldWrapper>
  )
}

import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { ModalFooter, ModalOverflow } from '@/components/Modal'
import TextField from '@/components/TextField'
import { LoyaltyFormData } from '@/modals/CreatingNewCampaignModal/components/LoyaltySteps/LoyaltyForm'
import { formatValueNumber } from '@/utils/formats/number'

import * as S from './styles'

export type CustomCampaignLoyaltyModalProps = {
  campaign?: LoyaltyFormData['campaign']
  close: (data: LoyaltyFormData['campaign'] | null) => void
}

const customCampaignLoyaltyFormSchema = z.object({
  reward: z
    .string({
      required_error: 'Campaign reward is required',
    })
    .min(1, 'Campaign reward is required'),
  orders: z
    .string({
      required_error: 'Campaign orders is required',
    })
    .min(1, 'Campaign orders is required')
    .refine((data) => data !== '0', {
      message: 'Campaign orders must be greater than 0',
    })
    .refine((data) => +data <= 20, {
      message: 'Campaign orders must be less than 20',
    }),
})

export type CustomCampaignLoyaltyFormData = z.infer<
  typeof customCampaignLoyaltyFormSchema
>

export const CustomCampaignLoyaltyModal = ({
  close,
  campaign,
}: CustomCampaignLoyaltyModalProps) => {
  const { handleSubmit, control } = useForm<CustomCampaignLoyaltyFormData>({
    resolver: zodResolver(customCampaignLoyaltyFormSchema),
    defaultValues: {
      reward: campaign ? String(campaign?.reward) : undefined,
      orders: campaign ? String(campaign?.orders) : undefined,
    },
  })

  const onSubmit = (values: CustomCampaignLoyaltyFormData) => {
    const { reward, orders } = values

    const data = {
      orders: Number(orders.replace(/\D/g, '')),
      reward: formatValueNumber(reward),
    }

    close(data)
  }

  return (
    <S.Form>
      <ModalOverflow>
        <S.WrapperContent>
          <S.FieldWrapper>
            <S.FieldHeader>
              <S.FieldHeaderTitle>Set a campaign</S.FieldHeaderTitle>
            </S.FieldHeader>

            <S.FieldContent>
              <Controller
                name="reward"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    variant="material"
                    label="Campaign reward"
                    labelFor="campaignReward"
                    id="campaignReward"
                    placeholder="$"
                    inputMode="numeric"
                    mask="money"
                    error={fieldState.error?.message}
                    {...field}
                  />
                )}
              />

              <Controller
                name="orders"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    type="number"
                    variant="material"
                    label="How many orders? (max 20)"
                    labelFor="campaignOrders"
                    id="campaignOrders"
                    placeholder="0"
                    inputMode="numeric"
                    mask="numberClean"
                    maxLength={3}
                    error={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
            </S.FieldContent>
          </S.FieldWrapper>
        </S.WrapperContent>
      </ModalOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
        >
          <Button size="large" color="white" onClick={() => close(null)} shadow>
            Cancel
          </Button>
          <Button size="large" onClick={handleSubmit(onSubmit)}>
            Continue
          </Button>
        </Box>
      </ModalFooter>
    </S.Form>
  )
}

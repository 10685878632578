import * as S from './styles'

export type CardContentProps = {
  children: React.ReactNode
  color?: 'primary' | 'default'
  maxHeight?: string
  maxWidth?: string
  style?: React.CSSProperties
}

const CardContent = ({
  children,
  color = 'default',
  ...props
}: CardContentProps) => (
  <S.Wrapper color={color} {...props}>
    {children}
  </S.Wrapper>
)

export default CardContent

import { darken, lighten } from 'polished'
import styled, { css } from 'styled-components'

export const FormControl = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: ${theme.spacings.xxsmall} 0;

    span {
      color: ${theme.dark
        ? darken(0.2, theme.colors.gray)
        : lighten(0.13, theme.colors.gray)};
      font-size: ${theme.font.sizes.small};
      margin-left: ${theme.spacings.small};
    }
  `}
`

export const Error = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.red};
  `}
`

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DropdownMenuItem } from '@iguanads/react'
import * as Sentry from '@sentry/browser'
import { format } from 'date-fns'
import _ from 'lodash'
import { Fragment, useCallback, useRef, useState } from 'react'
import {
  Br,
  Cut,
  Image,
  Line,
  Printer,
  render,
  Row,
  Text,
} from 'react-thermal-printer'
import { titleCase } from 'title-case'

import menuMakerService from '@/api/menu-maker.service'
import { MenuIcon } from '@/components/DropdownMenu'
import { Animation as LoadingAnimation } from '@/components/Loading/styles'
import Toast from '@/components/Toast'
import { OrderStatusEnum } from '@/enums/order.enum'
import useAuth from '@/hooks/useAuth'
import { CategoryType, ItemType } from '@/types/menu-maker.types'
import { OrderType } from '@/types/orders.types'
import { formatCurrency } from '@/utils/formats/number'

import { LOGO, SERVICE, WRITE } from './printer.utils'

const BENTO_PACKAGE_MERCHANT_ID = 'aMpH5VC0j6iWA9hRLbdR'

const replaceText = (text: string) => {
  text = text.replace(
    /([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g,
    '',
  )
  text = text.replaceAll('\t\n', '')
  return text
}

const replaceName = (name: string) => {
  const nameArray = name.indexOf(' ') > 0 ? name.split(' ') : [name]
  name = `${nameArray[0]} ${nameArray[1] || ''}`
  return name
}

type OrderPrintButtonProps = {
  order: OrderType
  close: () => void
  onOrderStatusChange: (status: string) => void
}

export const OrderPrintButton = ({
  order,
  close,
  onOrderStatusChange,
}: OrderPrintButtonProps) => {
  const [device, setDevice] = useState<any>(null)
  const { onPrintCharacteristic, printCharacteristic } = useAuth()
  const toastPrintLoading = useRef<string | number | null>(null)

  const isBentoPackageOrder = order?.merchant?.id === BENTO_PACKAGE_MERCHANT_ID

  const handleError = useCallback(
    (error: any) => {
      console.log(error)
      device?.gatt && device?.gatt?.disconnect()
      onPrintCharacteristic(null)
      Toast.dismiss(toastPrintLoading.current!)
      close()
      Sentry.captureException(error)
    },
    [close, device, onPrintCharacteristic],
  )

  const handlePrintError = useCallback(
    (error: any) => {
      console.log(error)
      onPrintCharacteristic(null)
      device?.gatt && device?.gatt?.disconnect()
      Toast.dismiss(toastPrintLoading.current!)
      Sentry.captureException(error)
      if (error?.code === 9) {
        Toast({
          title: 'Error',
          message: 'Verify that the printer is ON and connected',
        })
      } else {
        Toast({
          title: 'Error',
          message: 'Error printing order',
        })
      }
    },
    [device?.gatt, onPrintCharacteristic],
  )

  const getOrderTextVersion = useCallback(
    async (order: OrderType) => {
      let categories: CategoryType[] = []
      let items: ItemType[] = []
      const merchantId = order?.merchant?.id || order.merchantId
      const hasItems = order?.items && order?.items.length > 0
      const hasItemsName = hasItems && order?.items?.some((item) => item.name)
      const hasCategoriesName =
        hasItems && order?.items?.some((item) => item.categoryName)

      if (!hasItemsName || !hasCategoriesName) {
        await Promise.all([
          menuMakerService.getCategories(merchantId as string),
          menuMakerService.getItems(merchantId as string),
        ]).then((res) => {
          categories = res[0].data
          items = res[1].data
        })
      }

      const itemsToPrint =
        order?.items &&
        order?.items.map((item) => {
          const options = item.modifiers.flatMap((modifier) => modifier.options)
          let categoryName = item.categoryName || ''
          let newOptions = options

          if (!item.categoryName) {
            const category = categories.find((category) =>
              category.items?.includes(item.itemId),
            )

            categoryName = category?.name || ''
          }

          newOptions = options.map((option) => {
            if (!option.name) {
              const item = items.find((item) => item.itemId === option.itemId)

              return {
                ...option,
                name: item?.name || '',
              }
            }

            return option
          })

          return {
            categoryName,
            name: item.name,
            comments: item.comments,
            qty: item.qty,
            price: item.price,
            itemId: item.itemId,
            options: newOptions.map((option) => ({
              name: option.name,
              qty: option.qty,
              price: option.price,
              itemId: option.itemId,
            })),
          }
        })

      return (
        itemsToPrint &&
        itemsToPrint.length > 0 &&
        _.orderBy(itemsToPrint, 'categoryName')?.map((item) => (
          <Fragment key={item.itemId}>
            {isBentoPackageOrder && <Text>Bento Package</Text>}
            <Text>{replaceText(item?.categoryName)?.toUpperCase()}</Text>
            <Row
              left={
                <Text underline="2dot-thick">{`* ${item.qty}x ${titleCase(
                  replaceText(item.name.toLowerCase()),
                )}`}</Text>
              }
              right={
                <Text bold={true}>{formatCurrency(item.qty * item.price)}</Text>
              }
              gap={2}
            />
            {item.options &&
              item.options.map((option, index) => {
                return (
                  <Row
                    key={`${option.itemId}-${index}`}
                    left={
                      <Text size={{ width: 1, height: 1 }}>{`    + ${
                        option.qty
                      }x ${titleCase(
                        replaceText(option?.name?.toLowerCase()),
                      )}`}</Text>
                    }
                    right={
                      <Text bold={true} size={{ width: 1, height: 1 }}>
                        {formatCurrency(item.qty * (option.price * option.qty))}
                      </Text>
                    }
                    gap={2}
                  />
                )
              })}
            {item?.comments && <Text>{`> ${replaceText(item.comments)}`}</Text>}
            {isBentoPackageOrder && (
              <>
                <Br />
                <Text>PICK-UP Address:</Text>
                <Text>{order.pickupAddress?.description}</Text>
                <Text>
                  {order.pickupAddress?.streetNumber}{' '}
                  {order.pickupAddress?.streetName}
                </Text>
                <Text>{order.pickupAddress?.extraInfo}</Text>
                <Text>{order.pickupAddress?.instructions}</Text>
              </>
            )}
            <Line />
          </Fragment>
        ))
      )
    },
    [isBentoPackageOrder],
  )

  const printData = useCallback(
    async (characteristic?: any) => {
      try {
        const createDate =
          order?.in_progress &&
          (order?.in_progress?._seconds || order?.in_progress?.seconds)
            ? new Date(
                (order?.in_progress?._seconds || order?.in_progress?.seconds) *
                  1000,
              )
            : new Date()
        const createdAt = format(createDate, 'E, MMM dd, yyyy h:mm:ss a')
        const orderType =
          order?.type && order?.type.length > 0 ? titleCase(order.type) : ''

        const receipt = (
          <Printer type="epson" width={32} characterSet="wpc1252">
            <Image align="center" src={LOGO} />
            <Br />
            <Br />
            <Text align="center">------------ ORDER -------------</Text>
            <Text align="center" size={{ width: 2, height: 2 }}>
              {order?.shortId}
            </Text>
            <Line />
            <Text bold={true}>{createdAt}</Text>
            <Br />
            <Row
              left="Order Type"
              right={<Text bold={true}>{orderType}</Text>}
              gap={2}
            />
            <Line />
            <Row
              left="Customer Name"
              right={
                <Text bold={true}>
                  {replaceText(replaceName(order?.user.name || ''))}
                </Text>
              }
              gap={2}
            />
            <Row
              left="Phone Number"
              right={<Text bold={true}>{order?.user.phoneNumber}</Text>}
              gap={2}
            />
            <Line />
            <Text bold={true}>Items</Text>
            <Br />
            {await getOrderTextVersion(order!)}
            <Row
              left="Subtotal"
              right={formatCurrency(order?.subTotal || 0)}
              gap={2}
            />
            <Row
              left={<Text bold={true}>Total</Text>}
              right={
                <Text bold={true}>{formatCurrency(order?.subTotal || 0)}</Text>
              }
              gap={2}
            />
            <Line />
            <Text align="center">Payment Processed</Text>
            <Text align="center" bold={true}>{`**** **** **** ${
              order?.paymentMethod?.cardNumberEnd || '****'
            }`}</Text>
            <Cut />
          </Printer>
        )

        const buffer: Uint8Array = await render(receipt)

        let index = 0

        while (index < buffer.length) {
          const bufferSlice = buffer.slice(index, index + 32)
          if (printCharacteristic !== null) {
            await printCharacteristic.writeValue(bufferSlice)
          } else {
            await characteristic.writeValue(bufferSlice)
          }
          index += 32
        }

        if (characteristic) {
          Toast.dismiss(toastPrintLoading.current!)
        } else {
          setTimeout(() => Toast.dismiss(toastPrintLoading.current!), 2000)
        }
        close()

        if (order?.lastStatus === OrderStatusEnum.CREATED) {
          onOrderStatusChange(OrderStatusEnum.IN_PROGRESS)
        }
      } catch (error) {
        handlePrintError(error)
      }
    },
    [
      close,
      getOrderTextVersion,
      handlePrintError,
      onOrderStatusChange,
      order,
      printCharacteristic,
    ],
  )

  const handleOrderPrint = useCallback(async () => {
    await getOrderTextVersion(order)
    notifyPrint()
    if (printCharacteristic === null) {
      const mobileNavigatorObject: any = window.navigator
      if (mobileNavigatorObject && mobileNavigatorObject.bluetooth && order) {
        await mobileNavigatorObject.bluetooth
          .requestDevice({ filters: [{ services: [SERVICE] }] })
          .then((device: any) => {
            setDevice(device)
            return device.gatt.connect()
          })
          .then((server: any) => {
            return server.getPrimaryService(SERVICE)
          })
          .then((service: any) => {
            return service.getCharacteristic(WRITE)
          })
          .then((characteristic: any) => {
            onPrintCharacteristic(characteristic)
            printData(characteristic)
          })
          .catch((error: any) => handleError(error))
      }
    } else {
      printCharacteristic !== null && printData()
    }
  }, [
    getOrderTextVersion,
    order,
    printCharacteristic,
    onPrintCharacteristic,
    printData,
    handleError,
  ])

  const notifyPrint = () =>
    (toastPrintLoading.current = Toast({
      type: 'info',
      title: (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <>Printing...</>
          <MenuIcon>
            <LoadingAnimation />
          </MenuIcon>
        </div>
      ),
      autoClose: false,
      hideCloseButton: true,
    }))

  return (
    <DropdownMenuItem onClick={handleOrderPrint}>
      Print {order.lastStatus === OrderStatusEnum.CREATED && '& accept'} order
    </DropdownMenuItem>
  )
}

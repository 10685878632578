import { useState } from 'react'
import { Helmet } from 'react-helmet-async'

import { PaymentForm } from '@/components/PaymentForm'
import { PaymentList } from '@/components/PaymentList'

import * as S from './styles'

export function PaymentMethods() {
  const [hasToFetchList, setHasToFetchList] = useState(false)

  return (
    <>
      <Helmet title="Payment Methods" />
      <S.Content>
        <S.CardListWrapper>
          <S.Label>Choose a credit card:</S.Label>
          <S.Card>
            <PaymentList hasToFetchList={hasToFetchList} />
          </S.Card>
        </S.CardListWrapper>

        <S.CardFormWrapper>
          <S.Label>Or add a new credit card:</S.Label>
          <S.Card>
            <PaymentForm onSavePayment={setHasToFetchList} />
          </S.Card>
        </S.CardFormWrapper>
      </S.Content>
    </>
  )
}

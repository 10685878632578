import { differenceInMinutes, format, intervalToDuration } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

import { weekDayNameEnum } from '@/enums/date.enum'
import { ShiftHoursType } from '@/types/menu-maker.types'
import { MerchantType } from '@/types/merchant.types'

export const formatDistanceToNow = (
  date: Date,
  withTime = true,
  timezone = 'America/Cayman',
) => {
  const diff = differenceInMinutes(new Date(), date)

  if (diff <= 1) {
    return 'Now'
  } else if (diff > 1 && diff <= 90) {
    return `${diff} min ago`
  } else if (diff > 90) {
    return withTime
      ? formatInTimeZone(date, timezone, 'MMM dd, yyyy h:mmaaa')
      : format(date, 'dd MMM')
  }
}

export const formatDistance = (seconds: number) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })

  if (duration.hours) {
    const plural = duration.hours > 1 ? 's' : ''
    return `${duration.hours} hour${plural}`
  }

  if (duration.minutes) {
    const plural = duration.minutes > 1 ? 's' : ''
    return `${duration.minutes} minute${plural}`
  }

  if (duration.seconds) {
    const plural = duration.seconds > 1 ? 's' : ''
    return `${duration.seconds} second${plural}`
  }

  return 0
}

export const convertTimeToDate = (time: string) => {
  const hour = +time.split(':')[0]
  const min = +time.split(':')[1]
  const date = new Date().setHours(hour, min)
  return new Date(date)
}

export const getWeekDays = (
  weekDays: number[],
  separator: '-' | ' | ' = '-',
) => {
  return weekDays.sort().map((day: number, index: number) => {
    const dayName = weekDayNameEnum[day as keyof typeof weekDayNameEnum]
    return index + 1 === weekDays.length ? dayName : `${dayName}${separator}`
  })
}

export const getHoursToList = (hours: ShiftHoursType[]) => {
  return hours.map(({ start, end }) => {
    return `(${start} / ${end})`
  })
}

export const getHoursToEdit = (hours: ShiftHoursType[]) => {
  return hours.map(({ start, end }, index: number) => {
    const startDate = format(convertTimeToDate(start), 'h:mmaa')
    const endDate = format(convertTimeToDate(end), 'h:mmaa')
    return index + 1 === hours.length
      ? `${startDate} - ${endDate}`
      : `${startDate} - ${endDate} | `
  })
}

export const changeTimeZone = (
  date: Date,
  merchantsSelecteds: MerchantType[],
) => {
  const timeZone = merchantsSelecteds[0].timeZone as string
  if (typeof date === 'string') {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone,
      }),
    )
  }

  return new Date(
    date.toLocaleString('en-US', {
      timeZone,
    }),
  )
}

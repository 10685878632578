import { ReactNode, useEffect, useState } from 'react'

import { FormStep } from '@/components/FormStep'
import FormStepProvider from '@/components/FormStep/Context'
import { CardType } from '@/types/payment.types'
import { Campaign, CampaignList } from '@/types/super-partner.types'

import { CheckoutForm } from './CheckoutForm'
import { ShareVoucher } from './ShareVoucher'
import { CampaignListInitialData, VoucherForm } from './VoucherForm'

export type Steps = {
  [stepName: string]: ReactNode
}

export type VoucherStepsProps = {
  close: (isSuccess?: boolean) => void
  campaignItem: CampaignList | null
  campaign: Campaign
  paymentMethod: CardType | null
}

export type BeforeType = {
  active: boolean
  field: string
}

export const VoucherSteps = ({
  campaignItem,
  campaign,
  close,
  paymentMethod,
}: VoucherStepsProps) => {
  const [activeStep, setActiveStep] = useState(0)
  const [steps, setSteps] = useState<Steps[]>([])
  const [before, setBefore] = useState<BeforeType>({
    active: false,
    field: '',
  })

  const campaignItemData = campaignItem as CampaignList<CampaignListInitialData>

  const nextFormStep = () => {
    setBefore({ active: false, field: '' })
    setActiveStep((currentStep) => currentStep + 1)
  }

  const beforeFormStep = ({ active, field }: BeforeType) => {
    setBefore({ active, field })
    setActiveStep((currentStep) => currentStep - 1)
  }

  useEffect(() => {
    setSteps([
      {
        voucher: (
          <VoucherForm
            key="voucher-form"
            campaignItem={campaignItemData}
            campaign={campaign}
            close={close}
            onNextStep={nextFormStep}
            before={before}
            active={activeStep === 0}
          />
        ),
      },
      {
        checkout: (
          <CheckoutForm
            key="checkout-form"
            campaignItem={campaignItemData}
            close={close}
            activeStep={activeStep}
            active={activeStep === 1}
            before={before}
            onBeforeStep={beforeFormStep}
            paymentMethod={paymentMethod}
            onNextStep={nextFormStep}
            setSteps={setSteps}
          />
        ),
      },
    ])
    if (campaignItemData.initialData?.removeAudiences) {
      setSteps((old) => {
        const hasShareStep = old.some(
          (step) => Object.keys(step)[0] === 'share',
        )

        if (hasShareStep) {
          return [...old]
        } else {
          return [
            ...old,
            {
              share: (
                <ShareVoucher
                  key="share-voucher"
                  active={activeStep === 2}
                  close={close}
                />
              ),
            },
          ]
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep])

  return (
    <FormStepProvider>
      <FormStep
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    </FormStepProvider>
  )
}

import { useCallback, useEffect, useState } from 'react'

import arrowDownIcon from '@/assets/img/icon-arrow-down.svg'
import Button from '@/components/Button'
import { Modal, ModalContent } from '@/components/Modal'
import TextField from '@/components/TextField'
import useModal from '@/hooks/useModal'

import PhoneSelectDialModal, { DialCodeType } from '../PhoneSelectDialModal'
import { countryDialCodes } from '../PhoneSelectDialModal/countryDialCodes'
import * as S from './styles'

const selectAndRemoveDialCode = (phone: string) => {
  if (!phone) return ''

  const phoneDialFive = phone.substring(0, 5)
  const dialCodeFive = countryDialCodes.find(
    (dial) => dial.dialCode === phoneDialFive,
  )
  if (dialCodeFive) {
    return {
      dialCode: dialCodeFive,
      phoneNumber: phone.replace(dialCodeFive.dialCode, ''),
    }
  }
  const dialCode = countryDialCodes.find((dial) =>
    phone.startsWith(dial.dialCode),
  )
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  return { dialCode, phoneNumber: phone.replace(dialCode?.dialCode!, '') }
}

type PhoneValidationModalProps = {
  phone?: string
  onClose: (phoneNumber?: string) => void
}

const PhoneValidationModal = ({
  phone = '',
  onClose,
}: PhoneValidationModalProps) => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [dialCode, setDialCode] = useState<DialCodeType | null>({
    name: 'Cayman Islands',
    flag: '🇰🇾',
    code: 'KY',
    dialCode: '+1345',
  })
  const [isLoading, setIsLoading] = useState(false)
  const { open: openDialModal, toggle: toggleDialModal } = useModal()

  const handleValidationSave = useCallback(async () => {
    if (!phoneNumber || !dialCode) return

    try {
      setIsLoading(true)

      const dataToSave = {
        phoneNumber: `${dialCode?.dialCode}${phoneNumber}`,
      }

      onClose(dataToSave.phoneNumber)
    } finally {
      setIsLoading(false)
    }
  }, [phoneNumber, dialCode, onClose])

  const renderPhoneSelectDialModal = () => {
    return (
      <Modal open={openDialModal} onOpenChange={toggleDialModal}>
        <ModalContent title="Dial code">
          <PhoneSelectDialModal
            onClose={(dial: DialCodeType) => {
              dial && setDialCode(dial)
              toggleDialModal(false)
            }}
          />
        </ModalContent>
      </Modal>
    )
  }

  useEffect(() => {
    if (phone) {
      const data = selectAndRemoveDialCode(phone)
      if (data && data.dialCode && data.phoneNumber) {
        const { dialCode, phoneNumber } = data
        setPhoneNumber(phoneNumber)
        setDialCode(dialCode)
      }
    }
  }, [phone])

  return (
    <S.Wrapper>
      <S.Flex1>
        <S.Title>Edit Phone</S.Title>

        <S.Section>
          <S.SelectDialCode onClick={() => toggleDialModal(true)}>
            <S.FlagEmoji>{dialCode?.flag}</S.FlagEmoji>
            {dialCode?.dialCode ? dialCode?.dialCode : '(+xxx)'}
            <S.Icon src={arrowDownIcon} alt="Icon to open select" />
          </S.SelectDialCode>

          <TextField
            variant="material"
            placeholder="Phone number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            mask="numberClean"
          />
        </S.Section>
      </S.Flex1>

      <S.ButtonWrapper>
        <Button
          color="white"
          onClick={() => onClose()}
          disabled={isLoading}
          shadow
        >
          Cancel
        </Button>
        <Button
          onClick={handleValidationSave}
          disabled={isLoading || !phoneNumber || !dialCode}
          loading={isLoading}
        >
          Save
        </Button>
      </S.ButtonWrapper>

      {renderPhoneSelectDialModal()}
    </S.Wrapper>
  )
}

export default PhoneValidationModal

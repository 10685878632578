import { forwardRef, ForwardRefRenderFunction, useCallback } from 'react'

import * as S from './styles'

export type SwitchChangeEventHandler = (
  checked: boolean,
  event: React.MouseEvent<HTMLButtonElement>,
) => void
export type SwitchClickEventHandler = SwitchChangeEventHandler

export type SwitchProps = {
  disabled?: boolean
  checkedChildren?: React.ReactNode
  unCheckedChildren?: React.ReactNode
  onChange?: SwitchChangeEventHandler
  onClick?: SwitchClickEventHandler
  tabIndex?: number
  checked: boolean
} & Omit<React.HTMLAttributes<HTMLButtonElement>, 'onChange' | 'onClick'>

const Switch: ForwardRefRenderFunction<HTMLButtonElement, SwitchProps> = (
  {
    checked,
    disabled = false,
    checkedChildren,
    unCheckedChildren,
    onClick,
    onChange,
    ...props
  },
  ref,
) => {
  const triggerChange = useCallback(
    (newChecked: boolean, event: React.MouseEvent<HTMLButtonElement>) => {
      let mergedChecked = checked

      if (!disabled) {
        mergedChecked = newChecked
        onChange?.(mergedChecked, event)
      }

      return mergedChecked
    },
    [checked, disabled, onChange],
  )

  const onInternalClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const ret = triggerChange(!checked, e)
      onClick?.(ret, e)
    },
    [checked, onClick, triggerChange],
  )

  return (
    <S.Wrapper
      {...props}
      type="button"
      role="switch"
      aria-checked={checked}
      disabled={disabled}
      onClick={onInternalClick}
      checked={checked}
      ref={ref}
    >
      <S.Inner>{checked ? checkedChildren : unCheckedChildren}</S.Inner>
    </S.Wrapper>
  )
}

export default forwardRef(Switch)

import Avatar from '@/components/Avatar'

import * as S from './styles'

export type AvatarGroupProps<T> = {
  items: T[]
  text: keyof T
  image: keyof T
  id: keyof T
  loading?: boolean
}

const AvatarGroup = <T,>({
  items,
  text,
  image,
  id,
  loading = false,
}: AvatarGroupProps<T>) => {
  const restOfItems = items.length > 0 ? items.slice(3) : []
  const hasMore = items.length > 3 && restOfItems.length > 0

  return (
    <S.Wrapper hasMore={hasMore}>
      {items.slice(0, 3).map((item, index) => (
        <Avatar
          key={item[id] as unknown as string}
          text={(item[text] as unknown as string).slice(0, 1).toUpperCase()}
          image={item[image] as unknown as string}
          loading={loading}
          style={{ zIndex: items.slice(0, 3).length - index }}
        />
      ))}

      {hasMore && <S.More>+{restOfItems.length}</S.More>}
    </S.Wrapper>
  )
}

export default AvatarGroup

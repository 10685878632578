import { endOfDay, startOfDay } from 'date-fns'

import { api } from '@/lib/axios'
import { AdsCampaignsType, VoucherListType } from '@/types/super-partner.types'

const getCampaigns = async (merchantId: string, [start, end]: [Date, Date]) => {
  const { data } = await api.get<AdsCampaignsType[]>(
    `/superpartner/${merchantId}/campaigns`,
    {
      params: {
        start: startOfDay(start).toISOString(),
        end: endOfDay(end).toISOString(),
      },
    },
  )

  return data
}

const getVouchers = async (merchantId: string, [start, end]: [Date, Date]) => {
  const { data } = await api.get<VoucherListType[]>(`/vouchers/${merchantId}`, {
    params: {
      start: startOfDay(start).toISOString(),
      end: endOfDay(end).toISOString(),
    },
  })

  return data
}

interface PatchAvailabilityProps {
  campaign: AdsCampaignsType
  merchantId: string
}

const patchAvailability = async ({
  campaign,
  merchantId,
}: PatchAvailabilityProps) => {
  return await api.patch<void>(
    `/superpartner/${merchantId}/campaigns/${campaign.id}`,
    {
      disabled: campaign.disabled,
    },
  )
}

interface PatchVoucherAvailabilityProps {
  voucher: VoucherListType
}

const patchVoucherAvailability = async ({
  voucher,
}: PatchVoucherAvailabilityProps) => {
  return await api.patch<void>(`/vouchers/${voucher.voucherId}`, {
    disabled: !voucher.isValid,
  })
}

export default {
  getCampaigns,
  getVouchers,
  patchAvailability,
  patchVoucherAvailability,
}

import { Box } from '@/components/Box'
import {
  ModalButtonClose,
  ModalFooter,
  ModalOverflow,
} from '@/components/Modal'

import * as S from './styles'

export type ConfirmModalProps = {
  message: string | React.ReactElement
  confirmLabel?: string
  cancelLabel?: string
  removeCancelButton?: boolean
  closeConfirm: (confirm: boolean) => void
  isLoading?: boolean
  disableCancel?: boolean
}

const ConfirmModal = ({
  message,
  confirmLabel = 'Delete',
  cancelLabel = 'Cancel',
  removeCancelButton = false,
  closeConfirm,
  isLoading = false,
  disableCancel = false,
}: ConfirmModalProps) => (
  <>
    <ModalOverflow>
      <S.Container>
        <p>{message}</p>
      </S.Container>
    </ModalOverflow>

    <ModalFooter>
      <Box flex alignItems="center" JustifyContent="space-between" width="100%">
        {!removeCancelButton && (
          <ModalButtonClose
            color="white"
            size="large"
            onClick={() => closeConfirm(false)}
            shadow
            disabled={disableCancel}
          >
            {cancelLabel}
          </ModalButtonClose>
        )}
        <span />
        <ModalButtonClose
          size="large"
          loading={isLoading}
          onClick={() => closeConfirm(true)}
        >
          {confirmLabel}
        </ModalButtonClose>
      </Box>
    </ModalFooter>
  </>
)

export default ConfirmModal

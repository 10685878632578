import {
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
} from 'react'

import * as S from './styles'

type RadioValue = string | ReadonlyArray<string> | number

export type RadioProps = {
  onCheck?: (value?: RadioValue) => void
  label?: string
  labelFor?: string
  labelColor?: 'default' | 'green' | 'red'
  value?: RadioValue
  additionalText?: string
} & InputHTMLAttributes<HTMLInputElement>

const Radio: ForwardRefRenderFunction<HTMLInputElement, RadioProps> = (
  {
    label,
    onCheck,
    labelFor = '',
    labelColor = 'default',
    value,
    disabled = false,
    additionalText,
    ...props
  },
  ref,
) => {
  const onChange = () => {
    !!onCheck && onCheck(value)
  }

  return (
    <S.Wrapper onClick={onChange}>
      <S.LabelWrapper>
        <S.Input
          id={labelFor}
          type="radio"
          value={value}
          onChange={onChange}
          disabled={disabled}
          ref={ref}
          {...props}
        />
        {!!label && (
          <S.Label
            htmlFor={labelFor}
            labelColor={labelColor}
            disabled={disabled}
          >
            {label}
          </S.Label>
        )}
      </S.LabelWrapper>

      {!!additionalText && (
        <S.AdditionalText>{additionalText}</S.AdditionalText>
      )}
    </S.Wrapper>
  )
}

export default forwardRef(Radio)

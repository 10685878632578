import ordinal from 'ordinal'
import { useState } from 'react'

import Radio from '@/components/Radio'
import Skeleton from '@/components/Skeleton'
import {
  FieldContent,
  FieldError,
  FieldHeader,
  FieldHeaderTitle,
  FieldWrapper,
} from '@/modals/CreatingNewCampaignModal/styles'
import { VoucherType } from '@/types/super-partner.types'

import * as S from './styles'

export type PositionFieldProps = {
  vouchers?: VoucherType
  error?: string
  before?: boolean
  pricing: {
    [key: string]: {
      month: string
      amount: number
      available: boolean
    }[]
  }
  onChange: (value?: number) => void
  loading: boolean
  position: string
}

const positions = [
  { name: '1' },
  { name: '2' },
  { name: '3' },
  { name: '4' },
  { name: '5' },
]

export const PositionField = ({
  error,
  onChange,
  loading,
  position,
}: PositionFieldProps) => {
  const [selectedPosition, setSelectPosition] = useState(position)

  return (
    <FieldWrapper id="position">
      <FieldHeader>
        <FieldHeaderTitle>Position</FieldHeaderTitle>
      </FieldHeader>

      <FieldContent>
        <S.OptionsList>
          {loading && (
            <>
              <S.OptionItem>
                <Skeleton />
              </S.OptionItem>
              <S.OptionItem>
                <Skeleton />
              </S.OptionItem>
              <S.OptionItem>
                <Skeleton />
              </S.OptionItem>
              <S.OptionItem>
                <Skeleton />
              </S.OptionItem>
              <S.OptionItem>
                <Skeleton />
              </S.OptionItem>
            </>
          )}

          {!loading &&
            positions.map((position) => (
              <S.OptionItem key={position.name}>
                <Radio
                  label={`${ordinal(parseInt(position.name))} position`}
                  labelFor={`position${position.name}`}
                  id={`position${position.name}`}
                  onChange={() => {
                    setSelectPosition(position.name)
                    onChange(parseInt(position.name))
                  }}
                  checked={selectedPosition === position.name}
                />
              </S.OptionItem>
            ))}

          {error && <FieldError>{error}</FieldError>}
        </S.OptionsList>
      </FieldContent>
    </FieldWrapper>
  )
}

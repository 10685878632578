import { darken } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { Content, Title } from '../Dropdown/styles'
import {
  MenuItem as MenuItemDropdown,
  MenuList as MenuListDropdown,
} from '../DropdownMenu'
import { Input, InputWrapper } from '../TextField/styles'

export const Wrapper = styled.div<{ wrapLabel: boolean }>`
  ${({ theme, wrapLabel }) => css`
    display: flex;
    align-items: center;
    width: 100%;

    ${wrapLabel &&
    css`
      flex-wrap: wrap;
    `}

    ${InputWrapper} {
      border: 2px solid ${theme.colors.superLight};
    }

    ${Input} {
      padding-left: 0;
      width: 100%;
      color: ${theme.dark ? theme.colors.secondary : theme.colors.body};
    }

    ${Title} {
      z-index: auto;
    }

    ${Content} {
      border-radius: 4px;
      box-shadow: 0 0.25rem 0.625rem 0 rgba(0, 0, 0, 0.15);
    }
  `}
`

export const MenuList = styled(MenuListDropdown)`
  border-radius: 4px;
`

export const MenuItem = styled(MenuItemDropdown)<{
  selected: boolean
}>`
  ${({ theme, selected, disabled }) => css`
    font-weight: ${theme.font.medium};

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:hover {
      background-color: ${theme.colors.primary};
      color: ${theme.dark ? theme.colors.black : theme.colors.white};
    }

    ${disabled &&
    css`
      &:hover {
        background-color: ${theme.colors.mainBg};
        color: ${theme.colors.lightGray};

        ${selected &&
        css`
          background-color: ${theme.colors.primary};
          color: ${theme.dark ? theme.colors.black : theme.colors.white};
        `}
      }
    `}

    ${selected &&
    css`
      background-color: ${theme.colors.primary};
      color: ${theme.dark ? theme.colors.black : theme.colors.white};
    `}
  `}
`

export const DatepickerWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-width: 500px;

    ${media.lessThan('medium')`
      min-width: 236px;
    `}

    .react-datepicker-wrapper,
    .react-datepicker,
    > div {
      width: 100%;

      .react-datepicker__input-container {
        input {
          background: ${theme.colors.white};
          border-radius: ${theme.border.radius.xsmall};
          padding: ${theme.spacings.xxsmall} ${theme.spacings.xxsmall};
          border: 2px solid ${theme.colors.mainBg};
          color: ${theme.colors.secondary};
          font-family: ${theme.font.quicksand};
          font-size: ${theme.font.sizes.xsmall};
          outline: none;
          transition: border ${theme.transition.default};
          width: 100%;

          &:focus {
            border: 2px solid ${theme.colors.lightGray};
          }
        }
      }
    }

    .react-datepicker {
      font-family: ${theme.font.quicksand};
      border: 1px solid transparent;
      color: ${theme.colors.secondary};
      font-size: ${theme.font.sizes.xsmall};
      background-color: ${theme.colors.white};

      .react-datepicker__triangle {
        &::before {
          border-bottom-color: ${theme.colors.lightGray};
        }
        &::after {
          border-bottom-color: ${theme.colors.white};
        }
      }

      .react-datepicker__navigation {
        .react-datepicker__navigation-icon {
          width: 16px;

          &::before {
            border-color: ${theme.colors.lightGray};
            border-width: 3px 3px 0 0;
            top: 9px;
          }
        }

        &:hover *::before {
          border-color: ${theme.colors.gray};
        }
      }

      .react-datepicker__month-container {
        .react-datepicker__header {
          background-color: ${theme.colors.white};
          color: ${theme.colors.secondary};
          border-bottom: 0;

          .react-datepicker__current-month {
            font-size: ${theme.font.sizes.small};
            font-family: ${theme.font.nunito};
            font-weight: ${theme.font.semiBold};
            color: ${theme.colors.gray};
          }

          .react-datepicker__day-names {
            padding: 0.5rem;
            background-color: ${theme.colors.mainBg};
            margin-top: 0.5rem;

            .react-datepicker__day-name {
              padding: 0 1.25rem 0 0.5rem;
              color: ${theme.colors.gray};
            }
          }
        }

        .react-datepicker__month {
          .react-datepicker__week {
            .react-datepicker__day {
              width: ${theme.spacings.medium};
              line-height: ${theme.spacings.medium};
              color: ${theme.dark
                ? theme.colors.superLight
                : theme.colors.secondary};

              &:hover {
                background-color: ${theme.colors.mainBg};
              }

              &.react-datepicker__day--in-range {
                background-color: ${theme.colors.primary};
                transition: all ${theme.transition.fast};
                color: ${theme.dark
                  ? theme.colors.superLight
                  : theme.colors.white};

                &:hover {
                  background-color: ${darken(0.1, theme.colors.primary)};
                }
              }

              &.react-datepicker__day--keyboard-selected {
                background-color: inherit;
                color: ${theme.colors.secondary};

                &.react-datepicker__day--in-range {
                  background-color: ${theme.colors.primary};
                  color: ${theme.dark
                    ? theme.colors.superLight
                    : theme.colors.white};
                }
              }

              &.react-datepicker__day--today {
                background-color: ${theme.colors.secondary};
                color: ${theme.dark
                  ? theme.colors.superLight
                  : theme.colors.white};
              }

              &.react-datepicker__day--in-selecting-range {
                background-color: ${theme.colors.mainBg};
                color: ${theme.dark
                  ? theme.colors.superLight
                  : theme.colors.secondary};
                border-radius: 0;
              }

              &.react-datepicker__day--selecting-range-start {
                background-color: ${theme.colors.mainBg};
                color: ${theme.dark
                  ? theme.colors.superLight
                  : theme.colors.secondary};
              }
            }
          }
        }
      }
    }

    .react-datepicker-popper,
    .react-datepicker__portal {
      .react-datepicker {
        font-family: ${theme.font.quicksand};
        border: 1px solid ${theme.colors.lightGray};
        color: ${theme.colors.secondary};
        font-size: ${theme.font.sizes.xsmall};

        .react-datepicker__triangle {
          &::before {
            border-bottom-color: ${theme.colors.lightGray};
          }
          &::after {
            border-bottom-color: ${theme.colors.white};
          }
        }

        .react-datepicker__navigation {
          .react-datepicker__navigation-icon {
            width: 16px;
            &::before {
              border-color: ${theme.colors.lightGray};
              border-width: 3px 3px 0 0;
              top: 5px;
            }
          }

          &:hover *::before {
            border-color: ${theme.colors.gray};
          }
        }

        .react-datepicker__month-container {
          .react-datepicker__header {
            background-color: ${theme.colors.white};
            color: ${theme.colors.secondary};
            border-bottom: 0;

            .react-datepicker__current-month {
              font-size: ${theme.font.sizes.small};
              font-family: ${theme.font.nunito};
              font-weight: ${theme.font.semiBold};
              color: ${theme.colors.gray};
            }

            .react-datepicker__day-names {
              padding: 0.5rem;
              background-color: ${theme.colors.mainBg};
              margin-top: 0.5rem;

              .react-datepicker__day-name {
                padding: 0 1.25rem 0 0.5rem;
                color: ${theme.colors.gray};
              }
            }
          }

          .react-datepicker__month {
            .react-datepicker__week {
              .react-datepicker__day {
                width: ${theme.spacings.medium};
                line-height: ${theme.spacings.medium};

                &:hover {
                  background-color: ${theme.colors.mainBg};
                }

                &.react-datepicker__day--in-range {
                  background-color: ${theme.colors.primary};
                  transition: all ${theme.transition.fast};

                  &:hover {
                    background-color: ${darken(0.1, theme.colors.primary)};
                  }
                }

                &.react-datepicker__day--keyboard-selected {
                  background-color: inherit;
                  color: ${theme.colors.secondary};

                  &.react-datepicker__day--in-range {
                    background-color: ${theme.colors.primary};
                    color: ${theme.colors.white};
                  }
                }

                &.react-datepicker__day--today {
                  background-color: ${theme.colors.secondary};
                  color: ${theme.colors.white};
                }

                &.react-datepicker__day--in-selecting-range {
                  background-color: ${theme.colors.mainBg};
                  color: ${theme.colors.secondary};
                  border-radius: 0;
                }

                &.react-datepicker__day--selecting-range-start {
                  background-color: ${theme.colors.mainBg};
                  color: ${theme.colors.secondary};
                }
              }
            }
          }
        }
      }
    }
  `}
`

export const Label = styled.label`
  ${({ theme }) => css`
    font-family: ${theme.font.nunito};
    font-weight: ${theme.font.semiBold};
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.body};
    padding-right: ${theme.spacings.xxsmall};
    cursor: pointer;
    min-width: 6.563rem;
  `}
`

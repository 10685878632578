import { ButtonProps } from '@/components/Button'

import * as S from './styles'

export type FabProps = { rounded?: boolean } & ButtonProps

const Fab = ({ children, rounded = true, ...props }: FabProps) => (
  <S.Wrapper {...props} rounded={rounded}>
    {children}
  </S.Wrapper>
)

export default Fab

import {
  HighlightOff as CloseIcon,
  Search as SearchIcon,
} from '@styled-icons/material-outlined'
import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import Button from '@/components/Button'
import Empty from '@/components/Empty'
import Skeleton from '@/components/Skeleton'
import TextField from '@/components/TextField'

import { countryDialCodes } from './countryDialCodes'
import * as S from './styles'

export type DialCodeType = {
  flag: string
  dialCode: string
  code?: string
  name?: string
}

type PhoneSelectDialModalProps = {
  onClose: (dial: DialCodeType) => void
}

const PhoneSelectDialModal = ({ onClose }: PhoneSelectDialModalProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [filtered, setFiltered] = useState<DialCodeType[]>([])
  const [filterText, setFilterText] = useState('')

  const handleFilterChange = (search: string, dialCodes: DialCodeType[]) => {
    if (search) {
      const countries = dialCodes.filter(
        ({ name }) => name && name.toLowerCase().includes(search.toLowerCase()),
      )
      const dials = dialCodes.filter(
        ({ dialCode }) =>
          dialCode && dialCode.toLowerCase().includes(search.toLowerCase()),
      )

      setFiltered([...countries, ...dials])
    } else {
      setFiltered(dialCodes)
    }
    setIsLoading(false)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(_.debounce(handleFilterChange, 300), [])

  const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setFilterText(value)
  }

  const handleClear = () => {
    setFilterText('')
    debouncedOnChange('', countryDialCodes)
  }

  useEffect(() => {
    if (countryDialCodes.length > 0) {
      debouncedOnChange(filterText, countryDialCodes)
    } else {
      setFiltered([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, countryDialCodes])

  return (
    <S.Wrapper>
      <S.Flex1>
        <S.Section>
          <S.Search>
            <TextField
              name="search_dials"
              placeholder="Search by country or dial code"
              disabled={countryDialCodes.length === 0}
              value={filterText}
              icon={
                filterText ? (
                  <>
                    <Button
                      onClick={handleClear}
                      icon={<CloseIcon />}
                      style={{ padding: 0, height: 'auto' }}
                      data-tip="Clear search"
                      minimal
                    />
                    <ReactTooltip
                      effect="solid"
                      className="tooltip"
                      backgroundColor="#433d3d"
                      borderColor="#433d3d"
                    />
                  </>
                ) : (
                  <SearchIcon />
                )
              }
              iconPosition="right"
              onChange={handleChangeFilter}
            />
          </S.Search>

          <S.List>
            {isLoading ? (
              <S.LoadingBox>
                <Skeleton height="1.563rem" />
              </S.LoadingBox>
            ) : filtered.length === 0 ? (
              <S.NoResultsBox>
                <Empty
                  image="/img/icons-message-empty-state.svg"
                  title="No results"
                  description="Try searching for a different country or dial code"
                />
              </S.NoResultsBox>
            ) : (
              filtered.map((item) => (
                <S.ListItem
                  key={item.code}
                  role="button"
                  onClick={() =>
                    onClose({
                      flag: item.flag,
                      dialCode: item.dialCode,
                    })
                  }
                >
                  <S.Emoji>{item.flag}</S.Emoji>

                  <S.Box>
                    <S.Description>{item.name}</S.Description>
                    <S.DialCode>{item.dialCode}</S.DialCode>
                  </S.Box>
                </S.ListItem>
              ))
            )}
          </S.List>
        </S.Section>
      </S.Flex1>
    </S.Wrapper>
  )
}

export default PhoneSelectDialModal

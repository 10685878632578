import styled, { css } from 'styled-components'

export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.nunito};
    font-weight: ${theme.font.extraBold};
    font-size: ${theme.font.sizes.xlarge};
    color: ${theme.colors.primary};
  `}
`

export const AddressInfo = styled.div`
  padding-right: 0.625rem;
`

export const AddressInfoText = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.quicksand};
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.gray};
    margin-top: 0.5rem;
    width: 80%;
  `}
`

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 196px;
  cursor: pointer;
`

export const MapWrapper = styled.div`
  width: 50%;
  height: calc(196px + 2rem);
  position: absolute;
  top: -2rem;
  right: -2rem;
  border-radius: 30px;
  overflow: hidden;
  z-index: 0;
`

export const Marker = styled.img`
  position: absolute;
  top: 35%;
  left: 45.6%;
  z-index: 1;
`

import { useState } from 'react'

export const useDialog = () => {
  const [open, setOpen] = useState(false)

  const toggle = (close: boolean) => {
    setOpen(close)
  }

  return {
    open,
    toggle,
  }
}

import { useEffect } from 'react'

declare const navigator: Navigator & {
  wakeLock: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: (arg: string) => Promise<any>
  }
}

export const useScreenLock = () => {
  useEffect(() => {
    const isWakeLockApiAvailable = 'wakeLock' in navigator

    const requestWakeLock = async () => {
      try {
        await navigator.wakeLock.request('screen')
      } catch (error) {}
    }

    if (isWakeLockApiAvailable) {
      requestWakeLock()
    }
  }, [])
}

import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { titleCase } from 'title-case'

import settingsService from '@/api/settings.service'
import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { ModalFooter, ModalOverflow } from '@/components/Modal'
import SelectButton from '@/components/SelectButton'
import Skeleton from '@/components/Skeleton'
import TextField from '@/components/TextField'

import * as S from './styles'

export type TagSelectedType = {
  name: string
  value: string
  selected: boolean
}

export type EditSettingsTagModalProps = {
  tags: TagSelectedType[]
  closeEditSettingsTag: (tags?: TagSelectedType[]) => void
}

type FormErrorsType = {
  tags: string
}

const EditSettingsTagModal = ({
  tags,
  closeEditSettingsTag,
}: EditSettingsTagModalProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [tagsSelected, setTagsSelected] = useState<TagSelectedType[]>([])
  const [formErrors, setFormErrors] = useState<FormErrorsType>({
    tags: '',
  })
  const [filteredTagsSelected, setFilteredTagsSelected] = useState<
    TagSelectedType[]
  >([])
  const [filterText, setFilterText] = useState('')

  const handleFilterChange = (
    search: string,
    tagsSelected: TagSelectedType[],
  ) => {
    if (search) {
      const tagsSelectedFiltered = tagsSelected.filter(
        ({ name }) => name && name.toLowerCase().includes(search.toLowerCase()),
      )
      setFilteredTagsSelected([...tagsSelectedFiltered])
    } else {
      setFilteredTagsSelected(tagsSelected)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(_.debounce(handleFilterChange, 300), [])

  const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setFilterText(value)
  }

  const handleSelectTag = useCallback(
    (tag: TagSelectedType) => {
      setFormErrors((old) => ({ ...old, tags: '' }))

      const newTagsSelected = [...tagsSelected]
      const tagIndex = newTagsSelected.findIndex((t) => t.value === tag.value)

      newTagsSelected[tagIndex] = {
        ...tag,
        selected: !tag.selected,
      }
      setTagsSelected(newTagsSelected)
    },
    [tagsSelected],
  )

  const saveTagsSelected = useCallback(async () => {
    setIsSaveLoading(true)
    const tags = tagsSelected.filter((t) => t.selected)

    if (!tags.length) {
      setFormErrors((old) => ({
        ...old,
        tags: 'Select at least one tag',
      }))
      setIsSaveLoading(false)
      return
    }

    setFormErrors((old) => ({ ...old, tags: '' }))

    closeEditSettingsTag(tags)
    setIsSaveLoading(false)
  }, [tagsSelected, closeEditSettingsTag])

  const getTagsOptions = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data: tagOptions } =
        await settingsService.getMerchantTagsOptions()

      console.log({ tagOptions })

      setTagsSelected(
        tagOptions.map((tag) => ({
          name: titleCase(tag.toLowerCase()),
          value: tag.toLowerCase(),
          selected: tags
            ? tags.some((t) => t.value === tag.toLowerCase())
            : false,
        })),
      )
    } catch (error) {
      setFilteredTagsSelected([])
    } finally {
      setIsLoading(false)
    }
  }, [tags])

  useEffect(() => {
    if (tagsSelected.length > 0) {
      debouncedOnChange(filterText, tagsSelected)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, tagsSelected])

  useEffect(() => {
    getTagsOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ModalOverflow>
        <S.SearchWrapper>
          <TextField
            type="text"
            name="search_menu"
            placeholder="Search by name"
            disabled={tagsSelected.length === 0}
            value={filterText}
            iconPosition="right"
            onChange={handleChangeFilter}
            variant="material"
          />
        </S.SearchWrapper>

        <S.Content>
          <S.MenuForm>
            <Box flex flexDirection="column">
              <S.Categories>
                {isLoading ? (
                  <Box flex gap="1rem" JustifyContent="center" flexWrap="wrap">
                    <Skeleton
                      width="8.125rem"
                      height="2rem"
                      count={8}
                      margin={0}
                      radius={4}
                    />
                  </Box>
                ) : (
                  filteredTagsSelected.map((tag, index) => (
                    <SelectButton
                      key={`${tag.value}-${index}`}
                      selected={tag.selected}
                      onClick={() => handleSelectTag(tag)}
                      disabled={isSaveLoading}
                    >
                      {tag.name}
                    </SelectButton>
                  ))
                )}
              </S.Categories>
              {formErrors.tags && <S.TextError>{formErrors.tags}</S.TextError>}
            </Box>
          </S.MenuForm>
        </S.Content>
      </ModalOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
          style={{ marginTop: '1rem' }}
        >
          <Button
            color="white"
            size="large"
            disabled={isSaveLoading}
            onClick={() => closeEditSettingsTag()}
            shadow
          >
            Cancel
          </Button>

          <Button
            size="large"
            disabled={isSaveLoading}
            onClick={saveTagsSelected}
            loading={isSaveLoading}
            style={{ marginLeft: 'auto' }}
          >
            Save
          </Button>
        </Box>
      </ModalFooter>
    </>
  )
}

export default EditSettingsTagModal

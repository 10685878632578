/* eslint-disable @typescript-eslint/no-explicit-any */
import 'react-tabs-scrollable/dist/rts.css'

import { FunctionComponent, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Tab, Tabs as ReactTabs, TabScreen } from 'react-tabs-scrollable'

import * as S from './styles'

type TabItem = {
  id: string
  title: string
  content: React.ReactNode
  route?: string
}

export type TabsProps = {
  tabs: TabItem[]
  activeTabProp?: number
  style?: React.CSSProperties
  className?: string
}

const Tabs = ({ tabs, activeTabProp = 0, ...rest }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(activeTabProp)
  const navigate = useNavigate()

  const onTabClick = (index: number) => {
    const tab = tabs[index]

    if (tab.route) {
      navigate(tab.route)
    }
    setActiveTab(index)
  }

  return (
    <S.Wrapper {...rest}>
      <ReactTabs
        activeTab={activeTab}
        onTabClick={(_: any, index: any) => {
          onTabClick(index)
        }}
        leftBtnIcon={
          (<FiChevronLeft size={'1.5em'} />) as unknown as FunctionComponent<
            Record<any, any>
          >
        }
        rightBtnIcon={
          (<FiChevronRight size={'1.5em'} />) as unknown as FunctionComponent<
            Record<any, any>
          >
        }
      >
        {tabs.map((item) => (
          <Tab key={item.id}>{item.title}</Tab>
        ))}
      </ReactTabs>

      {tabs.map((item) => (
        <TabScreen
          key={item.id}
          activeTab={activeTab + 1}
          index={Number(item.id)}
        >
          {item.content}
        </TabScreen>
      ))}
    </S.Wrapper>
  )
}

export default Tabs

import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { Collapsible, CollapsibleTrigger } from '@/components/Collapsible'

export const Wrapper = styled.div`
  min-height: 31.25rem;
`

type DraggingProps = {
  isDragging?: boolean
}

type DraggingOverProps = {
  isDraggingOver?: boolean
}

export const ListItem = styled.li<DraggingProps>`
  ${({ theme, isDragging = false }) => css`
    display: flex;
    align-items: center;
    user-select: none;
    background: ${isDragging ? theme.colors.white : 'tansparent'};
    border-bottom: ${isDragging ? 'none' : '1px solid #dcdcdc'};
    padding: ${theme.spacings.xsmall} 0;
    border-radius: ${isDragging ? theme.border.radius.xsmall : 0};
    opacity: ${isDragging ? '0.8' : 1};

    &:last-child {
      border-bottom: ${isDragging ? '1px solid #dcdcdc' : 'none'};
    }

    > div {
      width: 100%;
    }
  `}
`

export const Colum = styled.div<{
  width?: string
  align?: 'start' | 'center' | 'end'
  header?: boolean
  item?: boolean
  mobile?: boolean
}>`
  ${({
    theme,
    width = '100%',
    align = 'start',
    header = false,
    item = false,
    mobile = false,
  }) => css`
    width: ${width};
    display: flex;
    align-items: center;
    justify-content: ${align};

    ${item &&
    css`
      padding: ${theme.spacings.xsmall} 0;
    `}

    ${header &&
    css`
      font-family: ${theme.font.nunito};
      font-weight: ${theme.font.bold};
      font-size: ${theme.font.sizes.small};
      line-height: 1.188rem;
      color: ${theme.colors.black};

      ${media.lessThan('medium')`
        font-size: ${theme.font.sizes.xsmall};
        line-height: 1rem;
      `}
    `}

    ${media.lessThan('medium')`
      padding: 0;

      ${
        mobile &&
        css`
          width: 50%;
        `
      }
    `}
  `}
`

export const DragIcon = styled.span`
  color: #dcdcdc;
  display: flex;
  justify-content: center;
`

export const ContainerCategories = styled.div<DraggingOverProps>`
  ${({ theme, isDraggingOver = false }) => css`
    background: ${isDraggingOver ? theme.colors.white : 'inherit'};
    border-radius: ${theme.border.radius.xsmall};
    border: ${isDraggingOver ? `1px dashed ${theme.colors.lightGray}` : 0};
  `}
`
export const CategoryItem = styled.div<DraggingProps>`
  ${({ theme, isDragging = false }) => css`
    user-select: none;
    background: ${isDragging ? theme.colors.white : 'inherit'};
    border-radius: ${isDragging ? theme.border.radius.xsmall : 0};
    opacity: ${isDragging ? '0.8' : 1};
  `}
`
export const CollapsibleCategories = styled(Collapsible)`
  ${({ theme }) => css`
    padding: 1.5rem;
    background: ${theme.colors.mainBg};
    border-radius: 16px;
    margin-bottom: ${theme.spacings.xsmall};

    ${media.lessThan('medium')`
      background: ${theme.colors.white};
      padding: 0;
    `}
  `}
`
export const TitleTriggerCategories = styled(CollapsibleTrigger)`
  ${({ theme }) => css`
    font-family: ${theme.font.nunito};
    font-weight: ${theme.font.bold};
    font-size: ${theme.font.sizes.medium};
    line-height: 21.82px;
    letter-spacing: -0.17px;
    color: ${theme.colors.primary};
    cursor: pointer;

    display: flex;
    align-items: center;
  `}
`

export const ContainerItems = styled.div<DraggingOverProps>`
  ${({ theme, isDraggingOver = false }) => css`
    background: ${isDraggingOver ? theme.colors.white : 'inherit'};
    border-radius: ${theme.border.radius.xsmall};
    border: ${isDraggingOver ? `1px dashed ${theme.colors.lightGray}` : 0};
  `}
`
export const Item = styled.div<DraggingProps>`
  ${({ theme, isDragging = false }) => css`
    user-select: none;
    background: ${isDragging ? theme.colors.mainBg : 'inherit'};
    border-radius: ${isDragging ? theme.border.radius.xsmall : 0};
    opacity: ${isDragging ? '0.8' : 1};
    padding: ${theme.spacings.xsmall} 0;
    padding-left: ${theme.spacings.small};
    border-bottom: 1px solid #dcdcdc;

    &:last-child {
      border-bottom: ${isDragging ? '1px solid #dcdcdc' : 'none'};
    }

    ${media.lessThan('medium')`
      padding: 0.25rem;
    `}
  `}
`

export const ContainerModifiers = styled.div<DraggingOverProps>`
  ${({ theme, isDraggingOver = false }) => css`
    background: ${isDraggingOver ? theme.colors.white : 'inherit'};
    border-radius: ${theme.border.radius.xsmall};
    border: ${isDraggingOver ? `1px dashed ${theme.colors.lightGray}` : 0};
  `}
`
export const ModifierItem = styled.div<DraggingProps>`
  ${({ theme, isDragging = false }) => css`
    display: flex;
    user-select: none;
    background: ${isDragging ? theme.colors.mainBg : 'inherit'};
    border-radius: ${isDragging ? theme.border.radius.xsmall : 0};
    opacity: ${isDragging ? '0.8' : 1};
    padding-left: 3.75rem;
    height: 3.375rem;

    &:first-child {
      margin-top: 0.625rem;
    }

    ${media.lessThan('medium')`
      padding-left: 1rem;
      height: 2.375rem;

      &:first-child {
        margin-top: 0.5rem;
      }
    `}
  `}
`

export const Image = styled.div<{ bg: string }>`
  ${({ theme, bg }) => css`
    width: 2.5rem;
    height: 2.5rem;
    background: ${theme.colors.primary};
    background-image: ${bg
      ? `url(${bg.indexOf('%') !== -1 ? bg : encodeURI(bg)})`
      : 'none'};
    background-size: cover;
    border-radius: 9px;
  `}
`

export const Name = styled.h3`
  ${({ theme }) => css`
    font-family: ${theme.font.quicksand};
    font-weight: ${theme.font.regular};
    font-size: ${theme.font.sizes.medium};
    line-height: 1.25rem;
    color: ${theme.colors.black};

    ${media.lessThan('medium')`
      font-size: ${theme.font.sizes.xsmall};
      line-height: 0.938rem;
    `}
  `}
`

export const ModifierLength = styled.h3`
  ${({ theme }) => css`
    font-family: ${theme.font.quicksand};
    font-weight: ${theme.font.regular};
    font-size: ${theme.font.sizes.medium};
    line-height: 1.25rem;
    color: ${theme.colors.black};

    ${media.lessThan('medium')`
      font-size: ${theme.font.sizes.xsmall};
    `}
  `}
`

type LinesProps = DraggingProps & DraggingOverProps & { last?: boolean }

export const Lines = styled.div<LinesProps>`
  ${({
    theme,
    last = false,
    isDragging = false,
    isDraggingOver = false,
  }) => css`
    height: 3.375rem;
    width: 0.125rem;
    background: ${theme.colors.lightGray};
    position: relative;
    opacity: ${isDragging || isDraggingOver ? 0 : 1};

    ${last &&
    css`
      &::before {
        content: '';
        height: 1.688rem;
        width: 0.125rem;
        background: ${theme.colors.mainBg};
        position: absolute;
        top: 48%;

        ${media.lessThan('medium')`
          background: ${theme.colors.white};
        `}
      }
    `}

    &::after {
      content: '';
      height: 0.125rem;
      width: 1.25rem;
      background: ${theme.colors.lightGray};
      position: absolute;
      top: 47%;

      ${media.lessThan('medium')`
        width: 0.625rem;
      `}
    }

    ${media.lessThan('medium')`
      height: 2.375rem;
    `}
  `}
`

export const TableHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 1rem 0 0.625rem ${theme.spacings.large};

    ${media.lessThan('medium')`
      padding-bottom: 0;
    `}
  `}
`

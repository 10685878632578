import { createContext, ReactNode, useState } from 'react'

export interface FormContextData<T> {
  dataForm: T
  setStepFormValues: (values: T) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormStepContext = createContext({} as FormContextData<any>)

interface FormStepProviderProps {
  children: ReactNode
}

export default function FormStepProvider<T>({
  children,
}: FormStepProviderProps) {
  const [dataForm, setDataForm] = useState<T>({} as T)

  const setStepFormValues = (values: T) => {
    setDataForm((prevValues) => ({
      ...prevValues,
      ...values,
    }))
  }

  return (
    <FormStepContext.Provider value={{ dataForm, setStepFormValues }}>
      {children}
    </FormStepContext.Provider>
  )
}

import 'react-datepicker/dist/react-datepicker.css'

import {
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
  sub,
} from 'date-fns'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'

import Dropdown from '@/components/Dropdown'
import TextField from '@/components/TextField'

import * as S from './styles'

export type DateRange = { start: string; end: string }

export type DateRangePickerProps = {
  start?: Date
  end?: Date
  label?: string
  labelFor?: string
  position?: 'left' | 'center' | 'right'
  wrapLabel?: boolean
  loading?: boolean
  onSelectDate: (dates: [Date, Date]) => void
}

const rangeDataMapper: { [key: number]: [Date, Date] } = {
  1: [new Date(), new Date()],
  2: [sub(new Date(), { days: 1 }), sub(new Date(), { days: 1 })],
  3: [sub(new Date(), { days: 7 }), new Date()],
  4: [
    sub(startOfWeek(new Date(), { weekStartsOn: 6 }), { days: 7 }),
    sub(endOfWeek(new Date(), { weekStartsOn: 6 }), { days: 7 }),
  ],
  5: [startOfMonth(new Date()), endOfMonth(new Date())],
  6: [
    sub(startOfMonth(new Date()), { months: 1 }),
    sub(endOfMonth(new Date()), { months: 1 }),
  ],
}

const menuIdSelectedByRange = (dateRange: [Date, Date]) => {
  const [startRange, endRange] = dateRange
  const today = format(new Date(), 'yyyy-MM-dd')
  const start = format(new Date(startRange), 'yyyy-MM-dd')
  const end = format(new Date(endRange), 'yyyy-MM-dd')
  const yesterday = format(sub(new Date(), { days: 1 }), 'yyyy-MM-dd')
  const sevenDays = format(sub(new Date(), { days: 7 }), 'yyyy-MM-dd')
  const startLastPay = format(
    sub(startOfWeek(new Date(), { weekStartsOn: 6 }), { days: 7 }),
    'yyyy-MM-dd',
  )
  const endWeek = format(
    sub(endOfWeek(new Date(), { weekStartsOn: 6 }), { days: 7 }),
    'yyyy-MM-dd',
  )
  const startMonth = format(startOfMonth(new Date()), 'yyyy-MM-dd')
  const endMonth = format(endOfMonth(new Date()), 'yyyy-MM-dd')

  const startLastMonth = format(
    sub(startOfMonth(new Date()), { months: 1 }),
    'yyyy-MM-dd',
  )
  const endLastMonth = format(
    sub(endOfMonth(new Date()), { months: 1 }),
    'yyyy-MM-dd',
  )

  if (start === today && end === today) {
    return 1
  } else if (start === yesterday && end === yesterday) {
    return 2
  } else if (start === sevenDays && end === today) {
    return 3
  } else if (start === startLastPay && end === endWeek) {
    return 4
  } else if (start === startMonth && end === endMonth) {
    return 5
  } else if (start === startLastMonth && end === endLastMonth) {
    return 6
  } else {
    return 7
  }
}

const DateRangePicker = ({
  start = new Date(),
  end = new Date(),
  label,
  labelFor,
  position = 'right',
  wrapLabel = false,
  loading = false,
  onSelectDate,
}: DateRangePickerProps) => {
  const [dateRange, setDateRange] = useState<[Date, Date]>([start, end])
  const [isCustom, setIsCustom] = useState(false)
  const [menuIdSelected, setMenuIdSelected] = useState(
    menuIdSelectedByRange(dateRange),
  )
  const [startDate, endDate] = dateRange

  const handleMenuSelected = (id: number, close?: () => void) => {
    setMenuIdSelected(id)
    setDateRange(rangeDataMapper[id])
    close && close()
  }

  const onClose = () => {
    setIsCustom(false)
  }

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      onSelectDate(dateRange)
      setIsCustom(false)
    }
  }, [dateRange, onSelectDate])

  const renderTextLabel = () => (
    <TextField
      value={`${format(new Date(dateRange[0]), 'MMM d, yyyy')} - ${format(
        new Date(dateRange[1]),
        'MMM d, yyyy',
      )}`}
      readOnly
    />
  )

  return (
    <S.Wrapper wrapLabel={wrapLabel}>
      {!!label && <S.Label htmlFor={labelFor || ''}>{label}</S.Label>}
      <Dropdown title={renderTextLabel()} position={position} onClose={onClose}>
        {(close) =>
          isCustom ? (
            <S.DatepickerWrapper>
              <DatePicker
                startDate={startDate}
                endDate={endDate}
                dateFormat="MMMM d, yyyy"
                monthsShown={2}
                onChange={(update) => {
                  if (update && Array.isArray(update)) {
                    const [startDate, endDate] = update
                    setMenuIdSelected(7)
                    setDateRange([startDate as Date, endDate as Date])
                    if (startDate && endDate) close()
                  }
                }}
                id={labelFor || ''}
                className="react-datepicker"
                selectsRange
                inline
              />
            </S.DatepickerWrapper>
          ) : (
            <S.MenuList>
              <S.MenuItem
                selected={menuIdSelected === 1}
                onClick={() => handleMenuSelected(1, close)}
                disabled={loading}
              >
                Today
              </S.MenuItem>
              <S.MenuItem
                selected={menuIdSelected === 2}
                onClick={() => handleMenuSelected(2, close)}
                disabled={loading}
              >
                Yesterday
              </S.MenuItem>
              <S.MenuItem
                selected={menuIdSelected === 3}
                onClick={() => handleMenuSelected(3, close)}
                disabled={loading}
              >
                Last 7 days
              </S.MenuItem>
              <S.MenuItem
                selected={menuIdSelected === 4}
                onClick={() => handleMenuSelected(4, close)}
                disabled={loading}
              >
                Last pay period
              </S.MenuItem>
              <S.MenuItem
                selected={menuIdSelected === 5}
                onClick={() => handleMenuSelected(5, close)}
                disabled={loading}
              >
                This Month
              </S.MenuItem>
              <S.MenuItem
                selected={menuIdSelected === 6}
                onClick={() => handleMenuSelected(6, close)}
                disabled={loading}
              >
                Last Month
              </S.MenuItem>
              <S.MenuItem
                selected={menuIdSelected === 7}
                onClick={() => {
                  setMenuIdSelected(7)
                  setIsCustom(true)
                }}
                disabled={loading}
              >
                Custom range
              </S.MenuItem>
            </S.MenuList>
          )
        }
      </Dropdown>
    </S.Wrapper>
  )
}

export default DateRangePicker

import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import Button from '@/components/Button'
import { Input, Wrapper as InputWrapper } from '@/components/TextField/styles'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${InputWrapper} {
    width: 4.688rem;
  }

  ${Input} {
    text-align: right;
    width: 4.688rem;
  }
`

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacings.medium} ${theme.spacings.small};
    margin-bottom: ${theme.spacings.small};
  `}
`

export const SavedItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 1.5rem;
  }
`

export const SavedItemContainer = styled.div`
  display: flex;
  gap: 1rem;

  ${media.lessThan('medium')`
      flex-direction: column;
      gap: 0.25rem;
  `}
`

export const SavedItem = styled.div`
  ${({ theme }) => css`
    width: 100%;

    h5 {
      font-weight: ${theme.font.bold};
      font-size: ${theme.font.sizes.xsmall};
      margin-bottom: 0.5rem;
    }

    ${media.greaterThan('medium')`
      min-width: 4.375rem;
    `}
  `}
`

export const SavedDescription = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.font.medium};
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.gray};
  `}
`

export const SavedText = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.font.medium};
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.black};
  `}
`

export const Actions = styled.div`
  min-width: 5rem;
`

export const ButtonIcon = styled(Button)`
  padding: 0.5rem;
`

export const ButtonAdd = styled(Button)`
  ${({ theme }) => css`
      font-weight: ${theme.font.medium};
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.primary};
      border-radius: 0;
      padding: 0 0.25rem;
      margin-top: ${theme.spacings.xsmall};
    }
  `}
`

export const Subtitle = styled.h3`
  ${({ theme }) => css`
    font-weight: ${theme.font.semiBold};
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.black};
    margin: 0.813rem 0 1rem;
  `}
`

import styled, { css } from 'styled-components'

import { Input, Wrapper as WrapperInput } from '@/components/TextField/styles'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const FormList = styled.ul`
  list-style: none;
`

export const FormListItem = styled.li`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${theme.spacings.xsmall};
    padding: ${theme.spacings.xsmall} 0.5rem;
    border-bottom: 1px solid #dcdcdc;

    ${WrapperInput} {
      width: 40%;
    }

    ${Input} {
      text-align: right;
    }

    &:last-child {
      border-bottom: none;
      margin-bottom: ${theme.spacings.xsmall};
    }
  `}
`

export const FormListItemTitle = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.gray};
  `}
`

export const Action = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const ButtonIcon = styled.span`
  border: none;
  padding: 0;
`

export const ItemLabel = styled.p<{ gray?: boolean; initials?: boolean }>`
  ${({ theme, gray = false, initials = false }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${gray ? theme.colors.gray : theme.colors.superLight};

    ${initials &&
    css`
      font-family: ${theme.font.nunito};
      background-color: #3798ff;
      border-radius: 12px;
      padding: 0.125rem 0.625rem;
      color: ${theme.colors.white};
      font-weight: ${theme.font.semiBold};
      letter-spacing: -0.58px;
    `}
  `}
`

export const ImageWrapper = styled.div<{ logo?: string }>`
  ${({ logo = '' }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dcdcdc;
    width: 6.938rem;
    height: 6.938rem;
    border-radius: 99999px;

    ${!!logo &&
    css`
      background-image: url('${logo}');
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    `}
  `}
`

export const InputFile = styled.input`
  display: none;
`

export const UploadButton = styled.div`
  ${({ theme }) => css`
    border: 0;
    color: ${theme.colors.primary};
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 99999px;
    padding: 1.625rem;
    cursor: pointer;
    transition: background-color ${theme.transition.default};

    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
    }
  `}
`

export const ImagePictureWrapper = styled(ImageWrapper)<{ picture?: string }>`
  ${({ theme, picture = '' }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dcdcdc;
    width: 100%;
    height: 9.063rem;
    border-radius: ${theme.border.radius.xlarge};

    ${!!picture &&
    css`
      background-image: url('${picture}');
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    `}
  `}
`

export const UploadPictureButton = styled(UploadButton)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: ${theme.border.radius.xlarge};
    width: 100%;
    padding: 2.063rem;
  `}
`

import { zodResolver } from '@hookform/resolvers/zod'
import { ChevronLeft } from '@styled-icons/material-outlined'
import _ from 'lodash'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import editIcon from '@/assets/img/icon-edit.svg'
import othersPriceIcon from '@/assets/img/icons-others-price.svg'
import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { StepForm, StepOverflow } from '@/components/FormStep'
import { useFormStepData } from '@/components/FormStep/Hook'
import {
  ModalFooter,
  ModalHeader,
  ModalHeaderClose,
  ModalHeaderTitle,
} from '@/components/Modal'
import Pill from '@/components/Pill'
import Switch from '@/components/Switch'
import { Permission } from '@/enums/permission.enum'
import useAuth from '@/hooks/useAuth'
import { api } from '@/lib/axios'
import {
  Amount,
  FieldContent,
  FieldError,
  FieldHeader,
  FieldHeaderActions,
  FieldHeaderTitle,
  FieldWrapper,
  WrapperContent,
} from '@/modals/CreatingNewCampaignModal/styles'
import { CardType } from '@/types/payment.types'
import { CampaignList } from '@/types/super-partner.types'
import { formatCurrency, formatExtNumber } from '@/utils/formats/number'

import { MagicVoucherField } from '../../MagicVoucherField'
import { PaymentField } from '../../PaymentField'
import { Total } from '../../Total'
import { BeforeType, Steps } from '..'
import { ShareVoucher } from '../ShareVoucher'
import { CampaignListInitialData, VoucherFormData } from '../VoucherForm'
import * as S from './styles'

export type CheckoutFormProps = {
  close: (isSuccess?: boolean) => void
  active: boolean
  activeStep: number
  before: BeforeType
  campaignItem: CampaignList<CampaignListInitialData> | null
  paymentMethod: CardType | null
  onBeforeStep: (before: BeforeType) => void
  onNextStep: () => void
  setSteps: Dispatch<SetStateAction<Steps[]>>
}

const checkoutFormSchema = z.object({
  payment: z.union([
    z.object(
      {
        cardNumberEnd: z.string(),
        defaultMethod: z.boolean(),
        gateway: z.string(),
        vaultId: z.string(),
      },
      { required_error: 'Payment is required' },
    ),
    z.null(),
  ]),
  magicVoucher: z
    .array(
      z.object({
        voucherId: z.string(),
        image: z.string(),
        title: z.string(),
        balance: z.number(),
        expiryDate: z.string(),
      }),
    )
    .optional(),
  voucherId: z.string().optional(),
  author: z.enum(['merchant', 'bento']),
  shareableInfo: z
    .object({
      message: z.string(),
      title: z.string(),
      link: z.string(),
    })
    .optional(),
})

type CheckoutFormData = z.infer<typeof checkoutFormSchema>
export type PaymentFormData = VoucherFormData & CheckoutFormData

export const CheckoutForm = ({
  close,
  active,
  activeStep,
  before,
  paymentMethod,
  campaignItem,
  onBeforeStep,
  onNextStep,
  setSteps,
}: CheckoutFormProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const { merchantSelected, user } = useAuth()
  const { t } = useTranslation()
  const { dataForm, setStepFormValues } = useFormStepData<PaymentFormData>()
  const isMaster = _.includes(user?.permissions, Permission.MASTER)

  const initialData = campaignItem?.initialData
  const hasPayment =
    !initialData?.removeAudiences &&
    !!(dataForm.audiences && dataForm.audiences?.length > 0)

  const {
    handleSubmit,
    control,
    setValue,
    resetField,
    watch,
    formState: { isValid },
  } = useForm<CheckoutFormData>({
    resolver: zodResolver(checkoutFormSchema),
    defaultValues: {
      payment: paymentMethod || undefined,
      magicVoucher: undefined,
      author: 'bento',
    },
  })

  const onSubmit = async (values: CheckoutFormData) => {
    try {
      setIsLoading(true)
      const data = { ...dataForm, ...values } as PaymentFormData

      const name = campaignItem?.name || ''
      const description = `Spend over ${formatCurrency(
        data?.minimumOrderValue / 100 || 0,
      )}, save ${formatCurrency(data.vouchers?.voucherShift / 100 || 0)}`

      const audiences =
        data?.audiences && data?.audiences.length > 0
          ? data?.audiences.map((audience) => audience.id)
          : []

      const dataToSave = {
        startAt: data.period.startAt.toISOString(),
        endAt: data.period.endAt.toISOString(),
        voucherShift: data.vouchers?.voucherShift,
        voucherQuantity: data.vouchers?.voucherQuantity,
        minimumOrderValue: data?.minimumOrderValue,
        audiences,
        author: audiences.length > 0 ? data.author : 'merchant',
        paymentMethod: data.payment,
        vouchers: data.magicVoucher?.map((voucher) => voucher.voucherId),
        usersPercentage: data.usersPercentage,
        itemIds: data.itemIds,
        name,
        description,
      }

      const { data: campaignCreated } = await api.post(
        `/superpartner/${merchantSelected?.id}/campaigns/voucher`,
        dataToSave,
      )

      const hasAudience = dataToSave.audiences?.length > 0

      if (initialData?.removeAudiences || !hasAudience) {
        setStepFormValues({
          ...data,
          voucherId: campaignCreated.voucherId,
          shareableInfo: campaignCreated.shareableInfo,
        })
        onNextStep()
      } else {
        close(true)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const calculateAudienceCount = useMemo(() => {
    if (!dataForm.usersPercentage || !dataForm.selectedCount) return 0
    return Number(
      ((dataForm.usersPercentage / 100) * dataForm.selectedCount).toFixed(0),
    )
  }, [dataForm.usersPercentage, dataForm.selectedCount])

  const magicVoucher = watch('magicVoucher')
  const author = watch('author')

  useEffect(() => {
    if (active) {
      if (!hasPayment || author === 'bento') {
        setValue('payment', null, {
          shouldValidate: true,
          shouldDirty: true,
        })
        resetField('magicVoucher')
      }
      if (!paymentMethod && author !== 'bento') {
        resetField('payment')
      }
      if (paymentMethod && author === 'merchant') {
        setValue('payment', paymentMethod, {
          shouldValidate: true,
          shouldDirty: true,
        })
      }
      if (!isMaster && author === 'bento') {
        setValue('author', 'merchant')
        resetField('payment')
        resetField('magicVoucher')
      }
    }
  }, [
    hasPayment,
    active,
    setValue,
    resetField,
    paymentMethod,
    author,
    isMaster,
  ])

  useEffect(() => {
    if (!dataForm.audiences || dataForm.audiences?.length === 0) {
      setSteps((old) => {
        const hasShareStep = old.some(
          (step) => Object.keys(step)[0] === 'share',
        )

        if (hasShareStep) {
          return [...old]
        } else {
          return [
            ...old,
            {
              share: (
                <ShareVoucher
                  key="share-voucher"
                  active={activeStep === 2}
                  close={close}
                />
              ),
            },
          ]
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, dataForm.audiences])

  return (
    <StepForm onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>
        <S.CustomCloseButton
          onClick={() => onBeforeStep({ active: true, field: '' })}
        >
          <ChevronLeft size={32} />
        </S.CustomCloseButton>

        <ModalHeaderTitle>Checkout</ModalHeaderTitle>

        <ModalHeaderClose
          onClick={(event) => {
            event.preventDefault()
            close()
          }}
        >
          ✕
        </ModalHeaderClose>
      </ModalHeader>

      <StepOverflow active={active ? 1 : 0} before={before.active ? 1 : 0}>
        <WrapperContent>
          {dataForm && dataForm.audiences && dataForm.audiences?.length > 0 && (
            <FieldWrapper>
              <FieldHeader checkout={1}>
                <FieldHeaderTitle>
                  Audience - {dataForm.usersPercentage}%
                </FieldHeaderTitle>

                <FieldHeaderActions>
                  <S.ButtonIcon
                    minimal
                    disabled={isLoading}
                    onClick={() =>
                      onBeforeStep({ active: true, field: 'audiences' })
                    }
                  >
                    <img
                      src={editIcon}
                      width={14}
                      height={14}
                      alt="Edit icon"
                    />
                  </S.ButtonIcon>
                </FieldHeaderActions>
              </FieldHeader>

              <FieldContent>
                <Box flex alignItems="center" gap="0.875rem" flexWrap="wrap">
                  {dataForm.audiences.map((audience) => (
                    <Pill
                      key={audience.id}
                      avatar={
                        <Amount>~{formatExtNumber(audience.count, 0)}</Amount>
                      }
                      removable={false}
                    >
                      {audience.name}
                    </Pill>
                  ))}
                </Box>
              </FieldContent>
            </FieldWrapper>
          )}

          <FieldWrapper>
            <FieldHeader checkout={1}>
              <FieldHeaderTitle>Vouchers</FieldHeaderTitle>

              <FieldHeaderActions>
                <S.ButtonIcon
                  minimal
                  disabled={isLoading}
                  onClick={() =>
                    onBeforeStep({ active: true, field: 'vouchers' })
                  }
                >
                  <img src={editIcon} width={14} height={14} alt="Edit icon" />
                </S.ButtonIcon>
              </FieldHeaderActions>
            </FieldHeader>

            <FieldContent>
              <Box flex alignItems="center" gap="0.875rem">
                <img
                  src={othersPriceIcon}
                  width={20}
                  height={20}
                  alt="Others price icon"
                />
                <S.TotalText>
                  ${dataForm?.vouchers?.voucherShift / 100} off per order -{' '}
                  {dataForm?.vouchers?.voucherQuantity}{' '}
                  {t(
                    'modals.creating-new-campaign.checkoutStep.vouchers.voucherQuantity',
                    {
                      count: dataForm?.vouchers?.voucherQuantity,
                    },
                  )}{' '}
                  | <span>${dataForm?.vouchers?.totalSpent / 100}</span>
                </S.TotalText>
              </Box>
            </FieldContent>
          </FieldWrapper>

          {isMaster && hasPayment && (
            <FieldWrapper>
              <FieldHeader checkout={1}>
                <FieldHeaderTitle>Create campaign as Bento</FieldHeaderTitle>

                <FieldHeaderActions>
                  <Controller
                    name="author"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Switch
                          onChange={(checked) =>
                            field.onChange(checked ? 'bento' : 'merchant')
                          }
                          checked={field.value === 'bento'}
                        />
                        {error?.message && (
                          <FieldError>{error?.message}</FieldError>
                        )}
                      </>
                    )}
                  />
                </FieldHeaderActions>
              </FieldHeader>

              <FieldContent style={{ marginTop: '0.25rem' }}>
                <S.CampaignAsBentoText>
                  The Magic Vouchers generated through this will be applied
                  towards Bento expenses, not towards the partner.
                </S.CampaignAsBentoText>
              </FieldContent>
            </FieldWrapper>
          )}

          {hasPayment && (
            <Controller
              name="payment"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <PaymentField
                  value={field.value || undefined}
                  onChange={field.onChange}
                  error={error?.message}
                  loading={isLoading}
                  disabled={author === 'bento'}
                />
              )}
            />
          )}

          {typeof dataForm?.total !== 'undefined' && dataForm?.total > 0 && (
            <Controller
              name="magicVoucher"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <MagicVoucherField
                  value={field.value}
                  onChange={field.onChange}
                  error={error?.message}
                  loading={isLoading}
                  disabled={author === 'bento'}
                />
              )}
            />
          )}
        </WrapperContent>
      </StepOverflow>

      <ModalFooter>
        <Total
          audiences={dataForm.audiences || []}
          total={dataForm.total || 0}
          selectedCount={calculateAudienceCount || 999}
          magicVoucher={magicVoucher}
        />

        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
        >
          <Button
            size="large"
            color="white"
            disabled={isLoading}
            onClick={() => close()}
            shadow
          >
            Cancel
          </Button>
          <Button
            size="large"
            type="submit"
            disabled={isLoading || !isValid}
            loading={isLoading}
          >
            Create
          </Button>
        </Box>
      </ModalFooter>
    </StepForm>
  )
}

import { darken, lighten } from 'polished'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ open?: boolean }>`
  ${({ theme, open = false }) => css`
    position: fixed;
    top: 0.625rem;
    left: 50%;
    min-width: 21.875rem;
    padding: ${theme.spacings.xxsmall} ${theme.spacings.medium}
      ${theme.spacings.xsmall};
    border-radius: ${theme.border.radius.xsmall};
    margin-bottom: ${theme.spacings.small};
    background-color: #2e2a2a;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    border-radius: 150px;
    border: 1px solid ${darken(0.35, theme.colors.lightGray)};
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 102;
    transition: transform 0.2s ease-in-out;

    transform: translateY(${open ? '0' : '-130%'}) translateX(-50%);
  `}
`

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    color: ${lighten(1, theme.colors.secondary)};
  `}
`

export const Content = styled.div`
  text-align: center;
`

export const Title = styled.p`
  ${({ theme }) => css`
    color: ${lighten(1, theme.colors.secondary)};
    line-height: 1.6;
  `}
`

export const Message = styled.p`
  ${({ theme }) => css`
    color: ${lighten(0.5, theme.colors.secondary)};
  `}
`

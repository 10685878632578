/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, DialogContent } from '@iguanads/react'
import { Exposure, SaveAlt } from '@styled-icons/material-outlined'
import { endOfDay, startOfDay } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import MediaQuery, { useMediaQuery } from 'react-responsive'
import ReactTooltip from 'react-tooltip'
import { useTheme } from 'styled-components'

import configsToStorageService from '@/api/configs-to-storage.service'
import dashboardService from '@/api/dashboard.service'
import ordersService from '@/api/orders.service'
import performanceDelivery from '@/assets/img/icons-performance-delivery.svg'
import performanceDeliveryLight from '@/assets/img/icons-performance-delivery-light.svg'
import performanceTakeout from '@/assets/img/icons-performance-takeout.svg'
import performanceTakeoutLight from '@/assets/img/icons-performance-takeout-light.svg'
import { Box } from '@/components/Box'
import Button from '@/components/Button'
import DateRangePicker from '@/components/DateRangePicker'
import Empty from '@/components/Empty'
import { InputFilter } from '@/components/InputFilter'
import { Modal, ModalContent } from '@/components/Modal'
import Pagination from '@/components/Pagination'
import Skeleton from '@/components/Skeleton'
import { getColorModifier, statusModifiers, TypeEnum } from '@/components/Table'
import * as T from '@/components/Table/styles'
import TableData from '@/components/TableData'
import { OrderDetailDialog } from '@/dialogs/order-detail.dialog'
import { Permission } from '@/enums/permission.enum'
import { useDialog } from '@/hooks/use-dialog'
import useAuth from '@/hooks/useAuth'
import useModal from '@/hooks/useModal'
import { AdditionsDeductionsModal } from '@/modals/AdditionsDeductionsModal'
import { DashboardType } from '@/types/dashboard.types'
import { OrderType } from '@/types/orders.types'
import { formatDistanceToNow } from '@/utils/formats/date'
import { downloadCSV } from '@/utils/formats/exportCsv'
import { formatCurrencyWithCode } from '@/utils/formats/number'

import * as S from './styles'

const columnsToExportBentoDeliveryHandler = [
  { key: 'type', name: 'Mode' },
  { key: 'shortId', name: 'ID' },
  { key: 'user.name', name: 'Customer' },
  { key: 'lastStatus', name: 'Status' },
  { key: 'total', name: 'Total KYD' },
  { key: 'netSale', name: 'Net KYD' },
  { key: 'tip', name: 'Tip KYD' },
  { key: 'createdAt', name: 'Date' },
  { key: 'refundAmount', name: 'Refund KYD' },
  { key: 'refundComment', name: 'Refund Info' },
  { key: 'magicVouchers', name: 'Merchant Voucher' },
]
const columnsToExportMerchantDeliveryHandler = [
  { key: 'type', name: 'Mode' },
  { key: 'shortId', name: 'ID' },
  { key: 'user.name', name: 'Customer' },
  { key: 'lastStatus', name: 'Status' },
  { key: 'subTotal', name: 'SubTotal' },
  { key: 'deliveryFee', name: 'Delivery fee KYD' },
  { key: 'total', name: 'Total KYD' },
  { key: 'netSale', name: 'Net KYD' },
  { key: 'tip', name: 'Tip KYD' },
  { key: 'createdAt', name: 'Date' },
  { key: 'refundAmount', name: 'Refund KYD' },
  { key: 'refundComment', name: 'Refund Info' },
  { key: 'magicVouchers', name: 'Merchant Voucher' },
]

type TextFieldWrapperProps = {
  isLoading: boolean
  hasError: boolean
  orders: OrderType[]
  handleChangeFilter: (search: string) => void
}

const PAGE_SIZE = 50

const TextFieldWrapper = ({
  isLoading,
  hasError,
  orders,
  handleChangeFilter,
}: TextFieldWrapperProps) => (
  <S.TextFieldWrapper>
    <InputFilter
      onFilterChange={handleChangeFilter}
      loading={isLoading || hasError || orders.length === 0}
    />
  </S.TextFieldWrapper>
)

type DateWrapperProps = {
  dateRange: [Date, Date]
  labelFor: string
  isMobile: boolean
  wrapLabel?: boolean
  loading?: boolean
  handleDate: (ranges: [Date, Date]) => void
}

const DateWrapper = ({
  dateRange,
  labelFor,
  isMobile = false,
  wrapLabel = false,
  loading = false,
  handleDate,
}: DateWrapperProps) => (
  <S.DateWrapper>
    <DateRangePicker
      label="Choose period"
      labelFor={labelFor}
      start={dateRange[0]}
      end={dateRange[1]}
      onSelectDate={handleDate}
      wrapLabel={wrapLabel}
      position={isMobile ? 'left' : 'right'}
      loading={loading}
    />
  </S.DateWrapper>
)

export function History() {
  const [orderDetailData, setOrderDetailData] = useState<OrderType>()
  const [orders, setOrders] = useState<OrderType[]>([])
  const [filteredOrders, setFilteredOrders] = useState<OrderType[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const theme = useTheme()
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    new Date(),
    new Date(),
  ])
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  })
  const { open: modalIsOpen, toggle: setModalIsOpen } = useDialog()
  const { merchantsSelected, merchantSelected, timezone } = useAuth()

  const onFilterChange = useCallback(
    (search: string) => {
      if (!search) {
        setFilteredOrders(orders)
        return
      }

      const users = orders.filter(
        ({ user }) =>
          user.name && user.name.toLowerCase().includes(search.toLowerCase()),
      )
      const ids = orders.filter(
        ({ shortId }) =>
          shortId && shortId.toLowerCase().includes(search.toLowerCase()),
      )

      setFilteredOrders([...users, ...ids])
    },
    [orders],
  )

  const [dashData, setDashData] = useState<DashboardType | null>(null)
  const { open: addDeduModalIsOpen, toggle: setAddDeduModalIsOpen } = useModal()
  const { user } = useAuth()

  const getDashboard = useCallback(
    async (ids: string[], [start, end]: [Date, Date]) => {
      const hasDashboardPermission = _.includes(
        user?.permissions,
        Permission.DASHBOARD,
      )

      if (!hasDashboardPermission) {
        return
      }

      setIsLoading(true)
      setHasError(false)

      const startDate = utcToZonedTime(start, timezone)
      const endDate = utcToZonedTime(end, timezone)

      try {
        const startISOString = startOfDay(startDate).toISOString()
        const endISOString = endOfDay(endDate).toISOString()

        const { data: responseData }: any =
          await dashboardService.multipleMerchantsDashboard(
            {
              start: startISOString,
              end: endISOString,
            },
            ids,
          )

        const dashboardData = responseData.map((item: { data: any }) => ({
          ...item.data,
        }))

        const data = dashboardData.reduce(
          (total: any, item: any) => {
            return {
              balance: [...total.balance, ...(item.balance || [])],
            }
          },
          {
            balance: [],
          },
        )

        setDashData(data)
      } catch (error) {
        setHasError(true)
      }
    },
    [timezone, user?.permissions],
  )

  const getOrders = useCallback(
    async (ids: string[], [start, end]: [Date, Date]) => {
      setIsLoading(true)
      setHasError(false)
      setFilteredOrders([])

      try {
        const { data: responseData }: any =
          await ordersService.getMultipleMerchantOrders(
            {
              start: startOfDay(start).toISOString(),
              end: endOfDay(end).toISOString(),
            },
            ids,
          )

        const dataMapped = responseData.map((item: any) => {
          return {
            data: item.data.map((order: any) => {
              return { ...order, merchantId: item.merchantId }
            }),
          }
        })

        const data = dataMapped.reduce((acc: any, item: { data: any }) => {
          return [...acc, ...item.data]
        }, [] as OrderType[])

        const orders = data.map((item: OrderType) => ({
          ...item,
          theme,
          total: item.total / 100,
          netSale: item.netSale / 100,
          subTotal: item.subTotal / 100,
          deliveryFee: item.deliveryFee ? item.deliveryFee / 100 : null,
          tip: item.tip
            ? {
                orderId: item.shortId,
                amount: item.tip.amount / 100,
              }
            : null,
          refundAmount: item.refundAmount ? item.refundAmount / 100 : null,
          magicVouchers: item.magicVouchers
            ? item.magicVouchers.map((voucher: { balance: number }) => ({
                ...voucher,
                balance: voucher?.balance / 100,
              }))
            : [],
          items: item.items?.map((itemChild) => ({
            ...itemChild,
            price: itemChild.price / 100,
            modifiers: itemChild.modifiers?.map((modifier) => ({
              ...modifier,
              options: modifier.options?.map((option) => ({
                ...option,
                price: option.price / 100,
              })),
            })),
          })),
        }))

        setOrders(orders)
      } catch (error) {
        setHasError(true)
        setOrders([])
        setFilteredOrders([])
      }
    },
    [theme],
  )

  const renderAdditionsDeductionsModal = () => {
    return (
      <Modal
        open={addDeduModalIsOpen}
        onOpenChange={setAddDeduModalIsOpen}
        modal
      >
        <ModalContent
          title="Additions & Deductions"
          onClose={() => setAddDeduModalIsOpen(false)}
        >
          <AdditionsDeductionsModal
            data={dashData?.balance || []}
            close={() => setAddDeduModalIsOpen(false)}
          />
        </ModalContent>
      </Modal>
    )
  }

  const openOrderDetail = (orderDetail: OrderType) => {
    setOrderDetailData(orderDetail)
    setModalIsOpen(true)
  }

  const closeOrderDetail = (isChanged?: boolean) => {
    if (isChanged && merchantsSelected.length > 0) {
      const merchants = merchantsSelected.map((m) => m.id)
      getOrders(merchants, dateRange)
      getDashboard(merchants, dateRange)
    }
    setModalIsOpen(false)
  }

  const renderOpenDetailModal = () => {
    if (!orderDetailData) return
    return (
      <Dialog open={modalIsOpen} onOpenChange={setModalIsOpen}>
        <DialogContent
          hideCloseButton
          onClose={() => setModalIsOpen(false)}
          aria-describedby="Order Detail"
        >
          <OrderDetailDialog
            order={orderDetailData}
            onClose={closeOrderDetail}
            setOrder={setOrderDetailData}
          />
        </DialogContent>
      </Dialog>
    )
  }

  const handleDate = useCallback(
    (ranges: [Date, Date]) => {
      const [start, end] = ranges
      const isNotEqualStart = dateRange[0] !== start
      const isNotEqualEnd = dateRange[1] !== end

      if (isNotEqualStart && isNotEqualEnd && merchantsSelected.length > 0) {
        setDateRange(ranges)
        const merchants = merchantsSelected.map((m) => m.id)
        getOrders(merchants, ranges)
        getDashboard(merchants, ranges)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateRange, getOrders, merchantsSelected],
  )

  const columns = useMemo(
    () => [
      {
        name: 'Mode',
        selector: (row: OrderType) => row.type,
        sortable: true,
        center: true,
        width: '60px',
        cell: (row: OrderType) => {
          if (row.theme.light)
            return (
              <img
                data-tag="allowRowEvents"
                src={
                  row.type === TypeEnum.DELIVERY
                    ? performanceDelivery
                    : performanceTakeout
                }
                width={32}
                height={32}
                alt={row.type}
              />
            )
          else {
            return (
              <img
                data-tag="allowRowEvents"
                src={
                  row.type === TypeEnum.DELIVERY
                    ? performanceDeliveryLight
                    : performanceTakeoutLight
                }
                width={32}
                height={32}
                alt={row.type}
              />
            )
          }
        },
      },
      {
        name: 'Customer',
        selector: (row: OrderType) => row.user.name,
        sortable: true,
        center: true,
        minWidth: '150px',
        cell: (row: OrderType) => (
          <div>
            <T.Client>
              <T.ClientName data-tag="allowRowEvents">
                {row.user.name}
              </T.ClientName>
              <T.ClientShortId data-tag="allowRowEvents">
                {row.shortId}
              </T.ClientShortId>
            </T.Client>
          </div>
        ),
      },
      {
        name: 'Status',
        selector: (row: OrderType) => row.lastStatus,
        sortable: true,
        center: true,
        minWidth: '85px',
        cell: (row: OrderType) => {
          const isCollected =
            row.driver && row.driver.tripStatus === 'collected'
          if (row.trip && isCollected) {
            return (
              <T.StatusWrapper
                data-tag="allowRowEvents"
                bgColor={getColorModifier('collected', theme)}
                style={{ color: theme.colors.secondary }}
              >
                {statusModifiers.collected}
              </T.StatusWrapper>
            )
          }

          return (
            <T.StatusWrapper
              data-tag="allowRowEvents"
              bgColor={getColorModifier(row.lastStatus, row.theme)}
            >
              {statusModifiers[row.lastStatus]}
            </T.StatusWrapper>
          )
        },
      },
      {
        name: 'SubTotal',
        selector: (row: OrderType) => row.subTotal,
        sortable: true,
        center: true,
        minWidth: '120px',
        omit: merchantSelected?.hasBentoDelivery,
        cell: (row: OrderType) => (
          <T.CurrencyWrapper data-tag="allowRowEvents">
            {formatCurrencyWithCode(row.subTotal)}
          </T.CurrencyWrapper>
        ),
      },
      {
        name: 'Delivery fee',
        selector: (row: OrderType) => row.deliveryFee,
        sortable: true,
        center: true,
        minWidth: '120px',
        omit: merchantSelected?.hasBentoDelivery,
        cell: (row: OrderType) => (
          <T.CurrencyWrapper data-tag="allowRowEvents">
            {formatCurrencyWithCode(row.deliveryFee || 0)}
          </T.CurrencyWrapper>
        ),
      },
      {
        name: 'Total',
        selector: (row: OrderType) => row.total,
        sortable: true,
        center: true,
        minWidth: '120px',
        cell: (row: OrderType) => {
          return (
            <T.CurrencyWrapper data-tag="allowRowEvents">
              {formatCurrencyWithCode(row.total)}
            </T.CurrencyWrapper>
          )
        },
      },
      {
        name: 'Tip',
        selector: (row: OrderType) => row.tip,
        sortable: true,
        center: true,
        minWidth: '120px',
        cell: (row: OrderType) => {
          let tip = row.tip ? formatCurrencyWithCode(row.tip?.amount ?? 0) : '-'
          tip =
            row.type === 'delivery' && row.deliveryHandler === 'bento'
              ? '-'
              : tip
          return (
            <T.CurrencyWrapper data-tag="allowRowEvents">
              {tip}
            </T.CurrencyWrapper>
          )
        },
      },
      {
        name: 'Date',
        selector: (row: OrderType) => row.createdAt,
        sortable: true,
        center: true,
        minWidth: '118px',
        cell: (row: OrderType) => (
          <T.DateWrapper data-tag="allowRowEvents">
            {formatDistanceToNow(new Date(row.createdAt), true, timezone)}
          </T.DateWrapper>
        ),
      },
      {
        name: 'Refund',
        selector: (row: OrderType) => row.refundAmount,
        sortable: true,
        center: true,
        minWidth: '123px',
        cell: (row: OrderType) => (
          <T.CurrencyWrapper
            data-tag="allowRowEvents"
            refund={!!row.refundAmount}
          >
            {row.refundAmount
              ? formatCurrencyWithCode(row.refundAmount * -1)
              : '-'}
          </T.CurrencyWrapper>
        ),
      },
      {
        name: 'Refund Info',
        selector: (row: OrderType) => row.refundComment,
        sortable: true,
        center: true,
        minWidth: '200px',
        cell: (row: OrderType) => {
          const refundComment = row.refundAmount ? row.refundComment : '-'
          return (
            <T.RefundInfoWrapper data-tag="allowRowEvents">
              {refundComment || '-'}
            </T.RefundInfoWrapper>
          )
        },
      },
      {
        name: 'Merchant Voucher',
        selector: (row: OrderType) => row.magicVouchers,
        sortable: true,
        center: true,
        width: '110px',
        cell: (row: OrderType) => {
          const sum = row.magicVouchers?.reduce((accumulator, currentValue) => {
            if (
              currentValue.author !== 'bento' &&
              currentValue.author === 'merchant'
            ) {
              return accumulator + currentValue.balance
            }
            return accumulator
          }, 0)

          return (
            <T.CurrencyWrapper data-tag="allowRowEvents" refund={!!sum}>
              {sum ? formatCurrencyWithCode(sum * -1) : 0}
            </T.CurrencyWrapper>
          )
        },
      },
    ],
    [merchantSelected?.hasBentoDelivery, timezone],
  )

  useEffect(() => {
    if (merchantsSelected.length > 0) {
      const merchants = merchantsSelected.map((m) => m.id)
      getOrders(merchants, dateRange)
      getDashboard(merchants, dateRange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrders, merchantsSelected])

  useEffect(() => {
    if (dateRange.length === 2) {
      configsToStorageService.saveDate(dateRange)
    }
  }, [dateRange])

  useEffect(() => {
    setFilteredOrders(orders)
    setIsLoading(false)
  }, [orders])

  return (
    <>
      <Helmet title="History" />
      <S.Wrapper>
        <S.Header>
          <MediaQuery minWidth={768}>
            <Box
              flex
              alignItems="center"
              JustifyContent="space-between"
              width="100%"
            >
              <h1>History</h1>

              <S.Actions>
                <TextFieldWrapper
                  isLoading={isLoading}
                  hasError={hasError}
                  orders={orders}
                  handleChangeFilter={onFilterChange}
                />
                <DateWrapper
                  dateRange={dateRange}
                  handleDate={handleDate}
                  isMobile={isMobile}
                  labelFor="period_1"
                  loading={isLoading}
                />

                <div>
                  <Button
                    onClick={() => {
                      downloadCSV(
                        orders || [],
                        merchantSelected?.hasBentoDelivery
                          ? columnsToExportBentoDeliveryHandler
                          : columnsToExportMerchantDeliveryHandler,
                        dateRange,
                      )
                    }}
                    disabled={isLoading || hasError || orders?.length === 0}
                  >
                    Download as CSV
                  </Button>
                </div>
              </S.Actions>
            </Box>
            {dashData?.balance && dashData?.balance.length > 0 && (
              <>
                <S.ButtonIcon
                  icon={<Exposure />}
                  color="white"
                  onClick={() => setAddDeduModalIsOpen(true)}
                >
                  Additions/Deductions
                </S.ButtonIcon>
              </>
            )}
          </MediaQuery>

          <MediaQuery maxWidth={768}>
            <Box
              flex
              alignItems="center"
              JustifyContent="space-between"
              width="100%"
            >
              <h1>Orders</h1>

              <TextFieldWrapper
                isLoading={isLoading}
                hasError={hasError}
                orders={orders}
                handleChangeFilter={onFilterChange}
              />
            </Box>
            <Box
              flex
              alignItems="flex-end"
              JustifyContent="space-between"
              width="100%"
              style={{ marginTop: '0.625rem' }}
            >
              <DateWrapper
                dateRange={dateRange}
                handleDate={handleDate}
                isMobile={isMobile}
                loading={isLoading}
                labelFor="period_2"
                wrapLabel
              />

              <S.MobileButtonWrapper>
                <Button
                  icon={<SaveAlt />}
                  onClick={() =>
                    downloadCSV(
                      orders || [],
                      merchantSelected?.hasBentoDelivery
                        ? columnsToExportBentoDeliveryHandler
                        : columnsToExportMerchantDeliveryHandler,
                      dateRange,
                    )
                  }
                  disabled={isLoading || hasError || orders?.length === 0}
                >
                  CSV
                </Button>
              </S.MobileButtonWrapper>
            </Box>
          </MediaQuery>
        </S.Header>

        <S.ContentTable>
          {isLoading ? (
            <>
              <Box
                flex
                alignItems="center"
                JustifyContent="space-between"
                width="100%"
                style={{ marginBottom: '1.75rem' }}
              >
                <Skeleton
                  width="2.5rem"
                  height="0.875rem"
                  count={5}
                  radius="4px"
                  direction="row"
                />
              </Box>

              <Skeleton
                height="2.5rem"
                count={7}
                radius="4px"
                margin="2.5rem"
              />
            </>
          ) : hasError ? (
            <Box
              flex
              JustifyContent="center"
              alignItems="center"
              width="100%"
              height="31.25rem"
            >
              <Empty
                title="Something Happened"
                description="Reload the page and try again"
              />
            </Box>
          ) : filteredOrders.length === 0 && orders.length === 0 ? (
            <Box
              flex
              JustifyContent="center"
              alignItems="center"
              width="100%"
              height="31.25rem"
            >
              <Empty
                title="There is nothing here, yet"
                description="Make sure you are available to receive orders"
              />
            </Box>
          ) : filteredOrders.length === 0 && orders.length > 0 ? (
            <Box
              flex
              JustifyContent="center"
              alignItems="center"
              width="100%"
              height="31.25rem"
            >
              <Empty
                title="No results found"
                description="Try changing the search terms"
              />
            </Box>
          ) : (
            <>
              <TableData<OrderType>
                data={filteredOrders || []}
                columns={columns}
                onEdit={openOrderDetail}
                pagination
                paginationPerPage={PAGE_SIZE}
                paginationComponent={({
                  onChangePage,
                  onChangeRowsPerPage,
                  currentPage,
                  rowsPerPage,
                }) => (
                  <Pagination
                    currentPage={Number(currentPage)}
                    totalCount={filteredOrders.length}
                    pageSize={rowsPerPage}
                    onPageChange={(page) =>
                      onChangePage(Number(page), filteredOrders.length)
                    }
                    onPageSizeChange={(size) =>
                      onChangeRowsPerPage(size, Number(currentPage))
                    }
                  />
                )}
              />

              <ReactTooltip
                effect="solid"
                className="tooltip"
                backgroundColor="#433d3d"
                borderColor="#433d3d"
              />
            </>
          )}
        </S.ContentTable>

        {addDeduModalIsOpen && renderAdditionsDeductionsModal()}

        {renderOpenDetailModal()}
      </S.Wrapper>
    </>
  )
}

import {
  HighlightOff as CloseIcon,
  Search as SearchIcon,
} from '@styled-icons/material-outlined'
import { ChangeEventHandler, ComponentProps, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { useDebounce } from 'react-use'

import Button from '@/components/Button'
import TextField from '@/components/TextField'

type InputFilterProps = ComponentProps<typeof TextField> & {
  loading?: boolean
  placeholder?: string
  onFilterChange: (search: string) => void
}

export function InputFilter({
  loading,
  placeholder = 'Search by name or order',
  onFilterChange,
  ...props
}: InputFilterProps) {
  const [term, setTerm] = useState('')

  const handleFilterChange = (search: string) => {
    cancel()
    onFilterChange(search)
  }

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setTerm(event.target.value)
  }

  const onClear = () => {
    setTerm('')
    handleFilterChange('')
  }

  const [, cancel] = useDebounce(
    () => {
      if (term.length >= 3 || term.length === 0) {
        handleFilterChange(term)
      }
    },
    400,
    [term],
  )

  return (
    <TextField
      type="text"
      name="search_order"
      placeholder={placeholder}
      icon={
        term ? (
          <>
            <Button
              onClick={onClear}
              icon={<CloseIcon />}
              style={{ padding: 0 }}
              data-tip="Clear search"
              minimal
            />
            <ReactTooltip
              effect="solid"
              className="tooltip"
              backgroundColor="#433d3d"
              borderColor="#433d3d"
            />
          </>
        ) : (
          <SearchIcon />
        )
      }
      value={term}
      disabled={loading}
      iconPosition="right"
      onChange={onChange}
      {...props}
    />
  )
}

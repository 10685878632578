import { lighten } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { InputWrapper } from '@/components/TextField/styles'

export const SearchInputContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;

    .tooltip {
      border-radius: 0.5rem;
      font-family: ${theme.font.quicksand};
      font-size: ${theme.font.sizes.xsmall};
    }

    ${InputWrapper} {
      border-color: ${theme.dark
        ? theme.colors.superLight
        : theme.colors.mainBg};

      ${theme.dark && `background-color: ${theme.colors.mainBg};`}

      &:focus-within {
        border-color: ${theme.dark
          ? theme.colors.primaryText
          : theme.colors.lightGray};
      }
    }

    @media (min-width: 955px) {
      display: block;
      width: 215px;
    }
  `}
`

export const SearchPreviewContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: 100%;
    max-height: 85vh;
    top: 43px;
    left: -16px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.06);
    border-radius: 11px;
    background-color: ${theme.dark ? theme.colors.mainBg : theme.colors.white};
    z-index: 100;

    ${media.greaterThan('medium')`
      max-height: initial;
      height: 85vh;
      width: 412px;
      height: auto;
      top: 43px;
      left: 0;
    `}
  `}
`

export const SearchPreviewWrapper = styled.div`
  width: 100%;
  max-height: 76vh;
  overflow-y: auto;

  ${media.greaterThan('medium')`
    max-height: initial;
    height: 76vh;
    width: 412px;
    height: auto;
    overflow-y: auto;
  `}
`

export const SearchPreviewItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem ${theme.spacings.xsmall};
    cursor: pointer;
    border-radius: 11px;
    transition: background-color 0.2s ease-in-out;

    &:not(:last-child) {
      border-bottom: solid 1px ${theme.colors.mainBg};
    }

    &:hover {
      background-color: ${theme.dark
        ? lighten(0.1, theme.colors.mainBg)
        : theme.colors.mainBg};
    }
  `}
`

export const SearchPreviewItemData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

export const LoaderContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    padding: ${theme.spacings.xsmall} 0;
    margin: 0.5rem ${theme.spacings.xsmall};
  `}
`

export const Empty = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.xsmall} ${theme.spacings.xsmall};
    font-size: ${theme.font.sizes.small};
  `}
`

import styled, { css } from 'styled-components'

export const CardName = styled.p`
  ${({ theme }) => css`
    display: flex;
    align-items: end;
    gap: 0.5rem;
    color: ${theme.colors.gray};

    span {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.primary};
      font-weight: ${theme.font.medium};
    }
  `}
`

export const CardNumber = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.gray};
  `}
`

export const SuperCreditContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: 0.938rem;
    color: ${theme.colors.primary};
    border-radius: ${theme.border.radius.xsmall};
    justify-content: space-between;

    &:hover {
      background: ${theme.colors.mainBg};
      cursor: pointer;
    }
  `}
`

export const SuperCreditName = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: ${theme.font.sizes.small};
  `}
`

export const SuperCreditDescription = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.secondary};
    font-size: ${theme.font.sizes.xxsmall};
  `}
`

export const SuperCreditBalance = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.secondary};
    font-size: ${theme.font.sizes.small};
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: right;
  `}
`

export const SuperCreditValue = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
  `}
`

import { Helmet } from 'react-helmet-async'

import { Box } from '@/components/Box'

import { Address } from './components/address'
import { FormGeneralSettings } from './components/form-general-settings'
import { FormHours } from './components/form-hours'
import { FormSettings } from './components/form-settings'
import * as S from './styles'

interface Props {
  admin?: boolean
}

export function Settings({ admin = false }: Props) {
  return (
    <>
      <Helmet title={`${admin ? 'Admin' : ''} Settings`} />
      <S.Wrapper>
        <Box flex flexDirection="column" gap="1.5rem">
          <S.Content>
            <FormSettings admin={admin || false} />
          </S.Content>
        </Box>

        <Box flex flexDirection="column" gap="1.5rem">
          <S.MapContent>
            <Address admin={admin || false} />
          </S.MapContent>

          <S.Content>
            <S.Title>Hours</S.Title>
            <FormHours />
          </S.Content>

          <S.Content>
            <S.Title>General Settings</S.Title>
            <FormGeneralSettings />
          </S.Content>
        </Box>
      </S.Wrapper>
    </>
  )
}

import { lighten } from 'polished'
import styled, { css } from 'styled-components'

import Button from '@/components/Button'
import { InputWrapper } from '@/components/TextField/styles'

export const Content = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.spacings.xlarge};
    display: flex;
    flex-direction: column;

    .tooltip {
      border-radius: 8px;
      font-family: ${theme.font.quicksand};
      font-size: ${theme.font.sizes.xsmall};
    }

    .rdt_TableRow {
      background-position: center;
      transition: background 0.8s;

      &:hover {
        background: ${theme.colors.mainBg}
          radial-gradient(circle, transparent 1%, ${theme.colors.mainBg} 1%)
          center/15000%;
      }

      &:active {
        background-color: ${theme.colors.lightGray};
        background-size: 100%;
        transition: background 0s;
      }
    }
  `}
`

export const ButtonEdit = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.colors.secondary};

    svg {
      width: 1.25rem;
      max-height: 1.25rem;
    }

    &:hover {
      color: ${lighten(0.2, theme.colors.secondary)};
    }
  `}
`

export const Header = styled.header`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: ${theme.spacings.medium};

    h1 {
      font-size: ${theme.font.sizes.xlarge};
      color: ${theme.colors.primary};
      font-family: ${theme.font.nunito};
      font-weight: ${theme.font.extraBold};
    }
  `}
`

export const Actions = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;

    > div + div {
      margin-left: ${theme.spacings.small};
    }
  `}
`

export const TextFieldWrapper = styled.div`
  ${({ theme }) => css`
    width: 12.375rem;
    max-width: 13rem;

    ${InputWrapper} {
      border-color: ${theme.colors.mainBg};

      &:focus-within {
        border-color: ${theme.colors.lightGray};
      }
    }
  `}
`

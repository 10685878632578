import { useEffect, useMemo, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { useTheme } from 'styled-components'

import deleteDarkIcon from '@/assets/img/icon-delete-dark.svg'
import cardIcon from '@/assets/img/icons-card.svg'
import cardIconLight from '@/assets/img/icons-card-light.svg'
import { Box } from '@/components/Box'
import Empty from '@/components/Empty'
import List from '@/components/List'
import Skeleton from '@/components/Skeleton'
import useAuth from '@/hooks/useAuth'
import { api } from '@/lib/axios'
import { CardType } from '@/types/payment.types'
import { formatCurrencyWithCode } from '@/utils/formats/number'

import * as S from './styles'

export type PaymentListProps = {
  onLoading?: (isLoading: boolean) => void
  hasToFetchList?: boolean
  hoverable?: boolean
  onSetPayment?: (card: CardType | null) => void
}

export const PaymentList = ({
  onLoading,
  hasToFetchList = false,
  hoverable = false,
  onSetPayment,
}: PaymentListProps) => {
  const [cards, setCards] = useState<CardType[]>([])
  const [credit, setCredit] = useState({ method: 'credit', limit: 0 })
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingCredit, setIsLoadingCredit] = useState(true)
  const { merchantSelected } = useAuth()
  const theme = useTheme()

  const fetchCardList = async () => {
    try {
      const { data } = await api.get(
        `/superpartner/${merchantSelected?.id}/payment`,
      )
      setCards(data)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchCreditLimit = async () => {
    try {
      setIsLoadingCredit(true)
      const { data } = await api.get(
        `/superpartner/${merchantSelected?.id}/payment/credit-limit`,
      )

      setCredit(data)
    } finally {
      setIsLoadingCredit(false)
      setIsLoading(false)
    }
  }

  const deleteCard = async (vaultId: string) => {
    try {
      setIsLoading(true)
      await api.delete(
        `/superpartner/${merchantSelected?.id}/payment/${vaultId}`,
      )
      fetchCardList()
      // eslint-disable-next-line no-empty
    } finally {
    }
  }

  useEffect(() => {
    if (merchantSelected?.id) {
      fetchCardList()
      fetchCreditLimit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (hasToFetchList) {
      fetchCardList()
      fetchCreditLimit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasToFetchList])

  useEffect(() => {
    onLoading && onLoading(isLoading)
  }, [isLoading, onLoading])

  const haveBalanceCredit = useMemo(() => {
    return credit && credit.limit > 0
  }, [credit])

  return (
    <S.Wrapper>
      {credit && credit.limit > 0 && (
        <S.SuperCreditContainer
          onClick={() =>
            onSetPayment &&
            onSetPayment({
              gateway: 'balance',
              cardNumberEnd: '1111',
              defaultMethod: false,
              vaultId: '1111',
              balance: credit.limit,
            })
          }
        >
          <Box flex alignItems="start" gap="0.5rem" flexDirection="column">
            <div className="flex items-center gap-2">
              {theme.dark ? (
                <img
                  src={cardIconLight}
                  width={18}
                  height={14}
                  alt="Card icon light"
                />
              ) : (
                <img src={cardIcon} width={18} height={14} alt="Card icon" />
              )}
              <S.SuperCreditName>Super Credit</S.SuperCreditName>
            </div>
            <S.SuperCreditDescription>
              Deduction will be added on your next pay cycle
            </S.SuperCreditDescription>
          </Box>

          <Box flex alignItems="end" gap="1rem">
            <S.SuperCreditBalance>
              <div>Credit available</div>
              <S.SuperCreditValue>
                {formatCurrencyWithCode(credit.limit / 100)}
              </S.SuperCreditValue>
            </S.SuperCreditBalance>
          </Box>
        </S.SuperCreditContainer>
      )}

      {isLoading || isLoadingCredit ? (
        <Skeleton count={3} />
      ) : cards && cards.length === 0 && !haveBalanceCredit ? (
        <Empty title="There is nothing here, yet" />
      ) : (
        <>
          {isLoadingCredit && <Skeleton count={2} />}

          <List<CardType>
            data={cards}
            onItemClick={(card) => onSetPayment && onSetPayment(card)}
            hoverable={hoverable}
            style={{ userSelect: 'auto' }}
          >
            {(card) => (
              <>
                <Box flex alignItems="center" gap="0.5rem">
                  {theme.dark ? (
                    <img src={cardIconLight} alt="Card icon light" />
                  ) : (
                    <img src={cardIcon} alt="Card icon" />
                  )}
                  <S.CardName>
                    {card.defaultMethod && <span>main card</span>}
                  </S.CardName>
                </Box>

                <Box flex alignItems="center" gap="1rem">
                  <S.CardNumber>••• {card.cardNumberEnd}</S.CardNumber>

                  <S.DeleteButton
                    data-tip="Delete"
                    onClick={() => deleteCard(card.vaultId)}
                  >
                    <img src={deleteDarkIcon} alt="Delete icon dark" />
                  </S.DeleteButton>
                  <ReactTooltip
                    effect="solid"
                    className="tooltip"
                    backgroundColor="#433d3d"
                    borderColor="#433d3d"
                  />
                </Box>
              </>
            )}
          </List>
        </>
      )}
    </S.Wrapper>
  )
}

import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: ${theme.spacings.xsmall};
    border-radius: ${theme.border.radius.xsmall};
    background: ${theme.colors.white};
  `}
`

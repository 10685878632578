import { PhotoCamera as PhotoCameraIcon } from '@styled-icons/material-outlined'
import { ChangeEvent, useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import slugify from 'slugify'

import settingsService from '@/api/settings.service'
import editFilledIcon from '@/assets/img/icon-edit-filled.svg'
import { Box } from '@/components/Box'
import Button from '@/components/Button'
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverflow,
} from '@/components/Modal'
import Radio from '@/components/Radio'
import Switch from '@/components/Switch'
import TextField from '@/components/TextField'
import useAuth from '@/hooks/useAuth'
import useModal from '@/hooks/useModal'
import CropPictureModal from '@/modals/CropPictureModal'
import { ImageUploadType } from '@/modals/EditItemModal'
import EditSettingsCategoryModal, {
  CategorySelectedType,
} from '@/modals/EditSettingsCategoryModal'
import EditSettingsServicesModal, {
  ServiceSelectedType,
} from '@/modals/EditSettingsServicesModal'
import { MerchantGeneralInfoType } from '@/types/settings.types'

import { FormLoading } from './loader'
import * as S from './styles'

export type CreateMerchantModalProps = {
  onClose: () => void
}

const CreateMerchantModal = ({ onClose }: CreateMerchantModalProps) => {
  const [logoUpload, setLogoUpload] = useState<ImageUploadType>(
    {} as ImageUploadType,
  )
  const [pictureUpload, setPictureUpload] = useState<ImageUploadType>(
    {} as ImageUploadType,
  )
  const [name, setName] = useState('')
  const [initials, setInitials] = useState('')
  const [phone, setPhone] = useState('')
  const [delivery, setDelivery] = useState('')
  const [available, setAvailable] = useState(false)
  const [visible, setVisible] = useState(false)
  const [categoriesSelected, setCategoriesSelected] = useState<
    CategorySelectedType[]
  >([])
  const [servicesSelected, setServicesSelected] = useState<
    ServiceSelectedType[]
  >([])
  const { open: openPictureCropModal, toggle: setOpenPictureCropModal } =
    useModal()
  const { open: openCategoryModal, toggle: setOpenCategoryModal } = useModal()
  const { open: openServiceModal, toggle: setOpenServiceModal } = useModal()
  const [isLoading, setIsLoading] = useState(false)
  const { user, onSetMerchantSelected } = useAuth()
  const navigate = useNavigate()
  const fileLogoInputRef = useRef<HTMLInputElement | null>(null)
  const filePictureInputRef = useRef<HTMLInputElement | null>(null)

  const canEnableSave = useCallback(() => {
    const canSave =
      categoriesSelected.length > 0 &&
      servicesSelected.length > 0 &&
      !!initials &&
      !!logoUpload.imagePreview &&
      !!name &&
      !!phone &&
      !!pictureUpload.imagePreview
    return canSave
  }, [
    categoriesSelected,
    initials,
    logoUpload.imagePreview,
    name,
    phone,
    pictureUpload.imagePreview,
    servicesSelected,
  ])

  const handleUploadButton = (logo = false) => {
    if (logo) {
      fileLogoInputRef.current && fileLogoInputRef.current.click()
      return
    }

    filePictureInputRef.current && filePictureInputRef.current.click()
  }

  const handleUploadChange = (
    event: ChangeEvent<HTMLInputElement>,
    logo = false,
  ) => {
    const file = event.target.files ? event.target.files[0] : null
    const timestamp = new Date().getTime()
    if (file) {
      const blob = file.slice(0, file.size, file.type)
      const newFile = new File(
        [blob],
        slugify(timestamp + file.name.replace(/[^a-zA-Z0-9]/g, '')),
        {
          type: file?.type,
        },
      )

      const imagePreview = URL.createObjectURL(newFile)
      if (logo) {
        setLogoUpload({ file: newFile, imagePreview })
        return
      }

      setPictureUpload({ file: newFile, imagePreview })
      setOpenPictureCropModal(true)
    }
  }

  const renderPictureModalCrop = () => (
    <Modal
      open={openPictureCropModal}
      onOpenChange={setOpenPictureCropModal}
      modal
    >
      <ModalContent
        title="Crop Picture"
        onClose={() => setOpenPictureCropModal(false)}
      >
        <CropPictureModal
          picture={pictureUpload}
          closeCropped={(pictureCropped) => {
            if (pictureCropped) setPictureUpload(pictureCropped)
            setOpenPictureCropModal(false)
          }}
        />
      </ModalContent>
    </Modal>
  )

  const closeEditSettingsCategory = (selecteds?: CategorySelectedType[]) => {
    if (selecteds) {
      setCategoriesSelected(selecteds)
    }
    setOpenCategoryModal(false)
  }

  const renderOpenEditSettingsCategoryModal = () => {
    return (
      <Modal open={openCategoryModal} onOpenChange={setOpenCategoryModal} modal>
        <ModalContent
          title="Edit Categories"
          onClose={() => setOpenCategoryModal(false)}
        >
          <EditSettingsCategoryModal
            categories={categoriesSelected}
            closeEditSettingsCategory={closeEditSettingsCategory}
          />
        </ModalContent>
      </Modal>
    )
  }

  const closeEditSettingsServices = (selecteds?: ServiceSelectedType[]) => {
    if (selecteds) {
      setServicesSelected(selecteds)
    }
    setOpenServiceModal(false)
  }

  const renderOpenEditSettingsServicesModal = () => {
    return (
      <Modal open={openServiceModal} onOpenChange={setOpenServiceModal} modal>
        <ModalContent
          title="Edit Services"
          onClose={() => setOpenServiceModal(false)}
          width="auto"
        >
          <EditSettingsServicesModal
            services={servicesSelected}
            closeEditSettingsServices={closeEditSettingsServices}
          />
        </ModalContent>
      </Modal>
    )
  }

  const handleSave = useCallback(async () => {
    if (user) {
      setIsLoading(true)
      try {
        const dataToSave: MerchantGeneralInfoType = {
          name,
          initials,
          phone,
          hasBentoDelivery: delivery === 'bento',
          isOpen: available,
          category: categoriesSelected.map((cat) => cat.name).join(' • '),
          hasDelivery: servicesSelected.some(
            (service) => service.name === 'Delivery',
          ),
          hasTakeout: servicesSelected.some(
            (service) => service.name === 'Takeout',
          ),
          visible,
          logo: '',
          picture: '',
        }

        const {
          data: { merchantId },
        } = await settingsService.createMerchant(dataToSave)

        if (logoUpload.file) {
          const { data: response } = await settingsService.merchantUpload(
            merchantId,
            logoUpload.file,
          )
          dataToSave.logo = response[0].url
        }

        if (pictureUpload.file) {
          const { data: response } = await settingsService.merchantUpload(
            merchantId,
            pictureUpload.file,
          )
          dataToSave.picture = response[0].url
        }

        if (logoUpload.file || pictureUpload.file) {
          await settingsService.saveMerchantGeneralInfo(
            dataToSave,
            merchantId,
            user.uuid,
          )
        }

        onSetMerchantSelected(merchantId)
        onClose()
        navigate('/admin-settings')
      } finally {
        setIsLoading(false)
      }
    }
  }, [
    available,
    categoriesSelected,
    delivery,
    initials,
    logoUpload,
    name,
    onClose,
    onSetMerchantSelected,
    phone,
    pictureUpload,
    navigate,
    servicesSelected,
    user,
    visible,
  ])

  return (
    <>
      <ModalOverflow>
        {isLoading ? (
          <FormLoading />
        ) : (
          <S.Wrapper>
            <Box
              flex
              JustifyContent="center"
              style={{ marginBottom: '1.5rem' }}
            >
              <S.ImageWrapper logo={logoUpload.imagePreview}>
                <>
                  <S.InputFile
                    type="file"
                    ref={fileLogoInputRef}
                    onChange={(event) => handleUploadChange(event, true)}
                    accept="image/png, image/jpeg"
                    disabled={isLoading}
                  />
                  <S.UploadButton onClick={() => handleUploadButton(true)}>
                    <PhotoCameraIcon size="60" />
                  </S.UploadButton>
                </>
              </S.ImageWrapper>
            </Box>

            <S.FormList>
              <S.FormListItem>
                <S.FormListItemTitle>Name</S.FormListItemTitle>
                <TextField
                  variant="material"
                  value={name}
                  disabled={isLoading}
                  onChange={(event) => setName(event.currentTarget.value)}
                />
              </S.FormListItem>
              <S.FormListItem>
                <S.FormListItemTitle>Merchant Initials</S.FormListItemTitle>
                <TextField
                  variant="material"
                  value={initials}
                  disabled={isLoading}
                  onChange={(event) => setInitials(event.currentTarget.value)}
                  maxLength={3}
                />
              </S.FormListItem>
              <S.FormListItem>
                <S.FormListItemTitle>Phone Number</S.FormListItemTitle>
                <TextField
                  variant="material"
                  value={phone}
                  mask="phone"
                  disabled={isLoading}
                  onChange={(event) => setPhone(event.currentTarget.value)}
                />
              </S.FormListItem>
              <S.FormListItem>
                <S.FormListItemTitle>Categories</S.FormListItemTitle>
                <Box flex gap="1rem">
                  <S.ItemLabel>
                    {categoriesSelected.map((cat) => cat.name).join(' | ')}
                  </S.ItemLabel>
                  <S.Action
                    onClick={() => setOpenCategoryModal(true)}
                    disabled={isLoading}
                  >
                    <S.ButtonIcon>
                      <img
                        src={editFilledIcon}
                        width={20}
                        height={20}
                        alt="Edit icon"
                      />
                    </S.ButtonIcon>
                  </S.Action>
                </Box>
              </S.FormListItem>
              <S.FormListItem>
                <S.FormListItemTitle>Services</S.FormListItemTitle>
                <Box flex gap="1rem">
                  <S.ItemLabel>
                    {servicesSelected.map((cat) => cat.name).join(' | ')}
                  </S.ItemLabel>
                  <S.Action
                    onClick={() => setOpenServiceModal(true)}
                    disabled={isLoading}
                  >
                    <S.ButtonIcon>
                      <img
                        src={editFilledIcon}
                        width={20}
                        height={20}
                        alt="Edit icon"
                      />
                    </S.ButtonIcon>
                  </S.Action>
                </Box>
              </S.FormListItem>
              <S.FormListItem>
                <S.FormListItemTitle>Availability</S.FormListItemTitle>
                <Switch
                  checked={available}
                  id="availability"
                  onChange={setAvailable}
                  disabled={isLoading}
                />
              </S.FormListItem>
              <S.FormListItem>
                <S.FormListItemTitle>Visibility</S.FormListItemTitle>
                <Switch
                  checked={visible}
                  id="visibility"
                  onChange={setVisible}
                  disabled={isLoading}
                />
              </S.FormListItem>
              <S.FormListItem>
                <S.FormListItemTitle>Delivery</S.FormListItemTitle>
                <Box flex gap="1rem">
                  <Radio
                    label="Bento Delivery"
                    labelFor="bento_delivery"
                    id="bento_delivery"
                    value="bento"
                    name="delivery"
                    checked={delivery === 'bento'}
                    onCheck={() => setDelivery('bento')}
                    disabled={isLoading}
                  />
                  <Radio
                    label="Partner Delivery"
                    labelFor="partner_delivery"
                    id="partner_delivery"
                    value="partner"
                    name="delivery"
                    checked={delivery === 'partner'}
                    onCheck={() => setDelivery('partner')}
                    disabled={isLoading}
                  />
                </Box>
              </S.FormListItem>
            </S.FormList>

            <Box
              flex
              JustifyContent="center"
              style={{ marginBottom: '1.5rem' }}
            >
              <S.ImagePictureWrapper picture={pictureUpload.imagePreview}>
                <S.InputFile
                  type="file"
                  ref={filePictureInputRef}
                  onChange={(event) => handleUploadChange(event)}
                  accept="image/png, image/jpeg"
                  disabled={isLoading}
                />
                <S.UploadPictureButton onClick={() => handleUploadButton()}>
                  <PhotoCameraIcon size="60" />
                  change hero
                </S.UploadPictureButton>
              </S.ImagePictureWrapper>
            </Box>

            <Box flex JustifyContent="end" />
          </S.Wrapper>
        )}
      </ModalOverflow>

      <ModalFooter>
        <Box flex alignItems="center" JustifyContent="end" width="100%">
          <Button
            size="large"
            onClick={handleSave}
            loading={isLoading}
            disabled={isLoading || !canEnableSave()}
          >
            Save
          </Button>
        </Box>
      </ModalFooter>

      {openPictureCropModal && renderPictureModalCrop()}
      {openCategoryModal && renderOpenEditSettingsCategoryModal()}
      {openServiceModal && renderOpenEditSettingsServicesModal()}
    </>
  )
}

export default CreateMerchantModal

import { Menu as DragIcon } from '@styled-icons/material-outlined/Menu'
import { useState } from 'react'
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd'
import { useMediaQuery } from 'react-responsive'
import { titleCase } from 'title-case'

import { Box } from '@/components/Box'
import { Collapsible, CollapsibleContent } from '@/components/Collapsible'
import { Modal, ModalContent } from '@/components/Modal'
import useAuth from '@/hooks/useAuth'
import useModal from '@/hooks/useModal'
import EditItemModal from '@/modals/EditItemModal'
import {
  CategoryType,
  ItemType,
  MenusType,
  ModifierType,
} from '@/types/menu-maker.types'
import { OverviewCategoryType } from '@/types/overview.types'
import { formatCurrency } from '@/utils/formats/number'
import { SorterNumerically } from '@/utils/formats/sort'

import * as S from './styles'

type OverviewListProps = {
  data: OverviewCategoryType[]
  categories: CategoryType[]
  menus: MenusType[]
  modifiers: ModifierType[]
  items: ItemType[]
  expanded: boolean
  refreshMenus?: () => void
}

const OverviewList = ({
  data,
  categories,
  menus,
  items,
  modifiers,
  expanded,
  refreshMenus,
}: OverviewListProps) => {
  const [editItem, setEditItem] = useState<ItemType | null>(null)
  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal()
  const { merchantSelected } = useAuth()
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  })

  const openEditItem = (item?: ItemType) => {
    const itemFind = items.find((i) => i.itemId === item?.itemId)
    setEditItem(itemFind || null)
    setModalIsOpen(true)
  }

  const closeEditItem = (refresh?: boolean) => {
    if (refresh) {
      refreshMenus && refreshMenus()
    }
    setModalIsOpen(false)
  }

  const renderOpenEditItemModal = () => {
    return (
      <Modal open={modalIsOpen} onOpenChange={setModalIsOpen} modal>
        <ModalContent title="Edit Item" onClose={() => setModalIsOpen(false)}>
          <EditItemModal
            closeEditItem={closeEditItem}
            editItemData={editItem}
            merchant={merchantSelected!.id}
            categories={categories}
            menus={menus}
            modifiers={modifiers}
            items={items}
          />
        </ModalContent>
      </Modal>
    )
  }

  const renderTableHeader = () => (
    <S.TableHeader>
      <S.Colum header mobile>
        Name
      </S.Colum>
      <S.Colum width="20%" align="center" header>
        Modifier
      </S.Colum>
      <S.Colum
        width="20%"
        align="center"
        header
        style={{ textAlign: 'center' }}
      >
        Customer Selects
      </S.Colum>
      <S.Colum width="10%" align="end" header>
        KYD
      </S.Colum>
    </S.TableHeader>
  )

  const renderModifierList = (
    modifiers: ModifierType[],
    categoryId: string,
    itemId: string,
  ) => (
    <Droppable
      droppableId={`modifiers-${categoryId}-${itemId}`}
      type={`modifiers-${categoryId}-${itemId}`}
      key={`modifiers-${categoryId}-${itemId}`}
    >
      {(
        dropProvided: DroppableProvided,
        dropSnapshot: DroppableStateSnapshot,
      ) => (
        <S.ContainerModifiers
          ref={dropProvided.innerRef}
          isDraggingOver={dropSnapshot.isDraggingOver}
          {...dropProvided.droppableProps}
        >
          {modifiers.map((modifier: ModifierType, index: number) => (
            <Draggable
              draggableId={`${modifier.modifierId}-${itemId}`}
              key={`${modifier.modifierId}-${itemId}`}
              index={index}
            >
              {(
                dragProvided: DraggableProvided,
                dragSnapshot: DraggableStateSnapshot,
              ) => (
                <S.ModifierItem
                  ref={dragProvided.innerRef}
                  isDragging={dragSnapshot.isDragging}
                  {...dragProvided.draggableProps}
                  {...dragProvided.dragHandleProps}
                >
                  <S.Colum width="8%">
                    <S.Lines
                      last={modifiers.length === index + 1}
                      isDragging={dragSnapshot.isDragging}
                      isDraggingOver={dropSnapshot.isDraggingOver}
                    />
                  </S.Colum>

                  {!isMobile && (
                    <S.Colum width="15%" align="center">
                      <S.Image bg={modifier.note} />
                    </S.Colum>
                  )}

                  <S.Colum width="15%" align="center">
                    <S.DragIcon>
                      <DragIcon size={24} />
                    </S.DragIcon>
                  </S.Colum>

                  <S.Colum mobile>
                    <S.Name>{titleCase(modifier.name.toLowerCase())}</S.Name>
                  </S.Colum>

                  <S.Colum width="20%" align="center" header>
                    <S.Name> </S.Name>
                  </S.Colum>

                  <S.Colum width="20%" align="center">
                    <S.Name>{modifier.maxEachOption}</S.Name>
                  </S.Colum>

                  <S.Colum width="20%" align="end">
                    <S.Name> </S.Name>
                  </S.Colum>
                </S.ModifierItem>
              )}
            </Draggable>
          ))}
          {dropProvided.placeholder}
        </S.ContainerModifiers>
      )}
    </Droppable>
  )

  const renderItemList = (items: ItemType[], categoryId: string) => (
    <Droppable
      droppableId={`items-${categoryId}`}
      type={`items-${categoryId}`}
      key={`items-${categoryId}`}
    >
      {(
        dropProvided: DroppableProvided,
        dropSnapshot: DroppableStateSnapshot,
      ) => (
        <S.ContainerItems
          ref={dropProvided.innerRef}
          isDraggingOver={dropSnapshot.isDraggingOver}
          {...dropProvided.droppableProps}
        >
          {items.map((item: ItemType, index: number) => (
            <Draggable
              draggableId={`${item.itemId}-${categoryId}`}
              key={`${item.itemId}-${categoryId}`}
              index={index}
            >
              {(
                dragProvided: DraggableProvided,
                dragSnapshot: DraggableStateSnapshot,
              ) => (
                <S.Item
                  ref={dragProvided.innerRef}
                  isDragging={dragSnapshot.isDragging}
                  {...dragProvided.draggableProps}
                  {...dragProvided.dragHandleProps}
                >
                  <Collapsible
                    open={expanded}
                    showTrigger={(item.modifiersLength || 0) > 0}
                    item={
                      <Box
                        flex
                        alignItems="center"
                        onClick={() => openEditItem(item)}
                        width="100%"
                      >
                        {(item.modifiersLength || 0) === 0 && (
                          <S.Colum width={isMobile ? '2.625rem' : '3.75rem'} />
                        )}

                        {!isMobile && (
                          <S.Colum width="15%" align="center">
                            <S.Image bg={item.featuredPicture || ''} />
                          </S.Colum>
                        )}

                        <S.Colum width="15%" align="center">
                          <S.DragIcon>
                            <DragIcon size={24} />
                          </S.DragIcon>
                        </S.Colum>

                        <S.Colum item mobile>
                          <S.Name>{titleCase(item.name.toLowerCase())}</S.Name>
                        </S.Colum>

                        <S.Colum width="20%" align="center" header item>
                          <S.Name>{item.modifiersLength || 0}</S.Name>
                        </S.Colum>

                        <S.Colum width="20%" align="center" item>
                          <S.Name> </S.Name>
                        </S.Colum>

                        <S.Colum width="20%" align="end" item>
                          <S.Name>{formatCurrency(item.price / 100)}</S.Name>
                        </S.Colum>
                      </Box>
                    }
                  >
                    <CollapsibleContent>
                      {item.modifiersLength! > 0 &&
                        renderModifierList(
                          item.modifiers,
                          categoryId,
                          item.itemId,
                        )}
                    </CollapsibleContent>
                  </Collapsible>
                </S.Item>
              )}
            </Draggable>
          ))}
          {dropProvided.placeholder}
        </S.ContainerItems>
      )}
    </Droppable>
  )

  const renderCategoriesList = (categories: OverviewCategoryType[]) => {
    const categoriesOrderedByPosition = categories.sort(
      SorterNumerically('position'),
    )

    return (
      <Droppable droppableId="categories" type="categories" key="categories">
        {(
          dropProvided: DroppableProvided,
          dropSnapshot: DroppableStateSnapshot,
        ) => (
          <S.ContainerCategories
            ref={dropProvided.innerRef}
            isDraggingOver={dropSnapshot.isDraggingOver}
            {...dropProvided.droppableProps}
          >
            {categoriesOrderedByPosition.map(
              (category: OverviewCategoryType, index: number) => (
                <Draggable
                  draggableId={category.categoryId!}
                  key={category.categoryId!}
                  index={index}
                >
                  {(
                    dragProvided: DraggableProvided,
                    dragSnapshot: DraggableStateSnapshot,
                  ) => (
                    <S.CategoryItem
                      ref={dragProvided.innerRef}
                      isDragging={dragSnapshot.isDragging}
                      {...dragProvided.draggableProps}
                      {...dragProvided.dragHandleProps}
                    >
                      <S.CollapsibleCategories
                        open={expanded}
                        item={
                          <S.TitleTriggerCategories>
                            <S.DragIcon style={{ marginRight: '0.75rem' }}>
                              <DragIcon size={24} />
                            </S.DragIcon>
                            {titleCase(category.name.toLowerCase())} (
                            {category.itemsLength})
                          </S.TitleTriggerCategories>
                        }
                      >
                        <CollapsibleContent>
                          {renderTableHeader()}
                          {category.itemsLength! > 0 &&
                            renderItemList(
                              category.items as ItemType[],
                              category.categoryId!,
                            )}
                        </CollapsibleContent>
                      </S.CollapsibleCategories>
                    </S.CategoryItem>
                  )}
                </Draggable>
              ),
            )}
            {dropProvided.placeholder}
          </S.ContainerCategories>
        )}
      </Droppable>
    )
  }

  return (
    <>
      {renderCategoriesList(data)}
      {modalIsOpen && renderOpenEditItemModal()}
    </>
  )
}

export default OverviewList

import styled, { css } from 'styled-components'

import Button from '@/components/Button'

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`

export const Avatar = styled.div<{ image: string }>`
  ${({ image }) => css`
    width: 5rem;
    height: 5rem;
    border-radius: 99999px;
    background-color: #d8d8d8;
    background-image: url(${image});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 60%;
  `};
`

export const UserInfo = styled.div``

export const Name = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.nunito};
    font-size: 1.375rem;
    font-weight: bold;
    letter-spacing: -0.14px;
    color: ${theme.colors.secondary};
  `}
`

export const Typography = styled.div`
  ${({ theme }) => css`
    margin: 2px 0 0;
    font-family: ${theme.font.nunito};
    font-size: 1rem;
    color: ${theme.colors.gray};
  `}
`

export const PhoneTypography = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  .tooltip {
    padding: 0.188rem 0.375rem;
    border-radius: 8px;
    font-size: 0.75rem;
  }
`

export const EditPhoneButton = styled(Button)`
  padding: 0.375rem;
  height: 24px;
  margin-top: 4px;

  svg {
    width: 0.75rem;
  }
`

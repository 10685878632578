import { Share } from '@styled-icons/material-rounded'
import { format } from 'date-fns'
import { useCallback, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { StepForm, StepOverflow } from '@/components/FormStep'
import { useFormStepData } from '@/components/FormStep/Hook'
import {
  ModalFooter,
  ModalHeader,
  ModalHeaderClose,
  ModalHeaderTitle,
} from '@/components/Modal'
import Toast from '@/components/Toast'
import {
  FieldContent,
  FieldHeader,
  FieldHeaderActions,
  FieldHeaderTitle,
  FieldWrapper,
  WrapperContent,
} from '@/modals/CreatingNewCampaignModal/styles'
import { formatCurrencyWithCode } from '@/utils/formats/number'

import { PaymentFormData } from '../CheckoutForm'
import * as S from './styles'

export type ShareVoucherProps = {
  close: (isSuccess?: boolean) => void
  active: boolean
}

export const ShareVoucher = ({ close, active }: ShareVoucherProps) => {
  const [isShareLoading, setIsShareLoading] = useState(false)
  const { dataForm } = useFormStepData<PaymentFormData>()
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  const handleShare = useCallback(async () => {
    try {
      setIsShareLoading(true)
      if (isMobile && navigator.share) {
        await navigator.share(dataForm.shareableInfo)
      } else {
        await navigator.clipboard.writeText(dataForm?.shareableInfo?.link || '')
        Toast({
          title: 'Link from voucher copied',
          message: 'Share it with your customers',
          type: 'success',
        })
      }
    } finally {
      setIsShareLoading(false)
    }
  }, [dataForm?.shareableInfo, isMobile])

  return (
    <StepForm>
      <ModalHeader>
        {/* <S.CustomCloseButton
          onClick={() => onBeforeStep({ active: true, field: '' })}
        >
          <ChevronLeft size={32} />
        </S.CustomCloseButton> */}

        <ModalHeaderTitle>Voucher</ModalHeaderTitle>

        <ModalHeaderClose
          onClick={(event) => {
            event.preventDefault()
            close(true)
          }}
        >
          ✕
        </ModalHeaderClose>
      </ModalHeader>

      <StepOverflow active={active ? 1 : 0}>
        <WrapperContent>
          <FieldWrapper>
            <FieldHeader>
              <FieldHeaderTitle>
                Voucher ID
                <br />
                <span>{dataForm.voucherId}</span>
              </FieldHeaderTitle>

              <FieldHeaderActions>
                <Button
                  onClick={handleShare}
                  icon={<Share />}
                  loading={isShareLoading}
                  disabled={isShareLoading}
                >
                  <span style={{ color: '#FFF' }}>Share</span>
                </Button>
              </FieldHeaderActions>
            </FieldHeader>

            {dataForm?.shareableInfo && (
              <FieldContent>
                <S.TextToCopy>
                  <h3>{dataForm?.shareableInfo?.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: dataForm?.shareableInfo?.message.replaceAll(
                        '\n',
                        '<br />',
                      ),
                    }}
                  />
                  <a
                    href={dataForm?.shareableInfo?.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {dataForm?.shareableInfo?.link}
                  </a>
                </S.TextToCopy>
              </FieldContent>
            )}
          </FieldWrapper>

          <FieldWrapper>
            <FieldHeader>
              <FieldHeaderTitle>Conditions</FieldHeaderTitle>
            </FieldHeader>

            <FieldContent>
              <Box flex alignItems="center" gap="0.875rem">
                <S.ConditionsList>
                  <li>
                    {dataForm.vouchers?.voucherQuantity} vouchers available from{' '}
                    {dataForm.period?.startAt &&
                      format(dataForm.period.startAt, 'MMM d, yyyy')}{' '}
                    until{' '}
                    {dataForm.period?.endAt &&
                      format(dataForm.period.endAt, 'MMM d, yyyy')}
                    .
                  </li>
                  <li>1 voucher per customer</li>
                  <li>
                    Minimum order{' '}
                    {formatCurrencyWithCode(dataForm.minimumOrderValue / 100)}
                  </li>
                </S.ConditionsList>
              </Box>
            </FieldContent>
          </FieldWrapper>
        </WrapperContent>
      </StepOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
        >
          <span />
          <Button size="large" onClick={() => close(true)}>
            Done
          </Button>
        </Box>
      </ModalFooter>
    </StepForm>
  )
}

import './global.css'
import '@iguanads/react/dist/index.css'

import { useEffect, useLayoutEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { RouterProvider } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import configsToStorage from './api/configs-to-storage.service'
import useSwitchTheme from './hooks/useSwitchTheme'
import { router } from './routes'
import darkTheme from './styles/darkTheme'
import GlobalStyles from './styles/global'
import lightTheme from './styles/lightTheme'

export function App() {
  const configs = configsToStorage.getConfigs()
  const saveTheme = configsToStorage.saveTheme
  const switchTheme = useSwitchTheme(({ switchTheme }) => switchTheme)
  const theme = useSwitchTheme(({ theme }) => theme)

  useEffect(() => {
    if (configs?.theme === 'Dark') {
      switchTheme(darkTheme)
      document.body.classList.add('dark')
    } else {
      switchTheme(lightTheme)
      document.body.classList.remove('dark')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    if (configs?.theme !== theme.name) saveTheme(theme.name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme])

  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <Helmet titleTemplate="%s | Partner Portal" />
        <GlobalStyles />

        <RouterProvider router={router} />
      </HelmetProvider>
    </ThemeProvider>
  )
}

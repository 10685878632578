import { createBrowserRouter, Navigate } from 'react-router-dom'

import { Providers } from './contexts/Providers'
import { Permission, PermissionToPath } from './enums/permission.enum'
import useAuth from './hooks/useAuth'
import { AppLayout } from './pages/_layouts/app'
import { AuthLayout } from './pages/_layouts/auth'
import { NotFound } from './pages/404'
import { Error500Page } from './pages/500'
import { Dashboard } from './pages/app/dashboard'
import { LiveOrders } from './pages/app/live-orders'
import { MenuMaker } from './pages/app/menu-maker'
import { History } from './pages/app/orders'
import { Settings } from './pages/app/settings'
import { SuperPartner } from './pages/app/super-partner'
import { ForgotPassword } from './pages/auth/forgot-password'
import { SignIn } from './pages/auth/sign-in'
import { Error } from './pages/error'

interface ProtectedRouteProps {
  permission: string
  children: React.ReactNode
}

const ProtectedRoute = ({ permission, children }: ProtectedRouteProps) => {
  const { user, isLoading } = useAuth()
  const isAllowed = user && !isLoading && user.permissions.includes(permission)

  if (!user && !isLoading) {
    return <Navigate to="/sign-in" replace />
  }

  if (!isAllowed) {
    return (
      <Navigate
        to={PermissionToPath[user?.permissionsOrdered[0] || '']}
        replace
      />
    )
  }

  return children
}

const RedirectSignIn = ({ children }: { children: React.ReactNode }) => {
  const { user, isLoading } = useAuth()

  if (user && !isLoading) {
    return <Navigate to={PermissionToPath[user?.permissionsOrdered[0] || '']} />
  }

  return children
}

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Providers>
        <AppLayout />
      </Providers>
    ),
    errorElement: <Error />,
    children: [
      {
        path: '/',
        element: (
          <ProtectedRoute permission={Permission.LIVE_ORDERS}>
            <LiveOrders />
          </ProtectedRoute>
        ),
      },
      {
        path: '/dashboard',
        element: (
          <ProtectedRoute permission={Permission.DASHBOARD}>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: '/orders',
        element: (
          <ProtectedRoute permission={Permission.ORDERS}>
            <History />
          </ProtectedRoute>
        ),
      },
      {
        path: '/',
        children: [
          {
            path: '/menu-maker',
            element: <Navigate to="/menu-maker/overview" replace />,
          },
          {
            path: '/menu-maker/overview',
            element: (
              <ProtectedRoute permission={Permission.MENU_MAKER}>
                <MenuMaker activeTab={0} />
              </ProtectedRoute>
            ),
          },
          {
            path: '/menu-maker/menus',
            element: (
              <ProtectedRoute permission={Permission.MENU_MAKER}>
                <MenuMaker activeTab={1} />
              </ProtectedRoute>
            ),
          },
          {
            path: '/menu-maker/categories',
            element: (
              <ProtectedRoute permission={Permission.MENU_MAKER}>
                <MenuMaker activeTab={2} />
              </ProtectedRoute>
            ),
          },
          {
            path: '/menu-maker/items',
            element: (
              <ProtectedRoute permission={Permission.MENU_MAKER}>
                <MenuMaker activeTab={3} />
              </ProtectedRoute>
            ),
          },
          {
            path: '/menu-maker/modifiers',
            element: (
              <ProtectedRoute permission={Permission.MENU_MAKER}>
                <MenuMaker activeTab={4} />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: '/',
        children: [
          {
            path: '/super-partner',
            element: <Navigate to="/super-partner/new-campaign" replace />,
          },
          {
            path: '/super-partner/new-campaign',
            element: (
              <ProtectedRoute permission={Permission.SUPER_PARTNER}>
                <SuperPartner activeTab={0} />
              </ProtectedRoute>
            ),
          },
          {
            path: '/super-partner/campaigns',
            element: (
              <ProtectedRoute permission={Permission.SUPER_PARTNER}>
                <SuperPartner activeTab={1} />
              </ProtectedRoute>
            ),
          },
          {
            path: '/super-partner/vouchers',
            element: (
              <ProtectedRoute permission={Permission.SUPER_PARTNER}>
                <SuperPartner activeTab={2} />
              </ProtectedRoute>
            ),
          },
          {
            path: '/super-partner/payment',
            element: (
              <ProtectedRoute permission={Permission.SUPER_PARTNER}>
                <SuperPartner activeTab={3} />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: '/settings',
        element: (
          <ProtectedRoute permission={Permission.ADMIN}>
            <Settings />
          </ProtectedRoute>
        ),
      },
      {
        path: '/admin-settings',
        element: (
          <ProtectedRoute permission={Permission.SUPER_ADMIN}>
            <Settings admin />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/',
    element: (
      <Providers>
        <RedirectSignIn>
          <AuthLayout />
        </RedirectSignIn>
      </Providers>
    ),
    errorElement: <Error />,
    children: [
      { path: '/sign-in', element: <SignIn /> },
      { path: '/forgot-password', element: <ForgotPassword /> },
    ],
  },
  {
    path: '/500',
    errorElement: <Error />,
    element: <Error500Page />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
])

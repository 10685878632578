import TimeKeeper, { TimeOutput } from 'react-timekeeper'

import * as S from './styles'

export type TimePickerProps = {
  time?: string
  onChange?: (time: TimeOutput) => void
  hour24Mode?: boolean
  switchToMinuteOnHourSelect?: boolean
  switchToMinuteOnHourDropdownSelect?: boolean
  closeOnMinuteSelect?: boolean
  coarseMinutes?: number
  forceCoarseMinutes?: boolean
  onDoneClick?: null | ((time: TimeOutput, Event?: React.MouseEvent) => void)
  doneButton?: null | ((time: TimeOutput) => React.ReactElement)
  className?: string
}

const TimePicker = ({
  time,
  onChange,
  hour24Mode = false,
  switchToMinuteOnHourSelect = false,
  switchToMinuteOnHourDropdownSelect = false,
  closeOnMinuteSelect = false,
  coarseMinutes = 5,
  forceCoarseMinutes = false,
  onDoneClick,
  doneButton,
  className,
}: TimePickerProps) => (
  <S.Wrapper className={className}>
    <TimeKeeper
      time={time}
      onChange={onChange}
      hour24Mode={hour24Mode}
      switchToMinuteOnHourSelect={switchToMinuteOnHourSelect}
      switchToMinuteOnHourDropdownSelect={switchToMinuteOnHourDropdownSelect}
      closeOnMinuteSelect={closeOnMinuteSelect}
      coarseMinutes={coarseMinutes}
      forceCoarseMinutes={forceCoarseMinutes}
      onDoneClick={onDoneClick}
      doneButton={doneButton}
    />
  </S.Wrapper>
)

export default TimePicker

import { ReactNode } from 'react'

import * as S from './styles'

export type HeadingProps = {
  title: string
  titleAppend?: string
  subtitle: string | ReactNode
}

const Heading = ({ title, titleAppend, subtitle }: HeadingProps) => (
  <S.Wrapper>
    <h1>
      {title}
      {!!titleAppend && <S.Append>{titleAppend}</S.Append>}
    </h1>
    <h6>{subtitle}</h6>
  </S.Wrapper>
)

export default Heading

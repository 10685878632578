import styled, { css } from 'styled-components'

import { DatepickerWrapper as DateRangePickerWrapper } from '@/components/DateRangePicker/styles'

export const Content = styled.div`
  ${({ theme }) => css`
    padding-top: 1.813rem;
    display: flex;
    flex-direction: column;

    .tooltip {
      border-radius: 8px;
      font-family: ${theme.font.quicksand};
      font-size: ${theme.font.sizes.xsmall};
    }
  `}
`

export const Header = styled.header`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: ${theme.spacings.medium};

    h1 {
      font-size: ${theme.font.sizes.xlarge};
      color: ${theme.colors.primary};
      font-family: ${theme.font.nunito};
      font-weight: ${theme.font.extraBold};
    }
  `}
`

export const Actions = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacings.medium};
    width: 100%;
    margin-bottom: ${theme.spacings.medium};
  `}
`

export const PeriodSelected = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
  `}
`
export const DatepickerWrapper = styled(DateRangePickerWrapper)`
  ${({ theme }) => css`
    min-width: 12.5rem;
    max-width: 18.75rem;

    .react-datepicker__input-container {
      input {
        color: ${theme.dark
          ? theme.colors.black
          : theme.colors.secondary} !important;
      }
    }
  `}
`

export const ContentTable = styled.section`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: ${theme.spacings.medium};
  `}
`

import { Info as InfoIcon } from '@styled-icons/material/Info'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Info as InfoOutlineIcon,
} from '@styled-icons/material-outlined'
import { useCallback, useEffect, useState } from 'react'
import { ValueType } from 'react-select'
import ReactTooltip from 'react-tooltip'

import menuMakerService from '@/api/menu-maker.service'
import othersPriceIcon from '@/assets/img/icons-others-price.svg'
import { Box } from '@/components/Box'
import Button from '@/components/Button'
import Checkbox from '@/components/Checkbox'
import Empty from '@/components/Empty'
import List from '@/components/List'
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverflow,
  ModalTrigger,
} from '@/components/Modal'
import Select from '@/components/Select'
import TextField from '@/components/TextField'
import useModal from '@/hooks/useModal'
import ApplyMultipleItemsModal from '@/modals/ApplyMultipleItemsModal'
import ConfirmModal from '@/modals/ConfirmModal'
import EditItemModal from '@/modals/EditItemModal'
import ItemsAssignedModal from '@/modals/ItemsAssignedModal'
import {
  CategoryType,
  ItemType,
  MenusType,
  ModifierType,
} from '@/types/menu-maker.types'

import * as S from './styles'

export type EditModifierModalProps = {
  editModifierData: ModifierType | null
  merchant: string
  items: ItemType[]
  categories: CategoryType[]
  menus: MenusType[]
  modifiers: ModifierType[]
  closeEditModifier: (refresh: boolean, modifier?: ModifierType) => void
}

type FormErrorsType = {
  name: string
  items: string
  hasItem: string
}

type ItemSelectedType = {
  name: string
  price: string | number
  itemId: string
}

type RestrictionType = {
  name: string
  quantity: string | number
  selected: boolean
  info: string
  hasSelect: boolean
  hasQuantity: boolean
}

const itemDefault: ModifierType = {
  name: '',
  note: '',
  merchant: '',
  itemsAssigned: [],
  max: 0,
  min: 0,
  modifierId: '',
  maxEachOption: 0,
  options: [],
}

const restrictionsDefault: RestrictionType[] = [
  {
    name: 'Require customer to choose modifier',
    quantity: 0,
    info: 'Use this to force customer to select at least 1 option',
    selected: false,
    hasSelect: true,
    hasQuantity: false,
  },
  {
    name: 'Set total number of modifiers customers can choose',
    quantity: 99,
    info: 'Use this to limit the number of options for this entire modifier',
    selected: false,
    hasSelect: true,
    hasQuantity: false,
  },
  {
    name: 'Set max number of EACH* modifier ITEM* customer can choose',
    quantity: 99,
    info: 'Use this to set a limit on each option for this modifier',
    selected: false,
    hasSelect: false,
    hasQuantity: true,
  },
]

const selectItemSelected = (
  items: ItemType[],
  editModifierData: ModifierType | null,
) => {
  if (editModifierData?.options) {
    const { options } = editModifierData
    return options.map((option) => {
      const selectedFound = items.find((item) => item.itemId === option.itemId)
      return {
        name: selectedFound?.name || '',
        itemId: option.itemId,
        price: Number(option.price).toFixed(2) || Number(0).toFixed(2),
      }
    })
  }
  return []
}

const EditModifierModal = ({
  editModifierData,
  closeEditModifier,
  merchant,
  items,
  categories,
  menus,
  modifiers,
}: EditModifierModalProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [name, setName] = useState(editModifierData?.name || '')
  const [note, setNote] = useState(editModifierData?.note || '')
  const [itemsAssigned, setItemsAssigned] = useState(
    editModifierData?.itemsAssigned || [],
  )
  const { open: itemModalIsOpen, toggle: setItemModalIsOpen } = useModal()
  const { open: openItemsAssignedModal, toggle: setOpenItemsAssignedModal } =
    useModal()
  const {
    open: openApplyMultipleItemsModal,
    toggle: setOpenApplyMultipleItemsModal,
  } = useModal()
  const [selected, setSelected] = useState<ItemType | null>(null)
  const [itemsSelected, setItemsSelected] = useState<ItemSelectedType[]>(
    selectItemSelected(items, editModifierData),
  )
  const [restrictions, setRestrictions] = useState<RestrictionType[]>(
    restrictionsDefault.map((res, index) => {
      if (index === 0) {
        res.quantity = editModifierData?.min || 0
        res.selected = !!res.quantity
      }
      if (index === 1) {
        res.quantity = editModifierData?.max || 99
        res.selected = res.quantity !== 99
        res.hasQuantity = res.quantity !== 99
      }
      if (index === 2) {
        res.quantity = editModifierData?.maxEachOption || 99
      }
      return res
    }),
  )
  const [formErrors, setFormErrors] = useState<FormErrorsType>({
    name: '',
    items: '',
    hasItem: '',
  })
  const isEditing = editModifierData !== null

  const handleSelectRestrictions = useCallback(
    (item: RestrictionType, index: number) => {
      const newRestriction = [...restrictions]
      newRestriction[index] = {
        ...item,
        selected: !item.selected,
      }
      if (index === 0) {
        newRestriction[0].quantity = !newRestriction[0].selected ? 0 : 1
      }
      if (index === 1) {
        newRestriction[1].quantity = !newRestriction[1].selected ? 99 : 1
        newRestriction[1].hasQuantity = !!newRestriction[1].selected
      }
      setRestrictions(newRestriction)
    },
    [restrictions],
  )

  const handleSetRestrictionsQuantity = useCallback(
    (value: string, item: RestrictionType, index: number) => {
      const newRestriction = [...restrictions]
      newRestriction[index] = {
        ...item,
        quantity: value,
      }
      setRestrictions(newRestriction)
    },
    [restrictions],
  )

  const handleReorder = (items: ItemSelectedType[]) => {
    setItemsSelected(items)
  }

  const handleSetItemPrice = useCallback(
    (value: string, item: ItemSelectedType, index: number) => {
      const newItemsSelected = [...itemsSelected]
      newItemsSelected[index] = {
        ...item,
        price: value,
      }
      setItemsSelected(newItemsSelected)
    },
    [itemsSelected],
  )

  const saveModifier = useCallback(async () => {
    setIsLoading(true)
    const options = itemsSelected.map(({ itemId, price }) => ({
      itemId,
      price: parseInt(price.toString().replace('.', ''), 10),
    }))
    const data: ModifierType = editModifierData
      ? {
          ...editModifierData,
          name,
          note,
          min: Number(restrictions[0].quantity) as number,
          max: Number(restrictions[1].quantity) as number,
          maxEachOption: Number(restrictions[2].quantity) as number,
          itemsAssigned,
          options,
        }
      : ({
          ...itemDefault,
          name,
          note,
          merchant,
          min: Number(restrictions[0].quantity) as number,
          max: Number(restrictions[1].quantity) as number,
          maxEachOption: Number(restrictions[2].quantity) as number,
          itemsAssigned,
          options,
        } as ModifierType)

    if (!data.name) {
      setFormErrors((old) => ({ ...old, name: 'Name is required' }))
      setIsLoading(false)
      return
    }

    setFormErrors((old) => ({ ...old, name: '' }))

    if (!data?.options?.length) {
      setFormErrors((old) => ({
        ...old,
        items: 'Select at least one item',
      }))
      setIsLoading(false)
      return
    }

    setFormErrors((old) => ({ ...old, items: '' }))

    try {
      const { data: modifier } = await menuMakerService.saveModifier(data)
      closeEditModifier(true, modifier)
    } finally {
      setIsLoading(false)
    }
  }, [
    itemsSelected,
    editModifierData,
    name,
    note,
    restrictions,
    itemsAssigned,
    merchant,
    closeEditModifier,
  ])

  const handleModifierSelectChange = (option: ValueType<ItemType, false>) => {
    if (option) {
      setFormErrors((old) => ({ ...old, hasItem: '', items: '' }))
      const hasItem = itemsSelected.some(
        (item) => item.itemId === option.itemId,
      )
      if (hasItem) {
        setFormErrors((old) => ({ ...old, hasItem: 'Item already added' }))
        return
      }
      const newItem = {
        name: option.name,
        itemId: option.itemId,
        price: Number(0).toFixed(2),
      }
      setItemsSelected((old) => [...old, newItem])
      setSelected(null)
    }
  }

  const handleRemoveItem = (index: number) => {
    const newItems = [...itemsSelected]
    newItems.splice(index, 1)
    setItemsSelected(newItems)
  }

  const closeModalItem = (refresh?: boolean, item?: ItemType) => {
    if (refresh && item) {
      items.push({ ...item, price: item.price / 100 })
    }
    setItemModalIsOpen(false)
  }

  const NewItemModal = () => {
    return (
      <Modal open={itemModalIsOpen} onOpenChange={setItemModalIsOpen} modal>
        <ModalTrigger asChild>
          <Button>New item</Button>
        </ModalTrigger>
        <ModalContent
          title="Create Item"
          onClose={() => setItemModalIsOpen(false)}
        >
          <EditItemModal
            closeEditItem={closeModalItem}
            editItemData={null}
            merchant={merchant}
            categories={categories}
            menus={menus}
            modifiers={modifiers}
            items={items}
          />
        </ModalContent>
      </Modal>
    )
  }

  const RenderItemsAssignedModal = () => (
    <Modal
      open={openItemsAssignedModal}
      onOpenChange={(open) => {
        if (!isEditing) {
          setOpenItemsAssignedModal(false)
          return
        }
        setOpenItemsAssignedModal(open)
      }}
      modal
    >
      <ModalTrigger asChild>
        <span
          data-tip="Save this modifier before assigning to items"
          data-for="buttons"
          data-tip-disable={isEditing}
        >
          <Button disabled={!isEditing}>Assigned Items</Button>
        </span>
      </ModalTrigger>
      <ModalContent
        title="Items Assigned"
        onClose={() => setOpenItemsAssignedModal(false)}
      >
        <ItemsAssignedModal items={items} assigned={itemsAssigned} />
      </ModalContent>
    </Modal>
  )

  const RenderApplyMultipleItemsModal = () => (
    <Modal
      open={openApplyMultipleItemsModal}
      onOpenChange={(open) => {
        if (!isEditing) {
          setOpenApplyMultipleItemsModal(false)
          return
        }
        setOpenApplyMultipleItemsModal(open)
      }}
      modal
    >
      <ModalTrigger asChild>
        <span
          data-tip="Save this modifier before assigning to items"
          data-for="buttons"
          data-tip-disable={isEditing}
        >
          <Button disabled={!isEditing}>
            Apply modifier to multiple items
          </Button>
        </span>
      </ModalTrigger>
      <ModalContent
        title="Apply Modifier"
        onClose={() => setOpenApplyMultipleItemsModal(false)}
      >
        <ApplyMultipleItemsModal
          items={items}
          modifier={
            editModifierData ||
            ({
              ...itemDefault,
              name,
              note,
              merchant,
              min: restrictions[0].quantity as number,
              max: restrictions[1].quantity as number,
              maxEachOption: Number(restrictions[2].quantity) as number,
            } as ModifierType)
          }
          assigned={itemsAssigned}
          closeApplyMultipleItems={(assigned) => {
            if (assigned) setItemsAssigned(assigned)
            setOpenApplyMultipleItemsModal(false)
          }}
        />
      </ModalContent>
    </Modal>
  )

  const deleteModifier = useCallback(async () => {
    setIsDeleting(true)
    const options = itemsSelected.map(({ itemId, price }) => ({
      itemId,
      price: parseInt(price.toString().replace('.', ''), 10),
    }))
    const data: ModifierType = {
      ...editModifierData!,
      name,
      note,
      min: Number(restrictions[0].quantity) as number,
      max: Number(restrictions[1].quantity) as number,
      maxEachOption: Number(restrictions[2].quantity) as number,
      itemsAssigned,
      options,
    }

    try {
      await menuMakerService.deleteModifier(data)
      closeEditModifier(true)
    } finally {
      setIsDeleting(false)
    }
  }, [
    closeEditModifier,
    editModifierData,
    itemsAssigned,
    itemsSelected,
    name,
    note,
    restrictions,
  ])

  const RenderConfirmModal = () => (
    <Modal modal>
      <ModalTrigger asChild>
        <Button
          color="white"
          size="large"
          disabled={isDeleting}
          loading={isDeleting}
          shadow
        >
          Delete
        </Button>
      </ModalTrigger>
      <ModalContent
        title="Delete Modifier"
        width="auto"
        xsWidth="90vw"
        xsHeight="auto"
        hideCloseButton
      >
        <ConfirmModal
          message={
            <>
              Are you sure you want to delete modifier{' '}
              <strong>{editModifierData?.name}</strong>?
            </>
          }
          closeConfirm={(confirm) => {
            if (confirm) deleteModifier()
          }}
        />
      </ModalContent>
    </Modal>
  )

  const renderNewItemOption = () =>
    (
      <Button
        color="white"
        style={{ fontSize: '0.875rem' }}
        onClick={() => setItemModalIsOpen(true)}
      >
        Add Item <AddIcon size={20} />
      </Button>
    ) as unknown as string

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [isEditing])

  return (
    <>
      <ModalOverflow>
        <S.WrapperContent>
          <Box flex style={{ marginBottom: '1rem' }}>
            <S.FormControl>
              <TextField
                value={name}
                label="Name"
                labelFor="name"
                id="name"
                onChange={(event) => setName(event.currentTarget.value)}
                error={formErrors.name}
                disabled={isLoading}
                variant="material"
              />
            </S.FormControl>

            <S.FormControl>
              <TextField
                value={note}
                label="Note"
                labelFor="note"
                id="note"
                onChange={(event) => setNote(event.currentTarget.value)}
                disabled={isLoading}
                variant="material"
              />
            </S.FormControl>
          </Box>

          <S.SelectModifiers>
            <Select<ItemType>
              options={items}
              formatOptionLabel={(option) => (
                <S.SelectOption>
                  <h5>{option.name}</h5>
                  <S.OptionPrice>
                    {option.price ? `KYD ${option.price.toFixed(2)}` : '-'}
                  </S.OptionPrice>
                </S.SelectOption>
              )}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.itemId}
              placeholder="Search existing item modifiers"
              variant="material"
              onChange={handleModifierSelectChange}
              value={selected}
              noOptionsMessage={renderNewItemOption}
              isSearchable
              isClearable
            />
            {formErrors.hasItem && (
              <S.TextError style={{ marginLeft: '1.5rem' }}>
                {formErrors.hasItem}
              </S.TextError>
            )}
          </S.SelectModifiers>

          <S.Content>
            <S.MenuForm>
              <Box flex flexDirection="column" width="100%">
                <S.LabelForm>
                  List of items{' '}
                  <InfoOutlineIcon
                    size={24}
                    data-tip="List of item options for this modifier"
                    data-for="items_info"
                  />
                  <ReactTooltip
                    effect="solid"
                    className="tooltip"
                    backgroundColor="#433d3d"
                    borderColor="#433d3d"
                    id="items_info"
                  />
                </S.LabelForm>

                {itemsSelected.length === 0 ? (
                  <Box
                    flex
                    JustifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="6.25rem"
                  >
                    <Empty title="There is nothing here, yet" />
                  </Box>
                ) : (
                  <List<ItemSelectedType>
                    data={itemsSelected}
                    onReorder={handleReorder}
                    dragAndDrop
                  >
                    {(item, index) => (
                      <>
                        <Box flex flexDirection="column" width="40%">
                          <S.Name>{item.name}</S.Name>
                        </Box>

                        <S.Actions>
                          <S.PriceWrapper>
                            <TextField
                              value={
                                item.price
                                  ? Number(item.price).toFixed(2)
                                  : item.price
                              }
                              id={`price_${index}`}
                              onChange={(event) =>
                                handleSetItemPrice(
                                  event.currentTarget.value,
                                  item,
                                  index,
                                )
                              }
                              disabled={isLoading}
                              variant="material"
                              placeholder="Price"
                              icon={
                                <img
                                  src={othersPriceIcon}
                                  width={20}
                                  alt="Others price icon"
                                />
                              }
                              mask="money"
                            />
                          </S.PriceWrapper>

                          <S.Action onClick={() => handleRemoveItem(index)}>
                            <S.ButtonIcon>
                              <DeleteIcon size={14} />
                            </S.ButtonIcon>
                            Delete
                          </S.Action>
                        </S.Actions>
                      </>
                    )}
                  </List>
                )}

                {formErrors.items && (
                  <S.TextError>{formErrors.items}</S.TextError>
                )}
              </Box>
            </S.MenuForm>
          </S.Content>

          <S.Buttons>
            <NewItemModal />

            <RenderItemsAssignedModal />

            <RenderApplyMultipleItemsModal />

            <ReactTooltip
              effect="solid"
              className="tooltip"
              backgroundColor="#433d3d"
              borderColor="#433d3d"
              id="buttons"
            />
          </S.Buttons>

          <S.Content>
            <S.MenuForm>
              <Box flex flexDirection="column" width="100%">
                <S.LabelForm>Modifier Restrictions</S.LabelForm>

                <List<RestrictionType> data={restrictions}>
                  {(item, index) => (
                    <Box flex flexDirection="column" width="100%">
                      <Box flex JustifyContent="space-between">
                        <S.Name>
                          {item.name}{' '}
                          <InfoIcon
                            size={18}
                            color="#ff4c4c"
                            data-tip={item.info}
                            data-for={`restrictions${index}_info`}
                          />
                          <ReactTooltip
                            effect="solid"
                            className="tooltip"
                            backgroundColor="#433d3d"
                            borderColor="#433d3d"
                            id={`restrictions${index}_info`}
                          />
                        </S.Name>

                        {item.hasSelect && (
                          <Checkbox
                            checked={item.selected}
                            onCheck={() =>
                              handleSelectRestrictions(item, index)
                            }
                          />
                        )}
                      </Box>

                      {item.hasQuantity && (
                        <S.PriceWrapper>
                          <TextField
                            type="number"
                            value={item.quantity}
                            id={`quantity_${index}`}
                            onChange={(event) =>
                              handleSetRestrictionsQuantity(
                                event.currentTarget.value,
                                item,
                                index,
                              )
                            }
                            disabled={isLoading}
                            variant="material"
                            label="Quantity"
                            labelFor={`quantity_${index}`}
                            mask="number"
                            min={1}
                            max={99}
                          />
                        </S.PriceWrapper>
                      )}
                    </Box>
                  )}
                </List>
              </Box>
            </S.MenuForm>
          </S.Content>
        </S.WrapperContent>
      </ModalOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
          style={{ marginTop: '1rem' }}
        >
          {editModifierData && <RenderConfirmModal />}

          <Button
            size="large"
            disabled={isLoading}
            onClick={saveModifier}
            loading={isLoading}
            style={{ marginLeft: 'auto' }}
          >
            Save
          </Button>
        </Box>
      </ModalFooter>
    </>
  )
}

export default EditModifierModal

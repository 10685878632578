import { Box } from '@/components/Box'
import Skeleton from '@/components/Skeleton'

import * as S from './styles'

export const FormSettingsHoursLoading = () => (
  <S.Wrapper>
    <S.Subtitle>
      <Skeleton width="7.188rem" height="1.25rem" />
    </S.Subtitle>

    <S.SavedItems>
      <S.SavedItemContainer>
        <S.SavedItem>
          <h5>
            <Skeleton width="3.125rem" height="0.938rem" />
          </h5>
          <S.SavedDescription>
            <Skeleton height="1.25rem" />
          </S.SavedDescription>
        </S.SavedItem>
        <S.SavedItem>
          <h5>
            <Skeleton width="3.125rem" height="0.938rem" />
          </h5>
          <S.SavedDescription>
            <Skeleton width="100%" height="1.25rem" />
          </S.SavedDescription>
        </S.SavedItem>
      </S.SavedItemContainer>
    </S.SavedItems>

    <Box
      flex
      alignItems="center"
      JustifyContent="center"
      style={{ marginTop: '1.25rem' }}
    >
      <Skeleton width="40%" height="1.25rem" />
    </Box>

    <S.Subtitle style={{ marginBottom: '0.313rem' }}>
      <Skeleton width="7.188rem" height="1.25rem" />
    </S.Subtitle>
    <Box width="5rem">
      <Skeleton width="6.25rem" height="2.5rem" />
    </Box>
  </S.Wrapper>
)

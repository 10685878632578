import { cn } from '@iguanads/react'
import { OpenInNew as OpenInNewIcon } from '@styled-icons/material/OpenInNew'
import { ComponentProps } from 'react'
import { useMediaQuery } from 'react-responsive'

import { Box } from '@/components/Box'
import Button from '@/components/Button'
import Logo from '@/components/Logo'
import MerchantDropdown from '@/components/MerchantDropdown'
import Skeleton from '@/components/Skeleton'
import ThemeDropdown from '@/components/ThemeDropdown'
import { env } from '@/env'
import useAuth from '@/hooks/useAuth'

import MerchantInfo from '../MerchantInfo'
import SearchInput from '../SearchInput'
import * as S from './styles'

export type HeaderProps = ComponentProps<'header'> & {
  loading?: boolean
}

export const Header = ({ loading, className, ...props }: HeaderProps) => {
  const { merchantsSelected, merchantSelected, isSuperAdmin } = useAuth()
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  })
  const isOneMerchantSelected = merchantsSelected?.length === 1

  return (
    <header
      className={cn(
        'flex w-full min-w-[22.5rem] flex-col gap-2 pb-[0.625rem] grid-in-header md:px-[0px] md:pt-3',
        className,
      )}
      {...props}
    >
      <Box flex alignItems="center" JustifyContent="space-between">
        <S.LogoSearch>
          <S.LogoWrapper>
            <Logo />
          </S.LogoWrapper>

          {isSuperAdmin && !isMobile && <SearchInput />}
        </S.LogoSearch>

        <S.UserSection>
          <ThemeDropdown />

          <S.MerchantSection>
            {isOneMerchantSelected && <MerchantInfo loading={loading} />}

            <S.MerchantDetails>
              {isOneMerchantSelected && (
                <S.MerchantName>
                  {loading ? (
                    <>
                      <Skeleton width="130px" height="1.25rem" />
                      <Skeleton width="130px" />
                    </>
                  ) : (
                    <>
                      <S.MerchantInfo>{merchantSelected?.name}</S.MerchantInfo>
                      <Button
                        as="a"
                        href={`https://${
                          env.VITE_ENV === 'stage' ? 'stage.' : ''
                        }bento.ky/merchants/${merchantSelected?.slug}/${
                          merchantSelected?.id
                        }`}
                        target="_blank"
                        icon={<OpenInNewIcon />}
                        style={{
                          padding: 0,
                          textDecoration: 'none',
                          lineHeight: '1.25rem',
                          height: 'auto',
                        }}
                        minimal
                      >
                        view live page
                      </Button>
                    </>
                  )}
                </S.MerchantName>
              )}

              <MerchantDropdown loading={loading} />
            </S.MerchantDetails>
          </S.MerchantSection>
        </S.UserSection>
      </Box>

      {isSuperAdmin && isMobile && <SearchInput />}
    </header>
  )
}

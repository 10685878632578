import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const MagicVoucherContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    img {
      position: absolute;
      top: calc(50% - 75px);
      left: calc(50% - 85px);
      width: 150px;
    }

    span {
      animation: blinker 1s linear infinite;
      font-family: ${theme.font.quicksand};
      font-size: 1.875rem;
      font-weight: 600;
      position: absolute;
      top: calc(65%);
      left: calc(50% - 136px);
      z-index: 4000;
      color: #ffffff;

      ${media.greaterThan('medium')`
        top: calc(70%);
      `}
    }
  `}

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`

export const MagicVoucherPanel = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(30, 30, 30, 0.8);
  width: 100%;
  height: 100%;
  z-index: 9200;
`

import {
  KeyboardArrowDown,
  KeyboardArrowRight,
} from '@styled-icons/material-rounded'
import {
  format,
  getDay,
  getMinutes,
  setDay,
  setHours,
  setMinutes,
} from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useMediaQuery } from 'react-responsive'

import menuMakerService from '@/api/menu-maker.service'
import overviewService from '@/api/overview.service'
import { Box } from '@/components/Box'
import Empty from '@/components/Empty'
import OverviewListTable from '@/components/OverviewListTable'
import Select from '@/components/Select'
import Skeleton from '@/components/Skeleton'
import useAuth from '@/hooks/useAuth'
import {
  CategoryType,
  ItemType,
  MenusType,
  ModifierType,
} from '@/types/menu-maker.types'
import { OverviewCategoryType, OverviewType } from '@/types/overview.types'
import { SortAlphabetically } from '@/utils/formats/sort'

import * as S from './styles'

const hours = [
  { value: '00:00', label: '00:00' },
  { value: '00:30', label: '00:30' },
  { value: '01:00', label: '01:00' },
  { value: '01:30', label: '01:30' },
  { value: '02:00', label: '02:00' },
  { value: '02:30', label: '02:30' },
  { value: '03:00', label: '03:00' },
  { value: '03:30', label: '03:30' },
  { value: '04:00', label: '04:00' },
  { value: '04:30', label: '04:30' },
  { value: '05:00', label: '05:00' },
  { value: '05:30', label: '05:30' },
  { value: '06:00', label: '06:00' },
  { value: '06:30', label: '06:30' },
  { value: '07:00', label: '07:00' },
  { value: '07:30', label: '07:30' },
  { value: '08:00', label: '08:00' },
  { value: '08:30', label: '08:30' },
  { value: '09:00', label: '09:00' },
  { value: '09:30', label: '09:30' },
  { value: '10:00', label: '10:00' },
  { value: '10:30', label: '10:30' },
  { value: '11:00', label: '11:00' },
  { value: '11:30', label: '11:30' },
]

const days = [
  { value: '0', label: 'Sunday' },
  { value: '1', label: 'Monday' },
  { value: '2', label: 'Tuesday' },
  { value: '3', label: 'Wednesday' },
  { value: '4', label: 'Thursday' },
  { value: '5', label: 'Friday' },
  { value: '6', label: 'Saturday' },
]

const getHourNow = () => {
  return `${format(new Date(), 'hh')}:${
    getMinutes(new Date()) > 30 ? '30' : '00'
  }`
}

const formatDateToGetMenus = (
  hour: string,
  periodDay: string,
  dayWeek: string,
) => {
  const [hours, minutes] = hour.split(':')
  const periodHour = periodDay === 'am' ? Number(hours) : Number(hours) + 12
  const date = setMinutes(
    setHours(setDay(new Date(), Number(dayWeek)), periodHour),
    Number(minutes),
  )
  return `${format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")}`
}

export function Overview() {
  const [hour, setHour] = useState(getHourNow())
  const [dayWeek, setDayWeek] = useState(String(getDay(new Date())))
  const [periodDay, setPeriodDay] = useState(format(new Date(), 'aaa'))
  const [items, setItems] = useState<ItemType[]>([])
  const [categories, setCategories] = useState<CategoryType[]>([])
  const [menus, setMenus] = useState<MenusType[]>([])
  const [modifiers, setModifiers] = useState<ModifierType[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [allExpanded, setAllExpanded] = useState(false)
  const [data, setData] = useState<OverviewType | null>(null)
  const { merchantSelected } = useAuth()
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  })

  const getMenus = useCallback(async (id: string, date: string) => {
    setIsLoading(true)
    setHasError(false)

    try {
      const [resOverview, resItems, resCategories, resMenus, resModifiers] =
        await Promise.all([
          overviewService.getMerchantMenus(id, date),
          menuMakerService.getItems(id),
          menuMakerService.getCategories(id),
          menuMakerService.getMenus(id),
          menuMakerService.getModifiers(id),
        ])
      const { data: dataOverview } = resOverview
      const { data: dataItems } = resItems
      const { data: dataCat } = resCategories
      const { data: dataMenus } = resMenus
      const { data: dataModifiers } = resModifiers

      const newItems = dataItems.map((item) => {
        return {
          ...item,
          price: item.price / 100,
          prices: item?.prices
            ? item.prices.map((p) => ({ ...p, price: p.price / 100 }))
            : [],
          categoriesName: dataCat
            .filter((c) => item.categories?.includes(c.categoryId!))
            .map((cat) => cat.name),
          menusLength: item.menus?.length || 0,
          modifiersLength: item.modifiers?.length || 0,
        }
      })

      const data = {
        ...dataOverview,
        categories: dataOverview.categories.map(
          (category: OverviewCategoryType) => ({
            ...category,
            itemsLength: category.items?.length || 0,
            items: dataOverview.items
              .filter((item: ItemType) =>
                (category.items as string[])?.includes(item.itemId),
              )
              .map((item: ItemType) => ({
                ...item,
                modifiersLength: item.modifiers?.length || 0,
              })),
          }),
        ),
      }

      setData(data)
      setItems(newItems)
      setCategories(dataCat.sort(SortAlphabetically<CategoryType>('name')))
      setMenus(dataMenus)
      setModifiers(dataModifiers)
    } catch (error) {
      setData(null)
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const onReorder = useCallback(
    async (categories: OverviewCategoryType[]) => {
      if (data) {
        const newItems = { ...data }
        newItems.categories = categories
        setData(newItems)
      }
    },
    [data],
  )

  const handleSave = useCallback(async () => {
    if (merchantSelected?.id && data?.categories) {
      setIsSaveLoading(true)

      try {
        await overviewService.saveMerchantOverview(merchantSelected.id, {
          categories: data.categories,
        })
        getMenus(
          merchantSelected?.id,
          formatDateToGetMenus(hour, periodDay, dayWeek),
        )
      } finally {
        setIsSaveLoading(false)
      }
    }
  }, [
    dayWeek,
    getMenus,
    hour,
    data?.categories,
    merchantSelected?.id,
    periodDay,
  ])

  const onRefreshMenus = useCallback(() => {
    if (hour && dayWeek && periodDay && merchantSelected) {
      getMenus(
        merchantSelected?.id,
        formatDateToGetMenus(hour, periodDay, dayWeek),
      )
    }
  }, [dayWeek, getMenus, hour, merchantSelected, periodDay])

  useEffect(() => {
    if (hour && dayWeek && periodDay && merchantSelected) {
      getMenus(
        merchantSelected?.id,
        formatDateToGetMenus(hour, periodDay, dayWeek),
      )
    }
  }, [dayWeek, hour, periodDay, merchantSelected, getMenus])

  return (
    <>
      <Helmet title="Overview" />
      <S.Content>
        <S.Header>
          <h1>Overview</h1>
        </S.Header>

        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          style={{ marginBottom: '2.5rem' }}
        >
          <S.FormBox flex>
            <S.FormControl>
              <S.Label>Menu hours</S.Label>
              <Select
                options={hours}
                formatOptionLabel={(option) => option.label}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={(option) => option && setHour(option.value)}
                value={hours.find((h) => h.value === hour)}
              />
            </S.FormControl>

            <S.FormControl>
              <Box flex alignItems="center" gap="0.25rem">
                <S.SelectButtonTime
                  selected={periodDay === 'am'}
                  onClick={() => setPeriodDay('am')}
                >
                  AM
                </S.SelectButtonTime>
                <S.Label>or</S.Label>
                <S.SelectButtonTime
                  selected={periodDay === 'pm'}
                  onClick={() => setPeriodDay('pm')}
                >
                  PM
                </S.SelectButtonTime>
              </Box>
            </S.FormControl>

            <S.FormControl>
              {isMobile && <S.Label>Week day</S.Label>}
              <Select
                options={days}
                formatOptionLabel={(option) => option.label}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={(option) => option && setDayWeek(option.value)}
                value={days.find((d) => d.value === dayWeek)}
              />
            </S.FormControl>
          </S.FormBox>

          <S.ExpandButton
            icon={allExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            iconside="right"
            onClick={() => setAllExpanded(!allExpanded)}
            minimal
          >
            {allExpanded ? 'Collapse' : 'Expand'} all
          </S.ExpandButton>
        </Box>

        {isLoading ? (
          <Skeleton height="2.5rem" count={7} radius="4px" margin="2.5rem" />
        ) : hasError ? (
          <Box
            flex
            JustifyContent="center"
            alignItems="center"
            width="100%"
            height="31.25rem"
          >
            <Empty
              title="Something Happened"
              description="Reload the page and try again"
            />
          </Box>
        ) : data === null || data.categories.length === 0 ? (
          <Box
            flex
            JustifyContent="center"
            alignItems="center"
            width="100%"
            height="31.25rem"
          >
            <Empty title="There is nothing here, yet" />
          </Box>
        ) : (
          <OverviewListTable
            data={data.categories}
            onReorder={onReorder}
            categories={categories}
            items={items}
            menus={menus}
            modifiers={modifiers}
            refreshMenus={onRefreshMenus}
            expanded={allExpanded}
          />
        )}

        <S.FabButtonSave
          size="large"
          onClick={handleSave}
          loading={isSaveLoading}
          disabled={hasError || isLoading || isSaveLoading}
        >
          Save
        </S.FabButtonSave>
      </S.Content>
    </>
  )
}

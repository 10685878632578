import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { InputWrapper } from '@/components/TextField/styles'

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacings.medium} ${theme.spacings.small};
    margin-bottom: ${theme.spacings.small};
  `}
`

export const MenuForm = styled.div`
  display: flex;

  ${media.lessThan('medium')`
    flex-direction: column;

    ${Box} {
      width: 100%;
    }
  `}
`

export const TextError = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.red};
    font-size: ${theme.font.sizes.xsmall};
  `}
`

export const LabelForm = styled.label`
  ${({ theme }) => css`
    font-size: 1.125rem;
    font-weight: ${theme.font.semiBold};
    margin-bottom: ${theme.spacings.xsmall};
  `}
`

export const Days = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Hours = styled.div`
  display: flex;
  align-items: center;
`

export const Hour = styled.div<{ first?: boolean }>`
  ${({ theme, first = false }) => css`
    ${InputWrapper} {
      ${first &&
      css`
        margin-right: ${theme.spacings.xxsmall} !important;
      `}
    }
  `}
`

export const ButtonIcon = styled(Button)`
  padding: 0.5rem;
`

export const ButtonAdd = styled(Button)`
  ${({ theme }) => css`
      font-weight: ${theme.font.medium};
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.gray};
      border-bottom: 1px solid #979797;
      border-radius: 0;
      padding: 0 0.25rem;
      margin-top: ${theme.spacings.xsmall};
    }
  `}
`

import deleteIcon from '@/assets/img/icon-delete.svg'
import rightIcon from '@/assets/img/icons-right.svg'
import magicVoucherIcon from '@/assets/img/magic-voucher-icon.svg'
import { Modal, ModalContent } from '@/components/Modal'
import useAuth from '@/hooks/useAuth'
import useModal from '@/hooks/useModal'
import {
  FieldContent,
  FieldHeader,
  FieldHeaderTitle,
  FieldWrapper,
} from '@/modals/CreatingNewCampaignModal/styles'
import { MagicVoucherModal } from '@/modals/MagicVoucherModal'
import { MagicVoucher } from '@/types/super-partner.types'
import { formatCurrencyWithSymbol } from '@/utils/formats/number'

import * as S from './styles'

export type MagicVoucherFieldProps = {
  value?: MagicVoucher[]
  error?: string
  loading?: boolean
  disabled?: boolean
  onChange: (values?: MagicVoucher[]) => void
}

export const MagicVoucherField = ({
  onChange,
  loading = false,
  disabled = false,
  value,
}: MagicVoucherFieldProps) => {
  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal()
  const { setMagicVoucherAnimation } = useAuth()

  const handleCloseMagicVoucher = (voucher: MagicVoucher | null) => {
    if (voucher) {
      setMagicVoucherAnimation(true)
      setTimeout(() => {
        setMagicVoucherAnimation(false)
      }, 3000)

      const vouchersAdded = value ? [...value] : []
      onChange([...vouchersAdded, voucher])
    }
    setModalIsOpen(false)
  }

  const renderMagicVoucherModal = () => (
    <Modal open={modalIsOpen} onOpenChange={setModalIsOpen} modal>
      <ModalContent
        title="Magic vouchers"
        onClose={() => setModalIsOpen(false)}
        style={{ minWidth: '25rem' }}
        width="auto"
        simpleHeader
      >
        <MagicVoucherModal
          selectedVouchers={value}
          onCloseMagicVoucher={handleCloseMagicVoucher}
        />
      </ModalContent>
    </Modal>
  )

  const styleOpacity = disabled ? { opacity: '0.5', cursor: 'not-allowed' } : {}

  return (
    <FieldWrapper style={styleOpacity}>
      <FieldHeader>
        <FieldHeaderTitle>Magic Voucher</FieldHeaderTitle>
      </FieldHeader>

      <FieldContent>
        <S.Vouchers>
          {value && value?.length > 0 ? (
            <>
              {value.map((voucher) => (
                <S.Voucher key={voucher.voucherId}>
                  <S.VoucherImage bg={voucher.image} />
                  <S.VoucherDesc>
                    <h4>{voucher.title}</h4>
                    <p>
                      {formatCurrencyWithSymbol(voucher.balance / 100)} discount
                    </p>
                  </S.VoucherDesc>

                  <S.VerticalLine />

                  <S.VoucherActions>
                    <S.VoucherDeleteButton
                      disabled={loading || disabled}
                      onClick={() =>
                        onChange(
                          value.filter(
                            (item) => item.voucherId !== voucher.voucherId,
                          ),
                        )
                      }
                    >
                      <img src={deleteIcon} alt="Delete icon" />
                    </S.VoucherDeleteButton>
                  </S.VoucherActions>
                </S.Voucher>
              ))}

              <S.ButtonVoucher
                type="button"
                onClick={() => !disabled && setModalIsOpen(true)}
                disabled={loading || disabled}
              >
                <S.RightIcon
                  src={rightIcon}
                  alt="Right icon"
                  style={{ marginRight: '1.25rem' }}
                />
              </S.ButtonVoucher>
            </>
          ) : (
            <S.EmptyVoucher
              type="button"
              onClick={() => !disabled && setModalIsOpen(true)}
              disabled={loading || disabled}
            >
              <S.EmptyBox>
                <S.EmptyVoucherImage bg={magicVoucherIcon} />
                <S.VoucherDesc>Have a magic voucher? Use it here</S.VoucherDesc>
              </S.EmptyBox>

              <S.RightIcon
                src={rightIcon}
                alt="Right icon"
                style={{ marginRight: '1.25rem' }}
              />
            </S.EmptyVoucher>
          )}
        </S.Vouchers>
      </FieldContent>

      {modalIsOpen && !disabled && renderMagicVoucherModal()}
    </FieldWrapper>
  )
}

import { Add, Remove } from '@styled-icons/material-rounded'
import { format } from 'date-fns'

import { Box } from '@/components/Box'
import Button from '@/components/Button'
import List from '@/components/List'
import { ModalFooter, ModalOverflow } from '@/components/Modal'
import { BalanceType } from '@/types/dashboard.types'
import { formatCurrencyWithCode } from '@/utils/formats/number'

import * as S from './styles'

type AdditionsDeductionsModalProps = {
  data: BalanceType[]
  close: () => void
}

export const AdditionsDeductionsModal = ({
  data,
  close,
}: AdditionsDeductionsModalProps) => {
  return (
    <>
      <ModalOverflow>
        <S.Section style={{ marginTop: 0 }}>
          <List data={data}>
            {(item) => {
              const itemLabel =
                item.type === 'ADDITION' ? 'Addition' : 'Decuction'
              return (
                <>
                  <Box
                    flex
                    flexDirection="row"
                    gap="0.625rem"
                    JustifyContent="space-between"
                    width="100%"
                  >
                    <Box flex flexDirection="row" gap="0.625rem">
                      <S.Icon addition={item.type === 'ADDITION' ? 1 : 0}>
                        {item.type === 'ADDITION' ? (
                          <Add size={24} />
                        ) : (
                          <Remove size={24} />
                        )}
                      </S.Icon>
                      <Box flex flexDirection="column">
                        <div>{item.description}</div>

                        <S.Date>
                          {itemLabel} added on
                          {format(new Date(item.date), ' MMMM do,yyyy')}
                        </S.Date>
                      </Box>
                    </Box>
                    <S.Value>
                      {formatCurrencyWithCode(item.amount / 100)}
                    </S.Value>
                  </Box>
                </>
              )
            }}
          </List>
        </S.Section>
      </ModalOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
          style={{ marginTop: '1rem' }}
        >
          <Button color="white" size="large" onClick={close} shadow>
            Close
          </Button>
          <span />
        </Box>
      </ModalFooter>
    </>
  )
}

import Loading from '@/components/Loading'

import * as S from './styles'

export type AvatarProps = {
  text?: string
  image?: string
  color?: string
  size?: number
  loading?: boolean
  style?: React.CSSProperties
}

const Avatar = ({
  text,
  image,
  color,
  size = 2.5,
  loading = false,
  style,
}: AvatarProps) => (
  <S.Wrapper size={size} color={color} loading={String(loading)} style={style}>
    {loading ? (
      <Loading />
    ) : (
      <>
        {image && !image.startsWith('blob:') && (
          <S.AvatarImage src={image} alt="Avatar image" />
        )}
        {text && !image && <S.Text>{text}</S.Text>}
      </>
    )}
  </S.Wrapper>
)

export default Avatar

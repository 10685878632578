import styled, { css } from 'styled-components'

export const HeaderTitle = styled.div`
  ${({ theme }) => css`
    font-size: 1.5rem;
    font-weight: ${theme.font.bold};
    font-family: ${theme.font.nunito};
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`

export const HeaderButtonTitle = styled.div``

import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  DialogClose,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Loading,
  RadioGroup,
  RadioGroupItem,
} from '@iguanads/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { OrderStatusEnum } from '@/enums/order.enum'
import useAuth from '@/hooks/useAuth'
import type { OrderType } from '@/types/orders.types'

const formSchema = z.object({
  status: z.enum(
    [
      OrderStatusEnum.IN_PROGRESS,
      OrderStatusEnum.READY,
      OrderStatusEnum.PICKED,
      OrderStatusEnum.IN_TRANSIT,
      OrderStatusEnum.DELIVERED,
    ],
    {
      required_error: 'You need to select a status.',
    },
  ),
})

type FormDataType = z.infer<typeof formSchema>

interface Props {
  order: OrderType
  closeChangeStatus: (status: string) => void
}

export function ChangeStatusDialog({ order, closeChangeStatus }: Props) {
  const [isLoading, setIsLoading] = useState(false)
  const { isSuperAdmin } = useAuth()
  const isBentoDeliveredHandler = order.deliveryHandler === 'bento'
  const isTakeout = order.type === 'takeout'
  const status = order.lastStatus

  const form = useForm<FormDataType>({
    resolver: zodResolver(formSchema),
  })

  const onSubmit = async (data: FormDataType) => {
    setIsLoading(true)
    closeChangeStatus(data.status)
  }

  return (
    <>
      <DialogHeader>
        <DialogTitle className="text-primary">Change Status</DialogTitle>
      </DialogHeader>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-full p-6"
          id="change-status-form"
        >
          <FormField
            control={form.control}
            name="status"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className="flex items-center gap-4"
                  >
                    {status !== OrderStatusEnum.IN_PROGRESS && (
                      <FormItem className="flex items-center space-x-2">
                        <FormControl>
                          <RadioGroupItem
                            id={OrderStatusEnum.IN_PROGRESS}
                            value={OrderStatusEnum.IN_PROGRESS}
                          />
                        </FormControl>
                        <label
                          htmlFor={OrderStatusEnum.IN_PROGRESS}
                          className="text-nowrap"
                        >
                          In Progress
                        </label>
                      </FormItem>
                    )}
                    {status !== OrderStatusEnum.READY && (
                      <FormItem className="flex items-center space-x-2">
                        <FormControl>
                          <RadioGroupItem
                            id={OrderStatusEnum.READY}
                            value={OrderStatusEnum.READY}
                          />
                        </FormControl>
                        <label
                          htmlFor={OrderStatusEnum.READY}
                          className="text-nowrap"
                        >
                          Ready
                        </label>
                      </FormItem>
                    )}
                    {isTakeout && status !== OrderStatusEnum.PICKED && (
                      <FormItem className="flex items-center space-x-2">
                        <FormControl>
                          <RadioGroupItem
                            id={OrderStatusEnum.PICKED}
                            value={OrderStatusEnum.PICKED}
                          />
                        </FormControl>
                        <label
                          htmlFor={OrderStatusEnum.PICKED}
                          className="text-nowrap"
                        >
                          Picked
                        </label>
                      </FormItem>
                    )}
                    {!isTakeout &&
                      isBentoDeliveredHandler &&
                      !!order.driver &&
                      status !== OrderStatusEnum.IN_TRANSIT && (
                        <FormItem className="flex items-center space-x-2">
                          <FormControl>
                            <RadioGroupItem
                              id={OrderStatusEnum.IN_TRANSIT}
                              value={OrderStatusEnum.IN_TRANSIT}
                            />
                          </FormControl>
                          <label
                            htmlFor={OrderStatusEnum.IN_TRANSIT}
                            className="text-nowrap"
                          >
                            In Transit
                          </label>
                        </FormItem>
                      )}
                    {!isTakeout &&
                      status !== OrderStatusEnum.DELIVERED &&
                      isBentoDeliveredHandler &&
                      isSuperAdmin && (
                        <FormItem className="flex items-center space-x-2">
                          <FormControl>
                            <RadioGroupItem
                              id={OrderStatusEnum.DELIVERED}
                              value={OrderStatusEnum.DELIVERED}
                            />
                          </FormControl>
                          <label
                            htmlFor={OrderStatusEnum.DELIVERED}
                            className="text-nowrap"
                          >
                            Delivered
                          </label>
                        </FormItem>
                      )}
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>

      <DialogFooter className="w-full">
        <Button variant="tertiary" asChild>
          <DialogClose disabled={isLoading}>Cancel</DialogClose>
        </Button>
        <Button type="submit" form="change-status-form" disabled={isLoading}>
          {isLoading && <Loading size={2} color="white" className="mr-1" />}
          Save
        </Button>
      </DialogFooter>
    </>
  )
}

import { ModeEditOutline as EditIcon } from '@styled-icons/material/ModeEditOutline'
import { Add as AddIcon } from '@styled-icons/material-outlined'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import menuMakerService from '@/api/menu-maker.service'
import { Box } from '@/components/Box'
import Empty from '@/components/Empty'
import Fab from '@/components/Fab'
import { Modal, ModalContent } from '@/components/Modal'
import Pagination from '@/components/Pagination'
import Skeleton from '@/components/Skeleton'
import TableData from '@/components/TableData'
import TableSearch from '@/components/TableSearch'
import useAuth from '@/hooks/useAuth'
import useModal from '@/hooks/useModal'
import EditModifierModal from '@/modals/EditModifierModal'
import {
  CategoryType,
  ItemType,
  MenusType,
  ModifierType,
} from '@/types/menu-maker.types'

import * as S from './styles'

const PAGE_SIZE = 50

export function Modifiers() {
  const [modifiers, setModifiers] = useState<ModifierType[]>([])
  const [items, setItems] = useState<ItemType[]>([])
  const [categories, setCategories] = useState<CategoryType[]>([])
  const [menus, setMenus] = useState<MenusType[]>([])
  const [filteredModifiers, setFilteredModifiers] = useState<
    ModifierType[] | null
  >([])
  const [editModifier, setEditModifier] = useState<ModifierType | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal()
  const { merchantSelected } = useAuth()

  const modalTitle = `${editModifier ? 'Edit' : 'Create'} Modifier`

  const handleFilterChange = (search: string, modifiers: ModifierType[]) => {
    return modifiers.filter(
      ({ name }) => name && name.toLowerCase().includes(search.toLowerCase()),
    )
  }

  const getModifiers = useCallback(async (id: string) => {
    setIsLoading(true)
    setHasError(false)

    try {
      const [resModifiers, resItems, resCategories, resMenus] =
        await Promise.all([
          menuMakerService.getModifiers(id),
          menuMakerService.getItems(id),
          menuMakerService.getCategories(id),
          menuMakerService.getMenus(id),
        ])

      const { data: dataModifiers } = resModifiers
      const { data: dataItems } = resItems
      const { data: dataCat } = resCategories
      const { data: dataMenus } = resMenus

      const newModifiers = dataModifiers.map((modifier) => {
        return {
          ...modifier,
          options: modifier?.options
            ? modifier.options.map((p) => ({ ...p, price: p.price / 100 }))
            : [],
          optionsLength: modifier.options?.length || 0,
          itemsAssignedLength: modifier.itemsAssigned?.length || 0,
        }
      })

      const newItems = dataItems.map((item) => {
        return {
          ...item,
          price: item.price / 100,
          categoriesName: dataCat
            .filter((c) => item.categories?.includes(c.categoryId!))
            .map((cat) => cat.name),
        }
      })

      if (newModifiers.length === 0) {
        setIsLoading(false)
      }

      setModifiers(newModifiers)
      setItems(newItems)
      setCategories(dataCat)
      setMenus(dataMenus)
    } catch (error) {
      setHasError(true)
      setModifiers([])
      setFilteredModifiers([])
      setIsLoading(false)
    }
  }, [])

  const openEditModifier = useCallback(
    (modifier?: ModifierType) => {
      setEditModifier(modifier || null)
      setModalIsOpen(true)
    },
    [setModalIsOpen],
  )

  const closeEditModifier = () => {
    getModifiers(merchantSelected!.id)
    setModalIsOpen(false)
  }

  const renderOpenEditModifierModal = () => {
    return (
      <Modal open={modalIsOpen} onOpenChange={setModalIsOpen} modal>
        <ModalContent title={modalTitle} onClose={() => setModalIsOpen(false)}>
          <EditModifierModal
            closeEditModifier={closeEditModifier}
            editModifierData={editModifier}
            merchant={merchantSelected!.id}
            items={items}
            categories={categories}
            menus={menus}
            modifiers={modifiers}
          />
        </ModalContent>
      </Modal>
    )
  }

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: (row: ModifierType) => row.name,
        sortable: true,
        width: '250px',
      },
      {
        name: 'Note',
        selector: (row: ModifierType) => row.note,
        sortable: true,
        minWidth: '200px',
      },
      {
        name: 'Modifiers',
        selector: (row: ModifierType) => row.optionsLength,
        sortable: true,
        center: true,
        minWidth: '90px',
        cell: (row: ModifierType) => (
          <div data-tag="allowRowEvents">{row.optionsLength}</div>
        ),
      },
      {
        name: 'Items assigned',
        selector: (row: ModifierType) => row.itemsAssignedLength,
        sortable: true,
        center: true,
        minWidth: '120px',
        cell: (row: ModifierType) => (
          <div data-tag="allowRowEvents">{row.itemsAssignedLength}</div>
        ),
      },
      {
        name: '',
        center: true,
        minWidth: '40px',
        cell: (row: ModifierType) => (
          <S.ButtonEdit
            icon={<EditIcon size={44} />}
            size="small"
            minimal
            onClick={() => openEditModifier(row)}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    if (filteredModifiers && filteredModifiers.length === 0) {
      setFilteredModifiers(null)
    }
  }, [filteredModifiers])

  useEffect(() => {
    if (merchantSelected) {
      getModifiers(merchantSelected.id)
    }
  }, [getModifiers, merchantSelected])

  return (
    <>
      <Helmet title="Modifiers" />
      <S.Content>
        <S.Header>
          <Box
            flex
            alignItems="center"
            JustifyContent="space-between"
            width="100%"
          >
            <h1>Modifiers</h1>

            <TableSearch<ModifierType>
              data={modifiers}
              filterCb={handleFilterChange}
              onFilter={(data) => {
                setFilteredModifiers(data)
                setIsLoading(false)
              }}
              disabled={isLoading || hasError || modifiers.length === 0}
            />
          </Box>
        </S.Header>

        {isLoading ? (
          <>
            <Box
              flex
              alignItems="center"
              JustifyContent="space-between"
              width="100%"
              style={{ marginBottom: '1.75rem' }}
            >
              <Skeleton
                width="2.5rem"
                height="0.875rem"
                count={5}
                radius="4px"
                direction="row"
              />
            </Box>

            <Skeleton height="2.5rem" count={7} radius="4px" margin="2.5rem" />
          </>
        ) : hasError ? (
          <Box
            flex
            JustifyContent="center"
            alignItems="center"
            width="100%"
            height="31.25rem"
          >
            <Empty
              title="Something Happened"
              description="Reload the page and try again"
            />
          </Box>
        ) : filteredModifiers === null ? (
          <Box
            flex
            JustifyContent="center"
            alignItems="center"
            width="100%"
            height="31.25rem"
          >
            <Empty title="There is nothing here, yet" />
          </Box>
        ) : (
          <>
            <TableData<ModifierType>
              data={filteredModifiers || []}
              columns={columns}
              onEdit={openEditModifier}
              pagination
              paginationPerPage={PAGE_SIZE}
              paginationComponent={({
                currentPage,
                onChangePage,
                onChangeRowsPerPage,
                rowsPerPage,
              }) => (
                <Pagination
                  currentPage={Number(currentPage)}
                  totalCount={filteredModifiers.length}
                  pageSize={rowsPerPage}
                  onPageChange={(page) =>
                    onChangePage(Number(page), filteredModifiers.length)
                  }
                  onPageSizeChange={(size) =>
                    onChangeRowsPerPage(size, Number(currentPage))
                  }
                />
              )}
            />
          </>
        )}

        <Fab size="large" onClick={() => openEditModifier()}>
          <AddIcon size={24} />
        </Fab>

        {modalIsOpen && renderOpenEditModifierModal()}
      </S.Content>
    </>
  )
}

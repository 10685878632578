import { useMutation, useQuery } from '@tanstack/react-query'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz'
import { useCallback, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useMediaQuery } from 'react-responsive'
import ReactTooltip from 'react-tooltip'
import { titleCase } from 'title-case'

import superPartnerService from '@/api/super-partner.service'
import { Box } from '@/components/Box'
import DateRangePicker from '@/components/DateRangePicker'
import Empty from '@/components/Empty'
import { Modal, ModalContent } from '@/components/Modal'
import Skeleton from '@/components/Skeleton'
import Switch from '@/components/Switch'
import TableData from '@/components/TableData'
import { AudienceNameEnum } from '@/enums/audience.enum'
import useAuth from '@/hooks/useAuth'
import useModal from '@/hooks/useModal'
import { queryClient } from '@/lib/react-query'
import { CampaignDetailModal } from '@/modals/CampaignDetailModal'
import { AdsCampaignsType } from '@/types/super-partner.types'
import { formatCurrency } from '@/utils/formats/number'

import * as S from './styles'

export function Campaigns() {
  const [campaignDetailData, setCampaignDetailData] =
    useState<AdsCampaignsType | null>(null)
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ])
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  })
  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal()
  const { merchantSelected, timezone } = useAuth()

  const [startDate, endDate] = dateRange

  const {
    data: campaigns,
    isLoading,
    isError: hasError,
  } = useQuery({
    enabled: !!merchantSelected?.id,
    queryKey: ['campaigns', merchantSelected?.id, startDate, endDate],
    retry: 0,
    queryFn: () =>
      superPartnerService.getCampaigns(merchantSelected?.id as string, [
        utcToZonedTime(startDate, timezone),
        utcToZonedTime(endDate, timezone),
      ]),
  })

  function updateDisabledCampaignCache(campaign: AdsCampaignsType) {
    const cached = queryClient.getQueryData<AdsCampaignsType[]>([
      'campaigns',
      merchantSelected?.id,
      startDate,
      endDate,
    ])

    if (cached) {
      const newCampaigns = [...cached]
      const index = newCampaigns.findIndex((item) => item.id === campaign.id)
      newCampaigns[index].disabled = !campaign.disabled

      queryClient.setQueryData<AdsCampaignsType[]>(
        ['campaigns', merchantSelected?.id, startDate, endDate],
        newCampaigns,
      )
    }
  }

  const { mutateAsync: patchAvailability, isPending: isDisabledLoading } =
    useMutation({
      mutationFn: superPartnerService.patchAvailability,
      onMutate({ campaign }) {
        updateDisabledCampaignCache(campaign)
        return { campaign }
      },
      onError(_, __, context) {
        if (context?.campaign) {
          updateDisabledCampaignCache(context.campaign)
        }
      },
    })

  const handleChangeAvailability = useCallback(
    async (campaign: AdsCampaignsType, merchantId: string) => {
      if (!merchantId) return

      try {
        await patchAvailability({ campaign, merchantId })
        // eslint-disable-next-line no-empty
      } finally {
      }
    },
    [patchAvailability],
  )

  const openCampaignDetail = (adsDetail: AdsCampaignsType) => {
    setCampaignDetailData(adsDetail)
    setModalIsOpen(true)
  }

  const closeCampaignDetail = (reload: boolean) => {
    if (reload && merchantSelected?.id) {
      queryClient.refetchQueries({
        queryKey: ['campaigns', merchantSelected?.id, startDate, endDate],
      })
    }

    setModalIsOpen(false)
  }

  const renderOpenCampaignDetailModal = () => {
    return (
      <Modal open={modalIsOpen} onOpenChange={setModalIsOpen} modal>
        <ModalContent title="" onClose={() => setModalIsOpen(false)} noHeader>
          <CampaignDetailModal
            closeCampaignDetail={closeCampaignDetail}
            campaignDetailData={campaignDetailData}
          />
        </ModalContent>
      </Modal>
    )
  }

  const columns = useMemo(
    () => [
      {
        name: 'On/Off',
        selector: (row: AdsCampaignsType) => row.disabled,
        sortable: false,
        center: true,
        width: '80px',
        cell: (row: AdsCampaignsType) => {
          const voucherQuantity = row.voucherQuantity - row.usage
          return (
            <>
              <Switch
                checked={!row.disabled}
                onChange={() => {
                  const merchantId = merchantSelected?.id || ''
                  handleChangeAvailability(row, merchantId)
                }}
                disabled={voucherQuantity === 0}
                data-tip="The campaign cannot be activated because there are no vouchers left."
                data-tip-disable={voucherQuantity !== 0}
              />
              <ReactTooltip
                effect="solid"
                className="tooltip"
                backgroundColor="#433d3d"
                borderColor="#433d3d"
              />
            </>
          )
        },
      },
      {
        name: 'Campaign Name',
        selector: (row: AdsCampaignsType) => row.name,
        sortable: true,
        center: true,
        minWidth: '270px',
        cell: (row: AdsCampaignsType) => (
          <Box
            flex
            flexDirection="column"
            alignItems="center"
            style={{ textAlign: 'center' }}
          >
            <strong data-tag="allowRowEvents">{row.name}</strong>
            <span data-tag="allowRowEvents">{row.description}</span>
          </Box>
        ),
      },
      {
        name: 'Created',
        selector: (row: AdsCampaignsType) => row.createdAt,
        sortable: false,
        center: true,
        width: '140px',
        cell: (row: AdsCampaignsType) => {
          return (
            <span data-tag="allowRowEvents">
              {format(new Date(row.createdAt), 'MMM d, yyyy')}
            </span>
          )
        },
      },
      {
        name: 'Period',
        selector: (row: AdsCampaignsType) => row.period,
        sortable: true,
        center: true,
        minWidth: '130px',
        cell: (row: AdsCampaignsType) => (
          <span data-tag="allowRowEvents">
            {formatInTimeZone(
              new Date(row.period.startAt),
              row.timezone,
              'MMM d, yyyy',
            )}{' '}
            to{' '}
            {formatInTimeZone(
              new Date(row.period.endAt),
              row.timezone,
              'MMM d, yyyy',
            )}
          </span>
        ),
      },
      {
        name: 'Nº of Vouchers',
        selector: (row: AdsCampaignsType) => row.voucherQuantity,
        sortable: false,
        center: true,
        width: '120px',
        cell: (row: AdsCampaignsType) => {
          return (
            <span data-tag="allowRowEvents">{row.voucherQuantity || '-'}</span>
          )
        },
      },
      {
        name: 'Audience',
        selector: (row: AdsCampaignsType) => row.audiences,
        sortable: false,
        center: true,
        width: '240px',
        cell: (row: AdsCampaignsType) => {
          return (
            <span data-tag="allowRowEvents">
              {(row.audiences?.length > 0 &&
                row.audiences
                  .map((name) => {
                    return (
                      AudienceNameEnum[name as keyof typeof AudienceNameEnum] ||
                      titleCase(name.toLowerCase())
                    )
                  })
                  .join(', ')) ||
                '-'}
            </span>
          )
        },
      },
      {
        name: 'Nº of Orders',
        selector: (row: AdsCampaignsType) => row.orders,
        sortable: false,
        center: true,
        width: '120px',
        cell: (row: AdsCampaignsType) => {
          return (
            <span data-tag="allowRowEvents">{row?.orders?.length || '-'}</span>
          )
        },
      },
      {
        name: 'Sales',
        selector: (row: AdsCampaignsType) => row.totalSales,
        sortable: false,
        center: true,
        width: '120px',
        cell: (row: AdsCampaignsType) => {
          const totalSales = Number(
            String(row?.totalSales || 0).replaceAll('.', ''),
          )
          return (
            <span data-tag="allowRowEvents">
              ${formatCurrency(totalSales / 100) ?? '-'}
            </span>
          )
        },
      },
      {
        name: 'Remaining Vouchers',
        selector: (row: AdsCampaignsType) => row.usage,
        sortable: false,
        center: true,
        width: '120px',
        cell: (row: AdsCampaignsType) => {
          return (
            <span data-tag="allowRowEvents">
              {row.voucherQuantity - row.usage || 0}
            </span>
          )
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleChangeAvailability, isDisabledLoading, merchantSelected?.id],
  )

  return (
    <>
      <Helmet title="Campaigns" />
      <S.Content>
        <S.Header>
          <h1>Campaigns</h1>
        </S.Header>

        <S.Actions>
          <S.PeriodSelected>
            <strong>Created:</strong>{' '}
            {startDate && format(startDate, 'MMM d, yyyy')} to{' '}
            {endDate && format(endDate, 'MMM d, yyyy')}
          </S.PeriodSelected>

          <S.DatepickerWrapper>
            <DateRangePicker
              label="Choose period"
              labelFor="period"
              start={startDate}
              end={endDate}
              onSelectDate={(update) => {
                if (update && Array.isArray(update)) {
                  setDateRange(update)
                }
              }}
              position={isMobile ? 'center' : 'right'}
              loading={isLoading}
            />
          </S.DatepickerWrapper>
        </S.Actions>

        <S.ContentTable>
          {isLoading ? (
            <>
              <Box
                flex
                alignItems="center"
                JustifyContent="space-between"
                width="100%"
                style={{ marginBottom: '1.75rem' }}
              >
                <Skeleton
                  width="2.5rem"
                  height="0.875rem"
                  count={5}
                  radius="4px"
                  direction="row"
                />
              </Box>

              <Skeleton
                height="2.5rem"
                count={7}
                radius="4px"
                margin="2.5rem"
              />
            </>
          ) : hasError ? (
            <Box
              flex
              JustifyContent="center"
              alignItems="center"
              width="100%"
              height="31.25rem"
            >
              <Empty
                title="Something Happened"
                description="Reload the page and try again"
              />
            </Box>
          ) : campaigns?.length === 0 ? (
            <Box
              flex
              JustifyContent="center"
              alignItems="center"
              width="100%"
              height="31.25rem"
            >
              <Empty
                title="There is nothing here, yet"
                description="Create a campaign to start"
              />
            </Box>
          ) : (
            <TableData<AdsCampaignsType>
              data={campaigns || []}
              columns={columns}
              onEdit={openCampaignDetail}
            />
          )}
        </S.ContentTable>

        {modalIsOpen && renderOpenCampaignDetailModal()}
      </S.Content>
    </>
  )
}

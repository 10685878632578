import styled, { css } from 'styled-components'

import { CloseButton as ModalCloseButton } from '@/components/Modal/styles'

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacings.xxsmall} ${theme.spacings.small};
    margin-bottom: ${theme.spacings.small};
  `}
`

export const CloseButton = styled(ModalCloseButton)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    width: 2.625rem;
    height: 2.625rem;
    text-align: center;
    line-height: 0.8;
    right: 1.25rem;
    position: fixed;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
    z-index: ${theme.layers.alwaysOnTop};
  `}
`

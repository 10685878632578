import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { Box } from '@/components/Box'

export const ContentInputs = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin: ${theme.spacings.small} 0;

    ${media.lessThan('medium')`
      flex-direction: column;

      ${FormControl} {
        width: auto;
        margin: 0;

        &:first-child {
          margin-bottom: ${theme.spacings.xxsmall};
        }
      }
    `}
  `}
`

export const FormControl = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin: 0 ${theme.spacings.small};
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacings.medium} ${theme.spacings.small};
    margin-bottom: ${theme.spacings.small};
  `}
`

export const MenuForm = styled.div`
  display: flex;

  ${media.lessThan('medium')`
    flex-direction: column;

    ${Box} {
      width: 100%;
    }
  `}
`

export const LabelForm = styled.label`
  ${({ theme }) => css`
    font-size: 1.125rem;
    font-weight: ${theme.font.semiBold};
    margin-bottom: ${theme.spacings.xsmall};
  `}
`

export const Menus = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const ErrorMenus = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.red};
    font-size: ${theme.font.sizes.xsmall};
  `}
`

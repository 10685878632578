import { AxiosResponse } from 'axios'

import { DateRange } from '@/components/DateRangePicker'
import { api } from '@/lib/axios'
import { MultipleMerchantsOrdersType, OrderType } from '@/types/orders.types'
import type { VoucherListType } from '@/types/super-partner.types'

const getOrders = (
  { start, end }: DateRange,
  uuid: string,
): Promise<AxiosResponse<OrderType[]>> => {
  return api.post<OrderType[]>(`/merchants/${uuid}/orders`, {
    start,
    end,
  })
}

const getMultipleMerchantOrders = (
  { start, end }: DateRange,
  ids: string[],
): Promise<AxiosResponse<MultipleMerchantsOrdersType[]>> | void => {
  if (ids.length > 0)
    return api.get<MultipleMerchantsOrdersType[]>(
      `/multiple-merchants/orders?merchants=${ids.join(
        ',',
      )}&start=${start}&end=${end}`,
    )
}

const updateOrderStatus = ({
  status,
  orderId,
}: {
  status: string
  orderId?: string
}): Promise<AxiosResponse<OrderType>> => {
  if (!orderId) return Promise.reject(new Error('Order id is required'))
  return api.post<OrderType>(`/orders/${orderId}`, {
    action: status,
  })
}

const saveAddCharge = (
  data: { amount: number; comment: string },
  orderId?: string,
): Promise<AxiosResponse<OrderType>> => {
  if (!orderId) return Promise.reject(new Error('Order id is required'))
  return api.post<OrderType>(`/orders/${orderId}/extra-charge`, data)
}

const saveCreateMagicVoucher = (
  data: { amount: number; reason: string },
  orderId?: string,
): Promise<AxiosResponse<OrderType>> => {
  if (!orderId) return Promise.reject(new Error('Order id is required'))
  return api.post<OrderType>(`/orders/${orderId}/voucher`, data)
}

const getOrdersByShortId = (
  shortId: string,
): Promise<AxiosResponse<OrderType[]>> => {
  if (!shortId) return Promise.reject(new Error('Short id is required'))
  return api.get<OrderType[]>(`/orders/short/${shortId}`)
}

const getCompensationVouchers = async (
  orderId: string,
): Promise<VoucherListType> => {
  const response = await api.get<VoucherListType>(
    `/orders/${orderId}/compensation`,
  )
  return response.data
}

export default {
  getOrders,
  getMultipleMerchantOrders,
  updateOrderStatus,
  saveAddCharge,
  saveCreateMagicVoucher,
  getOrdersByShortId,
  getCompensationVouchers,
}

import styled, { css } from 'styled-components'

type BoxProps = {
  minWidth?: string
  maxWidth?: string
  width?: string
  height?: string
  flex?: boolean
  flexWrap?: 'wrap' | 'nowrap'
  flexDirection?: 'row' | 'column'
  JustifyContent?: 'start' | 'center' | 'space-between' | 'end'
  alignItems?: 'start' | 'center' | 'end' | 'flex-end' | 'baseline'
  flexGrow?: number
  gap?: string
  style?: React.CSSProperties
}

export const Box = styled.div<BoxProps>`
  ${({
    minWidth,
    maxWidth,
    width,
    height,
    flex,
    flexWrap,
    flexDirection,
    JustifyContent,
    alignItems,
    flexGrow,
    gap,
  }) => css`
    ${!!minWidth &&
    css`
      min-width: ${minWidth};
    `}

    ${!!maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

    ${!!width &&
    css`
      width: ${width};
    `}

    ${!!height &&
    css`
      height: ${height};
    `}

    ${!!flex &&
    css`
      display: flex;
    `}

    ${!!flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `}

    ${!!flexGrow &&
    css`
      flex: ${flexGrow};
    `}

    ${!!flexDirection &&
    css`
      flex-direction: ${flexDirection};
    `}

    ${!!JustifyContent &&
    css`
      justify-content: ${JustifyContent};
    `}

    ${!!alignItems &&
    css`
      align-items: ${alignItems};
    `}

    ${!!gap &&
    css`
      gap: ${gap};
    `}
  `}
`

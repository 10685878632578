import { useCallback, useEffect, useState } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'

import {
  CategoryType,
  ItemType,
  MenusType,
  ModifierType,
} from '@/types/menu-maker.types'
import { OverviewCategoryType } from '@/types/overview.types'
import { reorder } from '@/utils/formats/list'

import OverviewList from './OverviewList'
import * as S from './styles'

export type OverviewListTableProps = {
  data: OverviewCategoryType[]
  categories: CategoryType[]
  menus: MenusType[]
  modifiers: ModifierType[]
  items: ItemType[]
  expanded: boolean
  onReorder?: (items: OverviewCategoryType[]) => void
  refreshMenus?: () => void
}

const OverviewListTable = ({
  data,
  categories,
  menus,
  modifiers,
  items,
  expanded,
  onReorder,
  refreshMenus,
}: OverviewListTableProps) => {
  const [categoryData, setCategoryData] = useState<OverviewCategoryType[]>([])

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return

      const isCategory = result.type === 'categories'
      const [type, categoryId, itemId] = result.type.split('-')
      const isItem = !isCategory && type === 'items'
      const isModifier = !isCategory && type === 'modifiers'

      let itemsReordered: OverviewCategoryType[] = []

      if (isCategory) {
        itemsReordered = reorder<OverviewCategoryType>(
          categoryData,
          result.source.index,
          result.destination.index,
        ).map((item, index) => ({ ...item, position: index }))
      }

      if (isItem) {
        const newItems = [...categoryData]
        const category = newItems.find((item) => item.categoryId === categoryId)
        const categoryIndex = newItems.findIndex(
          (item) => item.categoryId === categoryId,
        )

        if (!category || categoryIndex < 0) return

        const categoryItemsReordered = reorder<ItemType>(
          category.items as ItemType[],
          result.source.index,
          result.destination.index,
        )

        newItems[categoryIndex].items = categoryItemsReordered
        itemsReordered = newItems
      }

      if (isModifier) {
        const newItems = [...categoryData]
        const category = newItems.find((item) => item.categoryId === categoryId)
        const categoryIndex = newItems.findIndex(
          (item) => item.categoryId === categoryId,
        )

        if (!category || categoryIndex < 0) return

        const item = (category.items as ItemType[]).find(
          (item) => item.itemId === itemId,
        )
        const itemIndex = (category.items as ItemType[]).findIndex(
          (item) => item.itemId === itemId,
        )

        if (!item || itemIndex < 0) return

        const itemsModifiersReordered = reorder<ModifierType>(
          item.modifiers,
          result.source.index,
          result.destination.index,
        )

        item.modifiers = itemsModifiersReordered
        itemsReordered = newItems
      }

      setCategoryData(itemsReordered)
      onReorder && onReorder(itemsReordered)
    },
    [categoryData, onReorder],
  )

  useEffect(() => {
    setCategoryData(data)
  }, [data])

  return (
    <S.Wrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        {categoryData && (
          <OverviewList
            data={categoryData}
            categories={categories}
            items={items}
            menus={menus}
            modifiers={modifiers}
            refreshMenus={refreshMenus}
            expanded={expanded}
          />
        )}
      </DragDropContext>
    </S.Wrapper>
  )
}

export default OverviewListTable

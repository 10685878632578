import { ArrowDownward } from '@styled-icons/material-rounded/ArrowDownward'
import DataTable, {
  TableProps as DataTableProps,
  TableStyles,
} from 'react-data-table-component'
import { DefaultTheme, useTheme } from 'styled-components'

export type TableProps<T> = DataTableProps<T>

export enum TypeEnum {
  DELIVERY = 'delivery',
  TAKEOUT = 'takeout',
}

export const getColorModifier = (key: string, theme: DefaultTheme): string => {
  const colors: { [key: string]: string } = {
    created: '#fba240',
    in_progress: '#43cb8f',
    ready: '#ff5ca2',
    collected: '#fae925',
    delivered: theme.colors.black,
    rejected: '#ff4c4c',
    cancelled: '#ff4c4c',
    picked: theme.colors.black,
    in_transit: '#3798ff',
    completed: theme.colors.black,
  }

  return colors[key]
}

export const colorModifiers: { [key: string]: string } = {
  created: '#fba240',
  in_progress: '#43cb8f',
  ready: '#ff5ca2',
  collected: '#fae925',
  delivered: '#433d3d',
  rejected: '#ff4c4c',
  cancelled: '#ff4c4c',
  picked: '#433d3d',
  in_transit: '#3798ff',
  completed: '#433d3d',
}

export const statusModifiers: { [key: string]: string } = {
  created: 'New',
  in_progress: 'Accepted',
  ready: 'Ready',
  collected: 'Collected',
  delivered: 'Delivered',
  rejected: 'Rejected',
  cancelled: 'Canceled',
  picked: 'Picked',
  in_transit: 'In-Transit',
  completed: 'Completed',
}

const sortIcon = <ArrowDownward />

const Table = <T,>({
  columns,
  data,
  onRowClicked,
  highlightOnHover = false,
  pointerOnHover = false,
  ...props
}: TableProps<T>) => {
  const theme = useTheme()

  const tableStyles: TableStyles = {
    table: {
      style: {
        color: theme.colors.secondary,
        backgroundColor: theme.colors.mainBg,
      },
    },
    headRow: {
      style: {
        minHeight: '56px',
        borderBottomWidth: '0',
        backgroundColor: theme.colors.secondaryBg,
        color: theme.colors.body,
      },
      denseStyle: {
        minHeight: '32px',
      },
    },
    headCells: {
      style: {
        fontSize: theme.font.sizes.small,
        fontWeight: theme.font.bold,
        fontFamily: theme.font.nunito,
        color: theme.colors.body,
        padding: 0,

        '> div div': {
          display: 'flex',
          flexWrap: 'wrap',
          whiteSpace: 'normal',
          textAlign: 'center',
        },
        '> span': {
          display: 'none',
        },
        activeSortStyle: {
          color: theme.colors.secondary,
          '&:focus': {
            outline: 'none',
          },
        },
      },
    },
    rows: {
      style: {
        fontSize: theme.font.sizes.medium,
        fontFamily: theme.font.quicksand,
        color: theme.colors.body,
        backgroundColor: theme.colors.secondaryBg,

        '> div': {
          paddingTop: theme.spacings.xsmall,
          paddingBottom: theme.spacings.xsmall,
        },

        '&:not(:last-of-type)': {
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
          borderBottomColor: theme.colors.borderColor,
        },
      },
      highlightOnHoverStyle: {
        color: theme.colors.body,

        '&:active': {
          backgroundColor: theme.colors.lightGray,
        },
      },
      denseStyle: {
        minHeight: '32px',
      },
    },
    cells: {
      style: {
        padding: 0,
      },
    },
  }

  return (
    <DataTable<T>
      columns={columns}
      data={data}
      sortIcon={sortIcon}
      customStyles={tableStyles}
      onRowClicked={onRowClicked}
      data-tag="allowRowEvents"
      highlightOnHover={highlightOnHover}
      pointerOnHover={pointerOnHover}
      {...props}
    />
  )
}

export default Table

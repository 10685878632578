import { KeyboardArrowLeft } from '@styled-icons/material-rounded/KeyboardArrowLeft'
import { KeyboardArrowRight } from '@styled-icons/material-rounded/KeyboardArrowRight'
import { differenceInDays } from 'date-fns'

import Slick, { SlickSettings } from '@/components/Slick'
import TakeoutDeliveryChart from '@/components/TakeoutDeliveryChart'
import TopSalesChart from '@/components/TopSalesChart'
import TopViewsChart from '@/components/TopViewsChart'
import TopViewsPieChart from '@/components/TopViewsPieChart'
import {
  DateCountType,
  SoldItemsType,
  TakeoutDeliveryType,
  VisitedItemsType,
} from '@/types/dashboard.types'

import * as S from './styles'

const settings: SlickSettings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  nextArrow: <KeyboardArrowRight />,
  prevArrow: <KeyboardArrowLeft />,
}

export type SlideChartsProps = {
  views: DateCountType[]
  soldItems: SoldItemsType[]
  takeoutDelivered: TakeoutDeliveryType[]
  visitedItens: VisitedItemsType[]
  initialSlide: number
  dateRange: [Date, Date]
  afterChange: (index: number) => void
}

const SlideCharts = ({
  views,
  soldItems,
  takeoutDelivered,
  visitedItens,
  initialSlide,
  dateRange,
  afterChange,
}: SlideChartsProps) => (
  <S.Wrapper>
    <Slick settings={{ ...settings, initialSlide, afterChange }}>
      <TopViewsPieChart data={visitedItens} />
      <TakeoutDeliveryChart
        data={takeoutDelivered}
        moreThanFifteenDays={differenceInDays(dateRange[1], dateRange[0]) > 15}
      />
      <TopSalesChart data={soldItems} />
      <TopViewsChart data={views} />
    </Slick>
  </S.Wrapper>
)

export default SlideCharts

import { ReactNode } from 'react'

import * as S from './styles'

export type TagProps = {
  children: ReactNode
  active?: boolean
  color?: string
  onClick?: () => void
  style?: React.CSSProperties
}

const Tag = ({ active = false, color, children, ...props }: TagProps) => {
  return (
    <S.Tag {...props} active={active} color={color}>
      {children}
    </S.Tag>
  )
}

export default Tag

import 'react-datepicker/dist/react-datepicker.css'

import { add, differenceInDays, format } from 'date-fns'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import { ImArrowRight2 } from 'react-icons/im'

import { Box } from '@/components/Box'
import SelectButton from '@/components/SelectButton'
import {
  FieldContent,
  FieldError,
  FieldHeader,
  FieldHeaderTitle,
  FieldWrapper,
} from '@/modals/CreatingNewCampaignModal/styles'
import { VoucherType } from '@/types/super-partner.types'

import * as S from './styles'

export type DatesRangeProps = {
  vouchers?: VoucherType
  error?: string
  value?: { startAt: Date; endAt: Date }
  onChange: (value: { startAt: Date; endAt: Date }) => void
}

export const DatesRangeField = ({
  error,
  value,
  onChange,
}: DatesRangeProps) => {
  const [rangeSelected, setRangeSelected] = useState(1)
  const { t } = useTranslation()
  const rangeDataMapper: { [key: number]: [Date, Date] } = {
    0: [new Date(), add(new Date(), { days: 1 })],
    1: [new Date(), add(new Date(), { days: 6 })],
    2: [new Date(), add(new Date(), { days: 13 })],
    3: [new Date(), add(new Date(), { days: 30 })],
    4: [new Date(), add(new Date(), { days: 89 })],
  }

  const totalDays =
    (value?.startAt &&
      value?.endAt &&
      differenceInDays(value?.endAt, value?.startAt) + 1) ||
    0

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSelectNewRange = (e: any, id: number) => {
    e.preventDefault()
    setRangeSelected(id)
    onChange({ startAt: rangeDataMapper[id][0], endAt: rangeDataMapper[id][1] })
  }

  return (
    <FieldWrapper>
      <FieldHeader>
        <FieldHeaderTitle>Period</FieldHeaderTitle>
      </FieldHeader>

      <FieldContent>
        <S.Dates>
          <span>{value?.startAt && format(value.startAt, 'MMM d, yyyy')}</span>
          <ImArrowRight2 />
          <span>{value?.endAt && format(value.endAt, 'MMM d, yyyy')}</span>
        </S.Dates>

        <S.TotalDaysPicker>
          <S.DatepickerWrapper>
            <DatePicker
              startDate={value?.startAt}
              endDate={value?.endAt}
              minDate={new Date()}
              onChange={(update) => {
                setRangeSelected(0)
                if (update && Array.isArray(update)) {
                  const [startDate, endDate] = update
                  onChange({
                    startAt: startDate as Date,
                    endAt: endDate as Date,
                  })
                }
              }}
              formatWeekDay={(nameOfDay) => nameOfDay.slice(0, 3)}
              id="date-range-campaign"
              className="react-datepicker"
              selectsRange
              inline
            />

            {error && <FieldError>{error}</FieldError>}
          </S.DatepickerWrapper>

          <S.TotalDays>
            <S.SelectsContainer>
              <SelectButton
                size="small"
                selected={rangeSelected === 1}
                onClick={(e) => {
                  onSelectNewRange(e, 1)
                }}
              >
                1 week
              </SelectButton>
              <SelectButton
                size="small"
                selected={rangeSelected === 2}
                onClick={(e) => {
                  onSelectNewRange(e, 2)
                }}
              >
                2 weeks
              </SelectButton>
              <SelectButton
                size="small"
                selected={rangeSelected === 3}
                onClick={(e) => {
                  onSelectNewRange(e, 3)
                }}
              >
                1 month
              </SelectButton>
              <SelectButton
                size="small"
                selected={rangeSelected === 4}
                onClick={(e) => {
                  onSelectNewRange(e, 4)
                }}
              >
                3 months
              </SelectButton>
              <SelectButton
                size="small"
                selected={rangeSelected === 0}
                onClick={(e) => {
                  onSelectNewRange(e, 0)
                }}
              >
                Custom range
              </SelectButton>
            </S.SelectsContainer>

            <h4>Total</h4>
            <Box flex alignItems="center" JustifyContent="space-between">
              <p>
                {totalDays}{' '}
                {t(
                  'modals.creating-new-campaign.datesRangeField.quantityDays',
                  {
                    count: totalDays,
                  },
                )}
              </p>
            </Box>
          </S.TotalDays>
        </S.TotalDaysPicker>
      </FieldContent>
    </FieldWrapper>
  )
}

import { getContrast } from 'polished'
import styled, { css } from 'styled-components'

import { Animation as LoadingAnimation } from '../../components/Loading/styles'
import { AvatarProps } from '.'

type WrapperProps = Pick<AvatarProps, 'size' | 'color'> & { loading?: string }

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, size, color, loading = '' }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${size}rem;
    height: ${size}rem;
    border-radius: 99999px;
    background-color: ${color || theme.colors.lightGray};
    color: ${theme.colors.secondary};
    overflow: hidden;

    ${loading === 'true' &&
    css`
      ${getContrast(color || theme.colors.lightGray, theme.colors.primary) <
        1 &&
      css`
        ${LoadingAnimation} {
          border-top: 0.188rem solid rgba(255, 255, 255, 0.2);
          border-right: 0.188rem solid rgba(255, 255, 255, 0.2);
          border-bottom: 0.188rem solid rgba(255, 255, 255, 0.2);
          border-left: 0.188rem solid
            ${theme.dark ? theme.colors.black : theme.colors.white};
        }
      `};
    `}
  `}
`

export const Text = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-family: ${theme.font.nunito};
    font-size: ${theme.font.sizes.large};
    font-weight: ${theme.font.bold};
    line-height: 1;
  `}
`

export const AvatarImage = styled.img`
  object-fit: scale-down;
`

import { useTheme } from 'styled-components'

import editIcon from '@/assets/img/icon-edit.svg'
import cardIcon from '@/assets/img/icons-card.svg'
import cardIconLight from '@/assets/img/icons-card-light.svg'
import { Box } from '@/components/Box'
import { Modal, ModalContent } from '@/components/Modal'
import useModal from '@/hooks/useModal'
import {
  FieldContent,
  FieldError,
  FieldHeader,
  FieldHeaderActions,
  FieldHeaderTitle,
  FieldWrapper,
} from '@/modals/CreatingNewCampaignModal/styles'
import PaymentModal from '@/modals/PaymentModal'
import { CardType } from '@/types/payment.types'
import { formatCurrencyWithCode } from '@/utils/formats/number'

import { ButtonIcon } from '../VoucherSteps/CheckoutForm/styles'
import * as S from './styles'

export type PaymentFieldProps = {
  value?: CardType
  error?: string
  loading?: boolean
  disabled?: boolean
  onChange: (value?: CardType) => void
}

export const PaymentField = ({
  error,
  onChange,
  loading = false,
  disabled = false,
  value,
}: PaymentFieldProps) => {
  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal()
  const theme = useTheme()

  const handleClosePayment = (card: CardType | null) => {
    if (card) {
      onChange({
        cardNumberEnd: card.cardNumberEnd,
        defaultMethod: card.defaultMethod,
        gateway: card.gateway,
        vaultId: card.vaultId,
        balance: card.balance,
      })
    }
    setModalIsOpen(false)
  }

  const renderPaymentModal = () => (
    <Modal open={modalIsOpen} onOpenChange={setModalIsOpen} modal>
      <ModalContent
        title="Payment Method"
        onClose={() => setModalIsOpen(false)}
        style={{ minWidth: '25rem' }}
        width="auto"
        simpleHeader
      >
        <PaymentModal closePayment={handleClosePayment} />
      </ModalContent>
    </Modal>
  )

  const styleOpacity = disabled ? { opacity: '0.5', cursor: 'not-allowed' } : {}

  return (
    <FieldWrapper style={styleOpacity}>
      <FieldHeader checkout={1}>
        <FieldHeaderTitle>Payment</FieldHeaderTitle>

        <FieldHeaderActions
          onClick={() => !loading && !disabled && setModalIsOpen(true)}
          style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        >
          {!value && (
            <S.CardName>
              <span>Add payment</span>
            </S.CardName>
          )}
          <ButtonIcon minimal disabled={loading || disabled}>
            <img src={editIcon} width={14} height={14} alt="Edit icon" />
          </ButtonIcon>
        </FieldHeaderActions>
      </FieldHeader>

      <FieldContent style={{ marginTop: value ? '1.125rem' : 0 }}>
        {value && value?.gateway === 'balance' && (
          <Box flex alignItems="center" JustifyContent="space-between">
            <Box flex alignItems="start" gap="0.5rem" flexDirection="column">
              <Box flex gap="0.5rem" flexDirection="row">
                {theme.dark ? (
                  <img
                    src={cardIconLight}
                    width={18}
                    height={14}
                    alt="Card icon light"
                  />
                ) : (
                  <img src={cardIcon} width={18} height={14} alt="Card icon" />
                )}{' '}
                <S.SuperCreditName>Super Credit</S.SuperCreditName>
              </Box>
              <S.SuperCreditDescription>
                Deduction will be added on your next pay cycle
              </S.SuperCreditDescription>
            </Box>

            <S.SuperCreditBalance>
              <div>Credit available</div>
              <S.SuperCreditValue>
                {formatCurrencyWithCode((value.balance || 0) / 100)}
              </S.SuperCreditValue>
            </S.SuperCreditBalance>
          </Box>
        )}
        {value?.gateway !== 'balance' && value && (
          <Box flex alignItems="center" JustifyContent="space-between">
            <Box flex alignItems="center" gap="0.8rem">
              {theme.dark ? (
                <img
                  src={cardIconLight}
                  width={18}
                  height={14}
                  alt="Card icon light"
                />
              ) : (
                <img src={cardIcon} width={18} height={14} alt="Card icon" />
              )}{' '}
              <S.CardName>
                {value.defaultMethod && <span>main card</span>}
              </S.CardName>
            </Box>

            <S.CardNumber>••• {value.cardNumberEnd}</S.CardNumber>
          </Box>
        )}
        {error && <FieldError>{error}</FieldError>}
      </FieldContent>

      {modalIsOpen && !disabled && renderPaymentModal()}
    </FieldWrapper>
  )
}

import { darken, lighten } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Dates = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 0.625rem;
    margin: ${theme.spacings.small} 0;

    span {
      font-weight: ${theme.font.semiBold};
      color: ${theme.colors.gray};
    }

    svg {
      fill: ${theme.colors.primary};
    }
  `}
`

export const TotalDaysPicker = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spacings.small};

    @media (max-width: 540px) {
      flex-direction: column;
    }
  `}
`

export const DatepickerWrapper = styled.div`
  ${({ theme }) => css`
    width: 317px;

    ${media.lessThan('small')`
      width: 236px;
    `}

    .react-datepicker {
      font-family: ${theme.font.quicksand};
      border: 1px solid ${theme.colors.supportSuperLight};
      color: ${theme.colors.black};
      font-size: ${theme.font.sizes.xsmall};
      background-color: ${theme.colors.white};
      border-radius: ${theme.border.radius.medium};

      .react-datepicker__navigation {
        &--previous {
          left: ${theme.spacings.small};
        }
        &--next {
          right: ${theme.spacings.small};
        }

        .react-datepicker__navigation-icon {
          width: 1rem;

          &::before {
            border-color: ${theme.colors.primary};
            border-width: 2px 2px 0 0;
            top: 14px;
          }
        }

        &:hover *::before {
          border-color: ${darken(0.1, theme.colors.primary)};
        }
      }

      .react-datepicker__month-container {
        .react-datepicker__header {
          background-color: ${theme.colors.white};
          color: ${theme.colors.black};
          border-bottom: 0;
          border-top-left-radius: ${theme.border.radius.medium};
          border-top-right-radius: ${theme.border.radius.medium};
          padding: 0;

          .react-datepicker__current-month {
            font-size: ${theme.font.sizes.medium};
            font-family: ${theme.font.nunito};
            font-weight: ${theme.font.semiBold};
            color: ${theme.colors.supportDarker};
            padding: 1rem 0;
          }

          .react-datepicker__day-names {
            padding: 0.5rem ${theme.spacings.large};
            background-color: ${theme.colors.white};
            border-top: 1px solid ${theme.colors.supportSuperLight};

            .react-datepicker__day-name {
              padding: 0 0.406rem;
              font-size: 0.625rem;
              width: auto;
              color: ${theme.colors.supportDarker};
              text-transform: uppercase;
            }
          }
        }

        .react-datepicker__month {
          margin: 0.313rem;

          .react-datepicker__week {
            .react-datepicker__day {
              width: ${theme.spacings.large};
              line-height: ${theme.spacings.large};
              font-size: ${theme.font.sizes.small};
              border-radius: 99999px;
              color: ${theme.dark
                ? theme.colors.superLight
                : theme.colors.supportGray};

              &:hover {
                background-color: ${theme.colors.mainBg};
              }

              &.react-datepicker__day--disabled {
                color: ${theme.dark
                  ? lighten(0.155, theme.colors.secondary)
                  : theme.colors.supportSuperLight};
                cursor: not-allowed;

                &:hover {
                  background-color: transparent;
                }
              }

              &.react-datepicker__day--today {
                background-color: ${theme.colors.secondary};
                color: ${theme.dark
                  ? theme.colors.superLight
                  : theme.colors.white};
              }

              &.react-datepicker__day--keyboard-selected {
                background-color: rgba(255, 76, 76, 0.1);
                color: ${theme.colors.black};

                &:focus-visible {
                  outline: none;
                }

                &.react-datepicker__day--in-range {
                  background-color: rgba(255, 76, 76, 0.1);

                  &:hover {
                    background-color: ${theme.dark
                      ? darken(0.555, theme.colors.primary)
                      : lighten(0.255, theme.colors.primary)};
                  }
                }
              }

              &.react-datepicker__day--in-range {
                background-color: rgba(255, 76, 76, 0.1);
                transition: all ${theme.transition.fast};
                color: ${theme.dark
                  ? theme.colors.superLight
                  : theme.colors.primary};

                &:hover {
                  background-color: ${theme.dark
                    ? darken(0.555, theme.colors.primary)
                    : lighten(0.255, theme.colors.primary)};
                }
              }

              &.react-datepicker__day--range-start,
              &.react-datepicker__day--range-end {
                background-color: ${theme.colors.primary} !important;
                transition: all ${theme.transition.fast};
                color: ${theme.dark
                  ? theme.colors.superLight
                  : theme.colors.white} !important;

                &:hover {
                  background-color: ${darken(
                    0.1,
                    theme.colors.primary,
                  )} !important;
                }
              }

              &.react-datepicker__day--in-selecting-range {
                background-color: ${theme.colors.mainBg};
                color: ${theme.dark
                  ? theme.colors.superLight
                  : theme.colors.secondary};
              }

              &.react-datepicker__day--selecting-range-start {
                background-color: ${theme.colors.mainBg};
                color: ${theme.colors.black};
              }
            }
          }
        }
      }
    }
  `}
`

export const TotalDays = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: ${theme.spacings.xxsmall};
    padding-bottom: ${theme.spacings.small};

    width: 100%;

    h4 {
      color: ${theme.colors.black};
      font-family: ${theme.font.nunito};
      font-size: ${theme.font.sizes.small};
      font-weight: ${theme.font.bold};
    }

    h5 {
      color: ${theme.colors.primary};
      font-size: 1.125rem;
      font-weight: ${theme.font.bold};
    }
  `}
`

export const SelectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

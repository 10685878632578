import { Fragment, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import inputOrderIcon from '@/assets/img/icons-input-order.svg'
import { Box } from '@/components/Box'
import List from '@/components/List'
import { Modal, ModalContent } from '@/components/Modal'
import useAuth from '@/hooks/useAuth'
import useModal from '@/hooks/useModal'
import { api } from '@/lib/axios'
import ConfirmModal from '@/modals/ConfirmModal'
import CreatingNewCampaignModal from '@/modals/CreatingNewCampaignModal'
import { Success } from '@/modals/CreatingNewCampaignModal/components/Success'
import PositionCampaignModal from '@/modals/PositionCampaignModal'
import { CardType } from '@/types/payment.types'
import { Campaign, CampaignList } from '@/types/super-partner.types'

import { campaignList } from './campaign-list'
import * as S from './styles'

export function NewCampaign() {
  const [campaignItem, setCampaignItem] = useState<CampaignList | null>(null)
  const [campaign, setCampaign] = useState<Campaign | null>(null)
  const [paymentMethod, setPaymentMethod] = useState<CardType | null>(null)
  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal()
  const { open: successCampaignIsOpen, toggle: setSuccessCampaignIsOpen } =
    useModal()
  const { open: positionCampaignIsOpen, toggle: setPositionCampaignIsOpen } =
    useModal()
  const { open: openConfirmModal, toggle: setOpenConfirmModal } = useModal()
  const { merchantSelected } = useAuth()

  function openCampaignModal(campaign: Campaign, item: CampaignList) {
    setCampaignItem({ ...item, name: campaign.title })
    setCampaign(campaign)
    if (campaign.type === 'VOUCHER' || campaign.type === 'LOYALTY')
      setModalIsOpen(true)
    if (campaign.type === 'POSITION') setPositionCampaignIsOpen(true)
  }

  const fetchCardList = async () => {
    try {
      const { data } = await api.get(
        `/superpartner/${merchantSelected?.id}/payment`,
      )

      if (data.length > 0) {
        const defaultMethod = data.find((item: CardType) => item.defaultMethod)

        setPaymentMethod(defaultMethod || data[0])
      }
      // eslint-disable-next-line no-empty
    } finally {
    }
  }

  const renderConfirmModal = () => (
    <Modal open={openConfirmModal} onOpenChange={setOpenConfirmModal} modal>
      <ModalContent
        title="Cancel Campaign"
        onClose={() => setOpenConfirmModal(false)}
        width="auto"
        xsWidth="auto"
        xsHeight="auto"
        hideCloseButton
      >
        <ConfirmModal
          cancelLabel="No"
          confirmLabel="Yes"
          message={<>Are you sure you want to cancel?</>}
          closeConfirm={(confirm: boolean) => {
            if (confirm) {
              setModalIsOpen(false)
              setPositionCampaignIsOpen(false)
            }
            setOpenConfirmModal(false)
          }}
        />
      </ModalContent>
    </Modal>
  )

  const handleCloseCampaign = (isSuccess?: boolean) => {
    if (isSuccess) {
      setModalIsOpen(false)
      setPositionCampaignIsOpen(false)
      setSuccessCampaignIsOpen(true)
    } else {
      setOpenConfirmModal(true)
    }
  }

  const renderSuccessCampaignModal = () => (
    <Modal
      open={successCampaignIsOpen}
      onOpenChange={setSuccessCampaignIsOpen}
      modal
    >
      <ModalContent
        title=""
        onClose={() => setSuccessCampaignIsOpen(false)}
        noHeader
      >
        <Success close={() => setSuccessCampaignIsOpen(false)} />
      </ModalContent>
    </Modal>
  )

  const renderCreatingNewCampaignModal = () => (
    <Modal open={modalIsOpen} onOpenChange={setModalIsOpen} modal>
      <ModalContent title="" onClose={handleCloseCampaign} noHeader>
        <CreatingNewCampaignModal
          campaignItem={campaignItem}
          campaign={campaign!}
          paymentMethod={paymentMethod}
          close={handleCloseCampaign}
        />
      </ModalContent>
    </Modal>
  )

  const renderPositionCampaignModal = () => (
    <Modal
      open={positionCampaignIsOpen}
      onOpenChange={setPositionCampaignIsOpen}
      modal
    >
      <ModalContent title="" onClose={handleCloseCampaign} noHeader>
        <PositionCampaignModal
          campaignItem={campaignItem!}
          campaign={campaign!}
          close={handleCloseCampaign}
          paymentMethod={paymentMethod}
        />
      </ModalContent>
    </Modal>
  )

  useEffect(() => {
    if (merchantSelected?.id) {
      fetchCardList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet title="New Campaign" />
      <S.Content>
        {campaignList.map((campaign) => (
          <S.Card key={campaign.id}>
            <S.CardIcon>
              <img
                src={campaign.icon}
                width={40}
                height={40}
                alt={campaign.title}
              />
            </S.CardIcon>

            <Box
              flex
              flexDirection="column"
              JustifyContent="center"
              gap="1rem"
              width="14.375rem"
            >
              <S.CardTitle>{campaign.title}</S.CardTitle>
              <S.CardDescription>{campaign.brief}</S.CardDescription>
            </Box>

            <S.CardList>
              <List<CampaignList>
                data={campaign.items}
                onItemClick={(item) => openCampaignModal(campaign, item)}
              >
                {(item) => (
                  <Fragment key={item.id}>
                    <S.ListItemTitle>{item.title}</S.ListItemTitle>
                    <S.ListIcon>
                      <img
                        src={inputOrderIcon}
                        width={14}
                        height={14}
                        alt="Input Order"
                      />
                    </S.ListIcon>
                  </Fragment>
                )}
              </List>
            </S.CardList>
          </S.Card>
        ))}
        {modalIsOpen && renderCreatingNewCampaignModal()}
        {positionCampaignIsOpen && renderPositionCampaignModal()}
        {openConfirmModal && renderConfirmModal()}
        {successCampaignIsOpen && renderSuccessCampaignModal()}
      </S.Content>
    </>
  )
}

import { format } from 'date-fns'
import { useEffect, useState } from 'react'

import {
  FieldContent,
  FieldError,
  FieldHeader,
  FieldHeaderTitle,
  FieldWrapper,
} from '@/modals/CreatingNewCampaignModal/styles'
import { formatCurrencyWithSymbol } from '@/utils/formats/number'

import * as S from './styles'

export type PeriodFieldProps = {
  months: {
    [key: string]: {
      month: string
      amount: number
      available: boolean
    }[]
  }
  position: number
  error?: string
  before?: boolean
  onChange: (value?: string[]) => void
}

export const PeriodField = ({
  error,
  onChange,
  months,
  position,
}: PeriodFieldProps) => {
  const [selectedMonths, setSelectedMonths] = useState<string[]>([])

  useEffect(() => {
    onChange(selectedMonths)
  }, [onChange, selectedMonths])

  useEffect(() => {
    setSelectedMonths([])
  }, [position])

  return (
    <FieldWrapper id="months">
      <FieldHeader>
        <FieldHeaderTitle>Period</FieldHeaderTitle>
      </FieldHeader>

      <FieldContent>
        <S.MonthList>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {months[position].map((item: any) => (
            <S.MonthItem
              active={selectedMonths.includes(item.month)}
              key={item.month}
              disabled={!item.available}
              onClick={() => {
                if (!item.available) return

                if (selectedMonths.includes(item.month)) {
                  setSelectedMonths((current) =>
                    current.filter((month) => {
                      return month !== item.month
                    }),
                  )
                } else {
                  setSelectedMonths([...selectedMonths, item.month])
                }
              }}
            >
              <S.Period>
                {format(new Date(`${item.month}-15`), 'LLL/yyyy')}
              </S.Period>
              <S.Price>{formatCurrencyWithSymbol(item.amount / 100)}</S.Price>
            </S.MonthItem>
          ))}

          {error && <FieldError>{error}</FieldError>}
        </S.MonthList>
      </FieldContent>
    </FieldWrapper>
  )
}

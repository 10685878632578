import styled, { css } from 'styled-components'

import Button from '@/components/Button'
import { Label, Wrapper as WrapperRadio } from '@/components/Radio/styles'

export const NewAddressTitle = styled.h1`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`

export const FormAddress = styled.div`
  margin-top: 1.563rem;
`

export const FormRow = styled.div`
  margin-bottom: 2rem;
  position: relative;

  input {
    &::placeholder {
      color: #dcdcdc;
    }
  }
`

export const Input = styled.input`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.colors.secondary};
    font-family: 'Quicksand';
    font-size: 0.75rem;
    padding: 0.75rem;
    border-radius: 13px;
    border: 1px solid ${theme.colors.lightGray};

    &::placeholder {
      color: ${theme.colors.gray};
    }

    &:focus {
      outline: none;
      border: 1px solid #dcdcdc;
    }
  `}
`

export const Icon = styled.span`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
`

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.938rem;
`

export const TextHelp = styled.div`
  ${({ theme }) => css`
    h4 {
      font-family: 'Quicksand';
      font-size: 0.938rem;
      line-height: 1.25rem;
      font-weight: 600;
      color: ${theme.colors.gray};
    }

    p {
      font-family: 'Quicksand';
      font-size: 0.75rem;
      line-height: 1.25rem;
      color: ${theme.colors.gray};
    }
  `}
`

export const ButtonSave = styled(Button)``

export const AlertWrapper = styled.div`
  position: absolute;
  top: 3.375rem;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 1.5rem;
`

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    bottom: 5.938rem !important;
  }
`

export const ButtonConfirmMap = styled(Button)``

export const Marker = styled.img`
  position: absolute;
  top: 40%;
  left: 46.6%;
  z-index: 9;
`

export const BackStepButton = styled.button`
  left: -3.438rem;
`

export const Address = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
`

export const AddressImage = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    p {
      font-family: 'Quicksand';
      font-size: 0.75rem;
      color: ${theme.colors.primary};
    }
  `}
`

export const AddressDescription = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: start;
    flex-direction: column;
    margin-left: 1.5rem;

    h3 {
      font-family: 'Quicksand';
      font-size: 0.938rem;
      color: ${theme.colors.secondary};
      font-weight: 600;
      line-height: 1.07;
    }

    p {
      font-family: 'Quicksand';
      font-size: 0.75rem;
      color: ${theme.colors.gray};
      line-height: 1.42;
    }
  `}
`

export const RadioInline = styled.div`
  display: flex;

  ${Label} {
    font-size: 0.938rem;
  }

  ${WrapperRadio} {
    margin-right: 2rem;
  }
`

export const Quarantine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const QuarantineDescription = styled.div`
  ${({ theme }) => css`
    h3 {
      font-family: 'Quicksand';
      font-size: 0.938rem;
      color: ${theme.colors.secondary};
      font-weight: 600;
      line-height: 1.07;
    }

    p {
      font-family: 'Quicksand';
      font-size: 0.75rem;
      color: ${theme.colors.gray};
      line-height: 1.42;
    }
  `}
`

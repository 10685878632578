import styled, { css } from 'styled-components'

export const WrapperContent = styled.div`
  position: relative;
`

export const MapWrapper = styled.div`
  height: 305px;
  position: relative;
  top: 0;
  border-radius: 30px;
`

export const Marker = styled.img`
  position: absolute;
  top: 37%;
  left: 48.6%;
  z-index: 9;
`

export const AutocompleteWrapper = styled.div`
  margin-top: 1rem;
`

export const TextHelp = styled.div`
  ${({ theme }) => css`
    h4 {
      font-family: 'Quicksand';
      font-size: 0.938rem;
      line-height: 1.25rem;
      font-weight: 600;
      color: ${theme.colors.gray};
    }

    p {
      font-family: 'Quicksand';
      font-size: 0.75rem;
      line-height: 1.25rem;
      color: ${theme.colors.gray};
    }
  `}
`

export const ButtonWrapper = styled.div`
  margin-top: 5.625rem;
  display: flex;
  justify-content: space-between;
`

import { Close } from '@styled-icons/material-rounded'
import { CSSProperties, ReactNode } from 'react'

import * as S from './styles'

export type PillProps = {
  children: ReactNode
  removable?: boolean
  avatar?: ReactNode
  onClose?: () => void
  style?: CSSProperties
}

const Pill = ({
  children,
  removable = true,
  avatar,
  onClose,
  ...props
}: PillProps) => (
  <S.Wrapper {...props}>
    {!!avatar && avatar}
    <S.Text>{children}</S.Text>
    {removable && (
      <S.Close type="button" onClick={() => onClose && onClose()}>
        <Close size={20} />
      </S.Close>
    )}
  </S.Wrapper>
)

export default Pill

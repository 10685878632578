import { zodResolver } from '@hookform/resolvers/zod'
import { format } from 'date-fns'
import ordinal from 'ordinal'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { StepForm, StepOverflow } from '@/components/FormStep'
import { useFormStepData } from '@/components/FormStep/Hook'
import {
  ModalFooter,
  ModalHeader,
  ModalHeaderClose,
  ModalHeaderTitle,
} from '@/components/Modal'
import useAuth from '@/hooks/useAuth'
import { api } from '@/lib/axios'
import { MagicVoucherField } from '@/modals/CreatingNewCampaignModal/components/MagicVoucherField'
import { PaymentField } from '@/modals/CreatingNewCampaignModal/components/PaymentField'
import { PeriodField } from '@/modals/CreatingNewCampaignModal/components/PeriodField'
import { PositionField } from '@/modals/CreatingNewCampaignModal/components/PositionField'
import {
  Header,
  ImageWrapper,
  Info,
  WrapperContent,
} from '@/modals/CreatingNewCampaignModal/styles'
import { CardType } from '@/types/payment.types'
import { Campaign, CampaignList } from '@/types/super-partner.types'

import { CheckoutSummary } from '../../CheckoutSummary'
import { BeforeType } from '..'

export type PositionFormProps = {
  close: (success?: boolean) => void
  campaignItem: CampaignList
  campaign: Campaign
  active: boolean
  before: BeforeType
  paymentMethod: CardType | null
  onNextStep: () => void
}

export type PricingOptions = {
  [key: string]: [
    {
      month: string
      amount: number
      available: boolean
    },
  ]
}

const positionFormSchema = z.object({
  position: z.number({ required_error: 'Position is required' }),
  period: z.string().array().min(1, { message: 'Period is required' }),
  payment: z.object(
    {
      cardNumberEnd: z.string(),
      defaultMethod: z.boolean(),
      gateway: z.string(),
      vaultId: z.string(),
    },
    { required_error: 'Payment is required' },
  ),
  magicVoucher: z
    .array(
      z.object({
        voucherId: z.string(),
        image: z.string(),
        title: z.string(),
        balance: z.number(),
        expiryDate: z.string(),
      }),
    )
    .optional(),
})

export type PositionFormData = z.infer<typeof positionFormSchema>

export const PositionForm = ({
  campaignItem,
  campaign,
  active,
  before,
  paymentMethod,
  close,
}: PositionFormProps) => {
  const { setStepFormValues } = useFormStepData<PositionFormData>()
  const [showPeriodSelector, setShowPeriodSelector] = useState(false)
  const { merchantSelected } = useAuth()
  const [isLoadingPositions, setIsLoadingPositions] = useState(true)
  const [isCreatingCampaign, setIsCreatingCampaign] = useState(false)

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<PositionFormData>({
    mode: 'all',
    resolver: zodResolver(positionFormSchema),
    defaultValues: {
      position: parseInt(campaignItem.id),
      period: [],
      payment: paymentMethod || undefined,
    },
  })

  const watchPosition = watch('position')
  const watchPeriod = watch('period')
  const watchMagicVoucher = watch('magicVoucher')

  useEffect(() => {
    setShowPeriodSelector(Boolean(campaignItem.id) && !isLoadingPositions)
  }, [campaignItem, isLoadingPositions])

  useEffect(() => {
    watch(() => {
      if (getValues('position')) {
        setShowPeriodSelector(true)
      }
    })
  }, [getValues, watch])

  const onSubmit = async (values: PositionFormData) => {
    setStepFormValues(values)

    const settings = values.period.map((period) => {
      const date = new Date(`${period}-05`)
      return {
        position: values.position,
        month: period,
        name: campaignItem?.name || '',
        description: `${ordinal(values.position)} position ${format(
          date,
          'MMM yyyy',
        )}`,
      }
    })

    let vouchers: string[] = []

    if (values.magicVoucher)
      vouchers = values.magicVoucher.map((voucher) => voucher.voucherId)

    const payload = {
      settings,
      vouchers,
      paymentMethod: values.payment,
    }

    setIsCreatingCampaign(true)

    try {
      await api.post(
        `/superpartner/${merchantSelected?.id}/campaigns/position`,
        payload,
      )

      close(true)
    } finally {
      setIsCreatingCampaign(false)
    }
  }

  const [pricingOptions, setPricingOptions] = useState<PricingOptions>({
    '1': [
      {
        month: '2023-08',
        amount: 9033,
        available: true,
      },
    ],
  })

  useEffect(() => {
    if (merchantSelected) getPricing(merchantSelected.id)
  }, [merchantSelected])

  async function getPricing(merchantId: string) {
    try {
      setIsLoadingPositions(true)
      const { data } = await api.get(
        `/superpartner/${merchantId}/campaigns/position/pricing`,
      )

      setPricingOptions(data)
    } finally {
      setIsLoadingPositions(false)
    }
  }

  return (
    <>
      <StepForm onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          <ModalHeaderTitle>{campaign.title}</ModalHeaderTitle>

          <ModalHeaderClose
            onClick={(event) => {
              event.preventDefault()
              close()
            }}
          >
            ✕
          </ModalHeaderClose>
        </ModalHeader>

        <StepOverflow active={active ? 1 : 0} before={before.active ? 1 : 0}>
          <WrapperContent>
            <Header>
              <Box flex flexDirection="column" gap="1.5rem">
                <Info>
                  <h4>You chose:</h4>
                  <p>{campaign.description}</p>
                </Info>
              </Box>

              <ImageWrapper style={{ backgroundColor: '#FFFFFF' }}>
                <img
                  src={campaign?.image}
                  width={333}
                  height={154}
                  alt={campaign?.title}
                />
              </ImageWrapper>
            </Header>
            <Controller
              name="position"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <PositionField
                  loading={isLoadingPositions}
                  pricing={pricingOptions}
                  position={getValues('position')?.toString()}
                  onChange={(args) => {
                    field.onChange(args)
                    setValue('period', [], { shouldValidate: false })
                  }}
                  error={error?.message}
                />
              )}
            />

            {showPeriodSelector && (
              <Controller
                name="period"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PeriodField
                    position={getValues('position')}
                    months={pricingOptions}
                    onChange={field.onChange}
                    error={error?.message}
                  />
                )}
              />
            )}
            <Controller
              name="payment"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <PaymentField
                  value={field.value}
                  onChange={field.onChange}
                  error={error?.message}
                />
              )}
            />
            <Controller
              name="magicVoucher"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <MagicVoucherField
                  value={field.value}
                  onChange={field.onChange}
                  error={error?.message}
                />
              )}
            />
          </WrapperContent>
        </StepOverflow>

        <ModalFooter>
          <CheckoutSummary
            position={watchPosition}
            monthsSelected={watchPeriod}
            pricingOptions={pricingOptions}
            magicVoucher={watchMagicVoucher}
          />

          <Box
            flex
            alignItems="center"
            JustifyContent="space-between"
            width="100%"
          >
            <Button size="large" color="white" onClick={() => close()} shadow>
              Cancel
            </Button>
            <Button
              loading={isCreatingCampaign}
              type="submit"
              size="large"
              disabled={!isValid || isCreatingCampaign}
            >
              Create
            </Button>
          </Box>
        </ModalFooter>
      </StepForm>
    </>
  )
}

import _ from 'lodash'
import { NavLink } from 'react-router-dom'
import { useTheme } from 'styled-components'

import adminSettingsButton from '@/assets/img/admin-settings-button.svg'
import adminSettingsButtonLight from '@/assets/img/admin-settings-button-light.svg'
import dashButton from '@/assets/img/dash-button.svg'
import dashButtonLight from '@/assets/img/dash-button-light.svg'
import liveButton from '@/assets/img/live-button.svg'
import menuMakerButton from '@/assets/img/menu-maker-button.svg'
import menuMakerButtonLight from '@/assets/img/menu-maker-button-light.svg'
import ordersButton from '@/assets/img/orders-button.svg'
import ordersButtonLight from '@/assets/img/orders-button-light.svg'
import settingsButton from '@/assets/img/settings-button.svg'
import settingsButtonLight from '@/assets/img/settings-button-light.svg'
import superPartnerButton from '@/assets/img/super-partner-button.svg'
import superPartnerButtonLight from '@/assets/img/super-partner-button-light.svg'
import { Permission } from '@/enums/permission.enum'
import useAuth from '@/hooks/useAuth'
import useLiveOrders from '@/hooks/useLiveOrders'

import * as S from './styles'

const Menu = () => {
  const { user } = useAuth()
  const { ongoing } = useLiveOrders()
  const theme = useTheme()

  const haveCreatedOrder = () => {
    return ongoing.filter((order) => order.lastStatus === 'created').length > 0
  }

  return (
    <S.Wrapper>
      {_.includes(user?.permissions, Permission.LIVE_ORDERS) && (
        <S.MenuItem>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? 'active' : '')}
            title="Live"
          >
            {ongoing?.length > 0 && (
              <S.Info hasCreated={haveCreatedOrder()}>{ongoing.length}</S.Info>
            )}
            <img
              src={liveButton}
              width={24}
              height={24}
              alt="Live button icon"
            />

            <S.Label>
              <S.LiveBall /> Live
            </S.Label>
          </NavLink>
        </S.MenuItem>
      )}

      {_.includes(user?.permissions, Permission.DASHBOARD) && (
        <S.MenuItem>
          <NavLink
            to="/dashboard"
            className={({ isActive }) => (isActive ? 'active' : '')}
            title="Dashboard"
          >
            {theme.light && (
              <img
                src={dashButton}
                width={24}
                height={24}
                alt="Dashboard icon dark"
              />
            )}
            {theme.dark && (
              <img
                src={dashButtonLight}
                width={24}
                height={24}
                alt="Dashboard icon light"
              />
            )}
            <S.Label>
              <S.LiveBall /> Dashboard
            </S.Label>
          </NavLink>
        </S.MenuItem>
      )}

      {_.includes(user?.permissions, Permission.ORDERS) && (
        <S.MenuItem>
          <NavLink
            to="/orders"
            className={({ isActive }) => (isActive ? 'active' : '')}
            title="History"
          >
            {theme.light && (
              <img
                src={ordersButton}
                width={24}
                height={24}
                alt="Orders button dark"
              />
            )}
            {theme.dark && (
              <img
                src={ordersButtonLight}
                width={24}
                height={24}
                alt="Orders button light"
              />
            )}
            <S.Label>History</S.Label>
          </NavLink>
        </S.MenuItem>
      )}

      {_.includes(user?.permissions, Permission.MENU_MAKER) && (
        <S.MenuItem>
          <NavLink
            to="/menu-maker"
            className={({ isActive }) => (isActive ? 'active' : '')}
            title="Menu Maker"
          >
            {theme.light && (
              <img
                src={menuMakerButton}
                width={24}
                height={24}
                alt="Menu Maker button dark"
              />
            )}
            {theme.dark && (
              <img
                src={menuMakerButtonLight}
                width={24}
                height={24}
                alt="Menu Maker button light"
              />
            )}

            <S.Label>Menu Maker</S.Label>
          </NavLink>
        </S.MenuItem>
      )}

      {_.includes(user?.permissions, Permission.SUPER_PARTNER) && (
        <S.MenuItem>
          <NavLink
            to="/super-partner"
            className={({ isActive }) => (isActive ? 'active' : '')}
            title="Super Partner"
          >
            {theme.light && (
              <img
                src={superPartnerButton}
                width={24}
                height={24}
                alt="Super Partner button dark"
              />
            )}
            {theme.dark && (
              <img
                src={superPartnerButtonLight}
                width={24}
                height={24}
                alt="Super Partner button light"
              />
            )}
            <S.Label>Super Partner</S.Label>
            <span className="animate-ping-slow rounded-xxl bg-pink-base px-2 text-sm !text-grayscale-white">
              New
            </span>
          </NavLink>
        </S.MenuItem>
      )}

      {_.includes(user?.permissions, Permission.ADMIN) && (
        <S.MenuItem>
          <NavLink
            to="/settings"
            className={({ isActive }) => (isActive ? 'active' : '')}
            title="Settings"
          >
            {theme.light && (
              <img
                src={settingsButton}
                width={24}
                height={24}
                alt="Settings button dark"
              />
            )}
            {theme.dark && (
              <img
                src={settingsButtonLight}
                width={24}
                height={24}
                alt="Settings button light"
              />
            )}
            <S.Label>Settings</S.Label>
          </NavLink>
        </S.MenuItem>
      )}

      {_.includes(user?.permissions, Permission.SUPER_ADMIN) && (
        <S.MenuItem>
          <NavLink
            to="/admin-settings"
            className={({ isActive }) => (isActive ? 'active' : '')}
            title="Admin Settings"
          >
            {theme.light && (
              <img
                src={adminSettingsButton}
                width={24}
                height={24}
                alt="Admin Settings button dark"
              />
            )}
            {theme.dark && (
              <img
                src={adminSettingsButtonLight}
                width={24}
                height={24}
                alt="Admin Settings button light"
              />
            )}
            <S.Label>Admin Settings</S.Label>
          </NavLink>
        </S.MenuItem>
      )}
    </S.Wrapper>
  )
}

export default Menu

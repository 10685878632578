import styled, { css } from 'styled-components'

export const HeaderTitle = styled.div`
  ${({ theme }) => css`
    font-size: 1.5rem;
    line-height: 1.22;
    font-weight: ${theme.font.bold};
    margin-bottom: ${theme.spacings.xsmall};
  `}
`

export const Section = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.medium};
    padding: ${theme.spacings.medium};
    border-radius: 24px;
    background-color: ${theme.colors.white};
  `}
`

import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { Box } from '../Box'
import { Title as DropdownTitle } from '../Dropdown/styles'
import {
  SearchInputContainer,
  SearchPreviewContainer,
} from '../SearchInput/styles'

export const Header = styled.header<{ hasSearch: boolean }>`
  ${({ hasSearch }) => css`
    grid-area: header;
    width: 100%;
    min-width: 22.5rem;
    padding: 0.75rem 0 0.625rem;

    ${Box} {
      height: ${!hasSearch ? '100%' : 'auto'};
      z-index: 1;
      position: relative;
    }

    ${SearchInputContainer} {
      margin-top: 0.625rem;
    }

    ${SearchPreviewContainer} {
      margin-left: 1.125rem;
    }

    ${media.lessThan('medium')`
      padding: 0;
    `}

    ${media.greaterThan('medium')`
      ${Box} {
        height: 100%;
      }

      ${SearchPreviewContainer} {
        margin-left: 0;
      }
    `}
  `}
`

export const LogoSearch = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${media.greaterThan('medium')`
    flex-direction: row;
  `}
`

export const LogoWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0;
    width: 3.813rem;

    ${media.greaterThan('medium')`
      width: 7.938rem;
      padding: 0 ${theme.spacings.medium} 0 ${theme.spacings.xxlarge};

      > div {
        width: 3.813rem;
      }
    `}
  `}
`

export const TextFieldWrapper = styled.div`
  ${({ theme }) => css`
    width: 20.5rem;
    padding: 0 ${theme.spacings.medium};
  `}
`

export const UserSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.875rem;
  margin-left: 8px;

  ${DropdownTitle} {
    padding-right: 0;
  }
`

export const MerchantName = styled.div`
  max-width: 200px;
  min-width: 130px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${media.lessThan('large')`
    max-width: 130px;
    min-width: 130px;
  `}
`

export const Aside = styled.aside`
  ${({ theme }) => css`
    grid-area: sidebar;

    height: 80vh;
    padding-bottom: 20px;

    ${media.lessThan('large')`
      position: fixed;
      height: 100vh;
      padding-bottom: 0;
      top: 0;
      box-shadow: 8px 0 10px -2px  rgba(0, 0, 0, 0.1);
      width: 7.938rem;
      overflow-y: auto;
     `}

    ${media.lessThan('medium')`
      position: fixed;
      bottom: 0;
      padding-bottom: 0;
      top: auto;
      width: 100%;
      height: auto;
      z-index: ${theme.layers.menu};
      box-shadow: none;
      margin-left: -0.625rem;
      overflow-x: auto;
    `}

    top: 112px;
  `}
`

export const Content = styled.main<{ radiusBorderLeftTop?: boolean }>`
  ${({ theme, radiusBorderLeftTop = false }) => css`
    grid-area: main;
    border-radius: ${theme.border.radius.large};
    background-color: ${theme.colors.white};
    width: 100%;
    min-width: 21.25rem;

    ${media.greaterThan('large')`
      border-top-left-radius: ${
        radiusBorderLeftTop ? theme.border.radius.large : 0
      };
    `}
  `}
`

export const BatteryIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 4px;
  font-size: 0.75rem;
`

export const MerchantInfo = styled.div`
  max-width: 200px;
  min-width: 130px;
  max-height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: right;

  ${media.lessThan('large')`
    max-width: 130px;
    min-width: 130px;
  `}
`

export const MerchantSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.875rem;

  ${media.lessThan('small')`
    flex-direction: column-reverse;
    align-items: flex-end;
  `}
`

export const MerchantDetails = styled.div`
  gap: 0.875rem;
  display: flex;
  align-items: center;
`

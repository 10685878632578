import styled, { css } from 'styled-components'

import { Label as RadioLabel } from '@/components/Radio/styles'

export const OptionsList = styled.ul`
  list-style: none;
`

export const OptionItem = styled.li`
  ${({ theme }) => css`
    & + & {
      border-top: 1px solid #dcdcdc;
    }

    ${RadioLabel} {
      width: 100%;
      padding: ${theme.spacings.xsmall} 0;
      padding-left: 0.625rem;
    }
  `}
`

import styled, { css } from 'styled-components'

import { TakeoutDeliveryChartProps } from '.'

type WrapperProps = Pick<
  TakeoutDeliveryChartProps,
  'height' | 'moreThanFifteenDays'
>

export const Wrapper = styled.div<WrapperProps>`
  ${({ height = '32.5rem', moreThanFifteenDays }) => css`
    width: 100%;
    min-width: 22.5rem;
    height: ${height};
    margin: 0 auto;

    ${moreThanFifteenDays &&
    css`
      .recharts-surface {
        height: 28.125rem;
      }
    `}
  `}
`
export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.nunito};
    font-size: ${theme.font.sizes.xlarge};
    font-weight: ${theme.font.extraBold};
    margin-bottom: ${theme.spacings.large};
    color: ${theme.colors.primary};
    text-align: center;
  `}
`

export const Tooltip = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.secondary};
    padding: 0.313rem ${theme.spacings.xxsmall};
    color: ${theme.colors.primaryText};
    border-radius: 0.5rem;

    h4 {
      font-size: ${theme.font.sizes.medium};
      font-weight: ${theme.font.bold};
    }

    p {
      font-size: ${theme.font.sizes.medium};
      padding: 0.125rem 0;
      color: ${theme.colors.lightGray};
    }
  `}
`

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
`

export const ListItemBullet = styled.div`
  svg {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.375rem;
    margin-right: 0.5rem;

    rect {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`

export const ListItem = styled.li`
  ${({ theme }) => css`
    display: flex;
    padding-right: ${theme.spacings.xsmall};
    color: ${theme.colors.secondary};
    height: ${theme.font.sizes.xxlarge};
  `}
`

import { useEffect, useState } from 'react'

import * as S from './styles'

export type DropdownProps = {
  title: React.ReactNode
  children: (close: () => void) => React.ReactNode
  position?: 'left' | 'right' | 'center'
  onClose?: () => void
}

const Dropdown = ({
  title,
  children,
  position = 'right',
  onClose,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (!isOpen) {
      onClose && onClose()
    }
  }, [isOpen, onClose])

  return (
    <S.Wrapper className="dropdown" isOpen={isOpen}>
      <S.Title className="dropdown-title" onClick={handleToggle}>
        {title}
      </S.Title>

      <S.Content
        className="dropdown-content"
        position={position}
        aria-hidden={!isOpen}
      >
        {children(handleToggle)}
      </S.Content>
      <S.Overlay aria-hidden={!isOpen} onClick={handleToggle} />
    </S.Wrapper>
  )
}

export default Dropdown

/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { GoogleMap, Libraries, useJsApiLoader } from '@react-google-maps/api'
import { useCallback, useState } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { components } from 'react-select'

import settingsService from '@/api/settings.service'
import othersSearchIcon from '@/assets/img/icons-others-search.svg'
import pinMap from '@/assets/img/pin-map.svg'
import Button from '@/components/Button'
import { ModalFooter, ModalOverflow } from '@/components/Modal'
import { env } from '@/env'
import useAuth from '@/hooks/useAuth'
import { AddressInfoType, AddressType } from '@/types/settings.types'

import * as S from './styles'

export type AddressCompleteType = {
  address: {
    description?: string
    city?: string
    country?: string
    streetNumber?: string
    instructions?: string
    extraInfo: string
    formattedAddress: string
    neighborhood: string
    placeId: string
    state: string
    streetName: string
    coordinates: {
      lat: number
      lng: number
    }
    coordinatesAdjustment?: {
      lat: number
      lng: number
    }
  }
}

export type EditAddressModalProps = {
  lat: number
  lng: number
  addressProp: AddressInfoType
  close: (address?: AddressType) => void
}

const options: google.maps.MapOptions = {
  disableDefaultUI: true,
  mapTypeId: 'satellite',
}

type LatLngType = {
  lat: number
  lng: number
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        src={othersSearchIcon}
        width={16}
        height={16}
        alt="Others search icon"
      />
    </components.DropdownIndicator>
  )
}

const libraries: Libraries = ['places', 'visualization']

const EditAddressModal = ({
  lat,
  lng,
  addressProp,
  close,
}: EditAddressModalProps) => {
  const { user, merchantsSelected } = useAuth()
  const [filterText, setFilterText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [address, setAddress] = useState<AddressInfoType>(addressProp)
  const [mapCenter, setMapCenter] = useState<LatLngType>({
    lat,
    lng,
  })
  const [map, setMap] = useState<google.maps.Map | null>(null)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: env.VITE_MAPS_API_KEY,
    libraries,
  })

  const handleDragMap = useCallback(() => {
    const center = map?.getCenter() as any
    if (center) {
      setMapCenter({ lat: center.lat(), lng: center.lng() })
    }
    setAddress({
      address: {
        ...address.address,
        coordinates: { lat: center.lat(), lng: center.lng() },
      },
    })
  }, [address.address, map])

  const handlePlaceChange = useCallback(
    async (e: any) => {
      setFilterText(e)
      const { value } = e

      const request = {
        placeId: value.place_id,
        fields: ['name', 'address_component', 'formatted_address', 'geometry'],
      }

      const service = new google.maps.places.PlacesService(map!)
      service.getDetails(request, callback)

      function callback(
        place: google.maps.places.PlaceResult | null,
        status: google.maps.places.PlacesServiceStatus,
      ) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const { address_components, geometry, formatted_address } = place!

          const newAddress: AddressInfoType = {
            address: addressProp.address,
          } as AddressInfoType

          newAddress.address.placeId = value.place_id

          newAddress.address.formattedAddress = formatted_address || ''

          if (address_components && address_components?.length > 0) {
            address_components.forEach((item) => {
              const { long_name, types } = item

              newAddress.address.streetName = types.includes('route')
                ? long_name
                : newAddress.address.streetName

              newAddress.address.neighborhood = types.includes('locality')
                ? long_name
                : newAddress.address.neighborhood
            })
          }

          const coordinates = {
            lat: geometry?.location?.lat() as number,
            lng: geometry?.location?.lng() as number,
          }

          newAddress.address.coordinates = {
            lat: coordinates.lat,
            lng: coordinates.lng,
          }

          setAddress(newAddress)
          setMapCenter(coordinates)
        }
      }
    },
    [addressProp.address, map],
  )

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map)
  }, [])

  const handleSave = async () => {
    if (user && merchantsSelected) {
      setIsLoading(true)
      try {
        await settingsService.saveMerchantAddressInfo(
          address.address,
          merchantsSelected[0].id,
          user.uuid,
        )
        close(address.address)
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <>
      <ModalOverflow>
        <S.WrapperContent>
          <S.MapWrapper>
            <S.Marker src={pinMap} />
            {isLoaded && (
              <GoogleMap
                options={options}
                mapContainerStyle={{
                  width: '100%',
                  height: '305px',
                  display: 'block',
                }}
                zoom={18}
                center={mapCenter}
                clickableIcons={false}
                onDragEnd={handleDragMap}
                onLoad={onLoad}
              />
            )}
          </S.MapWrapper>
          <S.AutocompleteWrapper>
            <GooglePlacesAutocomplete
              autocompletionRequest={{
                componentRestrictions: { country: ['ky'] },
              }}
              debounce={500}
              selectProps={{
                value: filterText as any,
                onChange: handlePlaceChange,
                placeholder: 'Address',
                noOptionsMessage: () =>
                  (
                    <S.TextHelp>
                      <h4>Can’t find your address?</h4>
                      <p>
                        Try searching by a closer location and adjust the
                        coordinates on map above
                      </p>
                    </S.TextHelp>
                  ) as any,
                loadingMessage: () => 'Searching...',
                components: {
                  DropdownIndicator,
                  IndicatorSeparator: null,
                },
                openMenuOnFocus: true,
                theme: (theme: any) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#c5c0c0',
                  },
                }),
                styles: {
                  input: (provided: any) => ({
                    ...provided,
                    color: '#8e8b8b',
                    fontSize: '0.75rem',
                  }),
                  control: (provided: any) => ({
                    ...provided,
                    borderRadius: '0.813rem',
                    border: '1px solid #c5c0c0',
                  }),
                  placeholder: (provided: any) => ({
                    ...provided,
                    color: '#8e8b8b',
                    fontSize: '0.75rem',
                  }),
                  singleValue: (provided: any) => ({
                    ...provided,
                    color: '#8e8b8b',
                    fontSize: '0.75rem',
                  }),
                },
              }}
            />
          </S.AutocompleteWrapper>
        </S.WrapperContent>
      </ModalOverflow>

      <ModalFooter>
        <S.ButtonWrapper style={{ marginTop: '1.6rem' }}>
          <Button shadow size="large" color="white" onClick={() => close()}>
            Cancel
          </Button>
          <Button shadow loading={isLoading} size="large" onClick={handleSave}>
            Save
          </Button>
        </S.ButtonWrapper>
      </ModalFooter>
    </>
  )
}

export default EditAddressModal

import {
  Add as AddIcon,
  Close as CloseIcon,
} from '@styled-icons/material-outlined'
import { format } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'

import { Box } from '@/components/Box'
import Button from '@/components/Button'
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverflow,
} from '@/components/Modal'
import SelectButton from '@/components/SelectButton'
import TextField from '@/components/TextField'
import TimePicker from '@/components/TimePicker'
import { ShiftHoursType, ShiftType } from '@/types/menu-maker.types'
import { convertTimeToDate } from '@/utils/formats/date'

import * as S from './styles'

export type EditSettingsHoursModalProps = {
  editItem: ShiftType | null
  closeEditSettingsHours: (shift?: ShiftType) => void
}

type FormErrorsType = {
  hours: ShiftHoursType[]
}

type WeekDayType = {
  name: string
  value: number
  selected: boolean
}

type HoursModalType = {
  start: boolean
  end: boolean
}

const weekDayName: WeekDayType[] = [
  { name: 'Mon', value: 1, selected: false },
  { name: 'Tue', value: 2, selected: false },
  { name: 'Wed', value: 3, selected: false },
  { name: 'Thu', value: 4, selected: false },
  { name: 'Fri', value: 5, selected: false },
  { name: 'Sat', value: 6, selected: false },
  { name: 'Sun', value: 7, selected: false },
]

const hoursDefault: ShiftHoursType[] = [{ start: '', end: '' }]
const hoursModalDefault: HoursModalType[] = [{ start: false, end: false }]

const EditSettingsHoursModal = ({
  editItem,
  closeEditSettingsHours,
}: EditSettingsHoursModalProps) => {
  const [weekDays, setWeekDays] = useState<WeekDayType[]>(weekDayName)
  const [hours, setHours] = useState<ShiftHoursType[]>(hoursDefault)
  const [openTimePickerModal, setOpenTimePickerModal] =
    useState<HoursModalType[]>(hoursModalDefault)
  const [formErrors, setFormErrors] = useState<FormErrorsType>({
    hours: hoursDefault,
  })

  const canBeDone = hours.every((h) => h.start && h.end)

  const handleSelectDay = useCallback(
    (day: WeekDayType, index: number) => {
      const newDays = [...weekDays]
      newDays[index] = { ...day, selected: !day.selected }
      setWeekDays(newDays)
    },
    [weekDays],
  )

  const handleHour = useCallback(
    (type: string, time: string, hour: ShiftHoursType, index: number) => {
      const newHours = [...hours]
      newHours[index] = { ...hour, [type]: time }
      setHours(newHours)
    },
    [hours],
  )

  const handleAddNewHour = useCallback(() => {
    setHours((old) => [...old, ...hoursDefault])
    setFormErrors((old) => ({ ...old, hours: [...old.hours, ...hoursDefault] }))
    setOpenTimePickerModal((old) => [...old, ...hoursModalDefault])
  }, [])

  const handleRemoveHour = useCallback(
    (index: number) => {
      const newHours = [...hours]
      newHours.splice(index, 1)
      setHours(newHours)
      const newHoursErrors = [...formErrors.hours]
      newHoursErrors.splice(index, 1)
      setFormErrors((old) => ({ ...old, hours: newHoursErrors }))
      const newModals = [...openTimePickerModal]
      newModals.splice(index, 1)
      setOpenTimePickerModal(newModals)
    },
    [formErrors.hours, hours, openTimePickerModal],
  )

  const setHourModalToggle = useCallback(
    (type: 'start' | 'end', index: number) => {
      const newHoursModal = [...openTimePickerModal]
      newHoursModal[index][type] = !newHoursModal[index][type]
      setOpenTimePickerModal(newHoursModal)
    },
    [openTimePickerModal],
  )

  const validationHoursForm = useCallback(
    (time: string, hour: ShiftHoursType, type: string, index: number) => {
      const timeDate = convertTimeToDate(time)
      const timeStart = convertTimeToDate(hour.start)
      const timeEnd = convertTimeToDate(hour.end)
      const newHours = [...formErrors.hours]

      if (type === 'start') {
        if (timeDate.getTime() > timeEnd.getTime()) {
          newHours[index] = {
            start: 'Start time cannot be greater than end time',
            end: '',
          }
          setFormErrors((old) => ({ ...old, hours: newHours }))
          handleHour('start', '', hour, index)
          return
        }
        newHours[index] = {
          start: '',
          end: '',
        }
        setFormErrors((old) => ({ ...old, hours: newHours }))
        handleHour('start', time, hour, index)
      } else {
        if (timeDate.getTime() < timeStart.getTime()) {
          newHours[index] = {
            start: '',
            end: 'End time cannot be less than start time',
          }
          setFormErrors((old) => ({ ...old, hours: newHours }))
          handleHour('end', '', hour, index)
          return
        }
        newHours[index] = {
          start: '',
          end: '',
        }
        setFormErrors((old) => ({ ...old, hours: newHours }))
        handleHour('end', time, hour, index)
      }
    },
    [formErrors.hours, handleHour],
  )

  const saveShift = useCallback(() => {
    const weekShift = weekDays
      .filter((day) => day.selected)
      .map((day) => day.value)

    closeEditSettingsHours({ hours, weekDays: weekShift })
  }, [closeEditSettingsHours, hours, weekDays])

  useEffect(() => {
    if (editItem) {
      const weekDaysShift = weekDays.map((day) => ({
        ...day,
        selected: !!editItem.weekDays.some((d) => d === day.value),
      }))
      setWeekDays(weekDaysShift)
      setFormErrors({
        hours: editItem.hours.map(() => ({
          start: '',
          end: '',
        })),
      })
      setOpenTimePickerModal(
        editItem.hours.map(() => ({
          start: false,
          end: false,
        })),
      )
      setHours(editItem.hours)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItem])

  return (
    <>
      <ModalOverflow>
        <S.Content>
          <S.MenuForm>
            <Box flex flexDirection="column" width="60%">
              <S.LabelForm>Menu Days</S.LabelForm>
              <S.Days>
                {weekDays.map((day, index) => (
                  <SelectButton
                    key={day.value}
                    selected={day.selected}
                    onClick={() => handleSelectDay(day, index)}
                  >
                    {day.name}
                  </SelectButton>
                ))}
              </S.Days>
            </Box>
            <Box flex flexDirection="column" width="35%">
              <S.LabelForm>Menu Hours</S.LabelForm>

              {hours.map((hour, index) => (
                <S.Hours key={`${hour.start}${hour.end}_${index}`}>
                  <S.Hour first>
                    <TextField
                      type="text"
                      label="Start Time"
                      labelFor="start"
                      id="start"
                      variant="material"
                      error={formErrors.hours[index].start}
                      onClick={() => setHourModalToggle('start', index)}
                      value={
                        hour.start
                          ? format(convertTimeToDate(hour.start), 'hh:mm aa')
                          : ''
                      }
                    />
                    <Modal
                      open={openTimePickerModal[index].start}
                      onOpenChange={(open) => {
                        if (!open) setHourModalToggle('start', index)
                      }}
                      modal
                    >
                      <ModalContent
                        onClose={() => setHourModalToggle('start', index)}
                        width="auto"
                        xsWidth="auto"
                        xsHeight="auto"
                        noHeader
                      >
                        <TimePicker
                          time={hour.start || '00:00'}
                          switchToMinuteOnHourSelect
                          onDoneClick={(time) => {
                            validationHoursForm(
                              time.formatted24,
                              hour,
                              'start',
                              index,
                            )
                            setHourModalToggle('start', index)
                          }}
                        />
                      </ModalContent>
                    </Modal>
                  </S.Hour>
                  <S.Hour>
                    <TextField
                      type="text"
                      label="End Time"
                      labelFor="end"
                      id="end"
                      variant="material"
                      error={formErrors.hours[index].end}
                      onClick={() => setHourModalToggle('end', index)}
                      value={
                        hour.end
                          ? format(convertTimeToDate(hour.end), 'hh:mm aa')
                          : ''
                      }
                    />
                    <Modal
                      open={openTimePickerModal[index].end}
                      onOpenChange={(open) => {
                        if (!open) setHourModalToggle('end', index)
                      }}
                      modal
                    >
                      <ModalContent
                        onClose={() => setHourModalToggle('end', index)}
                        width="auto"
                        xsWidth="auto"
                        xsHeight="auto"
                        noHeader
                      >
                        <TimePicker
                          time={hour.end || '00:00'}
                          switchToMinuteOnHourSelect
                          onDoneClick={(time) => {
                            validationHoursForm(
                              time.formatted24,
                              hour,
                              'end',
                              index,
                            )
                            setHourModalToggle('end', index)
                          }}
                        />
                      </ModalContent>
                    </Modal>
                  </S.Hour>
                  {hours.length > 1 && (
                    <S.ButtonIcon
                      minimal
                      onClick={() => handleRemoveHour(index)}
                    >
                      <CloseIcon size="20" />
                    </S.ButtonIcon>
                  )}
                </S.Hours>
              ))}

              <Box>
                <S.ButtonAdd
                  icon={<AddIcon />}
                  iconside="right"
                  minimal
                  onClick={handleAddNewHour}
                >
                  add new
                </S.ButtonAdd>
              </Box>
            </Box>
          </S.MenuForm>
        </S.Content>
      </ModalOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
          style={{ marginTop: '1rem' }}
        >
          <Button
            color="white"
            size="large"
            onClick={() => closeEditSettingsHours()}
            shadow
          >
            Cancel
          </Button>

          <Button
            size="large"
            disabled={!canBeDone}
            onClick={saveShift}
            style={{ marginLeft: 'auto' }}
          >
            Save
          </Button>
        </Box>
      </ModalFooter>
    </>
  )
}

export default EditSettingsHoursModal

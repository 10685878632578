import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    .rts___btn {
      border: none;
      border-radius: 0.25rem;
      color: ${theme.colors.secondary};
      height: 3.75rem;

      &:hover {
        background-color: inherit;

        > svg {
          stroke: ${theme.colors.primary};
        }
      }

      &:disabled {
        cursor: not-allowed;
        color: ${theme.colors.gray};
      }
    }

    .rts___tabs___container {
      padding: 0;

      .rts___tabs {
        border-bottom: 1px solid ${theme.colors.borderColor};
        padding: 0;
        width: 100%;

        .rts___tab {
          display: inline-block;
          border: none;
          border-bottom: 3px solid transparent;
          bottom: -1px;
          position: relative;
          list-style: none;
          font-family: ${theme.font.nunito};
          font-weight: ${theme.font.semiBold};
          font-size: ${theme.font.sizes.large};
          padding: ${theme.spacings.xxsmall} ${theme.spacings.small}
            ${theme.spacings.xsmall};
          color: ${theme.colors.black};
          cursor: pointer;
          transition: all ${theme.transition.default};
          border-radius: 1px;
          box-shadow: none;

          &.rts___tab___selected {
            border-bottom-color: ${theme.colors.primary};
            border-bottom-width: 3px;
            border-radius: 1px;
            color: ${theme.colors.primary};
            background: ${theme.colors.secondaryBg};
          }

          ${media.lessThan('medium')`
            font-size: ${theme.font.sizes.medium};
            padding: ${theme.spacings.xxsmall} ${theme.spacings.xxsmall} ${theme.spacings.xsmall};
          `}

          ${media.lessThan('small')`
            font-size: ${theme.font.sizes.small};
            padding: ${theme.spacings.xxsmall} 0.25rem ${theme.spacings.xsmall};
          `}
        }
      }
    }
  `}
`

export const TabPanel = styled.div``

import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  text-align: center;
`

export const Info = styled.h3`
  ${({ theme }) => css`
    text-transform: uppercase;
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.medium};
  `}
`

export const Value = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxlarge};
    font-weight: ${theme.font.bold};
  `}
`

export const Currency = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.bold};
    margin-right: 0.5rem;
  `}
`

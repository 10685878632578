import { useCallback, useEffect, useState } from 'react'
import Cropper, { Area, Point, Size } from 'react-easy-crop'
import { useMediaQuery } from 'react-responsive'

import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { ModalFooter, ModalOverflow } from '@/components/Modal'
import Slider from '@/components/Slider'
import { ImageUploadType } from '@/modals/EditItemModal'
import getCroppedImg from '@/utils/images/crop'
import resizeImg from '@/utils/images/resize'

import * as S from './styles'

export type CropPictureModalProps = {
  picture: ImageUploadType
  closeCropped: (croppedImage?: ImageUploadType, isCancel?: boolean) => void
  aspect?: number
  cropSize?: Size
  cropShape?: 'round' | 'rect'
}

const CropPictureModal = ({
  picture,
  closeCropped,
  aspect,
  cropSize = { width: 600, height: 400 },
  cropShape = 'rect',
}: CropPictureModalProps) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [contrast, setContrast] = useState(0)
  const [brightness, setBrightness] = useState(0)
  const [saturate, setSaturate] = useState(0)
  const [shadows, setShadows] = useState(0)
  const [cropWidth, setCropWidth] = useState(isMobile ? 400 : cropSize.width)
  const [cropHeight, setCropHeight] = useState(isMobile ? 300 : cropSize.height)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)
  const [croppedImage, setCroppedImage] = useState<ImageUploadType | null>(null)

  const onCropComplete = useCallback((_: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleCroppedImage = useCallback(async () => {
    try {
      const croppedImageUp = await getCroppedImg(
        picture.imagePreview,
        croppedAreaPixels!,
        rotation,
        picture.file as File,
        contrast,
        brightness,
        saturate,
        shadows,
      )
      const resizedCroppedImageUp = await resizeImg(
        croppedImageUp.imagePreview,
        croppedImageUp.file as File,
      )
      setCroppedImage(resizedCroppedImageUp)
    } catch (e) {}
  }, [
    brightness,
    contrast,
    croppedAreaPixels,
    picture,
    rotation,
    saturate,
    shadows,
  ])

  useEffect(() => {
    if (croppedImage) closeCropped(croppedImage, false)
  }, [closeCropped, croppedImage])

  return (
    <>
      <ModalOverflow>
        <Box flex alignItems="center" JustifyContent="center">
          <S.CropContainer
            contrast={contrast}
            brightness={brightness}
            saturate={saturate}
            shadows={shadows}
          >
            <Cropper
              image={picture.imagePreview}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={aspect}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropShape={cropShape}
              cropSize={
                !aspect ? { width: cropWidth, height: cropHeight } : undefined
              }
              onMediaLoaded={(mediaSize) => {
                setCropWidth(mediaSize.width)
                setCropHeight(mediaSize.height)
              }}
            />
          </S.CropContainer>
        </Box>

        <S.TextFrame>Drag photo to frame</S.TextFrame>

        <S.Controls>
          <S.Control>
            <Box flex alignItems="center" JustifyContent="space-between">
              <S.ControlTitle>Zoom</S.ControlTitle>
              <S.ControlValue>{zoom.toFixed(1)}</S.ControlValue>
            </Box>

            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(value) => setZoom(value)}
            />
          </S.Control>

          <S.Control>
            <Box flex alignItems="center" JustifyContent="space-between">
              <S.ControlTitle>Rotation</S.ControlTitle>
              <S.ControlValue>{rotation}</S.ControlValue>
            </Box>

            <Slider
              value={rotation}
              min={0}
              max={360}
              step={1}
              aria-labelledby="Rotation"
              onChange={(value) => setRotation(value)}
            />
          </S.Control>

          <S.Control>
            <Box flex alignItems="center" JustifyContent="space-between">
              <S.ControlTitle>Contrast</S.ControlTitle>
              <S.ControlValue>{contrast}</S.ControlValue>
            </Box>

            <Slider
              value={contrast}
              min={-100}
              max={100}
              step={1}
              aria-labelledby="Contrast"
              onChange={(value) => setContrast(value)}
            />
          </S.Control>

          <S.Control>
            <Box flex alignItems="center" JustifyContent="space-between">
              <S.ControlTitle>Brightness</S.ControlTitle>
              <S.ControlValue>{brightness}</S.ControlValue>
            </Box>

            <Slider
              value={brightness}
              min={-100}
              max={100}
              step={1}
              aria-labelledby="Brightness"
              onChange={(value) => setBrightness(value)}
            />
          </S.Control>

          <S.Control>
            <Box flex alignItems="center" JustifyContent="space-between">
              <S.ControlTitle>Saturation</S.ControlTitle>
              <S.ControlValue>{saturate}</S.ControlValue>
            </Box>

            <Slider
              value={saturate}
              min={-100}
              max={100}
              step={1}
              aria-labelledby="Saturation"
              onChange={(value) => setSaturate(value)}
            />
          </S.Control>

          <S.Control>
            <Box flex alignItems="center" JustifyContent="space-between">
              <S.ControlTitle>Shadows</S.ControlTitle>
              <S.ControlValue>{shadows}</S.ControlValue>
            </Box>

            <Slider
              value={shadows}
              min={0}
              max={100}
              step={1}
              aria-labelledby="Shadows"
              onChange={(value) => setShadows(value)}
            />
          </S.Control>

          {!aspect && (
            <>
              <S.Control>
                <Box flex alignItems="center" JustifyContent="space-between">
                  <S.ControlTitle>Crop Width</S.ControlTitle>
                  <S.ControlValue>{cropWidth}</S.ControlValue>
                </Box>

                <Slider
                  value={cropWidth}
                  min={100}
                  max={isMobile ? 400 : 600}
                  step={50}
                  aria-labelledby="Crop Width"
                  onChange={(value) => setCropWidth(value)}
                />
              </S.Control>

              <S.Control>
                <Box flex alignItems="center" JustifyContent="space-between">
                  <S.ControlTitle>Crop Height</S.ControlTitle>
                  <S.ControlValue>{cropHeight}</S.ControlValue>
                </Box>

                <Slider
                  value={cropHeight}
                  min={100}
                  max={isMobile ? 300 : 400}
                  step={50}
                  aria-labelledby="Crop Height"
                  onChange={(value) => setCropHeight(value)}
                />
              </S.Control>
            </>
          )}
        </S.Controls>
      </ModalOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
          style={{ marginTop: '1rem' }}
        >
          <Button
            color="white"
            size="large"
            onClick={() => closeCropped(undefined, true)}
            shadow
          >
            Cancel
          </Button>
          <span />
          <Button size="large" onClick={handleCroppedImage}>
            Save
          </Button>
        </Box>
      </ModalFooter>
    </>
  )
}

export default CropPictureModal

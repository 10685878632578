import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import Button from '@/components/Button'
import { Label as DateRangeLabel } from '@/components/DateRangePicker/styles'
import { InputWrapper } from '@/components/TextField/styles'

export const Wrapper = styled.main`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacings.xxlarge} ${theme.spacings.small};

    .tooltip {
      border-radius: 8px;
      font-family: ${theme.font.quicksand};
      font-size: ${theme.font.sizes.xsmall};
    }

    .rdt_TableRow {
      background-position: center;
      transition: background 0.8s;

      &:hover {
        background: ${theme.colors.mainBg}
          radial-gradient(circle, transparent 1%, ${theme.colors.mainBg} 1%)
          center/15000%;
      }

      &:active {
        background-color: ${theme.colors.lightGray};
        background-size: 100%;
        transition: background 0s;
      }
    }
  `}
`

export const Header = styled.header`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: ${theme.spacings.medium};

    h1 {
      font-size: ${theme.font.sizes.xlarge};
      color: ${theme.colors.primary};
      font-family: ${theme.font.nunito};
      font-weight: ${theme.font.extraBold};
    }
  `}
`

export const Actions = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;

    > div + div {
      margin-left: ${theme.spacings.small};
    }
  `}
`

export const TextFieldWrapper = styled.div`
  ${({ theme }) => css`
    width: 12.375rem;
    max-width: 13rem;

    ${InputWrapper} {
      border-color: ${theme.colors.mainBg};

      &:focus-within {
        border-color: ${theme.colors.lightGray};
      }
    }
  `}
`

export const DateWrapper = styled.div`
  width: 180px;

  ${media.between('medium', 'large')`
    ${DateRangeLabel} {
      display: none;
    }
  `}

  ${media.greaterThan('large')`
    width: 300px;
  `}
`

export const MobileButtonWrapper = styled.div`
  margin-left: 1.5rem;
  margin-bottom: 0.25rem;
`

export const ContentTable = styled.div`
  width: 100%;
`

export const ButtonIcon = styled(Button)`
  padding: 0.625;
  height: auto;

  svg {
    width: 1.25rem;
    max-height: 1.25rem;
  }
`

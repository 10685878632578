import styled, { css } from 'styled-components'

import { Wrapper as AvatarWrapper } from '../../components/Avatar/styles'

export const Wrapper = styled.div<{ hasMore?: boolean }>`
  ${({ hasMore }) => css`
    display: flex;
    align-items: center;
    margin-right: ${!hasMore ? '0.5rem' : '0'};

    ${AvatarWrapper} {
      margin-right: -0.5rem;
    }
  `}
`

export const More = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primaryText};
  font-weight: ${({ theme }) => theme.font.semiBold};
  font-family: ${({ theme }) => theme.font.nunito};
`

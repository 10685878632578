import { useState } from 'react'

import { FormStep } from '@/components/FormStep'
import FormStepProvider from '@/components/FormStep/Context'
import { CardType } from '@/types/payment.types'
import { Campaign, CampaignList } from '@/types/super-partner.types'

import { PositionForm } from './PositionForm'

export type PositionStepProps = {
  close: (success?: boolean) => void
  paymentMethod: CardType | null
  campaignItem: CampaignList
  campaign: Campaign
}

export type BeforeType = {
  active: boolean
  field: string
}

export const PositionSteps = ({
  campaign,
  campaignItem,
  close,
  paymentMethod,
}: PositionStepProps) => {
  const [activeStep, setActiveStep] = useState(0)
  const [before, setBefore] = useState<BeforeType>({
    active: false,
    field: '',
  })

  const nextFormStep = () => {
    setBefore({ active: false, field: '' })
    setActiveStep((currentStep) => currentStep + 1)
  }

  return (
    <FormStepProvider>
      <FormStep
        steps={[
          {
            position: (
              <PositionForm
                campaignItem={campaignItem}
                campaign={campaign}
                close={close}
                onNextStep={nextFormStep}
                before={before}
                paymentMethod={paymentMethod}
                active={activeStep === 0}
              />
            ),
          },
        ]}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    </FormStepProvider>
  )
}

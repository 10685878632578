import { createGlobalStyle, css } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  ${({ theme }) => css`
    html,
    body,
    #__next {
      height: 100%;
    }

    body {
      font-family: ${theme.font.quicksand};
      font-size: ${theme.font.sizes.medium};
      background-color: ${theme.colors.mainBg};
      color: ${theme.colors.body};
      -webkit-font-smoothing: antialiased;
    }
  `}
`

export default GlobalStyles

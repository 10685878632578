import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { Box } from '@/components/Box'

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacings.medium} ${theme.spacings.small};
    margin-bottom: ${theme.spacings.small};
  `}
`

export const MenuForm = styled.div`
  display: flex;

  ${media.lessThan('medium')`
    flex-direction: column;

    ${Box} {
      width: 100%;
    }
  `}
`

export const Categories = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const TextError = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.red};
    font-size: ${theme.font.sizes.xsmall};
  `}
`

export const SearchWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.small};
  `}
`

import { Box } from '@/components/Box'
import Skeleton from '@/components/Skeleton'

import * as S from './styles'

export const FormLoading = () => (
  <S.Wrapper>
    <Box flex JustifyContent="center" style={{ marginBottom: '1.5rem' }}>
      <Skeleton width="6.938rem" height="6.938rem" circle />
    </Box>

    <S.FormList>
      <S.FormListItem>
        <S.FormListItemTitle>
          <Skeleton width="6.25rem" />
        </S.FormListItemTitle>
        <Skeleton width="12.5rem" />
      </S.FormListItem>
      <S.FormListItem>
        <S.FormListItemTitle>
          <Skeleton width="6.25rem" />
        </S.FormListItemTitle>
        <Skeleton width="12.5rem" />
      </S.FormListItem>
      <S.FormListItem>
        <S.FormListItemTitle>
          <Skeleton width="6.25rem" />
        </S.FormListItemTitle>
        <Skeleton width="12.5rem" />
      </S.FormListItem>
      <S.FormListItem>
        <S.FormListItemTitle>
          <Skeleton width="6.25rem" />
        </S.FormListItemTitle>
        <Skeleton width="12.5rem" />
      </S.FormListItem>
      <S.FormListItem>
        <S.FormListItemTitle>
          <Skeleton width="6.25rem" />
        </S.FormListItemTitle>
        <Skeleton width="12.5rem" />
      </S.FormListItem>
      <S.FormListItem>
        <S.FormListItemTitle>
          <Skeleton width="6.25rem" />
        </S.FormListItemTitle>
        <Skeleton width="12.5rem" />
      </S.FormListItem>
      <S.FormListItem>
        <S.FormListItemTitle>
          <Skeleton width="6.25rem" />
        </S.FormListItemTitle>
        <Skeleton width="12.5rem" />
      </S.FormListItem>
      <S.FormListItem>
        <S.FormListItemTitle>
          <Skeleton width="6.25rem" />
        </S.FormListItemTitle>
        <Skeleton width="12.5rem" />
      </S.FormListItem>
    </S.FormList>

    <Box flex JustifyContent="center" style={{ marginBottom: '1.5rem' }}>
      <Skeleton width="100%" height="9.063rem" radius="32px" />
    </Box>
  </S.Wrapper>
)

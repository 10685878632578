import { format } from 'date-fns'
import ordinal from 'ordinal'
import { useEffect, useState } from 'react'

import {
  FieldHeader,
  FieldHeaderTitle,
  FieldWrapper,
} from '@/modals/CreatingNewCampaignModal/styles'
import { MagicVoucher } from '@/types/super-partner.types'
import { formatCurrencyWithCode } from '@/utils/formats/number'

import { PricingOptions } from '../PositionSteps/PositionForm'
import * as S from './styles'

type MonthsAmountType = {
  month: string
  amount: number
}

export type CheckoutSummaryProps = {
  position: number
  monthsSelected: string[]
  pricingOptions: PricingOptions
  magicVoucher?: MagicVoucher[]
}

export const CheckoutSummary = ({
  position,
  monthsSelected,
  pricingOptions,
  magicVoucher = [],
}: CheckoutSummaryProps) => {
  const [months, setMonths] = useState<MonthsAmountType[]>([])
  const currentMonth = format(new Date(), 'yyyy-MM')
  const isCurrentMonthSelected = monthsSelected.includes(currentMonth)

  const getTotal = () => {
    const total = months?.reduce((acc, month) => acc + month.amount, 0)
    const voucherTotal =
      magicVoucher?.reduce((acc, voucher) => acc + voucher.balance, 0) || 0

    return total > 0 ? total - voucherTotal || 0 : 0
  }

  useEffect(() => {
    if (position && pricingOptions && monthsSelected.length > 0) {
      const months = pricingOptions[position]
        .filter((item) => monthsSelected.includes(item.month))
        .map((i) => {
          const date = new Date(`${i.month}-05`)
          return {
            month: format(date, 'MMM yyyy'),
            amount: i.amount,
          }
        })
      setMonths(months)
    } else {
      setMonths([])
    }
  }, [position, monthsSelected, pricingOptions])

  return (
    <FieldWrapper style={{ padding: 0 }}>
      <FieldHeader>
        <FieldHeaderTitle>Total</FieldHeaderTitle>
      </FieldHeader>

      {months.length > 0 &&
        months.map((item, index) => (
          <S.Info key={item.month}>
            <S.Description>
              {ordinal(position)} position{' '}
              {isCurrentMonthSelected && index === 0
                ? '(current month)'
                : `for ${item.month}`}
            </S.Description>
            <S.Value>
              {formatCurrencyWithCode((item.amount || 0) / 100)}
            </S.Value>
          </S.Info>
        ))}
      {magicVoucher.length > 0 &&
        magicVoucher.map((voucher) => (
          <S.Info key={voucher.voucherId}>
            <S.Description>
              {voucher.title} ({voucher.voucherId})
            </S.Description>
            <S.Value green={1}>
              - {formatCurrencyWithCode((voucher.balance || 0) / 100)}
            </S.Value>
          </S.Info>
        ))}
      <S.Info>
        <S.TotalDescription>Total</S.TotalDescription>
        <S.TotalValue>{formatCurrencyWithCode(getTotal() / 100)}</S.TotalValue>
      </S.Info>
    </FieldWrapper>
  )
}

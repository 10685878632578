import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { Wrapper as SliderWrapper } from '@/components/Slider/styles'

type CropContainerProps = {
  contrast: number
  brightness: number
  saturate: number
  shadows: number
}

export const CropContainer = styled.div<CropContainerProps>`
  ${({ contrast, brightness, saturate, shadows }) => css`
    position: relative;
    width: 400px;
    height: 300px;

    img {
      filter: contrast(${contrast + 100}%) brightness(${brightness + 100}%)
        saturate(${saturate + 100}%) grayscale(${shadows}%);
    }

    ${media.greaterThan('medium')`
    width: 600px;
      height: 400px;
    `}
  `}
`

export const TextFrame = styled.p`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    font-weight: ${theme.font.medium};
    margin-top: 0.5rem;
    margin-bottom: ${theme.spacings.xsmall};

    ${media.greaterThan('medium')`
      margin-bottom: ${theme.spacings.small};
    `}
  `}
`

export const Controls = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.938rem;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`

export const Control = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: ${theme.spacings.xsmall};

    ${media.greaterThan('medium')`
      max-width: 12.5rem;

      ${SliderWrapper} {
        max-width: 12.5rem;
      }
    `}
  `}
`

export const ControlTitle = styled.h2`
  ${({ theme }) => css`
    font-weight: ${theme.font.medium};
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.gray};
    margin-bottom: ${theme.spacings.xsmall};
  `}
`

export const ControlValue = styled.span`
  ${({ theme }) => css`
    font-weight: ${theme.font.medium};
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.lightGray};
    margin-bottom: ${theme.spacings.xsmall};
  `}
`

import styled, { css } from 'styled-components'

export const EmptyVoucherImage = styled.div<{ bg: string }>`
  ${({ theme, bg }) => css`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 8px;
    background-color: ${theme.colors.lightGray};
    background-image: url(${bg});
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center center;
  `}
`

export const VoucherDesc = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.quicksand};
    color: ${theme.colors.gray};
    font-size: 0.938rem;
    font-weight: 400;
  `}
`

export const RightIcon = styled.img`
  margin-left: 1rem;
  height: 1rem;
`

export const VerticalLine = styled.div`
  ${({ theme }) => css`
    width: 0.063rem;
    height: 100%;
    background-color: ${theme.colors.mainBg};
    margin: 0 0.5rem;
  `};
`

export const Vouchers = styled.div`
  display: flex;
  overflow-x: auto;
`
export const Voucher = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 8px;
    margin-right: 0.5rem;
    background-color: ${theme.colors.mainBg};
    max-width: 16.875rem;
  `};
`
export const VoucherImage = styled.div<{ bg: string }>`
  width: 2.625rem;
  min-width: 2.625rem;
  height: 2.625rem;
  margin-right: 0.5rem;
  border-radius: 8px;
  background-color: #d8d8d8;
  background-image: url(${(props) => props.bg});
  background-size: cover;
  background-position: center center;
`
export const EmptyVoucher = styled.button`
  ${({ disabled = false }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: none;
    background-color: transparent;
    cursor: pointer;

    ${disabled &&
    css`
      cursor: not-allowed;
    `}
  `}
`
export const EmptyBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`
export const ButtonVoucher = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
`
export const VoucherActions = styled.div`
  display: flex;
  align-items: center;
`
export const VoucherDeleteButton = styled.button`
  ${({ disabled = false }) => css`
    background: transparent;
    border: none;
    border-radius: 4px;
    padding: 0.375rem 0.25rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    ${disabled &&
    css`
      cursor: not-allowed;
    `}

    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  `}
`

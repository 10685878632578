import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'

import menuMakerService from '@/api/menu-maker.service'
import { Box } from '@/components/Box'
import Button from '@/components/Button'
import Checkbox from '@/components/Checkbox'
import Empty from '@/components/Empty'
import List from '@/components/List'
import {
  ModalButtonClose,
  ModalFooter,
  ModalOverflow,
} from '@/components/Modal'
import TextField from '@/components/TextField'
import { ItemType, ModifierType } from '@/types/menu-maker.types'

import * as S from './styles'

export type ApplyMultipleItemsModalProps = {
  items: ItemType[]
  modifier: ModifierType | null
  assigned: string[]
  closeApplyMultipleItems: (itemsAssigned?: string[]) => void
}

type ItemsAssigned = {
  name: string
  description: string
  itemId: string
  categoriesName: string[]
  selected: boolean
}

const selectItemsAssignedSelected = (items: ItemType[], assigned: string[]) => {
  return items
    .filter((item) => item.isSellable)
    .map(({ name, itemId, description, categoriesName }) => ({
      name,
      itemId,
      description,
      categoriesName: categoriesName || [],
      selected: assigned.some((id) => id === itemId),
    }))
}

const ApplyMultipleItemsModal = ({
  items,
  modifier,
  assigned,
  closeApplyMultipleItems,
}: ApplyMultipleItemsModalProps) => {
  const [itemsAssigned, setItemsAssigned] = useState<ItemsAssigned[]>(
    selectItemsAssignedSelected(items, assigned),
  )
  const [filteredItemsAssigned, setFilteredItemsAssigned] =
    useState<ItemsAssigned[]>(itemsAssigned)
  const [filterText, setFilterText] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleFilterChange = (
    search: string,
    itemsAssigned: ItemsAssigned[],
  ) => {
    if (search) {
      let itemsAssignedFiltered = []
      if (search.startsWith('#')) {
        itemsAssignedFiltered = itemsAssigned.filter(({ categoriesName }) => {
          const catName = categoriesName.filter((cat) =>
            cat.toLowerCase().includes(search.replace('#', '').toLowerCase()),
          )
          return catName.length > 0
        })
      } else {
        itemsAssignedFiltered = itemsAssigned.filter(
          ({ name }) =>
            name && name.toLowerCase().includes(search.toLowerCase()),
        )
      }
      setFilteredItemsAssigned([...itemsAssignedFiltered])
    } else {
      setFilteredItemsAssigned(itemsAssigned)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(_.debounce(handleFilterChange, 300), [])

  const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setFilterText(value)
  }

  const handleSelectApply = useCallback(
    (item: ItemsAssigned) => {
      const index = itemsAssigned.findIndex((i) => i.itemId === item.itemId)
      const newItem = [...itemsAssigned]
      newItem[index] = {
        ...item,
        selected: !item.selected,
      }
      setItemsAssigned(newItem)
    },
    [itemsAssigned],
  )

  const handleSave = useCallback(async () => {
    setIsLoading(true)
    const itemIds = itemsAssigned
      .filter((item) => item.selected)
      .map((item) => item.itemId)

    try {
      await menuMakerService.saveApplyModifierItems({
        modifier: modifier!,
        itemIds,
      })
      closeApplyMultipleItems(itemIds)
    } finally {
      setIsLoading(false)
    }
  }, [closeApplyMultipleItems, itemsAssigned, modifier])

  useEffect(() => {
    if (itemsAssigned.length > 0) {
      debouncedOnChange(filterText, itemsAssigned)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, itemsAssigned])

  return (
    <>
      <ModalOverflow>
        <S.SearchWrapper>
          <TextField
            type="text"
            name="search_menu"
            placeholder="Search by name"
            disabled={isLoading || itemsAssigned.length === 0}
            value={filterText}
            iconPosition="right"
            onChange={handleChangeFilter}
            variant="material"
          />
        </S.SearchWrapper>

        <S.Content>
          {filteredItemsAssigned.length === 0 ? (
            <Box
              flex
              JustifyContent="center"
              alignItems="center"
              width="100%"
              height="12.5rem"
            >
              <Empty title="There is nothing here, yet" />
            </Box>
          ) : (
            <List<ItemsAssigned> data={filteredItemsAssigned}>
              {(item, index) => (
                <>
                  <Box flex flexDirection="column" width="95%">
                    <S.Name>{item.name}</S.Name>
                    <S.Description>{item.description}</S.Description>
                  </Box>

                  <Checkbox
                    checked={item.selected}
                    id={`check_${index}`}
                    disabled={isLoading}
                    onCheck={() => handleSelectApply(item)}
                  />
                </>
              )}
            </List>
          )}
        </S.Content>
      </ModalOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
          style={{ marginTop: '1rem' }}
        >
          <ModalButtonClose
            color="white"
            size="large"
            onClick={() => closeApplyMultipleItems()}
            shadow
          >
            Cancel
          </ModalButtonClose>
          <span />
          <Button
            size="large"
            onClick={handleSave}
            disabled={isLoading}
            loading={isLoading}
          >
            Save
          </Button>
        </Box>
      </ModalFooter>
    </>
  )
}

export default ApplyMultipleItemsModal

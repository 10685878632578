import styled, { css, keyframes } from 'styled-components'
import media from 'styled-media-query'

const flickerAnimation = keyframes`
  0%   { opacity: 1; }
  50%  { opacity: 0; }
  100% { opacity: 1; }
`

export const Wrapper = styled.ul`
  ${({ theme }) => css`
    list-style: none;

    ${media.lessThan('large')`
      width: 100%;
      z-index: ${theme.layers.menu};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100vh;
      gap: 0.625rem;
    `}

    ${media.lessThan('medium')`
      gap: 0;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      bottom: 0;
      width: calc(100% + 20px);
      height: auto;
      margin-top: 0.625rem;
      box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.1);
      z-index: ${theme.layers.menu};
      margin-left: -0.625rem;
    `}
  `}
`

export const MenuItem = styled.li`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 7.938rem;
      height: 6.5rem;
      text-decoration: none;
      cursor: pointer;
      border-top-left-radius: ${theme.border.radius.large};
      border-bottom-left-radius: ${theme.border.radius.large};
      transition: all ${theme.transition.default};
      position: relative;

      ${media.lessThan('large')`
        height: 4.625rem;
      `}

      &:hover {
        background-color: ${theme.colors.secondaryBg};

        span {
          color: ${theme.colors.primary};
        }
      }

      &.active {
        background-color: ${theme.colors.white};

        span {
          color: ${theme.colors.primary};
        }

        &:hover {
          background-color: ${theme.colors.white};
        }
      }

      ${media.lessThan('large')`
        height: inherit;
        min-width: 4.5rem;
        width: 100%;
        border-radius: 0;
        border-right: 2px solid transparent;
        background-color: ${
          theme.dark ? theme.colors.secondary : theme.colors.mainBg
        };

        &.active {
          border-right-color: ${theme.colors.primary};
          background-color: ${theme.colors.white};

          &:hover {
            border-right-color: ${theme.colors.primary};
          }
        }
      `}

      ${media.lessThan('medium')`
        min-width: 2rem;
        width: 100%;
        height: 4.75rem;
        border-radius: 0;
        border-right: 0 solid transparent;
        border-bottom: 2px solid transparent;
        background-color: ${
          theme.dark ? theme.colors.secondary : theme.colors.mainBg
        };

        &.active {
          border-bottom-color: ${theme.colors.primary};
          background-color: ${theme.colors.white};

          &:hover {
            border-bottom-color: ${theme.colors.primary};
          }
        }
      `}
    }
  `}
`

export const Label = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxsmall};
    font-weight: ${theme.font.semiBold};
    color: ${theme.colors.body};
    margin-top: 0.125rem;
    text-align: center;
  `}
`

export const LiveBall = styled.span`
  ${({ theme }) => css`
    width: 0.375rem;
    height: 0.375rem;
    background-color: ${theme.colors.green};
    border-radius: 50%;
    display: inline-block;
    animation: ${flickerAnimation} 2s infinite;
  `}
`

export interface InfoProps {
  hasCreated: boolean
}

export const Info = styled.div<InfoProps>`
  ${({ theme, hasCreated }) => css`
    min-width: 18px;
    height: 18px;
    padding: 0 4px;
    border-radius: 9px;
    border: 1px solid ${theme.colors.mainBg};
    background: ${theme.colors.primary};
    color: ${theme.colors.primaryText};
    font-family: ${theme.font.quicksand};
    text-transform: capitalize;
    font-size: ${theme.font.sizes.xxsmall};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: calc(50% - 23px);
    left: calc(50% + 3px);

    transform: scale(1);
    animation: ${hasCreated ? 'pulseSC 2s infinite' : ''};

    @keyframes pulseSC {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 76, 76, 0.7);
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 76, 76, 0);
      }

      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 76, 76, 0, 0);
      }
    }
  `}
`

import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
`

export const FormCard = styled.div`
  padding-top: 1.563rem;
`

export const FormRow = styled.div`
  ${({ theme }) => css`
    margin-bottom: 2rem;

    input {
      &::placeholder {
        color: ${theme.colors.gray};
      }
    }
  `}
`

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.938rem;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 1.5rem;
`

export const PaymentTitle = styled.h2`
  ${({ theme }) => css`
    font-family: ${theme.font.nunito};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.secondary};
    margin-bottom: ${theme.spacings.small};
  `}
`

import ReactSlider, { ReactSliderProps } from 'react-slider'

import * as S from './styles'

export type SliderProps = ReactSliderProps

const Slider = ({ ...props }: SliderProps) => (
  <S.Wrapper>
    <ReactSlider {...props} />
  </S.Wrapper>
)

export default Slider

import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { InputWrapper } from '@/components/TextField/styles'

export const FormControl = styled.div`
  ${({ theme }) => css`
    margin: 0 ${theme.spacings.small} 0 0;

    ${InputWrapper} {
      width: 40%;

      ${media.lessThan('medium')`
        width: 100%;
      `}
    }
  `}
`

export const TextNotice = styled.p`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.small};
    color: ${theme.colors.supportGray};
  `}
`

type SorterMode = 'ASC' | 'DESC'

export const SortAlphabetically =
  <T>(fieldName?: keyof T, mode: SorterMode = 'ASC') =>
  (a: T, b: T) => {
    let aa = ''
    let bb = ''
    if (!fieldName) {
      aa = String(a)
      bb = String(b)
    } else {
      aa = String(a[fieldName]) || ''
      bb = String(b[fieldName]) || ''
    }

    if (mode === 'ASC') {
      return aa.toLocaleLowerCase() < bb.toLocaleLowerCase() ? -1 : 1
    }

    return aa.toLocaleLowerCase() < bb.toLocaleLowerCase() ? 1 : -1
  }

export const SorterNumerically =
  <T>(fieldName?: keyof T, mode: SorterMode = 'ASC') =>
  (a: T, b: T) => {
    let aa = 0
    let bb = 0
    if (!fieldName) {
      aa = Number(a)
      bb = Number(b)
    } else {
      aa = Number(a[fieldName])
      bb = Number(b[fieldName])
    }

    if (mode === 'ASC') {
      return aa - bb
    }

    return bb - aa
  }

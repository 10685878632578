import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts'

import { Box } from '@/components/Box'
import Empty from '@/components/Empty'
import { VisitsType } from '@/types/super-partner.types'

import * as S from './styles'

type CustomTooltipProps = {
  active: boolean
  payload: Array<{ name: string; value: string }>
  label: string
}

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <S.Tooltip>
        <h4>{label}</h4>
        <p>Views: {payload[0].value}</p>
      </S.Tooltip>
    )
  }

  return null
}

export type CampaignViewsChartProps = {
  data: VisitsType[]
  title: string
  height?: string
}

const CampaignViewsChart = ({
  data,
  title,
  height,
}: CampaignViewsChartProps) => (
  <S.Wrapper height={height}>
    <S.Title>{title}</S.Title>
    <S.Subtitle>
      {data.reduce((total, item) => total + item.visits, 0)}
    </S.Subtitle>

    {data.length === 0 ? (
      <Box flex JustifyContent="center" alignItems="center" height="100%">
        <Empty title="There is nothing here, yet" />
      </Box>
    ) : (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data} margin={{ right: 20, left: 20, top: 20 }}>
          <defs>
            <linearGradient id="campaignViewsColor" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#ff4c4c" stopOpacity={1} />
              <stop offset="95%" stopColor="#fba140" stopOpacity={1} />
            </linearGradient>
          </defs>
          <CartesianGrid horizontal={false} strokeDasharray="5" />
          <XAxis dataKey="date" strokeWidth="0" fontSize="0.75rem" />
          <Tooltip
            cursor={{ stroke: '#ff4c4c', strokeDasharray: '5' }}
            content={<CustomTooltip {...({} as CustomTooltipProps)} />}
          />
          <Line
            type="monotone"
            dataKey="visits"
            stroke="url(#campaignViewsColor)"
            strokeLinejoin="round"
            strokeWidth={4}
            unit={3}
            dot={{
              stroke: '#fba240',
              strokeWidth: 1.2,
              r: 5,
            }}
            activeDot={{
              stroke: '#ff4c4c',
              strokeWidth: 1.2,
              r: 5,
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    )}
  </S.Wrapper>
)

export default CampaignViewsChart

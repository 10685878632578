import { useState } from 'react'

import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { ModalFooter, ModalOverflow } from '@/components/Modal'
import TextArea from '@/components/TextArea'

import * as S from './styles'

export type EditLocationInstructionsModalProps = {
  instructions: string
  closeEditLocalInstructions: (instructions: string) => void
  loading: boolean
}

const EditLocationInstructionsModal = ({
  closeEditLocalInstructions,
  instructions,
  loading,
}: EditLocationInstructionsModalProps) => {
  const [newInstructions, setNewInstructions] = useState(instructions)

  return (
    <>
      <ModalOverflow>
        <S.Content>
          <S.MenuForm>
            <Box flex flexDirection="column">
              <TextArea
                label="Local Instructions:"
                labelFor="instructions"
                id="instructions"
                value={newInstructions}
                onChange={(e) => {
                  setNewInstructions(e.target.value)
                }}
                style={{ width: '18.75rem' }}
              />
            </Box>
          </S.MenuForm>
        </S.Content>
      </ModalOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
          style={{ marginTop: '1rem' }}
        >
          <Button
            color="white"
            size="large"
            onClick={() => closeEditLocalInstructions(instructions)}
            shadow
          >
            Cancel
          </Button>

          <Button
            size="large"
            onClick={() => {
              closeEditLocalInstructions(newInstructions)
            }}
            style={{ marginLeft: 'auto' }}
            loading={loading}
          >
            Save
          </Button>
        </Box>
      </ModalFooter>
    </>
  )
}

export default EditLocationInstructionsModal

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, DialogContent } from '@iguanads/react'
import { Exposure } from '@styled-icons/material-rounded'
import { endOfDay, format, startOfDay } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useMediaQuery } from 'react-responsive'
import ReactTooltip from 'react-tooltip'
import { useTheme } from 'styled-components'
import { titleCase } from 'title-case'

import configsToStorageService from '@/api/configs-to-storage.service'
import dashboardService from '@/api/dashboard.service'
import financesViewIcon from '@/assets/img/icons-finances-view.svg'
import inputOrderIcon from '@/assets/img/icons-input-order.svg'
import performanceDelivery from '@/assets/img/icons-performance-delivery.svg'
import performanceDeliveryLight from '@/assets/img/icons-performance-delivery-light.svg'
import performanceTakeout from '@/assets/img/icons-performance-takeout.svg'
import performanceTakeoutLight from '@/assets/img/icons-performance-takeout-light.svg'
import { Box } from '@/components/Box'
import CardContent from '@/components/CardContent'
import DateRangePicker from '@/components/DateRangePicker'
import Empty from '@/components/Empty'
import Heading from '@/components/Heading'
import InfoValue from '@/components/InfoValue'
import MessagingContainer from '@/components/Messaging'
import { Modal, ModalContent } from '@/components/Modal'
import Skeleton from '@/components/Skeleton'
import SlideCharts from '@/components/SlideCharts'
import Table, {
  getColorModifier,
  statusModifiers,
  TypeEnum,
} from '@/components/Table'
import * as T from '@/components/Table/styles'
import { OrderDetailDialog } from '@/dialogs/order-detail.dialog'
import { OrderStatusEnum } from '@/enums/order.enum'
import { useDialog } from '@/hooks/use-dialog'
import useAudio from '@/hooks/useAudio'
import useAuth from '@/hooks/useAuth'
import useModal from '@/hooks/useModal'
import { db } from '@/lib/firebase'
import { AdditionsDeductionsModal } from '@/modals/AdditionsDeductionsModal'
import { DashboardType } from '@/types/dashboard.types'
import { OrderType } from '@/types/orders.types'
import { formatDistance, formatDistanceToNow } from '@/utils/formats/date'
import deliveryTakeoutFormat from '@/utils/formats/deliveryTakeout'
import {
  formatCurrency,
  formatCurrencyWithCode,
  formatNumber,
  formatPercentage,
} from '@/utils/formats/number'

import packageJson from '../../../../package.json'
import * as S from './styles'

const status = [
  OrderStatusEnum.CREATED,
  OrderStatusEnum.IN_PROGRESS,
  OrderStatusEnum.READY,
  OrderStatusEnum.IN_TRANSIT,
]

export function Dashboard() {
  const [orderDetailData, setOrderDetailData] = useState<OrderType>()
  const [dashData, setDashData] = useState<DashboardType | null>(null)
  const [orders, setOrders] = useState<OrderType[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const theme = useTheme()
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    new Date(),
    new Date(),
  ])
  const [initialSlide, setInitialSlide] = useState(
    configsToStorageService.getSlidePosition(),
  )
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  })
  const { open: orderDetailModalIsOpen, toggle: setOrderDetailModalIsOpen } =
    useDialog()
  const { open: addDeduModalIsOpen, toggle: setAddDeduModalIsOpen } = useModal()
  const { merchantsSelected, timezone } = useAuth()
  const { playing, play, pause, canPlay } = useAudio()
  let dataOrders: OrderType[] = []

  const getMerchantDowntime = useCallback(
    async (merchantId: string, start: string, end: string) => {
      try {
        const { data } = await dashboardService.getMerchantDowntime(
          merchantId,
          {
            start,
            end,
          },
        )
        return data
      } catch (error) {}
    },
    [],
  )

  const getDashboard = useCallback(
    async (ids: string[], [start, end]: [Date, Date]) => {
      setIsLoading(true)
      setHasError(false)

      const startDate = utcToZonedTime(start, timezone)
      const endDate = utcToZonedTime(end, timezone)

      try {
        const startISOString = startOfDay(startDate).toISOString()
        const endISOString = endOfDay(endDate).toISOString()

        const { data: responseData }: any =
          await dashboardService.multipleMerchantsDashboard(
            {
              start: startISOString,
              end: endISOString,
            },
            ids,
          )
        const merchantDowntime = await getMerchantDowntime(
          ids[0],
          startISOString,
          endISOString,
        )

        const dashboardData: DashboardType[] = responseData.map(
          (item: { data: any }) => ({
            ...item.data,
          }),
        )

        const data = dashboardData.reduce(
          (total, item) => {
            return {
              views: [...total.views, ...item.views],
              visitedItems: [...total.visitedItems, ...item.visitedItems],
              orders: [...total.orders, ...item.orders],
              soldItems: [...total.soldItems, ...item.soldItems],
              lastDailyOrdersUpdate: item.lastDailyOrdersUpdate,
              deliveryOrdersPerDay: [
                ...total.deliveryOrdersPerDay,
                ...item.deliveryOrdersPerDay,
              ],
              takeoutOrdersPerDay: [
                ...total.takeoutOrdersPerDay,
                ...item.takeoutOrdersPerDay,
              ],
              takeoutDelivery: [
                ...total.takeoutDelivery,
                ...(item.takeoutDelivery || []),
              ],
              totalSales: {
                delivery: total.totalSales.delivery + item.totalSales.delivery,
                delivery_fees_merchant:
                  total.totalSales.delivery_fees_merchant +
                  item.totalSales.delivery_fees_merchant,
                merchant_voucher:
                  total.totalSales.merchant_voucher +
                  item.totalSales.merchant_voucher,
                netSales: total.totalSales.netSales + item.totalSales.netSales,
                refunds: total.totalSales.refunds + item.totalSales.refunds,
                sales: total.totalSales.sales + item.totalSales.sales,
                takeout: total.totalSales.takeout + item.totalSales.takeout,
                tips_merchant_delivery:
                  total.totalSales.tips_merchant_delivery +
                  item.totalSales.tips_merchant_delivery,
                tips_takeout:
                  total.totalSales.tips_takeout + item.totalSales.tips_takeout,
              },
              menuViews: total.menuViews + item.menuViews,
              totalOrders: total.totalOrders + item.totalOrders,
              conversion: total.conversion + item.conversion,
              ticketAverage: total.ticketAverage + item.ticketAverage,
              downtime: total.downtime + item.downtime,
              balance: [...total.balance, ...(item.balance || [])],
            }
          },
          {
            views: [],
            visitedItems: [],
            orders: [],
            soldItems: [],
            lastDailyOrdersUpdate: '',
            deliveryOrdersPerDay: [],
            takeoutOrdersPerDay: [],
            takeoutDelivery: [],
            totalSales: {
              delivery: 0,
              delivery_fees_merchant: 0,
              merchant_voucher: 0,
              netSales: 0,
              refunds: 0,
              sales: 0,
              takeout: 0,
              tips_merchant_delivery: 0,
              tips_takeout: 0,
            },
            menuViews: 0,
            totalOrders: 0,
            conversion: 0,
            ticketAverage: 0,
            downtime: 0,
            balance: [],
          },
        )

        const newDashData = {
          ...data,
          soldItems: data.soldItems.map((sold) => ({
            ...sold,
            item: titleCase(sold.item.toLowerCase()),
          })),
          visitedItems: data.visitedItems.map((vItem) => ({
            ...vItem,
            item: titleCase(vItem.item.toLowerCase()),
          })),
          views: data.views.map((item) => ({
            ...item,
            date: format(new Date(`${item.date}T23:59:59.000Z`), 'MMM, dd E'),
          })),
          menuViews: data.views.reduce(
            (total, item) => (total += item.count),
            0,
          ),
          totalOrders: data.orders.reduce(
            (total, item) => (total += item.count),
            0,
          ),
          takeoutDelivery: deliveryTakeoutFormat(
            data.takeoutOrdersPerDay,
            data.deliveryOrdersPerDay,
          ),
          conversion: 0,
          downtime:
            merchantDowntime?.reduce(
              (total, item) => (total += item.downtime),
              0,
            ) || 0,
        }

        setDashData({
          ...newDashData,
          conversion: (newDashData.totalOrders / newDashData.menuViews) * 100,
          ticketAverage: newDashData.totalSales.sales / newDashData.totalOrders,
        })
      } catch (error) {
        setHasError(true)
        setDashData(null)
      } finally {
        setIsLoading(false)
      }
    },
    [getMerchantDowntime, timezone],
  )

  const totalMerchantVouchers = (order: OrderType) => {
    const magicVouchers = order.magicVouchers
    const vouchers = magicVouchers?.map((voucher) => {
      if (voucher.author !== 'bento' && voucher.author === 'merchant') {
        return voucher.balance
      }
      return 0
    })

    if (vouchers && vouchers.length > 0) {
      return _.sum(vouchers) || 0
    }

    return 0
  }

  const getOrders = useCallback(
    (merchantId: string) => {
      setOrders([])
      setIsLoading(true)

      const q = query(
        collection(db, 'orders'),
        where('merchant.id', '==', merchantId),
        where('lastStatus', 'in', status),
        orderBy('createdAt', 'desc'),
      )

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const data: OrderType[] = querySnapshot.docs.map(
          (doc: any) => doc.data() as OrderType,
        )

        const hasOrder = data.some((item) => {
          return dataOrders.find((order) => order.orderId === item.orderId)
        })

        if (hasOrder) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          dataOrders = dataOrders.map((item) => {
            const order = data.find((order) => order.orderId === item.orderId)

            if (order) {
              return order
            }

            return item
          })
        } else {
          dataOrders = [...dataOrders, ...data]
        }

        const countData = data.length
        const filterOrdersByMerchant = dataOrders.filter((order) => {
          return order.merchant.id === merchantId
        })
        const countOrdersByMerchant = filterOrdersByMerchant.length

        if (countData < countOrdersByMerchant) {
          dataOrders = dataOrders.filter((item) => {
            return item.merchant.id !== merchantId
          })

          dataOrders = [...dataOrders, ...data]
        }

        const allOrdersHasOrderId = dataOrders.every((order) => order.orderId)

        if (countData > countOrdersByMerchant || !allOrdersHasOrderId) {
          const filterOrdersWithoutMerchant = dataOrders.filter((order) => {
            return order.merchant.id !== merchantId
          })

          dataOrders = [...filterOrdersWithoutMerchant, ...data]
        }

        setOrders(
          _.orderBy(dataOrders, 'createdAt', 'desc').map((item: OrderType) => ({
            ...item,
            theme,
            total:
              ((item.deliveryHandler !== 'bento' ? item.total : item.subTotal) -
                totalMerchantVouchers(item)) /
              100,
            subTotal: item.subTotal / 100,
            deliveryFee: item.deliveryFee ? item.deliveryFee / 100 : null,
            tip: item.tip
              ? {
                  orderId: item.shortId,
                  amount: item.tip.amount / 100,
                }
              : null,
            magicVouchers: item.magicVouchers
              ? item.magicVouchers.map((voucher) => ({
                  ...voucher,
                  balance: voucher?.balance / 100,
                }))
              : [],
            refundAmount: item.refundAmount ? item.refundAmount / 100 : null,
            items: item.items?.map((child) => ({
              ...child,
              price: child.price / 100,
              modifiers: child.modifiers?.map((modifier) => ({
                ...modifier,
                options: modifier.options.map((option) => ({
                  ...option,
                  price: option.price / 100,
                })),
              })),
            })),
          })),
        )
      })

      return unsubscribe
    },
    [theme, dataOrders],
  )

  const handleDate = useCallback(
    (ranges: [Date, Date]) => {
      const [start, end] = ranges
      const isNotEqualStart = dateRange[0] !== start
      const isNotEqualEnd = dateRange[1] !== end

      if (isNotEqualStart && isNotEqualEnd && merchantsSelected) {
        setDateRange(ranges)
        const merchants = merchantsSelected.map((item) => item.id)
        getDashboard(merchants, ranges)
      }
    },
    [dateRange, getDashboard, merchantsSelected],
  )

  const allChartsAreEmpty = useCallback(() => {
    if (dashData) {
      const soldCount = dashData?.soldItems.length
      const takeDelyCount = dashData?.takeoutDelivery.reduce(
        (total, item) => (total += item.takeout + item.delivery),
        0,
      )
      const viewsCount = dashData?.visitedItems.length

      return !dashData?.menuViews && !soldCount && !takeDelyCount && !viewsCount
    }
  }, [dashData])

  const openOrderDetail = (orderDetail: any) => {
    setOrderDetailData(orderDetail)
    pause()
    setOrderDetailModalIsOpen(true)
  }

  const closeOrderDetail = () => {
    setOrderDetailModalIsOpen(false)
  }

  const renderOpenDetailModal = () => {
    if (!orderDetailData) return
    return (
      <Dialog
        open={orderDetailModalIsOpen}
        onOpenChange={setOrderDetailModalIsOpen}
      >
        <DialogContent
          hideCloseButton
          onClose={() => setOrderDetailModalIsOpen(false)}
        >
          <OrderDetailDialog
            order={orderDetailData}
            onClose={closeOrderDetail}
            setOrder={setOrderDetailData}
          />
        </DialogContent>
      </Dialog>
    )
  }

  const renderAdditionsDeductionsModal = () => {
    return (
      <Modal
        open={addDeduModalIsOpen}
        onOpenChange={setAddDeduModalIsOpen}
        modal
      >
        <ModalContent
          title="Additions & Deductions"
          onClose={() => setAddDeduModalIsOpen(false)}
        >
          <AdditionsDeductionsModal
            data={dashData?.balance || []}
            close={() => setAddDeduModalIsOpen(false)}
          />
        </ModalContent>
      </Modal>
    )
  }

  const handleChangeSlider = (index: number) => {
    configsToStorageService.saveSlidePosition(index)
    setInitialSlide(index)
  }

  const setOrderRead = useCallback(async (readOrders: OrderType[]) => {
    if (readOrders.length > 0) {
      const calls: Promise<any>[] = []
      readOrders.forEach(({ orderId }) => {
        if (orderId) {
          calls.push(dashboardService.setOrderRead(orderId))
        }
      })
      if (calls.length > 0) {
        await Promise.all(calls)
      }
    }
  }, [])

  const saveTabletStatus = useCallback(async () => {
    if (merchantsSelected.length > 0) {
      let battery: number | undefined
      const newNavigator: any = navigator
      const version = packageJson.version

      if ('getBattery' in newNavigator) {
        const batteryPromise = await newNavigator.getBattery()
        battery = Number((batteryPromise.level * 100).toFixed(0))
      }

      if (battery) {
        try {
          const merchants = merchantsSelected.map((item) => item.id)
          await dashboardService.multipleMerchantsSaveTabletStatus(
            merchants,
            battery,
            version,
            configsToStorageService.getMessagingToken(),
          )
        } catch (error) {}
      }
    }
  }, [merchantsSelected])

  const renderSubtitle = (dashData: DashboardType | null) => (
    <>
      Last full update:{' '}
      {dashData?.lastDailyOrdersUpdate
        ? format(
            new Date(dashData.lastDailyOrdersUpdate),
            "MMM dd 'at' HH:mm a",
          )
        : '-'}
      <br />
      {isLoading ? (
        <Skeleton width="120px" radius="0.25rem" />
      ) : (
        <S.DowntimeLabel data-tip="Downtime is how long you had to stop orders being placed via Bento (availability set to OFF)">
          Downtime:{' '}
          <b>
            {dashData?.downtime
              ? formatDistance(dashData?.downtime)
              : '0 minute'}
          </b>
        </S.DowntimeLabel>
      )}
    </>
  )

  const columns = useMemo(
    () => [
      {
        name: 'Mode',
        selector: (row: OrderType) => row.type,
        sortable: true,
        center: true,
        width: '40px',
        cell: (row: OrderType) => {
          if (theme.light)
            return (
              <img
                data-tag="allowRowEvents"
                src={
                  row.type === TypeEnum.DELIVERY
                    ? performanceDelivery
                    : performanceTakeout
                }
                width={32}
                height={32}
                alt={row.type}
              />
            )
          else {
            return (
              <img
                data-tag="allowRowEvents"
                src={
                  row.type === TypeEnum.DELIVERY
                    ? performanceDeliveryLight
                    : performanceTakeoutLight
                }
                width={32}
                height={32}
                alt={row.type}
              />
            )
          }
        },
      },
      {
        name: 'Customer',
        selector: (row: OrderType) => row.user.name,
        sortable: true,
        center: true,
        minWidth: '146px',
        cell: (row: OrderType) => (
          <T.Client>
            <T.ClientName data-tag="allowRowEvents">
              {row.user.name}
            </T.ClientName>
            <T.ClientShortId data-tag="allowRowEvents">
              {row.shortId}
            </T.ClientShortId>
          </T.Client>
        ),
      },
      {
        name: 'Status',
        selector: (row: OrderType) => row.lastStatus,
        sortable: true,
        center: true,
        minWidth: '85px',
        cell: (row: OrderType) => {
          const isCollected =
            row.driver && row.driver.tripStatus === 'collected'
          if (row.trip && isCollected) {
            return (
              <T.StatusWrapper
                data-tag="allowRowEvents"
                bgColor={getColorModifier('collected', theme)}
                style={{ color: theme.colors.secondary }}
              >
                {statusModifiers.collected}
              </T.StatusWrapper>
            )
          }

          return (
            <T.StatusWrapper
              data-tag="allowRowEvents"
              bgColor={getColorModifier(row.lastStatus, theme)}
            >
              {statusModifiers[row.lastStatus]}
            </T.StatusWrapper>
          )
        },
      },
      {
        name: 'Value',
        selector: (row: OrderType) => row.total,
        sortable: true,
        center: true,
        minWidth: '110px',
        cell: (row: OrderType) => {
          return (
            <T.CurrencyWrapper data-tag="allowRowEvents">
              {formatCurrencyWithCode(row.total)}
            </T.CurrencyWrapper>
          )
        },
      },
      {
        name: 'Date',
        selector: (row: OrderType) => row.createdAt,
        sortable: true,
        center: true,
        id: 'createdAt',
        minWidth: '118px',
        cell: (row: OrderType) => (
          <T.DateWrapper data-tag="allowRowEvents">
            {formatDistanceToNow(new Date(row.createdAt), true, timezone)}
          </T.DateWrapper>
        ),
      },
    ],
    [theme, timezone],
  )

  useEffect(() => {
    const unsubscribe: Array<() => void> = []

    if (merchantsSelected) {
      const merchants = merchantsSelected.map((item) => item.id)
      getDashboard(merchants, dateRange)
      merchants.forEach((merchantId) => {
        const unsub = getOrders(merchantId)
        unsubscribe.push(unsub)
      })
    }

    return () => {
      unsubscribe.forEach((unsub) => {
        if (typeof unsub === 'function') {
          unsub()
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, getDashboard, merchantsSelected])

  useEffect(() => {
    if (dateRange.length === 2) {
      configsToStorageService.saveDate(dateRange)
    }
  }, [dateRange])

  useEffect(() => {
    if (orders.length > 0 && !orderDetailModalIsOpen) {
      const hasNewOrders = orders.some(
        (order) => order.lastStatus === 'created',
      )

      if (!hasNewOrders && playing) {
        pause()
      }

      if (hasNewOrders && canPlay) {
        play()
      }
    }

    return () => pause()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, canPlay, orderDetailModalIsOpen, playing])

  useEffect(() => {
    if (orders.length > 0) {
      const readOrders = orders.filter((order) => !order.isRead)

      if (readOrders.length > 0) {
        setOrderRead(readOrders)
      }
    }
  }, [orders, setOrderRead])

  useEffect(() => {
    saveTabletStatus()
    const handle = setInterval(
      () => {
        saveTabletStatus()
      },
      10 * 60 * 1000,
    )

    return () => clearInterval(handle)
  }, [saveTabletStatus])

  useEffect(() => {
    if (orders.length > 0 && !orderDetailModalIsOpen) {
      const hasNewOrders = orders.some(
        (order) => order.lastStatus === 'created',
      )

      if (hasNewOrders && !playing && canPlay) {
        play()
      }
    }

    return () => pause()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, orderDetailModalIsOpen])

  useEffect(() => {
    const createdAtInterval = setInterval(() => {
      setOrders((old) => [
        ...old.map((order) => ({
          ...order,
          createdAt: order.createdAt,
        })),
      ])
    }, 60000)
    return () => clearInterval(createdAtInterval)
  }, [])

  return (
    <>
      <Helmet title="Dashboard" />
      <S.Wrapper>
        <S.HeaderContent>
          <S.Header isDate>
            <Heading
              title="Period:"
              titleAppend={`${format(
                new Date(dateRange[0]),
                'MMM d, yyyy',
              )} to ${format(new Date(dateRange[1]), 'MMM d, yyyy')}`}
              subtitle={renderSubtitle(dashData)}
            />

            <S.DateWrapper>
              <DateRangePicker
                label="Choose period"
                labelFor="period"
                start={dateRange[0]}
                end={dateRange[1]}
                onSelectDate={handleDate}
                position={isMobile ? 'center' : 'right'}
                loading={isLoading}
              />
            </S.DateWrapper>
          </S.Header>

          <S.ContentCharts>
            <Box
              flex
              minWidth="13rem"
              maxWidth="15.5rem"
              width="100%"
              flexDirection="column"
            >
              {isLoading ? (
                <Skeleton height="14.25rem" radius="24px" />
              ) : (
                <CardContent color="primary" style={{ position: 'relative' }}>
                  {dashData?.balance && dashData.balance.length > 0 && (
                    <S.ButtonIcon
                      icon={<Exposure />}
                      color="white"
                      onClick={() => setAddDeduModalIsOpen(true)}
                    />
                  )}
                  <InfoValue
                    info="Sales"
                    value={`${
                      dashData?.totalSales.sales
                        ? formatCurrency(dashData.totalSales.sales)
                        : '-'
                    }`}
                    currency={
                      !hasError && dashData?.totalSales.sales ? 'KYD' : ''
                    }
                  />
                  <InfoValue
                    info="Net Sales"
                    value={`${
                      dashData?.totalSales.netSales
                        ? formatCurrency(dashData.totalSales.netSales)
                        : '-'
                    }`}
                    currency={
                      !hasError && dashData?.totalSales.netSales ? 'KYD' : ''
                    }
                  />
                </CardContent>
              )}

              {isLoading ? (
                <Skeleton height="14.25rem" radius="24px" />
              ) : (
                <CardContent style={{ paddingTop: '1.5rem' }}>
                  <InfoValue
                    info="Menu Views"
                    value={`${
                      dashData?.menuViews
                        ? formatNumber(dashData.menuViews)
                        : '-'
                    }`}
                  />
                  <InfoValue
                    info="Total Orders"
                    value={`${
                      dashData?.totalOrders
                        ? formatNumber(dashData.totalOrders)
                        : '-'
                    }`}
                  />
                  <S.Conversion>
                    Conversion{' '}
                    <strong>
                      {dashData?.conversion
                        ? `${formatPercentage(
                            Number(dashData.conversion.toFixed(2)) / 100,
                          )}`
                        : '-'}
                    </strong>
                  </S.Conversion>
                </CardContent>
              )}

              {isLoading ? (
                <Skeleton height="14.25rem" radius="24px" />
              ) : (
                <CardContent>
                  <CardContent
                    color="primary"
                    maxHeight="4.625rem"
                    maxWidth="4.625rem"
                    style={{ padding: '1.125rem' }}
                  >
                    <img
                      src={financesViewIcon}
                      width={40}
                      height={40}
                      alt="Icon finances view"
                    />
                  </CardContent>
                  <InfoValue
                    info="Ticket Average"
                    value={`${
                      dashData?.ticketAverage
                        ? formatCurrency(dashData.ticketAverage)
                        : '-'
                    }`}
                    currency={!hasError && dashData?.ticketAverage ? 'KYD' : ''}
                  />
                </CardContent>
              )}
            </Box>

            <Box flex flexDirection="column" width="100%" minWidth="18.75rem">
              {isLoading ? (
                <Skeleton height="43.75rem" radius="24px" />
              ) : (
                <CardContent maxHeight="auto">
                  {hasError ? (
                    <Box
                      flex
                      JustifyContent="center"
                      alignItems="center"
                      height="100%"
                    >
                      <Empty
                        title="Something Happened"
                        description="Reload the page and try again"
                      />
                    </Box>
                  ) : allChartsAreEmpty() ? (
                    <Box
                      flex
                      JustifyContent="center"
                      alignItems="center"
                      height="100%"
                    >
                      <Empty
                        title="There is nothing here, yet"
                        description="Make sure you are available to receive orders"
                      />
                    </Box>
                  ) : (
                    <SlideCharts
                      views={dashData?.views || []}
                      soldItems={dashData?.soldItems || []}
                      takeoutDelivered={dashData?.takeoutDelivery || []}
                      visitedItens={dashData?.visitedItems || []}
                      initialSlide={initialSlide}
                      afterChange={handleChangeSlider}
                      dateRange={dateRange}
                    />
                  )}
                </CardContent>
              )}
            </Box>
          </S.ContentCharts>
        </S.HeaderContent>

        <S.HeaderContent>
          <S.Header>
            <Heading title="Last Orders" subtitle="Real-time update" />
            <S.Link to="/orders" title="History">
              see history{' '}
              <img
                src={inputOrderIcon}
                width={24}
                height={24}
                alt="Input order icon"
              />
            </S.Link>
          </S.Header>

          {isLoading ? (
            <>
              <Box
                flex
                alignItems="center"
                JustifyContent="space-between"
                width="100%"
                style={{ marginBottom: '1.75rem' }}
              >
                <Skeleton
                  width="2.5rem"
                  height="0.875rem"
                  count={5}
                  radius="4px"
                  direction="row"
                />
              </Box>

              <Skeleton
                height="2.5rem"
                count={7}
                radius="4px"
                margin="2.5rem"
              />
            </>
          ) : (
            <S.ContentTable hasEmptyOrError={hasError || orders.length === 0}>
              {hasError ? (
                <Box
                  flex
                  JustifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  <Empty
                    title="Something Happened"
                    description="Reload the page and try again"
                  />
                </Box>
              ) : orders.length === 0 ? (
                <Box
                  flex
                  JustifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  <Empty
                    title="There is nothing here, yet"
                    description="Make sure you are available to receive orders"
                  />
                </Box>
              ) : (
                <>
                  <Table
                    onRowClicked={openOrderDetail}
                    defaultSortFieldId="createdAt"
                    defaultSortAsc={false}
                    columns={columns as any}
                    data={orders.slice(0, 10)}
                    conditionalRowStyles={[
                      {
                        when: (row) => row.lastStatus === 'created',
                        classNames: ['new_order'],
                      },
                    ]}
                    highlightOnHover={true}
                    pointerOnHover={true}
                  />
                  <ReactTooltip
                    effect="solid"
                    className="tooltip"
                    backgroundColor="#433d3d"
                    borderColor="#433d3d"
                  />
                </>
              )}
            </S.ContentTable>
          )}
        </S.HeaderContent>

        {renderOpenDetailModal()}
        {addDeduModalIsOpen && renderAdditionsDeductionsModal()}
        <MessagingContainer />
      </S.Wrapper>
    </>
  )
}

import { Image as ImageIcon } from '@styled-icons/material-outlined/Image'
import { format } from 'date-fns'

import inputOrderIcon from '@/assets/img/icons-input-order.svg'
import { Box } from '@/components/Box'
import { Modal, ModalContent, ModalOverflow } from '@/components/Modal'
import useModal from '@/hooks/useModal'
import { ItemType } from '@/types/menu-maker.types'
import { VoucherListType } from '@/types/super-partner.types'

import { OrdersModal } from '../CampaignDetailModal/components/OrdersModal'
import * as S from './styles'

export type VoucherDetailModalProps = {
  voucherDetailData: VoucherListType | undefined
  closeVoucherDetail: () => void
}
export const VoucherDetailModal = ({
  voucherDetailData,
  closeVoucherDetail,
}: VoucherDetailModalProps) => {
  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal()

  const renderOpenOrdersModal = () => {
    return (
      <Modal open={modalIsOpen} onOpenChange={setModalIsOpen} modal>
        <ModalContent title="" onClose={() => setModalIsOpen(false)} noHeader>
          <OrdersModal orders={voucherDetailData?.orders ?? []} />
        </ModalContent>
      </Modal>
    )
  }

  return (
    <ModalOverflow style={{ padding: 0, borderRadius: '24px' }}>
      <S.Header>
        <S.WrapperTitle>
          <S.TitleDescription>
            {voucherDetailData?.merchants[0].name} Voucher
          </S.TitleDescription>
          <S.Title>${(voucherDetailData?.balance || 0) / 100} off</S.Title>
        </S.WrapperTitle>

        <S.CloseButton onClick={() => closeVoucherDetail()}>✕</S.CloseButton>
      </S.Header>

      <S.Content>
        <S.CampaignCardsWrapper>
          <S.CampaignCards>
            <S.CampaignCardChart>
              <Box
                flex
                flexDirection="column"
                alignItems="center"
                gap="0.625rem"
                width="100%"
              >
                <Box
                  flex
                  alignItems="center"
                  JustifyContent="space-between"
                  width="100%"
                >
                  <S.CampaignCardChartTitle>
                    Quantity of orders
                  </S.CampaignCardChartTitle>
                  <S.CampaignCardChartNumber>
                    {voucherDetailData?.orders.length ?? '-'}
                  </S.CampaignCardChartNumber>
                </Box>
                {voucherDetailData?.orders &&
                  voucherDetailData?.orders.length > 0 && (
                    <S.SeeOrdersLink onClick={() => setModalIsOpen(true)}>
                      See orders{' '}
                      <img
                        src={inputOrderIcon}
                        width={24}
                        height={24}
                        alt="Input order icon"
                      />
                    </S.SeeOrdersLink>
                  )}
              </Box>
            </S.CampaignCardChart>
            <S.CampaignCardChart>
              <S.CampaignCardChartTitle>Voucher ID</S.CampaignCardChartTitle>
              <S.CampaignCardChartNumber>
                {voucherDetailData?.voucherId}
              </S.CampaignCardChartNumber>
            </S.CampaignCardChart>
            <S.CampaignCardChart>
              <S.CampaignCardChartTitle>Created at</S.CampaignCardChartTitle>
              <S.CampaignCardChartNumber>
                {format(
                  new Date(voucherDetailData?.createdAt as string),
                  'MMM d, yyyy',
                )}
              </S.CampaignCardChartNumber>
            </S.CampaignCardChart>

            <S.CampaignCardChart>
              <S.CampaignCardChartTitle>Valid until</S.CampaignCardChartTitle>
              <S.CampaignCardChartNumber>
                {format(
                  new Date(voucherDetailData?.expiresAt as string),
                  'MMM d, yyyy',
                )}
              </S.CampaignCardChartNumber>
            </S.CampaignCardChart>
          </S.CampaignCards>
          {voucherDetailData?.items && voucherDetailData?.items.length > 0 && (
            <S.CampaignCardItems>
              <S.CampaignCardChartTitle>Valid Items</S.CampaignCardChartTitle>
              <S.CampaignCardList>
                {voucherDetailData?.items.map((item: ItemType) => {
                  return (
                    <Box
                      flex={true}
                      flexDirection="column"
                      alignItems="center"
                      JustifyContent="center"
                      gap="8px"
                      key={item.id}
                      width="80px"
                    >
                      {item.featuredPicture ? (
                        <S.ItemImage
                          width={80}
                          height={80}
                          src={item.featuredPicture}
                          alt={item.name}
                        />
                      ) : (
                        <S.ItemNoImage>
                          <ImageIcon size={24} />
                        </S.ItemNoImage>
                      )}
                      <S.ItemName>{item.name}</S.ItemName>
                      <S.ItemPrice>$ {item.price / 100}</S.ItemPrice>
                    </Box>
                  )
                })}
              </S.CampaignCardList>
            </S.CampaignCardItems>
          )}
        </S.CampaignCardsWrapper>
      </S.Content>
      {modalIsOpen && renderOpenOrdersModal()}
    </ModalOverflow>
  )
}

import styled, { css } from 'styled-components'

import { TagProps } from '.'

export const Tag = styled.div<TagProps>`
  ${({ theme, active, color }) => css`
    border: 1px solid ${theme.colors.gray};
    margin: 0.5rem 1rem 0.5rem 0;
    padding: 0.75rem 1.5rem 0.875rem;
    border-radius: 16px;
    font-family: ${theme.font.quicksand};
    text-transform: capitalize;
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.medium};

    ${active &&
    css`
      border: none;
      color: ${theme.colors.primaryText};
      background: ${color || theme.colors.primary};
    `}
  `}
`

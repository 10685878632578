import { zodResolver } from '@hookform/resolvers/zod'
import { ChevronLeft } from '@styled-icons/material-outlined'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import editIcon from '@/assets/img/icon-edit.svg'
import othersPriceIcon from '@/assets/img/icons-others-price.svg'
import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { StepForm, StepOverflow } from '@/components/FormStep'
import { useFormStepData } from '@/components/FormStep/Hook'
import {
  ModalFooter,
  ModalHeader,
  ModalHeaderClose,
  ModalHeaderTitle,
} from '@/components/Modal'
import Pill from '@/components/Pill'
import Switch from '@/components/Switch'
import { Permission } from '@/enums/permission.enum'
import useAuth from '@/hooks/useAuth'
import { api } from '@/lib/axios'
import {
  Amount,
  FieldContent,
  FieldError,
  FieldHeader,
  FieldHeaderActions,
  FieldHeaderTitle,
  FieldWrapper,
  WrapperContent,
} from '@/modals/CreatingNewCampaignModal/styles'
import { CardType } from '@/types/payment.types'
import { CampaignList } from '@/types/super-partner.types'
import { formatCurrencyWithCode, formatExtNumber } from '@/utils/formats/number'

import { MagicVoucherField } from '../../MagicVoucherField'
import { PaymentField } from '../../PaymentField'
import { Total } from '../../Total'
import { BeforeType } from '..'
import { CampaignListInitialData, LoyaltyFormData } from '../LoyaltyForm'
import * as S from './styles'

export type CheckoutFormProps = {
  close: (isSuccess?: boolean) => void
  active: boolean
  before: BeforeType
  campaignItem: CampaignList<CampaignListInitialData> | null
  paymentMethod: CardType | null
  onBeforeStep: (before: BeforeType) => void
  onNextStep: () => void
}

const checkoutFormSchema = z.object({
  payment: z.union([
    z.object(
      {
        cardNumberEnd: z.string(),
        defaultMethod: z.boolean(),
        gateway: z.string(),
        vaultId: z.string(),
      },
      { required_error: 'Payment is required' },
    ),
    z.null(),
  ]),
  magicVoucher: z
    .array(
      z.object({
        voucherId: z.string(),
        image: z.string(),
        title: z.string(),
        balance: z.number(),
        expiryDate: z.string(),
      }),
    )
    .optional(),
  voucherId: z.string().optional(),
  author: z.enum(['merchant', 'bento']),
  shareableInfo: z
    .object({
      message: z.string(),
      title: z.string(),
      link: z.string(),
    })
    .optional(),
})

type CheckoutFormData = z.infer<typeof checkoutFormSchema>
export type PaymentFormData = LoyaltyFormData & CheckoutFormData

export const CheckoutForm = ({
  close,
  active,
  before,
  paymentMethod,
  campaignItem,
  onBeforeStep,
  onNextStep,
}: CheckoutFormProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const { merchantSelected, user } = useAuth()
  const { t } = useTranslation()
  const { dataForm, setStepFormValues } = useFormStepData<PaymentFormData>()
  const isMaster = _.includes(user?.permissions, Permission.MASTER)

  const hasPayment = !!(dataForm.audiences && dataForm.audiences?.length > 0)

  const {
    handleSubmit,
    control,
    setValue,
    resetField,
    watch,
    formState: { isValid },
  } = useForm<CheckoutFormData>({
    resolver: zodResolver(checkoutFormSchema),
    defaultValues: {
      payment: paymentMethod || undefined,
      magicVoucher: undefined,
      author: 'bento',
    },
  })

  const onSubmit = async (values: CheckoutFormData) => {
    try {
      setIsLoading(true)
      const data = { ...dataForm, ...values } as PaymentFormData

      const name = campaignItem?.name || ''
      const description = `${formatCurrencyWithCode(
        data?.campaign.reward / 100 || 0,
      )} reward after ${data.campaign.orders || 0} ${t(
        'modals.creating-new-campaign.checkoutStep.campaign.orders',
        {
          count: data.campaign.orders,
        },
      )}`

      const audiences =
        data?.audiences && data?.audiences.length > 0
          ? data?.audiences.map((audience) => audience.id)
          : []

      const dataToSave = {
        startAt: startOfMonth(data.period.startAt).toISOString(),
        endAt: endOfMonth(data.period.endAt).toISOString(),
        voucherQuantity: data.budget?.vouchers,
        voucherShift: data.campaign?.reward,
        minimumOrderValue: data.minimumOrderValue,
        minimumOrders: data.campaign?.orders,
        audiences,
        author: audiences.length > 0 ? data.author : 'merchant',
        paymentMethod: data.payment,
        vouchers: data.magicVoucher?.map((voucher) => voucher.voucherId),
        usersPercentage: data.usersPercentage,
        name,
        description,
      }

      const { data: campaignCreated } = await api.post(
        `/superpartner/${merchantSelected?.id}/campaigns/loyalty`,
        dataToSave,
      )

      setStepFormValues({
        ...data,
        voucherId: campaignCreated.voucherId,
        shareableInfo: campaignCreated.shareableInfo,
      })
      onNextStep()
    } finally {
      setIsLoading(false)
    }
  }

  const calculateAudienceCount = useMemo(() => {
    if (!dataForm.usersPercentage || !dataForm.selectedCount) return 0
    return Number(
      ((dataForm.usersPercentage / 100) * dataForm.selectedCount).toFixed(0),
    )
  }, [dataForm.usersPercentage, dataForm.selectedCount])

  const magicVoucher = watch('magicVoucher')
  const author = watch('author')

  useEffect(() => {
    if (active) {
      if (!hasPayment || author === 'bento') {
        setValue('payment', null, {
          shouldValidate: true,
          shouldDirty: true,
        })
        resetField('magicVoucher')
      }
      if (!paymentMethod && author !== 'bento') {
        resetField('payment')
      }
      if (paymentMethod && author === 'merchant') {
        setValue('payment', paymentMethod, {
          shouldValidate: true,
          shouldDirty: true,
        })
      }
    }
  }, [hasPayment, active, setValue, resetField, paymentMethod, author])

  return (
    <StepForm onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>
        <S.CustomCloseButton
          onClick={() => onBeforeStep({ active: true, field: '' })}
        >
          <ChevronLeft size={32} />
        </S.CustomCloseButton>

        <ModalHeaderTitle>Checkout</ModalHeaderTitle>

        <ModalHeaderClose
          onClick={(event) => {
            event.preventDefault()
            close()
          }}
        >
          ✕
        </ModalHeaderClose>
      </ModalHeader>

      <StepOverflow active={active ? 1 : 0} before={before.active ? 1 : 0}>
        <WrapperContent>
          {dataForm && dataForm.audiences && dataForm.audiences?.length > 0 && (
            <FieldWrapper>
              <FieldHeader checkout={1}>
                <FieldHeaderTitle>
                  Audience - {dataForm.usersPercentage}%
                </FieldHeaderTitle>

                <FieldHeaderActions>
                  <S.ButtonIcon
                    minimal
                    disabled={isLoading}
                    onClick={() =>
                      onBeforeStep({ active: true, field: 'audiences' })
                    }
                  >
                    <img
                      src={editIcon}
                      width={14}
                      height={14}
                      alt="Edit icon"
                    />
                  </S.ButtonIcon>
                </FieldHeaderActions>
              </FieldHeader>

              <FieldContent>
                <Box flex alignItems="center" gap="0.875rem" flexWrap="wrap">
                  {dataForm.audiences.map((audience) => (
                    <Pill
                      key={audience.id}
                      avatar={
                        <Amount>~{formatExtNumber(audience.count, 0)}</Amount>
                      }
                      removable={false}
                    >
                      {audience.name}
                    </Pill>
                  ))}
                </Box>
              </FieldContent>
            </FieldWrapper>
          )}

          <FieldWrapper>
            <FieldHeader checkout={1}>
              <FieldHeaderTitle>Total</FieldHeaderTitle>

              <FieldHeaderActions>
                <S.ButtonIcon
                  minimal
                  disabled={isLoading}
                  onClick={() =>
                    onBeforeStep({ active: true, field: 'campaign' })
                  }
                >
                  <img src={editIcon} width={14} height={14} alt="Edit icon" />
                </S.ButtonIcon>
              </FieldHeaderActions>
            </FieldHeader>

            <FieldContent>
              <Box flex alignItems="center" gap="0.875rem">
                <img
                  src={othersPriceIcon}
                  width={20}
                  height={20}
                  alt="Others price icon"
                />
                <S.TotalText>
                  ${dataForm?.campaign?.reward / 100} reward after{' '}
                  {dataForm?.campaign?.orders}{' '}
                  {t(
                    'modals.creating-new-campaign.checkoutStep.campaign.orders',
                    {
                      count: dataForm?.campaign?.orders,
                    },
                  )}
                </S.TotalText>
              </Box>
            </FieldContent>
          </FieldWrapper>

          <FieldWrapper>
            <FieldHeader checkout={1}>
              <FieldHeaderTitle>Custom</FieldHeaderTitle>
            </FieldHeader>

            <FieldContent>
              <Box flex alignItems="center" gap="0.875rem">
                <S.TotalText>
                  Total vouchers: {dataForm?.budget?.vouchers}{' '}
                  {t(
                    'modals.creating-new-campaign.checkoutStep.vouchers.voucherQuantity',
                    {
                      count: dataForm?.budget?.vouchers,
                    },
                  )}
                </S.TotalText>
              </Box>
            </FieldContent>
          </FieldWrapper>

          <FieldWrapper>
            <FieldHeader checkout={1}>
              <FieldHeaderTitle>Period to expire the voucher</FieldHeaderTitle>
            </FieldHeader>

            <FieldContent>
              <Box flex alignItems="center" gap="0.875rem">
                <S.TotalText>
                  from{' '}
                  {dataForm.period
                    ? format(dataForm.period?.startAt, 'MMM/yyyy')
                    : '-'}{' '}
                  to{' '}
                  {dataForm.period
                    ? format(dataForm.period?.endAt, 'MMM/yyyy')
                    : '-'}
                </S.TotalText>
              </Box>
            </FieldContent>
          </FieldWrapper>

          {isMaster && hasPayment && (
            <FieldWrapper>
              <FieldHeader checkout={1}>
                <FieldHeaderTitle>Create campaign as Bento</FieldHeaderTitle>

                <FieldHeaderActions>
                  <Controller
                    name="author"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Switch
                          onChange={(checked) =>
                            field.onChange(checked ? 'bento' : 'merchant')
                          }
                          checked={field.value === 'bento'}
                        />
                        {error?.message && (
                          <FieldError>{error?.message}</FieldError>
                        )}
                      </>
                    )}
                  />
                </FieldHeaderActions>
              </FieldHeader>

              <FieldContent style={{ marginTop: '0.25rem' }}>
                <S.CampaignAsBentoText>
                  The Magic Vouchers generated through this will be applied
                  towards Bento expenses, not towards the partner.
                </S.CampaignAsBentoText>
              </FieldContent>
            </FieldWrapper>
          )}

          {hasPayment && (
            <Controller
              name="payment"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <PaymentField
                  value={field.value || undefined}
                  onChange={field.onChange}
                  error={error?.message}
                  loading={isLoading}
                  disabled={author === 'bento'}
                />
              )}
            />
          )}

          {typeof dataForm?.total !== 'undefined' && dataForm?.total > 0 && (
            <Controller
              name="magicVoucher"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <MagicVoucherField
                  value={field.value}
                  onChange={field.onChange}
                  error={error?.message}
                  loading={isLoading}
                  disabled={author === 'bento'}
                />
              )}
            />
          )}
        </WrapperContent>
      </StepOverflow>

      <ModalFooter>
        <Total
          audiences={dataForm.audiences || []}
          total={dataForm.total || 0}
          selectedCount={calculateAudienceCount || 999}
          magicVoucher={magicVoucher}
        />

        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
        >
          <Button
            size="large"
            color="white"
            disabled={isLoading}
            onClick={() => close()}
            shadow
          >
            Cancel
          </Button>
          <Button
            size="large"
            type="submit"
            disabled={isLoading || !isValid}
            loading={isLoading}
          >
            Create
          </Button>
        </Box>
      </ModalFooter>
    </StepForm>
  )
}

import styled, { css, DefaultTheme } from 'styled-components'

import { TextAreaProps } from '.'

type WrapperProps = Pick<TextAreaProps, 'disabled' | 'variant'> & {
  error?: boolean
}

export const TextAreaWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    background: ${theme.colors.white};
    border-radius: ${theme.border.radius.xsmall};
    padding: 0 ${theme.spacings.xxsmall};
    border: 2px solid transparent;
    transition: border ${theme.transition.default};
    border: 2px solid ${theme.colors.lightGray};
  `}
`

type StyledTextAreaProps = Pick<TextAreaProps, 'resizable'>

export const TextArea = styled.textarea<StyledTextAreaProps>`
  ${({ theme, resizable }) => css`
    font-family: ${theme.font.quicksand};
    font-size: ${theme.font.sizes.xsmall};
    padding: ${theme.spacings.xxsmall} 0;
    background: transparent;
    border: 0;
    outline: none;
    width: 100%;
    resize: ${resizable ? 'auto' : 'none'};
    color: ${theme.colors.superLight};

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 ${theme.spacings.small} ${theme.colors.white}
        inset;
      filter: none;
      -webkit-text-fill-color: ${theme.colors.superLight};
    }

    &::placeholder {
      color: ${theme.colors.gray};
    }
  `}
`

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.black};
    cursor: pointer;
  `}
`

const wrapperModifiers = {
  error: (theme: DefaultTheme) => css`
    ${Label} {
      color: ${theme.colors.red} !important;
    }
  `,
  disabled: (theme: DefaultTheme) => css`
    ${Label},
    ${TextArea} {
      cursor: not-allowed;
      color: ${theme.colors.gray};

      &::placeholder {
        color: currentColor;
      }
    }
  `,
  material: (theme: DefaultTheme, disabled?: boolean) => css`
    ${Label} {
      font-weight: ${theme.font.medium};
      font-size: ${theme.font.sizes.xxsmall};
      color: ${theme.colors.gray};
    }

    ${TextAreaWrapper} {
      border-radius: 0;
      padding: 0;
      background-color: transparent;
      border: 2px solid transparent;
      border-bottom: 1px solid ${theme.colors.lightGray};

      ${disabled &&
      css`
        border-bottom: 1px solid #dcdcdc;
      `}

      &:focus-within {
        border: 2px solid transparent;
        border-bottom: 1px solid ${theme.colors.lightGray};
      }
    }

    ${TextArea} {
      font-family: ${theme.font.quicksand};
      color: ${theme.colors.superLight};
      font-size: ${theme.font.sizes.medium};
      font-weight: ${theme.font.medium};
      padding: 0 0 0.375rem;
    }
  `,
}

export const Error = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.red};
    font-size: ${theme.font.sizes.xsmall};
  `}
`

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, error, disabled, variant }) => css`
    width: 100%;

    ${error && wrapperModifiers.error(theme)}
    ${variant === 'material' && wrapperModifiers.material(theme, disabled)}
    ${disabled && wrapperModifiers.disabled(theme)}
  `}
`

import { darken } from 'polished'
import styled, { css } from 'styled-components'

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20rem;
`

export const Addresses = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tooltip {
    border-radius: 8px;
    font-family: 'Quicksand';
    font-size: 0.75rem;
  }
`

export const Address = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.colors.lightGray};

    &:last-child {
      border-bottom: none;
    }
  `}
`

export const WrapperButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  margin-right: 0.938rem;
  border: none;
  background-color: transparent;
`

export const AddressImage = styled.div`
  display: flex;
  align-items: center;
`

export const AddressDescription = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: start;
    flex-direction: column;
    margin-left: 1.5rem;

    h3 {
      font-family: 'Quicksand';
      font-size: 0.938rem;
      color: ${theme.colors.secondary};
      font-weight: 600;
      line-height: 1.07;

      span {
        font-family: 'Quicksand';
        font-size: 0.75rem;
        color: ${theme.colors.primary};
        margin-left: 0.5rem;
      }
    }

    p {
      font-family: 'Quicksand';
      font-size: 0.75rem;
      color: ${theme.colors.gray};
      line-height: 1.42;
    }
  `}
`

export const DeleteButton = styled.button`
  ${({ theme }) => css`
    background: ${theme.colors.primary};
    border: none;
    border-radius: 4px;
    padding: 0.375rem 0.25rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      width: 0.75rem;
      height: 0.813rem;
    }

    &:hover {
      background: ${darken(0.15, theme.colors.primary)};
    }
  `}
`

export const WrapperActions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const EditButton = styled(DeleteButton)`
  svg {
    fill: white;
  }
`

export const ModalWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding-bottom: 105px;

  @media (min-width: 768px) {
    height: auto;
    padding-bottom: 0;
  }
`

export const ConfirmTitle = styled.h1`
  font-size: 1.25rem;
  font-weight: bold;
`

export const ConfirmDescription = styled.p`
  margin-top: 16px;
  font-size: 1.063rem;
  font-weight: normal;
`

export const Container = styled.div`
  padding: 1.875rem;
`

import * as DialogPrimitive from '@radix-ui/react-dialog'
import styled, { css, keyframes } from 'styled-components'
import media from 'styled-media-query'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
`

const fadeout = keyframes`
  from {
    opacity: 0.8;
  }
  to {
    opacity: 0;
  }
`

const scaleIn = keyframes`
  from {
    transform: translate(-50%, -50%) scale(0.9);
  }
  to {
    transform: translate(-50%, -50%) scale(1);
  }
`

const scaleOut = keyframes`
  from {
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    transform: translate(-50%, -50%) scale(0.9);
  }
`

type WrapperType = {
  width: string
  xsWidth?: string
  height: string
  xsHeight?: string
  noBackground?: number
}

export const Wrapper = styled(DialogPrimitive.Content)<WrapperType>`
  ${({ theme, width, height, xsHeight, xsWidth, noBackground }) => css`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
    width: ${width || '100%'};
    height: ${height || 'auto'};
    max-width: ${width || '48rem'};
    max-height: ${height || '90vh'};
    outline: 0;
    border-radius: ${theme.border.radius.medium};
    background: ${noBackground === 1 ? 'none' : theme.colors.mainBg};

    display: grid;
    grid-template-rows: auto 1fr auto;

    &[data-state='open'] {
      animation: ${scaleIn} 200ms ease-out;
    }

    &[data-state='closed'] {
      animation:
        ${fadeout} 200ms ease-out,
        ${scaleOut} 200ms ease-out;
    }

    ${media.lessThan('medium')`
      width: ${xsWidth || '100%'};
      height: ${xsHeight || '100%'};
      max-width: ${xsWidth || '100%'};
      max-height: ${xsHeight || '100%'};
      border-radius: 0;
    `}
  `}
`

export const Overlay = styled(DialogPrimitive.Overlay)`
  ${({ theme }) => css`
    position: fixed;
    inset: 0;
    z-index: 101;
    background-color: ${theme.colors.secondary};
    opacity: 0.8;

    &[data-state='open'] {
      animation: ${fadeIn} 200ms ease-out;
    }

    &[data-state='closed'] {
      animation: ${fadeout} 200ms ease-out;
    }
  `}
`

type SimpleType = { simple?: number }

export const Header = styled.header<SimpleType>`
  ${({ theme, simple }) => css`
    position: relative;
    background-color: ${theme.colors.white};
    padding: ${theme.spacings.xsmall} ${theme.spacings.xsmall};
    border-radius: 0;
    box-shadow: 0 0.375rem 1.125rem 0 rgb(0 0 0 / 10%);
    z-index: 1;

    ${media.lessThan('medium')`
     border-radius: 0;
    `}

    ${media.greaterThan('medium')`
     border-radius: ${theme.border.radius.medium} ${theme.border.radius.medium} 0
      0;
    `}

    ${simple === 1 &&
    css`
      background-color: transparent;
      box-shadow: none;
    `}
  `}
`

export const Title = styled(DialogPrimitive.Title)`
  ${({ theme }) => css`
    font-family: ${theme.font.nunito};
    color: ${theme.colors.primary};
    font-size: calc(${theme.font.sizes.xlarge} - 0.125rem);
    font-weight: ${theme.font.bold};
    text-align: center;
    flex: 1;
    border-radius: 0;

    ${media.greaterThan('medium')`
     border-radius: ${theme.border.radius.medium} ${theme.border.radius.medium} 0
      0;
    `}
  `}
`

export const CloseButton = styled(DialogPrimitive.Close)`
  ${({ theme }) => css`
    all: unset;
    appearance: none;
    background: none;
    color: ${theme.colors.primary};
    font-size: ${theme.spacings.large};
    border: 0;
    border-radius: 50%;
    position: absolute;
    right: ${theme.spacings.xxsmall};
    top: calc(${theme.spacings.xxsmall} - 0.125rem);
    outline: none;
    cursor: pointer;
    width: 30px;
    height: 40px;

    ${media.greaterThan('medium')`
      right: ${theme.spacings.large};
      top: calc(${theme.spacings.xsmall} - 0.125rem);
    `}
  `}
`

export const ModalOverflow = styled.div`
  ${({ theme }) => css`
    flex: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: ${theme.spacings.xsmall} calc(${theme.spacings.large} - 0.625rem)
      ${theme.spacings.xsmall} ${theme.spacings.large};
  `}
`

export const ModalFooter = styled.footer<SimpleType>`
  ${({ theme, simple }) => css`
    position: relative;
    flex: 0;
    background-color: ${theme.colors.white};
    padding: ${theme.spacings.small} ${theme.spacings.large};
    border-radius: 0;
    box-shadow: 0 -0.375rem 1.125rem 0 rgb(0 0 0 / 10%);
    z-index: 1;

    ${media.greaterThan('medium')`
      border-radius: 0 0 ${theme.border.radius.medium}
      ${theme.border.radius.medium};
    `}

    ${simple === 1 &&
    css`
      background-color: transparent;
      box-shadow: none;
    `}
  `}
`

export const ExternalCloseButton = styled.div`
  ${({ theme }) => css`
    position: fixed;
    right: 0.625rem;
    top: 0.625rem;
    z-index: 150;
    color: ${theme.colors.primary};
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow:
      rgb(0 0 0 / 20%) 0px 3px 5px -1px,
      rgb(0 0 0 / 14%) 0px 6px 10px 0px,
      rgb(0 0 0 / 12%) 0px 1px 18px 0px;
    cursor: pointer;
  `}
`

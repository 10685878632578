import { CardType } from '@/types/payment.types'
import { Campaign, CampaignList } from '@/types/super-partner.types'

import { PositionSteps } from './components/PositionSteps'

export type Props = {
  close: (success?: boolean) => void
  paymentMethod: CardType | null
  campaignItem: CampaignList
  campaign: Campaign
}

const PositionCampaignModal = ({
  campaign,
  campaignItem,
  close,
  paymentMethod,
}: Props) => {
  return (
    <PositionSteps
      campaignItem={campaignItem}
      campaign={campaign}
      close={close}
      paymentMethod={paymentMethod}
    />
  )
}

export default PositionCampaignModal

import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.div`
  z-index: 9999;
  display: block;
  width: auto;

  .dropdown {
    width: auto;
  }

  ${media.lessThan('large')`
    display: block;
    width: 130px;

    .dropdown {
      width: 130px;
    }
  `}

  font-size: 12px;

  .dropdown-content {
    ${({ theme }) => css`
      width: 146px;
      right: -8px;
      top: 8px;
      padding: 0.188rem;
      color: ${theme.colors.white};
      background-color: ${theme.dark
        ? theme.colors.superLight
        : theme.colors.secondary};
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      font-size: 10px;

      &::before {
        border-bottom: 0.5rem solid
          ${theme.dark ? theme.colors.superLight : theme.colors.secondary};
      }
    `}

    ${media.greaterThan('large')`  
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      right: -8px;
      top: 48px;
    `}
  }

  .dropdown,
  .dropdown-title {
    ${({ theme }) => css`
      color: ${theme.colors.white};
      font-size: 10px;
    `}

    .dropdown-title {
      width: 100%;
    }
  }
`

export const Title = styled.div`
  ${media.greaterThan('large')`  
    display:none;
  `}

  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Content = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    background-color: ${theme.dark
      ? theme.colors.superLight
      : theme.colors.secondary};
    z-index: 16;
    padding: 0.625rem;
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  `}
`

export const Info = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    display: flex;
    flex-direction: row;
    gap: 8px;
  `}

  ${media.greaterThan('large')`  
    display:none;
  `}
`

export const ItemAvailabilitySwitch = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between;
    cursor: pointer;
  `}
`

export const AvailableIcon = styled.div`
  ${({ theme }) => css`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${theme.colors.green};
  `}
`

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const Status = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`

export const ItemInfo = styled.div`
  display: block;

  ${media.lessThan('large')`
    display: none;
  `}

  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
`

export const ItemAvailability = styled.div`
  display: block;
  ${media.lessThan('large')`
    display: none;
  `}

  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
`

export const ItemSubInfo = styled.div``

export const CookingTime = styled.div`
  ${({ theme }) => css`
    /* margin-left: 0.5rem; */
    font-family: ${theme.font.quicksand};
    font-size: 12px;
    line-height: 18px;
    color: ${theme.colors.white};
    display: flex;
    align-items: center;

    svg {
      margin-right: 4px;
    }
  `}
`

export const ItemStatus = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.green};
    color: ${theme.colors.white};
    border-radius: 10px;
    padding: 0.625rem 1.25rem;
    font-size: 14px;
    font-weight: 600;
    /* margin-left: 8px; */
    font-family: ${theme.font.quicksand};
  `}
`

export const ItemLogo = styled.img`
  width: 82px;
  max-height: 110px;
  object-fit: scale-down;
  border-radius: 8px;
`

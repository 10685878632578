import styled, { css } from 'styled-components'

export const Description = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.semiBold};
    line-height: 1.22;
    color: ${theme.colors.gray};
  `}
`

export const Section = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.medium};
    padding: ${theme.spacings.medium};
    border-radius: 24px;
    background-color: ${theme.colors.white};
  `}
`

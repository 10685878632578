import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.div<{ isClosed: boolean }>`
  ${({ theme }) => css`
    background-color: ${theme.dark
      ? theme.colors.superLight
      : theme.colors.secondary};
    border-radius: ${theme.border.radius.xsmall};
    color: ${theme.colors.white};
    padding: 0.875rem;
  `}

  ${({ isClosed }) => css`
    opacity: ${isClosed ? 0.5 : 1};
    z-index: 1;
  `}

  ${media.lessThan('large')`
    padding: 0.5rem;
    border-radius: 0.313rem;
  `}
`
export const ItemInfo = styled.div`
  display: block;
  ${media.lessThan('large')`
    display: none;
  `}

  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
`

export const ItemAvaiability = styled.div`
  display: block;

  ${media.lessThan('large')`
    display: none;
  `}

  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
`

export const ItemSubInfo = styled.div``

export const CookingTime = styled.div`
  ${({ theme }) => css`
    /* margin-left: 0.5rem; */
    font-family: ${theme.font.quicksand};
    font-size: 12px;
    line-height: 18px;
    color: ${theme.colors.white};
    display: flex;
    align-items: center;

    svg {
      margin-right: 4px;
    }
  `}
`

export const ItemStatus = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.green};
    color: ${theme.colors.white};
    border-radius: 0.625rem;
    padding: 0.625rem 1.25rem;
    font-size: 14px;
    font-weight: 600;
    /* margin-left: 8px; */
    font-family: ${theme.font.quicksand};
  `}
`

export const ItemLogo = styled.img`
  width: 82px;
  max-height: 110px;
  object-fit: scale-down;
  border-radius: 0.5rem;
`

import { lighten } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.button<{ selected: boolean; size?: string }>`
  ${({ theme, selected, size = 'big' }) => css`
    font-family: ${theme.font.quicksand};
    border: none;
    border: 1px solid ${theme.colors.gray};
    color: ${theme.colors.gray};
    border-radius: ${theme.border.radius.medium};
    font-weight: ${theme.font.medium};
    font-size: ${theme.font.sizes.medium};
    padding: ${theme.spacings.xxsmall} 1.25rem
      calc(${theme.spacings.xsmall} - 0.188rem);
    background-color: transparent;
    cursor: pointer;
    margin-right: ${theme.spacings.small};
    margin-bottom: ${theme.spacings.xsmall};
    min-width: 4.75rem;
    transition: all ${theme.transition.default};

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: ${theme.colors.mainBg};
    }

    ${selected &&
    css`
      background-color: ${lighten(0.3, theme.colors.primary)};
      color: ${theme.colors.primary};
      border-color: ${lighten(0.3, theme.colors.primary)};

      &:hover {
        background-color: ${lighten(0.25, theme.colors.primary)};
        color: ${theme.colors.primary};
        border-color: ${lighten(0.3, theme.colors.primary)};
      }
    `}

    ${media.lessThan('medium')`
      margin-right: 0.5rem;
    `}

    ${size === 'small' &&
    css`
      margin: 0;
      margin-right: 0;
      padding: 0.375rem 0.625rem;

      ${media.lessThan('medium')`
        margin-right: 0;
      `}
    `}
  `}
`

import { Steps } from '@/modals/CreatingNewCampaignModal/components/VoucherSteps'

import * as S from './styles'

type FormStepProps = {
  steps: Steps[]
  activeStep: number
  setActiveStep: (step: number) => void
}

export const FormStep = ({ steps, activeStep }: FormStepProps) => {
  return (
    <>
      {steps.length > 1 && (
        <S.StepNavigation>
          step {activeStep + 1} of {steps.length}
        </S.StepNavigation>
      )}

      <S.Steps>
        {steps.map((step, index) => (
          <S.Step key={index} active={activeStep === index ? 1 : 0}>
            {Object.values(step)}
          </S.Step>
        ))}
      </S.Steps>
    </>
  )
}

export const StepOverflow = S.StepOverflow
export const StepForm = S.StepForm

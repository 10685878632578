import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    .slider {
      width: 100%;
      height: 0.875rem;

      .track {
        position: relative;
        top: 0.25rem;
        height: 0.25rem;
        background-color: #dcdcdc;
      }

      .thumb {
        width: 0.813rem;
        height: 0.813rem;
        background-color: ${theme.colors.primary};
        border-radius: 99999px;
        cursor: pointer;
      }

      .mark {
        margin: 0px calc(19px);
        bottom: calc(50% - 6px);
        width: 8px;
        height: 8px;
        border: 2px solid rgb(0, 0, 0);
        background-color: rgb(255, 255, 255);
        cursor: pointer;
        border-radius: 50%;
        vertical-align: middle;
      }
    }
  `}
`

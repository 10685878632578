import { CardType } from '@/types/payment.types'
import { Campaign, CampaignList } from '@/types/super-partner.types'

import { LoyaltySteps } from './components/LoyaltySteps'
import { VoucherSteps } from './components/VoucherSteps'

export type Props = {
  close: (isSuccess?: boolean) => void
  campaignItem: CampaignList | null
  campaign: Campaign
  paymentMethod: CardType | null
}

const CreatingNewCampaignModal = ({
  campaignItem,
  campaign,
  close,
  paymentMethod,
}: Props) => {
  const Component = campaign?.type === 'VOUCHER' ? VoucherSteps : LoyaltySteps

  return (
    <Component
      campaignItem={campaignItem}
      campaign={campaign}
      close={close}
      paymentMethod={paymentMethod}
    />
  )
}

export default CreatingNewCampaignModal

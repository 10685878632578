import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Input = styled.input`
  ${({ theme }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    width: 0.938rem;
    height: 0.938rem;
    border: 0.063rem solid ${theme.colors.gray};
    border-radius: 4px;
    position: relative;
    outline: none;
    transition: background border ${theme.transition.fast};

    &:before {
      content: '';
      width: 0.688rem;
      height: 0.688rem;
      border-radius: 2px;
      position: absolute;
      opacity: 0;
      background: ${theme.colors.primary};
      transition: ${theme.transition.fast};
    }

    &:hover {
      border-color: ${theme.colors.primary};
    }

    &:focus {
      box-shadow: 0 0 0.188rem ${theme.colors.primary};
    }

    &:checked {
      border-color: ${theme.colors.primary};

      &:before {
        opacity: 1;
      }
    }
  `}
`

export const Label = styled.label`
  ${({ theme }) => css`
    padding-left: 0.625rem;
    color: ${theme.colors.gray};
    font-weight: ${theme.font.medium};
    line-height: 1;
    cursor: pointer;
  `}
`

import styled, { css } from 'styled-components'

import { RadioProps } from '.'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

type InputProps = Pick<RadioProps, 'disabled'>

export const Input = styled.input<InputProps>`
  ${({ theme, disabled }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    width: 0.938rem;
    height: 0.813rem;
    border: 1px solid ${theme.colors.primary};
    border-radius: 99999px;
    background: transparent;
    transition: background ${theme.transition.fast};
    outline: none;
    cursor: pointer;

    &:focus {
      box-shadow: 0 0 3px ${theme.colors.primary};
    }

    &:before {
      content: '';
      width: 0.688rem;
      height: 0.688rem;
      border-radius: 99999px;
      background: ${theme.colors.primary};
      transition: opacity ${theme.transition.fast};
      opacity: 0;
    }

    &:checked {
      &:before {
        opacity: 1;
      }
    }

    ${disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
  `}
`

type LabelProps = Pick<RadioProps, 'disabled' | 'labelColor'>

export const Label = styled.label<LabelProps>`
  ${({ theme, labelColor = 'default', disabled }) => css`
    padding-left: 0.625rem;
    color: ${labelColor === 'default'
      ? theme.colors.gray
      : theme.colors[labelColor]};
    font-weight: ${theme.font.medium};
    line-height: 1;
    cursor: pointer;
    width: 100%;

    ${disabled &&
    css`
      cursor: not-allowed;
    `}
  `}
`

export const AdditionalText = styled.label<LabelProps>`
  ${({ theme, labelColor = 'default', disabled }) => css`
    padding-left: 0.625rem;
    color: ${labelColor === 'default'
      ? theme.colors.gray
      : theme.colors[labelColor]};
    font-weight: ${theme.font.regular};
    line-height: 1;
    cursor: pointer;

    ${disabled &&
    css`
      cursor: not-allowed;
    `}
  `}
`

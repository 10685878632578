import { KeyboardArrowDown } from '@styled-icons/material-rounded/KeyboardArrowDown'

import Dropdown from '@/components/Dropdown'
import { MenuItem, MenuList } from '@/components/DropdownMenu'
import useSwitchTheme from '@/hooks/useSwitchTheme'
import darkTheme from '@/styles/darkTheme'
import lightTheme from '@/styles/lightTheme'

import * as S from './styles'

const ThemeDropdown = () => {
  const switchTheme = useSwitchTheme(({ switchTheme }) => switchTheme)
  const theme = useSwitchTheme(({ theme }) => theme)

  return (
    <S.Wrapper>
      <Dropdown
        title={
          <S.DropSelected>
            <span>{theme.name} mode</span> <KeyboardArrowDown size={16} />
          </S.DropSelected>
        }
      >
        {(close) => (
          <MenuList>
            <MenuItem
              onClick={() => {
                document.body.classList.remove('dark')
                switchTheme(lightTheme)
                close()
              }}
            >
              Light mode
            </MenuItem>
            <MenuItem
              onClick={() => {
                document.body.classList.add('dark')
                switchTheme(darkTheme)
                close()
              }}
            >
              Dark mode
            </MenuItem>
          </MenuList>
        )}
      </Dropdown>
    </S.Wrapper>
  )
}

export default ThemeDropdown

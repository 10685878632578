import { Link as RouterLink } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components'
import media from 'styled-media-query'

import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { Wrapper as CardContent } from '@/components/CardContent/styles'

const flickerAnimation = keyframes`
  0%   { background-color: rgba(251, 162, 64, 0.5); }
  50%  { background-color: transparent; }
  100% { background-color: rgba(251, 162, 64, 0.5); }
`

export const Wrapper = styled.main`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.spacings.xxlarge} ${theme.spacings.small};
    width: calc(100vw - 10rem);

    ${CardContent} + ${CardContent} {
      margin-top: ${theme.spacings.xsmall};
    }

    ${media.lessThan('medium')`
      width: 97vw;
    `}

    ${media.lessThan('large')`
      flex-direction: column;
      justify-content: center;
      padding: ${theme.spacings.large} ${theme.spacings.small};

      ${HeaderContent} {
        &:first-child {
          order: 2;
          padding-right: 0;
        }

        &:last-child {
          order: 1;
          margin-bottom: ${theme.spacings.small};
        }
      }
    `}

    .tooltip {
      border-radius: 8px;
      font-family: ${theme.font.quicksand};
      font-size: ${theme.font.sizes.xsmall};
    }

    .rdt_TableRow {
      background-position: center;
      transition: background 0.8s;

      &:hover {
        background: ${theme.colors.mainBg}
          radial-gradient(circle, transparent 1%, ${theme.colors.mainBg} 1%)
          center/15000%;
      }

      &:active {
        background-color: ${theme.colors.lightGray};
        background-size: 100%;
        transition: background 0s;
      }
    }
  `}
`

export const HeaderContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    ${media.greaterThan('medium')`
      &:first-child {
        padding-right: ${theme.spacings.small};
      }
    `}

    ${media.greaterThan('large')`
      &:first-child {
        width: 60%;
      }
      &:last-child {
        width: 40%;
      }
    `}
  `}
`

export const ContentCharts = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: stretch;
    width: 100%;

    ${Box} {
      &:first-child {
        margin-right: ${theme.spacings.xsmall};
      }
    }

    ${CardContent} {
      height: 100%;
    }

    ${media.lessThan('medium')`
      flex-direction: column;
      justify-content: center;
      text-align: center;

      ${Box} {
        &:first-child {
          margin-right: 0;
          max-width: 100%;
        }
        &:last-child {
          width: 100%;
        }
      }

      ${CardContent} {
        margin-top: ${theme.spacings.xsmall};
      }
    `}

    ${media.lessThan('large')`
      ${Box} {
        &:last-child {
          width: 100%;
        }
      }
    `}
  `}
`

type ContentTableProps = {
  hasEmptyOrError?: boolean
}

export const ContentTable = styled.div<ContentTableProps>`
  ${({ hasEmptyOrError = false }) => css`
    display: flex;
    width: 100%;

    .new_order {
      animation: ${flickerAnimation} 2s infinite;
    }

    ${hasEmptyOrError &&
    css`
      ${media.lessThan('large')`
        height: 9.375rem;
      `}
      ${media.greaterThan('large')`
        height: 43.75rem;
      `}
    `}
  `}
`

type HeaderProps = {
  isDate?: boolean
}

export const Header = styled.header<HeaderProps>`
  ${({ theme, isDate = false }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: ${theme.spacings.medium};

    ${media.lessThan('medium')`
      ${
        isDate &&
        css`
          flex-direction: column;
          justify-content: center;
          text-align: center;
          margin-top: ${theme.spacings.medium};
        `
      }

      > div {
        margin-bottom: 0.5rem;
      }
    `}
  `}
`

export const DateWrapper = styled.div`
  width: 300px;
`

export const Link = styled(RouterLink)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-family: ${theme.font.nunito};
    font-size: ${theme.font.sizes.small};
    font-weight: ${theme.font.semiBold};
    color: ${theme.colors.primary};
    text-decoration: none;

    img {
      margin-left: 0.25rem;
    }
  `}
`

export const Conversion = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-weight: ${theme.font.medium};
    font-size: ${theme.font.sizes.xsmall};
    padding: 0.5rem 0;
    background-color: #dcdcdc;
    color: ${theme.colors.secondary};
    width: 100%;
    min-height: 2.25rem;
    border-radius: 10px;

    strong {
      margin-left: 0.25rem;
      font-weight: ${theme.font.semiBold};
      font-size: ${theme.font.sizes.medium};
    }
  `}
`

export const DowntimeLabel = styled.span`
  ${({ theme }) => css`
    padding: 0.063rem 0.375rem;
    line-height: 2;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.primaryText};
    border-radius: 4px;
  `}
`

export const ButtonIcon = styled(Button)`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.spacings.xsmall};
    right: ${theme.spacings.xsmall};
    padding: 0.25rem;
    height: auto;

    svg {
      width: 1.25rem;
      max-height: 1.25rem;
    }
  `}
`

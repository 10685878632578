import { Image as ImageIcon } from '@styled-icons/material-outlined/Image'
import { useMutation, useQuery } from '@tanstack/react-query'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useCallback, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useMediaQuery } from 'react-responsive'

import superPartnerService from '@/api/super-partner.service'
import { Box } from '@/components/Box'
import DateRangePicker from '@/components/DateRangePicker'
import Empty from '@/components/Empty'
import { Modal, ModalContent } from '@/components/Modal'
import Skeleton from '@/components/Skeleton'
import Switch from '@/components/Switch'
import * as T from '@/components/Table/styles'
import TableData from '@/components/TableData'
import useAuth from '@/hooks/useAuth'
import useModal from '@/hooks/useModal'
import { queryClient } from '@/lib/react-query'
import { VoucherDetailModal } from '@/modals/VoucherDetailModal'
import { VoucherListType } from '@/types/super-partner.types'
import { formatCurrency } from '@/utils/formats/number'

import * as S from './styles'

export function Vouchers() {
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ])
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  })
  const { merchantSelected, timezone } = useAuth()

  const [startDate, endDate] = dateRange

  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal()
  const [voucherDetail, setVoucherDetail] = useState<VoucherListType>()

  const {
    data: vouchers,
    isLoading,
    isError: hasError,
  } = useQuery({
    enabled: !!merchantSelected?.id,
    queryKey: ['vouchers', merchantSelected?.id, startDate, endDate],
    retry: 0,
    queryFn: () =>
      superPartnerService.getVouchers(merchantSelected?.id as string, [
        utcToZonedTime(startDate, timezone),
        utcToZonedTime(endDate, timezone),
      ]),
  })

  function updateDisabledVoucherCache(voucher: VoucherListType) {
    const cached = queryClient.getQueryData<VoucherListType[]>([
      'vouchers',
      merchantSelected?.id,
      startDate,
      endDate,
    ])

    if (cached) {
      const newVouchers = [...cached]
      const index = newVouchers.findIndex(
        (item) => item.voucherId === voucher.voucherId,
      )
      newVouchers[index].isValid = !voucher.isValid

      queryClient.setQueryData<VoucherListType[]>(
        ['vouchers', merchantSelected?.id, startDate, endDate],
        newVouchers,
      )
    }
  }

  const { mutateAsync: patchAvailability, isPending: isDisabledLoading } =
    useMutation({
      mutationFn: superPartnerService.patchVoucherAvailability,
      onMutate({ voucher }) {
        updateDisabledVoucherCache(voucher)
        return { voucher }
      },
      onError(_, __, context) {
        if (context?.voucher) {
          updateDisabledVoucherCache(context.voucher)
        }
      },
    })

  const handleChangeAvailability = useCallback(
    async (voucher: VoucherListType) => {
      try {
        await patchAvailability({ voucher })
        // eslint-disable-next-line no-empty
      } finally {
      }
    },
    [patchAvailability],
  )

  const openVoucherDetailModal = (voucherDetailItem: VoucherListType) => {
    setVoucherDetail(voucherDetailItem)
    setModalIsOpen(true)
  }

  const renderVoucherDetailModal = () => {
    return (
      <Modal open={modalIsOpen} onOpenChange={setModalIsOpen} modal>
        <ModalContent title="" onClose={() => setModalIsOpen(false)} noHeader>
          <VoucherDetailModal
            closeVoucherDetail={() => setModalIsOpen(false)}
            voucherDetailData={voucherDetail}
          />
        </ModalContent>
      </Modal>
    )
  }

  const columns = useMemo(
    () => [
      {
        name: 'On/Off',
        selector: (row: VoucherListType) => row.isValid,
        sortable: false,
        center: true,
        width: '80px',
        cell: (row: VoucherListType) => {
          return (
            <Switch
              checked={row.isValid}
              onChange={() => handleChangeAvailability(row)}
            />
          )
        },
      },
      {
        name: 'Created At',
        selector: (row: VoucherListType) => row.createdAt,
        sortable: true,
        center: true,
        width: '140px',
        cell: (row: VoucherListType) => {
          return (
            <span data-tag="allowRowEvents">
              {format(new Date(row.createdAt), 'MMM d, yyyy')}
            </span>
          )
        },
      },
      {
        name: 'Voucher ID',
        selector: (row: VoucherListType) => row.voucherId,
        sortable: true,
        center: true,
        width: '120px',
        cell: (row: VoucherListType) => {
          return <span data-tag="allowRowEvents">{row.voucherId || '-'}</span>
        },
      },
      {
        name: 'Amount',
        selector: (row: VoucherListType) => row.balance,
        sortable: true,
        center: true,
        width: '120px',
        cell: (row: VoucherListType) => {
          return (
            <span data-tag="allowRowEvents">
              ${formatCurrency((row?.balance || 0) / 100) ?? '-'}
            </span>
          )
        },
      },
      {
        name: 'Min. Order',
        selector: (row: VoucherListType) => row.minOrder,
        sortable: true,
        center: true,
        width: '120px',
        cell: (row: VoucherListType) => {
          return (
            <span data-tag="allowRowEvents">
              ${formatCurrency((row?.minOrder || 0) / 100) ?? '-'}
            </span>
          )
        },
      },
      {
        name: 'Image',
        selector: (row: VoucherListType) => row.image,
        sortable: false,
        center: true,
        width: '120px',
        cell: (row: VoucherListType) => {
          return (
            <T.ImageWrapper data-tag="allowRowEvents">
              {row.image ? (
                <img
                  src={row.image}
                  alt="Voucher Image"
                  width={50}
                  height={50}
                  className="object-contain"
                />
              ) : (
                <ImageIcon size={24} />
              )}
            </T.ImageWrapper>
          )
        },
      },
      {
        name: 'Description',
        selector: (row: VoucherListType) => row.description,
        sortable: true,
        center: false,
        width: '260px',
        cell: (row: VoucherListType) => {
          return <span data-tag="allowRowEvents">{row.description || '-'}</span>
        },
      },
      {
        name: 'Orders',
        selector: (row: VoucherListType) => row.orders,
        sortable: true,
        center: true,
        width: '80px',
        cell: (row: VoucherListType) => {
          return (
            <span data-tag="allowRowEvents">{row.orders.length || '0'}</span>
          )
        },
      },
      {
        name: 'Reason',
        selector: (row: VoucherListType) => row.reason,
        sortable: true,
        center: false,
        width: '260px',
        cell: (row: VoucherListType) => {
          return <span data-tag="allowRowEvents">{row.reason || '-'}</span>
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleChangeAvailability, isDisabledLoading],
  )

  return (
    <>
      <Helmet title="Magic Vouchers" />
      <S.Content>
        <S.Header>
          <h1>Magic Vouchers</h1>
        </S.Header>

        <S.Actions>
          <S.PeriodSelected>
            <strong>Created:</strong>{' '}
            {startDate && format(startDate, 'MMM d, yyyy')} to{' '}
            {endDate && format(endDate, 'MMM d, yyyy')}
          </S.PeriodSelected>

          <S.DatepickerWrapper>
            <DateRangePicker
              label="Choose period"
              labelFor="period"
              start={startDate}
              end={endDate}
              onSelectDate={(update) => {
                if (update && Array.isArray(update)) {
                  setDateRange(update)
                }
              }}
              position={isMobile ? 'center' : 'right'}
              loading={isLoading}
            />
          </S.DatepickerWrapper>
        </S.Actions>

        <S.ContentTable>
          {isLoading ? (
            <>
              <Box
                flex
                alignItems="center"
                JustifyContent="space-between"
                width="100%"
                style={{ marginBottom: '1.75rem' }}
              >
                <Skeleton
                  width="2.5rem"
                  height="0.875rem"
                  count={5}
                  radius="4px"
                  direction="row"
                />
              </Box>

              <Skeleton
                height="2.5rem"
                count={7}
                radius="4px"
                margin="2.5rem"
              />
            </>
          ) : hasError ? (
            <Box
              flex
              JustifyContent="center"
              alignItems="center"
              width="100%"
              height="31.25rem"
            >
              <Empty
                title="Something Happened"
                description="Reload the page and try again"
              />
            </Box>
          ) : vouchers?.length === 0 ? (
            <Box
              flex
              JustifyContent="center"
              alignItems="center"
              width="100%"
              height="31.25rem"
            >
              <Empty
                title="There is nothing here, yet"
                description="Create a new voucher to see it here"
              />
            </Box>
          ) : (
            <TableData<VoucherListType>
              data={vouchers || []}
              columns={columns}
              onEdit={openVoucherDetailModal}
            />
          )}
        </S.ContentTable>

        {modalIsOpen && renderVoucherDetailModal()}
      </S.Content>
    </>
  )
}

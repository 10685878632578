import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Content = styled.main`
  ${({ theme }) => css`
    padding-top: 1.813rem;
    display: flex;
    gap: ${theme.spacings.small};
    overflow: hidden;
    overflow-x: auto;

    .tooltip {
      border-radius: 8px;
      font-family: ${theme.font.quicksand};
      font-size: ${theme.font.sizes.xsmall};
    }

    ${media.lessThan('medium')`
      flex-direction: column;
    `}
  `}
`

export const Label = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.nunito};
    font-weight: ${theme.font.regular};
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.supportGray};
    margin-bottom: ${theme.spacings.small};
    margin-left: ${theme.spacings.xsmall};
  `}
`

export const CardListWrapper = styled.div`
  width: 100%;
  min-width: 15rem;
  max-width: 40%;

  ${media.lessThan('medium')`
    max-width: 100%;
  `}
`

export const CardFormWrapper = styled.div`
  width: 100%;
`

export const Card = styled.section`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.mainBg};
    border-radius: ${theme.border.radius.xlarge};
    padding: ${theme.spacings.small};
  `}
`

import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { titleCase } from 'title-case'

import settingsService from '@/api/settings.service'
import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { ModalFooter, ModalOverflow } from '@/components/Modal'
import SelectButton from '@/components/SelectButton'
import Skeleton from '@/components/Skeleton'
import TextField from '@/components/TextField'

import * as S from './styles'

export type CategorySelectedType = {
  name: string
  value: string
  selected: boolean
}

export type EditSettingsCategoryModalProps = {
  categories: CategorySelectedType[]
  closeEditSettingsCategory: (categories?: CategorySelectedType[]) => void
}

type FormErrorsType = {
  categories: string
}

const EditSettingsCategoryModal = ({
  categories,
  closeEditSettingsCategory,
}: EditSettingsCategoryModalProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [categoriesSelected, setCategoriesSelected] = useState<
    CategorySelectedType[]
  >([])
  const [formErrors, setFormErrors] = useState<FormErrorsType>({
    categories: '',
  })
  const [filteredCategoriesSelected, setFilteredCategoriesSelected] = useState<
    CategorySelectedType[]
  >([])
  const [filterText, setFilterText] = useState('')

  const handleFilterChange = (
    search: string,
    categoriesSelected: CategorySelectedType[],
  ) => {
    if (search) {
      const categoriesSelectedFiltered = categoriesSelected.filter(
        ({ name }) => name && name.toLowerCase().includes(search.toLowerCase()),
      )
      setFilteredCategoriesSelected([...categoriesSelectedFiltered])
    } else {
      setFilteredCategoriesSelected(categoriesSelected)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(_.debounce(handleFilterChange, 300), [])

  const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setFilterText(value)
  }

  const handleSelectCategory = useCallback(
    (category: CategorySelectedType) => {
      setFormErrors((old) => ({ ...old, categories: '' }))

      const newCategoriesSelected = [...categoriesSelected]
      const categoryIndex = newCategoriesSelected.findIndex(
        (cat) => cat.value === category.value,
      )

      newCategoriesSelected[categoryIndex] = {
        ...category,
        selected: !category.selected,
      }
      setCategoriesSelected(newCategoriesSelected)
    },
    [categoriesSelected],
  )

  const saveCategoriesSelected = useCallback(async () => {
    setIsSaveLoading(true)
    const categories = categoriesSelected.filter((c) => c.selected)

    if (!categories.length) {
      setFormErrors((old) => ({
        ...old,
        categories: 'Select at least one category',
      }))
      setIsSaveLoading(false)
      return
    }

    setFormErrors((old) => ({ ...old, categories: '' }))

    closeEditSettingsCategory(categories)
    setIsSaveLoading(false)
  }, [categoriesSelected, closeEditSettingsCategory])

  const getCategoriesOptions = useCallback(async () => {
    try {
      setIsLoading(true)
      const {
        data: { categories: catOptions },
      } = await settingsService.getMerchantCategoriesOptions()

      setCategoriesSelected(
        catOptions.map((cat) => ({
          name: titleCase(cat.toLowerCase()),
          value: cat.toLowerCase(),
          selected: categories
            ? categories.some((c) => c.value === cat.toLowerCase())
            : false,
        })),
      )
    } catch (error) {
      setFilteredCategoriesSelected([])
    } finally {
      setIsLoading(false)
    }
  }, [categories])

  useEffect(() => {
    if (categoriesSelected.length > 0) {
      debouncedOnChange(filterText, categoriesSelected)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, categoriesSelected])

  useEffect(() => {
    getCategoriesOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ModalOverflow>
        <S.SearchWrapper>
          <TextField
            type="text"
            name="search_menu"
            placeholder="Search by name"
            disabled={categoriesSelected.length === 0}
            value={filterText}
            iconPosition="right"
            onChange={handleChangeFilter}
            variant="material"
          />
        </S.SearchWrapper>

        <S.Content>
          <S.MenuForm>
            <Box flex flexDirection="column">
              <S.Categories>
                {isLoading ? (
                  <Box flex gap="1rem" JustifyContent="center" flexWrap="wrap">
                    <Skeleton
                      width="8.125rem"
                      height="2rem"
                      count={8}
                      margin={0}
                      radius={4}
                    />
                  </Box>
                ) : (
                  filteredCategoriesSelected.map((category, index) => (
                    <SelectButton
                      key={`${category.value}-${index}`}
                      selected={category.selected}
                      onClick={() => handleSelectCategory(category)}
                      disabled={isSaveLoading}
                    >
                      {category.name}
                    </SelectButton>
                  ))
                )}
              </S.Categories>
              {formErrors.categories && (
                <S.TextError>{formErrors.categories}</S.TextError>
              )}
            </Box>
          </S.MenuForm>
        </S.Content>
      </ModalOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
          style={{ marginTop: '1rem' }}
        >
          <Button
            color="white"
            size="large"
            disabled={isSaveLoading}
            onClick={() => closeEditSettingsCategory()}
            shadow
          >
            Cancel
          </Button>

          <Button
            size="large"
            disabled={isSaveLoading}
            onClick={saveCategoriesSelected}
            loading={isSaveLoading}
            style={{ marginLeft: 'auto' }}
          >
            Save
          </Button>
        </Box>
      </ModalFooter>
    </>
  )
}

export default EditSettingsCategoryModal

import styled, { css } from 'styled-components'

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacings.xxsmall} ${theme.spacings.small};
    margin-bottom: ${theme.spacings.small};
  `}
`

export const Name = styled.h4`
  ${({ theme }) => css`
    font-weight: ${theme.font.medium};
    line-height: 1.375rem;
  `}
`

export const Description = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.medium};
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.gray};
    line-height: 1.375rem;
  `}
`

export const SearchWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.small};
  `}
`

export const SelectOption = styled.p`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
      font-weight: ${theme.font.medium};
      font-size: ${theme.font.sizes.medium};
    }
  `}
`

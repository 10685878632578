import * as S from './styles'

export type InfoValueProps = {
  info: string
  value: string
  currency?: string
}

const InfoValue = ({ info, value, currency }: InfoValueProps) => (
  <S.Wrapper>
    <S.Info>{info}</S.Info>
    <S.Value>
      {!!currency && <S.Currency>{currency}</S.Currency>}
      {value}
    </S.Value>
  </S.Wrapper>
)

export default InfoValue

import { darken } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { Box } from '@/components/Box'
import { Wrapper as ButtonWrapper } from '@/components/Button/styles'
import { Wrapper as WrapperRadio } from '@/components/Radio/styles'

export const WrapperContent = styled.div`
  ${({ theme }) => css`
    .tooltip {
      border-radius: 8px;
      font-family: ${theme.font.quicksand} !important;
      font-size: ${theme.font.sizes.xsmall} !important;
    }

    ${media.lessThan('medium')`
      > ${Box}:first-child {
        flex-direction: column;
      }
    `}
  `}
`

export const FormControl = styled.div`
  ${({ theme }) => css`
    margin: 0 ${theme.spacings.small} ${theme.spacings.xxsmall};
    flex: 1;
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacings.medium} ${theme.spacings.small};
    margin-bottom: ${theme.spacings.small};
  `}
`

export const MenuForm = styled.div`
  display: flex;

  ${media.lessThan('medium')`
    flex-direction: column;

    ${Box} {
      width: 100%;
    }
  `}
`

export const LabelForm = styled.label`
  ${({ theme }) => css`
    font-size: 1.125rem;
    font-weight: ${theme.font.semiBold};
  `}
`

export const Categories = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const ErrorCategories = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.red};
    font-size: ${theme.font.sizes.xsmall};
  `}
`

export const PriceWrapper = styled.div`
  max-width: 5.625rem;
  width: 100%;
`

export const ImageWrapper = styled.div<{ picture?: string }>`
  ${({ theme, picture = '' }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dcdcdc;
    width: 14.5rem;
    height: 11.5rem;
    border-radius: 9px;

    ${!!picture &&
    css`
      background-image: url('${picture}');
      background-size: cover;
    `}

    ${media.lessThan('medium')`
      width: 6rem;
      height: 4.75rem;
      align-self: center;
      margin-bottom: ${theme.spacings.xsmall};
    `}
  `}
`

export const InputFile = styled.input`
  display: none;
`

export const Label = styled.label`
  ${({ theme }) => css`
    font-weight: ${theme.font.medium};
    display: block;
    margin-bottom: 0.5rem;
  `}
`

export const RadioInline = styled.div`
  ${({ theme }) => css`
    display: flex;

    ${WrapperRadio} {
      margin-right: ${theme.spacings.medium};
    }
  `}
`

export const UploadButton = styled.button`
  ${({ theme }) => css`
    border: 0;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    border-radius: 50%;
    padding: 1.125rem;
    cursor: pointer;
    transition: background ${theme.transition.default};

    &:hover {
      background-color: ${darken(0.2, theme.colors.primary)};
    }

    ${media.lessThan('medium')`
      padding: 0.531rem;

      svg {
        width: 0.938rem;
        height: 0.75rem;
      }
    `}
  `}
`

export const SelectModifiers = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.small};
  `}
`

export const Name = styled.h4`
  ${({ theme }) => css`
    font-weight: ${theme.font.medium};
    line-height: 1.375rem;
  `}
`

export const Note = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.medium};
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.gray};
    line-height: 1.375rem;
  `}
`

export const SelectOption = styled.p`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
      font-weight: ${theme.font.medium};
      font-size: ${theme.font.sizes.medium};
    }
  `}
`

export const Actions = styled.div`
  display: flex;

  ${PriceWrapper} {
    margin-right: 1.5rem;
  }

  ${media.lessThan('medium')`
    flex-direction: column;

    ${PriceWrapper} {
      margin-bottom: 0.5rem;
    }
  `}
`

export const OptionPrice = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.font.medium};
    font-size: ${theme.font.sizes.medium};
  `}
`

export const Action = styled.button`
  ${({ theme }) => css`
    border: none;
    background-color: transparent;
    color: ${theme.colors.primary};
    font-weight: ${theme.font.semiBold};
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.quicksand};
    min-width: 5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition:
      background ${theme.transition.default},
      color ${theme.transition.default};

    &:hover {
      color: ${darken(0.2, theme.colors.primary)};

      ${ButtonIcon} {
        background-color: ${darken(0.2, theme.colors.primary)};
      }
    }
  `}
`

export const ButtonIcon = styled.span`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    border: none;
    border-radius: 4px;
    color: ${theme.colors.white};
    padding: 0.188rem 0.25rem;
    margin-right: 0.5rem;

    svg {
      margin-bottom: 0.125rem;
    }
  `}
`

export const Buttons = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 ${theme.spacings.small} ${theme.spacings.xxsmall};

    ${ButtonWrapper} {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;

      &:last-child {
        margin-right: 0;

        ${media.lessThan('medium')`
          width: 100%;
        `}
      }
    }
  `}
`

export const TextError = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.red};
    font-size: ${theme.font.sizes.xsmall};
  `}
`

import { darken } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { ListItem } from '@/components/List/styles'

export const Content = styled.div`
  ${({ theme }) => css`
    padding-top: 1.813rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(16.875rem, 1fr));
    gap: ${theme.spacings.small};

    .tooltip {
      border-radius: 8px;
      font-family: ${theme.font.quicksand};
      font-size: ${theme.font.sizes.xsmall};
    }

    ${media.lessThan('medium')`
      gap: ${theme.spacings.xxsmall};
    `}
  `}
`

export const Card = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacings.small};
    background-color: ${theme.colors.mainBg};
    padding: ${theme.spacings.large} ${theme.spacings.small};
    border-radius: ${theme.border.radius.medium};
  `}
`

export const CardIcon = styled.div`
  ${({ theme }) => css`
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 99999px;
    border: 2px solid ${theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

export const CardTitle = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.quicksand};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.bold};
    text-align: center;
  `}
`

export const CardDescription = styled.div`
  ${({ theme }) => css`
    text-align: center;
    color: ${theme.colors.gray};
  `}
`

export const CardList = styled.div`
  ${({ theme }) => css`
    width: 100%;

    ${ListItem} {
      transition: background-color 0.2s ease-in-out;
      padding-left: ${theme.spacings.xxsmall};
      padding-right: 0.5rem;
      border-radius: ${theme.border.radius.small};
      cursor: pointer;

      &:hover {
        background-color: ${darken(0.05, theme.colors.mainBg)};
      }
    }
  `}
`

export const ListItemTitle = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    width: 100%;
    padding-right: 0.5rem;
  `}
`

export const ListIcon = styled.div`
  ${({ theme }) => css`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 99999px;
    background-color: ${theme.colors.white};
    box-shadow: 1px 0.2px 0.7px 0 rgba(67, 61, 61, 0.09);
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { InputWrapper } from '@/components/TextField/styles'

export const FormControl = styled.div`
  ${({ theme }) => css`
    margin: 0 ${theme.spacings.small} calc(${theme.spacings.small} - 0.25rem);

    ${InputWrapper} {
      width: 60%;

      ${media.lessThan('medium')`
        width: 100%;
      `}
    }
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacings.medium} ${theme.spacings.small};
    margin-bottom: ${theme.spacings.small};

    .tooltip {
      border-radius: 0.5rem;
      font-family: ${theme.font.quicksand} !important;
      font-size: ${theme.font.sizes.xsmall} !important;
    }
  `}
`

export const MenuForm = styled.div`
  display: flex;

  ${media.lessThan('medium')`
    flex-direction: column;

    ${Box} {
      width: 100%;
    }
  `}
`

export const LabelForm = styled.label`
  ${({ theme }) => css`
    font-size: 1.125rem;
    font-weight: ${theme.font.semiBold};
    margin-bottom: ${theme.spacings.xsmall};
  `}
`

export const Days = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Hours = styled.div`
  display: flex;
  align-items: center;
`

export const Hour = styled.div<{ first?: boolean }>`
  ${({ theme, first = false }) => css`
    ${InputWrapper} {
      ${first &&
      css`
        margin-right: ${theme.spacings.xxsmall} !important;
      `}
    }
  `}
`

export const ButtonAdd = styled(Button)`
  ${({ theme }) => css`
      font-weight: ${theme.font.medium};
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.gray};
      border-bottom: 1px solid #979797;
      border-radius: 0;
      padding: 0 0.25rem;
      margin-top: ${theme.spacings.xsmall};
    }
  `}
`

export const SavedItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SavedItemContainer = styled.div`
  display: flex;

  ${media.lessThan('medium')`
      flex-direction: column;
  `}
`

type SavedItemProps = {
  hours?: boolean
}

export const SavedItem = styled.div<SavedItemProps>`
  ${({ theme, hours = false }) => css`
    width: 100%;

    h5 {
      font-weight: ${theme.font.bold};
      font-size: ${theme.font.sizes.medium};
      margin-bottom: 0.5rem;
    }

    ${media.greaterThan('medium')`
      min-width: ${hours ? '12.5rem' : '18.75rem'};
    `}

    ${media.lessThan('medium')`
      &:last-child {
        margin-top: 1.25rem;
      }
    `}
  `}
`

export const SavedDescription = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.font.medium};
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.gray};
  `}
`

export const Actions = styled.div`
  ${media.lessThan('medium')`
      min-width: 5rem;
  `}
`

export const ButtonIcon = styled(Button)`
  padding: 0.5rem;
`

export const Name = styled.h4`
  ${({ theme }) => css`
    font-weight: ${theme.font.medium};
    line-height: 1.375rem;
  `}
`

export const Note = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.medium};
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.gray};
    line-height: 1.375rem;
  `}
`

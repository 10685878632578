import styled, { css } from 'styled-components'

export const Wrapper = styled.main`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacings.xxlarge} ${theme.spacings.small};
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.spacings.xlarge};
  `}
`

export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.nunito};
    font-weight: ${theme.font.extraBold};
    font-size: ${theme.font.sizes.xlarge};
    color: ${theme.colors.primary};
  `}
`

import { useCallback, useState } from 'react'

import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { MagicVouchersList } from '@/components/MagicVouchersList'
import { ModalFooter, ModalOverflow } from '@/components/Modal'
import TextField from '@/components/TextField'
import Toast from '@/components/Toast'
import useAuth from '@/hooks/useAuth'
import { api } from '@/lib/axios'
import { MagicVoucher } from '@/types/super-partner.types'

import * as S from './styles'

type MagicVoucherModalProps = {
  selectedVouchers?: MagicVoucher[]
  onCloseMagicVoucher: (voucher: MagicVoucher | null) => void
}

export const MagicVoucherModal = ({
  selectedVouchers,
  onCloseMagicVoucher,
}: MagicVoucherModalProps) => {
  const [voucherId, setVoucherId] = useState('')
  const [isAddNewVoucher, setIsAddNewVoucher] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { merchantSelected, setMagicVoucherAnimation } = useAuth()

  const handleSave = useCallback(async () => {
    try {
      setIsLoading(true)
      setMagicVoucherAnimation(true)
      setTimeout(() => {
        setMagicVoucherAnimation(false)
      }, 3000)

      const { data } = await api.post(
        `/merchants/${merchantSelected?.id}/vouchers/${voucherId}`,
      )

      Toast({
        title: 'Success',
        message: 'Voucher added successfully',
        type: 'success',
      })

      onCloseMagicVoucher(data)
      setIsAddNewVoucher(false)
    } finally {
      setIsLoading(false)
    }
  }, [
    merchantSelected?.id,
    onCloseMagicVoucher,
    setMagicVoucherAnimation,
    voucherId,
  ])

  return (
    <>
      <ModalOverflow>
        {isAddNewVoucher ? (
          <>
            <S.Description>
              Simply type or scan the Bento Magic Voucher. It works like magic.
            </S.Description>

            <S.Section>
              <TextField
                variant="material"
                placeholder="Magic Voucher Code"
                value={voucherId}
                onChange={(event) => setVoucherId(event.target.value)}
              />
            </S.Section>
          </>
        ) : (
          <S.Section style={{ marginTop: 0 }}>
            <MagicVouchersList
              selectedVouchers={selectedVouchers}
              onSetVoucher={onCloseMagicVoucher}
              hoverable
            />
          </S.Section>
        )}
      </ModalOverflow>

      <ModalFooter simple={1}>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
          style={{ marginTop: '1rem' }}
        >
          <Button
            color="white"
            size="large"
            onClick={() =>
              isAddNewVoucher
                ? setIsAddNewVoucher(false)
                : onCloseMagicVoucher(null)
            }
            disabled={isLoading}
            shadow
          >
            {isAddNewVoucher ? 'Back to list' : 'Cancel'}
          </Button>
          <span />
          <Button
            size="large"
            onClick={() =>
              isAddNewVoucher ? handleSave() : setIsAddNewVoucher(true)
            }
            disabled={isLoading || (!voucherId && isAddNewVoucher)}
          >
            {isAddNewVoucher ? 'Save' : 'Add new voucher'}
          </Button>
        </Box>
      </ModalFooter>
    </>
  )
}

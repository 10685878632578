import { zodResolver } from '@hookform/resolvers/zod'
import { addMonths, differenceInMonths, startOfMonth } from 'date-fns'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { StepForm, StepOverflow } from '@/components/FormStep'
import { useFormStepData } from '@/components/FormStep/Hook'
import {
  ModalFooter,
  ModalHeader,
  ModalHeaderClose,
  ModalHeaderTitle,
} from '@/components/Modal'
import {
  Header,
  ImageWrapper,
  Info,
  WrapperContent,
} from '@/modals/CreatingNewCampaignModal/styles'
import { Campaign, CampaignList } from '@/types/super-partner.types'
import { formatCurrencyWithCode } from '@/utils/formats/number'

import { AudienceField } from '../../AudienceField'
import { BudgetField } from '../../BudgetField'
import { CampaignField } from '../../CampaignField'
import { MinimumOrderField } from '../../MinimumOrderField'
import { PeriodExpireField } from '../../PeriodExpireField'
import { Total } from '../../Total'
import { BeforeType } from '..'

export type CampaignListInitialData = {
  campaign: {
    reward: number
    orders: number
  }
}

export type LoyaltyFormProps = {
  close: (isSuccess?: boolean) => void
  campaignItem: CampaignList<CampaignListInitialData> | null
  campaign: Campaign
  active: boolean
  before: BeforeType
  onNextStep: () => void
}

const loyaltyFormSchema = z
  .object({
    audiences: z
      .array(
        z.object({
          id: z.string(),
          name: z.string(),
          count: z.number(),
          selected: z.boolean(),
        }),
      )
      .max(5, 'Max 5 audiences')
      .optional(),
    usersPercentage: z.number().optional(),
    total: z.number().optional(),
    selectedCount: z.number().optional(),
    campaign: z.object(
      {
        reward: z.coerce.number(),
        orders: z.coerce.number(),
      },
      { required_error: 'Campaign is required' },
    ),
    budget: z.object(
      {
        vouchers: z.coerce.number(),
      },
      { required_error: 'Budget is required' },
    ),
    minimumOrderValue: z.coerce.number({
      invalid_type_error: 'Minimum order amount is required',
    }),
    period: z
      .object(
        { startAt: z.date(), endAt: z.date() },
        { required_error: 'Period to expire is required' },
      )
      .refine(
        (data) => {
          const { startAt, endAt } = data
          const months =
            differenceInMonths(startOfMonth(endAt), startOfMonth(startAt)) + 1
          return months > 2
        },
        { message: 'Minimum 3 months' },
      ),
  })
  .refine(
    (data) => {
      return data.campaign.reward <= data.minimumOrderValue
    },
    ({ campaign }) => ({
      path: ['minimumOrderValue'],
      message: `Minimum order amount must be greater than or equal to campaign reward (${formatCurrencyWithCode(
        campaign.reward / 100,
      )})`,
    }),
  )

export type LoyaltyFormData = z.infer<typeof loyaltyFormSchema>

export const LoyaltyForm = ({
  campaignItem,
  campaign,
  onNextStep,
  active,
  before,
  close,
}: LoyaltyFormProps) => {
  const { dataForm, setStepFormValues } = useFormStepData<LoyaltyFormData>()

  const initialValues = campaignItem?.initialData

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    setError,
    formState: { isValid, isSubmitting },
  } = useForm<LoyaltyFormData>({
    resolver: zodResolver(loyaltyFormSchema),
    mode: 'onChange',
    defaultValues: {
      audiences: dataForm?.audiences || undefined,
      campaign: dataForm?.campaign || initialValues?.campaign || undefined,
      period: dataForm?.period || {
        startAt: startOfMonth(new Date()),
        endAt: addMonths(startOfMonth(new Date()), 2),
      },
      total: dataForm?.total || 0,
      budget: dataForm?.budget || undefined,
      minimumOrderValue: dataForm?.minimumOrderValue || undefined,
      selectedCount: dataForm?.selectedCount || 999,
    },
  })

  const onSubmit = (values: LoyaltyFormData) => {
    setStepFormValues(values)
    onNextStep()
  }

  const campaignLoyalty = watch('campaign')
  const budget = watch('budget')
  const audiences = watch('audiences')
  const total = watch('total')
  const selectedCount = watch('selectedCount')
  const usersPercentage = watch('usersPercentage')

  const calculateAudienceCount = useMemo(() => {
    if (!usersPercentage || !selectedCount) return 0
    return Number(((usersPercentage / 100) * selectedCount).toFixed(0))
  }, [usersPercentage, selectedCount])

  return (
    <>
      <StepForm onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          <ModalHeaderTitle>{campaign?.title}</ModalHeaderTitle>

          <ModalHeaderClose
            onClick={(event) => {
              event.preventDefault()
              close()
            }}
          >
            ✕
          </ModalHeaderClose>
        </ModalHeader>

        <StepOverflow active={active ? 1 : 0} before={before.active ? 1 : 0}>
          <WrapperContent>
            <Header>
              <Info>
                <p>{campaign?.description}</p>
              </Info>

              <ImageWrapper>
                <img src={campaign?.image} alt={campaign?.title} />
              </ImageWrapper>
            </Header>

            <Controller
              name="campaign"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CampaignField
                  selectedCount={selectedCount}
                  campaign={campaignLoyalty}
                  onChange={field.onChange}
                  error={error?.message}
                  before={before.active && before.field === 'campaign'}
                  isInitialDataCustom={
                    initialValues?.campaign &&
                    initialValues.campaign.reward > 1500
                  }
                />
              )}
            />

            <Controller
              name="budget"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <BudgetField
                  budget={budget}
                  onChange={field.onChange}
                  error={error?.message}
                />
              )}
            />

            <Controller
              name="minimumOrderValue"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <MinimumOrderField
                  value={field.value}
                  onChange={field.onChange}
                  error={error?.message}
                />
              )}
            />

            <Controller
              name="audiences"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <AudienceField
                  value={field.value}
                  onChange={field.onChange}
                  error={error?.message}
                  onSelectedCount={(value) => {
                    if (value === 0 && audiences && audiences?.length > 0) {
                      setError('audiences', {
                        type: 'manual',
                        message: '',
                      })
                    }
                    setValue('selectedCount', value)
                  }}
                  onSelectPercentage={(value) =>
                    setValue('usersPercentage', value)
                  }
                  onAudiencePrice={(value) => setValue('total', value)}
                  before={before.active && before.field === 'audiences'}
                  campaignType={campaign?.type}
                />
              )}
            />

            <Controller
              name="period"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <PeriodExpireField
                  value={field.value}
                  onChange={field.onChange}
                  error={error?.message}
                />
              )}
            />
          </WrapperContent>
        </StepOverflow>

        <ModalFooter>
          <Total
            audiences={audiences || []}
            total={total || 0}
            selectedCount={calculateAudienceCount || 999}
          />

          <Box
            flex
            alignItems="center"
            JustifyContent="space-between"
            width="100%"
          >
            <Button size="large" color="white" onClick={() => close()} shadow>
              Cancel
            </Button>
            <Button
              type="submit"
              size="large"
              disabled={!isValid || isSubmitting}
            >
              Continue
            </Button>
          </Box>
        </ModalFooter>
      </StepForm>
    </>
  )
}

import { AxiosResponse } from 'axios'
import { format } from 'date-fns'

import { api } from '@/lib/axios'
import {
  DashboardType,
  MerchantDowntimeType,
  MultipleMerchantsDashboardType,
} from '@/types/dashboard.types'

export type DashboardSendProps = { start: string; end: string }

const merchantDashboard = (
  { start, end }: DashboardSendProps,
  uuid: string,
): Promise<AxiosResponse<DashboardType>> => {
  return api.post<DashboardType>(`/merchants/${uuid}/dashboard`, {
    start,
    end,
  })
}

const multipleMerchantsDashboard = (
  { start, end }: DashboardSendProps,
  ids: string[],
): Promise<AxiosResponse<MultipleMerchantsDashboardType[]>> | void => {
  if (ids.length > 0)
    return api.get<MultipleMerchantsDashboardType[]>(
      `/multiple-merchants/dashboard?merchants=${ids.join(
        ',',
      )}&start=${start}&end=${end}`,
    )
}

const setOrderRead = (orderId: string): Promise<AxiosResponse<void>> => {
  return api.patch(`orders/${orderId}/acknowledge`, { orderId })
}

const saveTabletStatus = (
  merchantId: string,
  battery: number,
  version: string,
  token: string,
): Promise<AxiosResponse<void>> => {
  if (token) {
    return api.post(`merchants/${merchantId}/tablets/status`, {
      battery,
      version,
      token,
    })
  }
  return api.post(`merchants/${merchantId}/tablets/status`, {
    battery,
    version,
  })
}

const multipleMerchantsSaveTabletStatus = (
  merchantsIds: string[],
  battery: number,
  version: string,
  token: string,
): Promise<AxiosResponse<void>> | void => {
  if (token && merchantsIds.length > 0) {
    return api.post(
      `multiple-merchants/tablets/status?merchants=${merchantsIds.join(',')}`,
      {
        battery,
        version,
        token,
      },
    )
  }

  if (merchantsIds.length > 0)
    return api.post(
      `multiple-merchants/tablets/status?merchants=${merchantsIds.join(',')}`,
      {
        battery,
        version,
      },
    )
}

const getMerchantDowntime = (
  merchantId: string,
  { start, end }: DashboardSendProps,
): Promise<AxiosResponse<MerchantDowntimeType[]>> => {
  return api.get(`merchants/${merchantId}/downtime`, {
    params: {
      start: format(new Date(start), 'yyyy-MM-dd'),
      end: format(new Date(end), 'yyyy-MM-dd'),
    },
  })
}

export default {
  merchantDashboard,
  multipleMerchantsDashboard,
  setOrderRead,
  saveTabletStatus,
  multipleMerchantsSaveTabletStatus,
  getMerchantDowntime,
}

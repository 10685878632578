import styled, { css } from 'styled-components'

import { Wrapper as WrapperInput } from '@/components/TextField/styles'
import { FieldContent } from '@/modals/CreatingNewCampaignModal/styles'

export const StyledFieldContent = styled(FieldContent)`
  ${({ theme }) => css`
    display: flex;
    gap: 0.5rem;

    label {
      color: ${theme.colors.gray};
    }

    ${WrapperInput} {
      width: 5rem;
    }
  `}
`

import { ImageUploadType } from '@/modals/EditItemModal'

import { createImage } from './create'

export default async function resizeImg(
  imageSrc: string,
  file: File,
  maxWidth = 2400,
  maxHeight = 2400,
): Promise<ImageUploadType> {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d')

  let width = image.width
  let height = image.height

  if (width > height) {
    if (width > maxWidth) {
      height = height * (maxWidth / width)
      width = maxWidth
    }
  } else {
    if (height > maxHeight) {
      width = width * (maxHeight / height)
      height = maxHeight
    }
  }

  canvas.width = width
  canvas.height = height

  ctx!.drawImage(image, 0, 0, width, height)

  return new Promise((resolve) => {
    canvas.toBlob((fileBlob) => {
      resolve({
        file: new File([fileBlob as Blob], file?.name, {
          lastModified: new Date().getTime(),
          type: file?.type,
        }),
        imagePreview: URL.createObjectURL(fileBlob as Blob),
      })
    }, file?.type)
  })
}

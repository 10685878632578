import { Helmet } from 'react-helmet-async'

import Tabs from '@/components/Tabs'

import { Categories } from './components/categories'
import { Items } from './components/items'
import { Menus } from './components/menus'
import { Modifiers } from './components/modifiers'
import { Overview } from './components/overview'
import * as S from './styles'

interface Props {
  activeTab?: number
}

export function MenuMaker({ activeTab = 0 }: Props) {
  return (
    <>
      <Helmet titleTemplate="%s - Menu Maker | Partner Portal" />
      <S.Wrapper>
        <Tabs
          activeTabProp={activeTab}
          tabs={[
            {
              id: '1',
              title: 'Overview',
              content: <Overview />,
              route: '/menu-maker/overview',
            },
            {
              id: '2',
              title: 'Menus',
              content: <Menus />,
              route: '/menu-maker/menus',
            },
            {
              id: '3',
              title: 'Categories',
              content: <Categories />,
              route: '/menu-maker/categories',
            },
            {
              id: '4',
              title: 'Items',
              content: <Items />,
              route: '/menu-maker/items',
            },
            {
              id: '5',
              title: 'Modifier',
              content: <Modifiers />,
              route: '/menu-maker/modifiers',
            },
          ]}
        />
      </S.Wrapper>
    </>
  )
}

import { Box } from '@/components/Box'
import {
  FieldHeader,
  FieldHeaderTitle,
  FieldWrapper,
} from '@/modals/CreatingNewCampaignModal/styles'
import { Audience, MagicVoucher } from '@/types/super-partner.types'
import {
  formatCurrencyWithCode,
  formatExtNumber,
  formatValueNumber,
} from '@/utils/formats/number'

import * as S from './styles'

export type TotalProps = {
  audiences: Audience[]
  selectedCount: number
  total: number
  magicVoucher?: MagicVoucher[]
}

export const Total = ({
  audiences,
  selectedCount,
  total,
  magicVoucher = [],
}: TotalProps) => {
  const getTotal = () => {
    return (
      total -
      (magicVoucher?.reduce((acc, voucher) => acc + voucher.balance, 0) || 0)
    )
  }

  return (
    <FieldWrapper style={{ padding: 0 }}>
      <FieldHeader>
        <FieldHeaderTitle>
          Subtotal
          <br />
          {audiences?.length > 0 && (
            <S.AudienceText>
              Audiences &bull; reaches ~
              {formatExtNumber(selectedCount !== 999 ? selectedCount : 0, 0)}
            </S.AudienceText>
          )}
        </FieldHeaderTitle>
      </FieldHeader>
      {audiences?.length > 0 && (
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          style={{ marginTop: '0.625rem' }}
        >
          <Box flex flexDirection="column">
            {audiences.map((audience) => (
              <S.Description style={{ margin: '0.25rem 0' }} key={audience.id}>
                - {audience.name}
              </S.Description>
            ))}
          </Box>

          <S.Value>
            {formatCurrencyWithCode(
              formatValueNumber(String(total || '0')) / 100,
            )}
          </S.Value>
        </Box>
      )}
      {magicVoucher.length > 0 &&
        magicVoucher.map((voucher) => (
          <S.Info key={voucher.voucherId}>
            <S.Description>
              {voucher.title} ({voucher.voucherId})
            </S.Description>
            <S.Value green={1}>
              -{' '}
              {formatCurrencyWithCode(
                formatValueNumber(String(voucher.balance || '0')) / 100,
              )}
            </S.Value>
          </S.Info>
        ))}
      <S.Info>
        <S.TotalDescription>Total</S.TotalDescription>
        <S.TotalValue>
          {formatCurrencyWithCode(
            formatValueNumber(String(getTotal() || '0')) / 100,
          )}
        </S.TotalValue>
      </S.Info>
    </FieldWrapper>
  )
}

import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import voucherIllustration from '@/assets/img/voucher-modal-bg.svg'
import Button from '@/components/Button'
import { CloseButton as ModalCloseButton } from '@/components/Modal/styles'

type CampaignLoyaltyProps = {
  loyalty?: boolean
}

export const Header = styled.header<CampaignLoyaltyProps>`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${theme.colors.white};
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: 1rem calc(2rem - 0.625rem) 1.188rem 2rem;
    background-image: url(${voucherIllustration});
    background-repeat: no-repeat;
    background-position: center;
  `};
`

export const CloseButton = styled(ModalCloseButton)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    width: 2.625rem;
    height: 2.625rem;
    text-align: center;
    line-height: 0.8;
    right: 1.25rem;
    position: fixed;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
    z-index: ${theme.layers.alwaysOnTop};

    ${media.lessThan('medium')`
        right: 65px;
        top: 30px;
    `}
  `}
`

export const WrapperTitle = styled.div<CampaignLoyaltyProps>`
  ${({ loyalty = false }) => css`
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-top: 1rem;
    height: 13.375rem;
    max-width: 15.625rem;
    z-index: 1;

    ${media.lessThan('medium')`
      text-shadow: ${
        loyalty
          ? '2px 0 #d12e2f, -2px 0 #d12e2f, 0 2px #d12e2f, 0 -2px #d12e2f, 1px 1px #d12e2f, -1px -1px #d12e2f, 1px -1px #d12e2f, -1px 1px #d12e2f'
          : '2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff'
      };
    `}
  `}
`

export const TitleDescription = styled.h5<CampaignLoyaltyProps>`
  ${({ theme, loyalty = false }) => css`
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.semiBold};
    text-transform: uppercase;

    ${loyalty &&
    css`
      color: ${theme.colors.white};
    `}
  `};
`

export const Title = styled.h1<CampaignLoyaltyProps>`
  ${({ theme, loyalty = false }) => css`
    font-size: 2.25rem;
    font-weight: ${theme.font.bold};
    color: ${loyalty ? theme.colors.white : theme.colors.primary};
    line-height: 1;
  `};
`

export const Content = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.xsmall};
  `};
`

export const CampaignCardsWrapper = styled.div``

export const CampaignCards = styled.div`
  ${({ theme }) => css`
    display: grid;
    gap: ${theme.spacings.xsmall};
    grid-template-columns: 1fr 1fr;
  `};
`

export const ItemNoImage = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 99999px;
    background-color: ${theme.colors.mainBg};
    color: ${theme.colors.lightGray};
  `}
`

export const ItemName = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.quicksand};
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.body};
    text-align: center;
  `}
`

export const ItemPrice = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.quicksand};
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.primary};
  `}
`

CampaignCards.displayName = 'CampaignCards'

export const CampaignCard = styled.div`
  ${({ theme }) => css`
    position: relative;
    padding: ${theme.spacings.xsmall};
    border-radius: 16px;
    min-width: 12.938rem;
    background-color: ${theme.colors.white};
    text-align: center;
    margin-top: ${theme.spacings.xsmall};
    border: 1px solid #d5d5d6;
  `};
`

export const CampaignCardIcon = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: -1.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 3.375rem;
    height: 3.375rem;
    border: 1px solid ${theme.colors.supportSuperLight};
    background-color: ${theme.dark ? theme.colors.black : theme.colors.white};
    border-radius: 99999px;
    display: flex;
    align-items: center;
    justify-content: center;
  `};
`

export const CampaignCardTitle = styled.h3`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.small};
    color: ${theme.colors.supportGray};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.semiBold};
  `};
`

export const CampaignCardDescription = styled.h5`
  ${({ theme }) => css`
    margin-top: 0.125rem;
    color: ${theme.colors.gray};
    font-weight: ${theme.font.medium};
    font-size: ${theme.font.sizes.small};
  `};
`

export const CampaignCardsChartWrapper = styled.div<CampaignLoyaltyProps>`
  ${({ theme, loyalty = false }) => css`
    display: flex;
    gap: ${theme.spacings.xsmall};

    ${media.lessThan('medium')`
      flex-direction: column;
    `}

    ${!loyalty &&
    css`
      flex-direction: column;
    `}
  `};
`

export const CampaignCardsChart = styled.div`
  ${({ theme }) => css`
    display: grid;
    gap: ${theme.spacings.xsmall};
    margin-top: ${theme.spacings.xsmall};
    grid-template-columns: 1fr 1fr;
  `};
`

export const CampaignCardItems = styled.div`
  ${({ theme }) => css`
    position: relative;
    padding: ${theme.spacings.small};
    border-radius: 16px;
    min-width: 12.938rem;
    background-color: ${theme.colors.white};
    display: flex;
    justify-content: start;
    gap: 32px;
    align-items: center;
    width: 100%;
    margin-top: 16px;
    overflow-x: auto;
  `};
`

export const CampaignCardChart = styled.div`
  ${({ theme }) => css`
    position: relative;
    padding: ${theme.spacings.small};
    border-radius: 16px;
    min-width: 12.938rem;
    background-color: ${theme.colors.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `};
`

export const CampaignCardList = styled.div`
  ${({ theme }) => css`
    font-size: 2rem;
    line-height: 1;
    font-weight: ${theme.font.semiBold};
    color: ${theme.colors.primary};
    display: flex;
    flex-direction: row;
    gap: 16px;
  `};
`

export const ItemImage = styled.img`
  border-radius: 40px;
  width: 80px;
  height: 80px;
`

export const CampaignCardChartTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.supportGray};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.semiBold};
    max-width: 5.625rem;

    ${media.lessThan('medium')`
      font-size: ${theme.font.sizes.small};
    `}
  `};
`

export const CampaignCardChartNumber = styled.div`
  ${({ theme }) => css`
    font-size: 2rem;
    line-height: 1;
    font-weight: ${theme.font.semiBold};
    color: ${theme.colors.primary};

    ${media.lessThan('medium')`
      font-size: ${theme.font.sizes.small};
    `}
  `};
`

export const SeeOrdersLink = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-family: ${theme.font.nunito};
    font-size: ${theme.font.sizes.small};
    font-weight: ${theme.font.semiBold};
    cursor: pointer;
    display: flex;
    align-items: center;
  `};
`

export const CampaignChartWrapper = styled.div<CampaignLoyaltyProps>`
  ${({ theme, loyalty = false }) => css`
    position: relative;
    padding: ${theme.spacings.xsmall};
    border-radius: 16px;
    background-color: ${theme.colors.white};
    margin-top: ${theme.spacings.xsmall};
    height: 26.875rem;
    border: 1px solid #d5d5d6;

    ${loyalty &&
    css`
      margin-top: 32px;
      width: 100%;
    `}

    ${media.lessThan('medium')`
      width: 100%;

      ${
        loyalty &&
        css`
          margin-top: 0;
        `
      }
    `}
  `};
`

export const DeleteFab = styled(Button)`
  position: absolute;
  bottom: 15px;
  right: 15px;
`

export const ButtonIcon = styled(Button)`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.spacings.xsmall};
    right: ${theme.spacings.xsmall};
    padding: 0.25rem;
    height: auto;

    svg {
      width: 1.25rem;
      max-height: 1.25rem;
    }
  `}
`

export const TooltipContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
`

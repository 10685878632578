import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'
import { KeyboardArrowDown } from '@styled-icons/material-rounded/KeyboardArrowDown'
import { KeyboardArrowRight } from '@styled-icons/material-rounded/KeyboardArrowRight'
import { useEffect, useState } from 'react'

import { Box } from '@/components/Box'

import * as S from './styles'

export const CollapsibleTrigger = S.Trigger
export const CollapsibleContent = S.Content

export type CollapsibleProps = CollapsiblePrimitive.CollapsibleProps & {
  item: React.ReactNode
  showTrigger?: boolean
}

export const Collapsible = ({
  children,
  open = false,
  showTrigger = true,
  item,
  ...props
}: CollapsibleProps) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  return (
    <CollapsiblePrimitive.Root
      {...props}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <Box flex alignItems="center" width="100%">
        {showTrigger && (
          <CollapsibleTrigger>
            <S.Icon>
              {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </S.Icon>
          </CollapsibleTrigger>
        )}

        {item}
      </Box>

      {children}
    </CollapsiblePrimitive.Root>
  )
}

import TextField from '@/components/TextField'
import {
  FieldError,
  FieldHeader,
  FieldHeaderTitle,
  FieldWrapper,
} from '@/modals/CreatingNewCampaignModal/styles'
import { formatValueNumber } from '@/utils/formats/number'

import { LoyaltyFormData } from '../LoyaltySteps/LoyaltyForm'
import * as S from './styles'

export type BudgetFieldProps = {
  budget?: LoyaltyFormData['budget']
  error?: string
  onChange: (budget?: LoyaltyFormData['budget']) => void
}

export const BudgetField = ({ error, onChange, budget }: BudgetFieldProps) => {
  return (
    <FieldWrapper>
      <FieldHeader>
        <FieldHeaderTitle>Maximum Vouchers</FieldHeaderTitle>
      </FieldHeader>

      <S.StyledFieldContent>
        <label>Set quantity limit:</label>
        <TextField
          variant="material"
          placeholder="0"
          inputMode="numeric"
          mask="numberClean"
          maxLength={3}
          onChange={(e) => {
            onChange({
              vouchers: formatValueNumber(e.target.value),
            })
          }}
          value={String(budget?.vouchers || 0)}
        />
        <label>vouchers</label>

        {error && <FieldError>{error}</FieldError>}
      </S.StyledFieldContent>
    </FieldWrapper>
  )
}

import styled, { css } from 'styled-components'

export const Wrapper = styled.div``

export const DropSelected = styled.div`
  ${({ theme }) => css`
    display: flex;
    font-size: ${theme.font.sizes.xsmall};
    font-family: ${theme.font.nunito};
    font-weight: ${theme.font.bold};
  `}
`

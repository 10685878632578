import { addMonths, startOfMonth } from 'date-fns'
import { useState } from 'react'
import DatePicker from 'react-datepicker'

import {
  FieldContent,
  FieldError,
  FieldHeader,
  FieldHeaderTitle,
  FieldWrapper,
} from '@/modals/CreatingNewCampaignModal/styles'

import * as S from './styles'

export type PeriodExpireFieldProps = {
  value: { startAt: Date; endAt: Date }
  error?: string
  onChange: (value?: { startAt: Date; endAt: Date }) => void
}

export const PeriodExpireField = ({
  value,
  error,
  onChange,
}: PeriodExpireFieldProps) => {
  const [startDate, setStartDate] = useState(value.startAt || new Date())
  const [endDate, setEndDate] = useState(
    value.endAt || addMonths(new Date(), 2),
  )

  const handleChange = ([newStartDate, newEndDate]: [Date, Date]) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
    onChange({
      startAt: newStartDate,
      endAt: newEndDate,
    })
  }

  return (
    <FieldWrapper id="months">
      <FieldHeader>
        <FieldHeaderTitle>
          Period to expire the voucher <span>(Minimum 3 months)</span>
        </FieldHeaderTitle>
      </FieldHeader>

      <FieldContent>
        <S.MonthList>
          <DatePicker
            startDate={startDate}
            endDate={endDate}
            onChange={handleChange}
            dateFormat="MM/yyyy"
            minDate={startOfMonth(new Date())}
            showMonthYearPicker
            showFullMonthYearPicker
            showFourColumnMonthYearPicker
            selectsRange
            inline
          />

          {error && <FieldError>{error}</FieldError>}
        </S.MonthList>
      </FieldContent>
    </FieldWrapper>
  )
}

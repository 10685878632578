import styled, { css, DefaultTheme } from 'styled-components'

import { CardContentProps } from '.'

type WrapperProps = Pick<CardContentProps, 'color' | 'maxHeight' | 'maxWidth'>

const wrapperModifiers = {
  default: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.mainBg};
  `,
  primary: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.primary};
    color: ${theme.colors.primaryText};
  `,
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, color, maxWidth, maxHeight = '14.25rem' }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: ${theme.border.radius.medium};
    padding: 2.75rem ${theme.spacings.small} 3.25rem;

    ${!!maxHeight &&
    css`
      max-height: ${maxHeight};
    `}

    ${!!maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

    > div + div {
      margin-top: 0.5rem;
    }

    ${!!color && wrapperModifiers[color](theme)}
  `}
`

import { useNavigate } from 'react-router-dom'

import successCampaign from '@/assets/img/success-campaign.svg'
import Button from '@/components/Button'

import * as S from './styles'

export type Props = {
  close: () => void
}

export const Success = ({ close }: Props) => {
  const navigate = useNavigate()

  return (
    <S.Wrapper>
      <S.Container>
        <img src={successCampaign} alt="Success campaign" />
      </S.Container>
      <div>
        <S.Title>Woohoo!</S.Title>
        <S.Description>Campaign created successfully</S.Description>
      </div>
      <Button
        onClick={() => {
          navigate('/super-partner/campaigns')
          close()
        }}
      >
        View campaign
      </Button>
    </S.Wrapper>
  )
}

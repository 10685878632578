import {
  Add as AddIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Info as InfoIcon,
} from '@styled-icons/material-outlined'
import { format } from 'date-fns'
import { useCallback, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import menuMakerService from '@/api/menu-maker.service'
import deleteIcon from '@/assets/img/icon-delete.svg'
import editIcon from '@/assets/img/icon-edit.svg'
import { Box } from '@/components/Box'
import Button from '@/components/Button'
import List from '@/components/List'
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverflow,
} from '@/components/Modal'
import SelectButton from '@/components/SelectButton'
import TextField from '@/components/TextField'
import TimePicker from '@/components/TimePicker'
import useModal from '@/hooks/useModal'
import ConfirmModal from '@/modals/ConfirmModal'
import { CategoryType, MenusType, ShiftType } from '@/types/menu-maker.types'
import {
  convertTimeToDate,
  getHoursToEdit,
  getWeekDays,
} from '@/utils/formats/date'
import { SorterNumerically } from '@/utils/formats/sort'

import * as S from './styles'

export type EditMenuModalProps = {
  editMenuData: MenusType | null
  merchant: string
  closeEditMenu: (refresh: boolean) => void
}

type HoursType = {
  start: string
  end: string
}

type FormErrorsType = {
  name: string
  hours: HoursType[]
}

type WeekDayType = {
  name: string
  value: number
  selected: boolean
}

type HoursModalType = {
  start: boolean
  end: boolean
}

const weekDayName: WeekDayType[] = [
  { name: 'Mon', value: 1, selected: false },
  { name: 'Tue', value: 2, selected: false },
  { name: 'Wed', value: 3, selected: false },
  { name: 'Thu', value: 4, selected: false },
  { name: 'Fri', value: 5, selected: false },
  { name: 'Sat', value: 6, selected: false },
  { name: 'Sun', value: 7, selected: false },
]

const hoursDefault: HoursType[] = [{ start: '', end: '' }]
const hoursModalDefault: HoursModalType[] = [{ start: false, end: false }]

const menuDefault: MenusType = {
  categories: [],
  items: [],
  merchant: '',
  name: '',
  shifts: [
    {
      hours: [],
      weekDays: [],
    },
  ],
  status: '',
}

const EditMenuModal = ({
  editMenuData,
  closeEditMenu,
  merchant,
}: EditMenuModalProps) => {
  const [isEditing, setIsEditing] = useState(!editMenuData?.shifts.length)
  const [shiftIndexEdit, setShiftIndexEdit] = useState<number | null>(null)
  const [categories, setCategories] = useState<CategoryType[]>(
    editMenuData?.categories
      ? (editMenuData?.categories as CategoryType[]).sort(
          SorterNumerically<CategoryType>('position'),
        )
      : [],
  )
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [openTimePickerModal, setOpenTimePickerModal] =
    useState<HoursModalType[]>(hoursModalDefault)
  const { open: openConfirmModal, toggle: setOpenConfirmModal } = useModal()
  const [name, setName] = useState(editMenuData?.name || '')
  const [weekDays, setWeekDays] = useState<WeekDayType[]>(weekDayName)
  const [hours, setHours] = useState<HoursType[]>(hoursDefault)
  const [shifts, setShifts] = useState<ShiftType[]>(editMenuData?.shifts || [])
  const [formErrors, setFormErrors] = useState<FormErrorsType>({
    name: '',
    hours: hoursDefault,
  })

  const canBeDone = hours.every((h) => h.start && h.end)

  const handleSelectDay = useCallback(
    (day: WeekDayType, index: number) => {
      const newDays = [...weekDays]
      newDays[index] = { ...day, selected: !day.selected }
      setWeekDays(newDays)
    },
    [weekDays],
  )

  const handleHour = useCallback(
    (type: string, time: string, hour: HoursType, index: number) => {
      const newHours = [...hours]
      newHours[index] = { ...hour, [type]: time }
      setHours(newHours)
    },
    [hours],
  )

  const handleAddNewHour = useCallback(() => {
    setHours((old) => [...old, ...hoursDefault])
    setFormErrors((old) => ({ ...old, hours: [...old.hours, ...hoursDefault] }))
    setOpenTimePickerModal((old) => [...old, ...hoursModalDefault])
  }, [])

  const handleRemoveHour = useCallback(
    (index: number) => {
      const newHours = [...hours]
      newHours.splice(index, 1)
      setHours(newHours)
      const newHoursErrors = [...formErrors.hours]
      newHoursErrors.splice(index, 1)
      setFormErrors((old) => ({ ...old, hours: newHoursErrors }))
      const newModals = [...openTimePickerModal]
      newModals.splice(index, 1)
      setOpenTimePickerModal(newModals)
    },
    [formErrors.hours, hours, openTimePickerModal],
  )

  const setHourModalToggle = useCallback(
    (type: 'start' | 'end', index: number) => {
      const newHoursModal = [...openTimePickerModal]
      newHoursModal[index][type] = !newHoursModal[index][type]
      setOpenTimePickerModal(newHoursModal)
    },
    [openTimePickerModal],
  )

  const handleAddShift = useCallback(() => {
    const weekShift = weekDays
      .filter((day) => day.selected)
      .map((day) => day.value)

    if (shiftIndexEdit !== null) {
      const newShift = [...shifts]
      newShift[shiftIndexEdit] = { hours, weekDays: weekShift }
      setShifts(newShift)
    } else {
      setShifts((old) => [...old, { hours, weekDays: weekShift }])
    }

    setShiftIndexEdit(null)
    setWeekDays(weekDayName)
    setHours(hoursDefault)
    setIsEditing(false)
  }, [hours, shiftIndexEdit, shifts, weekDays])

  const handleEditShift = useCallback(
    (shiftEdit: ShiftType, index: number) => {
      const weekDaysShift = weekDays.map((day) => ({
        ...day,
        selected: !!shiftEdit.weekDays.some((d) => d === day.value),
      }))

      setShiftIndexEdit(index)
      setIsEditing(true)
      setWeekDays(weekDaysShift)
      setHours(shiftEdit.hours)
    },
    [weekDays],
  )

  const handleRemoveShift = useCallback(
    (index: number) => {
      const newShifts = [...shifts]
      newShifts.splice(index, 1)
      if (!newShifts.length) {
        setIsEditing(true)
      }
      setShifts(newShifts)
    },
    [shifts],
  )

  const handleReorder = (categoriesReordered: CategoryType[]) => {
    setCategories(categoriesReordered)
  }

  const validationHoursForm = useCallback(
    (time: string, hour: HoursType, type: string, index: number) => {
      const timeDate = convertTimeToDate(time)
      const timeStart = convertTimeToDate(hour.start)
      const timeEnd = convertTimeToDate(hour.end)
      const newHours = [...formErrors.hours]

      if (type === 'start') {
        if (timeDate.getTime() > timeEnd.getTime()) {
          newHours[index] = {
            start: 'Start time cannot be greater than end time',
            end: '',
          }
          setFormErrors((old) => ({ ...old, hours: newHours }))
          handleHour('start', '', hour, index)
          return
        }
        newHours[index] = {
          start: '',
          end: '',
        }
        setFormErrors((old) => ({ ...old, hours: newHours }))
        handleHour('start', time, hour, index)
      } else {
        if (timeDate.getTime() < timeStart.getTime()) {
          newHours[index] = {
            start: '',
            end: 'End time cannot be less than start time',
          }
          setFormErrors((old) => ({ ...old, hours: newHours }))
          handleHour('end', '', hour, index)
          return
        }
        newHours[index] = {
          start: '',
          end: '',
        }
        setFormErrors((old) => ({ ...old, hours: newHours }))
        handleHour('end', time, hour, index)
      }
    },
    [formErrors.hours, handleHour],
  )

  const saveMenu = useCallback(async () => {
    setIsLoading(true)
    const cats = categories
      .sort(SorterNumerically<CategoryType>('position'))
      .map((cat) => cat.categoryId)
    const data: MenusType = editMenuData
      ? {
          ...editMenuData,
          name,
          shifts,
          categories: cats as string[],
        }
      : {
          ...menuDefault,
          name,
          shifts,
          merchant,
          categories: cats as string[],
        }

    if (!data.name) {
      setFormErrors((old) => ({ ...old, name: 'Name is required' }))
      setIsLoading(false)
      return
    }

    setFormErrors((old) => ({ ...old, name: '' }))

    try {
      await menuMakerService.saveMenu(data)
      closeEditMenu(true)
    } finally {
      setIsLoading(false)
    }
  }, [categories, closeEditMenu, editMenuData, merchant, name, shifts])

  const deleteMenu = useCallback(async () => {
    setIsDeleting(true)
    const data: MenusType = {
      ...editMenuData!,
      name,
      shifts,
    }

    try {
      await menuMakerService.deleteMenu(data)
      closeEditMenu(true)
    } finally {
      setIsDeleting(false)
    }
  }, [closeEditMenu, editMenuData, name, shifts])

  const renderConfirmModal = () => (
    <Modal open={openConfirmModal} onOpenChange={setOpenConfirmModal} modal>
      <ModalContent
        title="Delete Menu"
        onClose={() => setOpenConfirmModal(false)}
        width="auto"
        xsWidth="90vw"
        xsHeight="auto"
        hideCloseButton
      >
        <ConfirmModal
          message={
            <>
              Are you sure you want to delete menu{' '}
              <strong>{editMenuData?.name}</strong>?
            </>
          }
          closeConfirm={(confirm) => {
            if (confirm) deleteMenu()
            setOpenConfirmModal(false)
          }}
        />
      </ModalContent>
    </Modal>
  )

  return (
    <>
      <ModalOverflow>
        <S.FormControl>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={formErrors.name}
            label="Menu's Name"
            labelFor="menus_name"
            id="menus_name"
            disabled={isLoading}
            variant="material"
          />
        </S.FormControl>

        {shifts.map(({ weekDays, hours }, index) => (
          <S.Content key={index}>
            <S.SavedItems>
              <S.SavedItemContainer>
                <S.SavedItem>
                  <h5>Days</h5>
                  <S.SavedDescription>
                    {getWeekDays(weekDays, ' | ')}
                  </S.SavedDescription>
                </S.SavedItem>
                <S.SavedItem hours>
                  <h5>Hours</h5>
                  <S.SavedDescription>
                    {getHoursToEdit(hours)}
                  </S.SavedDescription>
                </S.SavedItem>
              </S.SavedItemContainer>

              <S.Actions>
                <S.ButtonIcon
                  minimal
                  disabled={isEditing || isLoading}
                  onClick={() => handleEditShift({ weekDays, hours }, index)}
                >
                  <img src={editIcon} width={19} height={19} alt="icon edit" />
                </S.ButtonIcon>
                <S.ButtonIcon
                  minimal
                  disabled={isEditing || isLoading}
                  onClick={() => handleRemoveShift(index)}
                >
                  <img
                    src={deleteIcon}
                    width={24}
                    height={24}
                    alt="icon delete"
                  />
                </S.ButtonIcon>
              </S.Actions>
            </S.SavedItems>
          </S.Content>
        ))}

        {isEditing && (
          <S.Content>
            <S.MenuForm>
              <Box flex flexDirection="column" width="65%">
                <S.LabelForm>Menu Days</S.LabelForm>
                <S.Days>
                  {weekDays.map((day, index) => (
                    <SelectButton
                      key={day.value}
                      selected={day.selected}
                      onClick={() => handleSelectDay(day, index)}
                    >
                      {day.name}
                    </SelectButton>
                  ))}
                </S.Days>
              </Box>
              <Box flex flexDirection="column" width="35%">
                <S.LabelForm>Menu Hours</S.LabelForm>

                {hours.map((hour, index) => (
                  <S.Hours key={`${hour.start}${hour.end}_${index}`}>
                    <S.Hour first>
                      <TextField
                        type="text"
                        label="Start Time"
                        labelFor="start"
                        id="start"
                        variant="material"
                        error={formErrors.hours[index]?.start}
                        onClick={() => setHourModalToggle('start', index)}
                        value={
                          hour.start
                            ? format(convertTimeToDate(hour.start), 'hh:mm aa')
                            : ''
                        }
                      />
                      <Modal
                        open={openTimePickerModal[index]?.start}
                        onOpenChange={(open) => {
                          if (!open) setHourModalToggle('start', index)
                        }}
                        modal
                      >
                        <ModalContent
                          onClose={() => setHourModalToggle('start', index)}
                          width="auto"
                          xsWidth="auto"
                          xsHeight="auto"
                          noHeader
                        >
                          <TimePicker
                            time={hour.start || '00:00'}
                            switchToMinuteOnHourSelect
                            onDoneClick={(time) => {
                              validationHoursForm(
                                time.formatted24,
                                hour,
                                'start',
                                index,
                              )
                              setHourModalToggle('start', index)
                            }}
                          />
                        </ModalContent>
                      </Modal>
                    </S.Hour>
                    <S.Hour>
                      <TextField
                        type="text"
                        label="End Time"
                        labelFor="end"
                        id="end"
                        variant="material"
                        error={formErrors.hours[index]?.end}
                        onClick={() => setHourModalToggle('end', index)}
                        value={
                          hour.end
                            ? format(convertTimeToDate(hour.end), 'hh:mm aa')
                            : ''
                        }
                      />
                      <Modal
                        open={openTimePickerModal[index]?.end}
                        onOpenChange={(open) => {
                          if (!open) setHourModalToggle('end', index)
                        }}
                        modal
                      >
                        <ModalContent
                          onClose={() => setHourModalToggle('end', index)}
                          width="auto"
                          xsWidth="auto"
                          xsHeight="auto"
                          noHeader
                        >
                          <TimePicker
                            time={hour.end || '00:00'}
                            switchToMinuteOnHourSelect
                            onDoneClick={(time) => {
                              validationHoursForm(
                                time.formatted24,
                                hour,
                                'end',
                                index,
                              )
                              setHourModalToggle('end', index)
                            }}
                          />
                        </ModalContent>
                      </Modal>
                    </S.Hour>
                    {hours.length > 1 && (
                      <S.ButtonIcon
                        minimal
                        onClick={() => handleRemoveHour(index)}
                      >
                        <CloseIcon size="20" />
                      </S.ButtonIcon>
                    )}
                  </S.Hours>
                ))}

                <Box>
                  <S.ButtonAdd
                    icon={<AddIcon />}
                    iconside="right"
                    minimal
                    onClick={handleAddNewHour}
                  >
                    add new
                  </S.ButtonAdd>
                </Box>
              </Box>
            </S.MenuForm>

            <Box flex JustifyContent="center">
              <Button
                icon={<CheckIcon />}
                iconside="right"
                minimal
                disabled={!canBeDone}
                onClick={handleAddShift}
              >
                done
              </Button>
            </Box>
          </S.Content>
        )}

        {!isEditing && (
          <Box
            flex
            JustifyContent="center"
            style={{ marginBottom: categories.length > 0 ? '1.5rem' : 0 }}
          >
            <Button
              icon={<AddIcon />}
              iconside="right"
              minimal
              onClick={() => setIsEditing(true)}
            >
              add new
            </Button>
          </Box>
        )}

        {categories.length > 0 && (
          <S.Content>
            <S.MenuForm>
              <Box flex flexDirection="column" width="100%">
                <S.LabelForm>
                  List of categories{' '}
                  <InfoIcon
                    size={18}
                    data-tip="Categories where this menu will be listed"
                    data-for="categories_info"
                  />
                  <ReactTooltip
                    effect="solid"
                    className="tooltip"
                    backgroundColor="#433d3d"
                    borderColor="#433d3d"
                    id="categories_info"
                  />
                </S.LabelForm>

                <List<CategoryType>
                  data={categories}
                  onReorder={handleReorder}
                  sorterBy="position"
                  dragAndDrop
                >
                  {(category) => (
                    <Box flex flexDirection="column" width="100%">
                      <S.Name>{category.name}</S.Name>
                      <S.Note>{category.note}</S.Note>
                    </Box>
                  )}
                </List>
              </Box>
            </S.MenuForm>
          </S.Content>
        )}
      </ModalOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
          style={{ marginTop: '1rem' }}
        >
          {editMenuData && (
            <Button
              color="white"
              size="large"
              disabled={isEditing || isDeleting}
              loading={isDeleting}
              onClick={() => setOpenConfirmModal(true)}
              shadow
            >
              Delete menu
            </Button>
          )}

          <Button
            size="large"
            disabled={isEditing || isLoading}
            onClick={saveMenu}
            loading={isLoading}
            style={{ marginLeft: 'auto' }}
          >
            Save
          </Button>
        </Box>
      </ModalFooter>

      {openConfirmModal && renderConfirmModal()}
    </>
  )
}

export default EditMenuModal

import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
`

export const WrapperButton = styled.div<{ cursor: string; added?: number }>`
  ${({ cursor, added = 0 }) => css`
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    cursor: ${cursor};

    ${added === 1 &&
    css`
      opacity: 0.5;
    `}
  `};
`

export const VoucherItemImage = styled.div<{ bg: string }>`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 0.5rem;
  background-color: #d8d8d8;
  background-image: url(${({ bg }) => bg});
  background-position: center center;
  background-size: cover;
`

export const VoucherItemTitle = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.nunito};
    font-size: ${theme.font.sizes.medium};
    font-weight: 800;
    color: ${theme.colors.black};
  `};
`

export const VoucherItemInfo = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.gray};
  `};
`

export const VoucherItemExpireDate = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.gray};
    margin-top: ${theme.spacings.xxsmall};
  `};
`

import styled, { css } from 'styled-components'

export const Info = styled.span`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 16px 0;
`

export const Description = styled.span`
  ${({ theme }) => css`
    font-size: 0.875rem;
    color: ${theme.colors.gray};
    font-weight: ${theme.font.regular};
  `}
`

export const Value = styled.span<{ green?: number }>`
  ${({ theme, green = 0 }) => css`
    font-size: 0.875rem;
    font-family: ${theme.font.nunito};
    font-weight: ${theme.font.regular};
    color: ${green === 1 ? theme.colors.green : 'inherit'};
  `}
`

export const TotalDescription = styled.span`
  ${({ theme }) => css`
    font-size: 1.125rem;
    font-family: ${theme.font.quicksand};
    font-weight: ${theme.font.bold};
  `}
`

export const TotalValue = styled.span`
  ${({ theme }) => css`
    font-size: 1.125rem;
    font-family: ${theme.font.quicksand};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.primary};
  `}
`

export const AudienceText = styled.span`
  ${({ theme }) => css`
    font-size: 1rem;
    font-weight: ${theme.font.medium};
  `}
`

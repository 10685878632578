import styled, { css } from 'styled-components'

import { TopViewsChartProps } from '.'

type WrapperProps = Pick<TopViewsChartProps, 'height'>

export const Wrapper = styled.div<WrapperProps>`
  ${({ height = '32.5rem' }) => css`
    width: 100%;
    min-width: 22.5rem;
    height: ${height};
    margin: 0 auto;
  `}
`

export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.nunito};
    font-size: ${theme.font.sizes.xlarge};
    font-weight: ${theme.font.extraBold};
    margin-bottom: ${theme.spacings.large};
    color: ${theme.colors.primary};
    text-align: center;
  `}
`

export const Tooltip = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.secondary};
    padding: 0.313rem ${theme.spacings.xxsmall};
    color: ${theme.colors.primaryText};
    border-radius: 0.5rem;
  `}
`

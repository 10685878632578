import { AxiosResponse } from 'axios'

import { api } from '@/lib/axios'
import { SavePictureResponseType } from '@/types/menu-maker.types'
import {
  AddressInfoType,
  AddressType,
  IBentoSettings,
  MerchantCategoriesOptions,
  MerchantGeneralInfoType,
  MerchantGeneralSettingsType,
  MerchantHoursType,
} from '@/types/settings.types'

const getMerchantGeneralInfo = (
  merchantId: string,
  userId: string,
): Promise<AxiosResponse<MerchantGeneralInfoType>> => {
  return api.get<MerchantGeneralInfoType>(
    `/merchants/${merchantId}/general-info/${userId}`,
  )
}

const saveMerchantGeneralInfo = (
  data: MerchantGeneralInfoType,
  merchantId: string,
  userId: string,
): Promise<AxiosResponse<MerchantGeneralInfoType>> => {
  return api.post<MerchantGeneralInfoType>(
    `/merchants/${merchantId}/general-info/${userId}`,
    data,
  )
}

const createMerchant = (
  data: MerchantGeneralInfoType,
): Promise<AxiosResponse<{ merchantId: string }>> => {
  return api.post<{ merchantId: string }>(`/merchants/create`, data)
}

const getMerchantAddressInfo = (
  merchantId: string,
  userId: string,
): Promise<AxiosResponse<AddressInfoType>> => {
  return api.get<AddressInfoType>(`/merchants/${merchantId}/address/${userId}`)
}

const saveMerchantAddressInfo = (
  data: AddressType,
  merchantId: string,
  userId: string,
): Promise<AxiosResponse<AddressInfoType>> => {
  return api.post<AddressInfoType>(
    `/merchants/${merchantId}/address/${userId}`,
    data,
  )
}
const getMerchantHours = (
  merchantId: string,
  userId: string,
): Promise<AxiosResponse<MerchantHoursType>> => {
  return api.get<MerchantHoursType>(
    `/merchants/${merchantId}/schedule/${userId}`,
  )
}

const saveMerchantHours = (
  data: MerchantHoursType,
  merchantId: string,
  userId: string,
): Promise<AxiosResponse<MerchantHoursType>> => {
  return api.post<MerchantHoursType>(
    `/merchants/${merchantId}/schedule/${userId}`,
    data,
  )
}

const getMerchantGeneralSettings = (
  merchantId: string,
  userId: string,
): Promise<AxiosResponse<MerchantGeneralSettingsType>> => {
  return api.get<MerchantGeneralSettingsType>(
    `/merchants/${merchantId}/general-settings/${userId}`,
  )
}

const getBentoSettings = async () => {
  const { data } = await api.get<IBentoSettings>(`/bento-settings`)
  return data
}

const saveMerchantGeneralSettings = (
  data: MerchantGeneralSettingsType,
  merchantId: string,
  userId: string,
): Promise<AxiosResponse<MerchantGeneralSettingsType>> => {
  return api.post<MerchantGeneralSettingsType>(
    `/merchants/${merchantId}/general-settings/${userId}`,
    data,
  )
}

const getMerchantCategoriesOptions = (): Promise<
  AxiosResponse<MerchantCategoriesOptions>
> => {
  return api.get<MerchantCategoriesOptions>(`/merchants/categories-options`)
}

const getMerchantTagsOptions = (): Promise<AxiosResponse<string[]>> => {
  return api.get<string[]>(`/merchants/tags-options`)
}

const merchantUpload = (
  merchantId: string,
  file: File,
): Promise<AxiosResponse<SavePictureResponseType[]>> => {
  const formData = new FormData()
  formData.append('file', file)
  return api.post<SavePictureResponseType[]>(
    `/merchants/${merchantId}/upload`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
}

export default {
  getMerchantGeneralInfo,
  getMerchantAddressInfo,
  saveMerchantAddressInfo,
  saveMerchantGeneralInfo,
  createMerchant,
  getMerchantHours,
  saveMerchantHours,
  getMerchantGeneralSettings,
  saveMerchantGeneralSettings,
  getMerchantCategoriesOptions,
  getMerchantTagsOptions,
  merchantUpload,
  getBentoSettings,
}

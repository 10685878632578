import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.06);
`

export const Image = styled.img`
  height: 72px;
`

export const Description = styled.div`
  ${({ theme }) => css`
    h3 {
      font-size: 1rem;
      font-weight: 600;
      color: ${theme.colors.secondary};
    }

    p {
      font-size: 0.75rem;
      font-weight: 500;
      color: ${theme.colors.gray};
    }
  `};
`

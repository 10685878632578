import { cn } from '@iguanads/react'
import { WifiOff as WifiOffIcon } from '@styled-icons/material-outlined'
import { useCallback, useEffect, useState } from 'react'
import { GiBattery25 } from 'react-icons/gi'
import { Outlet, useLocation } from 'react-router-dom'

import { Header } from '@/components/header'
import Menu from '@/components/Menu'
import Snackbar from '@/components/Snackbar'
import { VoucherAnimation } from '@/components/VoucherAnimation'
import { PermissionToPath } from '@/enums/permission.enum'
import useAuth from '@/hooks/useAuth'
import { useScreenLock } from '@/hooks/useScreenLock'

const PermissionPaths: Record<string, string> = {
  '/': '/live-orders',
  '/dashboard': '/dashboard',
  '/sentry': '/sentry',
  '/orders': '/orders',
  '/menu-maker': '/menu-maker',
  '/menu-maker/overview': '/menu-maker',
  '/menu-maker/menus': '/menu-maker',
  '/menu-maker/categories': '/menu-maker',
  '/menu-maker/items': '/menu-maker',
  '/menu-maker/modifiers': '/menu-maker',
  '/super-partner': '/super-partner',
  '/super-partner/new-campaign': '/super-partner',
  '/super-partner/campaigns': '/super-partner',
  '/super-partner/vouchers': '/super-partner',
  '/super-partner/payment': '/super-partner',
  '/settings': '/settings',
  '/admin-settings': '/admin-settings',
}

export function AppLayout() {
  const [isOnline, setIsOnline] = useState(true)
  const [isCharging, setIsCharging] = useState(false)
  const [isNeedingCharge, setIsNeedingCharge] = useState(false)
  const [batteryLevel, setBatteryLevel] = useState(100)
  const { user, magicVoucherAnimation } = useAuth()
  const { pathname } = useLocation()
  useScreenLock()

  const permission =
    user?.permissionsOrdered && PermissionToPath[user?.permissionsOrdered[0]]
  const permissionFirst = PermissionPaths[permission || '/']
  const pathFirst = PermissionPaths[pathname]
  const noRadiusInTopLeft = pathFirst === permissionFirst

  const checkOnlineStatus = useCallback(() => {
    setIsOnline(navigator.onLine)
  }, [])

  const checkNeedCharging = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newVariable: any = window.navigator
    const manager = await newVariable.getBattery()
    const level = Math.round(manager.level * 100)
    const charging = Boolean(manager.charging)

    setIsCharging(charging)
    setBatteryLevel(level)

    setIsNeedingCharge(Boolean(level < 30))
  }, [])

  useEffect(() => {
    if ('getBattery' in navigator) {
      const intervalBatteryStatus = setInterval(checkNeedCharging, 5000)

      return () => clearInterval(intervalBatteryStatus)
    }
  }, [checkNeedCharging])

  useEffect(() => {
    if ('onLine' in navigator) {
      const intervalOnlineStatus = setInterval(checkOnlineStatus, 5000)

      return () => clearInterval(intervalOnlineStatus)
    }
  }, [checkOnlineStatus])

  return (
    <main className="grid h-screen min-w-[22.5rem] grid-cols-1 grid-rows-[auto_1fr] px-[0.625rem] pb-[5.25rem] grid-areas-layout md:grid-cols-[137px_minmax(0,1fr)] md:pb-[0.625rem] md:pl-[0] md:pt-[0] md:grid-areas-layout-md lg:grid-cols-[127px_minmax(0,1fr)] lg:px-[0.75rem] lg:grid-areas-layout-lg">
      <Snackbar
        icon={<WifiOffIcon size={32} />}
        title="It seems that you're offline"
        message="Please check your connection and try again."
        open={!isOnline}
        playSound
      />

      <Snackbar
        icon={
          <div className="flex flex-col items-center justify-center gap-1 text-sm">
            <GiBattery25 size={32} /> <>{batteryLevel}%</>
          </div>
        }
        title="It seems that this device isn’t charging"
        message="Please check if the charger is working and properly connected."
        open={isNeedingCharge && !isCharging}
        playSound
      />

      {magicVoucherAnimation && <VoucherAnimation />}

      <Header />

      <aside className="fixed bottom-[0] top-auto z-20 h-auto w-full pb-[0px] shadow-none grid-in-sidebar md:h-screen md:!w-[7.938rem] md:overflow-y-auto md:shadow-[8px_0px_10px_-2px] md:shadow-grayscale-darkest/10 lg:!relative lg:z-auto lg:h-auto lg:w-auto lg:overflow-auto lg:shadow-none">
        <Menu />
      </aside>

      <div
        className={cn(
          'w-full min-w-[21.25rem] rounded-[1.875rem] bg-grayscale-white pb-[5.25rem] grid-in-main dark:bg-[#2e2a2a] md:pb-0',
          {
            'lg:rounded-tl-[0]': noRadiusInTopLeft,
          },
        )}
      >
        <Outlet />
      </div>
    </main>
  )
}

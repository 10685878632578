import { useCallback, useState } from 'react'

import { Box } from '@/components/Box'
import Button from '@/components/Button'
import { ModalFooter, ModalOverflow } from '@/components/Modal'
import SelectButton from '@/components/SelectButton'

import * as S from './styles'

export type ServiceSelectedType = {
  name: string
  value: string
  selected: boolean
}

export type EditSettingsServicesModalProps = {
  services: ServiceSelectedType[]
  closeEditSettingsServices: (services?: ServiceSelectedType[]) => void
}

type FormErrorsType = {
  services: string
}

const INITIAL_DATA: ServiceSelectedType[] = [
  { name: 'Takeout', value: 'takeout', selected: false },
  { name: 'Delivery', value: 'delivery', selected: false },
]

const EditSettingsServicesModal = ({
  services,
  closeEditSettingsServices,
}: EditSettingsServicesModalProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [servicesSelected, setServicesSelected] = useState<
    ServiceSelectedType[]
  >(
    INITIAL_DATA.map((ser) => ({
      ...ser,
      selected: services ? services.some((s) => s.value === ser.value) : false,
    })),
  )
  const [formErrors, setFormErrors] = useState<FormErrorsType>({
    services: '',
  })

  const handleSelectService = useCallback(
    (service: ServiceSelectedType, index: number) => {
      setFormErrors((old) => ({ ...old, categories: '' }))
      const newServicesSelected = [...servicesSelected]
      newServicesSelected[index] = {
        ...service,
        selected: !service.selected,
      }
      setServicesSelected(newServicesSelected)
    },
    [servicesSelected],
  )

  const saveServicesSelected = useCallback(async () => {
    setIsLoading(true)
    const services = servicesSelected.filter((c) => c.selected)

    if (!services.length) {
      setFormErrors((old) => ({
        ...old,
        services: 'Select at least one service',
      }))
      setIsLoading(false)
      return
    }

    setFormErrors((old) => ({ ...old, services: '' }))

    closeEditSettingsServices(services)
    setIsLoading(false)
  }, [servicesSelected, closeEditSettingsServices])

  return (
    <>
      <ModalOverflow>
        <S.Content>
          <S.MenuForm>
            <Box flex flexDirection="column">
              <S.Categories>
                {servicesSelected.map((service, index) => (
                  <SelectButton
                    key={service.value}
                    selected={service.selected}
                    onClick={() => handleSelectService(service, index)}
                  >
                    {service.name}
                  </SelectButton>
                ))}
              </S.Categories>
              {formErrors.services && (
                <S.TextError>{formErrors.services}</S.TextError>
              )}
            </Box>
          </S.MenuForm>
        </S.Content>
      </ModalOverflow>

      <ModalFooter>
        <Box
          flex
          alignItems="center"
          JustifyContent="space-between"
          width="100%"
          style={{ marginTop: '1rem' }}
        >
          <Button
            color="white"
            size="large"
            disabled={isLoading}
            onClick={() => closeEditSettingsServices()}
            shadow
          >
            Cancel
          </Button>

          <Button
            size="large"
            disabled={isLoading}
            onClick={saveServicesSelected}
            loading={isLoading}
            style={{ marginLeft: 'auto' }}
          >
            Save
          </Button>
        </Box>
      </ModalFooter>
    </>
  )
}

export default EditSettingsServicesModal

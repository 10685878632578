import { formatInTimeZone } from 'date-fns-tz'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { titleCase } from 'title-case'

import bentoIcon from '@/assets/img/icons/others/bento.svg'
import calendarIcon from '@/assets/img/icons/others/calendar.svg'
import priceIcon from '@/assets/img/icons/others/price.svg'
import cartIcon from '@/assets/img/icons/performance/cart.svg'
import takeoutIcon from '@/assets/img/icons/performance/takeout.svg'
import profilePoliciesIcon from '@/assets/img/icons/profile/policies.svg'
import orderEditIcon from '@/assets/img/icons/two-colors/order-edit.svg'
import voucherTwoColorsIcon from '@/assets/img/icons/two-colors/voucher.svg'
import inputOrderIcon from '@/assets/img/icons-input-order.svg'
import { Box } from '@/components/Box'
import { Modal, ModalContent, ModalOverflow } from '@/components/Modal'
import Skeleton from '@/components/Skeleton'
import { AudienceNameEnum } from '@/enums/audience.enum'
import { Permission } from '@/enums/permission.enum'
import useAuth from '@/hooks/useAuth'
import useModal from '@/hooks/useModal'
import { api } from '@/lib/axios'
import ConfirmModal from '@/modals/ConfirmModal'
import {
  AdsCampaignsType,
  CampaignDetailType,
  VisitsType,
} from '@/types/super-partner.types'
import { formatCurrency, formatExtNumber } from '@/utils/formats/number'

import CampaignViewsChart from './components/CampaignViewsChart'
import { OrdersModal } from './components/OrdersModal'
import * as S from './styles'

const formatViewsData = (data: VisitsType[], timezone: string) => {
  return _.orderBy(data, 'date').map((item) => ({
    ...item,
    date: formatInTimeZone(new Date(item.date), timezone, 'MMM, dd E'),
  }))
}

export type CampaignDetailModalProps = {
  campaignDetailData: AdsCampaignsType | null
  closeCampaignDetail: (reload: boolean) => void
}
export const CampaignDetailModal = ({
  campaignDetailData,
  closeCampaignDetail,
}: CampaignDetailModalProps) => {
  const [campaignDetail, setCampaignDetail] =
    useState<CampaignDetailType | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal()
  const { open: deleteIsOpen, toggle: setDeleteIsOpen } = useModal()
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const { user, timezone } = useAuth()
  const isSuperAdmin = _.includes(user?.permissions, Permission.SUPER_ADMIN)
  const isLoyalty = campaignDetailData?.type === 'loyalty'

  const getCampaignDetail = async (campaignId: string, merchantId: string) => {
    try {
      setIsLoading(true)

      const { data } = await api.get<CampaignDetailType>(
        `/superpartner/${merchantId}/campaigns/${campaignId}/detail`,
      )
      setCampaignDetail({
        ...data,
        totalSales: Number(String(data.totalSales || 0).replaceAll('.', '')),
        menuVisits: formatViewsData(data.menuVisits, timezone),
        campaignVisit: formatViewsData(data.campaignVisit, timezone),
      })
    } finally {
      setIsLoading(false)
    }
  }

  const renderOpenOrdersModal = () => {
    return (
      <Modal open={modalIsOpen} onOpenChange={setModalIsOpen} modal>
        <ModalContent title="" onClose={() => setModalIsOpen(false)} noHeader>
          <OrdersModal orders={campaignDetail?.orders ?? []} />
        </ModalContent>
      </Modal>
    )
  }

  const deleteCampaign = async () => {
    setIsLoadingDelete(true)
    await api
      .delete(
        `/superpartner/${campaignDetailData?.merchantId}/campaigns/${campaignDetailData?.id}`,
      )
      .then(() => {
        setDeleteIsOpen(false)
        closeCampaignDetail(true)
      })
      .finally(() => {
        setIsLoadingDelete(false)
      })
  }

  const renderDeleteCampaignModal = () => (
    <Modal open={deleteIsOpen} modal>
      <ModalContent
        title="Delete Campaign"
        width="auto"
        xsWidth="90vw"
        xsHeight="auto"
        hideCloseButton
        isToCloseOnOverlay={false}
      >
        <ConfirmModal
          isLoading={isLoadingDelete}
          message="Are you sure you want to delete this campaign?"
          closeConfirm={(confirm) => {
            if (confirm) deleteCampaign()
            else setDeleteIsOpen(false)
          }}
          disableCancel={isLoadingDelete}
          confirmLabel="Delete"
        />
      </ModalContent>
    </Modal>
  )

  useEffect(() => {
    if (campaignDetailData) {
      getCampaignDetail(campaignDetailData.id, campaignDetailData.merchantId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ModalOverflow style={{ padding: 0, borderRadius: '24px' }}>
      <S.Header loyalty={isLoyalty}>
        <S.WrapperTitle loyalty={isLoyalty}>
          <S.TitleDescription loyalty={isLoyalty}>
            Campaign Detail
          </S.TitleDescription>
          <S.Title loyalty={isLoyalty}>
            {isLoyalty ? 'Loyalty' : campaignDetailData?.name}
          </S.Title>
        </S.WrapperTitle>

        <S.Illustration loyalty={isLoyalty} />

        <S.CloseButton onClick={() => closeCampaignDetail(false)}>
          ✕
        </S.CloseButton>
      </S.Header>

      <S.Content>
        <S.CampaignCardsWrapper>
          <S.CampaignCards>
            <S.CampaignCard>
              <S.CampaignCardIcon>
                <img
                  src={profilePoliciesIcon}
                  width={32}
                  height={32}
                  alt="Profile policies icon"
                />
              </S.CampaignCardIcon>
              <S.CampaignCardTitle>Campaign name</S.CampaignCardTitle>
              <S.CampaignCardDescription>
                {campaignDetailData?.name}
              </S.CampaignCardDescription>
            </S.CampaignCard>

            {campaignDetailData?.period && (
              <S.CampaignCard>
                <S.CampaignCardIcon>
                  <img
                    src={calendarIcon}
                    width={26}
                    height={26}
                    alt="Calendar icon"
                  />
                </S.CampaignCardIcon>
                <S.CampaignCardTitle>Period</S.CampaignCardTitle>
                <S.CampaignCardDescription>
                  {formatInTimeZone(
                    new Date(campaignDetailData?.period?.startAt),
                    campaignDetailData.timezone,
                    'MMM d, yyyy',
                  )}{' '}
                  to{' '}
                  {formatInTimeZone(
                    new Date(campaignDetailData?.period.endAt),
                    campaignDetailData.timezone,
                    'MMM d, yyyy',
                  )}
                </S.CampaignCardDescription>
              </S.CampaignCard>
            )}

            {campaignDetailData?.budget && (
              <S.CampaignCard>
                <S.CampaignCardIcon>
                  <img
                    src={priceIcon}
                    width={32}
                    height={32}
                    alt="Price icon"
                  />
                </S.CampaignCardIcon>
                <S.CampaignCardTitle>Budget</S.CampaignCardTitle>
                <S.CampaignCardDescription>
                  ${formatCurrency((campaignDetailData?.budget || 0) / 100)}
                </S.CampaignCardDescription>
              </S.CampaignCard>
            )}

            {campaignDetailData?.audiences &&
              campaignDetailData?.audiences.length > 0 && (
                <S.CampaignCard>
                  <S.CampaignCardIcon>
                    <img
                      src={takeoutIcon}
                      width={32}
                      height={32}
                      alt="Takeout icon"
                    />
                  </S.CampaignCardIcon>
                  <S.CampaignCardTitle>Audience</S.CampaignCardTitle>
                  <S.CampaignCardDescription>
                    {campaignDetailData?.audiences
                      .map((name) => {
                        return (
                          AudienceNameEnum[
                            name as keyof typeof AudienceNameEnum
                          ] || titleCase(name.toLowerCase())
                        )
                      })
                      .join(', ')}
                  </S.CampaignCardDescription>
                </S.CampaignCard>
              )}

            {!isLoyalty && (
              <S.CampaignCard>
                <S.CampaignCardIcon>
                  <img
                    src={bentoIcon}
                    width={32}
                    height={32}
                    alt="Bento icon"
                  />
                </S.CampaignCardIcon>
                <S.CampaignCardTitle>Nº of orders</S.CampaignCardTitle>
                <S.CampaignCardDescription>
                  {campaignDetail?.orderQuantity ?? '-'}
                </S.CampaignCardDescription>
              </S.CampaignCard>
            )}

            {isLoyalty && (
              <S.CampaignCard>
                <S.CampaignCardIcon>
                  <img
                    src={voucherTwoColorsIcon}
                    width={32}
                    height={32}
                    alt="Voucher icon"
                  />
                </S.CampaignCardIcon>
                <S.CampaignCardTitle>Used Vouchers</S.CampaignCardTitle>
                <S.CampaignCardDescription>
                  {campaignDetailData.usage ?? '-'}
                </S.CampaignCardDescription>
              </S.CampaignCard>
            )}

            <S.CampaignCard>
              <S.CampaignCardIcon>
                <img src={cartIcon} width={32} height={32} alt="Cart icon" />
              </S.CampaignCardIcon>
              <S.CampaignCardTitle>Sales</S.CampaignCardTitle>
              <S.CampaignCardDescription>
                $
                {formatCurrency((campaignDetail?.totalSales || 0) / 100) ?? '-'}
              </S.CampaignCardDescription>
              <S.CampaignCardDescription>
                Investment: $
                {formatCurrency(
                  (campaignDetailData?.investedAmount || 0) / 100,
                ) ?? '-'}
              </S.CampaignCardDescription>
            </S.CampaignCard>
          </S.CampaignCards>
        </S.CampaignCardsWrapper>

        <S.CampaignCardsChartWrapper loyalty={isLoyalty}>
          <S.CampaignCardsChart>
            {isLoyalty && (
              <S.CampaignCard>
                <S.CampaignCardIcon>
                  <img
                    src={orderEditIcon}
                    width={32}
                    height={32}
                    alt="Order edit icon"
                  />
                </S.CampaignCardIcon>
                <S.CampaignCardTitle>Minimum Order</S.CampaignCardTitle>
                <S.CampaignCardDescription>
                  $
                  {formatCurrency(
                    (campaignDetailData?.minimumOrderValue || 0) / 100,
                  ) ?? '-'}
                </S.CampaignCardDescription>
              </S.CampaignCard>
            )}
            <S.CampaignCardChart>
              <Box
                flex
                flexDirection="column"
                alignItems="center"
                gap="0.625rem"
                width="100%"
              >
                <Box
                  flex
                  alignItems="center"
                  JustifyContent="space-between"
                  width="100%"
                >
                  <S.CampaignCardChartTitle>
                    Quantity of orders
                  </S.CampaignCardChartTitle>
                  <S.CampaignCardChartNumber>
                    {campaignDetail?.orderQuantity ?? '-'}
                  </S.CampaignCardChartNumber>
                </Box>
                {campaignDetail?.orders &&
                  campaignDetail?.orders.length > 0 && (
                    <S.SeeOrdersLink onClick={() => setModalIsOpen(true)}>
                      See orders{' '}
                      <img
                        src={inputOrderIcon}
                        width={24}
                        height={24}
                        alt="Input order icon"
                      />
                    </S.SeeOrdersLink>
                  )}
              </Box>
            </S.CampaignCardChart>

            {!!campaignDetailData?.voucherQuantity && (
              <S.CampaignCardChart>
                <S.CampaignCardChartTitle>
                  Remaining Vouchers
                </S.CampaignCardChartTitle>
                <S.CampaignCardChartNumber>
                  {Number(campaignDetailData?.voucherQuantity) -
                    Number(campaignDetailData?.usage) || 0}
                </S.CampaignCardChartNumber>
              </S.CampaignCardChart>
            )}

            <S.CampaignCardChart>
              <S.CampaignCardChartTitle>
                Number of participants
              </S.CampaignCardChartTitle>
              <S.CampaignCardChartNumber>
                {formatExtNumber(campaignDetailData?.usersQuantity ?? 0, 0) ??
                  '-'}
              </S.CampaignCardChartNumber>
            </S.CampaignCardChart>
          </S.CampaignCardsChart>

          <S.CampaignChartWrapper loyalty={isLoyalty}>
            {isLoading ? (
              <Skeleton width="100%" height="100%" />
            ) : (
              <CampaignViewsChart
                data={campaignDetail?.campaignVisit ?? []}
                title="Campaign views"
                height="20.625rem"
              />
            )}
          </S.CampaignChartWrapper>
          {campaignDetailData?.type !== 'position' && !isLoyalty && (
            <S.CampaignChartWrapper>
              {isLoading ? (
                <Skeleton width="100%" height="100%" />
              ) : (
                <CampaignViewsChart
                  data={campaignDetail?.menuVisits ?? []}
                  title="Menu views"
                  height="20.625rem"
                />
              )}
            </S.CampaignChartWrapper>
          )}
        </S.CampaignCardsChartWrapper>
        {isSuperAdmin && (
          <S.DeleteFab onClick={() => setDeleteIsOpen(true)} size="large">
            Delete
          </S.DeleteFab>
        )}
      </S.Content>

      {modalIsOpen && renderOpenOrdersModal()}
      {deleteIsOpen && renderDeleteCampaignModal()}
    </ModalOverflow>
  )
}

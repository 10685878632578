import { useState } from 'react'

const useModal = () => {
  const [open, setOpen] = useState(false)

  const toggle = (close: boolean) => setOpen(close)

  return {
    open,
    toggle,
  }
}

export default useModal
